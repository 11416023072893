/* eslint-disable multiline-ternary */
import { faFileArchive, faPlus } from '@fortawesome/free-solid-svg-icons'
import { PaginationConfig } from 'antd/lib/pagination'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CardFooterICP } from 'common/components/list/inner/CardFooterICP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { CardListItemICP } from 'common/components/list/inner/CardListItemICP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { BatchCardSkeletonICP } from 'modules/batch/components/batch-list/inner/BatchCardSkeletonICP'
import { ManeuverDrawerCP } from 'modules/maneuver/components/maneuver-drawer/ManeuverDrawerCP'
import { RecipeCardContentICP } from 'modules/maneuver/components/recipe-list/inner/RecipeCardContentICP'
import { ActivityStatusEnum } from 'modules/maneuver/enums/ActivityStatusEnum'
import { ManeuverRoutes } from 'modules/maneuver/ManeuverRoutes'
import { ISearchManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchManeuverRequestDTO'
import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'
import { RecipeRequests } from 'modules/maneuver/services/RecipeRequests'
import { RecipeUtils } from 'modules/maneuver/utils/RecipeUtils'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { RecipeDrawerCP } from '../../recipe-drawer/RecipeDrawerCP'

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchManeuverRequestDTO = {
    status: ActivityStatusEnum.IN_PROGRESS,
    title: undefined,
    code: undefined,
    propertyCode: undefined,
    fieldCode: undefined,
    type: undefined,
    season: moment().year(),
    page: DEFAULT_PAGINATION.current,
    itemsPerPage: DEFAULT_PAGINATION.pageSize
}
interface IRecipeListCardICPProps {
    recipe: IRecipeResponseDTO
    loading: boolean
}

export function RecipeListCardICP(props: IRecipeListCardICPProps): JSX.Element {
    const history = useHistory()
    const [showActivityDrawer, setShowActivityDrawer] = useState<boolean>(false)
    const [recipeDrawerVisible, setRecipeDrawerVisible] = useState<boolean>(false)

    const deleteReq = useRequest(RecipeRequests.deleteRecipe(props.recipe.code))
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    const cloneReq = useRequest(RecipeRequests.cloneRecipe(props.recipe.code))
    useEffect(oncloneReqChange, [cloneReq.awaiting])

    const archiveReq = useRequest()
    useEffect(onArchiveReqChange, [archiveReq.awaiting])

    function onDeleteReqChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success || !deleteReq.returnData)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Ocorreu um erro ao remover a instrução. Tente novamente.')

        NotificationHelper.success('Instrução removida com sucesso')
        ListActions.mustUpdate()
    }

    function oncloneReqChange(): void {

        if (cloneReq.awaiting || !cloneReq.tried)
            return

        if (!cloneReq.success || !cloneReq.returnData)
            return RequestUtils.showDefaultErrorNotification(cloneReq.error, 'Ocorreu um erro ao duplicar a instrução. Tente novamente.')

        NotificationHelper.success('Instrução duplicada com sucesso')
        ListActions.mustUpdate()
    }

    function onArchiveReqChange(): void {

        if (archiveReq.awaiting || !archiveReq.tried)
            return

        if (!archiveReq.success || !archiveReq.returnData)
            return RequestUtils.showDefaultErrorNotification(archiveReq.error, 'Ocorreu um erro. Tente novamente.')

        ListActions.mustUpdate()
    }

    if (!props.recipe || props.loading)
        return <BatchCardSkeletonICP />

    return (
        <WrapperSCP>

            <CardListItemICP
                key={props.recipe.code}
                item={props.recipe}
                loading={props.loading}
                customSkeleton={<BatchCardSkeletonICP />}
                header={
                    <CardHeaderICP
                        highlight={`#${props.recipe.code}`}
                        highlighAction={() => setRecipeDrawerVisible(true)}
                        title={props.recipe.title}
                        subtitle={`${RecipeUtils.translateType(props.recipe.type)}`}
                        itemCode={props.recipe.code}
                        actions={props.recipe.archived ? <FontAwsomeIconCP icon={faFileArchive} color={CostumizedThemeConfig.gray} /> : [
                            {
                                label: 'Editar',
                                action: (itemCode) => setRecipeDrawerVisible(true)
                            },
                            {
                                label: 'Duplicar',
                                action: (itemCode) => { cloneReq.runRequest() }
                            },
                            {
                                label: 'Arquivar',
                                action: (itemCode) => { archiveReq.runRequest(RecipeRequests.archiveRecipe(props.recipe.code, true)) }
                            }
                        ]}
                    />
                }
                content={
                    <RecipeCardContentICP
                        formulas={props.recipe.formulas}
                        mixTotalLiters={props.recipe.mixTotalLiters}
                        description={props.recipe.description}
                    />
                }
                footer={
                    <CardFooterICP
                        onDelete={deleteReq.runRequest}
                        deleteLoading={false}
                        confirmLabel={'Tem certeza que quer excluir esta instrução?'}
                    >
                        <ActionsSCP>
                            {
                                props.recipe.archived ?
                                    <ButtonCP
                                        type={'ghost'}
                                        style={{ borderRadius: '5px' }}
                                        onClick={() => archiveReq.runRequest(RecipeRequests.archiveRecipe(props.recipe.code, false))}
                                        icon={<FontAwsomeIconCP icon={faFileArchive} />}
                                    >
                                        Desarquivar
                                    </ButtonCP>

                                    :

                                    <ButtonCP
                                        type={'primary'}
                                        style={{ borderRadius: '5px' }}
                                        onClick={() => {
                                            setShowActivityDrawer(true)
                                        }}
                                        icon={<FontAwsomeIconCP icon={faPlus} />}
                                    >
                                        Nova atividade
                                    </ButtonCP>
                            }

                        </ActionsSCP>
                    </CardFooterICP>
                }
            />
            <ManeuverDrawerCP
                show={showActivityDrawer}
                recipeType={props.recipe.type}
                recipeCode={props.recipe.code}
                onCancel={() => {
                    setShowActivityDrawer(false)
                }}
                onSuccess={(recipeData) => {
                    setShowActivityDrawer(false)
                    if (!!recipeData) {
                        SearchActions.setFilter<ISearchManeuverRequestDTO>({
                            ...DEFAULT_FILTERS,
                            status: ActivityStatusEnum.NOT_INITIATED,
                            title: recipeData?.title
                        }, FilterTypesEnum.MANEUVER)
                        history.push(ManeuverRoutes.ROOT)
                    }
                }}
            />
            <RecipeDrawerCP
                show={recipeDrawerVisible}
                recipeCode={props.recipe.code}
                onCancel={() => setRecipeDrawerVisible(false)}
                onSuccess={() => {
                    setRecipeDrawerVisible(false)
                }}
            />
        </WrapperSCP>
    )
}

const ActionsSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const WrapperSCP = styled.div`
  
`

const POWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .ant-btn{
        margin-left: 10px;
        margin-bottom: 5px;
        min-width: 150px;
    }
    
`