import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { ISaveProductRequestDTO } from './dtos/requests/ISaveProductRequestDTO'
import { ISearchProductRequestDTO } from './dtos/requests/ISearchProductRequestDTO'

import { ISearchPurchaseRequestDTO } from './dtos/requests/ISearchPurchaseRequestDTO'
import { ISavePurchaseRequestDTO } from './dtos/requests/ISavePurchaseRequestDTO'

export class ProductRequests {

    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/products`
    private static readonly prodUsageApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/product-usage`
    private constructor() { }

    static getProduct = (code: number): RequestConfigTP => ({
        url: `${ProductRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static getProductList = (dto?: ISearchProductRequestDTO): RequestConfigTP<ISearchProductRequestDTO> => ({
        url: `${ProductRequests.baseApiUrl}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static saveProduct = (dto: ISaveProductRequestDTO): RequestConfigTP<ISaveProductRequestDTO> => ({
        url: `${ProductRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updateProduct = (code: number, dto: ISaveProductRequestDTO): RequestConfigTP<ISaveProductRequestDTO> => ({
        url: `${ProductRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static deleteProduct = (code: number): RequestConfigTP => ({
        url: `${ProductRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static getPurchase = (productCode: number, code: number): RequestConfigTP => ({
        url: `${ProductRequests.baseApiUrl}/${productCode}/purchases/${code}`,
        method: HttpMethodEnum.GET,
    })

    static getPurchaseList = (productCode: number): RequestConfigTP<ISearchPurchaseRequestDTO> => ({
        url: `${ProductRequests.baseApiUrl}/${productCode}/purchases`,
        method: HttpMethodEnum.GET,
    })

    static savePurchase = (productCode: number, dto: ISavePurchaseRequestDTO): RequestConfigTP<ISavePurchaseRequestDTO> => ({
        url: `${ProductRequests.baseApiUrl}/${productCode}/purchases`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updatePurchase = (productCode: number, code: number, dto: ISavePurchaseRequestDTO): RequestConfigTP<ISavePurchaseRequestDTO> => ({
        url: `${ProductRequests.baseApiUrl}/${productCode}/purchases/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static deletePurchase = (productCode: number, code: number): RequestConfigTP => ({
        url: `${ProductRequests.baseApiUrl}/${productCode}/purchases/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static addImagesToPurchase = (productCode: number, code: number, data: FormData): RequestConfigTP => ({
        url: `${ProductRequests.baseApiUrl}/${productCode}/purchases/${code}/pictures`,
        method: HttpMethodEnum.PUT,
        params: data,
        headers: { 'content-type': 'multipart/form-data' }
    })

    static removePurchasePictures = (productCode: number, purchaseCode: number, dto: { pictures: string[] }): RequestConfigTP => ({
        url: `${ProductRequests.baseApiUrl}/${productCode}/purchases/${purchaseCode}/pictures`,
        method: HttpMethodEnum.DELETE,
        params: dto,
    })

    static getPurchasePictures = (productCode: number, code: number): RequestConfigTP => ({
        url: `${ProductRequests.baseApiUrl}/${productCode}/purchases/${code}/pictures`,
        method: HttpMethodEnum.GET
    })
}
