import { Layout } from 'antd'
import { ThemeConfig } from 'config/ThemeConfig'
import styled from 'styled-components'

interface ISiderCPProps {
    breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
    bgColor?: string
    mainSider?: boolean
    width?: string
    vh?: number
}

/**
 * COMPONENTE
 * Barra lateral esquerda generica para compor layouts.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export const SiderSCP = styled(Layout.Sider) <ISiderCPProps>`
    &.ant-layout-sider {
        height: 100%;
        min-height: ${props => (props.mainSider ? `calc(${props => props.vh}px * 100)` : '100%')};
        background: ${props => (props.bgColor ? props.bgColor : ThemeConfig.menuBg)};
        ${props => (props.width ? `width: ${props.width}` : '')};

        .ant-layout-sider-zero-width-trigger{
            top: 35px;
            right: 0;
            background: transparent;
        }
        .ant-layout-sider-children{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        .ant-layout-sider-trigger{
            position: absolute;
            top: 0;
            background: transparent;
            color: #fff;
            max-width: 30px;
            right: 0;
        }

        &.ant-layout-sider-has-trigger {
            padding-bottom: 0
        }
    }
`
export const SiderCP = SiderSCP
