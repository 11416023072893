import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { TextCP } from 'common/components/text/TextCP'
import { FieldUtils } from 'modules/field/utils/FieldUtils'
import { IManauverItemResponseDTO } from 'modules/maneuver/services/dtos/responses/IManauverItemResponseDTO'
import { ManeuverUtils } from 'modules/maneuver/utils/ManeuverUtils'
import React, { useEffect, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import moment from 'moment-timezone'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faCheckDouble, faFeatherAlt, faArrowDown, faArrowUp, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import { LogbookDrawerCP } from 'modules/logbook/components/logbook-drawer/LogbookDrawerCP'
import { ManeuverRequests } from 'modules/maneuver/services/ManeuverRequests'
import { useRequest } from 'common/request-helper/UseRequest'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { DateUtils } from 'common/utils/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { IConcludeManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/IConcludeManeuverRequestDTO'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { RecipeUtils } from 'modules/maneuver/utils/RecipeUtils'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'

interface ILogbookHeaderCPProps {
    manauverData: IManauverItemResponseDTO
}

export function LogbookHeaderCP(props: ILogbookHeaderCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [hide, setHide] = useState<boolean>(!!screenSize.smd)
    const titleStyle = { marginLeft: '5px' }
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }
    const btnPositionStyle: CSSProperties = { position: 'absolute', top: '1em', right: '1em' }
    const [showLogbookForm, setShowLogookForm] = useState<boolean>(false)
    const [conclusionDate, setConclusionDate] = useState<string>(DateUtils.formatDate(moment()
        .format(), DateFormatEnum.BR_WITHOUT_TIME))
    const [disableNewEntry, setDisableNewEntry] = useState<boolean>(false)

    useEffect(() => {
        if (!!props.manauverData) {
            setDisableNewEntry(!!props.manauverData.realEndDate)
        }
    }, [props.manauverData])

    const markAsConcludedReq = useRequest()
    useEffect(onRequestChange, [markAsConcludedReq.awaiting])

    function onRequestChange(): void {

        if (markAsConcludedReq.awaiting || !markAsConcludedReq.tried) {
            return
        }

        if (!markAsConcludedReq.success) {
            if (markAsConcludedReq.status !== HttpStatusEnum.UNAUTHORIZED) {
                console.error('ERROR: ', markAsConcludedReq.returnData, markAsConcludedReq.error)
            }
            return RequestUtils.showDefaultErrorNotification(markAsConcludedReq.error, 'Erro ao buscar detalhes do manejo!')
        }

        ListActions.mustUpdate()
    }

    function reopen(): void {
        markAsConcludedReq.runRequest(ManeuverRequests.reopen(props.manauverData.code))
    }

    function concludeManeuver(): void {
        if (!conclusionDate) {
            return
        }

        const dto: IConcludeManeuverRequestDTO = {
            date: DateUtils.getDateFormattedToApi(conclusionDate),
        }

        markAsConcludedReq.runRequest(ManeuverRequests.markAsConcluded(props.manauverData.code, dto))
    }

    return (
        <WrapperSCP>
            <CardWrapperSCP>
                <CardHeaderICP
                    highlight={ManeuverUtils.getRecipeTypeIcon(props.manauverData.type)}
                    style={{ gridTemplateColumns: '60px 3fr .4fr' }}
                    title={`${props.manauverData.field ? props.manauverData.field.name : props.manauverData.property.name} ${props.manauverData.field ? `${props.manauverData.field.totalArea}ha` : ''}`}
                    categoryTitle={`${RecipeUtils.translateType(props.manauverData.type)}: ${props.manauverData.recipeTitle}`}
                    subtitle={
                        !hide ?
                            <SubTitleSCP>
                                <TextCP
                                    text={`${!!props.manauverData.field && !!props.manauverData.field.coffeeVariety ? FieldUtils.translateVarietysEnum(props.manauverData.field.coffeeVariety) : ''}
                                    ${props.manauverData.field ? props.manauverData.field.plantingYear : ''}`}
                                />
                                {!!props.manauverData.recipe.description &&
                                    <TextCP text={`${props.manauverData.recipe.description}`} />}
                            </SubTitleSCP>
                            : undefined
                    }

                />
                <WrpSCP>
                    {props.manauverData.recipe.formulas.length > 0 &&
                        <FormulaWrapperSCP>
                            {!!props.manauverData.recipe.mixTotalLiters
                                ? <TextCP text={`Litragem total da mistura: ${props.manauverData.recipe.mixTotalLiters} litros/ha`} />
                                : <TextCP text={'Litragem total da mistura não definida!'} />}
                            {
                                !!props.manauverData.field && props.manauverData.recipe.formulas.map(fr => (
                                    <TextCP
                                        key={`${fr.productCode}`}
                                        text={`${fr.productName}: ${MaskUtils.applyNumberMask(fr.quantity)}${ProductUtils.getUnityLabel(fr.isLiquid)}/ha (${MaskUtils.applyNumberMask(fr.quantity * (props.manauverData.field?.totalArea ?? 0))} ${ProductUtils.getUnityLabel(fr.isLiquid)})`} />
                                ))
                            }

                        </FormulaWrapperSCP>}
                    {
                        screenSize.smd &&
                        <ButtonCP
                            onClick={() => setHide(!hide)}
                            size={'small'}
                            style={btnPositionStyle}
                            icon={<FontAwsomeIconCP icon={hide ? faArrowDown : faArrowUp} />}
                            type={'primary'}
                        />

                    }
                </WrpSCP>

            </CardWrapperSCP>
            {
                !hide &&

                <DateInfoWrapper>
                    <div>
                        <RowSCP>
                            <TextCP text={'Iniciado em:'} style={titleStyle} />
                            <TextCP text={moment(props.manauverData.startDate)
                                .format('DD/MM/YYYY')} style={valueStyle} />
                        </RowSCP>
                        <RowSCP>
                            <TextCP text={'Estimativa de conclusão:'} style={titleStyle} />
                            <TextCP text={moment(props.manauverData.estimatedEndDate)
                                .format('DD/MM/YYYY')} style={valueStyle} />
                        </RowSCP>
                        {props.manauverData.realEndDate &&
                            <RowSCP>
                                <TextCP text={'Concluído em:'} style={titleStyle} />
                                <TextCP text={moment(props.manauverData.realEndDate)
                                    .format('DD/MM/YYYY')} style={valueStyle} />
                            </RowSCP>}
                    </div>
                    <ButtonWrapperSCP>

                        {
                            disableNewEntry ?
                                <ButtonCP
                                    type={'ghost'}
                                    icon={<FontAwsomeIconCP icon={faToggleOn} />}
                                    loading={markAsConcludedReq.awaiting}
                                    onClick={reopen}
                                >
                                    Reabrir
                                </ButtonCP>
                                :
                                <PopOverCP
                                    placement={'bottom'}
                                    title={'Concluir item'}
                                    trigger={'click'}
                                    content={
                                        <POWrapperSCP>
                                            <InputCP
                                                label={'Data'}
                                                placeholder={'__/__/____'}
                                                onChange={setConclusionDate}
                                                value={conclusionDate}
                                                type={'date'}
                                                required={true}
                                                mask={MaskUtils.applyDateMask}
                                            />
                                            <ButtonCP
                                                type={'primary'}
                                                icon={<FontAwsomeIconCP icon={faCheckDouble} />}
                                                onClick={concludeManeuver}
                                                loading={markAsConcludedReq.awaiting}
                                                disabled={disableNewEntry}
                                            />
                                        </POWrapperSCP>

                                    }
                                >
                                    <ButtonCP
                                        type={'ghost'}
                                        icon={<FontAwsomeIconCP icon={faCheckDouble} />}
                                        loading={markAsConcludedReq.awaiting}
                                        disabled={disableNewEntry}
                                    >
                                        Concluir
                                    </ButtonCP>
                                </PopOverCP>
                        }
                        <ButtonCP
                            type={'primary'}
                            onClick={() => setShowLogookForm(true)}
                            icon={<FontAwsomeIconCP icon={faFeatherAlt} />}
                            disabled={disableNewEntry}
                        >
                            Novo registro
                        </ButtonCP>
                    </ButtonWrapperSCP>

                </DateInfoWrapper>

            }

            <LogbookDrawerCP
                maneuverCode={props.manauverData.code}
                recipeCode={props.manauverData.recipeCode}
                fieldCode={props.manauverData.field && props.manauverData.field.code ? props.manauverData.field.code : 0}
                show={showLogbookForm}
                onCancel={() => setShowLogookForm(false)}
                onSuccess={() => setShowLogookForm(false)}
            />
        </WrapperSCP>

    )
}

const WrpSCP = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  align-items: end;
`
const WrapperSCP = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  column-gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

`

const CardWrapperSCP = styled.div`


`

const ButtonWrapperSCP = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  .ant-btn {
    margin-left: 10px;
  }

  @media (max-width: 1280px) {
    flex-direction: column;
    .ant-btn {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: .5fr .5fr;
    column-gap: 10px;
    width: 100%;
    .ant-btn {
      width: 100%;
      margin: 0;
      justify-content: center;
    }
  }
`
const RowSCP = styled.div`
  display: flex;
  margin-bottom: 5px;

`

const POWrapperSCP = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;

  .ant-btn {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    .ant-btn {
      margin: 0;
    }
  }
`

const DateInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 10px 0;
  align-items: flex-end;
  min-width: 230px;
  justify-content: space-between;

  .ant-typography {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    margin: 10px 0;
    align-items: flex-start;
  }
`

const SubTitleSCP = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  .ant-typography {
    color: ${props => props.theme.gray};
    font-size: 12px;
  }

`

const FormulaWrapperSCP = styled.div`
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  background: #e6e6e6;
  border-radius: 5px;

  .ant-typography {
    font-size: 11px;
  }
`