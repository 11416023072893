import React, { CSSProperties } from 'react'
import { Checkbox } from 'antd'
import styled from 'styled-components'
import { TextCP } from 'common/components/text/TextCP'

interface ICheckboxCPProps {
    onChange: (isChecked: boolean) => void
    isChecked: boolean
    isDisabled?: boolean
    enableAutoFocus?: boolean
    style?: CSSProperties
    label?: string
}

/**
 * COMPONENTE
 * Elemento de selecao unica para formularios do tipo 'check-box' (wrapper para componente do antd).
 */
export function CheckboxCP(props: ICheckboxCPProps): JSX.Element {

    return (
        <WrapperSCP style={props.style}>
            <TextCP text={props.label ?? ''} />
            <Checkbox
                checked={props.isChecked}
                onChange={event => props.onChange(event.target.checked)}
                autoFocus={props.enableAutoFocus}
                disabled={props.isDisabled}
                style={props.style}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    row-gap: 10px;
    justify-content: flex-end;
    align-items: center;
    .ant-typography{
        margin: 0 10px;
    }
`
