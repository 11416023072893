import React, { useEffect } from 'react'
import md5 from 'md5'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { BatchPresentationScreen } from 'modules/batch-presentation/screens/BatchPresentationScreen'
import { BatchLandingPageScreen } from 'modules/batch-landing-page/screens/BatchLandingPageScreen'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { LoggedUserDataTP } from 'common/redux/ReduxTypes'
import { RequestHelper } from 'common/request-helper/RequestHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { AuthActions } from 'modules/auth/AuthActions'
import { AuthRequests } from 'modules/auth/AuthRequests'
import { IUserLoginRequestDTO } from 'modules/auth/services/dtos/requests/IUserLoginRequestDTO'
import { IUserLoginResponseDTO } from 'modules/auth/services/dtos/responses/IUserLoginResponseDTO'
import { BatchLandingPageRoutes } from 'modules/batch-landing-page/BatchLandingPageRoutes'
import { useSelector } from 'react-redux'

BatchPresentationRoutes.ROOT = '/coffees/details'
BatchPresentationRoutes.BATCH_DETAILS = `${BatchPresentationRoutes.ROOT}/:batchCode`

export function BatchPresentationRoutes(): JSX.Element {
    // const history = useHistory()
    // const loggedUserData: LoggedUserDataTP | null = useSelector((state: IReduxState) => _.get(state, 'loggedUser'))

    // const routeLocation = useLocation()
    // useEffect(onRouteChange, [routeLocation.pathname])

    // const request = useRequest<IUserLoginResponseDTO, IUserLoginRequestDTO>()
    // useEffect(onRequestChange, [request.awaiting])

    // function onRequestChange(): void {

    //     if (request.awaiting || !request.tried)
    //         return

    //     if (!request.success || !request.returnData)
    //         return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao carregar dados!')

    //     const resDto = request.returnData
    //     AuthActions.authorizePublicRoutes(resDto.token)
    // }

    // function authPublicRoute(): void {

    //     if (loggedUserData?.user)
    //         return

    //     const loginDto: IUserLoginRequestDTO = {
    //         email: 'qrcodeaccess@flowins.me'.trim(),
    //         password: md5('flowins331'.trim())
    //     }
    //     request.runRequest(AuthRequests.getUserLoginReqConfig(loginDto))
    // }

    // function onRouteChange(): void {
    //     if (routeLocation.pathname.match(BatchLandingPageRoutes.ROOT)) {
    //         authPublicRoute()
    //         return
    //     }

    //     RequestHelper.addErrorCustomAction({
    //         action: (response) => AuthActions.onUnauthorizedRequestResponse(response, history),
    //         httpStatus: HttpStatusEnum.UNAUTHORIZED
    //     })
    // }
    return (
        <Switch>
            <Route path={BatchPresentationRoutes.BATCH_DETAILS} component={BatchPresentationScreen} />
        </Switch>
    )
}
