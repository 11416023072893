import { useScreenSize } from 'common/hooks/UseScreenSize'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { TextCP } from 'common/components/text/TextCP'
import { IImplementResponseDTO } from 'modules/maneuver/services/dtos/responses/IImplementResponseDTO'
import { ManeuverUtils } from 'modules/maneuver/utils/ManeuverUtils'
import React, { useState } from 'react'
import styled from 'styled-components'

import { DateUtils } from 'common/utils/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'

import { ButtonCP } from 'common/components/button/ButtonCP'
import { MaintenanceDrawerCP } from 'modules/maneuver/components/maintenance-drawer/MaintenanceDrawerCP'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'

interface IMaintenanceHeaderCPProps {
    implementData?: IImplementResponseDTO
}

export function MaintenanceHeaderCP(props: IMaintenanceHeaderCPProps): JSX.Element {
    const screenSize = useScreenSize()

    const [maintenanceDrawerVisible, setMaintenanceDrawerVisible] = useState<boolean>(false)

    if (!props.implementData) {
        return (
            <WrapperSCP>
                <LoadingOverlayCP show={true} />
            </WrapperSCP>
        )
    }

    return (

        <WrapperSCP>
            <CardWrapperSCP>
                <CardHeaderICP
                    highlight={ManeuverUtils.getImplementTypeIcon(props.implementData.type)}
                    style={{ gridTemplateColumns: '60px 3fr .4fr' }}
                    title={`${props.implementData.brand} - ${props.implementData.model}`}
                    categoryTitle={`${ManeuverUtils.translateImplementType(props.implementData.type)}`}
                    subtitle={
                        <SubTitleSCP>
                            <TextCP text={`Ano: ${props.implementData.year}`} />
                            {!!props.implementData.lastRevisionDate &&
                                <TextCP text={`Última revisão: ${DateUtils.getFormatted(props.implementData.lastRevisionDate, DateFormatEnum.BR_WITHOUT_TIME)}`} />}
                        </SubTitleSCP>
                    }

                />

            </CardWrapperSCP>

            <BtnWrapperSCP>
                <ButtonCP
                    type={'primary'}
                    onClick={() => setMaintenanceDrawerVisible(true)}
                    wrapperStyle={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
                >
                    Nova manutenção
                </ButtonCP>
            </BtnWrapperSCP>
            <MaintenanceDrawerCP
                show={maintenanceDrawerVisible}
                onCancel={() => setMaintenanceDrawerVisible(false)}
                implementCode={props.implementData.code}
                onSuccess={() => {
                    setMaintenanceDrawerVisible(false)
                }}
            />
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    column-gap: 20px;
    @media (max-width: 768px){
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
    background: white;
    border-radius: 10px;
    padding: 8px;
    align-items: center;
    min-height: 90px;
`

const CardWrapperSCP = styled.div`


`

const RowSCP = styled.div`
    display: flex;
    margin-bottom: 5px;

`

const BtnWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 768px){
        justify-content: center;
        .ant-btn{ 
            width: 100%;
            text-align: center;
            justify-content: center;
        }
    }
`

const SubTitleSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    width: 100%;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }

`
