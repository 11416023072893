import { TagCP } from 'common/components/tag/TagCP'
import { TextCP } from 'common/components/text/TextCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { BenefitDetailsContentICP } from 'modules/batch/components/batch-process-details-content/inner/BenefitDetailsContentICP'
import { PostHarvestDetailsContentICP } from 'modules/batch/components/batch-process-details-content/inner/PostHarvestDetailsContentICP'
import { StorageDetailsContentCP } from 'modules/batch/components/batch-process-details-content/inner/StorageDetailsContentICP'
import { BatchProcessTypeEnum } from 'modules/batch/enums/BatchProcessTypeEnum'
import { IBatchProcessResDTO } from 'modules/batch/services/dtos/responses/IBatchProcessResDTO'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IBatchProcessDetailsCPProps {
    process: IBatchProcessResDTO
    language?: LanguageEnum
}

const LABELS_PT = {
    type: 'Tipo',
    startDate: 'Início',
}

const LABELS_EN = {
    type: 'Type',
    startDate: 'Started on',
}
export function BatchProcessDetailsCP(props: IBatchProcessDetailsCPProps): JSX.Element {
    const [labels, setLabels] = useState(LABELS_PT)
    const [dateFormat, setDateFormat] = useState<DateFormatEnum>(DateFormatEnum.BR_WITHOUT_TIME)
    const [typeTranslateFunc, setTypeTranslateFunc] = useState<(type: BatchProcessTypeEnum) => string>(() => BatchUtils.translateProcessType)

    useEffect(translateLabels, [props.language])

    function translateLabels(): void {
        if (props.language === LanguageEnum.EN) {
            setLabels(LABELS_EN)
            setTypeTranslateFunc(() => BatchUtils.translateProcessToEnglish)
            setDateFormat(DateFormatEnum.EUA_WITHOUT_TIME)
        }
    }

    return (
        <WrapperSCP>
            <ContentInfoSCP>
                <RowSCP>
                    <TextCP text={`${labels.type}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TagCP content={`${typeTranslateFunc(props.process.type)}`} color={BatchUtils.getBatchProcessTypeTagColor(props.process.type)} />
                </RowSCP>
                <RowSCP>
                    <TextCP text={`${labels.startDate}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={DateUtils.getFormatted(props.process.startDate, dateFormat)} />
                </RowSCP>
            </ContentInfoSCP>
            {
                props.process.type === BatchProcessTypeEnum.POST_HARVEST && props.process.structure &&
                <PostHarvestDetailsContentICP
                    method={props.process.method}
                    methodOther={props.process.methodOther}
                    structure={props.process.structure}
                    language={props.language}
                />
            }
            {
                !!props.process.benefit &&
                <BenefitDetailsContentICP
                    benefit={props.process.benefit}
                    language={props.language}
                />
            }
            {
                !!props.process.storage &&
                <StorageDetailsContentCP
                    storage={props.process.storage}
                    language={props.language}
                />
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`

const ContentInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
        .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
        .ant-typography{
        font-size: 12px;
    }
`