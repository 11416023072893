import { faPen } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListCP } from 'common/components/list/ListCP'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IProductMaintenanceResponseDTO } from 'modules/maneuver/services/dtos/responses/IProductMaintenanceResponseDTO'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import React from 'react'
import styled from 'styled-components'

interface IMaintenanceListDrawerCPProps {
    show: boolean
    onCancel: () => void
    implementCode: number
    productMaintenances: IProductMaintenanceResponseDTO[]
    setMtnDrawerVisible: () => void
}

export function ProdMaintenanceListDrawerCP(props: IMaintenanceListDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()

    return (
        <DrawerCP
            title={`#${props.implementCode} - Itens utilizados `}
            visible={props.show}
            width={'60%'}
            onClose={() => {
                props.onCancel()
            }}
        >
            <WrapperSCP>
                <HeaderSCP>
                    <TextCP text={'Item'} />
                    <TextCP text={'Quantidade'} style={{ display: 'flex', justifyContent: 'center' }} />
                </HeaderSCP>
                <ListCP<IProductMaintenanceResponseDTO>
                    bordered={false}
                    itemLayout={'vertical'}
                    size={'large'}
                    loadMoreData={() => { }} dataSource={props.productMaintenances}
                    locale={{
                        emptyText: <EmptyCP description={'Esta manutenção não inclui outros itens'} />
                    }}
                    renderItem={(item: IProductMaintenanceResponseDTO) => (
                        <GridWrapperSCP>
                            <TextCP text={`${screenSize.smd ? 'Nome: ' : ''} ${item.product.name}`} />
                            <TextCP
                                text={`${screenSize.smd ? 'Quantidade: ' : ''} ${item.quantity} ${ProductUtils.getUnitOfMeasurementAbbreviation(item.product.unitOfMeasurement)}`}
                            />
                            <ButtonWrapperSCP>

                                <ButtonCP
                                    type={'primary'}
                                    onClick={() => props.setMtnDrawerVisible()}
                                    icon={<FontAwsomeIconCP icon={faPen} />}
                                    tooltip={'Editar'}
                                />

                            </ButtonWrapperSCP>
                        </GridWrapperSCP>
                    )}
                />

            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
 
`
const HeaderSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    align-items: center;
    padding: 10px 10px;
    background: #ececec;
  
    .ant-typography {
        font-weight: 600;
    }
    @media(max-width: 768px){
     display: none;
     }
`

const GridWrapperSCP = styled.div`
    display: grid;
    grid-template-columns:  1fr 1fr 1fr ;
    align-items: center;
    padding: 10px 10px;
     border-bottom: 1px solid ${props => props.theme.lightGray};
     @media(max-width: 768px){
        grid-template-columns: 1fr;
       
     }
`
const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    .ant-btn{
        margin: 0 5px;
    }
`