import React, { useState } from 'react'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'
import { UserMainMenuCP } from 'modules/app/components/user-main-menu/UserMainMenuCP'
import styled from 'styled-components'
import { UserProfileSettingsCP } from 'modules/app/components/user-profile-settings/UserProfileSettingsCP'
import { CollapseTriggerCP } from 'modules/app/components/main-menu-sider/inner/CollapseTriggerCP'
import { VersionMenuItemICP } from 'modules/app/components/user-main-menu/inner/VersionMenuItemICP'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
interface IMainMenuSiderCPProps{
    isMobile?: boolean
}

/**
 * Sider contendo o menu principal do sistema
 * @todo implementar comportamento de collapse para quando o contexto do menu mudar (manejo)
 */
export function MainMenuSiderCP(props: IMainMenuSiderCPProps): JSX.Element {
    const menuContextChanged: boolean = useSelector((state: IReduxState) => _.get(state, 'maneuverChildMenuOpened'))
    const [siderIsCollapsed, setSiderIsCollapsed] = useState<boolean>(false)

    return (
        <WrapperSCP style={{ display: `${props.isMobile ? 'none' : 'block'}` }}>
            <SiderCP
                bgColor={'transparent'}
                mainSider={true}
                width={'260'}
                collapsed={siderIsCollapsed}
                collapsible={true}
                trigger={<CollapseTriggerCP />}
                collapsedWidth={78}
                onCollapse={() => setSiderIsCollapsed(!siderIsCollapsed)}
            >
                <MenuWrapperSCP>
                    <UserProfileSettingsCP isCollapsed={siderIsCollapsed} />
                    <UserMainMenuCP isCollapsed={siderIsCollapsed} />
                    <VersionMenuItemICP />
                </MenuWrapperSCP>

            </SiderCP>
        </WrapperSCP>
    )
}
const MenuWrapperSCP = styled.div`
    position: relative;
    height: 100%;
`
const WrapperSCP = styled.div`
`
