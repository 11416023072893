import { faObjectGroup, faObjectUngroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Space } from 'antd'

import { SplitBatchDrawerCP } from 'modules/batch/components/split-batch-drawer/SplitBatchDrawerCP'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ButtonCP } from '../../../../../common/components/button/ButtonCP'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { ListActions } from 'common/components/list/inner/ListActions'
import { MergeBatchesDrawerCP } from 'modules/batch/components/merge-batches-drawer/MergeBatchesDrawerCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'

type BatchActionTP = 'split' | 'merge'

export function MergeOrSplitActionCP(): JSX.Element | null {
    const selectedBatches: number[] = useSelector((state: IReduxState) => _.get(state, 'listState.selectedItems'))
    const batchList: number[] = useSelector((state: IReduxState) => _.get(state, 'listState.list'))
    const screenSize = useScreenSize()

    const [showDrawer, setShowDrawer] = useState<boolean>(false)
    const [action, setAction] = useState<BatchActionTP>()

    useEffect(defineActions, [selectedBatches])

    function defineActions(): void {
        if (!selectedBatches)
            return

        if (selectedBatches.length > 1)
            setAction('merge')
        else
            setAction('split')
    }

    if (!selectedBatches || _.isEmpty(selectedBatches))
        return null

    return (
        <WrapperSCP>
            <Space direction={'horizontal'}>
                <ButtonCP
                    type={screenSize.smd ? 'primary' : 'ghost'}
                    onClick={() => setShowDrawer(true)}
                >
                    <Space direction={'horizontal'}>
                        <FontAwesomeIcon icon={action === 'split' ? faObjectUngroup : faObjectGroup} />
                        {action === 'split' ? `Dividir Lote #${selectedBatches[0]}` : `Mesclar Lotes (${selectedBatches.length})`}
                    </Space>
                </ButtonCP>
            </Space>

            <SplitBatchDrawerCP
                show={showDrawer && action === 'split'}
                batchCode={selectedBatches[0]}
                onCancel={() => {
                    ListActions.unselectAll()
                    setShowDrawer(false)
                }}
                onSuccess={() => {
                    setShowDrawer(false)
                    ListActions.unselectAll()
                    ListActions.mustUpdate()
                }}
            />

            <MergeBatchesDrawerCP
                show={showDrawer && action === 'merge'}
                batches={selectedBatches}
                onCancel={() => {
                    setShowDrawer(false)
                    ListActions.unselectAll()
                }}
                onSuccess={() => {
                    setShowDrawer(false)
                    ListActions.unselectAll()
                    ListActions.mustUpdate(true)
                }}
            />
        </WrapperSCP>
    )
}

const ResumeWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const WrapperSCP = styled.div`
  
   @media(max-width: 767px){
    position: absolute;
    bottom: 0;
    z-index: 9;
    width: 100%;
    right: -6px;
    background: white;
    padding: 18px;
    justify-content: center;
    display: flex;
    border-top: 1px solid ${props => props.theme.gray}; 
     .ant-space-item, .ant-btn{
        width: 100%;
     }
     .ant-space{
         width: auto;
     }
   }
`