import { TextCP } from 'common/components/text/TextCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'

import { MaskUtils } from 'common/utils/MaskUtils'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { ListActions } from 'common/components/list/inner/ListActions'
import { SwitchCP } from 'common/components/switch/SwitchCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { UserPermissionsEnum } from 'common/enums/UserPermissionsEnum'
import { CheckboxCP } from 'common/components/form-fields/checkbox/CheckboxCP'
import { PersonUtils } from 'modules/person/utils/PersonUtils'

interface IPersonCardContentICPProps {
    personCode: number
    phone: string
    cpf?: string
    email?: string
    activeUser: boolean
}

/**
 * COMPONENTE
 * Conteudo central de um item da lista de pessoas
 */
export function PersonCardContentICP(props: IPersonCardContentICPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }
    const [qGraderConfirmVisible, setQGraderConfirmVisible] = useState<boolean>(false)
    const [permissions, setPermissions] = useState<UserPermissionsEnum[]>([])

    const setAsUserReq = useRequest()
    useEffect(onSetAsUserReqChange, [setAsUserReq.awaiting])

    function onSetAsUserReqChange(): void {
        if (setAsUserReq.awaiting || !setAsUserReq.tried)
            return

        if (!setAsUserReq.success || !setAsUserReq.returnData) {
            if (setAsUserReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', setAsUserReq.returnData, setAsUserReq.error)
            return RequestUtils.showDefaultErrorNotification(
                setAsUserReq.error,
                'Erro ao tornar usuário!',
            )
        }

        ListActions.mustUpdate()
    }

    return (
        <WrapperSCP>
            {!!props.email && (
                <RowSCP style={{ justifyContent: 'space-between' }}>
                    <TextCP text={'Usuário ativo?'} />

                    <PopOverCP
                        placement={'left'}
                        visible={qGraderConfirmVisible}
                        content={
                            <ConfirmSCP>
                                Selecione as permissões de usuário:
                                <CheckboxWppSCP>
                                    {Object.keys(UserPermissionsEnum).map((permission) => (
                                        <CheckboxCP
                                            key={permission}
                                            label={PersonUtils.translatePermissions(
                                                permission as UserPermissionsEnum,
                                            )}
                                            isChecked={permissions.includes(
                                                permission as UserPermissionsEnum,
                                            )}
                                            onChange={(chkd) => {
                                                if (chkd) {
                                                    setPermissions([
                                                        ...permissions,
                                                        permission as UserPermissionsEnum,
                                                    ])
                                                } else {
                                                    setPermissions(
                                                        permissions.filter(
                                                            (pr) => pr !== permission,
                                                        ),
                                                    )
                                                }
                                            }}
                                        />
                                    ))}
                                </CheckboxWppSCP>
                                <ButtonWrppSCP>
                                    <ButtonCP
                                        onClick={async () =>
                                            await setAsUserReq.runRequest(
                                                PersonRequests.setAsUser(props.personCode, {
                                                    isQGrader: false,
                                                }),
                                        )}
                                        type={'primary'}
                                    >
                                        Salvar
                                    </ButtonCP>
                                </ButtonWrppSCP>
                            </ConfirmSCP>
                        }
                    >
                        <SwitchWrapperSCP
                            onClick={
                                !props.activeUser
                                    ? () => setQGraderConfirmVisible(!qGraderConfirmVisible)
                                    : () => {}
                            }
                        >
                            <SwitchCP
                                isChecked={props.activeUser}
                                isTextInside={true}
                                textInsideChecked={'Sim'}
                                textInsideUnchecked={'Não'}
                                loading={setAsUserReq.awaiting}
                                disabled={props.activeUser}
                            />
                        </SwitchWrapperSCP>
                    </PopOverCP>
                </RowSCP>
            )}
            <RowSCP>
                <TextCP text={'Telefone: '} />
                <TextCP text={`${MaskUtils.applyPhoneMask(props.phone)}`} style={valueStyle} />
            </RowSCP>
            {!!props.email && (
                <RowSCP>
                    <TextCP text={'E-mail: '} />
                    <TextCP text={`${props.email}`} style={valueStyle} />
                </RowSCP>
            )}
            {!!props.cpf && (
                <RowSCP>
                    <TextCP text={'CPF: '} />
                    <TextCP text={`${MaskUtils.applyCpfCnpjMask(props.cpf)}`} style={valueStyle} />
                </RowSCP>
            )}
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 0 10px;
    min-height: 93px;
`
const RowSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.lightGray};
    margin-bottom: 5px;
    align-items: center;
    .ant-typography {
        font-size: 13px;
    }
`
const ConfirmSCP = styled.div`
    display: flex;
    flex-direction: column;
`

const ButtonWrppSCP = styled.div`
    margin-top: 3px;
    display: flex;
    justify-content: flex-end;
    .ant-btn {
        padding: 5px;
        margin-left: 8px;
        &:hover {
            text-decoration: underline;
        }
        &.ant-btn-primary {
            color: ${(props) => props.theme.primaryColor};
            background: transparent;
            font-weight: 600;
            &:hover {
                background: transparent;
            }
        }
        &.ant-btn-ghost {
            border: none;
            color: ${(props) => props.theme.gray};
        }
    }
`

const SwitchWrapperSCP = styled.div``

const CheckboxWppSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    row-gap: 5px;
    margin-top: 2em;
`
