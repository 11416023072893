import { faWarehouse, faLeaf, faImages, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { DropdownButtonCP, DropdownButtonOptionTP } from 'common/components/button/DropdownButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { FieldDrawerCP } from 'modules/field/components/field-drawer/FieldDrawerCP'
import { IPropertyResponseDTO } from 'modules/property/services/dtos/responses/IPropertyResponseDTO'
import React, { CSSProperties, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { PropertyDrawerCP } from '../../property-drawer/PropertyDrawerCP'
import { FileTP, PropertyFileUploadDrawerCP } from '../../property-file-upload-drawer/PropertyFileUploadDrawerCP'

interface IPropertyActionsDropdownButtonCPProps {
    setActiveKey: (key: string) => void
    setShouldReload: (fire: number) => void
    activeKey: string
    style?: CSSProperties
}

export function PropertyActionsDropdownButtonCP(props: IPropertyActionsDropdownButtonCPProps): JSX.Element {
    const property: IPropertyResponseDTO = useSelector((state: IReduxState) => state.property)
    const [editProperty, setEditProperty] = useState<IPropertyResponseDTO | undefined>(property)
    const [showPropertyForm, setShowPropertyForm] = useState(false)
    const [showNewField, setShowNewField] = useState(false)
    const [showNewFile, setShowNewFile] = useState(false)
    const [fileType, setFileType] = useState<FileTP>()

    function getOptions(): DropdownButtonOptionTP[] {
        const defaultOptions = [
            {
                action: () => {
                    setEditProperty(property)
                    setShowPropertyForm(true)

                },
                key: 'description',
                icon: <FontAwsomeIconCP icon={faWarehouse} />,
                label: 'Editar propriedade'
            },
            {
                action: () => {
                    setShowNewField(true)
                    props.setActiveKey('fields')
                },
                key: 'fields',
                icon: <FontAwsomeIconCP icon={faLeaf} />,
                label: 'Novo talhão'
            },
            {
                action: () => {
                    setFileType('pictures')
                    props.setActiveKey('photos')
                    setShowNewFile(true)
                },
                key: 'photos',
                icon: <FontAwsomeIconCP icon={faImages} />,
                label: 'Carregar fotos'
            },
            {
                action: () => {
                    setFileType('documents')
                    props.setActiveKey('documents')
                    setShowNewFile(true)
                },
                key: 'documents',
                icon: <FontAwsomeIconCP icon={faFileAlt} />,
                label: 'Carregar documentos'
            },
        ]

        const mainOpt = defaultOptions.find(op => op.key === props.activeKey)

        if (!mainOpt)
            return [{ label: 'erro', action: () => console.log('Not Found Key'), key: 'not_found_key' }]

        const currentIndex = _.findIndex(defaultOptions, { key: props.activeKey })
        defaultOptions.splice(currentIndex, 1, defaultOptions[0])
        return _.uniq([mainOpt, ...defaultOptions])

    }

    return (
        <WrapperSCP style={props.style}>
            <DropdownButtonCP
                options={getOptions()}
            />
            <PropertyDrawerCP
                property={editProperty}
                onSuccess={() => setShowPropertyForm(false)}
                visible={showPropertyForm}
                onClose={() => setShowPropertyForm(false)}
            />

            <FieldDrawerCP
                visible={showNewField}
                onClose={() => setShowNewField(false)}
                propertyCode={property.code}
                reloadList={() => props.setShouldReload(Date.now())}
            />
            <PropertyFileUploadDrawerCP
                open={showNewFile}
                type={fileType!}
                closeDrawer={() => setShowNewFile(false)}
            />
        </WrapperSCP>
    )
}

const ActionSCP = styled.div`
       display: flex;
      color: ${props => props.theme.primaryColor};
`

const WrapperSCP = styled.div`

`