import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'

import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface ILogbookEntriesListCPProps<T> {
    loading?: boolean
    source: T[]
    style?: CSSProperties
    renderItem: (item: T, index: number) => JSX.Element
}

export function LogbookEntriesListCP<T>(props: ILogbookEntriesListCPProps<T>): JSX.Element {

    return (
        <WrapperSCP style={props.style}>
            <ListCP<T>
                bordered={false}
                wrapperStyle={props.style}
                itemLayout={'vertical'}
                size={'large'}
                loadMoreData={() => { }} dataSource={props.source}
                hasStateControl={true}
                locale={{
                    emptyText: <EmptyCP description={'Você ainda não tem registros.'} />
                }}
                renderItem={props.renderItem}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
   background: white;
   border-radius: 8px;
    margin-bottom: 8em;
    max-height: 8000vh;
    overflow: hidden;
  

    @media(max-width: 767px ){
        overflow: hidden;
        height: auto;
        max-height: 10000em;
        overflow: hidden; 
    }
   
   
    .ant-list-item{
        padding: 20px;
        margin-bottom: 2em;
    }
    .ant-spin-nested-loading{
        overflow: hidden;
    }
`
