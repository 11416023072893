/* eslint-disable @typescript-eslint/no-floating-promises */
import { Row, Skeleton } from 'antd'
import { AlertCP } from 'common/components/alert/AlertCP'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaskUtils } from 'common/utils/MaskUtils'

import { IFormulaAplicationDataResDTO } from 'modules/maneuver/services/dtos/responses/IFormulaAplicationDataResDTO'
import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'
import { RecipeRequests } from 'modules/maneuver/services/RecipeRequests'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IImplementUsabilityCardCPProps {
    recipe: IRecipeResponseDTO
    implementCode: number
    fieldCode?: number
}

export function ImplementUsabilityCardCP(props: IImplementUsabilityCardCPProps): JSX.Element {
    const [aplicationData, setAplicationData] = useState<IFormulaAplicationDataResDTO>()

    useEffect(getaplicationData, [props.implementCode])

    const request = useRequest<IFormulaAplicationDataResDTO>()
    useEffect(onRequestChange, [request.awaiting])

    function getaplicationData(): void {
        if (!!props.implementCode && !!props.recipe && !!props.fieldCode) {
            request.runRequest(RecipeRequests.getFormulaAplicationData(props.recipe.code, {
                implementCode: props.implementCode,
                fieldCode: props.fieldCode
            }))
        }
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried) {
            return
        }

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED) {
                console.error('ERROR: ', request.returnData, request.error)
            }
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar dados!')
        }
        setAplicationData(request.returnData)

    }

    if (!!request.error) {
        return (
            <Skeleton active={true} paragraph={{ rows: 1 }} loading={request.awaiting || !aplicationData}>
                <AlertCP
                    type={'warning'}
                    showIcon={true}
                    message={RequestUtils.getErrorNotificationTxt(request.error, 'Ocorreu um erro. Confira os dados de capacidade do implemento e litragem da mistura.')}
                />
            </Skeleton>
        )
    }

    return (

        <MainWrapperSCP>
            <CardWrapperSCP>
                <Skeleton active={true} paragraph={{ rows: 1 }} loading={request.awaiting || !aplicationData}>
                    {
                        !!aplicationData &&
                        <ContentSCP>
                            <Row>
                                <TextCP strong={true} text={'INFORMAÇÕES GERAIS:\u00A0'} style={{ marginBottom: '10px' }} />
                            </Row>
                            {
                                !!props.recipe.mixTotalLiters &&
                                <Row>
                                    <TextCP strong={true} text={'Litragem total da mistura:\u00A0'} />
                                    <TextCP text={` ${MaskUtils.applyNumberMask(props.recipe.mixTotalLiters)} litros/ha`} />
                                </Row>
                            }
                            <Row>
                                <TextCP strong={true} text={'Capacidade do implemento:\u00A0'} />
                                <TextCP text={` ${MaskUtils.applyNumberMask(aplicationData.implementCapacity)} litros `} />
                                {
                                    !!aplicationData.implementSolidCapacity &&
                                    <TextCP text={`/ ${MaskUtils.applyNumberMask(aplicationData.implementSolidCapacity)} kg`} />
                                }
                            </Row>
                            <Row>
                                <TextCP strong={true} text={'Litragem total a ser aplicada:\u00A0'} />
                                <TextCP
                                    text={` ${MaskUtils.applyNumberMask(aplicationData.totalApplicationLiters)} litros`}
                                />
                            </Row>

                            <Row>
                                <TextCP strong={true} text={'Quantidade total de produto:\u00A0'} />
                            </Row>
                            {
                                <PercentageWrapperSCP>
                                    {aplicationData.productUsagePreview.map(usage => (
                                        <Row key={usage.productName}>
                                            <TextCP text={`${usage.productName}:\u00A0`} />
                                            {/* <TextCP
                                                text={`${MaskUtils.applyNumberMask(usage.totalQuantity)} ${ProductUtils.getUnityLabel(usage.isLiquid)}/ha (Estimativa de gasto ${MaskUtils.applyNumberMask(usage.totalQuantity * props.recipe.field?.totalArea!)}${ProductUtils.getUnityLabel(usage.isLiquid)}) `}
                                            /> */}
                                        </Row>
                                    ))}
                                </PercentageWrapperSCP>

                            }

                        </ContentSCP>
                    }
                </Skeleton>
            </CardWrapperSCP>

            <CardWrapperSCP>
                <Skeleton active={true} paragraph={{ rows: 1 }} loading={request.awaiting || !aplicationData}>
                    {
                        !!aplicationData &&
                        <ContentSCP>
                            <Row>
                                <TextCP strong={true} text={'DADOS PARA A APLICAÇÃO:\u00A0'} style={{ marginBottom: '10px' }} />
                            </Row>

                            <Row>
                                <TextCP strong={true} text={'Litragem por aplicação:\u00A0'} />
                                <TextCP
                                    text={` ${MaskUtils.applyNumberMask(aplicationData.litersPerApplication)} litros`}
                                />
                            </Row>
                            <Row>
                                <TextCP strong={true} text={'Aplicação por metro linear:\u00A0'} />
                                <TextCP
                                    text={` ${MaskUtils.applyNumberMask(aplicationData.linearMetersApplication)} litros`}
                                />
                            </Row>
                            <Row>
                                <TextCP strong={true} text={'Quantidade de produto:\u00A0'} />
                            </Row>
                            {
                                <PercentageWrapperSCP>
                                    {aplicationData.productUsagePreview.map(usage => (
                                        <Row key={usage.productName}>
                                            <TextCP text={`${usage.productName}:\u00A0`} />
                                            <TextCP
                                                text={`${MaskUtils.applyNumberMask(usage.quantity)} ${ProductUtils.getUnityLabel(usage.isLiquid)}`} />
                                        </Row>
                                    ))}
                                </PercentageWrapperSCP>

                            }
                            <InfoWrapperSCP>
                                <TextCP
                                    text={`${+aplicationData.implementApplications > 1 ? 'Serão necessárias ' : 'Será necessária'} ${MaskUtils.applyNumberMask(aplicationData.implementApplications)} ${+aplicationData.implementApplications > 1 ? 'aplicações' : 'aplicação'} para esta atividade`}
                                />
                            </InfoWrapperSCP>
                        </ContentSCP>
                    }
                </Skeleton>
            </CardWrapperSCP>
        </MainWrapperSCP>

    )
}

const MainWrapperSCP = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

const CardWrapperSCP = styled.div`
  display: flex;
  margin: 15px 0;
  width: 100%;
  box-shadow: 0px 9px 18px -4px rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  padding: 10px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    border-radius: 0 5px 5px 0;
    background: ${props => props.theme.primaryColor}
  }
`

const ContentSCP = styled.div`
  width: 100%;

  .ant-typography {
    font-size: 12px;
  }
`

const InfoWrapperSCP = styled.div`
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  background: #d3f5d2;
  border-radius: 5px;

  .ant-typography {
    font-size: 12px;
  }
`

const PercentageWrapperSCP = styled.div`

  padding: 5px;
  width: 100%;
  border-radius: 5px;

  .ant-typography {
    font-size: 12px;
  }
`
