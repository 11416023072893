import React from 'react'
import styled from 'styled-components'
import { TitleCP } from 'common/components/title/TitleCP'
import ReadMoreReact from 'read-more-react'

interface IDescriptionSectionCPProps {
    title?: string
    subTitle?: string
    upperTitle?: string
    description?: string
    showReadMore?: boolean
    minDescriptionLength?: number
    maxDescriptionLength?: number
    idealDescriptionLength?: number
    extra?: JSX.Element
    mainColor?: string
}

/**
 * COMPONENTE
 * Wrapper genérico para exibição de uma seção de descrição
 * @author kiaravzm
 * @todo criar wrapper generico para componente ReadMore com tratamento para limites de texto
 */
export function DescriptionSectionCP(props: IDescriptionSectionCPProps): JSX.Element {

    return (
        <SectionWrapperSCP>
            <HeaderWrapperSCP>
                <TitleCP
                    textSize={12}
                    weight={400}
                    style={{ margin: 0, fontStyle: 'italic', color: props.mainColor }}
                >
                    {props.upperTitle}

                </TitleCP>
                <TitleCP
                    textSize={20}
                    weight={600}
                    style={{ margin: 0, color: props.mainColor }}
                >
                    {props.title}
                </TitleCP>
                <TitleCP
                    style={{ margin: 0, color: props.mainColor }}
                >
                    {props.subTitle}

                </TitleCP>
            </HeaderWrapperSCP>

            {props.extra}
            <DescriptionWrapperSCP style={{ color: props.mainColor }}>
                {
                    props.showReadMore ?
                        <ReadMoreReact
                            text={props.description}
                            min={props.minDescriptionLength ?? 80}
                            ideal={props.idealDescriptionLength ?? 100}
                            max={props.maxDescriptionLength ?? 200}
                            readMoreText={'Read more ->'}
                        />
                        :
                        <span>{props.description}</span>
                }

            </DescriptionWrapperSCP>
        </SectionWrapperSCP>
    )
}
const SectionWrapperSCP = styled.div`
    margin: 10px 0;
`
const HeaderWrapperSCP = styled.div`
    margin: 10px 0;
`
const DescriptionWrapperSCP = styled.div`
    text-align: justify;
`
