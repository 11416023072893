import { faPenAlt } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import styled from 'styled-components'
import { useScreenSize } from '../../../../../common/hooks/UseScreenSize'
import { IPropertyResponseDTO } from '../../../services/dtos/responses/IPropertyResponseDTO'
import { TextCP } from 'common/components/text/TextCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { SystemConfig } from 'config/SystemConfig'

interface IPropertyDetailsHeaderICPProps {
    property: IPropertyResponseDTO
    editInfo: () => void
}

export function PropertyDetailsHeaderICP(props: IPropertyDetailsHeaderICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const placeholder = `${SystemConfig.getInstance().uiBaseUrl}/assets/farm-placeholder.png`
    return (
        <WrapperSCP>
            <MainImageSCP mainImage={props.property.mainPicture ?? placeholder} isMobile={screenSize.smd} />
            <DetailsWrapperSCP>
                <ContentWrapperSCP>
                    <TitleWrapperSCP>
                        <TextCP text={props.property.name} size={'extraLarge'} strong={true} />
                        <TextCP text={props.property.city} size={'large'} />
                        <FontAwsomeIconCP icon={faPenAlt} style={{ position: 'absolute', right: 0, top: '0.5em' }} tooltip={'Editar dados'} onClick={props.editInfo} />
                    </TitleWrapperSCP>
                    <OtherInfoSCP>
                        <TextCP text={`Área total: ${props.property.totalArea} ha`} size={'small'} />
                        <TextCP text={`Área dedicada: ${props.property.dedicatedArea} ha`} size={'small'} />
                    </OtherInfoSCP>
                    {/* <OtherInfoSCP>
                        <TextCP text={`IE: ${props.property.stateRegistration}`} size={'small'} />
                        <TextCP text={`Região: ${props.property.region}`} size={'small'} />
                    </OtherInfoSCP> */}

                </ContentWrapperSCP>
            </DetailsWrapperSCP>
        </WrapperSCP>

    )
}

const MainImageSCP = styled.div<{ mainImage?: string, isMobile?: boolean }>`
    position: relative;
    border-radius: 10px 10px 0 0;
    background: url(${(props) => (props.mainImage ? props.mainImage : '#cecece')});
    background-position: center;
    background-size: cover;
    display: flex;
    height: 180px;;
    justify-content: center;
    align-items: ${(props) => (props.isMobile ? 'center' : 'flex-end')};
    @media(max-width: 767px){
        height: 250px;
    }
`

const WrapperSCP = styled.div`
    background: #fff;
  
`
const TitleWrapperSCP = styled.div`

`
const OtherInfoSCP = styled.div`
    margin-top: 5px;
    display: grid;
    width: 300px;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`
const DetailsWrapperSCP = styled.div`
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
   
    @media(max-width: 768px){
           flex-direction: column;
           padding: 10px  25px 6em 25px;
    }
`

const AreaInfo = styled.div`
      display: grid;
      grid-template-columns: 1fr 2fr;

      svg{
        font-size: 20px;
     }
`
const TextWrapperSCP = styled.div`
        display: grid;
        grid-template-rows: 1fr 1fr;
        .ant-typography{
            color: ${props => props.theme.gray};
            font-size: 12px;
        }
`
const ContentWrapperSCP = styled.div`
  position:relative;
`
const LeftContentSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1em;
    column-gap: 10px;
`