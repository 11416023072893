import { LogoCP } from 'common/components/logo/LogoCP'
import { ProducerCardICP } from 'modules/batch-presentation/components/producer-carrousel/inner/ProducerCardICP'
import { IBatchBasicInfoResDTO } from 'modules/batch-presentation/services/dtos/response/BatchBasicInfoResDTO'
import React from 'react'
import styled from 'styled-components'
import { BatchDetailsIconGridCP } from 'modules/batch-presentation/components/batch-details-icon-grid/BatchDetailsIconGridCP'
interface IBatchBasicInfoCPProps {
    resume: IBatchBasicInfoResDTO
}

export function BatchBasicInfoCP(props: IBatchBasicInfoCPProps): JSX.Element {

    return (
        <WrapperSCP>

            <LogoCP inverse={true} style={{ maxWidth: '180px' }} />
            <ProducersSCP>
                {
                    props.resume.producer.map(prod => (
                        <ProducerCardICP
                            key={prod.code}
                            name={prod.name}
                            photo={prod.photo}
                        />
                    ))
                }
            </ProducersSCP>
            <BatchDetailsIconGridCP
                code={props.resume.code}
                methods={props.resume.methods}
                bags={props.resume.totalBags}
                varieties={props.resume.varieties}
                processMethods={props.resume.processMethods}
                harvestTypes={props.resume.harvestTypes}
                structures={props.resume.structures}
                postHarvestDays={props.resume.postHarvestDays}
                averageScore={props.resume.averageScore}
                altitude={props.resume.averageAltitude}
            />


        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`
const SpecificationsSCP = styled.div`
    row-gap: 4px;
    display: flex;
    flex-direction: column;
    .ant-typography{
        color: white;
        font-size: 16px;
    }

`
const ProducersSCP = styled.div`
    display: flex;
    margin-bottom: 2em;
    column-gap: 30px;
    margin-top: 2em;
`
const RowSCP = styled.div`
  display: flex;
  column-gap: 3px;
  flex-wrap: wrap;
`

const DescSliderSCP = styled.div`
`
const MainSCP = styled.div`
     display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    margin: 10px 0;
    align-items: center;
    @media(max-width: 768px){
        grid-template-columns: 1fr ;
    }
`