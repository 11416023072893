/* eslint-disable @typescript-eslint/interface-name-prefix */
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { SchemaDataTP } from 'common/redux/ReduxTypes'
import { ThemeConfig } from 'config/ThemeConfig'
import _ from 'lodash'
import React, { Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { SchemaPublicRouter } from './SchemaPublicRouter'
declare global {
    interface Window {
        less: any
    }
}
export function SchemaRouter(props: { basename: string }): JSX.Element | null {
    const schemaData: SchemaDataTP | null = useSelector((state: IReduxState) => _.get(state, 'schemaData'))
    const [theme, setTheme] = useState<any>()
    const [themeLoaded, setThemeLoaded] = useState(false)

    useEffect(() => { defineSchemaTheme() }, [schemaData])
    async function defineSchemaTheme(): Promise<void> {
        if (!!schemaData?.color) {
            const config = {
                ...ThemeConfig,
                primaryColor: schemaData.color,
                tooltipBg: schemaData.color,
                textSelectionBg: schemaData.color,
                borderColorBase: schemaData.color,
                inputHoverBorderColor: schemaData.color,
                btnPrimaryBg: schemaData.color,
                primary6: schemaData.color,
                linkColor: schemaData.color
            }
            await window.less.modifyVars(formatedTheme(config)).catch(error => { console.log(error) })
            setTheme(config)
        } else
            setTheme(ThemeConfig)

        setThemeLoaded(true)
    }

    function formatedTheme(_theme: any): any {
        const varNames = Object.keys(_theme)
        const lessVars = {}
        varNames.forEach(name => {
            const nameInKebabCase = name.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase() // transforma o nome da variável para kebab-case
            lessVars[`@${nameInKebabCase}`] = _theme[name]
        })
        return lessVars
    }

    if (!schemaData)
        return null

    return (

        <Suspense fallback={'loading'}>
            {
                !!themeLoaded && !!theme &&
                <ThemeProvider theme={theme}>
                    <BrowserRouter basename={props.basename}>
                        <SchemaPublicRouter />
                    </BrowserRouter>
                </ThemeProvider>
            }
        </Suspense>

    )
}

