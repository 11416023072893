import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IPersonResponseDTO } from 'modules/person/services/dtos/responses/IPersonResponseDTO'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import React, { useEffect, useState } from 'react'

interface IPersonSelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    label?: string
    value?: number
    onSelect?: (value: number) => void
    loading?: boolean
    required?: boolean
    isMultiple?: boolean
    personList?: IPersonResponseDTO[]
}

export function PersonSelectorCP<FModelTP extends FormModelValidator = any>(props: IPersonSelectorCPProps<FModelTP>): JSX.Element {
    const [personOptions, setPersonOptions] = useState<SelectOptionTP[]>([])
    useEffect(getPersonList, [props.personList])

    const request = useRequest<IGenericListResponseDTO<IPersonResponseDTO>>(PersonRequests.getPersonList({}))
    useEffect(onRequestChange, [request.awaiting])

    function getPersonList(): void {

        if (!!props.personList)
            return setPersonOptions(props.personList.map(product => ({ label: product.name, value: product.code })))
        request.runRequest()

    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar lista de colaboradores!')
        }

        const options = request.returnData.list.map(product => ({ label: product.name, value: product.code }))
        setPersonOptions(options)

    }

    return (
        <SelectCP
            options={personOptions}
            label={props.label}
            loading={request.awaiting || props.loading}
            placeholder={'Responsável'}
            required={props.required}
            fieldName={'personCode'}
            formStateManager={props.formStateManager}
            onChange={props.onSelect}
            value={props.value}
            isMultiple={props.isMultiple}
            disabled={request.awaiting}
        />
    )
}