import { faMedal } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CoffeeBeansIcon } from 'common/components/icons/CoffeeBeans'
import { GenericCardWidgetCP } from 'common/components/widgets/GenericCardWidgetCP'
import { WidgetGenericValueICP } from 'common/components/widgets/inner/WidgetGenericValueICP'
import { MaskUtils } from 'common/utils/MaskUtils'
import React from 'react'
import styled from 'styled-components'

interface IAverageScoresReportCardsICPProps {
    averageScore?: number
    averagePicking?: number
    loading?: boolean
}
export function AverageScoresReportCardsICP(props: IAverageScoresReportCardsICPProps): JSX.Element {

    return (
        <>
            <GenericCardWidgetCP
                gridArea={'sensory-average'}
                title={'Pontuação média'}
                subtitle={'Entre os lotes especiais'}
                icon={<FontAwsomeIconCP icon={faMedal} />}
                content={
                    <WidgetGenericValueICP
                        value={!!props.averageScore ? `${MaskUtils.applyNumberMask(props.averageScore)}` : undefined}
                        label={'pontos'}
                        loading={props.loading}
                    />
                }

            />
            <GenericCardWidgetCP
                gridArea={'physical-average'}
                title={'Catação média'}
                icon={<CoffeeBeansIcon width={22} />}
                content={
                    <WidgetGenericValueICP
                        value={!!props.averagePicking ? `${MaskUtils.applyNumberMask(props.averagePicking)}` : undefined}
                        label={'%'}
                        loading={props.loading}
                    />
                }
            />

        </>
    )
}

const WrapperSCP = styled.div`
`
