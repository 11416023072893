import styled from 'styled-components'
import React from 'react'
import { RadioGroupCP, RadioOptionProps } from 'common/components/radio-group/RadioGroupCP'
import { PriorityEnum } from 'modules/maneuver/enums/PriorityEnum'
import { ManeuverUtils } from 'modules/maneuver/utils/ManeuverUtils'

interface IPrioritySelectorCPProps {
    onChangepriority: (priority: PriorityEnum) => void
    priority?: PriorityEnum
}

export function PrioritySelectorCP(props: IPrioritySelectorCPProps): JSX.Element {

    function getOptions(): RadioOptionProps[] {
        return Object.values(PriorityEnum).map(priority => ({
            value: priority,
            content: ManeuverUtils.translatePriority(priority)
        }))
    }

    return (
        <WrapperSCP>
            <RadioGroupCP
                type={'button'}
                options={getOptions()}
                selected={props.priority}
                onChange={props.onChangepriority}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

