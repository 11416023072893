import { faDiagnoses, faRainbow } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CoffeeBeansIcon } from 'common/components/icons/CoffeeBeans'
import { BrewMethodEnum } from 'modules/analysis/enums/BrewMethodEnum'
import { CoffeeFlavorEnum } from 'modules/analysis/enums/CoffeeFlavorEnum'
import { FeatureCardICP } from 'modules/batch-presentation/components/analysis-carrousel/inner/FeatureCardICP'
import React from 'react'
import styled from 'styled-components'

interface ICoffeeFeaturesICPProps {
    brewMethods?: BrewMethodEnum[]
    brewMethodsOther?: string
    aromas?: CoffeeFlavorEnum[]
    aromasOther?: string
    flavors?: CoffeeFlavorEnum[]
    flavorsOther?: string
}

export function CoffeeFeaturesICP(props: ICoffeeFeaturesICPProps): JSX.Element {

    return (
        <WrapperSCP>
            {
                !!props.aromas && !!props.aromas.length &&
                <FeatureCardICP
                    icon={<FontAwsomeIconCP icon={faDiagnoses} color={'white'} size={'2x'} />}
                    title={'aroma.plur'}
                    options={props.aromas}
                    others={props.aromasOther}
                />
            }

            {
                !!props.flavors && props.flavors.length &&
                <FeatureCardICP
                    icon={<FontAwsomeIconCP icon={faRainbow} color={'white'} size={'2x'} />}
                    title={'flavor.plur'}
                    options={props.flavors}
                    others={props.flavorsOther}
                />
            }
            {
                !!props.brewMethods && props.brewMethods.length &&
                <FeatureCardICP
                    icon={<CoffeeBeansIcon width={22} />}
                    title={'brewMethods'}
                    options={props.brewMethods}
                    others={props.brewMethodsOther}
                />
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    column-gap: 0px;    
    margin: 2em 0;
    align-items: center;
    justify-content: center;
    @media(max-width: 768px){
       flex-direction: column;     
       margin-bottom: 3em;
       align-items: center;
    }
`
