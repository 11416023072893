import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/RequestManagerTypes'
import { SystemConfig } from 'config/SystemConfig'
import { ISavePropertyRequestDTO } from './dtos/requests/ISavePropertyRequestDTO'

export class PropertyRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/properties`
    private constructor() { }

    static search = (): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}`,
        method: HttpMethodEnum.GET,
    })

    static searchNames = (): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/names`,
        method: HttpMethodEnum.GET,
    })

    static searchDocuments = (code: number): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/${code}/documents`,
        method: HttpMethodEnum.GET,
    })

    static searchPictures = (code: number): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/${code}/pictures`,
        method: HttpMethodEnum.GET,
    })

    static getPictures = (codes: number[]): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/pictures`,
        method: HttpMethodEnum.GET,
        params: { codes }
    })

    static save = (dto: ISavePropertyRequestDTO): RequestConfigTP<ISavePropertyRequestDTO> => ({
        url: `${PropertyRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static update = (code: number, dto: ISavePropertyRequestDTO): RequestConfigTP<ISavePropertyRequestDTO> => ({
        url: `${PropertyRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static get = (code: number): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static updateDescription = (code: number, description: string): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/${code}/description`,
        method: HttpMethodEnum.PUT,
        params: { description },
    })

    static updateTranlatedDescription = (code: number, descriptionEng: string): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/${code}/translated-description`,
        method: HttpMethodEnum.PUT,
        params: { descriptionEng },
    })

    static updateMainImage = (code: number, data: FormData): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/${code}/main-picture`,
        method: HttpMethodEnum.PUT,
        params: data,
        headers: { 'content-type': 'multipart/form-data' }
    })

    static addImages = (code: number, type: 'documents' | 'pictures', data: FormData): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/${code}/${type}`,
        method: HttpMethodEnum.PUT,
        params: data,
        headers: { 'content-type': 'multipart/form-data' }
    })

    static removeImages = (code: number, dto: { files: string[] }): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/${code}/pictures`,
        method: HttpMethodEnum.DELETE,
        params: dto,
    })

    static removeDocuments = (code: number, dto: { files: string[] }): RequestConfigTP => ({
        url: `${PropertyRequests.baseApiUrl}/${code}/documents`,
        method: HttpMethodEnum.DELETE,
        params: dto,
    })
}
