/* eslint-disable @typescript-eslint/no-floating-promises */
import moment from 'moment'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { DateInput } from 'common/components/form-inputs/DateInput'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { ISavePurchaseRequestDTO } from 'modules/maneuver/services/dtos/requests/ISavePurchaseRequestDTO'
import { ISearchPurchaseRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchPurchaseRequestDTO'

import React, { useEffect, useState } from 'react'
import { IPurchaseResponseDTO } from '../../services/dtos/responses/IPurchaseResponseDTO'
import { ProductSelectorCP } from '../product-selector/ProductSelectorCP'
import { PurchaseFormValidator } from './inner/PurchaseFormValidator'
import { MaskUtils } from 'common/utils/MaskUtils'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import styled from 'styled-components'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'
import { ProductRequests } from 'modules/maneuver/services/ProductRequests'
import { ReceiptFileUploaderCP } from 'modules/maneuver/components/receipt-file-uploader/ReceiptFileUploaderCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { CheckboxCP } from 'common/components/form-fields/checkbox/CheckboxCP'
import { DateUtils } from 'common/utils/DateUtils'

interface IPurchaseDrawerPProps {
    productCode?: number
    unitOfMeasurement?: UnitOfMeasurementEnum
    purchaseCode?: number
    show: boolean
    onCancel: () => void
    onSuccess: () => void
}

export function PurchaseDrawerCP(props: IPurchaseDrawerPProps): JSX.Element {
    const screenSize = useScreenSize()

    const [formValidator, setFormValidator] = useState<PurchaseFormValidator>(new PurchaseFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const [purchaseCode, setPurchaseCode] = useState<number | undefined>(props.purchaseCode)
    const [unitOfMeasurement, setUnitOfMeasurement] = useState<UnitOfMeasurementEnum>()
    const [shouldUploadImages, setShouldUploadImages] = useState<number>()
    const [isPaid, setIsPaid] = useState<boolean>(false)

    useEffect(getPurchaseDetails, [props.purchaseCode, props.show])

    const getPurchaseRequest = useRequest<IPurchaseResponseDTO, ISearchPurchaseRequestDTO>()
    useEffect(onGetDetailsReqChange, [getPurchaseRequest.awaiting])

    const request = useRequest<IPurchaseResponseDTO, ISavePurchaseRequestDTO>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(() => {
        setUnitOfMeasurement(props.unitOfMeasurement)
    }, [props.unitOfMeasurement])

    function getPurchaseDetails(): void {
        if (!props.show || !props.purchaseCode) {
            return
        }
        if (!!props.productCode) {
            setUnitOfMeasurement(props.unitOfMeasurement)
            getPurchaseRequest.runRequest(ProductRequests.getPurchase(props.productCode, props.purchaseCode))
        } else {
            setFormValidator(new PurchaseFormValidator())
        }
    }

    function onGetDetailsReqChange(): void {
        if (getPurchaseRequest.awaiting || !getPurchaseRequest.tried) {
            return
        }

        if (!getPurchaseRequest.success || !getPurchaseRequest.returnData) {
            if (getPurchaseRequest.status !== HttpStatusEnum.UNAUTHORIZED) {
                console.error('ERROR: ', getPurchaseRequest.returnData, getPurchaseRequest.error)
            }
            return RequestUtils.showDefaultErrorNotification(getPurchaseRequest.error, 'Erro ao buscar compra!')
        }

        setFormValidator(new PurchaseFormValidator(getPurchaseRequest.returnData))
        formStateManager.changeFieldValue('productCode', props.productCode)
        setPurchaseCode(getPurchaseRequest.returnData.code)
        setIsPaid(getPurchaseRequest.returnData.isPaid)
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried) {
            return
        }

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED) {
                console.error('ERROR: ', request.returnData, request.error)
            }
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar compra!')
        }

        setPurchaseCode(request.returnData.code)
        setShouldUploadImages(Date.now())
    }

    async function onFormSubmit(): Promise<void> {

        if (props.productCode) {
            formStateManager.changeFieldValue('productCode', props.productCode)
        }

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues) {
            return
        }

        const dto: ISavePurchaseRequestDTO = {
            date: DateUtils.getDateFormattedToApi(formValues.date),
            quantity:
                +(`${formValues.quantity}`).replace(',', '.'),

            paidValue: +(`${formValues.paidValue}`.replace(',', '.')
                .replace(/[^\d.-]/g, '')),
            season: formValues.season,
            isPaid,
            paymentDate: formValues.paymentDate ? DateUtils.getDateFormattedToApi(formValues.paymentDate) : undefined,
            notes: formValues.notes,
        }

        request.runRequest(props.purchaseCode ?
            ProductRequests.updatePurchase(formValues.productCode, props.purchaseCode, dto) :
            ProductRequests.savePurchase(formValues.productCode, dto))
    }

    function onUploadDone(): void {
        NotificationHelper.success('Compra salva com sucesso!')
        ListActions.mustUpdate()
        formStateManager.reset()
        props.onSuccess()
    }

    return (
        <DrawerCP
            title={props.purchaseCode ? `Editar compra #${props.purchaseCode}` : 'Nova compra'}
            visible={props.show}
            width={'40%'}
            onClose={() => {
                formStateManager.reset()
                props.onCancel()
            }}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'primary'}
                        isSubmit={true}
                        wrapperStyle={{ width: '100%' }}
                        loading={request.awaiting}
                        onClick={onFormSubmit}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >
            <FormWrapperSCP>
                <ProductSelectorCP
                    label={'Produto'}
                    onSelect={(prdCode, il) => {
                        formStateManager.changeFieldValue('productCode', prdCode)
                        setUnitOfMeasurement(il)
                    }}
                    loading={request.awaiting || getPurchaseRequest.awaiting}
                    value={props.productCode}
                />
                <TwoColumnsWpp>
                    <SeasonSelectorCP
                        formStateManager={formStateManager}
                        label={'Safra'}
                        loading={request.awaiting || getPurchaseRequest.awaiting}
                    />
                    <DateInput
                        label={'Data da compra'}
                        fieldName={'date'}
                        formStateManager={formStateManager}
                        required={true}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        loading={request.awaiting || getPurchaseRequest.awaiting}
                    />
                </TwoColumnsWpp>

                <TwoColumnsWpp>
                    <InputCP
                        label={'Quantidade'}
                        placeholder={'Quantidade'}
                        fieldName={'quantity'}
                        type={'number'}
                        icon={<span>{ProductUtils.getUnitOfMeasurementAbbreviation(unitOfMeasurement!)}</span>}
                        formStateManager={formStateManager}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        disabled={!formStateManager.getFieldValue('productCode') && !props.productCode}
                        loading={request.awaiting || getPurchaseRequest.awaiting}
                    />
                    <InputCP
                        label={'Valor de compra'}
                        fieldName={'paidValue'}
                        formStateManager={formStateManager}
                        required={true}
                        type={'currency'}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        loading={request.awaiting || getPurchaseRequest.awaiting}
                    />
                    <InputCP
                        label={'Data do pagamento'}
                        placeholder={'__/__/____'}
                        fieldName={'paymentDate'}
                        type={'date'}
                        formStateManager={formStateManager}
                        required={false}
                        mask={MaskUtils.applyDateMask}
                        maxLength={10}
                        loading={request.awaiting || getPurchaseRequest.awaiting}
                    />
                    <CheckboxWrapperSCP>
                        <CheckboxCP
                            isChecked={isPaid}
                            onChange={setIsPaid}
                            label={'Marcar como pago'}
                        />
                    </CheckboxWrapperSCP>
                </TwoColumnsWpp>

                <TextAreaCP
                    label={'Observação'}
                    fieldName={'notes'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    loading={request.awaiting || getPurchaseRequest.awaiting}
                />

                <ReceiptFileUploaderCP
                    type={'purchase'}
                    itemCode={props.productCode}
                    subItemCode={purchaseCode}
                    loading={request.awaiting || getPurchaseRequest.awaiting}
                    fireSaveReq={shouldUploadImages}
                    onUploadDone={onUploadDone}
                />

            </FormWrapperSCP>
        </DrawerCP>
    )
}

const FormWrapperSCP = styled.div`
  display: grid;
  row-gap: 15px;
  align-items: center;
`
const TwoColumnsWpp = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  align-items: end;
`

const ButtonWrapperSCP = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`

const CheckboxWrapperSCP = styled.div`
  align-items: start;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
`