import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ListActions } from 'common/components/list/inner/ListActions'

import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IStructureResDTO } from 'modules/batch/services/dtos/responses/IStructureResDTO'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'
import { StructureFormValidator } from 'modules/structure/components/structure-drawer/inner/StructureFormValidator'
import { StructureTypeSelectorCP } from 'modules/structure/components/structure-type-selector/StructureTypeSelectorCP'
import { ISaveStructureReqDTO } from 'modules/structure/services/dtos/request/ISaveStructureReqDTO'
import { StructureRequests } from 'modules/structure/services/StructureRequests'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface IStructureDrawerCPProps {
    structureCode?: number
    show: boolean
    onCancel: () => void
    onSuccess: () => void
}

export function StructureDrawerCP(props: IStructureDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const history = useHistory()

    const [fieldCode, setFieldCode] = useState<number>()
    const [formValidator, setFormValidator] = useState<StructureFormValidator>(new StructureFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const saveRequest = useRequest<IStructureResDTO>()
    useEffect(onRequestChange, [saveRequest.awaiting])

    const getDetailsReq = useRequest<IStructureResDTO>()
    useEffect(onGetDetailsReqChange, [getDetailsReq.awaiting])

    useEffect(getDetails, [props.structureCode, props.show])

    function getDetails(): void {
        if (!!props.structureCode && props.show)
            getDetailsReq.runRequest(StructureRequests.get(props.structureCode))
    }

    function onGetDetailsReqChange(): void {
        if (getDetailsReq.awaiting || !getDetailsReq.tried)
            return

        if (!getDetailsReq.success || !getDetailsReq.returnData) {
            if (getDetailsReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getDetailsReq.returnData, getDetailsReq.error)
            return RequestUtils.showDefaultErrorNotification(getDetailsReq.error, 'Ocorreu um erro ao tentar buscar os dados da estrutura. Tente novamente.')
        }

        const item: ISaveStructureReqDTO = {
            name: getDetailsReq.returnData.name,
            type: getDetailsReq.returnData.type,
            capacity: getDetailsReq.returnData.capacity,
            unitOfMeasurement: getDetailsReq.returnData.unitOfMeasurement
        }
        setFormValidator(new StructureFormValidator(item))

    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)

        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveStructureReqDTO = {
            name: formValues.name,
            type: formValues.type,
            capacity: 0,
            unitOfMeasurement: UnitOfMeasurementEnum.LITERS
        }

        saveRequest.runRequest(props.structureCode ?
            StructureRequests.update(props.structureCode, dto) :
            StructureRequests.save(dto))
    }

    function onRequestChange(): void {
        if (saveRequest.awaiting || !saveRequest.tried)
            return

        if (!saveRequest.success || !saveRequest.returnData) {
            if (saveRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveRequest.returnData, saveRequest.error)
            return RequestUtils.showDefaultErrorNotification(saveRequest.error, 'Erro ao salvar estrutura!')
        }

        NotificationHelper.success('Item salvo com sucesso!')
        formStateManager.reset()
        props.onSuccess()
        ListActions.mustUpdate()

    }

    return (
        <DrawerCP
            title={props.structureCode ? `Editar estrutura #${props.structureCode}` : 'Nova estrutura'}
            visible={props.show}
            width={'35%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFormSubmit}
                        loading={saveRequest.awaiting || getDetailsReq.awaiting}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {
                props.onCancel()
            }}
        >

            <WrapperSCP>
                <InputCP
                    label={'Nome'}
                    placeholder={'Nome'}
                    fieldName={'name'}
                    type={'text'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    loading={saveRequest.awaiting || getDetailsReq.awaiting}
                />

                <StructureTypeSelectorCP
                    formStateManager={formStateManager}
                    required={true}
                />

                {/* <TwoColWrapperSCP>
                    <InputCP
                        label={'Capacidade:'}
                        type={'number'}
                        fieldName={'capacity'}
                        formStateManager={formStateManager}
                        required={true}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        icon={<span>litros</span>}
                        loading={saveRequest.awaiting || getDetailsReq.awaiting}
                    />
                    <UnitOfMesurementSelectorCP
                        label={'Unidade de medida:'}
                        formStateManager={formStateManager}
                        required={true}
                    />
                </TwoColWrapperSCP> */}
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    row-gap: 10px;

`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`