import { IsOptional, IsString } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { HarvestTypeEnum } from 'modules/harvest/enums/HarvestTypeEnum'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'

export class HarvestFormValidator extends FormModelValidator {
    @IsOptional()
    code?: number

    @IsNotEmpty()
    type: HarvestTypeEnum

    @IsOptional()
    @IsString()
    typeOther?: string

    @IsOptional()
    @IsString()
    description?: string

    @IsNotEmpty()
    liters: number

    constructor(dto?: IHarvestResponseDTO) {

        super()
        if (dto) {
            this.code = dto.code
            this.type = dto.type
            this.typeOther = dto.typeOther
            this.description = dto.description
            this.liters = dto.liters
        }

    }
}
