import React from 'react'
import styled from 'styled-components'

interface IBatchReportContetnCPProps {
}

export function BatchReportContetnCP(props: IBatchReportContetnCPProps): JSX.Element {

    return (
        <WrapperSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
