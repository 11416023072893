import { faTrashAlt, faPenAlt } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { TextCP } from 'common/components/text/TextCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaskUtils } from 'common/utils/MaskUtils'
import _ from 'lodash'
import { BatchProcessDetailsCP } from 'modules/batch/components/batch-process-details.tsx/BatchProcessDetailsCP'
import { BatchProcessLogbookFormICP } from 'modules/batch/components/batch-process-logbook-drawer/inner/BatchProcessLogbookFormICP'
import { BatchRequests } from 'modules/batch/services/BatchRequests'

import { IBatchProcessResDTO } from 'modules/batch/services/dtos/responses/IBatchProcessResDTO'
import { ISaveBatchProcessLogbookResDTO } from 'modules/batch/services/dtos/responses/ISaveBatchProcessLogbookResDTO'
import { LogbookEntryICP } from 'modules/logbook/components/logbook-entries-list/inner/LogbookEntryICP'
import { LogbookEntriesListCP } from 'modules/logbook/components/logbook-entries-list/LogbookEntriesListCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IBatchProcessLogbookDrawerCPProps {
    batchCode: number
    processCode: number
    show: boolean
    onCancel: () => void
}

export function BatchProcessLogbookDrawerCP(props: IBatchProcessLogbookDrawerCPProps): JSX.Element {
    const [newEntryDrawerVisible, setNewEntryDrawerVisible] = useState<boolean>(false)
    const [processDetails, setProcessDetails] = useState<IBatchProcessResDTO>()
    const [processLogbookList, setProcessLogbookList] = useState<ISaveBatchProcessLogbookResDTO[]>([])
    const [processLogbookCode, setProcessLogbookCode] = useState<number>()

    const request = useRequest<IGenericListResponseDTO<ISaveBatchProcessLogbookResDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    const findOneReq = useRequest<IBatchProcessResDTO>()
    useEffect(onFindOneRequestChange, [findOneReq.awaiting])

    const deleteReq = useRequest()
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    useEffect(() => { init() }, [props.processCode, props.show])

    async function init(): Promise<void> {
        if (!!props.show && !!props.processCode) {
            await findOneReq.runRequest(BatchRequests.getProcess(props.batchCode, props.processCode))
            await request.runRequest(BatchRequests.searchProcessLogbook(props.batchCode, props.processCode))
        }

    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar lista de registros!')
        }

        setProcessLogbookList(_.orderBy(request.returnData.list, ['date'], ['desc']))
        ListActions.updateIsDone()
    }

    function onFindOneRequestChange(): void {

        if (findOneReq.awaiting || !findOneReq.tried)
            return

        if (!findOneReq.success || !findOneReq.returnData) {
            if (findOneReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', findOneReq.returnData, findOneReq.error)
            return RequestUtils.showDefaultErrorNotification(findOneReq.error, 'Erro ao buscar dados do processo!')
        }
        setProcessDetails(findOneReq.returnData)
    }

    function onDeleteReqChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success) {
            if (deleteReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteReq.returnData, deleteReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Erro ao remover registro!')
        }

        NotificationHelper.success('Registro removido com sucesso')
        init()
    }

    return (
        <DrawerCP
            title={`#${props.processCode} - Registros`}
            visible={props.show}
            width={'50%'}
            onClose={() => {
                props.onCancel()
            }}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={() => {
                            setProcessLogbookCode(undefined)
                            setNewEntryDrawerVisible(true)
                        }}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Novo registro
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >
            <WrapperSCP>
                <CardWrapperSCP>
                    {
                        !!processDetails ?
                            <BatchProcessDetailsCP process={processDetails} />
                            :
                            <GenericRowSkeletonICP rows={3} columns={2} />
                    }
                </CardWrapperSCP>

                <LogbookEntriesListCP<ISaveBatchProcessLogbookResDTO>
                    source={processLogbookList}
                    renderItem={(item: ISaveBatchProcessLogbookResDTO) => (
                        <LogbookEntryICP
                            date={item.date}
                            notes={item.notes}
                            isLastOne={!!processLogbookList.length && item.code === processLogbookList[processLogbookList.length - 1].code}
                            content={

                                <ContentInfoSCP>
                                    {
                                        !!item.averageTemperature &&
                                        <RowSCP>
                                            <TextCP text={'Temperatura média:\u00A0'} style={{ fontWeight: '500 ' }} />
                                            <TextCP text={`${MaskUtils.applyNumberMask(item.averageTemperature)}°C`} />
                                        </RowSCP>
                                    }
                                    {
                                        !!item.humidity &&
                                        <RowSCP>
                                            <TextCP text={'Umidade:\u00A0'} style={{ fontWeight: '500 ' }} />
                                            <TextCP text={`${MaskUtils.applyNumberMask(item.humidity)}%`} />
                                        </RowSCP>
                                    }
                                    {
                                        !!item.turnings &&
                                        <RowSCP>
                                            <TextCP text={'Quantas vezes revolveu:\u00A0'} style={{ fontWeight: '500 ' }} />
                                            <TextCP text={`${MaskUtils.applyNumberMask(item.turnings)}`} />
                                        </RowSCP>
                                    }
                                </ContentInfoSCP>

                            }
                            actions={
                                <ActionsWrapperSCP>

                                    <ButtonCP
                                        type={'ghost'}
                                        confirmMsg={'Tem certeza que deseja remover este registro?'}
                                        onClick={() => deleteReq.runRequest(BatchRequests.deleteProcessLogbook(props.batchCode, props.processCode, item.code))}
                                        icon={<FontAwsomeIconCP icon={faTrashAlt} />}
                                        loading={deleteReq.awaiting}
                                    />
                                    <ButtonCP
                                        type={'primary'}
                                        onClick={() => {
                                            setProcessLogbookCode(item.code)
                                            setNewEntryDrawerVisible(true)
                                        }}
                                        icon={<FontAwsomeIconCP icon={faPenAlt} />}
                                    />
                                </ActionsWrapperSCP>
                            }
                        />
                    )}
                />

                <BatchProcessLogbookFormICP
                    show={newEntryDrawerVisible}
                    processCode={props.processCode}
                    batchProcessLogbookCode={processLogbookCode}
                    batchCode={props.batchCode}
                    onCancel={() => {
                        setProcessLogbookCode(undefined)
                        setNewEntryDrawerVisible(false)
                    }}
                    onSuccess={() => {
                        init()
                        setProcessLogbookCode(undefined)
                        setNewEntryDrawerVisible(false)
                    }}
                />
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
    position: relative;
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const CardWrapperSCP = styled.div`
    border: 1px solid ${props => props.theme.primaryColor};
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    margin-bottom: 10px;
`

const ActionsWrapperSCP = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    column-gap: 10px;
    margin-bottom: 20px;
    .ant-btn{
        width: 100%;
        justify-content: center;
    }
    @media(max-width: 768px){
    
         .ant-btn {            
            margin: 4px!important;
            width: 100%;
        }
    }
`
const ContentInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
        .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px) {
        grid-template-columns: 1fr;
    }
`
const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
        .ant-typography{
        font-size: 12px;
    }
`