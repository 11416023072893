import { faEye } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { MobileListItemICP } from 'common/components/list/inner/MobileListItemICP'

import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { MaskUtils } from 'common/utils/MaskUtils'
import { AnalysisUtils } from 'modules/analysis/utils/AnalysisUtils'
import { ReportRoutes } from 'modules/report/ReportRoutes'
import { QualityReportDataTP } from 'modules/report/types/QualityReportDataTP'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface IFieldReportListItemICPProps {
    reportData: QualityReportDataTP
    loading?: boolean
    key: string
}

export function FieldReportListItemICP(props: IFieldReportListItemICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const history = useHistory()

    if (screenSize.smd) {
        return (
            <MobileListItemICP
                loading={!!props.loading}
                key={`${props.key}`}
                data={[
                    { label: 'Talhão', value: `${props.reportData.fieldName}` },
                    { label: 'Total de lotes', value: `${props.reportData.totalBatches ?? '0'}` },
                    { label: 'Total de especiais', value: `${props.reportData.totalSpecial}` },
                    { label: 'Nota Média', value: props.reportData.averageScore ? `${MaskUtils.applyNumberMask(props.reportData.averageScore)}` : '-' },
                    { label: 'Catação Média', value: props.reportData.averagePicking ? `${MaskUtils.applyNumberMask(props.reportData.averagePicking)}` : '-' },
                    {
                        label: 'Principais armomas', value: `${props.reportData.mostCommonAromas
                            .map(flavor => AnalysisUtils.translateCoffeeFlavor(flavor)).join(', ')
                            }`
                    },
                    {
                        label: 'Principais defeitos', value: `${props.reportData.mostCommonDefects
                            .map(defect => AnalysisUtils.translatePhysicalDefect(defect)).join(', ')
                            }`
                    },
                ]}
                actions={[
                    {
                        action: () => history.push(`${ReportRoutes.FIELD_WITHOUT_PARAM}/${props.reportData.fieldCode}`),
                        icon: <FontAwsomeIconCP icon={faEye} />,
                        label: 'Detalhes'
                    }
                ]}

            />
        )
    }

    return (
        <ListItemICP
            key={props.key}
            isMobile={screenSize.smd}
            loading={props.loading}
            style={{ padding: '0', margin: '0' }}
            customSkeleton={<GenericRowSkeletonICP rows={1} columns={8} />}
        >
            <WrapperSCP>
                <MobileLabelsSCP>
                    <TextCP text={'Talhão'} />
                    <TextCP text={'Total de lotes'} />
                    <TextCP text={'Total de especiais'} />
                    <TextCP text={'Nota Média'} />
                    <TextCP text={'Catação Média'} />
                    <TextCP text={'Principais armomas'} />
                    <TextCP text={'Principais defeitos'} />
                </MobileLabelsSCP>
                <ValuesSCP>
                    <TextCP text={`${props.reportData.fieldName}`} />
                    <TextCP text={`${props.reportData.totalBatches ?? '0'}`} />
                    <TextCP text={`${props.reportData.totalSpecial}`} />

                    <TextCP text={props.reportData.averageScore ? `${MaskUtils.applyNumberMask(props.reportData.averageScore)}` : '-'} />
                    <TextCP text={props.reportData.averagePicking ? `${MaskUtils.applyNumberMask(props.reportData.averagePicking)}` : '-'} />

                    <TextCP
                        text={`${props.reportData.mostCommonAromas
                            .map(flavor => AnalysisUtils.translateCoffeeFlavor(flavor)).join(', ')
                            }`}
                        style={{ fontSize: '11px' }}
                    />

                    <TextCP
                        text={`${props.reportData.mostCommonDefects
                            .map(defect => AnalysisUtils.translatePhysicalDefect(defect)).join(', ')
                            }`}
                        style={{ fontSize: '11px' }}
                    />
                    <ButtonCP
                        wrapperStyle={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            cursor: 'pointer',
                        }}
                        icon={<FontAwsomeIconCP icon={faEye} />}
                        onClick={() => history.push(`${ReportRoutes.FIELD_WITHOUT_PARAM}/${props.reportData.fieldCode}`)}
                        type={'primary'}
                    />
                </ValuesSCP>

            </WrapperSCP>
        </ListItemICP>
    )
}

const WrapperSCP = styled.div`   
    position:relative;
    display: flex;  
    
    @media(max-width: 768px){
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 5px;
          column-gap: 30px;
          padding-bottom: 60px;
          margin-bottom: 2em;
          border-bottom: 1px solid ${props => props.theme.lightGray};
       
    }
`

const ValuesSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: 0;
    padding: 10px;
    column-gap: 10px;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    align-items: center;
    .ant-list-bordered.ant-list-lg .ant-list-item{
        padding: 0!important;
    }
    width: 100%;
    .ant-typography {
        font-size: 12px;
    }
    .ant-btn span svg {
        width: auto;
        margin: 0;
    }

    @media(max-width: 768px){
        grid-template-columns: 1fr;    
        row-gap: 5px;    
        border-bottom: none;
    }
`
const MobileLabelsSCP = styled.div`
    display: none;
    .ant-typography{
        font-weight: 600;
    }
    @media(max-width: 768px){
        display: grid;
        padding: 10px 0;
        grid-template-columns: 1fr;
        row-gap: 5px;   
        width: 100%;
    }
`

const ActionsSCP = styled.div`
    display: flex;
    column-gap: 5px;
    @media(max-width: 767px){
        position: absolute;
        bottom: 15px;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        .ant-btn{
            width: 100%;
        }
    }
`
