import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'
import { PersonScreen } from 'modules/person/screens/PersonScreen'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

PersonRoutes.ROOT = '/equipe'

export function PersonRoutes(): JSX.Element {
    return (
        <AccessControlCP module={SystemModulesEnum.PEOPLE}>
            <Switch>
                <Route path={PersonRoutes.ROOT} exact={true} component={PersonScreen} />
            </Switch>
        </AccessControlCP>
    )
}