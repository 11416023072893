import React from 'react'

export function FertilizationIcon(props: any): JSX.Element {
    return (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
            <svg {...props} xmlns={'http://www.w3.org/2000/svg'} width={'25'} height={'25'} viewBox={'0 0 10.83 13.203'}>
                <g transform={'translate(-46)'}>
                    <g transform={'translate(47.16)'}>
                        <g transform={'translate(0)'}>
                            <path className={'a'} d={'M99.123,0H91.387A.387.387,0,0,0,91,.387V1.6h8.51V.387A.387.387,0,0,0,99.123,0Z'} transform={'translate(-91)'} />
                        </g>
                    </g>
                    <g transform={'translate(49.815 6.623)'}>
                        <path className={'a'} d={'M194.851,257.2a.52.52,0,1,0-.547.546,1.213,1.213,0,0,0,.873.338v-.064c0-.009,0-.016.005-.025A1.163,1.163,0,0,0,194.851,257.2Z'} transform={'translate(-193.964 -256.859)'} />
                    </g>
                    <g transform={'translate(51.814 6.627)'}>
                        <path className={'a'} d={'M272.708,256.986a1.165,1.165,0,0,0-1.225,1.225,1.214,1.214,0,0,0,.885-.338,1.244,1.244,0,0,0,.341-.887Z'} transform={'translate(-271.483 -256.986)'} />
                    </g>
                    <g transform={'translate(48.321 4.306)'}><g transform={'translate(0)'}><path className={'a'} d={'M139.094,167a3.094,3.094,0,1,0,3.094,3.094A3.1,3.1,0,0,0,139.094,167Zm1.834,3.752a2,2,0,0,1-1.429.567l-.018,0v.711a.387.387,0,1,1-.774,0v-.71a1.921,1.921,0,0,1-1.88-1.318,2.263,2.263,0,0,1-.069-1.1.387.387,0,0,1,.316-.316,2.229,2.229,0,0,1,1.087.066,1.761,1.761,0,0,1,.945.69,1.753,1.753,0,0,1,.932-.686,2.257,2.257,0,0,1,1.1-.07.387.387,0,0,1,.316.316,2.241,2.241,0,0,1-.065,1.088A1.933,1.933,0,0,1,140.928,170.752Z'} transform={'translate(-136 -167)'} /></g></g>
                    <g transform={'translate(46 2.372)'}>
                        <g transform={'translate(0)'}>
                            <path className={'a'} d={'M56.79,93.761,55.909,92H46.921l-.881,1.761a.387.387,0,0,0-.041.173v7.736a1.162,1.162,0,0,0,1.16,1.16h8.51a1.162,1.162,0,0,0,1.16-1.16V93.934A.392.392,0,0,0,56.79,93.761ZM51.415,100.9a3.868,3.868,0,1,1,3.868-3.868A3.873,3.873,0,0,1,51.415,100.9Z'} transform={'translate(-46 -92)'} />
                        </g>
                    </g>
                </g>
            </svg>
        </span>
    )
}
