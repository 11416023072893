/* eslint-disable react/jsx-indent */
import { Typography } from 'antd'
import { TransCP } from 'common/components/translation/TransCP'
import React from 'react'
import styled, { css } from 'styled-components'

interface ITextCPProps {
    text: string | number
    underline?: boolean
    delete?: boolean
    strong?: boolean
    color?: string
    wrapText?: boolean
    size?: any
    iconCP?: JSX.Element
    style?: any
    onClick?: () => void
    className?: string
    translationNameSpace?: string
    translationFiles?: string | string[]
}

/**
 * Componente para exibir texto.
 *
 * @author Stella
 * @author hjcostabr
 */
export function TextCP(props: ITextCPProps): JSX.Element {

    return (
        <TextSCP
            wrapText={props.wrapText}
            underline={props.underline}
            delete={props.delete}
            strong={props.strong}
            color={props.color}
            size={props.size || 'normal'}
            style={props.style}
            className={props.className}

        >
            <TextWrapperSCP className={'wrpClass'} onClick={props.onClick}>
                {
                    props.iconCP &&
                    <IconSCP>
                        {props.iconCP}
                    </IconSCP>
                }

                {

                    !!props.translationNameSpace &&
                        !!props.translationFiles ?
                        <TransCP
                            text={`${props.text}`}
                            files={props.translationFiles}
                            namespace={props.translationNameSpace}
                            style={props.style}
                        />
                        :
                        props.text
                }
            </TextWrapperSCP>

        </TextSCP>
    )
}

const IconSCP = styled.span`
    margin-right: 10px;
`

const TextWrapperSCP = styled.div`
    display: flex;
    align-items: center;
`

const fontSizeCss = css<{ size: any }>`
    font-size: ${props => props.theme.fontSizes[props.size]};
`

const TextSCP = styled(Typography.Text) <{ size: any, color?: string, wrapText?: boolean }>`
    &.ant-typography {
        color:  ${props => (!!props.color ? `${props.color}` : props.theme.black)};
        ${fontSizeCss}
        strong {
            font-weight: 600;
        }
    }
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: normal;
    white-space:normal;
`
