/* eslint-disable react/jsx-one-expression-per-line */
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IListItemPropertyResponseDTO } from 'modules/property/services/dtos/responses/IListItemPropertyResponseDTO'
import { PropertyRequests } from 'modules/property/services/PropertyRequests'
import React, { useEffect, useState } from 'react'

import { PropertyCardICP } from 'modules/property/components/property-list/inner/PropertyCardICP'
import styled from 'styled-components'

export function PropertyListCP(): JSX.Element {

    const [properties, setProperties] = useState<IGenericListResponseDTO<IListItemPropertyResponseDTO>>()
    const request = useRequest<IGenericListResponseDTO<IListItemPropertyResponseDTO>>()

    useEffect(getProperties, [])
    useEffect(onRequestChange, [request.awaiting])

    function getProperties(): void {
        request.runRequest(PropertyRequests.search())
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar propriedade!')
        }

        setProperties(request.returnData)
    }

    const loading = request.awaiting || !properties
    return (
        <ListWrapperSCP>
            <ListCP<IListItemPropertyResponseDTO>
                grid={{ gutter: 16, column: 2, md: 1, sm: 1, xs: 1, xl: 2, xxl: 3 }}
                style={{ overflow: 'overlay', paddingTop: '2em' }}
                loading={loading}
                bordered={false}
                itemLayout={'vertical'}
                size={'large'}
                loadMoreData={() => { }} dataSource={properties?.list}
                locale={{
                    emptyText: <EmptyCP description={'Você ainda não tem propriedades'} />
                }}
                renderItem={item => <PropertyCardICP item={item} loading={loading} reloadList={getProperties} />}
            />
        </ListWrapperSCP>

    )
}
const ListWrapperSCP = styled.div`
    max-height: 78vh;
    overflow: overlay;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    }
`