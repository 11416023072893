import { css } from '@emotion/core'
import { Result } from 'antd'
import { ResultProps } from 'antd/lib/result'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import _ from 'lodash'
import { UserRequests } from 'modules/user/services/UserRequests'
import React, { useEffect, useState } from 'react'
import RingLoader from 'react-spinners/RingLoader'
import { useRequest } from '../../../../../common/request-helper/UseRequest'

interface IProps {
    values: any
}

export function RegistrationCompleteStep(props: IProps): JSX.Element {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const producerReq = useRequest<any>()
    const imageReq = useRequest<any>()

    useEffect(() => {
        if (_.isEmpty(props.values) || !props.values.rgFront)
            return

        const body = {
            legal: props.values.legal,
            types: ['PRODUCER'],
            cpfCnpj: props.values.cpfCnpj,
            name: props.values.name,
            stateRegistration: `${props.values.stateRegistration}`,
            email: props.values.email,
            phone: props.values.phone,
            address: {
                street: props.values.street,
                number: props.values.number,
                complement: props.values.complement,
                neighborhood: props.values.neighborhood,
                zipCode: props.values.zipCode,
                city: props.values.city,
                state: props.values.state,
            },
        }
        producerReq.runRequest(UserRequests.save(body))
    }, [props.values])

    useEffect(() => {
        if (producerReq.awaiting || !producerReq.tried)
            return

        if (!producerReq.success || !producerReq.returnData) {
            if (producerReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', producerReq.returnData, producerReq.error)
            setLoading(false)
            setError(true)
            return RequestUtils.showDefaultErrorNotification(producerReq.error, 'Erro ao salvar propriedade!')
        }

        const userCode = producerReq.returnData.code
        const formData = new FormData()
        formData.append('profilePicture', props.values.profilePicture)
        formData.append('rgFront', props.values.rgFront)
        formData.append('rgBack', props.values.rgBack)
        formData.append('rgFace', props.values.rgFace)
        formData.append('stateRegPicture', props.values.stateRegPicture)
        formData.append('addressPicture', props.values.addressPicture)
        imageReq.runRequest(UserRequests.addImages(userCode, formData))

    }, [producerReq.awaiting])

    useEffect(() => {
        if (imageReq.awaiting || !imageReq.tried)
            return

        if (!imageReq.success || !imageReq.returnData) {
            if (imageReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', imageReq.returnData, imageReq.error)
            setLoading(false)
            setError(true)
            return RequestUtils.showDefaultErrorNotification(imageReq.error, 'Erro ao salvar propriedade!')
        }

        setLoading(false)

    }, [imageReq.awaiting])

    return (
        <Result
            icon={resultData(loading, error).icon}
            status={resultData(loading, error).status}
            title={resultData(loading, error).title}
            subTitle={resultData(loading, error).subTitle}
            extra={resultData(loading, error).extra}
        />
    )
}

const resultData = (loading: boolean, error: boolean): ResultProps => {
    if (loading) {
        return {
            icon: <RingLoader color={'#8712b1'} css={override} />,
            status: 'success',
            title: 'Aguarde, estamos processando seus dados',
        }
    }

    if (error) {
        return {
            status: 'error',
            title: 'Erro no envio',
            subTitle: 'Ocorreu um erro durante o processamento de seus dados. Aguarde alguns instantes e tente novamente.'
        }
    }

    return {
        status: 'success',
        title: 'Parabéns! ',
        subTitle: `Seu pré-cadastro foi efetuado com sucesso. Para finalizar, clique no link 
        abaixo para efetuar o pagamento da assinatura. Assim que for aprovado, 
        você receberá um email com os dados de acesso à plataforma`,
        extra: [
            <ButtonCP
                style={{ display: 'block', margin: '0 auto', width: '200px' }}
                key={'assine'}
                href={'https://www.cobreai.com/pay/QJ2S2/'}
                type={'primary'}
            >
                Efetuar pagamento
            </ButtonCP>
        ]
    }
}

const override = css`
  display: block;
  margin: 0 auto;
`