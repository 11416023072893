import { faTrash, faEye, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PopConfirmCP } from 'common/components/pop-confirm/PopConfirmCP'

import React from 'react'
import styled from 'styled-components'

interface IDocumentActionsICPProps {
    onDelete: () => void
    onPreview: () => void
    onDownload: () => void
    loading: boolean
}

export function DocumentActionsICP(props: IDocumentActionsICPProps): JSX.Element {
    return (
        <WrapperSCP>

            <ButtonCP
                onClick={props.onPreview}
                type={'primary'}
                icon={<FontAwsomeIconCP icon={faEye} />}
                tooltip={'Visualizar'}
            />

            <ButtonCP
                onClick={props.onDownload}
                type={'primary'}
                icon={<FontAwsomeIconCP icon={faCloudDownloadAlt} />}
                tooltip={'Baixar'}
            />

            <PopConfirmCP title={'Deseja mesmo excluir este documento?'} onConfirm={props.onDelete}>
                <ButtonCP
                    type={'primary'}
                    icon={<FontAwsomeIconCP icon={faTrash} />}
                    tooltip={'Excluir'}
                    loading={props.loading}
                />
            </PopConfirmCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 5px;
        @media(max-width: 768px){
            max-width: 100px;
        }
`