import { CarouselCP } from 'common/components/carousel/CarouselCP'
import { PropertyDetailsCP } from 'modules/batch-landing-page/components/property-details/PropertyDetailsCP'
import React from 'react'
import styled from 'styled-components'

interface IPropertyDetailsCarouselICPProps {
    propertyCodes: number[]
    setProducerCode: (code: number) => void
}

export function PropertyDetailsCarouselICP(props: IPropertyDetailsCarouselICPProps): JSX.Element {

    return (
        <CarouselCP autoPlay={false}>
            {
                props.propertyCodes.map(code => (
                    <PropertyDetailsCP propertyCode={code} setProducerCode={props.setProducerCode} key={code} />
                ))
            }
        </CarouselCP>
    )
}

const WrapperSCP = styled.div`
`
