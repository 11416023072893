import React, { CSSProperties } from 'react'

export function EquipmentIcon(props: { color?: string, style?: CSSProperties }): JSX.Element {
    return (
        <span style={{ display: 'flex', justifyContent: 'center', ...props.style }}>

            <svg id={'Layer_1_1_'} enableBackground={'new 0 0 64 64'} height={'27'} viewBox={'0 0 64 64'} width={'27'} fill={props.color} xmlns={'http://www.w3.org/2000/svg'}>
                <path d={'m12 39c0 .551.448 1 1 1h8c.552 0 1-.449 1-1s-.448-1-1-1h-8c-.552 0-1 .449-1 1z'} />
                <path d={'m17 62c3.207 0 5.708-2.778 5.372-5.967l-1.282-14.042c-.03.001-.059.009-.09.009h-8c-.031 0-.06-.008-.09-.009l-1.281 14.042c-.337 3.189 2.164 5.967 5.371 5.967z'} />
                <path d={'m26 4c-.595 0-1.09.465-1.127 1.059l-.781 12.507c-.086 1.365-1.224 2.434-2.592 2.434s-2.506-1.069-2.592-2.435l-.781-12.506c-.037-.594-.532-1.059-1.127-1.059s-1.09.465-1.127 1.059l-.781 12.507c-.086 1.365-1.224 2.434-2.592 2.434s-2.506-1.069-2.592-2.435l-.781-12.506c-.037-.594-.532-1.059-1.127-1.059s-1.09.465-1.127 1.059l-.875 14.004.002 2.937c0 2.206 1.794 4 4 4h14c2.206 0 4-1.794 4-4v-3l-.873-13.941c-.037-.594-.532-1.059-1.127-1.059z'} />
                <path d={'m14 28h6v8h-6z'} />
                <path d={'m42 39c0 .551.448 1 1 1h8c.552 0 1-.449 1-1s-.448-1-1-1h-8c-.552 0-1 .449-1 1z'} />
                <path d={'m47 62c3.207 0 5.708-2.778 5.372-5.967l-1.282-14.042c-.03.001-.059.009-.09.009h-8c-.031 0-.06-.008-.09-.009l-1.281 14.042c-.337 3.189 2.164 5.967 5.371 5.967z'} />
                <path d={'m36.674 26.009c-.275 1.265.493 2.501 1.748 2.815.379.095.777.095 1.156 0l6.096-1.524c.434-.108.88-.163 1.326-.163s.893.054 1.326.163l6.096 1.524c.379.095.777.095 1.156 0 1.255-.314 2.023-1.551 1.748-2.815l-2.686-12.355c-.913-4.204-3.755-7.747-7.64-9.559-3.885 1.812-6.727 5.355-7.641 9.559z'} />
                <path d={'m47.842 29.241c-.551-.138-1.133-.138-1.684 0l-2.158.54v6.219h6v-6.219z'} />
            </svg>
        </span>
    )
}