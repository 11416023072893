import { faCamera, faClipboardList, faPenAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { NotificationHelper } from 'common/NotificationHelper'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { BatchProcessAlbumDrawerCP } from 'modules/batch/components/batch-process-album-drawer/BatchProcessAlbumDrawerCP'
import { BatchOriginDetailsEntryICP } from 'modules/batch/components/batch-process-details-content/inner/BatchOriginDetailsEntryICP'
import { BatchProcessDetailsCP } from 'modules/batch/components/batch-process-details.tsx/BatchProcessDetailsCP'
import { BatchProcessDrawerCP } from 'modules/batch/components/batch-process-drawer/BatchProcessDrawerCP'
import { BatchProcessLogbookDrawerCP } from 'modules/batch/components/batch-process-logbook-drawer/BatchProcessLogbookDrawerCP'
import { BatchProcessTypeEnum } from 'modules/batch/enums/BatchProcessTypeEnum'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IBatchProcessResDTO } from 'modules/batch/services/dtos/responses/IBatchProcessResDTO'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { LogbookEntryICP } from 'modules/logbook/components/logbook-entries-list/inner/LogbookEntryICP'
import { LogbookEntriesListCP } from 'modules/logbook/components/logbook-entries-list/LogbookEntriesListCP'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

type RouteParamsTP = { code?: string }

interface IProcessTimelineCPProps {
    hasSales: boolean
}

export function ProcessTimelineCP(props: IProcessTimelineCPProps): JSX.Element {
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const routeParams = useParams<RouteParamsTP>()

    const [hasBenefit, setHasBenefit] = useState<boolean>(false)
    const [batchCode, setBatchCode] = useState<number>()
    const [processDrawerVisible, setProcessDrawerVisible] = useState<boolean>(false)
    const [processCode, setProcessCode] = useState<number>()
    const [processList, setProcessList] = useState<IBatchProcessResDTO[]>([])
    const [albumDrawerVisible, setAlbumDrawerVisible] = useState<boolean>(false)
    const [logbookDrawerVisible, setLogbookDrawerVisible] = useState<boolean>(false)

    const deleteReq = useRequest()
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    useEffect(() => {
        if (!!routeParams.code)
            setBatchCode(+routeParams.code)
    }, [routeParams])

    useEffect(getBatchDetails, [batchCode])
    useEffect(onItemChange, [updateStatus])

    const request = useRequest<IBatchResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    function getBatchDetails(): void {
        if (!!batchCode)
            request.runRequest(BatchRequests.getProcesses(batchCode))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE)
            getBatchDetails()
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar detalhes!')
        }

        const processes = _.orderBy(request.returnData.processes, ['endDate', 'startDate'], ['desc', 'desc'])
        processes.push({ type: BatchProcessTypeEnum.ORIGIN } as any)
        setProcessList(processes)
        setHasBenefit(request.returnData.bags > 0)
        ListActions.updateIsDone()
    }

    function onDeleteReqChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success) {
            if (deleteReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteReq.returnData, deleteReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Erro ao remover registro!')
        }

        NotificationHelper.success('Registro removido com sucesso')
        getBatchDetails()
        ListActions.mustUpdate(true)
    }

    return (
        <WrapperSCP>

            <LogbookEntriesListCP<IBatchProcessResDTO>
                source={processList}
                renderItem={(item: IBatchProcessResDTO) => (
                    <EntryWrapperSCP>
                        {
                            item.type === BatchProcessTypeEnum.ORIGIN && !!batchCode ?
                                <BatchOriginDetailsEntryICP
                                    batchCode={batchCode}
                                /> :
                                <LogbookEntryICP
                                    date={item.startDate}
                                    endDate={item.endDate}
                                    notes={item.notes}
                                    // isLastOne={!!processList.length && item.code === processList[processList.length - 1].code}
                                    content={
                                        <>
                                            <BatchProcessDetailsCP process={item} />
                                            {
                                                item.type === BatchProcessTypeEnum.BENEFIT && props.hasSales &&
                                                <TextCP
                                                    text={'O benefício não pode ser alterado após ter sido realizada uma venda.'}
                                                    style={{ color: 'red', fontSize: '10px' }}
                                                />
                                            }
                                        </>
                                    }
                                    actions={
                                        <ActionsWrapperSCP>
                                            <MainSCP>
                                                <ButtonCP
                                                    type={'primary'}
                                                    onClick={() => {
                                                        setProcessCode(item.code)
                                                        setLogbookDrawerVisible(true)
                                                    }}
                                                    icon={<FontAwsomeIconCP icon={faClipboardList} />}

                                                >
                                                    {' '}
                                                    Diário
                                                </ButtonCP>
                                            </MainSCP>
                                            <OtherActionsSCP>
                                                <ButtonCP
                                                    type={'ghost'}
                                                    disabled={item.type === BatchProcessTypeEnum.BENEFIT && props.hasSales}
                                                    confirmMsg={'Tem certeza que deseja remover este registro?'}
                                                    onClick={() => { deleteReq.runRequest(BatchRequests.deleteProcess(batchCode!, item.code)) }}
                                                    icon={<FontAwsomeIconCP icon={faTrashAlt} />}
                                                    loading={deleteReq.awaiting}
                                                />
                                                <ButtonCP
                                                    type={'primary'}
                                                    disabled={item.type === BatchProcessTypeEnum.BENEFIT && props.hasSales}
                                                    onClick={() => {
                                                        setProcessCode(item.code)
                                                        setProcessDrawerVisible(true)
                                                    }}
                                                    icon={<FontAwsomeIconCP icon={faPenAlt} />}
                                                />
                                                <ButtonCP
                                                    type={'primary'}
                                                    onClick={() => {
                                                        setProcessCode(item.code)
                                                        setAlbumDrawerVisible(true)
                                                    }}
                                                    icon={<FontAwsomeIconCP icon={faCamera} />}
                                                />

                                            </OtherActionsSCP>
                                        </ActionsWrapperSCP>
                                    }
                                />
                        }
                    </EntryWrapperSCP>
                )}
            />

            {
                !!batchCode &&
                <BatchProcessDrawerCP
                    show={processDrawerVisible}
                    onCancel={() => {
                        setProcessDrawerVisible(false)
                    }}
                    onSuccess={() => {
                        setProcessDrawerVisible(false)
                    }}
                    processCode={processCode}
                    batchCode={batchCode}
                    hasBenefit={hasBenefit}
                />
            }
            {
                !!batchCode && !!processCode &&
                <BatchProcessAlbumDrawerCP
                    show={albumDrawerVisible}
                    processCode={processCode}
                    batchCode={batchCode}
                    onCancel={() => setAlbumDrawerVisible(false)}
                />
            }

            {
                !!batchCode && !!processCode &&
                <BatchProcessLogbookDrawerCP
                    show={logbookDrawerVisible}
                    processCode={processCode}
                    batchCode={batchCode}
                    onCancel={() => setLogbookDrawerVisible(false)}
                />
            }

        </WrapperSCP>
    )
}

const ActionsWrapperSCP = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    .ant-btn{
        width: 100%;
        justify-content: center;
    }
    @media(max-width: 768px){
    
         .ant-btn {            
            margin: 4px!important;
            width: 100%;
        }
    }
`
const EntryWrapperSCP = styled.div``
const MainSCP = styled.div`
    width: 100%;
    margin: 10px;
    @media(max-width: 768px){
        margin: 0;
    }
`

const OtherActionsSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;    
    width: 100%;
    @media(max-width: 768px){
        column-gap: 5px;  
    }
`

const WrapperSCP = styled.div`
  
`
const HeaderWrapperSCP = styled.div`
    background: #fff;
    min-height: 100px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    @media(max-width: 768px) {
        min-height: 62px;
        padding: 0 10px 7px 10px;
        margin-bottom: 10px;
    }
`

const POWrapperSCP = styled.div`
width: 100%;
display: flex;
align-items: flex-end;

`

const ContentWrapperSCP = styled.div`
margin-top: 15px;
background: #fff;
min-height: 100px;
border-radius: 10px;
position: relative;
padding: 10px;
`

const ContentInfoSCP = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
    .ant-typography{
    font-size: 12px;
}
@media(max-width: 768px) {
    grid-template-columns: 1fr;
}
`

const RowSCP = styled.div`
display: flex;
flex-wrap: wrap;
    .ant-typography{
    font-size: 12px;
}

`
const ExtraWrapperSCP = styled.div`


    `