import React, { useEffect, useState } from 'react'
import { PaginationConfig } from 'antd/lib/pagination'

import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { InvoiceListItemCP } from 'modules/financial/components/invoice-list-item/InvoiceListItemCP'
import moment from 'moment-timezone'
import { InvoiceTypeEnum } from 'modules/financial/enums/InvoiceType'
import styled from 'styled-components'
import { InvoiceFiltersCP } from 'modules/financial/components/invoice-filters/InvoiceFiltersCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { FinancialRequests } from 'modules/financial/services/FinancialRequests'
import { ISearchInvoicesReqDTO } from 'modules/financial/services/dtos/request/ISearchInvoicesReqDTO'
import { IInvoiceResDTO } from 'modules/financial/services/dtos/responses/IInvoiceResDTO'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { ListHeaderICP } from 'common/components/list/ListHeaderICP'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { FinancialDashboardTypeCP } from 'modules/financial/components/financial-dashboard-type/FinancialDashboardTypeCP'
import { ListActions } from 'common/components/list/inner/ListActions'

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 7,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`
}

const DEFAULT_FILTERS: ISearchInvoicesReqDTO = {
    isCredit: true,
    season: moment().year(),
    startDate: moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('YYYY-MM-DD'),
    endDate: moment().add(1, 'years')
        .format('YYYY-MM-DD'),
    itemsPerPage: DEFAULT_PAGINATION.pageSize,
    page: 1
}
interface IFinancialDashboardCPProps {
    type: InvoiceTypeEnum
    setTypeDashboard: (type: InvoiceTypeEnum) => void
}

export function FinancialDashboardCP(props: IFinancialDashboardCPProps): JSX.Element {
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const screenSize = useScreenSize()

    const [filters, setFilters] = useState<ISearchInvoicesReqDTO>(DEFAULT_FILTERS)
    const [pagination, setPagination] = useState<PaginationConfig>(DEFAULT_PAGINATION)

    const [invoiceData, setInvoiceData] = useState<IInvoiceResDTO[]>([])

    const searchInvoices = useRequest<IGenericListResponseDTO<IInvoiceResDTO>>()
    useEffect(searchInvoces, [filters])
    useEffect(onRequestChange, [searchInvoices.awaiting])

    useEffect(defineSeaarchType, [props.type])
    useEffect(onItemChange, [updateStatus])

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE)
            searchInvoces()
    }

    function defineSeaarchType(): void {
        if (!props.type)
            return

        switch (props.type) {
            case InvoiceTypeEnum.EXPENSE:
                return setFilters({ ...filters, isCredit: false })
            case InvoiceTypeEnum.REVENUE:
                return setFilters({ ...filters, isCredit: true })
            default:
                setFilters({ ...filters, isCredit: true })
        }
    }

    function searchInvoces(): void {
        if (!!filters)
            searchInvoices.runRequest(FinancialRequests.searchInvoices(filters))
    }

    function onRequestChange(): void {
        if (searchInvoices.awaiting || !searchInvoices.tried)
            return

        if (!searchInvoices.success || !searchInvoices.returnData) {
            if (searchInvoices.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', searchInvoices.returnData, searchInvoices.error)
            return RequestUtils.showDefaultErrorNotification(searchInvoices.error, 'Erro ao buscar receitas!')
        }
        setInvoiceData(searchInvoices.returnData.list)
        setPagination({
            ...pagination,
            total: searchInvoices.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                setFilters({ ...filters, page, itemsPerPage: pageSize })
            }
        })
        ListActions.updateIsDone()
    }

    return (
        <WrapperSCP>
            <InvoiceFiltersCP type={props.type} filters={filters} setFilters={setFilters} />
            {
                !!screenSize.smd &&
                <FinancialDashboardTypeCP type={props.type} onChangeType={props.setTypeDashboard} />

            }
            <DashboardWrapperSCP>

                <ListWrapperSCP>
                    <ListHeaderICP
                        gridColumns={'7% 7% 30% 12% 8% 10% 22%'}
                        gap={'15px'}
                        labels={[
                            'Data',
                            'Categoria',
                            'Descrição',
                            'Valor total',
                            'Status',
                            'Data do pagamento'
                        ]}
                    />
                    <ListCP<IInvoiceResDTO>
                        loading={searchInvoices.awaiting}
                        itemLayout={'vertical'}
                        size={'large'}
                        loadMoreData={() => { }} dataSource={invoiceData}
                        hasStateControl={true}
                        wrapperStyle={{ marginBottom: '0' }}
                        pagination={pagination}
                        locale={{
                            emptyText: <EmptyCP description={'Sem resultados para os filtros selecionados'} />
                        }}
                        renderItem={(item: IInvoiceResDTO) => (
                            <InvoiceListItemCP
                                type={props.type}
                                invoice={item}
                                key={`${item.code}${Math.random()}`}
                                loading={searchInvoices.awaiting}
                            />
                        )}
                    />
                </ListWrapperSCP>

                {/* <WidgetsWrapperSCP>
                    <WidgetResumeCP
                        totalValue={'59.019,50'}
                        icon={<FontAwesomeIcon icon={faFileInvoiceDollar} style={{ fontSize: 15 }} />}
                    />
                    <WidgetValueCP
                        title={'Receita total'}
                        value={'400.000.00'}
                        icon={<FontAwesomeIcon icon={faFunnelDollar} style={{ fontSize: 15 }} />}
                    />
                    <WidgetValueCP
                        title={'Despesas totais'}
                        value={'365.870.00'}
                        icon={<FontAwesomeIcon icon={faWallet} style={{ fontSize: 15 }} />}
                    />
                </WidgetsWrapperSCP> */}
            </DashboardWrapperSCP>
        </WrapperSCP>

    )
}
const WrapperSCP = styled.div`
     margin-bottom: 4em;
    max-height: 75vh;   
    overflow: overlay;
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    }
`

const DashboardWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 100%;
    column-gap: 2%;

`
const WidgetsWrapperSCP = styled.div``

const ListWrapperSCP = styled.div`
    border-radius: 10px;
    background: #fff;
    padding: 15px;
`

const FilterSCP = styled.div`
    margin: 20px 0;
    max-width: 370px;
`
const ListHeaderSCP = styled.div`
    .ant-typography{     
        white-space: pre-wrap;
        text-align: center;
        font-weight: 600;
    }
    display: grid;
    grid-template-columns: 1fr 0.7fr 2.2fr 1.2fr 1fr 0.7fr;
    column-gap: 20px;
    @media(max-width: 768px){
      display: none;
   
    }

`