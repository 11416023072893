import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { ResponseErrorCustomActionTP } from 'common/request-helper/types/ResponseErrorCustomActionTP'
import * as _ from 'lodash'

/**
 * MANAGER
 * Encapsula metodos para gestao de requisicoes http.
 *
 * @todo: Concluir mecanismo para 'cancelar' 01 requisicao em andamento
 *
 * @author hjcostabr
 */
export class RequestHelper {

    static cancelRequest: Function | undefined

    private static readonly DEFAULT_HEADERS = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }

    private static bearerAuthToken?: string
    private static readonly schema: string
    private static readonly errorCustomActions: ResponseErrorCustomActionTP[] = []

    private constructor() { }

    /**
     * Setter para token de autenticacao global:
     * Sera utilizado automaticamente em todas as requisicoes a menos que
     * sejam configuradas individualmente para NAO incluir autenticaco.
     */
    static setBearerAuthToken(authToken?: string): void {
        this.bearerAuthToken = authToken
    }

    /**
     * Adiciona 01 action generica customizada para tratar erros de 01
     * determinado tipo (status http) em requisicoes.
     * Sera executada toda vez que ocorrer 01 erro do tipo especificado
     * em alguma requisicao a menos que a requisicao sobrescreva essa acao
     * individualmente.
     */
    static addErrorCustomAction(action: ResponseErrorCustomActionTP): void {
        this.errorCustomActions.push(action)
    }

    /**
     * Executa 01 requisicao http generica parametrizada.
     * @todo: Concluir mecanismo para 'cancelar' 01 requisicao em andamento
     */
    static async runRequest<ResultType = any>(config: RequestConfigTP): Promise<AxiosResponse<ResultType> | void> {

        const requestParams: AxiosRequestConfig = {
            url: config.url,
            method: config.method,
            headers: this.getRequestHeaders(config),
            params: (config.method === HttpMethodEnum.GET) ? config.params : {},
            data: (config.method !== HttpMethodEnum.GET) ? config.params : {},
            responseType: config.responseType ?? 'json',
            cancelToken: new axios.CancelToken(((c) => {
                RequestHelper.cancelRequest = c
            }))
        }

        try {
            return await axios.request<ResultType>(requestParams)

        } catch (error) {
            this.handleRequestError(error, config)
        }
    }

    /** Gera & retorna headers para envio de 01 requisicao. */
    private static getRequestHeaders(config?: RequestConfigTP): {} {

        const customHeaders = _.get(config, 'headers', {})
        const noAuth = _.get(config, 'noAuth', false)

        const headers: any = {
            ...this.DEFAULT_HEADERS,
            ...customHeaders
        }

        if (!noAuth) {
            const bearerAuthToken = _.get(config, 'bearerAuthToken', this.bearerAuthToken)
            if (!!bearerAuthToken) {
                headers.Authorization = `Bearer ${bearerAuthToken}`

                // @Todo Passar no config o schema
                if (!config?.customRequestHelperTP)
                    headers.schema = _.get(config, 'schema', this.schema)
            }
        }

        return headers
    }

    /** Avalia & trata 01 erro ocorrido durante 01 requisicao. */
    private static handleRequestError(error: any, requestConfig?: RequestConfigTP): void {

        const errorStatus: HttpStatusEnum = _.get(error, 'response.status')
        console.log('STATUS', errorStatus)
        if (!errorStatus)
            throw error.response.data

        const reqCustomAction: ResponseErrorCustomActionTP | undefined = _.get(requestConfig, 'httpStatusCustomAction')

        if (!!reqCustomAction && reqCustomAction.httpStatus === errorStatus)
            return reqCustomAction.action(error.response.data)

        for (const genericCustomAction of this.errorCustomActions) {
            if (genericCustomAction.httpStatus === errorStatus)
                return genericCustomAction.action(error.response.data)
        }

        throw error.response.data
    }
}
