/* eslint-disable @typescript-eslint/no-floating-promises */
import { Skeleton } from 'antd'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { ListHeaderICP } from 'common/components/list/ListHeaderICP'

import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaskUtils } from 'common/utils/MaskUtils'
import { ThemeConfig } from 'config/ThemeConfig'
import { IFormulaApplicationByFieldResDTO } from 'modules/maneuver/services/dtos/responses/IFormulaApplicationByFieldResDTO'

import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'
import { ManeuverRequests } from 'modules/maneuver/services/ManeuverRequests'
import { ManeuverUtils } from 'modules/maneuver/utils/ManeuverUtils'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import { RecipeUtils } from 'modules/maneuver/utils/RecipeUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormulaContentICP } from './inner/FormulaContentICP'

interface IRecipeCardCPProps {
    recipeCode?: number
    setRecipe?: (recipe: IRecipeResponseDTO) => void
    fieldCodes?: number[]
}

/**
 * COMPONENTE
 * Card de informações gerais da instrução
 */
export function RecipeCardCP(props: IRecipeCardCPProps): JSX.Element | null {
    const [recipeData, setRecipeData] = useState<IRecipeResponseDTO>()
    const [formulaData, setFormulaData] = useState<IFormulaApplicationByFieldResDTO[]>()
    useEffect(getRecipeData, [props.recipeCode])
    useEffect(getFormulaAplicationData, [props.fieldCodes?.length])

    const request = useRequest<IRecipeResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const formulaApplicationReq = useRequest<IFormulaApplicationByFieldResDTO[]>()
    useEffect(onFormulaApplicationReqChange, [formulaApplicationReq.awaiting])

    function getFormulaAplicationData(): void {
        if (!!props.fieldCodes && !!props.fieldCodes.length && !!recipeData?.formulas.length) {
            formulaApplicationReq.runRequest(ManeuverRequests.getFormulaApplicationData({
                fields: props.fieldCodes.map(fie => +fie),
                formulas: recipeData.formulas.map(form => +form.code)
            }))
        }
    }

    function getRecipeData(): void {
        if (!!props.recipeCode)
            request.runRequest(ManeuverRequests.getRecipe(props.recipeCode))
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar instrução!')
        }
        setRecipeData(request.returnData)
        if (!!props.setRecipe)
            props.setRecipe(request.returnData)

    }

    function onFormulaApplicationReqChange(): void {
        if (formulaApplicationReq.awaiting || !formulaApplicationReq.tried)
            return

        if (!formulaApplicationReq.success || !formulaApplicationReq.returnData) {
            if (formulaApplicationReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', formulaApplicationReq.returnData, formulaApplicationReq.error)
            return RequestUtils.showDefaultErrorNotification(formulaApplicationReq.error, 'Erro ao buscar dados da fórmula!')
        }
        setFormulaData(formulaApplicationReq.returnData)

    }

    if (!props.recipeCode)
        return null

    return (
        <CardWrapperSCP>
            <Skeleton active={true} paragraph={{ rows: 1 }} loading={request.awaiting || !recipeData}>
                {!!recipeData &&
                    <WrapperSCP>
                        <HighLightSCP>
                            {ManeuverUtils.getRecipeTypeIcon(recipeData.type)}
                        </HighLightSCP>

                        <InfoWrapperSCP>
                            <TitleSCP>{`${recipeData.title} (${RecipeUtils.translateType(recipeData.type)})`}</TitleSCP>

                            {recipeData.description &&
                                <SubtitleSCP>
                                    <TextCP text={`${recipeData.description}`} />
                                </SubtitleSCP>}

                            {!!recipeData.formulas && !!recipeData.formulas.length &&
                                <FormulaWrapperSCP>
                                    {
                                        !!recipeData.mixTotalLiters ?
                                            <TextCP text={`Litragem total da mistura: ${recipeData.mixTotalLiters} litros/ha`} />
                                            :
                                            <TextCP text={'Litragem total da mistura não definida!'} />
                                    }

                                </FormulaWrapperSCP>
                            }



                            {
                                !!formulaData &&
                                <FormulaResumeSCP>
                                    <ListHeaderICP
                                        gridColumns={'1fr 1fr 1fr 1fr 1fr 1fr'}
                                        labels={[
                                            'Produto:',
                                            'Qtd:',
                                            'Área:',
                                            'Gasto total:',
                                            'Em estoque:',
                                            'Saldo final:'
                                        ]}
                                        noPadding={true}
                                    />
                                    <ListCP<IFormulaApplicationByFieldResDTO>
                                        bordered={false}
                                        itemLayout={'vertical'}
                                        size={'large'}
                                        wrapperStyle={{ marginBottom: '0px' }}
                                        dataSource={formulaData}
                                        loading={false}
                                        locale={{
                                            emptyText: <EmptyCP description={'Sem resultados'} />
                                        }}
                                        renderItem={(fData: IFormulaApplicationByFieldResDTO) => (
                                            <FormulaContentICP item={fData} />
                                        )}
                                    />

                                </FormulaResumeSCP>

                            }
                        </InfoWrapperSCP>
                    </WrapperSCP>
                }
            </Skeleton>
        </CardWrapperSCP>
    )
}

const CardWrapperSCP = styled.div`
    display: flex;
    margin: 15px 0;
    width: 100%;
    box-shadow: 0px 9px 18px -4px rgba(0,0,0,0.23);
    border-radius: 8px;
    padding: 10px;
    position: relative;
        &:before{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            border-radius: 0 5px 5px 0;
            background: ${props => props.theme.primaryColor}
        }
`
const HighLightSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    margin: 10px;
    svg{
        font-size: 20px;
    }
`

const InfoWrapperSCP = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`

const TitleSCP = styled.div`
    font-size: 12px;
    color: ${props => props.theme.primaryColor};
`

const SubtitleSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }
    
`

const FormulaWrapperSCP = styled.div`
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    .ant-typography{
        font-size: 12px;
    }
`

const WrapperSCP = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 7fr ;
  width: 100%;
  @media(max-width: 767px){
    grid-template-columns: 1fr 5fr;
  }
`

const ProductsSCP = styled.div`
  
`

const GridTitleSCP = styled.div`
   column-gap: 10px;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    .ant-typography{
        color:  ${props => props.theme.black}!important;
        font-weight: 500;
        font-size: 12px;
    }
`

const GridItemsSCP = styled.div`
 column-gap: 10px;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }
`
const FormulaResumeSCP = styled.div`
      .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }
`