/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
//import { RingProgress } from '@ant-design/charts'
import { IChartStatisticConfig } from 'common/components/charts/interfaces/IChartStatisticConfig'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import styled from 'styled-components'

interface IRingProgressChartCPProps {
    height?: number
    width?: number
    autoFit?: boolean
    percent: number
    color?: [string, string]
    innerRadius?: number
    radius?: number
    label: string
    statistic?: IChartStatisticConfig
}

export function RingProgressChartCP(props: IRingProgressChartCPProps): JSX.Element {
    return (
        <WrapperSCP>
            {/* <RingProgress
                color={[`${CostumizedThemeConfig.primaryColor}`, `${CostumizedCostumizedThemeConfig.lightGray}`]}
                height={props.height ?? 100}
                width={props.width ?? 100}
                autoFit={props.autoFit}
                percent={props.percent}
                statistic={{
                    title: {
                        formatter: () => props.label,
                        style: {
                            color: `${CostumizedThemeConfig.primaryColor}`,
                            fontSize: '10px',
                            lineHeight: '14px',
                        }
                    }
                }}
                radius={props.radius ?? 0.88}
                innerRadius={props.innerRadius ?? 0.88}
            /> */}
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div`
    width: 100%;
    .g2-html-annotation{
        font-weight: 500!important;
        font-size: 12px!important;
        color: ${props => props.theme.primaryColor}!important;
    }
`