import { ImageBlockICP } from 'common/components/custom-photo-gallery/inner/ImageBlockICP'
import React from 'react'
import { Image } from 'antd'
import styled from 'styled-components'
import { IImageData } from 'common/components/custom-photo-gallery/inner/IImageData'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import _ from 'lodash'
interface IPhotoGalleryCPProps {
    images: IImageData[]
}

export function PhotoGalleryCP(props: IPhotoGalleryCPProps): JSX.Element {

    if (_.isEmpty(props.images))
        return (<EmptyCP description={'Você ainda não adicionou fotos a este albúm'} />)

    return (
        <WrapperSCP>
            <Image.PreviewGroup>
                {
                    props.images.map(image => (
                        <ImageBlockICP
                            key={`${image.url}_key`}
                            url={image.url}
                            previewWidth={'100%'}
                            onDelete={image.onDelete}
                            loading={image.loading}
                            previewConfig={image.previewConfig}
                        />
                    ))
                }
            </Image.PreviewGroup>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
  
    @media(max-width: 768px){
        grid-template-columns:1fr;  
   }
`
