import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { IReportFiltersRequestDTO } from 'modules/report/services/dtos/requests/IReportFiltersRequestDTO'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'

export class ReportRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/reports`
    private constructor() { }

    static fieldRevenue = (dto: IReportFiltersRequestDTO): RequestConfigTP<IReportFiltersRequestDTO> => ({
        url: `${ReportRequests.baseApiUrl}/field-revenue`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getQualityReport = (dto: ISearchReportReqDTO): RequestConfigTP => ({
        url: `${ReportRequests.baseApiUrl}/quality`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getProductionReport = (dto: ISearchReportReqDTO): RequestConfigTP => ({
        url: `${ReportRequests.baseApiUrl}/production`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getFieldReport = (dto: ISearchReportReqDTO): RequestConfigTP => ({
        url: `${ReportRequests.baseApiUrl}/field`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getBatchRanking = (dto: ISearchReportReqDTO): RequestConfigTP => ({
        url: `${ReportRequests.baseApiUrl}/batch-ranking`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getFinancialReport = (dto: ISearchReportReqDTO): RequestConfigTP => ({
        url: `${ReportRequests.baseApiUrl}/financial`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getFinancialReportByField = (dto: ISearchReportReqDTO): RequestConfigTP => ({
        url: `${ReportRequests.baseApiUrl}/financial-by-field`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getCostPerCategory = (dto: ISearchReportReqDTO): RequestConfigTP => ({
        url: `${ReportRequests.baseApiUrl}/cost-per-category`,
        method: HttpMethodEnum.GET,
        params: dto,
    })
}