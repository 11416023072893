import React from 'react'
import styled from 'styled-components'
import { SensoryAnalyseDetailsCP } from 'modules/batch/components/batch-analyses/inner/SensoryAnalyseDetailsCP'
import { SensoryAnalisysRadarCP } from 'modules/analysis/components/sensory-radar/SensoryAnalisysRadarCP'

import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { TextCP } from 'common/components/text/TextCP'
import { IBatchSensoryAnalysisDTO } from '../../../batch/services/dtos/responses/IBatchAnalysisResponseDTO'

interface ISensoryAnalysesResumeContentCPProps {
    item: IBatchSensoryAnalysisDTO
    language?: LanguageEnum
}

export function SensoryAnalysesResumeContentCP(
    props: ISensoryAnalysesResumeContentCPProps,
): JSX.Element {
    const isSpecialText = props.language === LanguageEnum.EN ? 'Not special' : 'Não especial'
    const scoreText = props.language === LanguageEnum.EN ? 'points' : 'pontos'
    const placeholderText =
        props.language === LanguageEnum.EN
            ? 'Non special coffee'
            : 'Café não pontuou como especial.'

    return (
        <ContentWrapperSCP>
            {props.item.notSpecial ? (
                <ScoreSCP>{isSpecialText}</ScoreSCP>
            ) : (
                <ScoreSCP>
                    {props.item.score}
                    {' '}
                    {scoreText}
                </ScoreSCP>
            )}
            {!!props.item.score && props.item.score >= 80 ? (
                <>
                    <SensoryAnalisysRadarCP item={props.item} language={props.language} />
                    <SensoryAnalyseDetailsCP
                        item={props.item}
                        language={props.language}
                        hideNotes={props.language === LanguageEnum.EN}
                    />
                </>
            ) : (
                <TextCP
                    text={props.item.notes ?? placeholderText}
                    style={{ display: 'flex', justifyContent: 'center' }}
                />
            )}
        </ContentWrapperSCP>
    )
}
const ContentWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
    background: #fff;
    @media (max-width: 768px) {
        padding: 0;
    }
`
const ScoreSCP = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    color: ${(props) => props.theme.primaryColor};
`
