export enum StructureTypeEnum {
    TERRACE = 'TERRACE',
    AFRICAN_BED = 'AFRICAN_BED',
    GREENHOUSE = 'GREENHOUSE',
    GREENHOUSE_AFRICAN_BED = 'GREENHOUSE_AFRICAN_BED',
    ROTARY_DRYER = 'ROTARY_DRYER',
    STATIC_DRYER = 'STATIC_DRYER',
    SEMI_STATIC_DRYER = 'SEMI_STATIC_DRYER',
    SHADED_TERRACE = 'SHADED_TERRACE',
    SHADED_AFRICAN_BED = 'SHADED_AFRICAN_BED',
    FERMENTATION_TANK = 'FERMENTATION_TANK',
    WASHER = 'WASHER',
    PULPER = 'PULPER',
    HOPPER = 'HOPPER',
    GRANARY = 'GRANARY',
}