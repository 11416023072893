import React from 'react'
import styled from 'styled-components'
import { TextCP } from 'common/components/text/TextCP'
import { SystemConfig } from 'config/SystemConfig'

interface IPropertyCardHeaderICPProps{
    imageUrl?: string
    name: string
    region: string
}

export function PropertyCardHeaderICP(props: IPropertyCardHeaderICPProps): JSX.Element {
    const placeholder = `${SystemConfig.getInstance().uiBaseUrl}/assets/farm-placeholder.png`
    return (
        <WrapperSCP>
            <ImageSCP imageUrl={props.imageUrl ?? placeholder} />
            <TitleWrapper>
                <TextCP text={props.name} size={'extraLarge'} strong={true} />
                <TextCP text={props.region} size={'normal'} />
            </TitleWrapper>
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div`
  
`
const ImageSCP = styled.div<{imageUrl: string}>`
    height: 200px;
    width: 100%;
    background: ${props => `url(${props.imageUrl})`};
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px 10px 0 0;
`
const TitleWrapper = styled.div`
    padding: 15px 15px 0 15px; 
`
