import { faBoxes, faCubes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { BatchGenerationDrawerCP } from 'modules/batch/components/batch-generation-drawer/BatchGenerationDrawerCP'
import React, { useState } from 'react'
import { BatchListCP } from '../components/batch-list/BatchListCP'
import { MergeOrSplitActionCP } from 'modules/batch/components/batch-list/inner/MergeOrSplitActionCP'
import { SearchActions } from 'common/components/list/inner/SearchActions'

export function BatchListScreen(): JSX.Element {

    const [batchGenerationVisible, setGenerationVisible] = useState<boolean>(false)

    return (
        <LayoutCP
            header={
                <HeaderCP icon={<FontAwesomeIcon icon={faCubes} />} title={'Lotes'}>
                    {
                        <MergeOrSplitActionCP />
                    }
                    <ButtonCP
                        type={'ghost'}
                        onClick={() => setGenerationVisible(true)}
                        icon={<FontAwsomeIconCP icon={faBoxes} />}
                        loading={false}
                    >
                        Gerar lotes
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>

                    <BatchListCP />
                    <BatchGenerationDrawerCP
                        show={batchGenerationVisible}
                        onCancel={() => setGenerationVisible(false)}
                        onSuccess={() => {
                            setGenerationVisible(false)
                            ListActions.mustUpdate()
                        }}
                    />

                </ContentCP>
            }
        />
    )
}