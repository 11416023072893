import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { FormItemICP } from 'common/components/form-fields/inner/FormItemICP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { RadioGroupCP, RadioOptionProps } from 'common/components/radio-group/RadioGroupCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { ProductFormValidator } from 'modules/maneuver/components/product-drawer/inner/ProductFormValidator'
import { ProductTypeSelectorCP } from 'modules/maneuver/components/product-type-selector/ProductTypeSelectorCP'
import { PurchaseListDrawerCP } from 'modules/maneuver/components/purchase-list/PurchaseListDrawerCP'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'
import { ISaveProductRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveProductRequestDTO'
import { ISearchProductRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchProductRequestDTO'
import { IProductResponseDTO } from 'modules/maneuver/services/dtos/responses/IProductResponseDTO'
import { ProductRequests } from 'modules/maneuver/services/ProductRequests'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface IProductDrawerPProps {
    productCode?: number
    show: boolean
    onCancel: () => void
    onSuccess: () => void
}

export function ProductDrawerCP(props: IProductDrawerPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [formValidator, setFormValidator] = useState<ProductFormValidator>(new ProductFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const [productCode, setProductCode] = useState<number>()
    const [productName, setProductName] = useState<string>('')
    const [purchaseListDrawerVisible, setPurchaseListDrawerVisible] = useState<boolean>(false)

    const request = useRequest<IProductResponseDTO, ISaveProductRequestDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const getDetailsReq = useRequest<IProductResponseDTO, ISearchProductRequestDTO>()
    useEffect(onGetReqChange, [getDetailsReq.awaiting])

    useEffect(getProductDetails, [props.productCode, props.show])

    function getProductDetails(): void {
        if (!!props.productCode && props.show) {
            getDetailsReq.runRequest(ProductRequests.getProduct(props.productCode))
            setProductCode(props.productCode)
        }
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar produto!')
        }

        setProductCode(request.returnData.code)
        setProductName(request.returnData.name)
        setPurchaseListDrawerVisible(true)

        ListActions.mustUpdate()
        formStateManager.reset()
        setFormValidator(new ProductFormValidator())
        props.onSuccess()

    }

    function onGetReqChange(): void {
        if (getDetailsReq.awaiting || !getDetailsReq.tried)
            return

        if (!getDetailsReq.success || !getDetailsReq.returnData) {
            if (getDetailsReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getDetailsReq.returnData, getDetailsReq.error)
            return RequestUtils.showDefaultErrorNotification(getDetailsReq.error, 'Erro ao buscar produto!')
        }
        setProductCode(getDetailsReq.returnData.code)
        setProductName(getDetailsReq.returnData.name)

        setFormValidator(new ProductFormValidator(getDetailsReq.returnData))
    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)

        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveProductRequestDTO = {
            name: formValues.name,
            type: formValues.type,
            unitOfMeasurement: formValues.unitOfMeasurement,
            remainingQuantity: formValues.remainingQuantity
        }

        request.runRequest(props.productCode ?
            ProductRequests.updateProduct(props.productCode, dto) :
            ProductRequests.saveProduct(dto))
    }

    function getUnitOptions(): RadioOptionProps[] {
        return Object.values(UnitOfMeasurementEnum).map(e => ({ content: ProductUtils.translateUnitOfMeasurement(e), value: e }))
    }

    return (
        <DrawerCP
            title={props.productCode ? `Editar produto #${props.productCode}` : 'Novo produto'}
            visible={props.show}
            width={'40%'}
            onClose={() => {
                formStateManager.reset()
                setFormValidator(new ProductFormValidator())
                props.onCancel()
            }}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        size={'large'}
                        onClick={onFormSubmit}
                        wrapperStyle={{ width: '100%' }}
                        loading={request.awaiting || getDetailsReq.awaiting}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >

            <WrapperSCP>
                <FormWrapperSCP>
                    <InputCP
                        label={'Nome do produto'}
                        fieldName={'name'}
                        formStateManager={formStateManager}
                        required={true}
                        fontSize={screenSize.smd ? 'large' : 'normal'}

                    />
                    <TwoColumnsSCP>
                        <ProductTypeSelectorCP
                            label={'Selecione o tipo'}
                            fieldName={'type'}
                            formStateManager={formStateManager}
                            required={true}
                        />

                        <FormItemICP
                            required={true}
                            formStateManager={formStateManager}
                            fieldName={'unitOfMeasurement'}
                            label={'Unidade de medida:'}
                        >
                            <RadioGroupCP
                                type={'button'}
                                options={getUnitOptions()}
                                selected={formStateManager.getFieldValue('unitOfMeasurement')}
                            />
                        </FormItemICP>
                    </TwoColumnsSCP>
                </FormWrapperSCP>

                {
                    !!productCode && !!productName &&
                    <PurchaseListDrawerCP
                        show={purchaseListDrawerVisible}
                        onCancel={() => setPurchaseListDrawerVisible(false)}
                        productCode={productCode}
                        productName={productName}
                    />
                }

            </WrapperSCP>

        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
  
`

const ButtonWrapperSCP = styled.div`
    width: 100%;
    .ant-btn {
        width: 100%;
    }
`

const FormWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`

const TwoColumnsSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    @media(max-width: 767px){
        grid-template-columns: 1fr;
    }
`
