import { ButtonCP } from 'common/components/button/ButtonCP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IFinancialReportByFieldResDTO } from 'modules/report/services/dtos/responses/IFinancialReportByFieldResDTO'
import React from 'react'
import styled, { CSSProperties } from 'styled-components'

interface IMobileListItemICPProps {
    key: string
    loading: boolean
    data: Array<{
        label: string,
        value: string,
        labelStyle?: CSSProperties,
        valueStyle?: CSSProperties,
    }>
    actions?: Array<{
        label: string,
        action: () => void,
        icon: JSX.Element,
    }>
    noPadding?: boolean
}

export function MobileListItemICP(props: IMobileListItemICPProps): JSX.Element {
    const screenSize = useScreenSize()

    return (
        <ListItemICP
            key={props.key}
            isMobile={screenSize.smd}
            loading={props.loading}
            style={{ padding: '0', margin: '0', backgroundColor: 'transparent' }}
            customSkeleton={<GenericRowSkeletonICP rows={1} columns={8} />}
        >
            <WrapperSCP style={{ padding: props.noPadding ? '0px' : '15px' }}>
                {
                    props.data.map(data => (
                        <RowSCP key={data.label}>
                            <TextCP text={`${data.label}: `} style={{ ...data.labelStyle, fontWeight: 600 }} />
                            <TextCP text={data.value} style={data.valueStyle} />
                        </RowSCP>
                    ))
                }
                {
                    !!props.actions &&
                    <ActionsSCP>
                        {
                            props.actions.map(act => (
                                <ButtonCP
                                    key={act.label}
                                    wrapperStyle={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        cursor: 'pointer',
                                        width: '100%'
                                    }}
                                    style={{ width: '100%' }}
                                    icon={act.icon}
                                    onClick={act.action}
                                    type={'primary'}
                                />
                            ))
                        }

                    </ActionsSCP>
                }

            </WrapperSCP>

        </ListItemICP>
    )
}

const WrapperSCP = styled.div`
   background: white;
   
    border-bottom: 1px solid ${props => props.theme.primaryColor};
    display: flex;  
    flex-direction: column;
    row-gap: 10px;
`

const RowSCP = styled.div`
    display: flex;
    column-gap: 3px;
    flex-wrap: wrap;
    row-gap: 5px;
`

const ActionsSCP = styled.div`
    display: flex;
    column-gap: 3px;
`
