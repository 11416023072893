import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { FileUploadCP } from 'common/components/file-upload/FileUploadCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { NewProcessAlbumFormValidator } from 'modules/batch/components/batch-process-album-drawer/inner/NewProcessAlbumFormValidator'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IProcessAlbumRequestDTO } from 'modules/batch/services/dtos/requests/IProcessAlbumRequestDTO'
import { IProcessAlbumResponseDTO } from 'modules/batch/services/dtos/responses/IProcessAlbumResponseDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
interface INewAlbumDrawerICPProps {
    batchCode: number
    processCode: number
    albumCode?: number
    onSuccess: () => void
    show: boolean
    onCancel: () => void
}

export function NewAlbumDrawerICP(props: INewAlbumDrawerICPProps): JSX.Element {
    const [formValidator, setFormValidator] = useState<NewProcessAlbumFormValidator>(new NewProcessAlbumFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const [files, setFiles] = useState<any>([])

    const pictureTypes = [
        { mime: 'image/jpeg', extension: 'jpeg' },
        { mime: 'image/jpg', extension: 'jpg' },
        { mime: 'image/png', extension: 'png' },
    ]

    useEffect(findAlbumData, [props.albumCode, props.show])

    const findReq = useRequest<IProcessAlbumResponseDTO>()
    useEffect(onFindRequestChange, [findReq.awaiting])

    const request = useRequest<IProcessAlbumResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const uploadReq = useRequest<IProcessAlbumResponseDTO>()
    useEffect(onUploadReqChange, [uploadReq.awaiting])

    function findAlbumData(): void {
        if (!!props.albumCode && !!props.show)
            findReq.runRequest(BatchRequests.findProcessAlbum(props.batchCode, props.processCode, props.albumCode))
    }

    function appendImages(option: any): void {
        setFiles([...files, option.file])
    }

    function onFindRequestChange(): void {

        if (findReq.awaiting || !findReq.tried)
            return

        if (!findReq.success || !findReq.returnData) {
            if (findReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', findReq.returnData, findReq.error)
            return RequestUtils.showDefaultErrorNotification(findReq.error, 'Erro ao buscar dados!')
        }

        setFormValidator(new NewProcessAlbumFormValidator(findReq.returnData))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar coleção!')
        }

        if (!props.albumCode)
            return uploadPictures(request.returnData.code)

        props.onSuccess()
    }

    function onUploadReqChange(): void {

        if (uploadReq.awaiting || !uploadReq.tried)
            return

        if (!uploadReq.success || !uploadReq.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', uploadReq.returnData, uploadReq.error)
            return RequestUtils.showDefaultErrorNotification(uploadReq.error, 'Erro ao fazer upload das imagens!')
        }

        props.onSuccess()
    }

    async function onSave(): Promise<void> {
        const formValues = formStateManager.getFormValues()

        if (!await formStateManager.validate() || !formValues)
            return

        const dto: IProcessAlbumRequestDTO = {
            date: DateUtils.getDateFormattedToApi(formValues.date),
            notes: formValues.notes,
            pictures: formValues.pictures
        }

        request.runRequest(
            props.albumCode ?
                BatchRequests.updateProcessAlbum(props.batchCode, props.processCode, props.albumCode, dto)
                :
                BatchRequests.saveProcessAlbum(props.batchCode, props.processCode, dto)
        )
    }

    function uploadPictures(albumCode: number): void {
        const formData = new FormData()
        for (const file of files)
            formData.append('files', file)
        uploadReq.runRequest(BatchRequests.uploadImagesToAlbum(props.batchCode, props.processCode, albumCode, formData))
    }

    return (
        <DrawerCP
            title={`${props.albumCode ? 'Editar coleção' : 'Nova coleção'}`}
            visible={props.show}
            width={'40%'}
            onClose={() => {
                props.onCancel()
                formStateManager.reset()
                setFiles([])
            }}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onSave}
                        wrapperStyle={{ width: '100%' }}
                        loading={request.awaiting || uploadReq.awaiting}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >

            <WrapperSCP>
                <InputCP
                    label={'Data'}
                    placeholder={'Data'}
                    type={'date'}
                    fieldName={'date'}
                    mask={MaskUtils.applyDateMask}
                    formStateManager={formStateManager}
                />
                <TextAreaCP
                    label={'Descrição'}
                    fieldName={'notes'}
                    formStateManager={formStateManager}
                    required={false}
                />
                {
                    !props.albumCode &&
                    <FileUploadCP
                        uploadFiles={appendImages}
                        fileTypes={pictureTypes}
                        loading={false}
                        multiple={true}
                        showUploadList={true}
                    />
                }
            </WrapperSCP>

        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    
`