import { Divider, List } from 'antd'
import { ListActions } from 'common/components/list/inner/ListActions'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { IListCPProps, ListCP } from 'common/components/list/ListCP'
import { TextCP } from 'common/components/text/TextCP'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
declare type Fn = () => any
interface IListAndFiltersCPProps<T> {
    filtersCp: JSX.Element
    listProps: IListCPProps<T>
    clearFilters: () => void
    total: number
    totalSelected: number
    saveScrollPos?: (pos: number) => void
    loadMoreData: Fn
    onScroll?: (e: MouseEvent) => void

}

export function ListAndFiltersCP<T>(props: IListAndFiltersCPProps<T>): JSX.Element {

    const scrollPos: number | null = useSelector((state: IReduxState) => _.get(state, 'searchState.scrollPosition'))
    const listRef = useRef<any>()
    useEffect(saveScroll, [listRef.current])
    useEffect(setScollPos, [props.listProps.loading])

    function saveScroll(): any {
        if (!!listRef && !!listRef.current) {
            listRef.current.addEventListener('scroll', (e) => {
                SearchActions.saveScrollPosition<T>(e.target.scrollTop)
            })

            return () => {
                listRef.current.removeEventListener('scroll', (e) => {
                    console.log(e)
                })
            }
        }
    }

    function setScollPos(): void {

        if (!!scrollPos && !props.listProps.loading)
            listRef.current.scrollTo(0, scrollPos)
    }


    return (
        <WrapperSCP>
            <ListWrapperSCP id={'scrollableDiv'} ref={listRef}>
                {props.filtersCp}
                <TotalSCP>
                    <TextCP
                        text={`${props.total} registros encontrados`}
                    />
                    <ActionsWprSCP>
                        {
                            !!props.totalSelected &&
                            <TextCP
                                text={'Limpar selecionados'}
                                onClick={() => ListActions.unselectAll()}
                                style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
                            />
                        }
                        <TextCP
                            text={'Limpar filtros'}
                            onClick={props.clearFilters}
                            style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
                        />
                    </ActionsWprSCP>

                </TotalSCP>
                <InfiniteScroll
                    dataLength={props.listProps.dataSource?.length ?? 0}
                    next={props.loadMoreData}
                    hasMore={props.listProps.dataSource?.length! < props.total}
                    loader={<span>...</span>}
                    endMessage={<Divider plain={true}>Isso é tudo.</Divider>}
                    scrollableTarget={'scrollableDiv'}
                    onScroll={props.onScroll}
                >
                    <List<T>
                        {...props.listProps}
                    />
                </InfiniteScroll>
            </ListWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    border-radius: 10px;
    @media(max-width: 786px){
        margin-left: 0;    
        margin-bottom: 10em;
    }
`

const ListWrapperSCP = styled.div`
      max-height: 83vh;
    overflow: overlay;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }

    .ant-list {
        border-radius: 10px;
        height: 100%;
        overflow-x: hidden!important;
        padding: 0!important;
        .ant-spin-nested-loading {
            flex: 1;
            
            .ant-spin-dot-item{
                background-color: ${props => props.theme.primaryColor};
            }
        }
        .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot{
            .ant-spin-dot-item{
                background-color: ${props => props.theme.primaryColor};
            }
        }
    }

    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    }

`

const TotalSCP = styled.div`
    margin-left: 3px;
        display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      
   
    }
    @media(max-width: 768px){        
    margin-top: 7px;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`
const ActionsWprSCP = styled.div`
    display: flex;
    column-gap: 10px;
`