import { HarvestLogbookScreen } from 'modules/harvest-planning/screens/HarvestLogbookScreen'
import { HarvestPlanningScreen } from 'modules/harvest-planning/screens/HarvestPlanningScreen'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

HarvestRoutes.ROOT = '/colheita'
HarvestRoutes.PLANNING = `${HarvestRoutes.ROOT}/planejamento`
HarvestRoutes.LOGBOOK_WITHOUT_PARAM = `${HarvestRoutes.ROOT}/logbook`
HarvestRoutes.LOGBOOK = `${HarvestRoutes.ROOT}/logbook/:code`

export function HarvestRoutes(): JSX.Element {
    return (
        <Switch>
            <Route path={HarvestRoutes.ROOT} exact={true} component={HarvestPlanningScreen} />
            <Route path={HarvestRoutes.PLANNING} component={HarvestPlanningScreen} />
            <Route path={HarvestRoutes.LOGBOOK} component={HarvestLogbookScreen} />
        </Switch>
    )
}
