import { AverageScoresReportCardsICP } from 'modules/report/components/quality-report-grid/inner/AverageScoresReportCardsICP'
import { FlavorAndAromaWidgetICP } from 'modules/report/components/quality-report-grid/inner/FlavorAndAromaWidgetICP'
import { TotalBatchesReportCardICP } from 'modules/report/components/quality-report-grid/inner/TotalBatchesReportCardICP'
import { TotalSpecialReportCardICP } from 'modules/report/components/quality-report-grid/inner/TotalSpecialReportCardICP'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'

import { QualityReportDataTP } from 'modules/report/types/QualityReportDataTP'
import React from 'react'
import styled from 'styled-components'

interface IQualityReportGridCPProps {
    filters?: ISearchReportReqDTO
    reportData?: QualityReportDataTP
    loading?: boolean
}

export function QualityReportGridCP(props: IQualityReportGridCPProps): JSX.Element {

    return (
        <GridLayoutSCP>
            <TotalBatchesReportCardICP
                total={props.reportData?.totalBatches}
                withSensorialAnalyses={props.reportData?.withSensorialAnalyses}
                withPhysicalAnalyses={props.reportData?.withPhysicalAnalyses}
                loading={props.loading}
            />
            <AverageScoresReportCardsICP
                averageScore={props.reportData?.averageScore}
                averagePicking={props.reportData?.averagePicking}
                loading={props.loading}
            />
            <TotalSpecialReportCardICP
                totalSpecial={props.reportData?.totalSpecial}
                total={props.reportData?.withSensorialAnalyses}
                loading={props.loading}
            />
            <FlavorAndAromaWidgetICP
                mostCommonAromas={props.reportData?.mostCommonAromas}
                mostCommonDefects={props.reportData?.mostCommonDefects}
                loading={props.loading}
            />
        </GridLayoutSCP>
    )
}

const GridLayoutSCP = styled.div`
  display: grid;
  row-gap: 10px;
  margin-top: 10px;
  column-gap: 10px;
  grid-template-columns:29% 22% 47%;
  grid-template-rows: auto;
  grid-template-areas: 
    "total-batches sensory-average total-special"
    "total-batches physical-average flavor-aroma";
    @media(max-width: 768px){
        grid-template-columns: 100%;
        grid-template-areas: 
            "total-batches"
            "total-batches"
            "sensory-average"
            "physical-average "
            "total-special"
            "flavor-aroma";
       
    }
`
