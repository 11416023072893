import { CheckboxCP } from 'common/components/form-fields/checkbox/CheckboxCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { MaskUtils } from 'common/utils/MaskUtils'
import { BatchSaleFormValidator } from 'modules/batch/components/batch-sale-form-drawer/inner/BatchSaleFormValidator'
import React from 'react'
import styled from 'styled-components'

interface ISaleFormICPProps {
    formStateManager: IFormStateManager<BatchSaleFormValidator>
    loading?: boolean
    isPaid: boolean
    markAsPaid: (isPaid: boolean) => void
}

export function SaleFormICP(props: ISaleFormICPProps): JSX.Element {

    return (
        <WrapperSCP>

            <InputCP
                label={'Comprador'}
                type={'text'}
                fieldName={'buyer'}
                formStateManager={props.formStateManager}
                required={true}
                loading={props.loading}
            />

            <TwoColWrapperSCP>
                <InputCP
                    label={'Quantidade de sacas'}
                    type={'number'}
                    fieldName={'bags'}
                    formStateManager={props.formStateManager}
                    min={1}
                    required={true}
                    loading={props.loading}

                />
                <InputCP
                    label={'Valor da saca'}
                    required={true}
                    type={'currency'}
                    formStateManager={props.formStateManager}
                    fieldName={'unitValue'}
                    loading={props.loading}
                />
            </TwoColWrapperSCP>
            <TwoColWrapperSCP>
                <InputCP
                    label={'Data da venda'}
                    placeholder={'__/__/____'}
                    fieldName={'date'}
                    type={'date'}
                    formStateManager={props.formStateManager}
                    required={true}
                    mask={MaskUtils.applyDateMask}
                    maxLength={10}
                    loading={props.loading}
                />
                <InputCP
                    label={'Data do pagamento'}
                    placeholder={'__/__/____'}
                    fieldName={'paymentDate'}
                    type={'date'}
                    formStateManager={props.formStateManager}
                    required={false}
                    mask={MaskUtils.applyDateMask}
                    maxLength={10}
                    loading={props.loading}
                />
                <CheckboxWrapperSCP>
                    <CheckboxCP
                        isChecked={props.isPaid}
                        onChange={props.markAsPaid}
                        label={'Marcar como pago'}
                    />
                </CheckboxWrapperSCP>
            </TwoColWrapperSCP>

            <TextAreaCP
                label={'Observação'}
                fieldName={'notes'}
                formStateManager={props.formStateManager}
                required={false}
                loading={props.loading}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`

const CheckboxWrapperSCP = styled.div`
    align-items: start;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 15px;
`