import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'

import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { BatchProcessLogbookFormValidator } from 'modules/batch/components/batch-process-logbook-drawer/inner/BatchProcessLogbookFormValidator'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { ISaveBatchProcessLogbookReqDTO } from 'modules/batch/services/dtos/requests/ISaveBatchProcessLogbookReqDTO'
import { ISaveBatchProcessLogbookResDTO } from 'modules/batch/services/dtos/responses/ISaveBatchProcessLogbookResDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IBatchProcessLogbookFormICPProps {
    batchCode: number
    processCode: number
    batchProcessLogbookCode?: number
    show: boolean
    onCancel: () => void
    onSuccess: () => void
}

export function BatchProcessLogbookFormICP(props: IBatchProcessLogbookFormICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [formValidator, setFormValidator] = useState<BatchProcessLogbookFormValidator>(new BatchProcessLogbookFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const request = useRequest<ISaveBatchProcessLogbookResDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const findOneReq = useRequest<ISaveBatchProcessLogbookResDTO>()
    useEffect(onFindOneRequestChange, [findOneReq.awaiting])

    useEffect(() => { init() }, [props.batchProcessLogbookCode, props.show])

    async function init(): Promise<void> {
        if (!!props.show && !!props.batchProcessLogbookCode)
            await findOneReq.runRequest(BatchRequests.findProcessLogbook(props.batchCode, props.processCode, props.batchProcessLogbookCode))

    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveBatchProcessLogbookReqDTO = {
            date: DateUtils.getDateFormattedToApi(formValues.date),
            averageTemperature: +formValues.averageTemperature,
            humidity: +formValues.humidity,
            turnings: +formValues.turnings,
            notes: formValues.notes
        }

        request.runRequest(!props.batchProcessLogbookCode ?
            BatchRequests.saveProcessLogbook(props.batchCode, props.processCode, dto)
            : BatchRequests.updateProcessLogbook(props.batchCode, props.processCode, props.batchProcessLogbookCode, dto))
    }

    function onFindOneRequestChange(): void {

        if (findOneReq.awaiting || !findOneReq.tried)
            return

        if (!findOneReq.success || !findOneReq.returnData) {
            if (findOneReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', findOneReq.returnData, findOneReq.error)
            return RequestUtils.showDefaultErrorNotification(findOneReq.error, 'Erro ao buscar dados do registro!')
        }

        setFormValidator(new BatchProcessLogbookFormValidator(findOneReq.returnData))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar registro!')
        }
        formStateManager.reset()
        props.onSuccess()
    }

    return (
        <DrawerCP
            title={'Novo registro'}
            visible={props.show}
            width={'30%'}
            onClose={() => {
                props.onCancel()
                formStateManager.reset()
            }}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFormSubmit}
                        wrapperStyle={{ width: '100%' }}
                        loading={request.awaiting || findOneReq.awaiting}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >
            <WrapperSCP>
                <ColumnsWrapperSCP>
                    <InputCP
                        label={'Data'}
                        placeholder={'__/__/____'}
                        fieldName={'date'}
                        type={'date'}
                        formStateManager={formStateManager}
                        required={true}
                        mask={MaskUtils.applyDateMask}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        maxLength={10}
                        loading={request.awaiting || findOneReq.awaiting}
                    />
                    <InputCP
                        label={'Temperatura média'}
                        type={'number'}
                        fieldName={'averageTemperature'}
                        formStateManager={formStateManager}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        loading={request.awaiting || findOneReq.awaiting}
                        icon={<span>°C</span>}
                    />
                    <InputCP
                        label={'Umidade'}
                        type={'number'}
                        fieldName={'humidity'}
                        formStateManager={formStateManager}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        loading={request.awaiting || findOneReq.awaiting}
                        icon={<span>%</span>}
                    />
                    <InputCP
                        label={'Quantas vezes revolveu'}
                        type={'number'}
                        fieldName={'turnings'}
                        formStateManager={formStateManager}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        loading={request.awaiting || findOneReq.awaiting}
                    />
                </ColumnsWrapperSCP>
                <TextAreaCP
                    label={'Observação'}
                    fieldName={'notes'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    loading={request.awaiting || findOneReq.awaiting}
                />
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`
const ColumnsWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`
