import { DividerCP } from 'common/components/divider/DividerCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { PhysicalAnalysesResumeContentCP } from 'modules/batch/components/batch-analyses/inner/PhysicalAnalysesResumeContentCP'
import React from 'react'
import styled from 'styled-components'
import { IBatchPhysicalAnalysisResponseDTO } from '../../../batch/services/dtos/responses/IBatchAnalysisResponseDTO'

interface IPhysicalAnalysisListCPProps {
    analysis: IBatchPhysicalAnalysisResponseDTO[]
}

export function PhysicalAnalysisListCP(props: IPhysicalAnalysisListCPProps): JSX.Element {
    if (!props.analysis.length)
        return <EmptyCP description={'Este lote ainda não possui análises físicas'} />

    return (
        <WrapperSCP>
            {props.analysis.map((analysis: IBatchPhysicalAnalysisResponseDTO) => (
                <AnalysesWrapperSCP key={analysis.id}>
                    <DividerCP
                        text={`${DateUtils.formatDate(
                            analysis.createdAt,
                            DateFormatEnum.BR_WITHOUT_TIME,
                        )}`}
                        orientation={'left'}
                    />
                    <PhysicalAnalysesResumeContentCP item={analysis} />
                </AnalysesWrapperSCP>
            ))}
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: #fff;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8em;
    max-height: 65vh;
    overflow: overlay;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: #f5f5f5;
    }
    ::-webkit-scrollbar {
        width: 2px;
        border-radius: 1px;
        background-color: #f5f5f5;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.primaryColor};
    }
    @media (max-width: 768px) and (max-height: 680px) {
        max-height: 52vh;
    }
    @media (max-width: 768px) and (min-height: 600px) {
        max-height: 61vh;
    }
    @media (max-height: 680px) {
        max-height: 52vh;
    }
`

const AnalysesWrapperSCP = styled.div``
