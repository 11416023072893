import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'

export class ReportActions {

    static readonly TYPE_CHILD_MENU_OPENED = 'TYPE_CHILD_MENU_OPENED'
    static readonly TYPE_INSTRUCTIONS_TYPE_FILTERED = 'TYPE_INSTRUCTIONS_TYPE_FILTERED'

    private constructor() { /* private constructor to avoid instantiation */ }

    static toggleChildMenu(opened: boolean): void {
        ReduxHelper.getInstance().getStore()
            .dispatch({ type: this.TYPE_CHILD_MENU_OPENED, payload: opened })
    }

}
