import { AltitudeIconCP } from 'common/components/icons/AltitudeIconCP'
import { CoffeeVarietyIconCP } from 'common/components/icons/CoffeeVarietyIconCP'
import { PropertyIconCP } from 'common/components/icons/PropertyIconCP'
import { RegionBrasilIconCP } from 'common/components/icons/RegionBrasilIconCP'
import { TextCP } from 'common/components/text/TextCP'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { ProducerCardICP } from 'modules/batch-presentation/components/producer-carrousel/inner/ProducerCardICP'
import { IProducerResumeRedDTO } from 'modules/batch-presentation/services/dtos/response/IProducerResumeRedDTO'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { BatchPropertyItemICP } from '../../batch-details-icon-grid/inner/BatchPropertyItemICP'

interface IProducerContentICPProps {
    producer: IProducerResumeRedDTO
}

export function ProducerContentICP(props: IProducerContentICPProps): JSX.Element {
    const currentLang = useSelector((state: IReduxState) => state.language)
    const description = currentLang === 'en' ? props.producer.descriptionEng ?? '' : props.producer.description ?? ''

    return (
        <WrapperSCP>
            {/* <TextCP
                text={props.producer.propertyName}
                style={{ fontSize: '23px', fontWeight: 600 }}
            />
            <TextCP
                text={props.producer.propertyRegion}
                style={{ fontSize: '18px', fontWeight: 600 }}
            /> */}
            <GridSCP>

                <BatchPropertyItemICP
                    icon={<PropertyIconCP />}
                    label={'property'}
                    value={[props.producer.propertyName]}
                    noTranslationValue={true}
                />

                <BatchPropertyItemICP
                    icon={<RegionBrasilIconCP />}
                    label={'region'}
                    value={[props.producer.propertyRegion]}
                    noTranslationLabel={false}
                    noTranslationValue={true}
                />

            </GridSCP>



            <DescWppSCP>
                <TextCP
                    text={description}
                    style={{ fontSize: '15px', fontStyle: 'italic' }}
                />
            </DescWppSCP>

            {/* <ProducerCardICP
                name={props.producer.person.name}
                photo={props.producer.person.photo}
            /> */}
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 1em 0 0 4em;
    margin-bottom: 5em;
    @media(max-width: 768px){
        padding: 10px;
    }
    .ant-typography{
        color: white!important;
    }
   
`

const DescWppSCP = styled.div`
     margin-top: 3em;
`

const GridSCP = styled.div`
    display: grid;
    width: 50%;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    margin: 10px 0;
    @media(max-width: 768px){
        width: 100%;
        grid-template-columns: 1fr;
    }
`