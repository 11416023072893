import { CarouselCP } from 'common/components/carousel/CarouselCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { BatchPresentationRequests } from 'modules/batch-presentation/services/BatchPresentationRequests'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Carousel } from 'antd'
import { ColorUtils } from 'common/utils/ColorUtils'

type RouteParamsTP = { batchCode: string }
interface IBatchSlideshowCPProps {
}

export function BatchSlideshowCP(props: IBatchSlideshowCPProps): JSX.Element {
    const routeParams = useParams<RouteParamsTP>()
    const [pictures, setPictures] = useState<string[]>()

    const [batchCode, setBatchCode] = useState<number>()
    useEffect(() => setBatchCode(+routeParams.batchCode), [routeParams])

    const getInfoReq = useRequest<string[]>()
    useEffect(onRequestChange, [getInfoReq.awaiting])

    useEffect(getDetails, [batchCode])

    function getDetails(): void {
        if (!!batchCode)
            getInfoReq.runRequest(BatchPresentationRequests.getAllPictures(batchCode))
    }

    function onRequestChange(): void {

        if (getInfoReq.awaiting || !getInfoReq.tried)
            return

        if (!getInfoReq.success) {
            if (getInfoReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getInfoReq.returnData, getInfoReq.error)
            return RequestUtils.showDefaultErrorNotification(getInfoReq.error, 'Some error occurred. Please, try to reload the page.')
        }

        setPictures(getInfoReq.returnData)
    }

    if (!pictures || getInfoReq.awaiting) {
        return (
            <div>  </div>
        )
    }
    return (
        <WrapperSCP>
            <Carousel autoplay={true} dots={false} effect={'fade'}>
                {
                    pictures.map(pic => (
                        <CarrouselWppSCP key={pic}>
                            <ImgWppSCP style={{ backgroundImage: `url(${pic})` }} />
                            <MaskSCP />
                            <Mask2SCP />
                        </CarrouselWppSCP>
                    ))
                }
            </Carousel>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    min-height: auto;
    width: 100%;
    position: relative;
    top: -1em;
    right: -4em;
    overflow: hidden;
   
    @media(max-width: 768px){
        display: none;
    }
`

const ImgWppSCP = styled.div`
    min-height: 78vh;
    width: 100%;
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    
    @media(min-width: 1400px){     
        min-height: 55vh;
    }
`

const CarrouselWppSCP = styled.div`
    position:relative;
`

const MaskSCP = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background: ${props => props.theme.primaryColor};
    background: ${props => `linear-gradient(90deg, ${props.theme.primaryColor} 0%, ${ColorUtils.hexToRgb(props.theme.primaryColor, 0.5)} 30%, ${ColorUtils.hexToRgb(props.theme.primaryColor, 0.1)} 100%)`};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Mask2SCP = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background: ${props => props.theme.primaryColor};
    background: ${props => `linear-gradient(0deg, ${props.theme.primaryColor} 0%, ${ColorUtils.hexToRgb(props.theme.primaryColor, 0.5)} 30%, ${ColorUtils.hexToRgb(props.theme.primaryColor, 0.1)} 100%)`};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`