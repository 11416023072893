import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { ManeuverLogbookContentCP } from 'modules/maneuver/components/maneuver-logbook-content/ManeuverLogbookContentCP'
import { ManeuverDrawerCP } from 'modules/maneuver/components/maneuver-drawer/ManeuverDrawerCP'
import { ManeuverRoutes } from 'modules/maneuver/ManeuverRoutes'
import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RecipeTypeEnum } from '../enums/RecipeTypeEnum'

type RouteParamsTP = { code?: string }

export function LogbookScreen(): JSX.Element {
    const [showFormDrawer, setShowFormDrawer] = useState<boolean>(false)
    const [maneuverCode, setManeuverCode] = useState<number>()
    const [type, setType] = useState<RecipeTypeEnum>()
    const history = useHistory()
    const routeParams = useParams<RouteParamsTP>()

    useEffect(() => {
        if (!routeParams.code)
            return

        setManeuverCode(+routeParams.code)
    }, [routeParams])

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={`Diário de bordo #${maneuverCode ?? '---'}`}
                    icon={
                        <FontAwsomeIconCP icon={faArrowLeft} onClick={() => history.push(ManeuverRoutes.ROOT)} size={'1x'} />
                    }
                >
                    <ButtonCP
                        icon={<FontAwsomeIconCP icon={faPen} />}
                        size={'middle'}
                        type={'ghost'}
                        onClick={() => setShowFormDrawer(true)}
                    >
                        Editar
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <ManeuverLogbookContentCP setType={setType} />
                    <ManeuverDrawerCP
                        maneuverCode={maneuverCode}
                        show={showFormDrawer}
                        recipeType={type}
                        onCancel={() => setShowFormDrawer(false)}
                        onSuccess={() => {
                            setShowFormDrawer(false)
                            ListActions.mustUpdate()
                        }}
                    />
                </ContentCP>
            }
        />
    )
}
