const ThemeConfig = {
    fontFamily: '\'Poppins\', sans-serif',

    primaryColor: '#7909ad',
    secondaryColor: '#4d4d4d',
    infoColor: '#1aa2c4',
    successColor: '#5ab37b',
    processingColor: '#1aa2c4',
    errorColor: '#c41a1a',
    highlightColor: '#c41a1a',
    warningColor: '#c4ab1a',
    normalColor: '#fff',
    fontSizeBase: '12px',
    fontSizeLg: '14px',
    white: '#ffffff',
    black: '#494949',
    gray: '#8e8e8e',
    lightGray: '#ececec',
    borderColorBase: '@primary-color',
    tooltipBg: '#7909ad',
    menuBg: '#fff',
    textSelectionBg: '#7909ad',
    checkboxColor: '@primary-color',
    statusColor: {
        awaitingBg: '#FFDE9F',
        inProgressBg: '#747474',
        doneBg: '#A9E0DC',
        awaiting: '#CBA355',
        inProgress: '#f1f1f1',
        done: '#3C9AA2'
    },

    layoutBodyBackground: '#F1F1F1',
    layoutHeaderBackground: '#fff',
    layoutFooterBackground: '@layout-body-background',
    layoutHeaderHeight: '72px',
    layoutSiderBackground: '@layout-header-background',

    inputBg: '#F5F5F5',
    inputHoverBorderColor: '@primary-color',

    btnPrimaryBg: '@primary-color',
    primary6: '@primary-color',
    linkColor: '@primary-color',

    fontSizes: {
        extraSmall: '.6rem',
        small: '.7rem',
        normal: '.85rem',
        large: '1rem',
        extraLarge: '1.3rem',
        title: '2.5rem',
    },

    spaces: [
        '.2rem',
        '.5rem',
        '.8rem',
        '1rem',
        '1.2rem',
        '1.5rem',
        '2rem',
        '2.4rem',
        '2.7rem',
        '3rem',
        '3.4rem',
        '3.5rem',
        '4rem'
    ]
}

module.exports = { ThemeConfig }
