import { TermsOfUseDrawerCP } from 'common/components/terms-of-use/TermsOfUseDrawerCP'
import { useRequest } from 'common/request-helper/UseRequest'
import * as _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { AuthActions } from 'modules/auth/AuthActions'
import { IUserLoginResponseDTO } from 'modules/auth/services/dtos/responses/IUserLoginResponseDTO'
import { SchemaActions } from 'modules/company/actions/SchemaActions'
import { RegistrationRoutes } from 'modules/registration/RegistrationRoutes'
import { useSelector } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { LayoutCP } from '../../../common/components/screen-layout/layout/LayoutCP'
import { useScreenSize } from '../../../common/hooks/UseScreenSize'
import { LoggedUserDataTP } from '../../../common/redux/ReduxTypes'
import { IReduxState } from '../../../common/redux/interfaces/IReduxState'
import { AppRoutes } from '../../app/AppRoutes'
import { ContentUserLoginCP } from '../components/content-user-login/ContentUserLoginCP'
import { SiderAuthCP } from '../components/sider-auth/SiderAuthCP'
const CLASS_MAIN_LAYOUT = 'layout_main'
interface IProps extends RouteComponentProps {
    history: any
}
export function UserLoginScreen(props: IProps): JSX.Element {
    const [mainInfoDrawerVisible, setMainInfoDrawerVisible] = useState<boolean>(false)

    const currentUserData: LoggedUserDataTP = useSelector((state: IReduxState) =>
        _.get(state, 'loggedUser'),)
    const loggedUser = _.get(currentUserData, 'user')
    const screenSize = useScreenSize()

    const request = useRequest<any>()
    // useEffect(onTermsReqChange, [request.awaiting])

    useEffect(() => SchemaActions.clearSchemaData(), [])

    // function onAcceptTerms(): void {
    //     request.runRequest(UserRequests.acceptTerms(loggedUser.id))
    // }

    // function onTermsReqChange(): void {
    //     if (request.awaiting || !request.tried)
    //         return

    //     if (!request.success)
    //         return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar termos de uso!')

    //     setMainInfoDrawerVisible(false)
    //     props.history.push(AppRoutes.ROOT)
    // }

    function goToLoggedScreen(user: IUserLoginResponseDTO): void {
        props.history.push(AppRoutes.ROOT)
    }

    // function onCloseModal(): void {
    //     setMainInfoDrawerVisible(false)
    //     AuthActions.logout()
    // }

    if (!_.isEmpty(loggedUser) && !mainInfoDrawerVisible)
        return <Redirect to={AppRoutes.ROOT} />

    return (
        <WrapperSCP>
            <LayoutCP
                mainLayout={true}
                sider={screenSize.smd ? undefined : <SiderAuthCP />}
                content={
                    <ContentUserLoginCP
                        history={props.history}
                        authUser={loggedUser}
                        goToLoggedScreen={(user) => goToLoggedScreen(user)}
                        goToRegister={() => props.history.push(RegistrationRoutes.ROOT)}
                    />
                }
            />
            {/* <TermsOfUseDrawerCP
                onClose={onCloseModal}
                loading={request.awaiting}
                show={mainInfoDrawerVisible}
                onAccept={onAcceptTerms}
            /> */}
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    .${CLASS_MAIN_LAYOUT} {
        > .ant-layout {
            background: #fff;
        }
    }
    @media (max-width: 768px) {
        .${CLASS_MAIN_LAYOUT} {
            > .ant-layout {
                background: ${(props) => props.theme.primaryColor};
            }
        }
    }
`
