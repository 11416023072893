import React from 'react'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import ptBR from 'antd/es/locale/pt_BR'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { LoadingOverlayCP } from '../../common/components/loading-overlay/LoadingOverlayCP'
import { ReduxHelper } from '../../common/redux/helpers/ReduxHelper'
import { ConfigProvider } from 'antd'
import { SchemaMiddleware } from './middlewares/SchemaMiddleware'

export function App(): JSX.Element {
    return (
        <Provider store={ReduxHelper.getInstance().getStore()}>
            <PersistGate
                loading={<LoadingOverlayCP show={true} />}
                persistor={ReduxHelper.getInstance().getPersistor()}
            >
                <ConfigProvider locale={ptBR} renderEmpty={() => <EmptyCP />}>
                    <SchemaMiddleware />
                </ConfigProvider>
            </PersistGate>
        </Provider>
    )
}

