/* eslint-disable max-len */
import { IStepProps } from 'common/components/steps/interface/IStepProps'
import { MathUtils } from 'common/utils/MathUtils'
import _ from 'lodash'
import { IHarvestLitersPerMaturation } from 'modules/harvest/interfaces/IHarvestLitersPerMaturation'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import React from 'react'

/**
 * @todo trocar todos reduce por _.sum
 */
export const BatchGenerationUtils = {
    getStepsConfig(
        harvestSelectionStep: JSX.Element,
        batchInformationStep: JSX.Element,

    ): IStepProps[] {
        return [
            {
                title: 'Selecione as colheitas',
                content: harvestSelectionStep,
                description: <span> Escolha quais colheitas irão gerar os lotes </span>,
                status: 'process',
                disabled: true
            },
            {
                title: 'Preencha os dados de cada lote',
                content: batchInformationStep,
                description: <span> Informe os dados do processamento</span>,
                status: 'process',
                disabled: true
            }
        ]
    },

    /* Retorna o valor SOMADO de todas as colheitas por maturação  */
    getTotalLitersPerMaturation(
        harvests: IHarvestResponseDTO[]
    ): IHarvestLitersPerMaturation {

        return {
            unripe: harvests.map(hvt => (hvt.remainingLitersPerMaturation ? (hvt.remainingLitersPerMaturation.unripe ?? 0) : 0)).reduce((val1: number, val2: number) => val1 + val2),
            semiRipe: harvests.map(hvt => (hvt.remainingLitersPerMaturation ? (hvt.remainingLitersPerMaturation.semiRipe ?? 0) : 0)).reduce((val1: number, val2: number) => val1 + val2),
            ripe: harvests.map(hvt => (hvt.remainingLitersPerMaturation ? (hvt.remainingLitersPerMaturation.ripe ?? 0) : 0)).reduce((val1: number, val2: number) => val1 + val2),
            overRipe: harvests.map(hvt => (hvt.remainingLitersPerMaturation ? (hvt.remainingLitersPerMaturation.overRipe ?? 0) : 0)).reduce((val1: number, val2: number) => val1 + val2),
            dried: harvests.map(hvt => (hvt.remainingLitersPerMaturation ? (hvt.remainingLitersPerMaturation.dried ?? 0) : 0)).reduce((val1: number, val2: number) => val1 + val2),
        }
    },

    calculateBatchHarvestLiters(
        currentHarvestCode: number,
        allHarvests: IHarvestResponseDTO[],
        liters: number
    ): number {

        const _liters = +liters

        const harvestRemainingLiters = _.sum(allHarvests.map(hvt => hvt.remainingLiters))
        const percentage = _liters / (harvestRemainingLiters * 0.01)
        const currentHarvestRLiters = allHarvests.find(hv => hv.code === currentHarvestCode)?.remainingLiters
        const result = (+currentHarvestRLiters! * percentage) * 0.01

        return MathUtils.round(result)
    },

    getRawLitersPerMaturation(
    ): IHarvestLitersPerMaturation {
        return {
            unripe: 0,
            semiRipe: 0,
            ripe: 0,
            overRipe: 0,
            dried: 0
        }
    },

    clearEmptyMaturations(
        maturations: IHarvestLitersPerMaturation
    ): IHarvestLitersPerMaturation {
        return {
            unripe: maturations.unripe !== 0 ? maturations.unripe : undefined,
            semiRipe: maturations.semiRipe !== 0 ? maturations.unripe : undefined,
            ripe: maturations.ripe !== 0 ? maturations.unripe : undefined,
            overRipe: maturations.overRipe !== 0 ? maturations.unripe : undefined,
            dried: maturations.dried !== 0 ? maturations.unripe : undefined
        }

    }
}