import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'
import { BatchAnalisesScreen } from 'modules/batch/screens/BatchAnalisesScreen'
import { BatchListScreen } from 'modules/batch/screens/BatchListScreen'
import { BatchProcessScreen } from 'modules/batch/screens/BatchProcessScreen'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

BatchRoutes.ROOT = '/lotes'
BatchRoutes.PROCESS_WITHOUT_PARAM = `${BatchRoutes.ROOT}/processos`
BatchRoutes.PROCESS = `${BatchRoutes.ROOT}/processos/:code`
BatchRoutes.ANALISES_WITHOUT_PARAM = `${BatchRoutes.ROOT}/analises`
BatchRoutes.ANALISES = `${BatchRoutes.ROOT}/analises/:code`

export function BatchRoutes(): JSX.Element {
    return (
        <AccessControlCP module={SystemModulesEnum.POST_HARVEST}>
            <Switch>
                <Route path={BatchRoutes.ROOT} exact={true} component={BatchListScreen} />
                <Route path={BatchRoutes.PROCESS} component={BatchProcessScreen} />
                <Route path={BatchRoutes.ANALISES} component={BatchAnalisesScreen} />
            </Switch>
        </AccessControlCP>
    )
}
