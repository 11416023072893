import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface IGenericCardWidgetCPProps {
    title?: string
    subtitle?: string
    icon?: JSX.Element
    content?: JSX.Element
    gridArea?: string
    style?: CSSProperties
}

export function GenericCardWidgetCP(props: IGenericCardWidgetCPProps): JSX.Element {

    return (
        <WrapperSCP style={props.gridArea ? { gridArea: `${props.gridArea}` } : props.style}>
            {
                !!props.title &&
                <TitleSCP>
                    {props.title}
                </TitleSCP>
            }
            {
                !!props.subtitle &&
                <SubtitleSCP>
                    {props.subtitle}
                </SubtitleSCP>
            }
            {
                !!props.icon &&
                <IconSCP>
                    {props.icon}
                </IconSCP>
            }
            <ContentWrapperSCP>
                {props.content}
            </ContentWrapperSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 10px;
    background-color: ${props => props.theme.white};
    border-radius: 5px;
    width: 100%;
    height: 100%;
    position: relative;
`
const TitleSCP = styled.div`
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 3px;
`
const SubtitleSCP = styled.div`
    font-size: 11px;
    color: ${props => props.theme.gray};

`
const IconSCP = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    background: ${props => props.theme.primaryColor};
    align-items: center;
    justify-content: center;
    svg {
        color: white;
        font-size: 16px;
    }    
`
const ContentWrapperSCP = styled.div`
    padding: 10px 0;
    margin-top: 10px;
`