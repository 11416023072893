import React, { useState } from 'react'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { AuthActions } from 'modules/auth/AuthActions'
import { UserProfileDrawerCP } from 'modules/app/components/user-profile-settings/inner/UserProfileDrawerCP'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AvatarCP } from 'common/components/avatar/AvatarCP'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { LoggedUserDataTP } from 'common/redux/ReduxTypes'

interface IUserProfileSettingsCPProps {
    isCollapsed: boolean
    darkColor?: boolean
}

export function UserProfileSettingsCP(props: IUserProfileSettingsCPProps): JSX.Element {
    const loggedUser: LoggedUserDataTP = useSelector((state: IReduxState) => state.loggedUser)
    const domain: string = useSelector((state: IReduxState) => _.get(state, 'schema.domain'))
    const [updateUserDrawerVisible, setUpdateUserDataDrawerVisible] = useState<boolean>(false)

    const history = useHistory()
    const firstName = loggedUser.user_name.split(' ')[0]

    return (
        <WrapperSCP>
            <AvatarCP size={50} name={loggedUser.user_name} />
            <WrapperSCP darkColor={props.darkColor} style={{ flexDirection: 'column', marginLeft: '10px', display: `${props.isCollapsed ? 'none' : 'flex'}` }}>
                <UserNameSCP>
                    {firstName}
                </UserNameSCP>
                <UserActionsSCP>
                    <FontAwesomeIcon icon={faCog} onClick={() => setUpdateUserDataDrawerVisible(true)} />
                    <FontAwesomeIcon
                        icon={faPowerOff}
                        onClick={() => {
                            AuthActions.logout()
                            //  history.replace(`${domain}/${AuthRoutes.LOGIN}`)
                        }}
                    />
                </UserActionsSCP>
            </WrapperSCP>

            <UserProfileDrawerCP
                visible={updateUserDrawerVisible}
                onClose={() => setUpdateUserDataDrawerVisible(false)}
            />
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div<{ darkColor?: boolean }>`
    display: flex;
    color: #fff;
    margin: 5px 0;
    color: ${props => (props.darkColor ? props.theme.primaryColor : '#fff')};
`
const UserNameSCP = styled.span`
    font-size: 14px;
    font-weight: 600;
`
const UserActionsSCP = styled.div`
    font-size: 14px;
    svg{
        margin-right: 10px;
        cursor: pointer;
    }
`
