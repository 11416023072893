import { DividerCP } from 'common/components/divider/DividerCP'
import { TextCP } from 'common/components/text/TextCP'
import { LitersPerMaturationCP } from 'modules/harvest-planning/components/liters-per-maturation/LitersPerMaturationCP'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import { HarvestUtils } from 'modules/harvest/utils/HarvestUtils'
import React from 'react'
import styled from 'styled-components'

interface IHarvestDetailsICPProps {
    harvest: IHarvestResponseDTO
}

export function HarvestDetailsICP(props: IHarvestDetailsICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <DividerCP text={'Dados da colheita'} orientation={'left'} />
            <GridSCP>
                <RowSCP>
                    <TextCP text={'Tipo:\u00A0'} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${HarvestUtils.translateType(props.harvest.type)}`} />
                </RowSCP>
                <RowSCP>
                    <TextCP text={'Outro:\u00A0'} style={{ fontWeight: '500' }} />
                    <TextCP text={`${props.harvest.typeOther ?? '-'} `} />
                </RowSCP>
                <RowSCP>
                    <TextCP text={'Quantidade colhida:\u00A0'} style={{ fontWeight: '500' }} />
                    <TextCP text={`${props.harvest.liters} litros`} />
                </RowSCP>
            </GridSCP>
            {
                !!props.harvest.description &&
                <RowSCP>
                    <TextCP text={'Descrição:\u00A0'} style={{ fontWeight: '500' }} />
                    <TextCP text={`${props.harvest.description}`} />
                </RowSCP>
            }
            {
                !!props.harvest.litersPerMaturation &&
                <RowSCP style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <TextCP text={'Litros por maturação:\u00A0'} style={{ fontWeight: '500' }} />
                    <LitersPerMaturationCP litersPerMaturation={props.harvest.litersPerMaturation} />
                </RowSCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
const GridSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography{
        font-size: 12px;
    }
    
`