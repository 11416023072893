import { Tag } from 'antd'
import { FontStyleTP } from 'common/types/FontStyleTP'
import { ColorUtils } from 'common/utils/ColorUtils'
import React from 'react'
import styled from 'styled-components'

const CLASS_SMALL = 'tag_small'

/** Labels para cores utilizados pelo ant. */
type AntTagColorTP = 'magenta' | 'red' | 'volcano' | 'orange' | 'gold' | 'lime' | 'green' | 'cyan' | 'blue' | 'geekblue' | 'purple'

/** Labels para cores utilizados do componente customizado. */
export type TagColorTP = 'pink' | 'red' | 'darkorange' | 'orange' | 'yellow' | 'lightgreen' | 'green' | 'lightblue' | 'blue' | 'navyblue' | 'purple'

interface ITagCPProps {
    content: string | JSX.Element
    color?: TagColorTP
    onClose?: () => void
    small?: boolean
    fontStyle?: FontStyleTP
}

/**
 * Componente de tag.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function TagCP(props: ITagCPProps): JSX.Element {

    function getColor(): AntTagColorTP {
        switch (props.color) {

            case 'pink':
                return 'magenta'

            case 'darkorange':
                return 'volcano'

            case 'yellow':
                return 'gold'

            case 'lightgreen':
                return 'lime'

            case 'lightblue':
                return 'cyan'

            case 'navyblue':
                return 'geekblue'

            case 'red':
            case 'orange':
            case 'green':
            case 'purple':
            case 'blue':
                return props.color

            default:
                return 'blue'
        }
    }

    return (
        <WrapperSCP
            className={!!props.small ? CLASS_SMALL : ''}
            fontStyle={props.fontStyle}
        >
            <Tag
                closable={!!props.onClose}
                onClose={props.onClose}
                color={getColor()}
            >
                {props.content}
            </Tag>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ fontStyle?: FontStyleTP }>`

    display: flex;
    align-items: center;
    margin-bottom: 2px;
    margin: 0 3px 3px 3px;

    .ant-tag {
        font-style: ${props => props.fontStyle ?? 'normal'};
        border-radius: 5px;
        &.ant-tag-purple{
            color:${props => props.theme.primaryColor};
         
            border-color: ${props => ColorUtils.lightenColor(props.theme.primaryColor, 30)};
        }
        
    }

    

    &.${CLASS_SMALL} {
        .ant-tag {
            font-size: .55rem;
            padding: 0 3px;
            margin: 0 2px;
            line-height: 0;
            display: flex;
            align-items: center;
            height: 15px;

            svg {
                height: .7rem;
                width: .7rem;
            }
        }
    }
`
