import { faPrescriptionBottle, faTractor, faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EquipmentIcon } from 'common/components/icons/EquipmentIcon'
import { ManeuverActivitiesIcon } from 'common/components/icons/ManeuverActivitiesIcon'
import { ImplementTypeEnum } from 'modules/maneuver/enums/ImplementTypeEnum'
import { ManeuverRoutes } from 'modules/maneuver/ManeuverRoutes'
import React from 'react'
import styled from 'styled-components'

const IconSCP = styled.span`
    svg {
        color: ${props => props.theme.primaryColor};
        fill: ${props => props.theme.primaryColor};
        font-size: 1.75em;
    }
`

export const ManeuverMenuUtils = {
    getMainMenuOptions() {
        return [

            {
                key: 'instructions',
                route: ManeuverRoutes.INSTRUCTIONS,
                title: 'Instruções',
                icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faTasks} /> </IconSCP>
            },
            {
                key: 'products',
                route: ManeuverRoutes.PRODUCTS,
                title: 'Estoque',
                icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faPrescriptionBottle} /> </IconSCP>
            },
            {
                key: 'main',
                route: ManeuverRoutes.ROOT,
                title: 'Atividades Agrícolas',
                icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><ManeuverActivitiesIcon /> </IconSCP>,
            },
            {
                key: ImplementTypeEnum.EQUIPMENT,
                route: ManeuverRoutes.EQUIPMENTS,
                title: 'Equipamentos',
                icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><EquipmentIcon /> </IconSCP>
            },
            {
                key: ImplementTypeEnum.VEHICLE,
                title: 'Veículos',
                route: ManeuverRoutes.VEHICLES,
                icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faTractor} /> </IconSCP>
            },
        ]
    },

}
