import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'

import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import moment from 'moment-timezone'
import { DateRangePickerCP } from 'common/components/date-range-picker/DateRangePickerCP'
import { DateUtils } from 'common/utils/DateUtils'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { useSelector } from 'react-redux'

type fieldTypesTP = 'property' | 'field' | 'range' | 'season'
interface IReportFiltersCPProps {
    onChangeFilters: (filters: ISearchReportReqDTO) => void
    filters?: ISearchReportReqDTO
    showFields?: fieldTypesTP[]
}

export function ReportFiltersCP(props: IReportFiltersCPProps): JSX.Element {
    const currentFilters: ISearchReportReqDTO | null = useSelector((state: IReduxState) => _.get(state, 'searchState.filter'))
    const type: FilterTypesEnum = useSelector((state: IReduxState) => _.get(state, 'searchState.type'))
    useEffect(loadPersistedData, [])

    function loadPersistedData(): void {
        if (type !== FilterTypesEnum.REPORT)
            return SearchActions.clearState()

        if (!!currentFilters) {
            props.onChangeFilters({ season: currentFilters.season })
        }
    }
    function onChangeFilters(filters: ISearchReportReqDTO): void {
        SearchActions.setFilter<ISearchReportReqDTO>({
            ...filters,
        }, FilterTypesEnum.REPORT)

        props.onChangeFilters(filters)
    }

    return (
        <WrapperSCP>

            <GridWrapperSCP>
                <SeasonSelectorCP
                    label={'Safra'}
                    onChange={(season) => onChangeFilters({ ...props.filters, season })}
                    value={props.filters?.season}
                />
                {
                    (!props.showFields || props.showFields.includes('range')) &&
                    <DateRangePickerCP
                        placeholder={['Entre as datas ...', '']}
                        value={(!!props.filters?.startDate && !!props.filters?.endDate) ?
                            [moment(props.filters.startDate), moment(props.filters.endDate)] :
                            undefined}
                        onChange={(dates) => onChangeFilters(
                            {
                                ...props.filters,
                                startDate: DateUtils.getDateFormattedToApi(moment(dates.beginDate).format('DD-MM-YYYY')),
                                endDate: DateUtils.getDateFormattedToApi(moment(dates.endDate).format('DD-MM-YYYY'))
                            }
                        )}
                    // allowClear={true}
                    />
                }

                <PropertyFieldFieldsCP
                    propertyCode={props.filters?.propertyCode}
                    fieldCode={props.filters?.fieldCode}
                    selectFirstAsDefault={true}
                    hideField={(
                        !!props.showFields &&
                        !props.showFields.includes('field'))
                        ? 'field'
                        : (!!props.showFields && !props.showFields.includes('property')) ? 'property' : undefined}
                    onChangeProperty={(propertyCode) => onChangeFilters({ ...props.filters, propertyCode })}
                    onChangeField={(fieldCode) => onChangeFilters({ ...props.filters, fieldCode })}
                />
            </GridWrapperSCP>
        </WrapperSCP>

    )
}
const WrapperSCP = styled.div`
    background: white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
  
    .ant-typography{
        font-size: 12px;   
    }

`

const GridWrapperSCP = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: .5fr 2fr 3fr;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;
    align-items: center;

    label, .ant-typography div{
      display: none;
    }

    @media(max-width: 767px){
        grid-template-columns: 1fr;
        .ant-row .ant-form-item-label > label{
             display: none;
         }
    }
`