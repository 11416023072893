import { Button } from 'antd'
import { PopConfirmCP, PopConfirmPlacementTP } from 'common/components/pop-confirm/PopConfirmCP'
import { TooltipCP, TooltipPlacementTP } from 'common/components/tooltip/TooltipCP'
import { ColorUtils } from 'common/utils/ColorUtils'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

type ButtonTypeTP = 'default' | 'primary' | 'ghost' | 'dashed' | 'link'
type ButtonShapeTP = 'circle' | 'round'
type ButtonSizeTP = 'large' | 'middle' | 'small'

type WrapperPropsTP = {
    marginRight?: number,
    marginLeft?: number,
    color?: string,
}

/**
 * @todo: Alterar inclusao de icones (aceitar componentes de icones como parametro ao inves dos icones do ant)
 * @todo: Tamanho 'small' nao funciona
 */
type AntProps = {
    onClick?: (event?: any) => void,
    type?: ButtonTypeTP,
    disabled?: boolean,
    size?: ButtonSizeTP,
    shape?: ButtonShapeTP,
    ghost?: boolean,
    icon?: JSX.Element | string,
    loading?: boolean,
    href?: string,
    style?: any,
    danger?: boolean,
}

/**
 * @todo concluir implementacao do 'pop confirm' (confirmMsg)
 */
type CustomPropsTP = {
    isSubmit?: boolean,
    tooltip?: string,
    toolTipPosition?: TooltipPlacementTP,
    confirmMsg?: string,
    confirmMsgPlacement?: PopConfirmPlacementTP,
    wrapperStyle?: CSSProperties,
}

interface IButtonCPProps extends React.PropsWithChildren<CustomPropsTP & WrapperPropsTP & AntProps> { }

/**
 * COMPONENTE
 * Botao generico customizavel.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function ButtonCP(props: IButtonCPProps): JSX.Element {

    /**
     * @todo concluir implementacao do 'pop confirm' (confirmMsg)
     */
    function renderContent(): JSX.Element {

        const button = renderButton()

        if (!!props.tooltip) {
            return (
                <TooltipCP text={props.tooltip} placement={props.toolTipPosition}>
                    {button}
                </TooltipCP>
            )
        }

        if (!!props.confirmMsg) {
            return (
                <PopConfirmCP onConfirm={props.onClick} placement={props.confirmMsgPlacement} title={props.confirmMsg} okText={'Sim'} cancelText={'Não'} disabled={props.disabled}>
                    <Button
                        htmlType={!!props.isSubmit ? 'submit' : 'button'}
                        disabled={props.disabled}
                        type={props.type ?? 'default'}
                        size={props.size}
                        ghost={props.ghost ?? false}
                        shape={props.shape}
                        icon={props.icon}
                        href={props.href}
                        loading={props.loading}
                        style={props.style}
                        danger={props.danger}
                    >
                        {props.children}
                    </Button>
                </PopConfirmCP>

            )
        }

        return button
    }

    function renderButton(): JSX.Element {
        return (
            <Button
                htmlType={!!props.isSubmit ? 'submit' : 'button'}
                onClick={props.onClick}
                disabled={props.disabled}
                type={props.type ?? 'default'}
                size={props.size}
                ghost={props.ghost ?? false}
                shape={props.shape}
                icon={props.icon}
                href={props.href}
                loading={props.loading}
                style={props.style}
                danger={props.danger}
            >
                {props.children}
            </Button>
        )
    }

    return (
        <WrapperSCP
            marginLeft={props.marginLeft}
            marginRight={props.marginRight}
            color={props.color}
            style={props.wrapperStyle}
        >
            {renderContent()}
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div<WrapperPropsTP>`

    margin-left: ${props => props.marginLeft ?? 0}px;
    margin-right: ${props => props.marginRight ?? 0}px;
    color: ${props => props.color ?? 'inherit'};

    .ant-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 0 none;
        height: auto;
        width: auto;
        flex-wrap: nowrap;
        padding: 10px 20px;
        border-radius: 5px;
        position: relative;
        /* border: 1px solid ${props => props.theme.primaryColor}; */
        span {
            white-space: none;
            word-wrap: break-word;
         
            svg {
                width: auto;
                 margin-right: 10px;
            }
        }
        svg{
            margin: 0 5px;
        }
    }

    .ant-btn-primary {
        background-color: ${props => props.theme.primaryColor};
        transition: background-color 0.20s ease-in;
        :disabled {
            background: ${props => ColorUtils.lightenColor(props.theme.secondaryColor, 100)};
            color: ${props => props.color ?? props.theme.white};
            border: none;
        }
    }

     .ant-btn-ghost {
        background-color: #fff;
        color:  ${props => props.theme.primaryColor};
        transition: background-color 0.20s ease-in;
        font-weight: 600;
        border-color: ${props => props.theme.primaryColor} !important;
        &:hover{
            background-color: #fff;
            color:  ${props => props.theme.primaryColor};
            text-decoration: underline;
        }
     }

     .ant-btn-background-ghost.ant-btn-primary {
        background: ${props => ColorUtils.lightenColor(props.theme.white, 65)};
        color: ${props => props.theme.primaryColor};
        box-shadow: 0px 0px 0px 1px ${props => props.theme.primaryColor};
    }

    .ant-btn-primary:hover:not([disabled]) {
        background: ${props => ColorUtils.lightenColor(props.theme.primaryColor, 50)};
    }

    .ant-btn-icon-only {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 40px;
        width: 40px;
        padding: 10px;

        svg {
            cursor: pointer !important;
        }

        .fa-fw {
            width: 1.25em;
            height: 1.25em;
            font-size: 80%;
        }
    }
    .ant-btn-ghost {
        border: 1px solid ${props => props.theme.secondaryColor};
    }
    .ant-btn .anticon {
        display: flex;
        align-items: center;
    }
    .ant-btn-link {
        color: ${props => props.color ?? 'inherit'};
    }
    .ant-btn-loading {

        display: flex;
        align-items: center;

        .anticon-loading {
            color: ${props => props.color ?? 'inherit'};
            margin-right: 7px;
        }
    }
`
