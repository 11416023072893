import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'

export class UserRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/users`
    private constructor() { }

    static save = (dto: any): RequestConfigTP<any> => ({
        url: `${UserRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static addImages = (code: number, data: FormData): RequestConfigTP => ({
        url: `${UserRequests.baseApiUrl}/${code}/files`,
        method: HttpMethodEnum.PUT,
        params: data,
        headers: { 'content-type': 'multipart/form-data' }
    })

    static findOne = (code: number): RequestConfigTP => ({
        url: `${UserRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET
    })

    static acceptTerms = (code: number): RequestConfigTP => ({
        url: `${UserRequests.baseApiUrl}/${code}/accept-terms`,
        method: HttpMethodEnum.PUT
    })
}