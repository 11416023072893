import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { ReceiptFileUploaderCP } from 'modules/maneuver/components/receipt-file-uploader/ReceiptFileUploaderCP'
import React, { useState } from 'react'
import styled from 'styled-components'

interface IUploadDocsDrawerCPProps {
    type: 'purchase' | 'maintenance' | 'financial'
    itemCode: number
    subItemCode?: number
    show: boolean
    onClose: () => void
    onSuccess: () => void

}

export function UploadDocsDrawerCP(props: IUploadDocsDrawerCPProps): JSX.Element {
    const [shouldUploadImages, setShouldUploadImages] = useState<number>()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <DrawerCP
            title={'Adicionar arquivos'}
            visible={props.show}
            width={'30%'}
            onClose={() => {
                props.onClose()
            }}
            footer={
                <WrapperButtonCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={() => {
                            setShouldUploadImages(Date.now())
                        }}
                        loading={loading}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Salvar
                    </ButtonCP>
                </WrapperButtonCP>
            }
        >
            <WrapperSCP>
                <ReceiptFileUploaderCP
                    type={props.type}
                    itemCode={props.itemCode}
                    subItemCode={props.subItemCode}
                    fireSaveReq={shouldUploadImages}
                    onUploadDone={props.onSuccess}
                    loading={loading}
                    setLoading={setLoading}
                />
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`

const WrapperButtonCP = styled.div`
    margin: 20px 0;
    .ant-btn{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`