import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { ISaveImplementRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveImplementRequestDTO'
import { ISaveMaintenanceRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveMaintenancesRequestDTO'
import { ISearchImplementRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchImplementRequestDTO'

export class ImplementRequests {

    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/implements`

    static getImplement = (code: number): RequestConfigTP => ({
        url: `${ImplementRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static getImplementList = (dto?: ISearchImplementRequestDTO): RequestConfigTP<ISearchImplementRequestDTO> => ({
        url: `${ImplementRequests.baseApiUrl}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static saveImplement = (dto: ISaveImplementRequestDTO): RequestConfigTP<ISaveImplementRequestDTO> => ({
        url: `${ImplementRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updateImplement = (code: number, dto: ISaveImplementRequestDTO): RequestConfigTP<ISaveImplementRequestDTO> => ({
        url: `${ImplementRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static deleteImplement = (code: number): RequestConfigTP => ({
        url: `${ImplementRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static searchMaintenances = (implementCode: number): RequestConfigTP => ({
        url: `${ImplementRequests.baseApiUrl}/${implementCode}/maintenances`,
        method: HttpMethodEnum.GET,
    })

    static getMaintenance = (implementCode: number, code: number): RequestConfigTP => ({
        url: `${ImplementRequests.baseApiUrl}/${implementCode}/maintenances/${code}`,
        method: HttpMethodEnum.GET,
    })

    static saveMaintenance = (implementCode: number, dto: ISaveMaintenanceRequestDTO): RequestConfigTP => ({
        url: `${ImplementRequests.baseApiUrl}/${implementCode}/maintenances/`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updateMaintenance = (implementCode: number, code: number, dto: ISaveMaintenanceRequestDTO): RequestConfigTP => ({
        url: `${ImplementRequests.baseApiUrl}/${implementCode}/maintenances/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static deleteMaintenance = (implementCode: number, code: number): RequestConfigTP => ({
        url: `${ImplementRequests.baseApiUrl}/${implementCode}/maintenances/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static addImagesToMaintenance = (implementCode: number, code: number, data: FormData): RequestConfigTP => ({
        url: `${ImplementRequests.baseApiUrl}/${implementCode}/maintenances/${code}/pictures`,
        method: HttpMethodEnum.PUT,
        params: data,
        headers: { 'content-type': 'multipart/form-data' }
    })

    static getMaintenancePictures = (implementCode: number, code: number): RequestConfigTP => ({
        url: `${ImplementRequests.baseApiUrl}/${implementCode}/maintenances/${code}/pictures`,
        method: HttpMethodEnum.GET
    })

    static removeMaintenancePictures = (code: number, implementCode: number, dto: { pictures: string[] }): RequestConfigTP => ({
        url: `${ImplementRequests.baseApiUrl}/${implementCode}/maintenances/${code}/pictures`,
        method: HttpMethodEnum.DELETE,
        params: dto,
    })
}
