import { faMedal } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CoffeeBeansIcon } from 'common/components/icons/CoffeeBeans'
import { GenericCardWidgetCP } from 'common/components/widgets/GenericCardWidgetCP'
import { WidgetGenericValueICP } from 'common/components/widgets/inner/WidgetGenericValueICP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { FieldDetailWidgetCP } from 'modules/report/components/field-widgets/FieldDetailWidgetCP'
import { IFieldReportResDTO } from 'modules/report/services/dtos/responses/IFieldReportResDTO'
import React from 'react'
import styled from 'styled-components'

interface IFieldReportGridICPProps {
    report?: IFieldReportResDTO
    loading: boolean
}

export function FieldReportGridICP(props: IFieldReportGridICPProps): JSX.Element {

    return (
        <WrapperGridSCP>
            <RowFirstGridSCP>
                <FieldDetailWidgetCP
                    gridArea={'field-details'}
                    field={props.report}
                    loading={props.loading}
                />
                <GenericCardWidgetCP
                    gridArea={'average-score'}
                    title={'Pontuação média'}
                    icon={<FontAwsomeIconCP icon={faMedal} />}
                    content={
                        <WidgetGenericValueICP
                            value={!!props.report?.averageScore ? `${MaskUtils.applyNumberMask(props.report.averageScore)}` : undefined}
                            label={'pontos'}
                            loading={props.loading}
                        />
                    }
                />

                <GenericCardWidgetCP
                    title={'Catação média'}
                    icon={<CoffeeBeansIcon width={22} />}
                    gridArea={'medium-grooming'}
                    content={
                        <WidgetGenericValueICP
                            value={!!props.report?.averagePicking ? `${MaskUtils.applyNumberMask(props.report.averagePicking)}` : undefined}
                            label={'%'}
                            loading={props.loading}
                        />
                    }
                />

                <GenericCardWidgetCP
                    title={'Total de litros colhidos'}
                    gridArea={'liters-harvested'}
                    content={
                        <WidgetGenericValueICP
                            value={!!props.report?.litersHarvested ? `${MaskUtils.applyNumberMask(props.report.litersHarvested)}` : 0}
                            label={'L'}
                            loading={props.loading}
                        />
                    }
                />
                <GenericCardWidgetCP
                    title={'Total de sacas'}
                    gridArea={'total-bags'}
                    content={
                        <WidgetGenericValueICP
                            value={!!props.report?.bags ? `${MaskUtils.applyNumberMask(props.report.bags)}` : 0}
                            label={'sacas'}
                            loading={props.loading}
                        />
                    }
                />
                <GenericCardWidgetCP
                    title={'Litros/pé de café'}
                    gridArea={'liters-foot'}
                    content={
                        <WidgetGenericValueICP
                            value={!!props.report?.litersPerTree ? `${MaskUtils.applyNumberMask(props.report.litersPerTree)}` : 0}
                            label={'L'}
                            loading={props.loading}
                        />
                    }
                />
                <GenericCardWidgetCP
                    title={'Sacas/hectare'}
                    gridArea={'bags-hectare'}
                    content={
                        <WidgetGenericValueICP
                            value={!!props.report?.bagsPerHectare ? `${MaskUtils.applyNumberMask(props.report.bagsPerHectare)}` : 0}
                            loading={props.loading}
                            label={'sacas'}
                        />
                    }
                />
            </RowFirstGridSCP>

        </WrapperGridSCP>
    )
}

const WrapperGridSCP = styled.div`
    margin: 15px 0;
    display: grid;
`

const RowFirstGridSCP = styled.div`
    display: grid;
    margin-bottom: 10px;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    grid-template-areas:
        "field-details average-score liters-harvested liters-foot"
        "field-details medium-grooming total-bags bags-hectare"
    ;
    @media(max-width: 768px){
        grid-template-columns: 100%;
        grid-template-areas: 
            "field-details"
            "field-details"
            "average-score"
            "medium-grooming"
            "liters-harvested"
            "liters-foot"
            "total-bags"
            "bags-hectare";
    }
`

const RowSecondGridSCP = styled.div`
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-template-areas:
        "total-billing  total-billing cost-bag sale-value sale-value"
        "flavor-aroma flavor-aroma total-cost sale-value sale-value"
    ;
`

const WrapperListFieldsSCP = styled.div`
    display: flex;
    grid-column-gap: 20px;
`
