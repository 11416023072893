import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { ProductionReportContentCP } from 'modules/report/components/production-report-content/ProductionReportContentCP'
import React from 'react'

export function ProductionReportScreen(): JSX.Element {

    return (
        <LayoutCP
            header={
                <HeaderCP icon={<FontAwsomeIconCP icon={faChartLine} />} title={'Indicadores de produção'} />
            }
            content={
                <ProductionReportContentCP />
            }
        />
    )
}
