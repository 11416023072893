/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-confusing-arrow */
import { ButtonCP } from 'common/components/button/ButtonCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { FieldFormValidator } from 'modules/field/components/field-form/inner/FieldFormValidator'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NotificationHelper } from '../../../../common/NotificationHelper'
import { CoffeeTypeEnum, ArabicaVarietyEnum, CaneforaVarietyEnum } from '../../enums/CoffeeTypeEnum'
import { ISaveFieldRequestDTO } from '../../services/dtos/requests/ISaveFieldRequestDTO'
import { IFieldResponseDTO } from '../../services/dtos/responses/IFieldResponseDTO'
import { FieldRequests } from '../../services/FieldRequests'
import { FieldUtils } from '../../utils/FieldUtils'
import { EnumSelectorCP } from 'common/components/enum-selector/EnumSelectorCP'

interface IProps {
    propertyCode: number
    field?: IFieldResponseDTO
    onSuccess: (item: IFieldResponseDTO) => void

}

export function FieldFormCP(props: IProps): JSX.Element {
    const screenSize = useScreenSize()
    const [coffeeType, setCoffeeType] = useState<CoffeeTypeEnum>(CoffeeTypeEnum.ARABICA)
    const [coffeeVariety, setCoffeeVariety] = useState<ArabicaVarietyEnum | CaneforaVarietyEnum>()
    const [formValidator, setFormValidator] = useState<FieldFormValidator>(new FieldFormValidator())

    const formStateManager = useFormStateManager(formValidator)
    const request = useRequest<IFieldResponseDTO, ISaveFieldRequestDTO>()
    useEffect(() => {
        setFormValidator(new FieldFormValidator(props.field))
    }, [props.field])

    useEffect(onRequestChange, [request.awaiting])

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, `Erro ao ${props.field ? 'atualizar' : 'salvar'} talhão!`)
        }

        NotificationHelper.success(`Talhão ${props.field ? 'atualizado' : 'salvo'} com sucesso!`)
        props.onSuccess(request.returnData)
    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)
        formStateManager.debugErrors()
        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveFieldRequestDTO = {
            name: formValues.name,
            plantingYear: formValues.plantingYear,
            coffeeType,
            varieties: formValues.varieties,
            minAltitude: +formValues.minAltitude,
            maxAltitude: +formValues.maxAltitude,
            totalArea: +formValues.totalArea,
            lineSpacing: +formValues.lineSpacing,
            streetSpacing: +formValues.streetSpacing,
        }

        request.runRequest(props.field ? FieldRequests.update(props.propertyCode, props.field.code, dto) : FieldRequests.save(props.propertyCode, dto))
    }

    return (
        <FormWrapperSCP mobile={screenSize.smd}>
            <FormContainerSCP>
                <InputCP
                    label={'Nome do talhão'}
                    fieldName={'name'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                />

                <SelectCP
                    options={Object.values(CoffeeTypeEnum).map(c => ({ label: FieldUtils.translateCoffeeTypeEnum(c), value: c }))}
                    label={'Tipo do café'}
                    fieldName={'coffeeType'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    onChange={setCoffeeType}
                />
                 

             <EnumSelectorCP 
                enumName={`VarietyEnum/byType/${coffeeType === CoffeeTypeEnum.ARABICA ? 1 : 2}`}
                fieldName={'varieties'}
                formStateManager={formStateManager}
                isMultiple={true}
             />
              

                
                <InputCP
                    label={'Ano do Plantio'}
                    fieldName={'plantingYear'}
                    type={'number'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                />

                <InputCP
                    label={'Área Total'}
                    fieldName={'totalArea'}
                    type={'number'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    icon={<div>ha</div>}
                />

                <InputCP
                    label={'Altitude Mínima'}
                    fieldName={'minAltitude'}
                    formStateManager={formStateManager}
                    type={'number'}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    icon={<div>m</div>}
                />
                <InputCP
                    label={'Altitude Máxima'}
                    fieldName={'maxAltitude'}
                    formStateManager={formStateManager}
                    type={'number'}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    icon={<div>m</div>}
                />

                <InputCP
                    label={'Espaçamento entre Pés'}
                    fieldName={'lineSpacing'}
                    formStateManager={formStateManager}
                    type={'number'}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    icon={<div>m</div>}
                />

                <InputCP
                    label={'Espaçamento entre Ruas'}
                    fieldName={'streetSpacing'}
                    formStateManager={formStateManager}
                    type={'number'}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    icon={<div>m</div>}
                />

                <ButtonCP
                    size={screenSize.smd ? 'large' : 'middle'}
                    type={'primary'}
                    isSubmit={true}
                    loading={request.awaiting}
                    onClick={onFormSubmit}
                    style={{ margin: '12px 0 0 auto' }}
                >
                    Salvar
                </ButtonCP>
            </FormContainerSCP>
        </FormWrapperSCP>

    )
}

const FormContainerSCP = styled.div`
    width: 100%;
    height: 100%;
`

const FormWrapperSCP = styled.div<{ mobile: boolean }>`
    width: 100%;
`