import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'

import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { BatchAnalysisHeaderICP } from 'modules/batch/components/batch-analyses-content/inner/BatchAnalysisHeaderICP'
import { AnalysisTypeEnum } from 'modules/analysis/enums/AnalysisTypeEnum'
import { SensoryAnalysisListCP } from 'modules/analysis/components/sensory-analysis-list/SensoryAnalysisListCP'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'
import { PhysicalAnalysisListCP } from 'modules/analysis/components/physical-analysis-list/PhysicalAnalysisListCP'
import { IBatchAnalysisResponseDTO } from '../../services/dtos/responses/IBatchAnalysisResponseDTO'

type RouteParamsTP = { code?: string }

interface BatchAnalysesContentCPProps {}

export function BatchAnalysesContentCP(props: BatchAnalysesContentCPProps): JSX.Element {
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) =>
        _.get(state, 'listState.updateStatus'),
    )
    const routeParams = useParams<RouteParamsTP>()

    const [batchCode, setBatchCode] = useState<number>()
    const [batchData, setBatchData] = useState<IBatchAnalysisResponseDTO>()
    const [type, setType] = useState<AnalysisTypeEnum>(AnalysisTypeEnum.SENSORY)

    const request = useRequest<IBatchAnalysisResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(() => {
        if (!!routeParams.code) setBatchCode(+routeParams.code)
    }, [routeParams])

    useEffect(getBatchDetails, [batchCode])
    useEffect(onItemChange, [updateStatus])

    function getBatchDetails(): void {
        if (!!batchCode) request.runRequest(BatchRequests.getAnalyses(batchCode))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE) getBatchDetails()
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried) return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(
                request.error,
                'Erro ao buscar detalhes!',
            )
        }

        setBatchData(request.returnData)
        ListActions.updateIsDone()
    }

    return (
        <WrapperSCP>
            <HeaderWrapperSCP>
                <BatchAnalysisHeaderICP type={type} setType={setType} />
            </HeaderWrapperSCP>

            <ContentWrapperSCP>
                {!!batchData && type === AnalysisTypeEnum.SENSORY && (
                    <SensoryAnalysisListCP analysis={batchData.sensoryAnalysis ?? []} />
                )}

                {!!batchData && type === AnalysisTypeEnum.PHYSICAL && (
                    <PhysicalAnalysisListCP analysis={batchData.physicalAnalysis ?? []} />
                )}

                {!batchData && <LoadingOverlayCP show={true} />}
            </ContentWrapperSCP>
        </WrapperSCP>
    )
}

const ActionsWrapperSCP = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    .ant-btn {
        width: 100%;
        justify-content: center;
    }
    @media (max-width: 768px) {
        .ant-btn {
            margin: 4px !important;
            width: 100%;
        }
    }
`
const MainSCP = styled.div`
    width: 100%;
    margin: 10px;
    @media (max-width: 768px) {
        margin: 0;
    }
`

const OtherActionsSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    width: 100%;
    @media (max-width: 768px) {
        column-gap: 5px;
    }
`

const WrapperSCP = styled.div`
    border-radius: 10px;
    margin-bottom: 4em;
    max-height: 75vh;
    overflow: overlay;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: #f5f5f5;
    }
    ::-webkit-scrollbar {
        width: 2px;
        border-radius: 1px;
        background-color: #f5f5f5;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.primaryColor};
    }
    @media (max-width: 768px) and (max-height: 600px) {
        max-height: 75vh;
    }
    @media (max-width: 768px) and (min-height: 600px) {
        max-height: 82vh;
    }
`

const HeaderWrapperSCP = styled.div`
    background: #fff;
    min-height: 100px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    @media (max-width: 768px) {
        min-height: 62px;
        padding: 0 10px 7px 10px;
    }
`

const ContentWrapperSCP = styled.div`
    margin-top: 15px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    min-height: 55vh;
`

const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography {
        font-size: 12px;
    }
`

const FilterSCP = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
