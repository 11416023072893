import { faPenAlt } from '@fortawesome/free-solid-svg-icons'

import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CardFooterICP } from 'common/components/list/inner/CardFooterICP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { CardListItemICP } from 'common/components/list/inner/CardListItemICP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { BatchCardSkeletonICP } from 'modules/batch/components/batch-list/inner/BatchCardSkeletonICP'
import { IStructureResDTO } from 'modules/batch/services/dtos/responses/IStructureResDTO'

import { StructureDrawerCP } from 'modules/structure/components/structure-drawer/StructureDrawerCP'
import { StructureRequests } from 'modules/structure/services/StructureRequests'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { StructureUtils } from 'modules/structure/utils/StructureUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IStructureCardICPProps {
    structure: IStructureResDTO
    loading: boolean
}

export function StructureCardICP(props: IStructureCardICPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }
    const [structureDrawerVisible, setStructureDrawerVisible] = useState<boolean>(false)
    const deleteReq = useRequest(StructureRequests.delete(props.structure.code))
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    function onDeleteReqChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success || !deleteReq.returnData)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Ocorreu um erro ao remover este item. Tente novamente.')

        NotificationHelper.success('Estrutura removida com sucesso')
        ListActions.mustUpdate()
    }

    if (!props.structure || props.loading)
        return <BatchCardSkeletonICP />

    return (
        <WrapperSCP>

            <CardListItemICP
                key={props.structure.code}
                item={props.structure}
                loading={props.loading}
                customSkeleton={<BatchCardSkeletonICP />}
                header={
                    <CardHeaderICP
                        highlight={`#${props.structure.code}`}
                        title={`${props.structure.name}`}
                        itemCode={props.structure.code}
                        subtitle={`${StructureUtils.translateType(props.structure.type)}`}
                        actions={
                            [
                                {
                                    label: 'Editar',
                                    action: (itemCode) => setStructureDrawerVisible(true)
                                }
                            ]

                        }
                    />
                }
                content={
                    <ContentWrapperSCP>
                        {/* <RowSCP>
                            <TextCP text={'Capacidade: '} />
                            <TextCP
                                text={`${props.structure.capacity ? `${props.structure.capacity} ${ProductUtils.translateUnitOfMeasurement(props.structure.unitOfMeasurement)}` : 'Não informada'}`} style={valueStyle}
                            />
                        </RowSCP> */}
                    </ContentWrapperSCP>
                }
                footer={
                    <CardFooterICP
                        onDelete={deleteReq.runRequest}
                        deleteLoading={false}
                        confirmLabel={'Tem certeza que quer excluir esta estrutura?'}
                    >
                        <ActionsSCP>
                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px', height: '100%' }}
                                onClick={() => setStructureDrawerVisible(true)}
                                icon={<FontAwsomeIconCP icon={faPenAlt} />}

                            >
                                Editar
                            </ButtonCP>
                        </ActionsSCP>
                    </CardFooterICP>
                }
            />

            <StructureDrawerCP
                show={structureDrawerVisible}
                onSuccess={() => {
                    setStructureDrawerVisible(false)

                }}
                onCancel={() => {
                    setStructureDrawerVisible(false)
                }}
                structureCode={props.structure.code}
            />

        </WrapperSCP>
    )
}

const ActionsSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const WrapperSCP = styled.div`
  
`

const POWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .ant-btn{
        margin-left: 10px;
        margin-bottom: 5px;
        min-width: 150px;
    }
    
`

const ContentWrapperSCP = styled.div`
    /* padding: 0 10px;   
    min-height: 20px; */
`
const RowSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    margin-bottom: 5px;
    .ant-typography{
        font-size: 13px;
    }
`
