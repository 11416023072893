import { Carousel, Image } from 'antd'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { CarouselItemICP } from 'common/components/carousel/inner/CarouselItemICP'
import { ImageBlockICP } from 'common/components/custom-photo-gallery/inner/ImageBlockICP'
interface ICarouselCPProps {
    children?: JSX.Element[]
    source?: string[]
    numberOfThumbs?: number
    dots?: boolean
    arrows?: boolean
    draggable?: boolean
    effect?: 'scrollx' | 'fade'
    dotPosition?: 'top' | 'bottom' | 'left' | 'right'
    autoPlay?: boolean
    arrowsColor?: string
}

/**
 * COMPONENTE para exibição elementos em carrosel
 * @author kiaravzm
 */
export function CarouselCP(props: ICarouselCPProps): JSX.Element {
    const thumbs = !!props.numberOfThumbs && !!props.source &&
        (props.source.length >= props.numberOfThumbs) ?
        props.source.slice(1, props.numberOfThumbs + 1) : []

    return (
        <CarouselWrapperSCP arrowsColor={props.arrowsColor}>
            <Carousel
                arrows={props.arrows ?? true}
                dots={props.dots ?? true}
                effect={props.effect}
                easing={'linear'}
                dotPosition={props.dotPosition}
                draggable={props.draggable ?? true}
                nextArrow={<FontAwesomeIcon icon={faCaretRight} color={props.arrowsColor} />}
                prevArrow={<FontAwesomeIcon icon={faCaretLeft} color={props.arrowsColor} />}
                autoplay={props.autoPlay ?? true}
            >
                {
                    !!props.source &&
                    props.source.map(url => (
                        <CarouselItemICP key={`key_${url}`} imageUrl={url} />
                    ))
                }
                {
                    !!props.children &&
                    props.children
                }
            </Carousel>
            {
                !!thumbs.length &&
                <ThumbsWrapperSCP style={{ gridTemplateColumns: props.numberOfThumbs ? `repeat(${props.numberOfThumbs}, 1fr)` : 'repeat(4, 1fr)' }}>
                    <Image.PreviewGroup>
                        {thumbs.map(url => (
                            <ImageBlockICP
                                key={`${url}_key`}
                                url={url}
                                previewWidth={'100%'}
                                previewConfig={{
                                    mask: <MaskWrapperSCP />
                                }}
                            />
                        ))}
                    </Image.PreviewGroup>
                </ThumbsWrapperSCP>
            }
        </CarouselWrapperSCP>

    )
}

const CarouselWrapperSCP = styled.div<{ arrowsColor?: string }>`
    padding: 0 20px;
    .ant-carousel .slick-dots{
        z-index: 5;
    }
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next,
    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover {
        font-size: inherit;
        color: ${props => props.arrowsColor ?? 'white'};
    }
`

const ThumbsWrapperSCP = styled.div`
    display: grid;
    column-gap: 10px;
    margin-top: 10px;
    @media(max-width: 768px){
        grid-template-columns: 1fr 1fr!important;
        row-gap: 10px;
    }
`

const MaskWrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    opacity: .5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
`