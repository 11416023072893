/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { IsBoolean, IsOptional } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsEmail } from 'common/validation/decorators/IsEmail'

import { IsString } from 'common/validation/decorators/IsString'

import { PersonGenderEnum } from 'modules/person/enums/PersonGenderEnum'
import { IPersonResponseDTO } from 'modules/person/services/dtos/responses/IPersonResponseDTO'
import { IsNotEmpty } from '../../../../../common/validation/decorators/IsNotEmpty'

export class PersonFormValidator extends FormModelValidator {

    // @IsOptional()
    // @IsBoolean()
    // legal?: boolean

    @IsString()
    @IsOptional()
    picture: string

    @IsString()
    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    phone: string

    // @IsOptional()
    // gender?: PersonGenderEnum

    @IsEmail()
    @IsOptional()
    email: string

    @IsOptional()
    occupation?: string

    @IsOptional()
    cpfCnpj: string

    constructor(dto?: IPersonResponseDTO) {
        super(dto)
        if (dto)
            Object.assign(this, dto)
        // else
        //     this.legal = false

    }
}