import { Input } from 'antd'
import { FormItemICP } from 'common/components/form-fields/inner/FormItemICP'
import styled from 'styled-components'
import React from 'react'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormItemInputCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'

const MIN_ROWS_DEFAULT = 2

interface ITextAreaCPProps<FModelTP extends FormModelValidator = any> extends IFormItemInputCommonProps<FModelTP> {
    minRows?: number
    maxRows?: number
    maxLength?: number
    loading?: boolean
}

/**
 * COMPONENTE
 * Input do tipo 'textarea'.
 *
 * @author hjcostabr
 */
export function TextAreaCP<FModelTP extends FormModelValidator = any>(props: ITextAreaCPProps<FModelTP>): JSX.Element {
    return (
        <FormItemICP<FModelTP, IFormItemInputCommonProps<FModelTP>>
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            required={props.required}
            width={props.width}
            errorMessage={props.errorMessage}
            loading={props.loading}
        >

            <TextAreaSCP
                value={props.value}
                maxLength={props.maxLength}
                autoSize={{
                    minRows: props.minRows ?? MIN_ROWS_DEFAULT,
                    maxRows: props.maxRows
                }}
            />

        </FormItemICP>
    )
}

const TextAreaSCP = styled(Input.TextArea)`
    
    width: 100%;

    &.ant-input {
        border: none;
        background: ${props => props.theme.inputBg};
        padding: 10px 11px;
        border-radius: 5px;
    }
`
