import { useMediaQuery } from 'react-responsive'

/**
 * Utils para responsividade
 *
 * @author Victor
 */

interface IScreenSizes {
    xs: boolean
    sm: boolean
    md: boolean
    lg: boolean
    xl: boolean
    smd: boolean
}

export function useScreenSize(): IScreenSizes {

    /*
    Tamanhos de tela pelo padrão do bootsrap
    xs: extra small
    sm: small
    md: medium
    lg: large
    xs: extra large
    */
    const xs = useMediaQuery({
        maxWidth: 575.98
    })

    const sm = useMediaQuery({
        minWidth: 576, maxWidth: 767.98
    })

    const md = useMediaQuery({
        minWidth: 768, maxWidth: 991.98
    })

    const lg = useMediaQuery({
        minWidth: 992, maxWidth: 1199.98
    })

    const xl = useMediaQuery({
        minWidth: 1200
    })

    // smaller than medium
    const smd = useMediaQuery({
        maxWidth: 991.98
    })

    return { xs, sm, md, lg, xl, smd }
}
