import React from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

interface ICollapseTriggerCPProps {
    isMobile?: boolean
    onClick?: () => void
}

export function CollapseTriggerCP(props: ICollapseTriggerCPProps): JSX.Element {
    return (
        <WrapperSCP isMobile={props.isMobile}>
            <FontAwesomeIcon icon={faBars} size={'2x'} onClick={props.onClick} />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ isMobile?: boolean }>`
    cursor: pointer;
    margin: 0;
    color: ${props => props.isMobile ? props.theme.primaryColor : '#fff'};
 
`