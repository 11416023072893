import { UserPermissionsEnum } from 'common/enums/UserPermissionsEnum'
import { WeekdayEnum } from 'common/enums/WeekdayEnum'
import { ActivityTypeEnum } from 'modules/person/enums/ActivityTypeEnum'
import { PersonGenderEnum } from 'modules/person/enums/PersonGenderEnum'

export const PersonUtils = {
    translateWeekday(weekday: WeekdayEnum, abreviate?: boolean): string {
        switch (weekday) {
            case WeekdayEnum.SUNDAY:
                return abreviate ? 'Dom.' : 'Domingo'
            case WeekdayEnum.MONDAY:
                return abreviate ? 'Seg.' : 'Segunda'
            case WeekdayEnum.TUESDAY:
                return abreviate ? 'Ter.' : 'Terça'
            case WeekdayEnum.WEDNESDAY:
                return abreviate ? 'Qua.' : 'Quarta'
            case WeekdayEnum.THURSDAY:
                return abreviate ? 'Qui.' : 'Quinta'
            case WeekdayEnum.FRIDAY:
                return abreviate ? 'Sex.' : 'Sexta'
            case WeekdayEnum.SATURDAY:
                return abreviate ? 'Sáb.' : 'Sábado'
            default:
                return ''
        }
    },

    translateGender(type: PersonGenderEnum): string {
        switch (type) {
            case PersonGenderEnum.FEMALE:
                return 'Feminino'
            case PersonGenderEnum.MALE:
                return 'Masculino'
            default:
                return ''
        }
    },

    translatePermissions(type: UserPermissionsEnum): string {
        switch (type) {
            case UserPermissionsEnum.POST_HARVEST:
                return 'Pós-colheita'
            case UserPermissionsEnum.MANEUVER:
                return 'Manejo'
            case UserPermissionsEnum.FINANCIAL:
                return 'Financeiro'
            case UserPermissionsEnum.PEOPLE:
                return 'Equipe'
            default:
                return ''
        }
    },

    translateActivityType(type: ActivityTypeEnum): string {
        switch (type) {
            case ActivityTypeEnum.MANEUVER:
                return 'Manejo'
            case ActivityTypeEnum.POST_HARVEST:
                return 'Colheita'
            default:
                return ''
        }
    }
}