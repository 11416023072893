import { InputCP } from 'common/components/form-fields/input/InputCP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { TextCP } from 'common/components/text/TextCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { CompanyFormValidator } from 'modules/company/components/company-drawer/inner/CompanyFormValidator'
import { CompanyRequests } from 'modules/company/services/CompanyRequests'
import { ICompanyResDTO } from 'modules/company/services/dtos/response/ICompanyResDTO'
import { ISysAdminResDTO } from 'modules/company/services/dtos/response/ISysAdminResDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface ISchemaSearchInputCPProps {
    formStateManager: IFormStateManager<CompanyFormValidator>
    disabled: boolean
    loading: boolean
    setSysAdminCode: (code?: number) => void
}

export function SchemaSearchInputCP(props: ISchemaSearchInputCPProps): JSX.Element {
    const [schemaData, setSchemaData] = useState<ISysAdminResDTO | undefined>()
    const searchReq = useRequest<ISysAdminResDTO>()
    useEffect(onRequestChange, [searchReq.awaiting])

    function searchSchema(email: string): void {
        if (!!email) {
            searchReq.runRequest(CompanyRequests.getSysAdmin({ email }))
            return
        }
        props.setSysAdminCode(undefined)
        setSchemaData(undefined)
    }

    function onRequestChange(): void {
        if (searchReq.awaiting || !searchReq.tried)
            return

        if (!searchReq.success || !searchReq.returnData) {
            props.setSysAdminCode(undefined)
            setSchemaData(undefined)
            return
        }

        setSchemaData(searchReq.returnData)
        props.setSysAdminCode(searchReq.returnData.code)
    }

    return (
        <WrapperSCP>
            <InputCP
                label={'E-mail'}
                placeholder={'E-mail'}
                fieldName={'email'}
                onChange={_.debounce((text) => searchSchema(text), 750)}
                type={'text'}
                disabled={props.disabled}
                formStateManager={props.formStateManager}
                required={true}
                loading={props.loading}
            />
            {
                !!schemaData &&
                <InfoWarningSCP>
                    <TextWrapperSCP>
                        <TextCP text={'Foi encontrada uma conta existente para o e-mail inserido'} />
                        <TextCP text={`Nome: ${schemaData.customerName}`} style={{ fontWeight: 600 }} />
                        <TextCP text={'Altenção! Ao salvar a empresa será vinculada a conta existente'} />
                    </TextWrapperSCP>
                </InfoWarningSCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`

const TextWrapperSCP = styled.div`
   display: flex;
   flex-direction: column;
   .ant-typography {
        font-size: 12px;
        color: ${props => props.theme.gray}
    }
    @media(max-width: 768px){
        .ant-typography {
            color: white;
        }
    }
    
`

const InfoWarningSCP = styled.div`
    padding: 10px;
     margin: 10px 0;
    border: 1px solid ${props => props.theme.primaryColor};
    border-radius: 8px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 10px;
    column-gap: 5px;
    svg {
        font-size: 20px;
        color: ${props => props.theme.primaryColor}
    }
    @media(max-width: 768px){
        color: white;
        border: 1px solid white;
        svg {
        color: white;
      }
    }
`