import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton, Space } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IPropertyResponseDTO } from 'modules/property/services/dtos/responses/IPropertyResponseDTO'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { PropertyActions } from '../../../PropertyActions'
import { PropertyRequests } from '../../../services/PropertyRequests'
interface IProps {
}

export function PropertyDescriptionICP(props: IProps): JSX.Element {
    const [description, setDescription] = useState<string>()
    const [edit, setEdit] = useState(false)
    const property = useSelector((state: IReduxState) => state.property)
    const screenSize = useScreenSize()

    useEffect(() => {
        if (!property)
            return
        setDescription(property.description)
    }, [property])

    const request = useRequest<IPropertyResponseDTO>()

    useEffect(onRequestChange, [request.awaiting])

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao atualizar descrição!')
        }

        NotificationHelper.success('Descrição atualizada com sucesso!')
        PropertyActions.setProperty(request.returnData)
    }

    function saveDescription(): void {
        if (!!description)
            request.runRequest(PropertyRequests.updateDescription(property.code, description))

        setEdit(false)
    }

    return (
        <WrapperSCP>
            <Skeleton active={true} loading={!property} paragraph={{ rows: 6 }}>
                {!edit &&
                    <EditableCommentSCP onClick={() => (screenSize.smd ? null : setEdit(true))}>
                        {description ?? 'Clique aqui para inserir uma descrição de sua propriedade.'}
                        {
                            screenSize.smd &&
                            <ButtonCP
                                icon={<FontAwesomeIcon icon={faEdit} />}
                                type={'link'}
                                onClick={() => setEdit(true)}
                                size={'small'}
                            />
                        }
                    </EditableCommentSCP>}
                {edit &&
                    <Space direction={'vertical'} style={{ width: '100%' }}>
                        <TextAreaCP value={description} minRows={5} maxRows={30} onChange={setDescription} maxLength={850} />
                        <Space direction={'horizontal'} style={{ marginBottom: '10px' }}>
                            <ButtonCP type={'primary'} onClick={saveDescription}>Salvar</ButtonCP>
                            <ButtonCP type={'default'} onClick={() => setEdit(false)}>Cancelar</ButtonCP>
                        </Space>
                    </Space>}
            </Skeleton>
        </WrapperSCP>

    )
}

const EditableCommentSCP = styled.div`
    padding: 0 20px;
    margin-top: 10px;
    text-align: justify;
    font-size: 16px;
    white-space: pre-wrap;
    min-height: 300px;
    &:hover{
        border: 1px #f5f5f5 solid;
        border-radius: 5px;
        transition: 0.3s;
        -webkit-box-shadow: -6px 7px 13px 1px rgba(224,217,224,0.3);
        -moz-box-shadow: -6px 7px 13px 1px rgba(224,217,224,0.3);
        box-shadow: -6px 7px 13px 1px rgba(224,217,224,0.3);
        cursor: url('/assets/cursor-pen.png'), auto;
    }

`

const WrapperSCP = styled.div`
    margin-bottom: 5em;
`
