import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch } from 'antd'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import React from 'react'
import styled from 'styled-components'
import { TextCP } from '../text/TextCP'

interface ISwitchCPProps {
    isTextInside: boolean
    textOutside?: string
    textInsideChecked?: string
    textInsideUnchecked?: string
    isChecked: boolean
    disabled?: boolean
    loading?: boolean
    onChangeAction?: SwitchChangeEventHandler
}

/**
 * Componente Wrapper de Switch
 * @param {ISwitchCPProps} props
 *
 * @author renatofs
 */
export function SwitchCP(props: ISwitchCPProps): JSX.Element {

    return (
        <SwitchWrapperSCP>
            {
                props.isTextInside &&
                <Switch
                    checkedChildren={props.textInsideChecked}
                    unCheckedChildren={props.textInsideUnchecked}
                    checked={props.isChecked}
                    disabled={props.disabled}
                    onChange={props.onChangeAction}
                    defaultChecked={true}
                    loading={props.loading}
                />
            }

            {
                !props.isTextInside &&
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={props.isChecked}
                    disabled={props.disabled}
                    onChange={props.onChangeAction}
                    defaultChecked={true}
                    loading={props.loading}
                />
            }

            {
                !props.isTextInside && props.textOutside &&
                <TextCP text={props.textOutside} />
            }
        </SwitchWrapperSCP>
    )

}

const SwitchWrapperSCP = styled.div`
   display: flex;
   margin: .5rem 0;
   .ant-switch{
        margin: 0 .5rem 0 0;
   }
`
