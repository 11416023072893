/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { TextCP } from 'common/components/text/TextCP'
import React, { CSSProperties, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'

import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { AnalysisTypeFilterCP } from 'modules/analysis/components/analysis-type-filter/AnalysisTypeFilterCP'
import _ from 'lodash'
import { AnalysisUtils } from 'modules/analysis/utils/AnalysisUtils'
import { AnalysisTypeEnum } from 'modules/analysis/enums/AnalysisTypeEnum'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { LogbookHeaderSkeletonCP } from 'modules/logbook/components/logbook-header-skeleton/LogbookHeaderSkeletonCP'
import { MaskUtils } from 'common/utils/MaskUtils'

type RouteParamsTP = { code?: string }
interface IBatchAnalysisHeaderICPProps {
    type: AnalysisTypeEnum
    setType: (type: AnalysisTypeEnum) => void
}

export function BatchAnalysisHeaderICP(props: IBatchAnalysisHeaderICPProps): JSX.Element {
    const btnPositionStyle: CSSProperties = { position: 'absolute', top: '1em', right: '1em' }
    const screenSize = useScreenSize()
    const [showMobileDetails, setShowMobileDetails] = useState<boolean>(!screenSize.smd)

    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const routeParams = useParams<RouteParamsTP>()

    const [batchCode, setBatchCode] = useState<number>()
    const [batch, setBatch] = useState<IBatchResponseDTO>()

    const request = useRequest<IBatchResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(() => {
        if (!!routeParams.code)
            setBatchCode(+routeParams.code)
    }, [routeParams])

    useEffect(getBatchDetails, [batchCode])
    useEffect(onItemChange, [updateStatus])

    function getBatchDetails(): void {
        if (!!batchCode)
            request.runRequest(BatchRequests.get(batchCode))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE)
            getBatchDetails()
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar detalhes!')
        }

        setBatch(request.returnData)
        ListActions.updateIsDone()
    }

    if (!batch || request.awaiting)
        return <LogbookHeaderSkeletonCP />

    return (
        <WrapperSCP>
            <CardWrapperSCP>
                <CardHeaderICP
                    highlight={`#${batch.code}`}
                    title={`${BatchUtils.translateMethod(batch.method)}`}
                    categoryTitle={`${batch.fields.map(fie => fie.name).join(', ')}`}
                    subtitle={
                        <ContentInfoSCP style={{ display: `${showMobileDetails ? 'grid' : 'none'}` }}>
                            <RowSCP>
                                <TextCP text={'Id na properiedade :\u00A0'} style={{ fontWeight: '500 ' }} />
                                <TextCP text={`${batch.farmId ?? '-'} `} />
                            </RowSCP>
                            <RowSCP>
                                <TextCP text={'Safra:\u00A0'} style={{ fontWeight: '500 ' }} />
                                <TextCP text={`${batch.season}`} />
                            </RowSCP>
                            <RowSCP>
                                <TextCP text={'Primeira análise em:\u00A0'} style={{ fontWeight: '500 ' }} />
                                <TextCP text={`${batch.sensoryAnalysis && batch.sensoryAnalysis.length ? DateUtils.formatDate(AnalysisUtils.getFirstAnalysisDate(batch.sensoryAnalysis), DateFormatEnum.BR_WITHOUT_TIME) : 'Sem análises'}`} />
                            </RowSCP>
                            <RowSCP>
                                <TextCP text={'Origem:\u00A0'} style={{ fontWeight: '500 ' }} />
                                <TextCP text={`${BatchUtils.translateOrigin(batch.origin)}`} />
                            </RowSCP>
                        </ContentInfoSCP>
                    }
                />
                {
                    screenSize.smd &&
                    <ButtonCP
                        onClick={() => setShowMobileDetails(!showMobileDetails)}
                        size={'small'}
                        style={btnPositionStyle}
                        icon={<FontAwsomeIconCP icon={!showMobileDetails ? faArrowDown : faArrowUp} />}
                        type={'primary'}
                    />
                }
            </CardWrapperSCP>
            <RightContentSCP>
                <ScoreSCP>
                    <RowSCP style={{ alignItems: 'flex-end' }}>
                        <TextCP text={`${MaskUtils.applyNumberMask(batch.averageScore ?? 0)}`} style={{ fontSize: '19px', fontWeight: '600', lineHeight: '22px' }} />
                        <TextCP text={'\u00A0pontos'} style={{ fontWeight: '500 ' }} />
                    </RowSCP>
                    <RowSCP style={{ alignItems: 'flex-end' }}>
                        <TextCP text={`${MaskUtils.applyNumberMask(batch.averagePicking ?? 0)}%`} style={{ fontSize: '19px', fontWeight: '600', lineHeight: '22px' }} />
                        <TextCP text={'\u00A0 catação média'} style={{ fontWeight: '500 ' }} />
                    </RowSCP>
                </ScoreSCP>
                <AnalysisTypeFilterCP
                    onChangeType={props.setType}
                    type={props.type}
                />
            </RightContentSCP>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    display: grid;
    grid-template-columns:1.5fr 1fr;
    width: 100%;
    column-gap: 20px;
    @media (max-width: 768px){
        grid-template-columns: 1fr;
    }
  
`

const CardWrapperSCP = styled.div`
    position: relative;

`

const RowSCP = styled.div`
    display: flex;
    margin-bottom: 5px;

`
const RightContentSCP = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;   
    width: 100%;

    @media(max-width: 768px){
     
    }
`

const ScoreSCP = styled.div`
    display: flex;
    flex-direction: column;   
    align-items: flex-end;
     width: 100%;
    justify-content: space-between;
    .ant-typography{
        font-size: 12px;
        color: ${props => props.theme.primaryColor};
    }
    @media(max-width: 768px){
        margin-top: 15px;   
        align-items: flex-end;    
        flex-direction: row;
        justify-content: space-around; 
    }
`

const SubTitleSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    width: 100%;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }

`

const ContentInfoSCP = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    margin: 5px 0;
    .ant-typography{
        font-size: 12px;
    }

    @media (max-width: 768px){
        grid-template-columns: 1fr;
    }
`

