import moment from 'moment-timezone'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { AvatarCP } from 'common/components/avatar/AvatarCP'
import { TextCP } from 'common/components/text/TextCP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { LogbookUtils } from 'modules/logbook/utils/LogbookUtils'
import { momentPtBrLocale } from 'common/utils/moment-pt-br-locale'
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { IPersonResponsibleResDTO } from 'modules/logbook/services/dtos/response/IPersonResponsibleResDTO'
import { UnityUtils } from 'common/utils/UnityUtils'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'

interface ILogbookEntryICPProps<T> {
    date: string
    endDate?: string
    startTime?: string
    endTime?: string
    peopleResponsible?: IPersonResponsibleResDTO[]
    notes?: string
    content?: JSX.Element
    actions?: JSX.Element
    isLastOne?: boolean
    language?: LanguageEnum
}

export function LogbookEntryICP<T>(props: ILogbookEntryICPProps<T>): JSX.Element {
    const screenSize = useScreenSize()
    const [height, setHeight] = useState(0)
    const ref = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (!!ref.current)
            setHeight(ref.current.clientHeight)
    }, [])

    useEffect(setLanguage, [props.language])

    function setLanguage(): void {
        if (props.language === LanguageEnum.EN) {
            moment.locale('en_US')
            return
        }

        moment.locale('pt_BR', momentPtBrLocale)
    }

    return (
        <MainWrapperSCP ref={ref}>
            <DateTimelineSCP isLastOne={props.isLastOne} currentHeight={height}>
                <TextCP
                    text={moment(props.date).format('DD')}
                    style={{
                        fontSize: '20px',
                        fontWeight: '600',
                        color: 'white',
                        lineHeight: '18px',
                        leterSpacing: '3px'
                    }}
                />
                <TextCP
                    text={`${moment(props.date).format('MMM')}`}
                    style={{
                        fontSize: '12px',
                        fontWeight: 'normal',
                        color: 'white',
                        lineHeight: '13px',
                    }}
                />
            </DateTimelineSCP>
            <CenterInfoWrapperSCP>

                <DateAndTimeSCP>
                    <TextCP
                        text={`${LogbookUtils.getDateText(props.date, props.language)} ${!!props.startTime ?
                            !!props.endTime ?
                                ` - das ${props.startTime}h ás ${props.endTime}h`
                                : ` - iniciado ás ${props.startTime}h`
                            :
                            ''
                            }`}
                        style={{ fontSize: '14px', fontWeight: 500 }}
                    />
                </DateAndTimeSCP>

                {
                    props.content &&
                    <ExtraInfoSCP>
                        {props.content}
                    </ExtraInfoSCP>
                }
                <ResponsibleWrapperSCP>
                    {
                        !!props.peopleResponsible &&
                        props.peopleResponsible.map(personRes => (
                            <ResponsibleInfoSCP key={`${personRes.person.name}_${personRes.code}_key`}>
                                <AvatarCP src={personRes.person.profilePicture} size={30} />
                                <NameAndOccupationSCP>
                                    <TextCP
                                        text={`${personRes.person.name}`}
                                        strong={true}
                                        style={{ fontSize: '12px' }}
                                    />

                                    {
                                        !!personRes.quantity &&
                                        <TextCP
                                            text={`${personRes.quantity} ${UnityUtils.translatePaymentUnity(personRes.paymentUnity)}`}
                                            style={{ fontSize: '12px', color: `${CostumizedThemeConfig.gray}` }}
                                        />
                                    }
                                </NameAndOccupationSCP>
                            </ResponsibleInfoSCP>
                        ))
                    }
                </ResponsibleWrapperSCP>
                {
                    props.notes &&
                    <NoteWrapperSCP>
                        {props.notes}
                    </NoteWrapperSCP>
                }
                {
                    screenSize.smd &&
                    <ActionsWrapperSCP>
                        {props.actions}
                    </ActionsWrapperSCP>
                }
            </CenterInfoWrapperSCP>
            {
                !screenSize.smd &&
                <ActionsWrapperSCP>
                    {props.actions}
                </ActionsWrapperSCP>
            }

        </MainWrapperSCP>
    )
}

const MainWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: .3fr 5fr 1fr;
    margin: 10px;
    column-gap: 20px;
    @media(max-width: 768px){
        grid-template-columns: 40px 1fr;
    }
`

const NoteWrapperSCP = styled.div`
    background: #e6e6e6;
    padding: 10px;
    border-radius: 5px;
`
const CenterInfoWrapperSCP = styled.div`
    margin: 10px 0 10px 12px;
    display: grid;
    row-gap: 8px;
`
const DateAndTimeSCP = styled.div`  
    .ant-typography{
        color:  ${props => props.theme.primaryColor};
    }

`
const DateTimelineSCP = styled.div<{ isLastOne?: boolean, currentHeight?: number }>`
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    justify-content: center;
    position: relative;
    &::after{
        display: ${props => (props.isLastOne ? 'none' : 'block')};
        position: absolute;
        content: '';
        background: ${props => props.theme.primaryColor};
        width: 1px;
        height:  ${props => (!!props.currentHeight ? `${props.currentHeight}px` : '300px')};
        top: 60px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
   

`
const ExtraInfoSCP = styled.div`

`

const ActionsWrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .ant-btn{
        margin-left: 5px;
    }
`

const ResponsibleInfoSCP = styled.div`
    display: flex;
    margin: 5px 0;
`

const NameAndOccupationSCP = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`
const ResponsibleWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    @media(max-width: 768px){
        grid-template-columns: 1fr ;
         row-gap: 10px;
    }
`