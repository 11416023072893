import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Radar } from 'react-chartjs-2'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { ColorUtils } from 'common/utils/ColorUtils'
import { SchemaDataTP } from 'common/redux/ReduxTypes'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { useSelector } from 'react-redux'
import { ThemeConfig } from 'config/ThemeConfig'
import { IBatchSensoryAnalysisDTO } from '../../../batch/services/dtos/responses/IBatchAnalysisResponseDTO'

interface ISensoryAnalisysRadarCPProps {
    item?: IBatchSensoryAnalysisDTO
    language?: LanguageEnum
    hideLegend?: boolean
}

const MAX_ = 10
const MIN_ = 6
const LABELS_PT = ['Aroma', 'Sabor', 'Sabor Residual', 'Acidez', 'Corpo', 'Balanço', 'Uniformidade', 'Xícara Limpa', 'Doçura', 'Nota Final']
const LABELS_EN = ['Aroma', 'Flavor', 'After Taste', 'Acidity', 'Body', 'Balance', 'Uniformidity', 'Clean cup', 'Sweetness', 'Final Score']

export function SensoryAnalisysRadarCP(props: ISensoryAnalisysRadarCPProps): JSX.Element {
    const persistedSchema: SchemaDataTP | null = useSelector((state: IReduxState) => _.get(state, 'schemaData'))

    const [labels, setLabels] = useState(LABELS_PT)

    useEffect(translateLabels, [props.language])

    function translateLabels(): void {
        if (props.language === LanguageEnum.EN)
            setLabels(LABELS_EN)
        else
            setLabels(LABELS_PT)
    }
    const data = [
        props.item?.aroma,
        props.item?.flavor,
        props.item?.afterTaste,
        props.item?.acidity,
        props.item?.body,
        props.item?.balance,
        props.item?.uniformidity,
        props.item?.cleanCup,
        props.item?.sweatness,
        props.item?.finalGrade,
    ]

    function mountChartData(): any {
        return {
            labels,
            datasets: [
                {
                    label: props.item?.qGrader.name,
                    backgroundColor: ColorUtils.hexToRgb(persistedSchema?.color ?? ThemeConfig.primaryColor, 0.8),
                    borderColor: ColorUtils.hexToRgb(persistedSchema?.color ?? ThemeConfig.primaryColor, 0.8),
                    pointBackgroundColor: ColorUtils.hexToRgb(persistedSchema?.color ?? ThemeConfig.primaryColor, 0.8),
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: ColorUtils.hexToRgb(persistedSchema?.color ?? ThemeConfig.primaryColor, 0.8),
                    data,
                }
            ]
        }
    }

    const minFromData = _.min(data) ?? 6
    return (
        <div style={{ position: 'relative' }}>
            <Radar
                data={{
                    labels,
                    datasets: [
                        {
                            label: props.item?.qGrader.name,
                            backgroundColor: ColorUtils.hexToRgb(persistedSchema?.color ?? ThemeConfig.primaryColor, 0.5),
                            borderColor: ColorUtils.hexToRgb(persistedSchema?.color ?? ThemeConfig.primaryColor, 0.8),
                            pointBackgroundColor: ColorUtils.hexToRgb(persistedSchema?.color ?? ThemeConfig.primaryColor, 0.8),
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: ColorUtils.hexToRgb(persistedSchema?.color ?? ThemeConfig.primaryColor, 0.8),
                            data,
                        }
                    ]
                }}
                options={{
                    legend: {
                        display: !props.hideLegend
                    },
                    lineTension: 5,
                    scale: {
                        ticks: {
                            suggestedMin: MIN_ < minFromData ? MIN_ : minFromData,
                            suggestedMax: MAX_
                        }
                    }
                }}
            />
        </div>
    )
}
