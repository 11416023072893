import { ListActions } from 'common/components/list/inner/ListActions'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { ScrollSliderActions } from 'common/components/scoll-slider/inner/ScrollSliderActions'
import { MouseStatusTP } from 'common/components/scoll-slider/types/MouseStatusTP'
import { LanguageActions } from 'common/components/translation/LanguageActions'
import { LanguageTP } from 'common/components/translation/types/LanguageTP'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { LoggedUserDataTP, ListStateControlTP, SearchDataFilterTP, SchemaDataTP } from 'common/redux/ReduxTypes'
import { ReduxUtils } from 'common/redux/ReduxUtils'
import { AuthActions } from 'modules/auth/AuthActions'
import { SchemaActions } from 'modules/company/actions/SchemaActions'
import { ManeuverActions } from 'modules/maneuver/ManeuverActions'
import { PropertyActions } from 'modules/property/PropertyActions'
import { ReportActions } from 'modules/report/ReportActions'
import { IPropertyResponseDTO } from '../../modules/property/services/dtos/responses/IPropertyResponseDTO'

/**
 * REDUX
 * Determina mapa de reducers para gestao de estado da aplicacao
 * via redux.
 *
 * @todo: Encontrar 01 forma de reidratar string (auth token)
 * @todo: Eliminar elementos de estado desnecessarios (aos poucos)
 *
 * @author hjcostabr
 * @author Lucas Rosa
 */
export const Reducers: IReduxState = {

    loggedUser: ReduxUtils.getPayloadReplacingReducer<LoggedUserDataTP>(AuthActions.TYPE_UPDATE_LOGGED_USER, null),
    schemaData: ReduxUtils.getPayloadReplacingReducer<SchemaDataTP>(SchemaActions.TYPE_LOAD_SCHEMA_DATA, null),
    property: ReduxUtils.getPayloadReplacingReducer<IPropertyResponseDTO>(PropertyActions.TYPE_SET_INFO_PROPERTY, null),
    maneuverChildMenuOpened: ReduxUtils.getPayloadReplacingReducer<boolean>(ManeuverActions.TYPE_CHILD_MENU_OPENED, true),
    reportChildMenuOpened: ReduxUtils.getPayloadReplacingReducer<boolean>(ReportActions.TYPE_CHILD_MENU_OPENED, true),
    listState: ReduxUtils.getPayloadReplacingReducer<ListStateControlTP | null>(ListActions.TYPE_UPDATED_LIST_STATE, null),
    searchState: ReduxUtils.getPayloadReplacingReducer<SearchDataFilterTP<any>>(SearchActions.TYPE_UPDATED_SEARCH_STATE, null),
    language: ReduxUtils.getPayloadReplacingReducer<LanguageTP>(LanguageActions.SWITCH_LANGUAGE, null),
}
