import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { BatchProcessFormValidator } from 'modules/batch/components/batch-process-drawer/inner/BatchProcessFormValidator'
import { DividerCP } from 'common/components/divider/DividerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { BenefitFormValidator } from 'modules/batch/components/batch-process-drawer/inner/BenefitFormValidator'
import { ISaveBenefitReqDTO } from 'modules/batch/services/dtos/requests/ISaveBenefitReqDTO'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faPercent } from '@fortawesome/free-solid-svg-icons'
import { AlertCP } from 'common/components/alert/AlertCP'

interface IBenefitFormICPProps {
    formStateManager: IFormStateManager<BatchProcessFormValidator>
}

export function BenefitFormICP(props: IBenefitFormICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [formValidator, setFormValidator] = useState<BenefitFormValidator>()
    const formStateManager = useFormStateManager(formValidator)
    const [benefitData, setBenefitData] = useState<ISaveBenefitReqDTO>()

    useEffect(() => { setMainValidator() }, [benefitData])
    useEffect(init, [])

    function init(): void {
        props.formStateManager.changeFieldValue('storage', undefined)
        props.formStateManager.changeFieldValue('structureCode', undefined)
        if (!!props.formStateManager.getFieldValue('benefit'))
            setBenefitData(props.formStateManager.getFieldValue('benefit'))
    }

    async function setMainValidator(): Promise<void> {
        if (!benefitData)
            return

        props.formStateManager.changeFieldValue('benefit', {
            spoutOne: benefitData.spoutOne ? +benefitData.spoutOne : undefined,
            spoutTwo: benefitData.spoutTwo ? +benefitData.spoutTwo : undefined,
            choice: benefitData.choice ? +benefitData.choice : undefined,
            other: benefitData.other ? +benefitData.other : undefined,
            humidity: benefitData.humidity ? +benefitData.humidity : undefined,
        })

    }
    return (
        <WrapperSCP>
            <DividerCP text={'Informe os dados do beneficiamento'} orientation={'left'} />
            <AlertCP
                type={'info'}
                message={'Lembre-se: 1 saca = 60 kg'}
            />
            <TwoColWrapperSCP>
                <InputCP
                    label={'Bica 1'}
                    type={'number'}
                    fieldName={'spoutOne'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    value={benefitData?.spoutOne}
                    onChange={(spoutOne) => setBenefitData({ ...benefitData, spoutOne })}
                    loading={false}
                    icon={<span>sacas</span>}
                />
                <InputCP
                    label={'Bica 2'}
                    type={'number'}
                    fieldName={'spoutTwo'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    value={benefitData?.spoutTwo}
                    onChange={(spoutTwo) => setBenefitData({ ...benefitData, spoutTwo })}
                    icon={<span>sacas</span>}
                />
            </TwoColWrapperSCP>
            <TwoColWrapperSCP>
                <InputCP
                    label={'Escolha'}
                    type={'number'}
                    fieldName={'choice'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    value={benefitData?.choice}
                    onChange={(choice) => setBenefitData({ ...benefitData, choice })}
                    icon={<span>sacas</span>}
                />
                <InputCP
                    label={'Outro'}
                    type={'number'}
                    fieldName={'other'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    value={benefitData?.other}
                    onChange={(other) => setBenefitData({ ...benefitData, other })}
                    icon={<span>sacas</span>}
                />

            </TwoColWrapperSCP>
            <TwoColWrapperSCP>
                <InputCP
                    label={'Umidade'}
                    type={'number'}
                    fieldName={'humidity'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    value={benefitData?.humidity}
                    onChange={(humidity) => setBenefitData({ ...benefitData, humidity })}
                    icon={<FontAwsomeIconCP icon={faPercent} />}
                />
            </TwoColWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
const FormWrapperSCP = styled.div`
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`