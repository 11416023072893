import { Alert } from 'antd'
import * as React from 'react'
import styled from 'styled-components'

interface IAlertCPProps {
    message: string
    showIcon?: boolean
    description?: string | JSX.Element
    type: 'success' | 'info' | 'warning' | 'error'
    closable?: boolean
}

/**
 * Componente mensagem
 * @author renatofs
 */
export function AlertCP(props: IAlertCPProps): JSX.Element {
    return (
        <WrapperSCP>
            <Alert
                showIcon={props.showIcon}
                message={props.message}
                description={props.description}
                type={props.type}
                closable={props.closable}
            />
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    margin: 5px 0;
    .ant-alert{
        border-radius: 5px;

        &.ant-alert-info{
            background-color: #fbf1ff;
            border: 1px solid #e2c3ef;
        }
        &.ant-alert-warning {
            .anticon svg {
                color: ${props => props.theme.warningColor}
            }
        }
    }
`