import { TextCP } from 'common/components/text/TextCP'
import { SystemConfig } from 'config/SystemConfig'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { IBatchProcessResumeResDTO } from 'modules/batch-presentation/services/dtos/response/IBatchProcessResumeResDTO'
import { LogbookUtils } from 'modules/logbook/utils/LogbookUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { useParams } from 'react-router-dom'
import { BatchOriginEnum } from 'modules/batch/enums/BatchOriginEnum'
import { ColorUtils } from 'common/utils/ColorUtils'
type RouteParamsTP = { batchCode?: string }

interface IProcessCardICPProps {
    process: IBatchProcessResumeResDTO
}

export function ProcessCardICP(props: IProcessCardICPProps): JSX.Element {
    const bgImage = props.process.mainPicture ?? `${SystemConfig.getInstance().uiBaseUrl}/assets/default_${props.process.type}.jpg`
    const isOrigin = props.process.type === BatchOriginEnum.MERGE || props.process.type === BatchOriginEnum.SPLIT || props.process.type === BatchOriginEnum.HARVEST
    const isOriginHarvest = props.process.type === BatchOriginEnum.HARVEST
    const currentLang = useSelector((state: IReduxState) => state.language)
    const lang: LanguageEnum = currentLang === 'pt' ? LanguageEnum.PT : LanguageEnum.EN
    const [dateText, setDateText] = useState<string>(`${LogbookUtils.getDateText(props.process.date, LanguageEnum.PT)}: `)

    const routeParams = useParams<RouteParamsTP>()
    const [batchCode, setBatchCode] = useState<number>()

    useEffect(getDateText, [lang])

    function getDateText(): void {
        setDateText(`${LogbookUtils.getDateText(props.process.date, lang)}: `)
    }

    useEffect(() => {
        if (!!routeParams.batchCode)
            setBatchCode(+routeParams.batchCode)
    }, [routeParams])

    return (
        <MainSCP>
            <WrapperSCP style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' }}>
                <ContentWppSCP>
                    <HeadSCP>
                        <TextCP
                            text={dateText}
                        />
                        <TextCP
                            text={props.process.type}
                            translationFiles={'batch'}
                            translationNameSpace={'batch'}
                        />
                    </HeadSCP>
                    <DetailsSCP>
                        {
                            !!props.process.method &&
                            <RowSCP>
                                {
                                    !isOrigin ?
                                        <TextCP
                                            text={props.process.method.length > 1 ? 'method.plur' : 'method.sing'}
                                            translationFiles={'batch'}
                                            translationNameSpace={'batch'}
                                        />
                                        :
                                        isOriginHarvest &&
                                        <TextCP
                                            text={props.process.method.length > 1 ? 'harvestType.plur' : 'harvestType.sing'}
                                            translationFiles={'batch'}
                                            translationNameSpace={'batch'}
                                        />
                                }

                                {
                                    props.process.type !== BatchOriginEnum.MERGE &&
                                        props.process.type !== BatchOriginEnum.SPLIT &&
                                        ![5755, 4570, 4571].includes(batchCode!) ?
                                        <>
                                            <TextCP text={': '} />
                                            {props.process.method.map((hvt: string, index: number) => (
                                                <RowSCP key={hvt}>
                                                    <TextCP
                                                        text={hvt}
                                                        translationFiles={'batch'}
                                                        translationNameSpace={'batch'}
                                                    />
                                                    {index !== props.process.method.length - 1 && <TextCP text={', '} />}
                                                </RowSCP>
                                            ))}
                                        </>
                                        : props.process.type !== BatchOriginEnum.MERGE &&
                                        props.process.type !== BatchOriginEnum.SPLIT &&
                                        [5755, 4570, 4571].includes(batchCode!) &&
                                        <>
                                            <TextCP text={': '} />
                                            {props.process.method.map((hvt: string, index: number) => (
                                                <RowSCP key={hvt}>
                                                    <TextCP
                                                        text={hvt === 'PULPED_NATURAL' ? 'NATURAL' : hvt}
                                                        translationFiles={'batch'}
                                                        translationNameSpace={'batch'}
                                                    />
                                                    {index !== props.process.method.length - 1 && <TextCP text={', '} />}
                                                </RowSCP>
                                            ))}
                                        </>

                                }
                            </RowSCP>

                        }

                        {
                            !!props.process.structure &&
                            <RowSCP>
                                <TextCP
                                    text={'structure.sing.uppercase'}
                                    translationFiles={'batch'}
                                    translationNameSpace={'batch'}
                                />
                                <TextCP text={': '} />
                                <TextCP
                                    text={props.process.structure}
                                    translationFiles={'batch'}
                                    translationNameSpace={'batch'}
                                />

                            </RowSCP>
                        }

                        {
                            !!props.process.averageHumidity &&
                            <RowSCP>
                                <TextCP
                                    text={'averageHumidity'}
                                    translationFiles={'batch'}
                                    translationNameSpace={'batch'}
                                />
                                <TextCP text={': '} />
                                <TextCP
                                    text={`${props.process.averageHumidity} %`}
                                />
                            </RowSCP>
                        }

                        {
                            !!props.process.averageTemperature &&
                            <RowSCP>
                                <TextCP
                                    text={'averageTemperature'}
                                    translationFiles={'batch'}
                                    translationNameSpace={'batch'}
                                />
                                <TextCP text={': '} />
                                <TextCP
                                    text={`${props.process.averageTemperature} C`}
                                />
                            </RowSCP>
                        }

                        {
                            !!props.process.bags &&
                            <RowSCP>
                                <TextCP
                                    text={'bags'}
                                    translationFiles={'batch'}
                                    translationNameSpace={'batch'}
                                />
                                <TextCP text={': '} />
                                <TextCP
                                    text={`${props.process.bags}`}
                                />
                            </RowSCP>
                        }

                    </DetailsSCP>

                </ContentWppSCP>
                <MaskSCP />
            </WrapperSCP>
        </MainSCP>
    )
}


const WrapperSCP = styled.div`
position: relative;
    width: 270px;
    min-height: 300px;
    border-radius: 25px;
    background-color: ${props => props.theme.primaryColor};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 1em;
    @media(max-width: 768px ){
        width: 97%;
        margin: 0;
    }
`

const ContentWppSCP = styled.div`
        margin: 2em 1em;
        z-index: 2;
        .ant-typography{
            color: white;

        }
`

const HeadSCP = styled.div`
    .ant-typography{    
        font-size: 16px;
        font-weight: 600;
    }
`

const DetailsSCP = styled.div`
    .ant-typography{    
        font-size: 15px;
        font-weight: 400;
    }
`
const RowSCP = styled.div`
  display: flex;
  column-gap: 3px;
  flex-wrap: wrap;
`

const MaskSCP = styled.div`
    position: absolute;
    border-radius: 25px;
    top: 0;
    right: 0;
    background: ${props => props.theme.primaryColor};
    background: ${props => `linear-gradient(4deg, ${ColorUtils.hexToRgb(props.theme.primaryColor, 1)} 0%, ${ColorUtils.hexToRgb(props.theme.primaryColor, 0.5)} 50%, ${ColorUtils.hexToRgb(props.theme.primaryColor, 1)} 100%)`};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`

const MainSCP = styled.div`
`