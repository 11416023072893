import React from 'react'
import styled from 'styled-components'
import { ButtonCP } from '../../../../common/components/button/ButtonCP'

interface IAuthFormFooterCPProps {
    link?: { text: string, onClick: () => void }
    button: { text: string, onClick: () => void }
    awaiting: boolean
}

export function AuthFormFooterCP(props: IAuthFormFooterCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <ButtonCP
                size={'middle'}
                type={'primary'}
                isSubmit={true}
                loading={props.awaiting}
                onClick={props.button.onClick}
                wrapperStyle={{ width: '100%' }}
            >
                {props.button.text}
            </ButtonCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin: 20px 0;
    .ant-btn{
        width: 100%;
    }
`

