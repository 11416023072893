import { ButtonCP } from 'common/components/button/ButtonCP'
import { CardFooterICP } from 'common/components/list/inner/CardFooterICP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { CardListItemICP } from 'common/components/list/inner/CardListItemICP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BatchCardSkeletonICP } from 'modules/batch/components/batch-list/inner/BatchCardSkeletonICP'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { NotificationHelper } from 'common/NotificationHelper'
import { ListActions } from 'common/components/list/inner/ListActions'
import { IPersonResponseDTO } from 'modules/person/services/dtos/responses/IPersonResponseDTO'
import { PersonDrawerCP } from 'modules/person/components/person-drawer/PersonDrawerCP'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { AvatarCP } from 'common/components/avatar/AvatarCP'
import { PersonCardContentICP } from 'modules/person/components/person-list/inner/PersonCardContentICP'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PersonPaymentStatementDrawerCP } from 'modules/person/components/person-payment-statement/PersonPaymentStatementDrawerCP'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'
import { ModalCP } from 'common/components/modal/ModalCP'
import { AuthActions } from 'modules/auth/AuthActions'

interface IPersonCardICPProps {
    person: IPersonResponseDTO
    loading: boolean
}

export function PersonCardICP(props: IPersonCardICPProps): JSX.Element {
    const [showConfirmLogout, setShowConfirmLogout] = useState<boolean>(false)
    const [personDrawerVisible, setPersonDrawerVisible] = useState<boolean>(false)
    const [paymentStatementVisible, setPaymentStatementVisible] = useState<boolean>(false)
    const deleteReq = useRequest(PersonRequests.deletePerson(props.person.code))
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    function onDeleteReqChange(): void {
        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success || !deleteReq.returnData) {
            return RequestUtils.showDefaultErrorNotification(
                deleteReq.error,
                'Ocorreu um erro ao remover este item. Tente novamente.',
            )
        }

        NotificationHelper.success('Membro da equipe removido com sucesso')
        ListActions.mustUpdate()
    }

    if (props.loading)
        return <BatchCardSkeletonICP />

    return (
        <WrapperSCP>
            <CardListItemICP
                key={props.person.code}
                item={props.person}
                loading={props.loading}
                customSkeleton={<BatchCardSkeletonICP />}
                header={
                    <CardHeaderICP
                        highlight={<AvatarCP src={props.person.picture} size={35} />}
                        title={`${props.person.name}`}
                        itemCode={props.person.code}
                        subtitle={props.person.occupation}
                        actions={[
                            {
                                label: 'Editar',
                                action: (itemCode) => setPersonDrawerVisible(true),
                            },
                        ]}
                    />
                }
                content={
                    <PersonCardContentICP
                        personCode={props.person.code}
                        phone={props.person.phone}
                        email={props.person.email}
                        cpf={props.person.cpf}
                        activeUser={!!props.person.isUser}
                    />
                }
                footer={
                    <CardFooterICP
                        onDelete={deleteReq.runRequest}
                        deleteLoading={false}
                        confirmLabel={'Tem certeza que quer excluir esta pessoa?'}
                    >
                        <ActionsSCP>
                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px' }}
                                tooltip={'Editar'}
                                onClick={() => setPersonDrawerVisible(true)}
                                icon={<FontAwsomeIconCP icon={faPen} style={{ margin: '0 5px' }} />}
                            />
                            {PermissionUtils.hasAccess(SystemModulesEnum.MANEUVER) && (
                                <ButtonCP
                                    type={'primary'}
                                    style={{ borderRadius: '5px', height: '100%' }}
                                    onClick={() => setPaymentStatementVisible(true)}
                                >
                                    Ver extrato de pagamento
                                </ButtonCP>
                            )}
                        </ActionsSCP>
                    </CardFooterICP>
                }
            />

            <PersonDrawerCP
                show={personDrawerVisible}
                onSuccess={() => {
                    setPersonDrawerVisible(false)
                }}
                onCancel={() => {
                    setPersonDrawerVisible(false)
                }}
                personCode={props.person.code}
                setShowConfirmLogout={setShowConfirmLogout}
            />
            <PersonPaymentStatementDrawerCP
                show={paymentStatementVisible}
                onCancel={() => {
                    setPaymentStatementVisible(false)
                }}
                person={props.person}
            />

            <ModalCP
                title={'Permissões de usuáro alteradas'}
                visible={showConfirmLogout}
                onOk={() => {
                    AuthActions.logout()
                    setShowConfirmLogout(false)
                }}
                okText={'Ok'}
                noCancel={true}
                destroyOnClose={true}
                centered={true}
            >
                Suas permissões de usuário foram modificadas. Faça o login novamente.
            </ModalCP>
        </WrapperSCP>
    )
}

const ActionsSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const WrapperSCP = styled.div``

const POWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .ant-btn {
        margin-left: 10px;
        margin-bottom: 5px;
        min-width: 150px;
    }
`
