/* eslint-disable react/jsx-one-expression-per-line */
import styled from 'styled-components'
import React from 'react'
import { ModalCP } from 'common/components/modal/ModalCP'

interface IPrivacyPolicyCPProps {
    show: boolean
    onCancel: () => void
}

export function PrivacyPolicyCP(props: IPrivacyPolicyCPProps): JSX.Element {

    return (
        <ModalCP
            title={'Política de privaciade'}
            visible={props.show}
            noFooter={true}
            onClose={props.onCancel}
        >
            <WrapperSCP>
                <p className={'c3'}><span className={'c2'}>1. Pol&iacute;tica Privacidade e Sigilo Comercial </span><span className={'c5'}>Flowins</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>A </span><span className={'c5'}>Flowins </span><span className={'c2'}>preza pela sua privacidade. A nossa pol&iacute;tica &eacute; de respeitar a sua privacidade e o sigilo comercial das opera&ccedil;&otilde;es de sua empresa em rela&ccedil;&atilde;o a qualquer informa&ccedil;&atilde;o sua que possamos coletar a partir de nossos servi&ccedil;os. Solicitamos informa&ccedil;&otilde;es pessoais e sens&iacute;veis apenas quando realmente necess&aacute;rias para o fornecimento de nossos servi&ccedil;os. Fazemos tais solicita&ccedil;&otilde;es apenas pelos meios legalmente permitidos, com o seu consentimento e sua autoriza&ccedil;&atilde;o, ou com base no &nbsp;leg&iacute;timo interesse de quem controla e opera os dados. Tamb&eacute;m informamos por que e como estamos coletando e como ser&aacute; usado.</span></p>
                <p className={'c3'}><span className={'c2'}>As informa&ccedil;&otilde;es coletadas s&atilde;o mantidas apenas pelo tempo necess&aacute;rio para o fornecimento do servi&ccedil;o solicitado e, no caso de descontinuidade do servi&ccedil;o com a </span><span className={'c5'}>Flowins</span><span className={'c2'}>, exclu&iacute;mos da nossa base de dados as informa&ccedil;&otilde;es que lhe dizem respeito ap&oacute;s tr&ecirc;s meses sem reativa&ccedil;&atilde;o da conta.</span></p>
                <p className={'c3'}><span className={'c2'}>Quando armazenamos dados, protegemos dentro de meios comercialmente aceit&aacute;veis, para evitar perdas e roubos, bem como acesso, divulga&ccedil;&atilde;o, c&oacute;pia, uso ou modifica&ccedil;&atilde;o n&atilde;o autorizados.</span></p>
                <p className={'c3'}><span className={'c2'}>O usu&aacute;rio entende e concorda que, para as finalidades de armazenamento, tratamento e processamento para utiliza&ccedil;&atilde;o da </span><span className={'c5'}>Flowins</span><span className={'c2'}>, para envio &agrave;s autoridades competentes para fins de valida&ccedil;&atilde;o ou informa&ccedil;&atilde;o e para fins da presta&ccedil;&atilde;o de servi&ccedil;o, os dados do usu&aacute;rio e de sua atividade comercial poder&atilde;o ser enviados para outras localidades (inclusive para o exterior), e para armazenamento e processamento em servidores de terceiros, f&iacute;sicos ou na nuvem.</span></p>
                <p className={'c3'}><span className={'c2'}>N&atilde;o compartilhamos informa&ccedil;&otilde;es de identifica&ccedil;&atilde;o pessoal publicamente ou com terceiros, exceto quando exigido por lei.</span></p>
                <p className={'c3'}><span className={'c2'}>Al&eacute;m disso, n&atilde;o compartilhamos informa&ccedil;&otilde;es comerciais do seu neg&oacute;cio, a n&atilde;o ser quando o usu&aacute;rio optar pela realiza&ccedil;&atilde;o de venda de lotes de caf&eacute; atrav&eacute;s da plataforma </span><span className={'c5'}>&ldquo;Gerente Flowins&rdquo;</span><span className={'c2'}>. Neste caso, ao disponibilizar para venda lotes de caf&eacute; atrav&eacute;s da plataforma, o usu&aacute;rio estar&aacute; autorizando o compartilhamento das informa&ccedil;&otilde;es referentes aos lotes com terceiros, com potenciais compradores e de forma p&uacute;blica. As informa&ccedil;&otilde;es que poder&atilde;o ser compartilhadas no caso de disponibiliza&ccedil;&atilde;o de lotes para venda pela plataforma incluem os dados referentes &agrave; localiza&ccedil;&atilde;o da fazenda e do talh&atilde;o, a descri&ccedil;&atilde;o e as fotos da propriedade fornecidas pelo Produtor, todas as informa&ccedil;&otilde;es da colheita e dos processos de p&oacute;s-colheita, inclusive as respectivas datas, as avalia&ccedil;&otilde;es do lote, a pontua&ccedil;&atilde;o e os pre&ccedil;os m&aacute;ximo e m&iacute;nimo indicados.</span></p>
                <p className={'c3'}><span className={'c2'}>O nosso s&iacute;tio pode ter links para s&iacute;tios externos que n&atilde;o s&atilde;o operados por n&oacute;s. O usu&aacute;rio dever&aacute; estar ciente de que n&atilde;o temos controle sobre o conte&uacute;do e pr&aacute;ticas desses sites e n&atilde;o podemos aceitar responsabilidade por suas respectivas pol&iacute;ticas de privacidade.</span></p>
                <p className={'c3'}><span className={'c2'}>Voc&ecirc; &eacute; livre para recusar a nossa solicita&ccedil;&atilde;o de informa&ccedil;&otilde;es pessoais e de informa&ccedil;&otilde;es sigilosas de seu neg&oacute;cio, entendendo que talvez n&atilde;o possamos fornecer alguns dos servi&ccedil;os desejados.</span></p>
                <p className={'c3'}><span className={'c2'}>O uso continuado de nosso site ser&aacute; considerado como aceita&ccedil;&atilde;o de nossas pr&aacute;ticas em torno de privacidade, informa&ccedil;&otilde;es pessoais e sigilo comercial. Se voc&ecirc; tiver alguma d&uacute;vida sobre como lidamos com dados do usu&aacute;rio e informa&ccedil;&otilde;es pessoais, entre em contato conosco.</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>2. Pol&iacute;tica de Cookies </span><span className={'c5'}>Flowins</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>2.1. O que s&atilde;o cookies?</span></p>
                <p className={'c3'}><span className={'c2'}>Cookies s&atilde;o pequenos arquivos baixados no seu computador, para melhorar sua experi&ecirc;ncia e s&atilde;o utilizados por diversos s&iacute;tios profissionais e de presta&ccedil;&atilde;o de servi&ccedil;os virtuais. Muitas vezes precisamos armazenar esses cookies e a desativa&ccedil;&atilde;o dos cookies ou o impedimento de seu armazenamento pode acarretar no menor desempenho ou no impedimento do funcionamento de certas funcionalidades do s&iacute;tio e dos servi&ccedil;os.</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>2.2. Como usamos os cookies?</span></p>
                <p className={'c3'}><span className={'c2'}>Utilizamos cookies por v&aacute;rios motivos, em especial para aumentar o desempenho e incluir funcionalidades. Infelizmente, na maioria dos casos, n&atilde;o existem op&ccedil;&otilde;es padr&atilde;o para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam aos servi&ccedil;os prestados pela </span><span className={'c5'}>Flowins</span><span className={'c2'}>. Para um melhor desempenho de todas as funcionalidades oferecidas, &eacute; recomend&aacute;vel que voc&ecirc; deixe todos os cookies se n&atilde;o tiver certeza se precisa ou n&atilde;o deles, caso sejam usados para fornecer um servi&ccedil;o que voc&ecirc; usa.</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>2.3. Desativar cookies</span></p>
                <p className={'c3'}><span className={'c2'}>Voc&ecirc; pode impedir a configura&ccedil;&atilde;o de cookies ajustando as configura&ccedil;&otilde;es do seu navegador. Contudo, voc&ecirc; deve estar ciente de que a desativa&ccedil;&atilde;o de cookies afetar&aacute; a funcionalidade deste e de muitos outros s&iacute;tios que voc&ecirc; visita. A desativa&ccedil;&atilde;o de cookies poder&aacute; resultar na desativa&ccedil;&atilde;o de determinadas funcionalidades e recursos deste s&iacute;tio. Portanto, n&atilde;o nos responsabilizamos pela dificuldade ou pelo impedimento de uso de algum servi&ccedil;o oferecido no caso de desativa&ccedil;&atilde;o dos cookies pelo usu&aacute;rio.</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>2.4. Cookies que definimos</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>2.4.1. Cookies relacionados &agrave; conta</span></p>
                <p className={'c3'}><span className={'c2'}>Se voc&ecirc; criar uma conta conosco, usaremos cookies para o gerenciamento do processo de inscri&ccedil;&atilde;o e administra&ccedil;&atilde;o geral. Esses cookies geralmente ser&atilde;o exclu&iacute;dos quando voc&ecirc; sair do sistema, por&eacute;m, em alguns casos, eles poder&atilde;o permanecer posteriormente para lembrar as prefer&ecirc;ncias do seu site ao sair.</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>2.4.2.Cookies relacionados ao login</span></p>
                <p className={'c3'}><span className={'c2'}>Utilizamos cookies quando voc&ecirc; est&aacute; logado, para que possamos lembrar dessa a&ccedil;&atilde;o. Isso evita que voc&ecirc; precise fazer login sempre que visitar uma nova p&aacute;gina. Esses cookies s&atilde;o normalmente removidos ou limpos quando voc&ecirc; efetua logout para garantir que voc&ecirc; possa acessar apenas a recursos e &aacute;reas restritas ao efetuar login.</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>2.4.3. Cookies relacionados a formul&aacute;rios</span></p>
                <p className={'c3'}><span className={'c2'}>Quando voc&ecirc; envia dados por meio de um formul&aacute;rio, como os encontrados nas p&aacute;ginas de contato, informa&ccedil;&otilde;es sobre a fazendo, talh&otilde;es e lotes, ou nos formul&aacute;rios de informa&ccedil;&otilde;es para disponibilizar algum lote para venda atrav&eacute;s da plataforma </span><span className={'c5'}>&ldquo;Gerente Flowins&rdquo;</span><span className={'c2'}>, os cookies podem ser configurados para lembrar os detalhes do usu&aacute;rio para correspond&ecirc;ncia futura e para gerenciamento dos servi&ccedil;os oferecidos.</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>2.4.4. Cookies de prefer&ecirc;ncias do site</span></p>
                <p className={'c3'}><span className={'c2'}>Para proporcionar uma &oacute;tima experi&ecirc;ncia neste site, fornecemos a funcionalidade para definir suas prefer&ecirc;ncias de como esse site &eacute; executado quando voc&ecirc; o usa. Para lembrar suas prefer&ecirc;ncias, precisamos definir cookies para que essas informa&ccedil;&otilde;es possam ser chamadas sempre que voc&ecirc; interagir com uma p&aacute;gina que for afetada por suas prefer&ecirc;ncias.</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>2.4.5. Cookies de Terceiros</span></p>
                <p className={'c3'}><span className={'c2'}>Em alguns casos especiais, tamb&eacute;m usamos cookies fornecidos por terceiros confi&aacute;veis. A se&ccedil;&atilde;o a seguir detalha quais cookies de terceiros voc&ecirc; pode encontrar atrav&eacute;s deste site. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>Esperamos que as informa&ccedil;&otilde;es constantes de nossa Pol&iacute;tica de Privacidade e Pol&iacute;tica de Cookies tenham sido esclarecedoras de como seus dados ser&atilde;o coletados, tratados e utilizados em nossos servi&ccedil;os. Caso voc&ecirc; tenha mais d&uacute;vidas, pode entrar em contato conosco.</span></p>
                <p className={'c0'}><span className={'c4 c2'} /></p>
                <p className={'c3'}><span className={'c2'}>Esta pol&iacute;tica &eacute; efetiva a partir de julho de 2020.</span></p>
                <p className={'c0'}><span className={'c2 c4'} /></p>

            </WrapperSCP>

        </ModalCP>

    )
}

const WrapperSCP = styled.div`
    max-height: 55vh;
    overflow: overlay;
    .c0{
        font-weight: 600;
    }
`
const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`
