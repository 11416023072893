import React from 'react'
import Tooltip from 'antd/lib/tooltip'

export type TooltipPlacementTP = 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom'

interface ITooltipCPProps {
    children: JSX.Element
    text: string
    placement?: TooltipPlacementTP
}

/**
 * COMPONENTE tooltip
 *
 * @author rentofs
 * @author hjcostabr
 */
export function TooltipCP(props: ITooltipCPProps): JSX.Element | null {

    return (
        <Tooltip title={props.text} placement={props.placement}>
            {props.children}
        </Tooltip>
    )
}

