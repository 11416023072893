import { Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'

export function DocumentCardSkeletonICP(): JSX.Element {
    return (
        <ContentWrapperSCP>
            <MainInfoWrapperSCP>
                <Skeleton active={true} paragraph={{ rows: 1 }} />
            </MainInfoWrapperSCP>
            <ActionsWrapperSCP>
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
            </ActionsWrapperSCP>
        </ContentWrapperSCP>
    )
}

const ContentWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;
    box-shadow: -5px 10px 16px -4px rgba(0,0,0,0.21);
    &:before{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            border-radius: 0 5px 5px 0;
            background: ${props => props.theme.primaryColor}
        }
    @media(max-width: 786px){
        flex-direction: column;
    }
`
const MainInfoWrapperSCP = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;
   svg{
       font-size: 30px;
       color: ${props => props.theme.primaryColor}
   }
   @media(max-width: 786px){
        grid-template-columns: 1fr;     
        margin-bottom: 15px;
    svg{
       font-size: 50px;
    }
   }
`

const ActionsWrapperSCP = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 5px;
        align-items: center;
        margin-bottom: 15px;
        margin-right: 10px;
        @media(max-width: 768px){
            max-width: 120px;
            margin-top: 15px;
        }
`