import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import styled from 'styled-components'
import { FontAwsomeIconCP } from '../icon/FontAwsomeIconCP'
import { ProgressChartCP } from 'common/components/progress/ProgressChartCP'

interface IDuoWidgetCPProps {
    dataWidget: IDuoWidgetData[]
    hasChart?: boolean
    chartPercent?: number
    dataChart?: string
    chartValuePositive?: boolean
    gridArea?: string
}

export interface IDuoWidgetData {
    title?: string
    value: string
    spanValue?: string
    spanValuePositive?: boolean
}

export function DuoWidget(props: IDuoWidgetCPProps): JSX.Element {

    return (
        <WidgetWrapperSCP style={{ gridArea: props.gridArea ?? undefined }}>
            {
                !props.hasChart
                    ? (<WrapperDuoWidgetSCP>
                        {props.dataWidget.length > 0 &&
                        props.dataWidget.map((item: IDuoWidgetData, index: number) => (
                            <div key={index}>
                                <DuoWidgetItemSCP>
                                    <HeaderWrapperSCP>
                                        {item.title && (<TitleSCP>{item.title}</TitleSCP>)}
                                    </HeaderWrapperSCP>
                                    <GridValueWrapperSCP>
                                        <ValueSCP>{`${item.value}`}</ValueSCP>
                                        {item.spanValue && (
                                            <SpanValueSCP style={{ color: item.spanValuePositive ? '#40b971' : '#b94040' }}>
                                                {item.spanValuePositive
                                                    ? <FontAwsomeIconCP icon={faCaretUp} size={'1x'} />
                                                    : <FontAwsomeIconCP icon={faCaretDown} size={'1x'} />}
                                                {item.spanValue}
                                            </SpanValueSCP>)}
                                    </GridValueWrapperSCP>
                                </DuoWidgetItemSCP>
                                {index < (props.dataWidget.length - 1) && <ItemDividierSCP />}
                            </div>
                        ))}
                    </WrapperDuoWidgetSCP>)
                    :
                    (<WrapperDuoWidgetSCP hasChart={props.hasChart}>
                        <DuoWidgetItemSCP style={{ gridArea: 'first-row' }}>
                            <HeaderWrapperSCP>
                                {props.dataWidget[0].title && (<TitleSCP>{props.dataWidget[0].title}</TitleSCP>)}
                            </HeaderWrapperSCP>
                            <GridValueWrapperSCP>
                                <ValueSCP>{`${props.dataWidget[0].value}`}</ValueSCP>
                                {props.dataWidget[0].spanValue && (
                                    <SpanValueSCP style={{ color: props.dataWidget[0].spanValuePositive ? '#40b971' : '#b94040' }}>
                                        {props.dataWidget[0].spanValuePositive
                                            ? <FontAwsomeIconCP icon={faCaretUp} size={'1x'} />
                                            : <FontAwsomeIconCP icon={faCaretDown} size={'1x'} />}
                                        {props.dataWidget[0].spanValue}
                                    </SpanValueSCP>)}
                            </GridValueWrapperSCP>
                        </DuoWidgetItemSCP>
                        <ItemDividierSCP style={{ gridArea: 'dividier' }} />
                        <DuoWidgetItemSCP style={{ gridArea: 'second-row' }}>
                            <HeaderWrapperSCP>
                                {props.dataWidget[1].title && (<TitleSCP>{props.dataWidget[1].title}</TitleSCP>)}
                            </HeaderWrapperSCP>
                            <GridValueWrapperSCP>
                                <ValueSCP>{`${props.dataWidget[1].value}`}</ValueSCP>
                                {props.dataWidget[1].spanValue && (
                                    <SpanValueSCP style={{ color: props.dataWidget[1].spanValuePositive ? '#40b971' : '#b94040' }}>
                                        {props.dataWidget[1].spanValuePositive
                                            ? <FontAwsomeIconCP icon={faCaretUp} size={'1x'} />
                                            : <FontAwsomeIconCP icon={faCaretDown} size={'1x'} />}
                                        {props.dataWidget[1].spanValue}
                                    </SpanValueSCP>)}
                            </GridValueWrapperSCP>
                        </DuoWidgetItemSCP>
                        <ChartWrapperSCP style={{ gridArea: 'chart' }}>
                            {props.chartPercent &&
                                <ProgressChartCP
                                    percent={props.chartPercent}
                                />}
                        </ChartWrapperSCP>
                    </WrapperDuoWidgetSCP>)
            }
        </WidgetWrapperSCP>
    )

}

const WidgetWrapperSCP = styled.div`

`

const WrapperDuoWidgetSCP = styled.div<{ hasChart?: boolean }>`
    padding: 20px;
    background-color: ${props => props.theme.white};
    border-radius: 5px;
    display: ${props => (props.hasChart ? 'grid' : 'block')};
    grid-template-columns: 55% 45%;

    grid-template-areas:
        "first-row chart"
        "dividier chart"
        "second-row chart"
    ;
`

const ItemDividierSCP = styled.div`
    margin: 20px 5px;
    border-bottom: 1px solid ${props => props.theme.lightGray};
`

const DuoWidgetItemSCP = styled.div``

const GridValueWrapperSCP = styled.div`
    display: flex;
    place-content: space-between;
`

const SpanValueSCP = styled.div`
    display: flex;
    font-size: 12px;
    align-items: center;

    svg {
        margin-right: 5px;
    }
`

const ValueSCP = styled.span`
    font-size: 23px;
    font-weight: bolder;
`

const TitleSCP = styled.span`
    font-size: 12px;
    flex: 1;
`

const HeaderWrapperSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
`

const ChartWrapperSCP = styled.div`
    align-self: center;
`
