import React, { useEffect } from 'react'
import { UserMainMenuCP } from 'modules/app/components/user-main-menu/UserMainMenuCP'
import styled from 'styled-components'
import { VersionMenuItemICP } from 'modules/app/components/user-main-menu/inner/VersionMenuItemICP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { LogoCP } from 'common/components/logo/LogoCP'
import { UserProfileSettingsCP } from 'modules/app/components/user-profile-settings/UserProfileSettingsCP'
import { CollapseTriggerCP } from 'modules/app/components/main-menu-sider/inner/CollapseTriggerCP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { useLocation } from 'react-router-dom'

interface IResponsiveMainMenuSiderCPProps {
    show?: boolean
    visible: boolean
    onClose: () => void
    shouldCollapse?: (collapse: boolean) => void
}

export function ResponsiveMainMenuSiderCP(props: IResponsiveMainMenuSiderCPProps): JSX.Element {
    const routeLocation = useLocation()

    useEffect(() => {
        if (routeLocation.pathname)
            props.onClose()
    }, [routeLocation.pathname])

    return (
        <WrapperSCP style={{ display: `${props.show ? 'block' : 'none'}` }}>
            <DrawerCP
                title={<LogoCP height={25} onlySymbol={true} style={{ marginTop: '10px' }} />}
                placement={'left'}
                clearHeaderStyle={true}
                removeBodyPadding={true}
                visible={props.visible}
                onClose={props.onClose}
                responsiveWidth={'80%'}
                closeIcon={<CollapseTriggerCP isMobile={true} />}
            >
                <>
                    <MenuWrapperSCP>
                        <UserMainMenuCP isCollapsed={!props.visible} shouldCollapse={props.shouldCollapse} />
                    </MenuWrapperSCP>
                    <ProfileSettinsWrapperSCP>
                        <UserProfileSettingsCP isCollapsed={!props.visible} darkColor={true} />
                    </ProfileSettinsWrapperSCP>

                    <VersionMenuItemICP />
                </>

            </DrawerCP>

        </WrapperSCP>

    )
}
const MenuWrapperSCP = styled.div`
    height: calc(100% - 90px);
    border-bottom: 1px solid #e6e6e6;
`

const WrapperSCP = styled.div`

`
const ProfileSettinsWrapperSCP = styled.div`
    margin: 0 2em;
`