import React from 'react'

export function GrassIcon(props: any): JSX.Element {
    return (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
            <svg {...props} xmlns={'http://www.w3.org/2000/svg'} width={'25'} height={'25'} viewBox={'0 0 13.589 11.147'}>
                <g transform={'translate(0 -0.001)'}>
                    <path d={'M13.19,10.371h-1.6v-.8c0-3.2.6-4.968,1.124-5.884a.4.4,0,0,0-.56-.533c-1.359.87-2.98,2.689-2.98,6.417v.8h-1.2A15.226,15.226,0,0,0,3.068.113a.4.4,0,0,0-.6.51,19.565,19.565,0,0,1,2,6.172A11,11,0,0,1,4.8,9.575v.8h-.8v-.8c0-3.728-1.62-5.547-2.98-6.417a.4.4,0,0,0-.56.533A11.859,11.859,0,0,1,1.619,9.575v.8H.4a.4.4,0,0,0,0,.8H13.19a.4.4,0,1,0,0-.8Zm0,0'} transform={'translate(0 -0.02)'} />
                    <path d={'M281.961,3.8A16.446,16.446,0,0,1,283.266.6a.4.4,0,0,0-.6-.51,15.139,15.139,0,0,0-3.509,4.665,14.867,14.867,0,0,1,1.069,3.226A7.909,7.909,0,0,1,281.961,3.8Zm0,0'} transform={'translate(-271.751)'} />
                </g>
            </svg>
        </span>
    )
}