import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'

import { ListActions } from 'common/components/list/inner/ListActions'

import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'

import { BatchSaleFormValidator } from 'modules/batch/components/batch-sale-form-drawer/inner/BatchSaleFormValidator'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { ISaveBatchSaleReqDTO } from 'modules/batch/services/dtos/requests/ISaveBatchSaleReqDTO'
import { IBatchSaleResDTO } from 'modules/batch/services/dtos/responses/IBatchSaleResDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SaleFormICP } from 'modules/financial/components/invoice-form-drawer/inner/SaleFormICP'
import { BatchSearchSelectorCP } from 'modules/batch/components/batch-search-selector/BatchSearchSelectorCP'
import { MaskUtils } from 'common/utils/MaskUtils'

interface IBatchSaleFormDrawerCPProps {
    show: boolean
    onCancel: () => void
    onSuccess: () => void
    batchCode?: number
    saleCode?: number
}

export function BatchSaleFormDrawerCP(props: IBatchSaleFormDrawerCPProps): JSX.Element {
    const [formValidator, setFormValidator] = useState<BatchSaleFormValidator>(new BatchSaleFormValidator())
    const formStateManager = useFormStateManager(formValidator)
    const [batchCode, setBatchCode] = useState<number>()
    const [isPaid, setIsPaid] = useState<boolean>(false)

    const saveReq = useRequest<IBatchSaleResDTO>()
    useEffect(onRequestChange, [saveReq.awaiting])

    const findOneReq = useRequest<IBatchSaleResDTO>()
    useEffect(onFindOneRequestChange, [findOneReq.awaiting])

    useEffect(() => { init() }, [props.saleCode, props.show])

    useEffect(() => setBatchCode(props.batchCode), [props.batchCode])

    async function init(): Promise<void> {
        if (!!props.show && !!props.saleCode && !!batchCode)
            await findOneReq.runRequest(BatchRequests.getSale(batchCode, props.saleCode))
    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return
        if (!batchCode)
            return

        const dto: ISaveBatchSaleReqDTO = {
            date: DateUtils.getDateFormattedToApi(formValues.date),
            paymentDate: formValues.paymentDate ? DateUtils.getDateFormattedToApi(formValues.paymentDate) : undefined,
            isPaid,
            bags: +formValues.bags,
            buyer: formValues.buyer,
            unitValue: +MaskUtils.removeMask(`${formValues.unitValue}`),
            notes: formValues.notes
        }

        saveReq.runRequest(!props.saleCode ?
            BatchRequests.saveSale(batchCode, dto)
            : BatchRequests.updateSale(batchCode, props.saleCode, dto))
    }

    function onRequestChange(): void {

        if (saveReq.awaiting || !saveReq.tried)
            return

        if (!saveReq.success || !saveReq.returnData) {
            if (saveReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveReq.returnData, saveReq.error)
            return RequestUtils.showDefaultErrorNotification(saveReq.error, 'Erro ao salvar venda!')
        }

        ListActions.mustUpdate()
        formStateManager.reset()
        props.onSuccess()
    }

    function onFindOneRequestChange(): void {

        if (findOneReq.awaiting || !findOneReq.tried)
            return

        if (!findOneReq.success || !findOneReq.returnData) {
            if (findOneReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', findOneReq.returnData, findOneReq.error)
            return RequestUtils.showDefaultErrorNotification(findOneReq.error, 'Erro ao buscar dados da venda!')
        }

        setFormValidator(new BatchSaleFormValidator(findOneReq.returnData))
    }

    return (
        <DrawerCP
            title={!props.saleCode ? `#${batchCode ?? '-'} - Nova venda` : `Editar venda #${props.saleCode}`}
            visible={props.show}
            width={'40%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFormSubmit}
                        wrapperStyle={{ width: '100%' }}
                        loading={saveReq.awaiting || findOneReq.awaiting}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {
                formStateManager.reset()
                props.onCancel()
            }}
        >
            <WrapperSCP>
                <BatchSearchSelectorCP
                    batchCode={batchCode}
                    setBatchCode={setBatchCode}
                    loading={saveReq.awaiting || findOneReq.awaiting}
                />

                <SaleFormICP
                    loading={saveReq.awaiting || findOneReq.awaiting}
                    formStateManager={formStateManager}
                    isPaid={isPaid}
                    markAsPaid={setIsPaid}
                />
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`