import React from 'react'
import { SiderCP } from '../../../../common/components/screen-layout/sider/SiderCP'
import { AuthScreenCoverCP } from '../auth-screen-cover/AuthScreenCoverCP'

export function SiderAuthCP(): JSX.Element {
    return (
        <SiderCP width={'60%'}>
            <AuthScreenCoverCP />
        </SiderCP>
    )
}
