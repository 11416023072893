import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import styled from 'styled-components'
import React, { CSSProperties } from 'react'

interface IFontAwsomeIconCPProps {
    icon: IconProp
    color?: string
    className?: string
    size?: SizeProp
    spin?: boolean
    onClick?: () => void
    style?: CSSProperties
    tooltip?: string
    loading?: boolean
}

/**
 * Componente WRAPPER de Icone do FontAwsome
 * @param {IFontAwsomeIconCPProps} props
 * @author kiara.vz
 */
export function FontAwsomeIconCP(props: IFontAwsomeIconCPProps): JSX.Element {

    return (
        <Tooltip title={props.tooltip}>
            <IconWrapperSCP onClick={props.onClick} style={props.style} className={props.className}>
                {
                    props.loading ?
                        <FontAwesomeIcon icon={faSpinner} color={props.color} spin={true} size={props.size ? props.size : '1x'} />
                        :
                        <FontAwesomeIcon
                            icon={props.icon}
                            color={props.color}
                            size={props.size}
                            spin={props.spin}
                            style={{ cursor: `${props.onClick ? 'pointer' : 'default'}` }}
                        />
                }

            </IconWrapperSCP>
        </Tooltip>

    )
}

const IconWrapperSCP = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
`
