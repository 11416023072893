import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { IReduxState } from 'common/redux/interfaces/IReduxState'

/**
 * UTIL
 * Abstrai o controle de selecao de itens de uma lista via REDUX
 */
export const ListUtils = {

    checkIfIsSelected(code: number): boolean {
        const state: IReduxState = ReduxHelper.getInstance().getStore().getState()
        return state.listState.selectedItems && state.listState.selectedItems.includes(code)
    },

}