import { Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { AvatarCP } from 'common/components/avatar/AvatarCP'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'
import { UploadButtonICP } from 'common/components/profile-image-uploader/inner/UploadButtonICP'
import { TextCP } from 'common/components/text/TextCP'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface ICompanyLogoUploaderCPProps {
    onChange: (info: any) => void
    beforeUpload: (file: RcFile, fileList: RcFile[]) => boolean | Promise<any>
    customRequest?: (option: object) => void
    imageUrl?: string
    fileTypes?: string
    loading: boolean
    instructions?: string
    multiple?: boolean
    showUploadList?: boolean
    disabled?: boolean
    style?: CSSProperties
    size?: number
    label: string

}

export function CompanyLogoUploaderCP(props: ICompanyLogoUploaderCPProps): JSX.Element {

    return (
        <FileUploaderWrapperSCP>
            <TextCP
                text={props.label}
            />
            <Upload
                beforeUpload={props.beforeUpload}
                customRequest={props.customRequest}
                disabled={props.disabled}
                onChange={props.onChange}
                accept={props.fileTypes}
                multiple={props.multiple}
                showUploadList={props.showUploadList}
            >
                {props.imageUrl ?
                    <img src={props.imageUrl} />
                    :
                    <UploadButtonICP style={{ width: '100%' }} disabled={props.disabled} loading={props.loading} />}
            </Upload>
            <InstructionsSCP>
                {props.instructions}
            </InstructionsSCP>
            <LoadingOverlayCP show={props.loading} />
        </FileUploaderWrapperSCP>
    )
}

const InstructionsSCP = styled(Upload)`
.ant-upload-select-text{
    span{
        font-size: 12px;
    }
    margin-top: 10px;
}
`
const FileUploaderWrapperSCP = styled.div`
        cursor: pointer;
        justify-content: center;
        display: flex;
        flex-direction: column;
        span{
            text-align: center;
        }
        margin-top: 2em;
        .ant-upload{
            width: 100%;
        }

        .ant-typography{
            font-weight: 500;
            font-size: 14px;
            color: #494949;
            margin-bottom: 1em;
        }
`