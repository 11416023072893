/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { IsNumberString } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsString } from 'common/validation/decorators/IsString'
import { IsNotEmpty } from '../../../../../common/validation/decorators/IsNotEmpty'

export class PropertyFormValidator extends FormModelValidator {

    @IsNotEmpty()
    @IsString()
    name: string

    // @IsNotEmpty()
    // @IsString()
    // stateRegistration: string

    @IsNotEmpty()
    @IsString()
    city: string

    // @IsNotEmpty()
    // @IsString()
    // region: string

    @IsNotEmpty()
    @IsNumberString()
    totalArea: string

    @IsNotEmpty()
    @IsNumberString()
    dedicatedArea: string

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
        if (dto) {
            this.totalArea = `${dto.totalArea}`
            this.dedicatedArea = `${dto.dedicatedArea}`
        }
    }
}