import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { ListHeaderICP } from 'common/components/list/ListHeaderICP'
import { FieldReportListItemICP } from 'modules/report/components/quality-report-by-field/inner/FieldReportListItemICP'

import { QualityReportDataTP } from 'modules/report/types/QualityReportDataTP'
import React from 'react'
import styled from 'styled-components'

interface IFieldQualityReportTableICPProps {
    reportData?: QualityReportDataTP[]
}

export function FieldQualityReportTableICP(props: IFieldQualityReportTableICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <ListHeaderICP
                gridColumns={'1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr '}
                labels={[
                    'Talhão:',
                    'Total de lotes:',
                    'Total de especiais:',
                    'Nota média:',
                    'Catação média:',
                    'Principais sabores:',
                    'Principais defeitos:'
                ]}
            />
            <ListCP<QualityReportDataTP>
                bordered={false}
                itemLayout={'vertical'}
                size={'large'}
                loadMoreData={() => { }} dataSource={props.reportData ?? []}
                hasStateControl={true}
                loading={false}
                locale={{
                    emptyText: <EmptyCP description={'Sem resultados para os fitros selecionados'} />
                }}
                renderItem={(item: QualityReportDataTP) => (
                    <FieldReportListItemICP key={`${item.fieldName}_${Math.random()}`} reportData={item} loading={false} />
                )}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: white;
    padding: 5px;
    border-radius: 8px;
  
`
