import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { ISavePersonResponsibleReqDTO } from 'modules/logbook/services/dtos/request/ISavePersonResponsibleReqDTO'
import { PersonSelectorCP } from 'modules/person/components/person-selector/PersonSelectorCP'
import { PaymentUnityEnum } from 'modules/logbook/enums/PaymentUnityEnum'
import { IPersonResponseDTO } from 'modules/person/services/dtos/responses/IPersonResponseDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PaymentDataFieldsICP } from 'modules/logbook/components/logbook-form/inner/PaymentDataFieldsICP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'

import { PersonResponsiblePaymentReferencesICP } from 'modules/logbook/components/logbook-form/inner/PersonResponsiblePaymentReferencesICP'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'

interface IPeopleResponsibleFormFieldsICPProps {
    personResponsible: ISavePersonResponsibleReqDTO
    onChangePersonResponsible: (personResponsible: ISavePersonResponsibleReqDTO) => void
    removeFromList: () => void
    loading?: boolean
    disableRemove: boolean
    personList: IPersonResponseDTO[]
}

export function PeopleResponsibleFormFieldsICP(props: IPeopleResponsibleFormFieldsICPProps): JSX.Element {
    const [valueToBePaid, setValueToBePaid] = useState<number>()
    const [selectedUnity, setSelectedUnity] = useState<PaymentUnityEnum>()
    const [showPaymentData, setShowPaymentData] = useState<boolean>(!!props.personResponsible.quantity)
    const [personResponsible, setPersonResponsible] = useState<ISavePersonResponsibleReqDTO>(props.personResponsible)
    const [selectedPerson, setSelectedPerson] = useState<IPersonResponseDTO>()
    const [hasInvalidFields, setHasInvalidFields] = useState<boolean>(false)

    useEffect(init, [props.personResponsible, props.personList])
    useEffect(onChangePersonResponsible, [personResponsible])
    useEffect(onChangeUnity, [selectedUnity])
    useEffect(onSelectPerson, [selectedPerson])

    function init(): void {
        setSelectedPerson(props.personList.find(pr => pr.code === props.personResponsible.personCode))
    }

    function onSelectPerson(): void {
        if (PermissionUtils.hasAccess(SystemModulesEnum.MANEUVER))
            setShowPaymentData(!!selectedPerson)

    }

    function onChangePersonResponsible(): void {
        if (!!personResponsible.personCode)
            setSelectedPerson(props.personList.find(pr => pr.code === personResponsible.personCode))

        setSelectedUnity(personResponsible.paymentUnity)
        props.onChangePersonResponsible(personResponsible)
    }

    function onChangeUnity(): void {
        if (!selectedPerson || !selectedUnity)
            return

        if (!selectedPerson.valuesUnitPayment)
            return

        switch (selectedUnity) {
            case PaymentUnityEnum.DAYS:
                return setValueToBePaid(selectedPerson.valuesUnitPayment.days)
            case PaymentUnityEnum.HOURS:
                return setValueToBePaid(selectedPerson.valuesUnitPayment.hours)
            case PaymentUnityEnum.LITERS:
                return setValueToBePaid(selectedPerson.valuesUnitPayment.liters)
            case PaymentUnityEnum.TREES:
                return setValueToBePaid(selectedPerson.valuesUnitPayment.trees)
            case PaymentUnityEnum.HECTARES:
                return setValueToBePaid(selectedPerson.valuesUnitPayment.hectares)
            default:
                return
        }
    }

    return (
        <WrapperSCP>
            <GridRowSCP>
                <PersonSelectorCP
                    label={'Responsável'}
                    value={props.personResponsible.personCode}
                    onSelect={(personCode) => setPersonResponsible({ ...props.personResponsible, personCode: +personCode })}
                    required={true}
                    personList={props.personList}
                    loading={props.loading}
                />
                <ActionsSCP>
                    {
                        PermissionUtils.hasAccess(SystemModulesEnum.MANEUVER) &&
                        <PopOverCP
                            placement={'right'}
                            trigger={'click'}
                            content={
                                <WrapperSCP>
                                    {
                                        !!selectedPerson ?
                                            <PersonResponsiblePaymentReferencesICP paymentReferences={selectedPerson.valuesUnitPayment} />
                                            : <span>Selecione um responsável</span>
                                    }
                                </WrapperSCP>
                            }
                        >
                            <FontAwsomeIconCP icon={faInfoCircle} size={'2x'} style={{ marginBottom: '13px' }} />
                        </PopOverCP>
                    }
                    {
                        PermissionUtils.hasAccess(SystemModulesEnum.MANEUVER) &&
                        <ButtonCP
                            type={'primary'}
                            onClick={() => setShowPaymentData(!showPaymentData)}

                        >
                            {!showPaymentData ? 'Cadastrar' : 'Esconder'}
                            {' '}
                            dados do pagamento *
                        </ButtonCP>
                    }

                    <ButtonCP
                        type={'ghost'}
                        icon={<FontAwesomeIcon icon={faTimes} />}
                        onClick={props.removeFromList}
                        disabled={props.disableRemove}
                    />
                </ActionsSCP>

            </GridRowSCP>

            <PaymentFieldsSCP>
                {
                    showPaymentData &&
                    PermissionUtils.hasAccess(SystemModulesEnum.MANEUVER) &&
                    <PaymentDataFieldsICP
                        personResponsible={personResponsible}
                        setPersonResponsible={setPersonResponsible}
                        loading={props.loading}
                        defaultValue={valueToBePaid}
                        setHasInvalidFields={setHasInvalidFields}
                    />
                }
                {
                    hasInvalidFields && PermissionUtils.hasAccess(SystemModulesEnum.MANEUVER) &&
                    <ValidationSCP>
                        Preencha os dados de pagamento deste responsável
                    </ValidationSCP>
                }
            </PaymentFieldsSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
  column-gap: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.lightGray};

  .ant-btn {
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    display: block;
    padding-bottom: 10px;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    margin-bottom: 10px;
  }
`
const GridRowSCP = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1.2fr 1fr;

  @media (max-width: 768px) {
    display: block;
  }
`
const ActionsSCP = styled.div`
  display: grid;
  grid-template-columns: .5fr 1fr .1fr;
  align-items: end;
  column-gap: 10px;

  .ant-btn {
    margin-bottom: 5px;
  }

  svg {
    color: ${props => props.theme.primaryColor};
    fill: ${props => props.theme.primaryColor};
  }

  @media (max-width: 768px) {

    .ant-btn {
      width: 100%;
    }
  }
`

const ValidationSCP = styled.div`
  font-size: 11px;
  color: ${props => props.theme.errorColor};
  font-size: 11px;
  position: absolute;
  top: -6.5em;
  right: 2em;
`

const PaymentFieldsSCP = styled.div`
  position: relative;
`