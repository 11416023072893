/* eslint-disable @typescript-eslint/no-floating-promises */

import { Col, Skeleton } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'

import { ProfileImageUploaderCP } from 'common/components/profile-image-uploader/ProfileImageUploaderCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaskUtils } from 'common/utils/MaskUtils'
import { ISavePersonRequestDTO } from 'modules/person/services/dtos/requests/ISavePersonRequestDTO'
import { ISearchPersonRequestDTO } from 'modules/person/services/dtos/requests/ISearchPersonRequestDTO'
import { IPersonResponseDTO } from 'modules/person/services/dtos/responses/IPersonResponseDTO'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { WeekdayEnum } from 'common/enums/WeekdayEnum'
import { IWorktimeRequestDTO } from 'modules/person/services/dtos/requests/IWorktimeRequestDTO'
import { IPersonValuesUnitPayment } from 'modules/person/interfaces/IPersonValuesUnitPayment'
import { ValuesPaymentUnitFormFields } from 'modules/person/components/person-drawer/inner/ValuesPaymentUnitFormFieldsCP'

import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { PersonFormValidator } from './inner/PersonFormValidator'
import { ListActions } from 'common/components/list/inner/ListActions'

import { DividerCP } from 'common/components/divider/DividerCP'
import { UserPermissionsEnum } from 'common/enums/UserPermissionsEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { LoggedUserDataTP } from 'common/redux/ReduxTypes'
import _ from 'lodash'
import { useSelector } from 'react-redux'
const MAX_FILE_SIZE = 5

const DEFAULT: IWorktimeRequestDTO[] = Object.values(WeekdayEnum).map((weekday) => ({
    weekday,
}))

interface IPersonModalCPProps {
    personCode?: number
    setShowConfirmLogout?: (confirm: boolean) => void
    show: boolean
    onCancel: () => void
    onSuccess: () => void
}

interface IPersonStyledProps {
    isCompany?: boolean
}

export function PersonDrawerCP(props: IPersonModalCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const loggedUserData: LoggedUserDataTP | null = useSelector((state: IReduxState) =>
        _.get(state, 'loggedUser'),)

    const [formValidator, setFormValidator] = useState<PersonFormValidator>(
        new PersonFormValidator(),
    )
    const formStateManager = useFormStateManager(formValidator)

    const [loading, setLoading] = useState<boolean>(false)

    const [valuesUnitPayment, setValuesUnitPayment] = useState<IPersonValuesUnitPayment>({})

    const [imgUrl, setImgUrl] = useState<string>()
    const [imgFile, setImgFile] = useState<File>()

    const [isCompany, setIsCompany] = useState<boolean>(false)
    const [isUser, setIsUser] = useState<boolean>(false)
    const [permissions, setPermissions] = useState<UserPermissionsEnum[]>([])

    const request = useRequest<IPersonResponseDTO, ISavePersonRequestDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const getPersonRequest = useRequest<IPersonResponseDTO, ISearchPersonRequestDTO>()
    useEffect(onGetDetailsReqChange, [getPersonRequest.awaiting])

    const saveImageReq = useRequest<{ url: string }>()
    useEffect(onSavePictureRequestChange, [saveImageReq.awaiting])

    useEffect(init, [props.personCode, props.show])
    useEffect(() => setLoading(false), [formValidator])

    function init(): void {
        if (!props.show)
            return
        if (props.personCode) {
            setLoading(true)
            getPersonRequest.runRequest(PersonRequests.getPerson(props.personCode))
        } else {
            setFormValidator(new PersonFormValidator())
            setImgUrl(undefined)
            setImgFile(undefined)
        }
    }

    function onGetDetailsReqChange(): void {
        if (getPersonRequest.awaiting || !getPersonRequest.tried)
            return

        if (!getPersonRequest.success || !getPersonRequest.returnData) {
            if (getPersonRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getPersonRequest.returnData, getPersonRequest.error)
            return RequestUtils.showDefaultErrorNotification(
                getPersonRequest.error,
                'Erro ao buscar pessoa!',
            )
        }

        setFormValidator(new PersonFormValidator(getPersonRequest.returnData))
        setImgUrl(getPersonRequest.returnData.picture)
        setIsCompany(false)

        setIsUser(getPersonRequest.returnData.isUser ?? false)
        // if (!!getPersonRequest.returnData.user.permissions)
        //     setPermissions(getPersonRequest.returnData.user.permissions.modules)

        if (!_.isEmpty(getPersonRequest.returnData.valuesUnitPayment))
            setValuesUnitPayment(getPersonRequest.returnData.valuesUnitPayment!)

        setLoading(false)
    }

    function saveImage(userCode: number): void {
        if (!imgFile) {
            props.onSuccess()
            formStateManager.reset()
            return
        }

        getBase64(imgFile, (imageUrl) => {
            setImgUrl(imageUrl)
        })

        const formData = new FormData()
        formData.append('profilePicture', imgFile)

        saveImageReq.runRequest(PersonRequests.saveProfilePicture(userCode, formData))
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(
                request.error,
                'Erro ao salvar colaborador!',
            )
        }

        if (!!imgFile)
            saveImage(request.returnData.code)
        else {
            ListActions.mustUpdate()
            formStateManager.reset()
            NotificationHelper.success('Colaborador cadastrado com sucesso!')
            props.onSuccess()
            if (
                loggedUserData?.user_id === props.personCode &&
                // !_.isEqual(permissions, request.returnData.user?.permissions.modules) &&
                !!props.setShowConfirmLogout
            )
                return props.setShowConfirmLogout(true)
        }
    }

    function onSavePictureRequestChange(): void {
        if (saveImageReq.awaiting || !saveImageReq.tried)
            return

        if (!saveImageReq.success || !saveImageReq.returnData) {
            if (saveImageReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveImageReq.returnData, saveImageReq.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar foto!')
        }
        ListActions.mustUpdate()
        NotificationHelper.success('Colaborador cadastrado com sucesso!')
        formStateManager.reset()
        props.onSuccess()
        if (
            loggedUserData?.user_id === props.personCode &&
            // !_.isEqual(permissions, request.returnData?.user?.permissions.modules) &&
            !!props.setShowConfirmLogout
        )
            return props.setShowConfirmLogout(true)
    }

    async function onFormSubmit(): Promise<void> {
        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)
        if (!(await formStateManager.validate()) || !formValues)
            return

        const dto: ISavePersonRequestDTO = {
            // legal: isCompany,
            picture: formValues.picture,
            name: formValues.name,
            phone: formValues.phone,
            email: formValues.email,
            cpf: formValues.cpfCnpj,
            // gender: formValues.gender,
            occupation: formValues.occupation,
            valuesUnitPayment,
            // permissions,
        }

        request.runRequest(
            props.personCode
                ? PersonRequests.updatePerson(props.personCode, dto)
                : PersonRequests.savePerson(dto),
        )
    }

    function validateImage(file: File): boolean {
        const allowedTypes = ['image/jpeg', 'image/png']
        if (!allowedTypes.includes(file.type)) {
            NotificationHelper.error('Apenas JPEG e PNG são permitidos')
            return false
        }

        const isLt5M = file.size / 1024 / 1024 < MAX_FILE_SIZE
        if (!isLt5M) {
            NotificationHelper.error('Somente é permitido arquivos menores que 5 MB')
            return false
        }

        getBase64(file, (imageUrl) => {
            //   setUploadLoading(true)
            setImgUrl(imageUrl)
        })

        return false
    }

    function getBase64(img: File, callback: (result: any) => void): void {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
    }

    const changeTypePerson = () => setIsCompany(!isCompany)

    return (
        <DrawerCP
            title={
                props.personCode
                    ? `Editar membro da equipe #${props.personCode}`
                    : 'Novo membro da Equipe'
            }
            visible={props.show}
            width={'60%'}
            onClose={() => {
                formStateManager.reset()
                setImgFile(undefined)
                props.onCancel()
            }}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'primary'}
                        isSubmit={true}
                        wrapperStyle={{ width: '100%' }}
                        loading={request.awaiting || saveImageReq.awaiting}
                        onClick={onFormSubmit}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >
            <Skeleton active={true} loading={loading}>
                <MainInfoSCP>
                    <ProfileImageUploaderCP
                        imageUrl={imgUrl}
                        fileTypes={'.png, .jpg'}
                        multiple={false}
                        showUploadList={false}
                        loading={saveImageReq.awaiting}
                        onChange={(info) => setImgFile(info.file)}
                        beforeUpload={validateImage}
                    />
                    <MainFieldsSCP>
                        {/* <SwitchWrapperSCP className={'teeste'} onClick={changeTypePerson}>
                            <SwitchCP
                                isChecked={isCompany}
                                isTextInside={true}
                                textInsideChecked={'Pessoa jurídica'}
                                textInsideUnchecked={'Pessoa física'}
                                loading={request.awaiting}
                            />
                        </SwitchWrapperSCP> */}
                        <InputCP
                            label={'Nome'}
                            fieldName={'name'}
                            formStateManager={formStateManager}
                            required={true}
                            fontSize={screenSize.smd ? 'large' : 'normal'}
                        />
                        <InputCP
                            label={'Telefone'}
                            type={'tel'}
                            fieldName={'phone'}
                            mask={MaskUtils.applyPhoneMask}
                            formStateManager={formStateManager}
                            required={true}
                            fontSize={screenSize.smd ? 'large' : 'normal'}
                        />
                        <InputCP
                            label={'E-mail'}
                            fieldName={'email'}
                            formStateManager={formStateManager}
                            required={false}
                            fontSize={screenSize.smd ? 'large' : 'normal'}
                        />
                        {/* {!isCompany && (
                            <PersonGenderSelectorCP
                                label={'Gênero'}
                                formStateManager={formStateManager}
                            />
                        )} */}
                    </MainFieldsSCP>
                </MainInfoSCP>
                <OtherFieldsSCP isCompany={isCompany}>
                    {!isCompany && (
                        <InputCP
                            label={'Ocupação'}
                            fieldName={'occupation'}
                            formStateManager={formStateManager}
                            fontSize={screenSize.smd ? 'large' : 'normal'}
                        />
                    )}
                    <InputCP
                        label={'CPF'}
                        fieldName={'cpfCnpj'}
                        mask={MaskUtils.applyCpfMask}
                        formStateManager={formStateManager}
                        // onChange={(value: string) =>
                        //     formStateManager.changeFieldValue('legal', value.length > 11)}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                    />
                </OtherFieldsSCP>

                <DividerCP text={'Valores de referência para pagamento'} orientation={'left'} />
                <ValuesPaymentUnitFormFields
                    setValuesUnitPayment={setValuesUnitPayment}
                    valuesUnitPayment={valuesUnitPayment}
                />

                {/* {!!isUser && (
                    <PermissionFieldsICP
                        permissions={permissions}
                        setPermissions={setPermissions}
                    />
                )} */}
            </Skeleton>
        </DrawerCP>
    )
}

const WrapperColFlex = styled(Col)`
    display: flex;
    align-items: center;

    &.with-label {
        margin-top: 32px;
    }
`

const MainInfoSCP = styled.div`
    margin: 10px 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 10px;
    @media (max-width: 786px) {
        grid-template-columns: 1fr;
    }
`

const MainFieldsSCP = styled.div`
    margin: 10px 0;
    display: grid;
    grid-template-rows: 0.5fr 1fr 1fr 1fr;
    row-gap: 5px;
`

const OtherFieldsSCP = styled.div<IPersonStyledProps>`
    margin: 10px 0;

    ${(props) =>
        !props.isCompany &&
        css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
        `}
`

const ButtonWrapperSCP = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: center;
    .ant-btn {
        width: 100%;
        justify-content: center;
    }
`

const SwitchWrapperSCP = styled.div`
    justify-self: right;
`
