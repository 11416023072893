import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PopConfirmCP } from 'common/components/pop-confirm/PopConfirmCP'
import React from 'react'
import styled from 'styled-components'

interface ICardFooterICPProps {
    onDelete?: () => void
    deleteLoading?: boolean
    confirmLabel?: string
    children?: JSX.Element
}

/**
 * COMPONENTE
 * Footer de um item card padrao de uma lista
 * comporta principais actions do item
 */
export function CardFooterICP(props: ICardFooterICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <DeleteSCP>
                {
                    !!props.onDelete &&
                    <PopConfirmCP title={props.confirmLabel ?? 'Tem certeza que quer remover este item?'} onConfirm={props.onDelete}>
                        <FontAwsomeIconCP icon={faTrashAlt} loading={props.deleteLoading} />
                    </PopConfirmCP>
                }
            </DeleteSCP>

            <MainActionsWrapperSCP>
                {props.children}
            </MainActionsWrapperSCP>
        </WrapperSCP>

    )
}
const WrapperSCP = styled.div`
  display: grid;
  grid-template-columns: .1fr 1fr;
  justify-content: space-between;
  column-gap: 5px;
  margin: 10px;
  width: 100%;
`
const DeleteSCP = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
      font-size: 20px;
  }
`

const MainActionsWrapperSCP = styled.div`
  display: flex;
  margin: 10px;
  .ant-btn{
      margin: 0 5px;
  }
`