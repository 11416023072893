import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { ActivityStatusFilterICP } from 'modules/maneuver/components/maneuver-list-filters/inner/ActivityStatusFilterICP'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'

import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'

import { ISearchManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchManeuverRequestDTO'
import { ActivityStatusEnum } from 'modules/maneuver/enums/ActivityStatusEnum'
import { ManeuverFiltersDrawerICP } from './ManeuverFiltersDrawerICP'

interface IManeuverMobileFiltersICPProps {
    onChangeStatus: (status: ActivityStatusEnum) => void
    setFilters: (filters?: ISearchManeuverRequestDTO) => void
    filters?: ISearchManeuverRequestDTO
}

export function ManeuverMobileFiltersICP(props: IManeuverMobileFiltersICPProps): JSX.Element {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)

    return (
        <WrapperSCP>
            <ActivityStatusFilterICP
                onChangeStatus={props.onChangeStatus}
                status={props.filters?.status}
            />
            <ButtonCP
                onClick={() => setOpenDrawer(true)}
                type={'primary'}
                style={{ position: 'absolute', bottom: 20, right: 20, height: 60, width: 60, zIndex: 999, borderRadius: '100%' }}
                icon={<FontAwsomeIconCP icon={faFilter} color={'#fff'} />}
            />
            <ManeuverFiltersDrawerICP
                show={openDrawer}
                onClose={() => setOpenDrawer(false)}
                filters={props.filters}
                setFilters={props.setFilters}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 3px;
`

const FieldsWrapperSCP = styled.div`


`
const ButtonsWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;

`

const GridWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: .8fr 1.5fr 1.8fr 3fr;
    column-gap: 10px;
    margin-top: 10px;
`