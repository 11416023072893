/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { IsOptional } from 'class-validator'

import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'

import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsString } from 'common/validation/decorators/IsString'
import { StoragePlaceEnum } from 'modules/batch/enums/StoragePlaceEnum'
import { StorageTypeEnum } from 'modules/batch/enums/StorageTypeEnum'
import { ISaveStorageReqDTO } from 'modules/batch/services/dtos/requests/ISaveStorageReqDTO'

export class StorageFormValidator extends FormModelValidator {

    @IsOptional()
    code?: number

    @IsNotEmpty()
    type: StorageTypeEnum

    @IsOptional()
    @IsString()
    typeOther?: string

    @IsNotEmpty()
    place: StoragePlaceEnum

    @IsOptional()
    @IsString()
    placeName?: string

    @IsOptional()
    humidity?: number

    constructor(dto?: ISaveStorageReqDTO) {
        console.log(dto)
        super(dto)
        Object.assign(this, dto)
    }
}