import { Skeleton, Row } from 'antd'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

export function ReportSkeletonICP(): JSX.Element {
    const screenSize = useScreenSize()
    const columns = _.fill(Array(6), {})
    return (
        <>
            {
                !screenSize.smd &&
                <RowSCP>
                    <TextCP text={'Resultados:'} />
                </RowSCP>
            }
            <ItemsWrapperSCP>
                {columns.map((_item, _index) => (
                    <ItemSCP key={`col_${Math.random()}`}>
                        <Skeleton.Button active={true} size={'default'} shape={'square'} />
                        <Skeleton.Button active={true} size={'small'} shape={'square'} />
                    </ItemSCP>
                ))}
            </ItemsWrapperSCP>

        </>
    )
}

const RowSCP = styled(Row)`
    margin-bottom: 3px;
`
const ItemsWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 2px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media(max-width: 768px){
        grid-template-columns: 1fr 1fr;
        row-gap: 30px;
    }
`
const ItemSCP = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    justify-content: center;

`