import { TextCP } from 'common/components/text/TextCP'
import { FieldQualityReportTableICP } from 'modules/report/components/quality-report-by-field/inner/FieldQualityReportTableICP'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'
import { QualityReportDataTP } from 'modules/report/types/QualityReportDataTP'
import React from 'react'
import styled from 'styled-components'

interface IQualityReportByFieldCPProps {
    filters?: ISearchReportReqDTO
    reportData?: QualityReportDataTP[]
}

export function QualityReportByFieldCP(props: IQualityReportByFieldCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <HeaderSCP>
                <TextCP text={'Indicadores por talhão'} style={{ fontSize: '15px', fontWeight: 600 }} />

            </HeaderSCP>
            <FieldQualityReportTableICP reportData={props.reportData} />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

`
const HeaderSCP = styled.div`
    display: flex;
    justify-content: flex-start;
    
    margin: 15px 0;
`