import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { IISaveUserReqDTO } from 'modules/person/services/dtos/requests/ISaveUserReqDTO'
import { ISearchUserRequestDTO } from 'modules/user/services/dtos/requests/ISearchUserRequestDTO'
import { IChangeProfileRequestDTO } from 'modules/person/services/dtos/requests/IChangeProfileRequestDTO'
import { ISavePersonRequestDTO } from './dtos/requests/ISavePersonRequestDTO'
import { ISearchPersonRequestDTO } from './dtos/requests/ISearchPersonRequestDTO'
import { ISearchPaymentStatmentReqDTO } from 'modules/person/services/dtos/requests/ISearchPaymentStatmentReqDTO'
import { ISaveInvoiceReqDTO } from 'modules/financial/services/dtos/request/ISaveInvoiceReqDTO'

export class PersonRequests {
    private static readonly personApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/people`
    private constructor() { }

    static getPersonList = (dto: ISearchPersonRequestDTO): RequestConfigTP<ISearchPersonRequestDTO> => ({
        url: `${PersonRequests.personApiUrl}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getPerson = (code: number): RequestConfigTP<ISearchPersonRequestDTO> => ({
        url: `${PersonRequests.personApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static setAsUser = (code: number, dto: IISaveUserReqDTO): RequestConfigTP => ({
        url: `${PersonRequests.personApiUrl}/${code}/set-as-user`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static listNames = (dto: ISearchUserRequestDTO): RequestConfigTP<ISearchUserRequestDTO> => ({
        url: `${PersonRequests.personApiUrl}/names`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static savePerson = (dto: ISavePersonRequestDTO): RequestConfigTP<ISavePersonRequestDTO> => ({
        url: `${PersonRequests.personApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updatePerson = (code: number, dto: ISavePersonRequestDTO): RequestConfigTP<ISavePersonRequestDTO> => ({
        url: `${PersonRequests.personApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static updatePersonProfile = (code: number, dto: IChangeProfileRequestDTO): RequestConfigTP<IChangeProfileRequestDTO> => ({
        url: `${PersonRequests.personApiUrl}/${code}/profile`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static saveProfilePicture = (code: number, data: FormData): RequestConfigTP => ({
        url: `${PersonRequests.personApiUrl}/${code}/picture`,
        method: HttpMethodEnum.PUT,
        params: data,
        headers: { 'content-type': 'multipart/form-data' }
    })

    static deletePerson = (code: number): RequestConfigTP => ({
        url: `${PersonRequests.personApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static getPersonPaymentStatement = (code: number, dto: ISearchPaymentStatmentReqDTO): RequestConfigTP => ({
        url: `${PersonRequests.personApiUrl}/${code}/payment-statement`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static saveStatementInvoice = (code: number, statementsCodes: number[], dto: ISaveInvoiceReqDTO): RequestConfigTP => ({
        url: `${PersonRequests.personApiUrl}/${code}/create-invoice`,
        method: HttpMethodEnum.POST,
        params: { ...dto, statementsCodes }
    })

    static consolidateStatements = (codes: number[]): RequestConfigTP => ({
        url: `${PersonRequests.personApiUrl}/consolidate-statements`,
        method: HttpMethodEnum.PUT,
        params: { codes }
    })

}
