import { momentPtBrLocale } from 'common/utils/moment-pt-br-locale'
import moment, { Moment } from 'moment-timezone'
import { DateFormatEnum } from '../enums/DateFormatEnum'

/**
 * UTILITARIOS
 * Reune metodos genericos uteis para manipulacao de datas.
 *
 * @author Giovanni
 * @author hjcostabr
 * @author renatofs
 */
export class DateUtils {

    static setTimeZone(date: Date | string, format: DateFormatEnum): string | undefined {
        return moment.tz(date, 'America/Fortaleza').format(format)
    }

    static formatDate = (date: string | Date, format?: DateFormatEnum | string): string => {
        return moment(date).format(format)
    }

    static getFormatted(date: string | Date, format: DateFormatEnum): string {
        moment.locale('pt_BR', momentPtBrLocale)
        return moment(date).format(format)
    }

    /** Transforma uma data, string, de um formato para outro. Ex.: 22/04/1987 para 1987-04-22. */
    static transformDateStrFormat(dateStr: string, originalFormat: DateFormatEnum, finalFormat: DateFormatEnum): string {
        return DateUtils.getFormatted(DateUtils.toDate(dateStr, originalFormat), finalFormat)
    }

    /**
     * Formata & retorna string contendo data no formato adequado para
     * enviar para a api.
     */
    static getDateFormattedToApi(date: string): string {
        return this.transformDateStrFormat(date, DateFormatEnum.BR_WITHOUT_TIME, DateFormatEnum.EUA_WITHOUT_TIME)
    }

    /**
     * Transforma uma string em uma data.
     * @param {string} dateStr Data em string
     * @param dateStrFormat Formato em que esta a string data
     * @returns {Date} Data.
     */
    static toDate(dateStr: string, dateStrFormat): Date {
        return moment(dateStr, dateStrFormat).toDate()
    }

    /**
     * Transforma uma string em uma data.
     * @param {string} dateStr Data em string
     * @param dateStrFormat Formato em que esta a string data
     * @returns {Date} Data.
     */
    static toMoment(dateStr: string, dateStrFormat: DateFormatEnum): Moment {
        return moment.tz(dateStr, dateStrFormat, 'America/Fortaleza')
    }

    static toDefaultMoment(dateStr: string, dateStrFormat: DateFormatEnum): string {
        return moment(dateStr).format(dateStrFormat)
    }
}
