import React from 'react'
import styled from 'styled-components'

/**
 * COMPONENTE
 * Imagem de fundo nas telas do modulo de AUTENTICACAO.
 *
 * @author StellaMCR
 * @author hjcostabr
 */
export function AuthScreenCoverCP(): JSX.Element {
    return (
        <WrapperSCP>
            <CoverSCP>
                <ColorSCP />
            </CoverSCP>

            <BarSCP />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`

const CoverSCP = styled.div`
    height: 100%;
    background-image: url('/assets/cover.png');
    background-position: center;
    background-size: cover;
    display: flex;
`

const ColorSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    opacity: 0.7;
    flex: 1;
`

const BarSCP = styled.div`
    height: 60%;
    width: 5px;
    background-color: ${props => props.theme.secondaryColor};
    position: absolute;
    right: -2px;
    top: 20%;
`

