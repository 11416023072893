import { TextCP } from 'common/components/text/TextCP'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { IRecipeFormulaItem } from 'modules/maneuver/interfaces/IRecipeFormulaItem'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'


interface IRecipeCardContentICPProps {
    formulas?: IRecipeFormulaItem[]
    description?: string
    mixTotalLiters?: number

}

/**
 * COMPONENTE
 * Conteudo central de um item da lista de instruções
 */
export function RecipeCardContentICP(props: IRecipeCardContentICPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }
    const [formulaVisible, setFormulaVisible] = useState<boolean>(false)

    return (
        <WrapperSCP>

            <RowSCP style={{ justifyContent: 'space-between' }}>
                <TextSCP>
                    <TextCP text={'Possui fórmula: '} />
                    <TextCP
                        text={`${props.formulas?.length ? 'Sim' : 'Não'}`}
                        style={valueStyle}
                    />
                </TextSCP>

                {
                    !!props.formulas?.length &&
                    <TextCP
                        text={`${formulaVisible ? 'Esconder' : 'Ver'} fórmula`}
                        onClick={() => setFormulaVisible(!formulaVisible)}
                        style={{ fontSize: '10px', cursor: 'pointer', textDecoration: 'underline' }}
                    />

                }
            </RowSCP>
            {
                formulaVisible && !!props.formulas?.length &&
                <FormulaWrapperSCP>
                    {props.mixTotalLiters ? <TextCP text={`Litragem total da mistura: ${props.mixTotalLiters} litros/ha`} />
                        : <TextCP text={'Litragem total da mistura não definida!'} />}
                    {
                        props.formulas.map(fr => (
                            <TextCP key={fr.code} text={`${fr.productName} - ${fr.quantity} ${ProductUtils.getUnityLabel(fr.isLiquid)}`} />
                        ))
                    }

                </FormulaWrapperSCP>
            }
            {
                props.description &&
                <ContentWppSCP>
                    <DescWrapperSCP>{`Descrição: ${props.description}`}</DescWrapperSCP>
                </ContentWppSCP>
            }

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 0 10px;   
    min-height: 95px;
`
const RowSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    margin-bottom: 5px;
    .ant-typography{
        font-size: 13px;
    }
`

const DescWrapperSCP = styled.div`
    margin: 8px 0;   
    column-gap: 8px;
    background: #f1f1f1;
    padding: 5px;
    border-radius: 5px;
    .ant-typography{
        font-size: 11px;
    }
`
const ContentWppSCP = styled.div`
    .ant-typography{
        font-size: 13px;
    }
`

const FormulaWrapperSCP = styled.div`
    padding-left: 10px;
    font-style: italic;
 .ant-typography{
        font-size: 12px;
    }
`

const TextSCP = styled.div`
    display: flex;
`