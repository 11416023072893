import React from 'react'

export function ScoreIconCP(props: any): JSX.Element {
	return (
		<span style={{ display: 'flex', justifyContent: 'center', width: props.width, alignItems: 'start', marginRight: '10px' }} >
			<svg
				{...props}
				xmlns={'http://www.w3.org/2000/svg'}
				x={'0px'}
				y={'0px'}
				viewBox={'0 0 100 100'}
				width={30}
				enableBackground={'new 0 0 100 100'}
			>
				<g fill={'#fff'}>
					<path d="M139.7,43.14c0.45-1.64,1.56-2.68,2.96-3.58c12.84-8.25,25.67-16.51,38.48-24.81c1.61-1.05,3.04-1.06,4.66-0.01
		c8.4,5.46,16.84,10.87,25.27,16.31c0.24,0.15,0.49,0.3,0.71,0.48c0.56,0.46,0.69,1.21,0.29,1.75c-0.44,0.59-1.02,0.69-1.67,0.38
		c-0.23-0.11-0.44-0.27-0.65-0.41c-8.42-5.43-16.85-10.85-25.26-16.31c-0.73-0.48-1.25-0.48-1.98,0
		c-12.93,8.37-25.89,16.71-38.83,25.06c-1.42,0.91-1.77,1.94-1.08,3.13c0.67,1.16,1.89,1.46,3.08,0.7c2.09-1.32,4.17-2.68,6.25-4.02
		c9.76-6.3,19.54-12.58,29.29-18.9c1.59-1.03,3.01-1.01,4.59,0.02c11.59,7.51,23.21,14.98,34.81,22.47
		c0.29,0.19,0.57,0.38,0.87,0.54c1.09,0.59,2.19,0.33,2.83-0.68c0.69-1.07,0.48-2.26-0.62-2.98c-1.99-1.32-4.01-2.6-6.02-3.9
		c-0.72-0.46-1.45-0.91-2.15-1.39c-0.74-0.5-0.93-1.19-0.53-1.82c0.41-0.66,1.17-0.8,1.94-0.31c2.69,1.72,5.37,3.44,8.04,5.18
		c2,1.31,2.74,3.32,2.06,5.47c-0.67,2.11-2.38,3.31-4.74,3.3c-0.14,0-0.27,0-0.46,0c0,13.49,0,26.93,0,40.51
		c0.41,0,0.83-0.01,1.24,0c2.38,0.1,4.22,2.05,4.19,4.42c-0.03,2.32-1.9,4.23-4.23,4.32c-0.31,0.01-0.63,0-0.94,0
		c-25.68,0-51.35,0-77.03,0c-3.12,0-4.18-0.71-5.36-3.59c0-0.57,0-1.14,0-1.71c0.35-0.64,0.62-1.34,1.07-1.9
		c0.84-1.06,2.01-1.56,3.37-1.55c0.31,0,0.62,0,1.05,0c0-1.2,0-2.32,0-3.45c0-0.34-0.02-0.69,0.02-1.02
		c0.09-0.74,0.62-1.2,1.32-1.18c0.71,0.02,1.19,0.5,1.21,1.27c0.03,1.11,0.01,2.22,0.01,3.33c0,0.31,0,0.62,0,1.04
		c0.38,0,0.68,0,0.98,0c2.71,0,5.42,0.02,8.12-0.02c0.48-0.01,1.02-0.15,1.43-0.39c6.35-3.87,13.27-5.93,20.64-6.58
		c1.04-0.09,2.09-0.15,3.23-0.23c-0.21-2.4-1.06-4.44-2.77-6.09c-0.18-0.17-0.58-0.18-0.87-0.15c-3.37,0.31-6.72,0.21-10.01-0.7
		c-5.48-1.52-9.77-4.57-12.28-9.79c-0.53-1.11-0.82-2.36-1.01-3.59c-0.23-1.54,0.34-2.3,1.85-2.68c3.06-0.78,6.12-0.56,9.14,0.2
		c7.28,1.83,12.08,6.35,14.31,13.52c0.3,0.97,0.89,1.58,1.64,2.33c0.03-0.33,0.06-0.55,0.06-0.76c0-2.25,0.02-4.5-0.01-6.75
		c0-0.33-0.12-0.78-0.35-0.97c-6.29-5.38-7.4-12.09-5.04-19.68c1.08-3.46,2.83-6.58,4.97-9.49c0.92-1.26,2.48-1.29,3.39-0.03
		c3.19,4.43,5.58,9.2,6.05,14.75c0.48,5.68-1.48,10.39-5.77,14.13c-0.47,0.41-0.7,0.8-0.69,1.44c0.04,2.17,0.01,4.33,0.02,6.5
		c0,0.27,0.02,0.55,0.03,0.82c0.09,0.04,0.18,0.08,0.26,0.13c0.42-0.73,0.99-1.4,1.23-2.18c2.52-8.34,9.3-13.79,17.98-14.26
		c1.83-0.1,3.7,0.06,5.51,0.35c1.63,0.26,2.17,1.2,1.96,2.86c-0.41,3.3-2.15,5.91-4.45,8.17c-3.56,3.5-7.95,5.26-12.86,5.69
		c-1.98,0.17-3.98,0.05-5.97,0.01c-0.5-0.01-0.84,0.11-1.17,0.5c-1.38,1.67-2.16,3.58-2.45,5.74c1.6,0.14,3.16,0.25,4.71,0.42
		c6.8,0.77,13.16,2.83,19.04,6.38c0.43,0.26,1,0.43,1.5,0.43c2.71,0.04,5.42,0.02,8.12,0.02c0.28,0,0.56,0,0.96,0
		c0-0.38,0-0.68,0-0.98c0-13.31,0-26.62,0.01-39.93c0-0.61-0.18-0.97-0.7-1.3c-11.36-7.3-22.7-14.61-34.04-21.95
		c-0.73-0.47-1.25-0.48-1.99,0c-11.31,7.32-22.63,14.62-33.96,21.9c-0.56,0.36-0.79,0.74-0.79,1.42c0.03,9.89,0.02,19.78,0.02,29.67
		c0,0.28,0.02,0.57-0.02,0.85c-0.08,0.68-0.47,1.09-1.17,1.14c-0.7,0.05-1.15-0.31-1.32-0.97c-0.09-0.32-0.06-0.68-0.06-1.02
		c0-9.41,0-18.81,0-28.22c0-0.34,0-0.68,0-1.07c-0.21-0.02-0.37-0.05-0.53-0.05c-2.46,0.01-4.1-1.21-4.8-3.56
		c-0.04-0.13-0.11-0.25-0.17-0.38C139.7,44.28,139.7,43.71,139.7,43.14z M183.43,95.47c12.91,0,25.82,0,38.73,0
		c0.31,0,0.63,0.02,0.94-0.02c0.92-0.12,1.61-0.91,1.59-1.82c-0.01-0.92-0.71-1.67-1.65-1.76c-0.31-0.03-0.63-0.02-0.94-0.02
		c-25.74,0-51.47,0-77.21,0c-0.31,0-0.63-0.01-0.94,0.02c-0.94,0.08-1.65,0.82-1.68,1.73c-0.03,0.94,0.68,1.75,1.65,1.86
		c0.28,0.03,0.57,0.01,0.85,0.01C157.66,95.47,170.54,95.47,183.43,95.47z M203.79,89.24c-4.63-2.58-13.26-5.05-22.96-4.53
		c-6.12,0.33-11.99,1.7-17.49,4.53C176.76,89.24,190.2,89.24,203.79,89.24z M208.89,62.79c-6.36,2.94-12.4,6.27-18.07,10.52
		C198.75,73.9,207,69.72,208.89,62.79z M182.19,41.83c-1.75,2.65-2.99,5.47-3.6,8.54c-0.9,4.58-0.14,8.78,3,12.38
		c0.15,0.17,0.34,0.31,0.59,0.55C182.19,56.04,182.19,48.93,182.19,41.83z M184.86,41.89c0,7.09,0,14.19,0,21.34
		c1.31-1.08,2.64-3.18,3.24-5.21C189.87,52.09,187.77,46.9,184.86,41.89z M175.86,73.34c-3.06-2.46-15.99-9.94-17.71-10.26
		C160.06,69.49,167.65,73.76,175.86,73.34z M160.87,61.21c5.35,3.14,10.73,6.3,15.99,9.39C175.63,65.7,166.96,60.55,160.87,61.21z
		 M190.1,70.64c5.07-3.7,10.34-6.67,16.01-9.42C199.51,60.91,192.21,65.21,190.1,70.64z"/>
				</g>
				<g>
					<path d="M50.83,7.02c0.14,0.1,0.28,0.19,0.41,0.29c0.67,0.49,0.96,1.14,0.7,1.94c-0.26,0.79-0.84,1.23-1.68,1.21
		c-0.8-0.02-1.35-0.45-1.59-1.21c-0.26-0.81,0.03-1.45,0.71-1.94c0.14-0.1,0.27-0.19,0.41-0.29C50.14,7.02,50.48,7.02,50.83,7.02z"
					/>
					<path d="M50.31,74.56c-0.46,0.8-0.84,1.47-1.23,2.13c-3.33,5.86-6.67,11.71-9.99,17.58c-0.44,0.77-0.95,1.41-1.93,1.32
		c-0.98-0.09-1.37-0.81-1.66-1.65c-1.21-3.45-2.44-6.89-3.66-10.34c-0.09-0.27-0.2-0.53-0.33-0.86c-1.83,0.38-3.63,0.74-5.43,1.11
		c-2,0.41-4.01,0.8-6,1.25c-0.84,0.19-1.56,0.12-2.1-0.59c-0.58-0.75-0.37-1.49,0.06-2.25c3.27-5.73,6.51-11.48,9.79-17.2
		c0.35-0.61,0.33-0.94-0.2-1.44C10.34,47.07,15.87,18.38,38.08,9.39c1.17-0.47,2.39-0.83,3.6-1.2c1.31-0.39,2.26,0.01,2.55,1.02
		c0.31,1.07-0.28,1.92-1.62,2.3c-8.54,2.44-14.89,7.59-18.75,15.57c-8.31,17.14,1.45,37.23,20.09,41.53
		c16.07,3.71,32.44-7.21,35.31-23.38c2.7-15.23-6.67-29.89-21.36-33.75c-1.21-0.32-1.78-1.18-1.51-2.22c0.25-1,1.21-1.44,2.44-1.12
		C71.4,11.37,81.26,22.5,82.84,35.32c1.37,11.1-1.96,20.58-9.94,28.41c-0.44,0.43-0.47,0.71-0.17,1.23
		c3.29,5.75,6.56,11.51,9.81,17.29c0.24,0.43,0.45,0.97,0.43,1.45c-0.05,1.08-1.08,1.69-2.29,1.44c-3.61-0.75-7.22-1.48-10.82-2.26
		c-0.62-0.13-0.85,0.02-1.05,0.6c-1.23,3.54-2.5,7.05-3.74,10.58c-0.29,0.83-0.72,1.46-1.66,1.53c-0.9,0.07-1.41-0.48-1.82-1.21
		c-3.57-6.29-7.14-12.57-10.72-18.85C50.72,75.26,50.56,74.99,50.31,74.56z M30.98,66.49c-2.73,4.81-5.44,9.59-8.2,14.46
		c0.38-0.04,0.63-0.05,0.88-0.11c2.76-0.57,5.52-1.15,8.28-1.72c1.35-0.28,2,0.08,2.46,1.36c0.99,2.76,1.96,5.53,2.95,8.29
		c0.09,0.26,0.21,0.51,0.37,0.9c3.27-5.75,6.46-11.36,9.69-17.04C41.31,72.03,35.9,70.01,30.98,66.49z M62.91,89.7
		c0.13-0.32,0.19-0.45,0.23-0.58c1.03-2.9,2.06-5.8,3.1-8.7c0.43-1.19,1.13-1.57,2.34-1.32c1.16,0.24,2.31,0.48,3.47,0.72
		c1.88,0.39,3.76,0.77,5.83,1.19c-2.8-4.93-5.5-9.7-8.24-14.52c-4.93,3.52-10.36,5.55-16.43,6.16
		C56.46,78.35,59.64,83.95,62.91,89.7z"/>
					<path d="M24.36,39.92C24.34,25.61,36,13.95,50.31,13.95c14.31,0,25.97,11.66,25.95,25.97c-0.01,14.27-11.63,25.91-25.91,25.93
		C36.05,65.88,24.37,54.23,24.36,39.92z M27.82,39.86c-0.02,12.4,10.08,22.52,22.47,22.53C62.66,62.4,72.76,52.34,72.8,39.98
		c0.04-12.4-10.03-22.54-22.43-22.57C37.96,17.37,27.84,27.46,27.82,39.86z"/>
					<path d="M39.43,52.84c0.48-2.9,0.94-5.8,1.45-8.69c0.09-0.52,0-0.85-0.37-1.21c-1.99-1.93-3.94-3.89-5.92-5.82
		c-0.6-0.58-1-1.21-0.72-2.07c0.28-0.86,0.96-1.12,1.79-1.24c2.71-0.38,5.41-0.82,8.12-1.2c0.57-0.08,0.89-0.34,1.15-0.86
		c1.19-2.46,2.44-4.89,3.65-7.35c0.37-0.75,0.85-1.3,1.76-1.29c0.87,0.01,1.33,0.55,1.69,1.27c1.21,2.45,2.46,4.89,3.65,7.35
		c0.27,0.55,0.61,0.81,1.22,0.89c2.71,0.38,5.41,0.81,8.12,1.19c0.8,0.11,1.45,0.4,1.73,1.21c0.29,0.85-0.09,1.49-0.69,2.07
		c-1.98,1.93-3.96,3.87-5.9,5.84c-0.25,0.26-0.45,0.75-0.4,1.09c0.42,2.82,0.9,5.63,1.39,8.44c0.13,0.75-0.02,1.39-0.61,1.86
		c-0.63,0.5-1.32,0.47-2.02,0.1c-2.5-1.31-5.01-2.59-7.5-3.92c-0.49-0.26-0.86-0.26-1.35,0c-2.49,1.33-5,2.62-7.51,3.9
		C40.74,55.13,39.35,54.3,39.43,52.84z M61.25,36.75c-1.81-0.28-3.62-0.63-5.44-0.8c-1.45-0.13-2.37-0.71-2.95-2.09
		c-0.72-1.71-1.64-3.33-2.56-5.16c-0.93,1.85-1.84,3.51-2.59,5.24c-0.56,1.3-1.44,1.86-2.82,2c-1.85,0.18-3.69,0.54-5.67,0.84
		c0.31,0.33,0.52,0.56,0.74,0.77c1.27,1.26,2.54,2.52,3.83,3.76c0.63,0.61,0.88,1.28,0.71,2.17c-0.39,2.06-0.69,4.13-1.05,6.37
		c1.91-0.99,3.69-1.85,5.41-2.83c1.02-0.57,1.9-0.58,2.91-0.01c1.72,0.97,3.5,1.84,5.42,2.83c-0.32-1.95-0.53-3.75-0.94-5.51
		c-0.34-1.48-0.04-2.57,1.13-3.6C58.74,39.53,59.94,38.12,61.25,36.75z"/>
				</g>
			</svg>
		</span>
	)
}

