import { faClipboard, faCloudUploadAlt, faFile, faImage, faPaperclip, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spin, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { UploadFile, UploadListType } from 'antd/lib/upload/interface'
import { NotificationHelper } from 'common/NotificationHelper'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import React from 'react'
import styled from 'styled-components'
import { FontAwsomeIconCP } from '../icon/FontAwsomeIconCP'

const { Dragger } = Upload

const MAX_FILE_SIZE = 50

interface IFileUploadCPProps {
    disabled?: boolean
    uploadFiles: (option: object) => void
    fileTypes?: Array<{ mime: string, extension: string }>
    loading: boolean
    loaddingMsg?: string
    multiple: boolean
    data?: any
    icon?: IconDefinition
    showHint?: boolean
    showUploadList?: boolean
}

/**
 * Componente responsável por fazer o upload de arquivos para o sitema.
 * Utiliza handlers customizados para fazer este upload.
 *
 * @todo: Verificar forma de tipar parametro da prop 'uploadFiles'
 *
 * @author renatofs
 */
export function FileUploadCP({ showHint = true, ...props }: React.PropsWithChildren<IFileUploadCPProps>): JSX.Element {

    /** Validacoes antes de realizar o upload. */
    function validateFile(file: RcFile): boolean {

        // Valida se há algum arquivo
        if (!file) {
            NotificationHelper.error('Nenhum arquivo foi encontrado')
            return false
        }

        // Verifica se arquivo possui no maximo 50mb
        if (file.size / 1024 / 1024 > MAX_FILE_SIZE) {
            NotificationHelper.error('Somente é permitido arquivos menores que 50 MB')
            return false
        }

        return true
    }

    function renderIcon(file: UploadFile<any>, listType?: UploadListType): JSX.Element {
        const type: string = (file.type ?? '').split('/')[0]

        switch (type) {
            case 'image':
                return <FontAwsomeIconCP icon={faImage} />
            case 'application':
                return <FontAwsomeIconCP icon={faFile} />

            default:
                return <FontAwsomeIconCP icon={faPaperclip} />
        }
    }

    return (
        <DraggerSCP>
            <Spin
                spinning={props.loading}
                tip={props.loaddingMsg ? props.loaddingMsg : 'Fazendo upload ...'}
            >
                <Dragger
                    beforeUpload={validateFile}
                    customRequest={props.uploadFiles}
                    accept={props.fileTypes?.map(t => t.mime).join(',')}
                    multiple={props.multiple}
                    showUploadList={props.showUploadList}
                    iconRender={renderIcon}
                    data={props.data}
                    disabled={props.disabled}
                >
                    {showHint &&
                        <DraggerHintWrapperSCP>
                            <FontAwesomeIcon icon={props.icon ?? faCloudUploadAlt} size={'3x'} />
                            <DraggerHintSCP> Clique ou arraste arquivos aqui para enviar ao sistema </DraggerHintSCP>
                            <DraggerHintSCP>
                                {' '}
                                Apenas arquivos
                                {' '}
                                <b>{props.fileTypes?.map(t => t.extension).join(', ')}</b>
                                {' '}
                                são aceitos
                                {' '}
                            </DraggerHintSCP>
                        </DraggerHintWrapperSCP>}
                    {props.children}
                </Dragger>
            </Spin>
        </DraggerSCP>
    )
}

const DraggerHintWrapperSCP = styled.div`
    padding: 16px 0;
    color: ${props => props.theme.primaryColor};
`

const DraggerSCP = styled.div`
    overflow: hidden;
    .ant-upload.ant-upload-drag .ant-upload {
        padding: 0
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover  {
            border-color: ${props => props.theme.primaryColor}
        }
    .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled){
        border-color: ${props => props.theme.primaryColor}
    }

    .ant-spin-blur {
        opacity: 0.2;
    }
    .ant-upload-list-text .ant-upload-list-item-name, .ant-upload-list-picture .ant-upload-list-item-name{
        text-align: left;
        flex: none;
        font-size: 14px;
        color: #494949;
    }
    .ant-upload-list-item-info .ant-upload-text-icon .anticon{
        font-size: 17px;
    }
`
const DraggerHintSCP = styled.p`
    
`
