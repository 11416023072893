import { PropertyListScreen } from 'modules/property/screens/PropertyListScreen'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PropertyDetailsScreen } from 'modules/property/screens/PropertyDetailsScreen'

PropertyRoutes.ROOT = '/propriedades'
PropertyRoutes.INFO_WITHOUT_PARAM = '/propriedades'
PropertyRoutes.INFO = '/propriedades/:code'

export function PropertyRoutes(): JSX.Element {
    return (

        <Switch>
            <Route path={PropertyRoutes.INFO} component={PropertyDetailsScreen} />
            <Route path={PropertyRoutes.ROOT} component={PropertyListScreen} />
        </Switch>

    )
}
