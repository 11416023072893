/* eslint-disable react/jsx-one-expression-per-line */
import { TextCP } from 'common/components/text/TextCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'

import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { BatchRoutes } from 'modules/batch/BatchRoutes'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { ISearchBatchResponseDTO } from 'modules/batch/services/dtos/responses/ISearchBatchResponseDTO'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface IBatchTreeResumeCardICPProps {
    batch: IBatchResponseDTO | ISearchBatchResponseDTO
    isMainBatch: boolean
}

export function BatchTreeResumeCardICP(props: IBatchTreeResumeCardICPProps): JSX.Element {
    const history = useHistory()
    return (
        <WrapperSCP>
            <GridSCP>
                <HighLightSCP>
                    #{props.batch.code === 0 ? '?' : props.batch.code}
                </HighLightSCP>

                <InfoWrapperSCP>
                    {
                        !!props.batch.fields ?
                            <CategoryTitleSCP>{props.batch.fields.map(f => f.name).join(', ')} ({props.batch.season})</CategoryTitleSCP>
                            : !!props.batch.fieldNames && <CategoryTitleSCP>{props.batch.fieldNames.map(f => f).join(', ')} ({props.batch.season})</CategoryTitleSCP>
                    }
                    <ContentInfoSCP>
                        <RowSCP>
                            <TextCP text={'Data:\u00A0'} style={{ fontWeight: '500 ' }} />
                            <TextCP text={`${DateUtils.getFormatted(props.batch.date, DateFormatEnum.BR_WITHOUT_TIME)}`} />
                        </RowSCP>
                        <RowSCP>
                            <TextCP text={'ID:\u00A0'} style={{ fontWeight: '500 ' }} />
                            <TextCP text={`${props.batch.farmId ?? '-'}`} />
                        </RowSCP>
                        <RowSCP>
                            <TextCP text={'Litragem:\u00A0'} style={{ fontWeight: '500 ' }} />
                            <TextCP text={`${props.batch.liters ?? '-'} L`} />
                        </RowSCP>
                        {
                            <RowSCP>
                                <TextCP text={'Sacas totais:\u00A0'} style={{ fontWeight: '500 ' }} />
                                <TextCP text={`${props.batch.bags ?? '-'} `} />
                            </RowSCP>
                        }
                        {
                            <RowSCP>
                                <TextCP text={'Sacas disponíveis:\u00A0'} style={{ fontWeight: '500 ' }} />
                                <TextCP text={`${props.batch.availableBags ?? '-'} `} />
                            </RowSCP>
                        }
                    </ContentInfoSCP>
                    {
                        !!props.batch.method ?
                            <TextCP text={`Método: ${BatchUtils.translateMethod(props.batch.method)}`} />
                            :
                            <TextCP text={`Origem: ${BatchUtils.translateOrigin(props.batch.origin)}`} />
                    }
                    {
                        !props.isMainBatch &&
                        <TextCP
                            text={'Ir para página do lote'}
                            onClick={() => history.push(`${BatchRoutes.ROOT}/processos/${props.batch.code}`)}
                            style={
                                {
                                    fontSize: '11px',
                                    marginTop: '10px',
                                    color: `${CostumizedThemeConfig.primaryColor}`,
                                    cursor: 'pointer',
                                    fontWeight: '600',
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                }
                            }
                        />
                    }
                </InfoWrapperSCP>
            </GridSCP>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    padding-top: 6px;
    @media(max-width: 768px){
        padding-bottom: 10px;
    }
`

const GridSCP = styled.div`
    display: grid;
    grid-template-columns: 60px 1fr;
    column-gap: 5px;
    margin: 10px 10px 0 10px;
    align-items: center;
    position: relative;
    width: 100%;
    @media(max-width: 768px){
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
`

const HighLightSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    svg{
        font-size: 20px;
    }
`
const InfoWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    .ant-typography{
    font-size: 12px;
    }
`

const CategoryTitleSCP = styled.div`
    font-size: 12px;
    color: ${props => props.theme.primaryColor};
    line-height: 15px;
    font-weight: 600;
`

const ContentInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    margin: 5px 0;
    width: 100%;
    .ant-typography{
    font-size: 12px;
    }
    @media(max-width: 768px){
    grid-template-columns: 1fr;
}
`

const RowSCP = styled.div`
display: flex;

`