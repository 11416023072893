import { TextCP } from 'common/components/text/TextCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { ILogbookItemResponseDTO } from 'modules/logbook/services/dtos/response/ILogbookItemResponseDTO'
import { DateUtils } from 'common/utils/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'
import { RecipeUtils } from 'modules/maneuver/utils/RecipeUtils'

interface ILogbookItemPreviewCPProps {
    logBookItem?: ILogbookItemResponseDTO
    recipeType: RecipeTypeEnum
}

export function LogbookItemPreviewCP(props: ILogbookItemPreviewCPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }
    const [implementName, setImplementName] = useState<string>('')
    useEffect(defineImplementName, [props.logBookItem])

    function defineImplementName(): void {
        if (!props.logBookItem)
            return
        if (props.logBookItem.equipment)
            setImplementName(`${props.logBookItem.equipment.brand}/${props.logBookItem.equipment.model}`)
        if (props.logBookItem.vehicle)
            setImplementName(`${props.logBookItem.vehicle.brand}/${props.logBookItem.vehicle.model}`)
    }

    if (!props.logBookItem) {
        return (
            <EmptyContentSCP>
                Ainda não há registros
            </EmptyContentSCP>
        )
    }
    return (
        <WrapperSCP>
            <HighLightSCP>
                {RecipeUtils.getRecipeTypeInitials(props.recipeType)}
            </HighLightSCP>
            <InfoWrapperSCP>
                <ResumeSCP>
                    <TextCP
                        text={`${DateUtils.getFormatted(props.logBookItem.date, DateFormatEnum.BR_WITHOUT_TIME)}`}
                        style={{ fontSize: '12px', fontWeight: 500 }}
                    />
                    <TextCP
                        text={` ${implementName}`}
                        style={{ fontSize: '12px' }}
                    />
                </ResumeSCP>
                <TextCP text={`${props.logBookItem.peopleResponsible.map(p => p.person.name).join(', ')}`} style={{ fontSize: '12px', color: `${CostumizedThemeConfig.gray}` }} />
            </InfoWrapperSCP>
        </WrapperSCP>
    )
}

const EmptyContentSCP = styled.div`
    background: ${props => props.theme.lightGray};
    min-height: 60px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const InfoWrapperSCP = styled.div`
    display: flex;
   flex-direction: column;

`

const ResumeSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography{
        white-space: pre-wrap;
    }
`

const WrapperSCP = styled.div`
    margin: 8px 0;
    display: grid;
    grid-template-columns: 40px 200px;
    column-gap: 8px;
    background: #f1f1f1;
    padding: 5px;
    border-radius: 5px;
`

const HighLightSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 30px;
    width: 30px;
`