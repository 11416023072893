import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { RecipeRequests } from 'modules/maneuver/services/RecipeRequests'
import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'
import React, { useEffect, useState } from 'react'
import { ISearchRecipeRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchRecipeRequestDTO'
import { CardCP } from 'common/components/card/CardCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { RecipeListCardICP } from 'modules/maneuver/components/recipe-list/inner/RecipeListCardICP'
import styled from 'styled-components'
import { PaginationConfig } from 'antd/lib/pagination'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { useSelector } from 'react-redux'
import { ListActions } from 'common/components/list/inner/ListActions'
import _ from 'lodash'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RecipeListFiltersCP } from '../recipe-list-filters/RecipeListFiltersCP'

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchRecipeRequestDTO = {
    title: undefined,
    code: undefined,
    type: undefined,
    archived: false,
    page: DEFAULT_PAGINATION.current,
    itemsPerPage: DEFAULT_PAGINATION.pageSize
}

export function RecipeListCP(): JSX.Element {
    const [recipeList, setRecipeList] = useState<IRecipeResponseDTO[]>([])
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const currentFilters: ISearchRecipeRequestDTO | null = useSelector((state: IReduxState) => _.get(state, 'searchState.filter'))
    const [pagination, setPagination] = useState<PaginationConfig>()
    const [total, setTotal] = useState<number>(0)

    const type: FilterTypesEnum = useSelector((state: IReduxState) => _.get(state, 'searchState.type'))

    const request = useRequest<IGenericListResponseDTO<IRecipeResponseDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(getRecipeList, [currentFilters])
    useEffect(onItemChange, [updateStatus])
    useEffect(loadPersistedData, [])

    function loadPersistedData(): void {

        if (type !== FilterTypesEnum.RECIPE)
            return SearchActions.setFilter<ISearchRecipeRequestDTO>(DEFAULT_FILTERS, FilterTypesEnum.RECIPE)

        if (!currentFilters) {
            SearchActions.setFilter<ISearchRecipeRequestDTO>({
                ...DEFAULT_FILTERS,
                page: 1
            }, FilterTypesEnum.RECIPE)
        } else {
            SearchActions.setFilter<ISearchRecipeRequestDTO>({
                ...currentFilters,
                page: 1
            }, FilterTypesEnum.RECIPE)
        }
    }
    function getRecipeList(): void {
        if (!!currentFilters && type === FilterTypesEnum.RECIPE)
            request.runRequest(RecipeRequests.getRecipeList(currentFilters))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE) {
            setRecipeList([])
            SearchActions.setFilter<ISearchRecipeRequestDTO>({
                ...DEFAULT_FILTERS,
                page: 1
            }, FilterTypesEnum.RECIPE)
        }
    }

    function onChangeFilter(filter?: ISearchRecipeRequestDTO): void {
        setRecipeList([])
        SearchActions.setFilter({
            ...filter,
            page: DEFAULT_PAGINATION.current,
            itemsPerPage: DEFAULT_PAGINATION.pageSize,
        }, FilterTypesEnum.RECIPE)
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried) {
            ListActions.isUpdating()
            return
        }

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar lista de atividades!')
        }
        setRecipeList(request.returnData.list)
        setTotal(request.returnData.total)
        setPagination({
            ...pagination,
            total: request.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                SearchActions.setFilter({ ...currentFilters, page, itemsPerPage: pageSize }, FilterTypesEnum.RECIPE)
            }
        })
        ListActions.updateIsDone()
    }

    return (
        <WrapperSCP>
            <RecipeListFiltersCP
                setFilters={onChangeFilter}
                filters={currentFilters ?? undefined}
            />
            <TotalSCP>
                <TextCP
                    text={`${total} registros encontrados`}

                />
                <TextCP
                    text={'Limpar filtros'}
                    onClick={() => {
                        setPagination(DEFAULT_PAGINATION)
                        SearchActions.setFilter({ ...DEFAULT_FILTERS }, FilterTypesEnum.RECIPE)
                    }}
                    style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
                />
            </TotalSCP>
            <ListWrapperSCP>
                <ListCP<IRecipeResponseDTO>
                    grid={{ gutter: 16, column: 3, md: 1, sm: 1, xs: 1, lg: 2, xl: 3, xxl: 3 }}
                    loading={request.awaiting}
                    bordered={false}
                    itemLayout={'vertical'}
                    size={'large'}
                    dataSource={recipeList}
                    locale={{
                        emptyText: <CardCP noSpacing={true} noSideSpacing={true}>
                            {' '}
                            <EmptyCP description={'Nenhum resultado'} />
                            {' '}
                        </CardCP>
                    }}
                    renderItem={(item: IRecipeResponseDTO) => (
                        <RecipeListCardICP recipe={item} loading={request.awaiting} />
                    )}
                    pagination={pagination}
                />
            </ListWrapperSCP>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
     border-radius: 10px;
    margin-bottom: 4em;
    max-height: 75vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    } 
`

const Empty = styled.div`
     border-radius: 10px;
    @media(max-width: 786px){
        margin-left: 0;    
    }
`

const ListWrapperSCP = styled.div`
    
`

const TotalSCP = styled.div`
    margin-left: 3px;
    display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      

   
    }
    @media(max-width: 768px){        
    margin-top: 17px;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`
