import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IBatchProcessAlbumByDateResDTO } from 'modules/batch/services/dtos/responses/IBatchProcessAlbumByDateResDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { CarouselCP } from 'common/components/carousel/CarouselCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { AllProcessPicturesDrawerCP } from 'modules/batch-landing-page/all-process-pictures-drawer/AllProcessPicturesDrawerCP'

interface IBatchProcessFullAlbumCPProps {
    batchCode: number
}

export function BatchProcessFullAlbumCP(props: IBatchProcessFullAlbumCPProps): JSX.Element {
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
    const [pictureList, setPictureList] = useState<string[]>([])
    const [albumsByDate, setAlbumsByDate] = useState<IBatchProcessAlbumByDateResDTO[]>([])
    const request = useRequest<IGenericListResponseDTO<IBatchProcessAlbumByDateResDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(getDetails, [props.batchCode])

    function getDetails(): void {
        if (props.batchCode)
            request.runRequest(BatchRequests.searchBatchPhotos(props.batchCode))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar fotos do lote')
        }

        const allPictures = _.flatten(request.returnData?.list.map(album => album.pictures))

        setPictureList(allPictures)
        setAlbumsByDate(request.returnData?.list ?? [])
    }

    if (!pictureList.length)
        return <></>

    return (
        <WrapperSCP>
            <CarouselCP
                autoPlay={true}
                source={pictureList}
                numberOfThumbs={4}
            />
            <ButtonWrapperSCP>
                <ButtonCP
                    type={'primary'}
                    onClick={() => setDrawerVisible(true)}
                    wrapperStyle={{ width: '100%' }}
                >
                    See all pictures
                </ButtonCP>
            </ButtonWrapperSCP>
            <AllProcessPicturesDrawerCP
                show={drawerVisible}
                onClose={() => setDrawerVisible(false)}
                albumsByDate={albumsByDate}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
  
`