import styled from 'styled-components'
import React, { useState } from 'react'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ISearchProductRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchProductRequestDTO'
import _ from 'lodash'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { ProductTypeSelectorCP } from 'modules/maneuver/components/product-type-selector/ProductTypeSelectorCP'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ProductFiltersDrawerCP } from 'modules/maneuver/components/product-filters-drawer/ProductFiltersDrawerCP'

interface IProductFilterFieldsICPProps {
    setFilters: (filters: ISearchProductRequestDTO) => void
    filters?: ISearchProductRequestDTO
}

export function ProductFilterFieldsICP(props: IProductFilterFieldsICPProps): JSX.Element {
    const type = !!props.filters && !!props.filters.types?.length ? props.filters.types[0] : undefined
    const screenSize = useScreenSize()
    const [filtersDrawerVisible, setFiltersDrawerVisible] = useState<boolean>(false)
    return (
        <WrapperSCP>
            <GridWrapperSCP>
                <InputCP
                    placeholder={'Nome'}
                    onChange={(name) => props.setFilters({ ...props.filters, searchString: name })}
                    value={props.filters?.searchString}
                />
                {
                    !screenSize.smd &&
                    <ProductTypeSelectorCP
                        value={type}
                        label={'Tipo'}
                        onSelect={(type) => props.setFilters({ ...props.filters, types: [type] })}
                    />
                }
                {
                    screenSize.smd &&
                    <ButtonCP
                        onClick={() => setFiltersDrawerVisible(true)}
                        type={'primary'}
                        style={{ width: 50, height: 50, zIndex: 1, borderRadius: '100%' }}
                        icon={<FontAwsomeIconCP icon={faFilter} color={'#fff'} />}
                    />
                }
                {
                    screenSize.smd &&
                    <ProductFiltersDrawerCP
                        show={filtersDrawerVisible}
                        onClose={() => setFiltersDrawerVisible(false)}
                        filters={props.filters}
                        setFilters={props.setFilters}
                    />
                }
            </GridWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 3px;
`

const GridWrapperSCP = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr .5fr ;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;

    label, .ant-typography div{
      display: none;
    }

    @media(max-width: 767px){
        grid-template-columns: 1fr .1fr;
        align-items: center;
         label, .ant-typography div{
                   display: block;
         }
    }
`

const StatusSCP = styled.div`
    @media(max-width: 767px){
       display: none;
    }

`