import styled from 'styled-components'
import React from 'react'
import { RadioGroupCP, RadioOptionProps } from 'common/components/radio-group/RadioGroupCP'
import { InvoiceCategoryEnum } from 'modules/financial/enums/InvoiceCategoryEnum'
import { FinancialUtils } from 'modules/financial/utils/FinancialUtils'

interface IInvoiceCategorySelectorCPProps {
    onChange: (category: InvoiceCategoryEnum) => void
    category?: InvoiceCategoryEnum
    disabled?: boolean
}

export function InvoiceCategorySelectorCP(props: IInvoiceCategorySelectorCPProps): JSX.Element {

    function getOptions(): RadioOptionProps[] {
        return Object.values(InvoiceCategoryEnum)
            .filter(cat => cat !== InvoiceCategoryEnum.PURCHASE && cat !== InvoiceCategoryEnum.MAINTENANCE && cat !== InvoiceCategoryEnum.TEAM && cat !== InvoiceCategoryEnum.PRODUCT)
            .map(category => ({
                value: category,
                content: FinancialUtils.translateCategory(category),
                disabled: props.disabled
            }))
    }

    return (
        <WrapperSCP>
            <RadioGroupCP
                type={'button'}
                options={getOptions()}
                selected={props.category}
                onChange={props.onChange}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .ant-radio-button-wrapper {
        border-color: #fff;
        background: ${props => props.theme.primaryColor};
        color: #fff;

        :hover {
            color: ${props => props.theme.primaryColor}!important;
            background: #fff;
            box-shadow: none;
        }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color:white!important;
        background:  ${props => props.theme.primaryColor};
        box-shadow: none;
        border: 1px ${props => props.theme.primaryColor} solid;
    }
    .ant-radio-button-wrapper{
        color:  ${props => props.theme.primaryColor}!important;
        background:  white;
        border: 1px ${props => props.theme.primaryColor} solid;
    }
`

