import React from 'react'

export function PropertyIconCP(props: any): JSX.Element {
	return (
		<span style={{ display: 'flex', justifyContent: 'center', width: props.width, alignItems: 'start', marginRight: '10px' }} >
			<svg
				{...props}
				xmlns={'http://www.w3.org/2000/svg'}
				x={'0px'}
				y={'0px'}
				viewBox={'0 0 100 100'}
				width={30}
				enableBackground={'new 0 0 100 100'}
			>
				<g fill={'#fff'}>
					<path d="M87,85.59c-0.46,1.15-1.32,1.56-2.55,1.56c-22.58-0.02-45.16-0.01-67.74-0.01c-1.8,0-2.47-0.66-2.47-2.46
		c0-3.57,0-7.15,0-10.72c0-0.13,0-0.26,0-0.39c0.04-0.84,0.49-1.35,1.18-1.33c0.66,0.02,1.11,0.5,1.13,1.3
		c0.02,1.31,0.01,2.62,0.01,3.93c0,0.59,0,1.17,0,1.81c4.39,0,8.69,0,13.05,0c0-5.34,0-10.65,0-16.05c-0.59,0-1.14,0.01-1.7,0
		c-1.45-0.02-2.31-0.87-2.33-2.3c-0.01-1-0.01-2.01,0-3.01c0.02-1.39,0.88-2.26,2.27-2.27c4.63-0.01,9.26-0.01,13.89,0
		c0.84,0,1.36,0.44,1.38,1.13c0.01,0.71-0.53,1.18-1.41,1.18c-4.29,0.01-8.59,0-12.88,0c-0.28,0-0.56,0-0.88,0c0,0.99,0,1.93,0,2.91
		c10.76,0,21.49,0,32.28,0c0-0.95,0-1.88,0-2.91c-0.3,0-0.57,0-0.84,0c-3.83,0-7.66,0-11.49,0c-0.26,0-0.52,0.02-0.77-0.03
		c-0.6-0.13-0.97-0.53-0.96-1.14c0.01-0.64,0.38-1.04,1.04-1.14c0.15-0.02,0.31-0.01,0.46-0.01c4.14,0,8.28,0,12.42,0
		c1.68,0,2.5,0.82,2.51,2.51c0.01,0.87,0.01,1.75,0,2.62c-0.02,1.59-0.85,2.42-2.43,2.44c-0.53,0.01-1.07,0-1.64,0
		c0,5.39,0,10.7,0,16.06c4.35,0,8.66,0,13.06,0c0-0.31,0-0.58,0-0.86c0-7.74,0.01-15.48-0.01-23.22c0-0.41-0.12-0.87-0.33-1.22
		c-2.45-3.92-4.91-7.84-7.41-11.73c-0.34-0.53-0.87-1.03-1.43-1.33c-5.91-3.17-11.84-6.3-17.77-9.41c-0.28-0.15-0.76-0.16-1.03-0.02
		c-5.94,3.1-11.87,6.23-17.78,9.39c-0.58,0.31-1.13,0.83-1.48,1.38c-2.51,3.89-4.97,7.8-7.41,11.73c-0.22,0.35-0.34,0.81-0.34,1.22
		c-0.02,3.99-0.01,7.97-0.02,11.96c0,0.23,0.01,0.46,0,0.69c-0.04,0.83-0.51,1.35-1.19,1.33c-0.65-0.02-1.08-0.52-1.12-1.32
		c-0.01-0.21,0-0.41,0-0.62c0-2.75,0-5.5,0-8.25c0-0.27,0-0.55,0-0.93c-1.15,0.81-2.26,1.17-3.53,0.84
		c-2.38-0.62-3.44-3.13-2.14-5.21c3.44-5.49,6.89-10.97,10.38-16.42c0.38-0.6,0.98-1.16,1.61-1.49c7.43-3.98,14.88-7.9,22.32-11.86
		c0.96-0.51,1.81-0.32,2.7,0.15c3.42,1.83,6.85,3.64,10.28,5.45c0.24,0.13,0.49,0.24,0.84,0.41c0.02-0.36,0.03-0.63,0.04-0.89
		c0.05-2.65-0.11-5.32,0.19-7.93c0.76-6.62,6.28-12.01,12.9-12.85c0.17-0.02,0.34-0.09,0.51-0.14c1.03,0,2.06,0,3.09,0
		c0.14,0.05,0.28,0.11,0.43,0.14c6.41,1.08,10.6,4.72,12.57,10.91c0.26,0.82,0.39,1.69,0.58,2.53C87,43,87,64.29,87,85.59z
		 M84.63,38.81c0-4.38,0-8.68,0-12.99c-8.55,0-17.04,0-25.58,0c0,1.62,0.02,3.19-0.01,4.75c-0.01,0.48,0.16,0.73,0.58,0.94
		c2.17,1.12,4.29,2.32,6.48,3.39c1.63,0.8,3.05,1.78,3.87,3.48c0.09,0.2,0.42,0.4,0.64,0.4C75.24,38.82,79.9,38.81,84.63,38.81z
		 M59.02,23.39c8.63,0,17.12,0,25.69,0c-0.17-4.86-2.16-8.67-6.31-11.12c-4.46-2.63-9.05-2.55-13.43,0.21
		C61.04,14.97,59.14,18.68,59.02,23.39z M11.73,56.97c0.51-0.47,1.02-0.78,1.31-1.23c3.1-4.85,6.17-9.72,9.22-14.6
		c0.63-1.01,1.42-1.78,2.48-2.34c5.94-3.11,11.87-6.22,17.77-9.4c1.13-0.61,2.04-0.6,3.15,0c5.79,3.11,11.6,6.19,17.43,9.23
		c1.25,0.65,2.2,1.52,2.94,2.72c3.01,4.84,6.07,9.66,9.12,14.48c0.57,0.9,1.41,1.14,2.15,0.64c0.65-0.44,0.68-1,0.09-1.93
		c-3.32-5.26-6.62-10.52-9.97-15.76c-0.31-0.49-0.82-0.93-1.34-1.21c-7.14-3.81-14.29-7.58-21.45-11.34
		c-0.28-0.15-0.76-0.16-1.04-0.02c-7.25,3.8-14.5,7.63-21.73,11.47c-0.37,0.2-0.75,0.49-0.97,0.84c-3.46,5.44-6.9,10.9-10.31,16.37
		c-0.18,0.29-0.25,0.85-0.09,1.12C10.71,56.37,11.19,56.58,11.73,56.97z M84.66,71.85c-3.61,0-7.14,0-10.72,0c0,4.33,0,8.61,0,12.91
		c3.59,0,7.15,0,10.72,0C84.66,80.45,84.66,76.19,84.66,71.85z M73.94,69.43c3.6,0,7.15,0,10.71,0c0-4.34,0-8.62,0-12.99
		c-1.31,0-2.59,0.02-3.87-0.01c-0.49-0.01-0.77,0.1-1,0.59c-0.48,1.04-1.34,1.65-2.46,1.91c-1.24,0.29-2.31-0.12-3.38-0.83
		C73.94,61.91,73.94,65.63,73.94,69.43z M84.63,54.13c0-4.39,0-8.67,0-12.93c-4.32,0-8.58,0-12.93,0c2.69,4.26,5.32,8.43,7.96,12.59
		c0.1,0.16,0.35,0.32,0.53,0.32C81.65,54.14,83.12,54.13,84.63,54.13z M42.35,74.02c-3.46-3.12-6.84-6.17-10.31-9.3
		c0,6.27,0,12.36,0,18.59C35.51,80.19,38.89,77.14,42.35,74.02z M45.81,74.02c3.47,3.13,6.84,6.16,10.28,9.26
		c0-6.22,0-12.32,0-18.53C52.62,67.88,49.25,70.92,45.81,74.02z M54.26,63.28c-6.86,0-13.54,0-20.37,0
		c3.46,3.12,6.81,6.14,10.19,9.18C47.47,69.4,50.81,66.4,54.26,63.28z M33.91,84.75c6.85,0,13.54,0,20.34,0
		c-3.45-3.11-6.81-6.13-10.18-9.17C40.67,78.66,37.34,81.66,33.91,84.75z M71.55,81.69c-4.38,0-8.69,0-13.01,0c0,1.06,0,2.07,0,3.08
		c4.36,0,8.67,0,13.01,0C71.55,83.74,71.55,82.75,71.55,81.69z M16.61,81.69c0,1.09,0,2.08,0,3.09c4.36,0,8.67,0,12.98,0
		c0-1.05,0-2.06,0-3.09C25.23,81.69,20.95,81.69,16.61,81.69z"/>
					<path d="M44.17,36.9c4.47,0.1,7.9,3.68,7.81,8.14c-0.09,4.38-3.72,7.8-8.16,7.69c-4.3-0.11-7.79-3.77-7.67-8.05
		C36.29,40.29,39.87,36.81,44.17,36.9z M49.67,44.85c0.01-3.13-2.51-5.65-5.61-5.64c-3.09,0.02-5.57,2.5-5.59,5.59
		c-0.02,3.11,2.49,5.63,5.62,5.62C47.18,50.43,49.67,47.94,49.67,44.85z"/>

				</g>
			</svg>
		</span>
	)
}

