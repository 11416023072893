import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IStructureResDTO } from 'modules/batch/services/dtos/responses/IStructureResDTO'
import { StructureRequests } from 'modules/structure/services/StructureRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IStructureSelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    label?: string
    value?: number
    required?: boolean
    onSelect?: (value: number) => void
    loading?: boolean
}

export function StructureSelectorCP<FModelTP extends FormModelValidator = any>(props: IStructureSelectorCPProps<FModelTP>): JSX.Element {
    const [structureOpt, setStructureOpt] = useState<SelectOptionTP[]>([])
    useEffect(getStructureList, [])

    const request = useRequest<IGenericListResponseDTO<IStructureResDTO>>(StructureRequests.search({}))
    useEffect(onRequestChange, [request.awaiting])

    function getStructureList(): void {
        request.runRequest()
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar lista de estrutura!')
        }

        const options = request.returnData.list.map(item => ({ label: item.name, value: item.code }))
        setStructureOpt(options)

    }
    return (
        <SelectCP
            options={structureOpt}
            label={props.label}
            loading={request.awaiting || props.loading}
            placeholder={'Estrutura'}
            required={props.required}
            fieldName={'structureCode'}
            formStateManager={props.formStateManager}
            onChange={props.onSelect}
            value={props.value}
            disabled={request.awaiting}
        />
    )
}

const WrapperSCP = styled.div`
`
