export enum ProductTypeEnum {
    FERTILIZER = 'FERTILIZER',
    HERBICIDE = 'HERBICIDE',
    FUNGICIDE = 'FUNGICIDE',
    BACTERICIDE = 'BACTERICIDE',
    INSECTICIDE = 'INSECTICIDE',
    FUEL = 'FUEL',
    SEED = 'SEED',
    PIECE = 'PIECE',

}