import React from 'react'

export function CalendarIconCP(props: any): JSX.Element {
	return (
		<span style={{ display: 'flex', justifyContent: 'center', width: props.width, alignItems: 'start', marginRight: '10px' }} >
			<svg
				{...props}
				xmlns={'http://www.w3.org/2000/svg'}
				x={'0px'}
				y={'0px'}
				viewBox={'0 0 100 100'}
				width={30}
				enableBackground={'new 0 0 100 100'}
			>
				<g fill={'#fff'}>
					<path d="M-51.1,87.71c-0.46,1.15-1.32,1.56-2.55,1.56c-22.58-0.02-45.16-0.01-67.74-0.01c-1.8,0-2.47-0.66-2.47-2.46
		c0-3.57,0-7.15,0-10.72c0-0.13,0-0.26,0-0.39c0.04-0.84,0.49-1.35,1.18-1.33c0.66,0.02,1.11,0.5,1.13,1.3
		c0.02,1.31,0.01,2.62,0.01,3.93c0,0.59,0,1.17,0,1.81c4.39,0,8.69,0,13.05,0c0-5.34,0-10.65,0-16.05c-0.59,0-1.14,0.01-1.7,0
		c-1.45-0.02-2.31-0.87-2.33-2.3c-0.01-1-0.01-2.01,0-3.01c0.02-1.39,0.88-2.26,2.27-2.27c4.63-0.01,9.26-0.01,13.89,0
		c0.84,0,1.36,0.44,1.38,1.13c0.01,0.71-0.53,1.18-1.41,1.18c-4.29,0.01-8.59,0-12.88,0c-0.28,0-0.56,0-0.88,0c0,0.99,0,1.93,0,2.91
		c10.76,0,21.49,0,32.28,0c0-0.95,0-1.88,0-2.91c-0.3,0-0.57,0-0.84,0c-3.83,0-7.66,0-11.49,0c-0.26,0-0.52,0.02-0.77-0.03
		c-0.6-0.13-0.97-0.53-0.96-1.14c0.01-0.64,0.38-1.04,1.04-1.14c0.15-0.02,0.31-0.01,0.46-0.01c4.14,0,8.28,0,12.42,0
		c1.68,0,2.5,0.82,2.51,2.51c0.01,0.87,0.01,1.75,0,2.62c-0.02,1.59-0.85,2.42-2.43,2.44c-0.53,0.01-1.07,0-1.64,0
		c0,5.39,0,10.7,0,16.06c4.35,0,8.66,0,13.06,0c0-0.31,0-0.58,0-0.86c0-7.74,0.01-15.48-0.01-23.22c0-0.41-0.12-0.87-0.33-1.22
		c-2.45-3.92-4.91-7.84-7.41-11.73c-0.34-0.53-0.87-1.03-1.43-1.33c-5.91-3.17-11.84-6.3-17.77-9.41c-0.28-0.15-0.76-0.16-1.03-0.02
		c-5.94,3.1-11.87,6.23-17.78,9.39c-0.58,0.31-1.13,0.83-1.48,1.38c-2.51,3.89-4.97,7.8-7.41,11.73c-0.22,0.35-0.34,0.81-0.34,1.22
		c-0.02,3.99-0.01,7.97-0.02,11.96c0,0.23,0.01,0.46,0,0.69c-0.04,0.83-0.51,1.35-1.19,1.33c-0.65-0.02-1.08-0.52-1.12-1.32
		c-0.01-0.21,0-0.41,0-0.62c0-2.75,0-5.5,0-8.25c0-0.27,0-0.55,0-0.93c-1.15,0.81-2.26,1.17-3.53,0.84
		c-2.38-0.62-3.44-3.13-2.14-5.21c3.44-5.49,6.89-10.97,10.38-16.42c0.38-0.6,0.98-1.16,1.61-1.49c7.43-3.98,14.88-7.9,22.32-11.86
		c0.96-0.51,1.81-0.32,2.7,0.15c3.42,1.83,6.85,3.64,10.28,5.45c0.24,0.13,0.49,0.24,0.84,0.41c0.02-0.36,0.03-0.63,0.04-0.89
		c0.05-2.65-0.11-5.32,0.19-7.93c0.76-6.62,6.28-12.01,12.9-12.85c0.17-0.02,0.34-0.09,0.51-0.14c1.03,0,2.06,0,3.09,0
		c0.14,0.05,0.28,0.11,0.43,0.14c6.41,1.08,10.6,4.72,12.57,10.91c0.26,0.82,0.39,1.69,0.58,2.53C-51.1,45.12-51.1,66.42-51.1,87.71
		z M-53.47,40.93c0-4.38,0-8.68,0-12.99c-8.55,0-17.04,0-25.58,0c0,1.62,0.02,3.19-0.01,4.75c-0.01,0.48,0.16,0.73,0.58,0.94
		c2.17,1.12,4.29,2.32,6.48,3.39c1.63,0.8,3.05,1.78,3.87,3.48c0.09,0.2,0.42,0.4,0.64,0.4C-62.85,40.94-58.2,40.93-53.47,40.93z
		 M-79.08,25.51c8.63,0,17.12,0,25.69,0c-0.17-4.86-2.16-8.67-6.31-11.12c-4.46-2.63-9.05-2.55-13.43,0.21
		C-77.06,17.09-78.96,20.81-79.08,25.51z M-126.37,59.09c0.51-0.47,1.02-0.78,1.31-1.23c3.1-4.85,6.17-9.72,9.22-14.6
		c0.63-1.01,1.42-1.78,2.48-2.34c5.94-3.11,11.87-6.22,17.77-9.4c1.13-0.61,2.04-0.6,3.15,0c5.79,3.11,11.6,6.19,17.43,9.23
		c1.25,0.65,2.2,1.52,2.94,2.72c3.01,4.84,6.07,9.66,9.12,14.48c0.57,0.9,1.41,1.14,2.15,0.64c0.65-0.44,0.68-1,0.09-1.93
		c-3.32-5.26-6.62-10.52-9.97-15.76c-0.31-0.49-0.82-0.93-1.34-1.21c-7.14-3.81-14.29-7.58-21.45-11.34
		c-0.28-0.15-0.76-0.16-1.04-0.02c-7.25,3.8-14.5,7.63-21.73,11.47c-0.37,0.2-0.75,0.49-0.97,0.84c-3.46,5.44-6.9,10.9-10.31,16.37
		c-0.18,0.29-0.25,0.85-0.09,1.12C-127.39,58.5-126.91,58.7-126.37,59.09z M-53.44,73.97c-3.61,0-7.14,0-10.72,0
		c0,4.33,0,8.61,0,12.91c3.59,0,7.15,0,10.72,0C-53.44,82.57-53.44,78.31-53.44,73.97z M-64.16,71.55c3.6,0,7.15,0,10.71,0
		c0-4.34,0-8.62,0-12.99c-1.31,0-2.59,0.02-3.87-0.01c-0.49-0.01-0.77,0.1-1,0.59c-0.48,1.04-1.34,1.65-2.46,1.91
		c-1.24,0.29-2.31-0.12-3.38-0.83C-64.16,64.04-64.16,67.75-64.16,71.55z M-53.47,56.25c0-4.39,0-8.67,0-12.93
		c-4.32,0-8.58,0-12.93,0c2.69,4.26,5.32,8.43,7.96,12.59c0.1,0.16,0.35,0.32,0.53,0.32C-56.44,56.26-54.98,56.25-53.47,56.25z
		 M-95.75,76.15c-3.46-3.12-6.84-6.17-10.31-9.3c0,6.27,0,12.36,0,18.59C-102.59,82.31-99.21,79.27-95.75,76.15z M-92.29,76.15
		c3.47,3.13,6.84,6.16,10.28,9.26c0-6.22,0-12.32,0-18.53C-85.48,70.01-88.85,73.05-92.29,76.15z M-83.83,65.41
		c-6.86,0-13.54,0-20.37,0c3.46,3.12,6.81,6.14,10.19,9.18C-90.63,71.53-87.29,68.52-83.83,65.41z M-104.19,86.88
		c6.85,0,13.54,0,20.34,0c-3.45-3.11-6.81-6.13-10.18-9.17C-97.43,80.78-100.76,83.79-104.19,86.88z M-66.55,83.82
		c-4.38,0-8.69,0-13.01,0c0,1.06,0,2.07,0,3.08c4.36,0,8.67,0,13.01,0C-66.55,85.86-66.55,84.87-66.55,83.82z M-121.49,83.81
		c0,1.09,0,2.08,0,3.09c4.36,0,8.67,0,12.98,0c0-1.05,0-2.06,0-3.09C-112.87,83.81-117.15,83.81-121.49,83.81z"/>
					<path d="M-93.92,39.03c4.47,0.1,7.9,3.68,7.81,8.14c-0.09,4.38-3.72,7.8-8.16,7.69c-4.3-0.11-7.79-3.77-7.67-8.05
		C-101.81,42.41-98.23,38.93-93.92,39.03z M-88.42,46.98c0.01-3.13-2.51-5.65-5.61-5.64c-3.09,0.02-5.57,2.5-5.59,5.59
		c-0.02,3.11,2.49,5.63,5.62,5.62C-90.92,52.55-88.43,50.07-88.42,46.98z"/>
				</g>
				<g>
					<path d="M10.48,16.12c0.17-0.4,0.31-0.82,0.53-1.2c0.82-1.42,2.04-2.22,3.7-2.25c1.33-0.03,2.66-0.01,4.13-0.01
		c0-0.64-0.02-1.22,0-1.81c0.1-2.26,1.68-4.01,3.85-4.27c2.08-0.25,4.15,1.09,4.66,3.13c0.23,0.92,0.2,1.9,0.3,2.9
		c1.2,0,2.42,0,3.71,0c0-0.63-0.01-1.22,0-1.82c0.06-2.34,1.96-4.22,4.28-4.24c2.34-0.02,4.25,1.77,4.37,4.13
		c0.03,0.62,0,1.24,0,1.92c7.11,0,14.12,0,21.24,0c0-0.56-0.01-1.1,0-1.64c0.06-2.53,1.92-4.42,4.34-4.41
		c2.44,0.01,4.31,1.95,4.33,4.51c0,0.49,0,0.99,0,1.54c1.26,0,2.47,0,3.8,0c0-0.51-0.01-1.02,0-1.54c0.04-2.58,1.9-4.5,4.34-4.51
		c2.45-0.01,4.31,1.91,4.37,4.48c0.01,0.51,0,1.03,0,1.62c1.32,0,2.54,0,3.76,0c2.77,0.01,4.58,1.8,4.58,4.55
		c0,21.17,0,42.35,0,63.52c0,2.71-1.83,4.54-4.51,4.55c-2.77,0.01-5.54,0.01-8.31,0c-1.05,0-1.7-0.9-1.16-1.71
		c0.22-0.34,0.8-0.6,1.22-0.61c2.69-0.05,5.38-0.03,8.08-0.03c1.6,0,2.34-0.75,2.34-2.35c0-16.68,0-33.35,0-50.03
		c0-0.25,0-0.51,0-0.84c-0.45,0-0.81,0-1.17,0c-19.89,0-39.79,0-59.68,0c-0.31,0-0.63,0.01-0.94-0.03
		c-0.68-0.09-1.12-0.58-1.05-1.23c0.07-0.65,0.41-1.07,1.1-1.09c0.31-0.01,0.63-0.01,0.94-0.01c19.89,0,39.79,0,59.68,0
		c0.34,0,0.67,0,1.06,0c0.02-0.29,0.05-0.51,0.05-0.74c0-3.11,0.01-6.22,0-9.33c0-1.52-0.75-2.27-2.25-2.28
		c-1.22-0.01-2.44,0-3.75,0c0,0.62,0.01,1.17,0,1.71c-0.06,2.58-1.92,4.47-4.38,4.46c-2.42-0.01-4.27-1.9-4.33-4.44
		c-0.01-0.54,0-1.09,0-1.68c-1.28,0-2.49,0-3.8,0c0,0.58,0.01,1.15,0,1.71c-0.03,2.48-1.86,4.37-4.27,4.4
		c-2.46,0.04-4.33-1.8-4.4-4.33c-0.02-0.57,0-1.14,0-1.77c-7.08,0-14.1,0-21.23,0c0,0.53,0.01,1.07,0,1.61
		c-0.05,2.65-1.89,4.53-4.4,4.49c-2.46-0.03-4.26-1.95-4.27-4.55c0-0.49,0-0.99,0-1.55c-1.24,0-2.46,0-3.8,0
		c0,0.59,0.02,1.21,0,1.82c-0.1,2.23-1.7,4.03-3.77,4.25c-2.21,0.24-4.23-1.03-4.73-3.11c-0.23-0.95-0.2-1.96-0.29-3
		c-1.42,0-2.85-0.05-4.28,0.02c-0.87,0.04-1.6,0.86-1.61,1.76c-0.03,3.47-0.01,6.95-0.01,10.54c0.31,0,0.59,0,0.86,0
		c1.91,0,3.82-0.01,5.72,0c0.85,0.01,1.3,0.4,1.32,1.11c0.03,0.74-0.44,1.22-1.3,1.23c-2.17,0.02-4.34,0.01-6.62,0.01
		c0,0.32,0,0.6,0,0.88c0,16.63,0,33.25,0,49.88c0,1.76,0.7,2.47,2.46,2.47c18.25,0,36.49,0,54.74,0c1.32,0,1.8,0.32,1.8,1.19
		c-0.01,0.86-0.49,1.16-1.82,1.16c-18.01,0-36.02-0.03-54.03,0.03c-2.7,0.01-4.61-0.83-5.49-3.48
		C10.48,59.93,10.48,38.02,10.48,16.12z M76.08,13.87c0,0.91-0.01,1.83,0,2.74c0.03,1.36,0.82,2.21,2.02,2.2
		c1.16-0.01,1.96-0.86,1.97-2.17c0.02-1.85,0.02-3.71,0-5.56c-0.02-1.29-0.84-2.16-1.98-2.17c-1.17-0.01-1.99,0.88-2.02,2.22
		C76.07,12.04,76.08,12.96,76.08,13.87z M25.19,13.86c0-0.89,0.01-1.78,0-2.66c-0.02-1.4-0.83-2.31-2.03-2.29
		c-1.17,0.01-1.96,0.91-1.98,2.26c-0.02,1.8-0.02,3.6,0,5.41c0.01,1.37,0.8,2.23,1.99,2.24c1.2,0.01,1.99-0.85,2.02-2.21
		C25.21,15.69,25.19,14.77,25.19,13.86z M37.67,13.88c0-0.94,0.02-1.88,0-2.82c-0.03-1.3-0.83-2.16-1.98-2.16
		c-1.15,0-1.98,0.88-1.98,2.16c-0.01,1.85-0.01,3.71,0,5.56c0.01,1.3,0.79,2.17,1.95,2.19c1.18,0.02,1.99-0.8,2.02-2.11
		C37.69,15.77,37.67,14.82,37.67,13.88z M67.57,13.85c0-0.97,0.02-1.93,0-2.9c-0.03-1.17-0.85-2.02-1.94-2.05
		c-1.12-0.03-2,0.82-2.02,2.03c-0.03,1.96-0.03,3.92,0,5.87c0.02,1.2,0.84,1.99,1.96,2c1.14,0,1.97-0.84,1.99-2.06
		C67.59,15.79,67.57,14.82,67.57,13.85z"/>
					<path d="M32.87,65.88c-2.37-0.06-4.24-2.01-4.19-4.38c0.05-2.36,2.01-4.24,4.38-4.19c2.41,0.05,4.3,2.03,4.23,4.42
		C37.22,64.09,35.25,65.94,32.87,65.88z M33,59.66c-1.07-0.01-1.95,0.86-1.96,1.92c-0.01,1.06,0.87,1.94,1.94,1.94
		c1.08,0.01,1.95-0.85,1.96-1.92C34.95,60.54,34.08,59.67,33,59.66z"/>
					<path d="M21.38,65.88c-2.38,0.01-4.3-1.88-4.31-4.25c-0.01-2.41,1.92-4.34,4.32-4.33c2.37,0.01,4.29,1.94,4.29,4.29
		C25.67,63.95,23.75,65.87,21.38,65.88z M21.4,59.66c-1.08-0.02-1.96,0.82-1.98,1.89c-0.02,1.08,0.82,1.95,1.9,1.97
		c1.08,0.02,1.97-0.82,1.99-1.89C23.34,60.58,22.47,59.68,21.4,59.66z"/>
					<path d="M72.15,39.36c0,2.37-1.89,4.3-4.25,4.32c-2.36,0.03-4.36-1.97-4.34-4.34c0.02-2.37,1.94-4.26,4.31-4.26
		C70.26,35.08,72.15,36.97,72.15,39.36z M69.79,39.36c0-1.09-0.85-1.93-1.93-1.93c-1.08,0-1.94,0.87-1.94,1.94
		c0,1.06,0.9,1.95,1.95,1.95C68.93,41.32,69.8,40.44,69.79,39.36z"/>
					<path d="M32.98,46.2c2.37,0,4.3,1.9,4.31,4.26c0.01,2.39-1.96,4.35-4.34,4.33c-2.36-0.02-4.28-1.96-4.27-4.31
		C28.69,48.11,30.61,46.2,32.98,46.2z M33.02,52.42c1.07-0.02,1.94-0.91,1.92-1.97c-0.02-1.07-0.9-1.91-1.98-1.9
		c-1.07,0.01-1.94,0.89-1.92,1.95C31.06,51.57,31.95,52.44,33.02,52.42z"/>
					<path d="M44.53,65.88c-2.37-0.04-4.27-2-4.23-4.35c0.04-2.36,1.99-4.25,4.35-4.23c2.41,0.02,4.3,1.97,4.25,4.39
		C48.87,64.05,46.91,65.92,44.53,65.88z M46.56,61.61c0.01-1.08-0.84-1.94-1.93-1.95c-1.07-0.01-1.96,0.86-1.97,1.91
		c-0.01,1.06,0.87,1.94,1.94,1.95C45.68,63.53,46.55,62.69,46.56,61.61z"/>
					<path d="M33.01,35.08c2.36,0.01,4.29,1.93,4.29,4.29c0,2.35-1.94,4.3-4.28,4.31c-2.37,0.02-4.34-1.97-4.33-4.35
		C28.7,36.97,30.63,35.07,33.01,35.08z M33.02,37.44c-1.07-0.01-1.96,0.84-1.97,1.91c-0.01,1.06,0.86,1.96,1.92,1.98
		c1.04,0.02,1.96-0.87,1.98-1.93C34.96,38.34,34.08,37.45,33.02,37.44z"/>
					<path d="M60.53,39.38c0,2.36-1.93,4.29-4.28,4.3c-2.33,0.01-4.3-1.94-4.32-4.29c-0.02-2.37,1.95-4.33,4.35-4.31
		C58.65,35.09,60.54,37,60.53,39.38z M58.17,39.37c0-1.08-0.85-1.94-1.93-1.94c-1.07,0-1.95,0.87-1.96,1.93
		c0,1.05,0.9,1.96,1.95,1.96C57.29,41.33,58.17,40.44,58.17,39.37z"/>
					<path d="M21.39,46.2c2.36,0.01,4.29,1.94,4.28,4.29c-0.01,2.35-1.95,4.29-4.3,4.29c-2.39,0.01-4.32-1.94-4.31-4.35
		C17.08,48.07,19.01,46.18,21.39,46.2z M21.37,52.42c1.06-0.01,1.95-0.9,1.94-1.95c-0.01-1.06-0.89-1.92-1.96-1.92
		c-1.08,0.01-1.94,0.87-1.93,1.94C19.43,51.57,20.3,52.43,21.37,52.42z"/>
					<path d="M44.58,46.2c2.38-0.01,4.31,1.87,4.33,4.23c0.02,2.4-1.91,4.35-4.3,4.35c-2.35,0-4.29-1.94-4.3-4.29
		C40.3,48.14,42.22,46.21,44.58,46.2z M46.56,50.47c0-1.08-0.86-1.92-1.95-1.92c-1.07,0-1.95,0.88-1.95,1.94
		c0.01,1.05,0.9,1.94,1.96,1.93C45.69,52.42,46.56,51.55,46.56,50.47z"/>
					<path d="M21.35,76.99c-2.37-0.01-4.28-1.91-4.28-4.28c0-2.36,1.91-4.29,4.27-4.31c2.37-0.02,4.36,1.97,4.33,4.34
		C25.65,75.1,23.72,77,21.35,76.99z M21.38,74.64c1.07-0.01,1.95-0.89,1.94-1.95c-0.01-1.05-0.92-1.94-1.97-1.93
		c-1.07,0.01-1.93,0.89-1.92,1.96C19.43,73.8,20.3,74.65,21.38,74.64z"/>
					<path d="M44.6,76.99c-2.36-0.01-4.29-1.94-4.29-4.29c0-2.35,1.94-4.29,4.29-4.31c2.38-0.02,4.33,1.95,4.32,4.35
		C48.9,75.11,46.98,77,44.6,76.99z M46.56,72.72c0.01-1.07-0.86-1.95-1.93-1.96c-1.05-0.01-1.96,0.88-1.97,1.93
		c-0.01,1.06,0.87,1.94,1.94,1.95C45.68,74.64,46.55,73.79,46.56,72.72z"/>
					<path d="M56.26,46.2c2.38,0.01,4.27,1.9,4.27,4.28c0,2.37-1.9,4.29-4.27,4.3c-2.38,0.02-4.36-1.97-4.33-4.34
		C51.96,48.09,53.89,46.19,56.26,46.2z M58.17,50.47c-0.01-1.09-0.85-1.92-1.95-1.92c-1.07,0-1.95,0.88-1.94,1.94
		c0.01,1.06,0.9,1.93,1.96,1.93C57.33,52.41,58.18,51.55,58.17,50.47z"/>
					<path d="M32.98,76.99c-2.37,0-4.29-1.92-4.3-4.28c0-2.36,1.91-4.29,4.27-4.32c2.37-0.02,4.35,1.95,4.34,4.33
		C37.28,75.09,35.35,77,32.98,76.99z M33.01,74.64c1.07-0.01,1.94-0.89,1.93-1.95c-0.01-1.06-0.92-1.94-1.97-1.92
		c-1.06,0.01-1.94,0.91-1.93,1.97C31.05,73.79,31.94,74.65,33.01,74.64z"/>
					<path d="M79.54,35.08c2.37,0.02,4.25,1.94,4.24,4.32c-0.02,2.37-1.95,4.29-4.3,4.28c-2.38-0.01-4.32-1.99-4.28-4.38
		C75.24,36.92,77.15,35.06,79.54,35.08z M81.42,39.37c0-1.09-0.84-1.93-1.93-1.94c-1.09,0-1.93,0.84-1.93,1.93
		c0,1.07,0.87,1.95,1.93,1.96C80.55,41.32,81.42,40.45,81.42,39.37z"/>
					<path d="M60.53,61.62c-0.01,2.38-1.91,4.27-4.29,4.27c-2.36,0-4.3-1.92-4.31-4.27c-0.01-2.38,1.96-4.33,4.35-4.31
		C58.66,57.33,60.54,59.23,60.53,61.62z M58.17,61.62c0.01-1.09-0.82-1.94-1.91-1.95c-1.07-0.01-1.96,0.85-1.97,1.91
		c-0.01,1.06,0.86,1.94,1.93,1.95C57.31,63.53,58.16,62.7,58.17,61.62z"/>
					<path d="M44.63,35.08c2.37,0,4.28,1.91,4.29,4.28c0.01,2.36-1.91,4.3-4.27,4.32c-2.36,0.03-4.36-1.98-4.34-4.34
		C40.32,36.99,42.26,35.08,44.63,35.08z M44.58,37.44c-1.07,0.02-1.94,0.92-1.92,1.97c0.02,1.05,0.95,1.94,1.99,1.92
		c1.06-0.02,1.92-0.93,1.91-1.99C46.54,38.27,45.66,37.42,44.58,37.44z"/>
					<path d="M67.86,46.2c2.39,0,4.28,1.87,4.29,4.26c0.01,2.41-1.93,4.35-4.32,4.33c-2.36-0.02-4.28-1.96-4.27-4.31
		C63.57,48.1,65.49,46.2,67.86,46.2z M67.9,48.55c-1.09-0.02-1.96,0.81-1.98,1.89c-0.02,1.07,0.84,1.95,1.91,1.97
		c1.08,0.02,1.95-0.82,1.96-1.9C69.81,49.42,68.99,48.57,67.9,48.55z"/>
					<path d="M72.15,61.56c0.01,2.39-1.85,4.3-4.23,4.32c-2.4,0.02-4.36-1.91-4.36-4.29c0.01-2.36,1.92-4.27,4.29-4.28
		C70.23,57.29,72.14,59.18,72.15,61.56z M69.79,61.56c-0.01-1.09-0.87-1.91-1.96-1.9c-1.08,0.02-1.93,0.89-1.91,1.96
		c0.02,1.08,0.89,1.91,1.98,1.89C68.99,63.51,69.81,62.66,69.79,61.56z"/>
					<path d="M79.5,46.2c2.39,0,4.28,1.89,4.28,4.27c0.01,2.4-1.94,4.35-4.33,4.32c-2.37-0.03-4.26-1.95-4.25-4.33
		C75.21,48.08,77.11,46.2,79.5,46.2z M79.52,48.55c-1.1-0.01-1.95,0.81-1.96,1.9c-0.01,1.09,0.82,1.95,1.9,1.97
		c1.08,0.02,1.94-0.83,1.96-1.91C81.43,49.41,80.61,48.57,79.52,48.55z"/>
					<path d="M83.78,61.71c-0.06,2.38-2,4.22-4.38,4.17c-2.38-0.05-4.24-1.99-4.19-4.36c0.04-2.41,2.02-4.29,4.42-4.21
		C82.01,57.39,83.83,59.32,83.78,61.71z M81.42,61.58c-0.01-1.09-0.85-1.92-1.94-1.92c-1.09,0.01-1.92,0.85-1.92,1.94
		c0.01,1.09,0.85,1.92,1.94,1.91C80.6,63.51,81.42,62.67,81.42,61.58z"/>
				</g>
			</svg>
		</span>
	)
}

