import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/RequestManagerTypes'
import { SystemConfig } from 'config/SystemConfig'
import { ISaveHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISaveHarvestPlanningReqDTO'
import { ISearchHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISearchHarvestPlanningReqDTO'
import { IConcludeHarvestPlanningRequestDTO } from 'modules/harvest-planning/services/dtos/request/IConcludeHarvestPlanningRequestDTO'

export class HarvestPlanningRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/harvest-planning`
    private constructor() { }

    static search = (dto: ISearchHarvestPlanningReqDTO): RequestConfigTP => ({
        url: `${HarvestPlanningRequests.baseApiUrl}/`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static get = (code: number): RequestConfigTP => ({
        url: `${HarvestPlanningRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static save = (dto: ISaveHarvestPlanningReqDTO): RequestConfigTP => ({
        url: `${HarvestPlanningRequests.baseApiUrl}/`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static update = (code: number, dto: ISaveHarvestPlanningReqDTO): RequestConfigTP => ({
        url: `${HarvestPlanningRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${HarvestPlanningRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static markAsConcluded = (code: number, dto: IConcludeHarvestPlanningRequestDTO): RequestConfigTP => ({
        url: `${HarvestPlanningRequests.baseApiUrl}/${code}/mark-as-concluded`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static reopen = (code: number): RequestConfigTP => ({
        url: `${HarvestPlanningRequests.baseApiUrl}/${code}/reopen`,
        method: HttpMethodEnum.PUT,
    })

    static getGeneralReport = (dto: ISearchHarvestPlanningReqDTO): RequestConfigTP => ({
        url: `${HarvestPlanningRequests.baseApiUrl}/report`,
        method: HttpMethodEnum.GET,
        params: dto,
    })
}