import { Avatar } from 'antd'
import { StringUtils } from 'common/utils/StringUtils'
import { SystemConfig } from 'config/SystemConfig'
import React, { CSSProperties, useEffect, useState } from 'react'

export interface IAvatarCPProps {
    size: 'large' | 'small' | 'default' | number
    src?: string
    name?: string
    showFullName?: boolean
    style?: CSSProperties
}

/**
 * Componente para renderizar imagem de perfil
 *
 * Caso tenha passado imagem e seja valida, mostra imagem
 * Caso contrario, se passou nome mostra as iniciais
 * Caso contrario, mostra imagem padrao
 *
 * @author Kiara Martins
 * @author renatofs
 */
export function AvatarCP(props: IAvatarCPProps): JSX.Element {
    const showFullName = props.showFullName ?? false
    const [validSrc, setValidSrc] = useState<boolean>(!!props.src)
    const [nameIntials, setNameInitials] = useState<string>(props.name && !showFullName ? StringUtils.getFirstAndLastInitials(props.name) : props.name ?? '')

    useEffect(() => {
        setValidSrc(!!props.src)
    }, [props.src])

    /** Evento o link da imagem seja invalido. */
    function onError(): boolean {
        setValidSrc(false)

        if (props.name)
            setNameInitials(StringUtils.getFirstAndLastInitials(props.name))

        return true
    }


    return (
        <>
            {
                validSrc &&
                <Avatar
                    src={props.src}
                    size={props.size}
                    shape={'circle'}
                    style={props.style}
                    onError={onError}
                />
            }

            {
                !validSrc && props.name &&
                <Avatar
                    size={props.size}
                    style={props.style}
                    shape={'circle'}
                >
                    {nameIntials}
                </Avatar>
            }

            {
                !validSrc && !props.name &&
                <Avatar
                    src={`${SystemConfig.getInstance().uiBaseUrl}/assets/avatar-placeholder.jpg`}
                    size={props.size}
                    shape={'circle'}
                    style={props.style}
                />
            }
        </>
    )
}

