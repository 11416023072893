import React, { CSSProperties } from 'react'

export function ManeuverActivitiesIcon(props: { color?: string, style?: CSSProperties }): JSX.Element {
    return (
        <span style={{ display: 'flex', justifyContent: 'center', ...props.style }}>

            <svg height={'27'} viewBox={'0 0 512 512'} width={'27'} fill={props.color} xmlns={'http://www.w3.org/2000/svg'}>
                <g id={'Planting-5'} data-name={'Planting'}>
                    <g id={'Glyph'}>
                        <path d={'m418.766 32.916c-2.78-.012-68.723.016-102.5 26.557-25.035 19.674-38.842 44.864-24.433 70.2l-27.833 21.868a141.787 141.787 0 0 0 -10.233-53.973c20.717-22.332 12.546-47.482-10.131-68.669-26.697-24.942-83.161-28.734-85.552-28.883a8.087 8.087 0 0 0 -8.43 9.024c.311 2.374 7.928 58.45 34.625 83.392 17.885 16.709 38.157 24.354 56.242 15.323a125.483 125.483 0 0 1 7.479 42.727v37.026l-22.884-12.463c3.907-13.3 2.6-35.007-33.389-54.614-33.172-18.07-88.883-11.13-91.239-10.826a8.085 8.085 0 0 0 -6.2 11.376c1.021 2.144 25.406 52.714 58.579 70.783 23.62 12.867 50.6 12.514 64.542-2.7l30.591 16.663v10.273a8 8 0 0 0 16 0v-44.112l37.6-29.541c21.33 20.991 49.818 13.435 74.88-6.261 33.772-26.54 49.387-90.6 50.036-93.312a8.1 8.1 0 0 0 -7.75-9.858z'} />
                        <path d={'m440.4 297.539-33.939-33.939a8 8 0 0 1 0-11.314l50.912-50.911a32.13 32.13 0 0 1 45.254 0c12.233 12.232 12.386 33.008 0 45.254l-50.911 50.912a8 8 0 0 1 -11.316-.002z'} />

                        <path d={'m501.711 390.694c-8.04-13.83-27.127-15.184-37.95-3.4q-.384.417-.79.817l-73.481 73.477c-24.781 22.194-96.526 37.037-138.525 14-4.952-2.716-1.976-10.264 3.517-8.95l.584.138c34.8 8.127 52.349-41.126 19.7-55.656q-1.365-.606-2.8-1.094c1.356-29.976-25.343-53.815-54.843-49.538a95.47 95.47 0 0 1 25.3-45.983l45.198-45.205a16 16 0 0 0 -7.721-26.913c-41.29-9.287-84.992 6.89-108.129 45a120.449 120.449 0 0 0 -3.024 24.621c-.03 10.078-16 10.348-16-.006v-.159c.01-13.6-13.37-23.4-26.22-18.931q-.566.2-1.129.4c-22.642 8.226-30.839 35.536-17.194 55.388 4.4 6.408 6.8 13.345 6.8 19.3 0 10.134-14.128 9.983-16 0a22.916 22.916 0 0 0 -4.932-10.285c-7.548-9.26-20.348-12.347-31.584-8.288-40.735 14.717-62.772 43.82-62.488 83.334.319 43.582 36.7 79.239 80.28 79.239h351.535c43.895 0 79.855-35.3 80.182-79.19q.18-24.129-10.286-42.116z'} />
                        <path d={'m429.088 342.794-55.6 55.6c-19.247 19.235-49.878 18-67.882 0a48.059 48.059 0 0 1 0-67.882l55.6-55.6-22.627-22.628a8.059 8.059 0 0 0 -11.314 0l-73.539 73.54a76.989 76.989 0 0 0 -14.426 20.035 64.161 64.161 0 0 1 48.179 53.975c24.457 15.015 32.161 49.709 15.305 73.69 26.154 2.736 54.328-2.194 75.39-23.25l73.54-73.539a8.078 8.078 0 0 0 0-11.314z'} />
                        <path d={'m390.059 268.686-73.137 73.138a32 32 0 1 0 45.254 45.254l73.138-73.137z'} />
                    </g>
                </g>
            </svg>
        </span>
    )
}