import { DividerCP } from 'common/components/divider/DividerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'

import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { BatchProcessFormValidator } from 'modules/batch/components/batch-process-drawer/inner/BatchProcessFormValidator'
import { BatchProcessMethodSelectorCP } from 'modules/batch/components/batch-process-method-selector/BatchProcessMethodSelectorCP'
import { BatchProcessMethodEnum } from 'modules/batch/enums/BatchProcessMethodEnum'
import { StructureSelectorCP } from 'modules/structure/components/structure-selector/StructureSelectorCP'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface IPostHarvestFormICPProps {
    formStateManager: IFormStateManager<BatchProcessFormValidator>
}

export function PostHarvestFormICP(props: IPostHarvestFormICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [method, setMethod] = useState<BatchProcessMethodEnum>()

    useEffect(clearFormState, [])

    function clearFormState(): void {
        props.formStateManager.changeFieldValue('benefit', undefined)
        props.formStateManager.changeFieldValue('storage', undefined)
    }

    return (
        <WrapperSCP>
            <DividerCP text={'Informe os dados do pós colheita'} orientation={'left'} />
            <FormWrapperSCP>
                <TwoColWrapperSCP>
                    <BatchProcessMethodSelectorCP
                        label={'Processamento'}
                        formStateManager={props.formStateManager}
                        required={true}
                        onSelect={setMethod}
                        value={method}
                    />
                </TwoColWrapperSCP>
                <TwoColWrapperSCP>
                    <StructureSelectorCP
                        formStateManager={props.formStateManager}
                        required={true}
                        label={'Estrutura'}
                    />
                </TwoColWrapperSCP>
            </FormWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div``
const FormWrapperSCP = styled.div``

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`
