import { DividerCP } from 'common/components/divider/DividerCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { BatchProcessFormValidator } from 'modules/batch/components/batch-process-drawer/inner/BatchProcessFormValidator'
import React, { useEffect, useState } from 'react'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import styled from 'styled-components'
import { StorageFormValidator } from 'modules/batch/components/batch-process-drawer/inner/StorageFormValidator'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { StoragePlaceSelectorCP } from 'modules/batch/components/storage-place-selector/StoragePlaceSelectorCP'
import { StorageTypeSelectorCP } from 'modules/batch/components/storage-type-selector/StorageTypeSelectorCP'
import { ISaveStorageReqDTO } from 'modules/batch/services/dtos/requests/ISaveStorageReqDTO'
import { faPercent } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'

interface IStorageFormICPProps {
    formStateManager: IFormStateManager<BatchProcessFormValidator>
}

export function StorageFormICP(props: IStorageFormICPProps): JSX.Element {
    const screenSize = useScreenSize()

    const [formValidator, setFormValidator] = useState<StorageFormValidator>()
    const formStateManager = useFormStateManager(formValidator)

    const [storageData, setStorageData] = useState<ISaveStorageReqDTO>(props.formStateManager.getFieldValue('storage'))

    useEffect(clearFormState, [])
    useEffect(() => { setMainValidator() }, [storageData])

    function clearFormState(): void {
        props.formStateManager.changeFieldValue('structureCode', undefined)
        props.formStateManager.changeFieldValue('benefit', undefined)
    }

    async function setMainValidator(): Promise<void> {

        props.formStateManager.changeFieldValue('storage', {
            type: storageData?.type,
            typeOther: storageData?.typeOther,
            place: storageData?.place,
            placeName: storageData?.placeName,
            humidity: !!storageData && !!storageData.humidity ? +storageData.humidity : undefined,
        })

    }

    return (
        <WrapperSCP>
            <DividerCP text={'Informe os dados do armazenamento'} orientation={'left'} />
            <TwoColWrapperSCP>
                <StorageTypeSelectorCP
                    label={'Tipo'}
                    formStateManager={formStateManager}
                    required={true}
                    value={storageData?.type}
                    onSelect={(type) => setStorageData({ ...storageData, type })}
                />
                <InputCP
                    label={'Outro'}
                    type={'text'}
                    fieldName={'typeOther'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    loading={false}
                />
            </TwoColWrapperSCP>
            <TwoColWrapperSCP>
                <StoragePlaceSelectorCP
                    label={'Local'}
                    formStateManager={formStateManager}
                    required={false}
                    value={storageData?.place}
                    onSelect={(place) => setStorageData({ ...storageData, place })}
                />
                <InputCP
                    label={'Nome do local'}
                    type={'text'}
                    fieldName={'placeName'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    value={storageData?.placeName}
                    onChange={(placeName) => setStorageData({ ...storageData, placeName })}
                    loading={false}
                />

            </TwoColWrapperSCP>
            <TwoColWrapperSCP>
                <InputCP
                    label={'Umidade'}
                    type={'number'}
                    fieldName={'humidity'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    icon={<FontAwsomeIconCP icon={faPercent} />}
                    value={storageData?.humidity}
                    onChange={(humidity) => setStorageData({ ...storageData, humidity })}
                />

            </TwoColWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
const FormWrapperSCP = styled.div`
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`
