import React, { useState } from 'react'
import styled from 'styled-components'
import { ProducerInfoHeaderCP } from 'modules/batch-landing-page/components/producer-info-header/ProducerInfoHeaderCP'
import { IFieldResponseDTO } from 'modules/field/services/dtos/responses/IFieldResponseDTO'
import { FieldDetailsCarouselICP } from 'modules/batch-landing-page/components/batch-main-info/inner/FieldDetailsCarouselICP'
import { PropertyDetailsCarouselICP } from 'modules/batch-landing-page/components/batch-main-info/inner/PropertyDetailsCarouselICP'

interface IBatchMainInfoCPProps {
    fields: IFieldResponseDTO[]
}

export function BatchMainInfoCP(props: IBatchMainInfoCPProps): JSX.Element {

    const [producerCode, setProducerCode] = useState<number>()
    const allProperties = props.fields.map(field => field.propertyCode)
    const codes = [...new Set(allProperties)]

    return (
        <BatchMainInfoWrapperSCP>
            <ProducerInfoHeaderCP producerCode={producerCode} />
            <PropertyDetailsCarouselICP propertyCodes={codes} setProducerCode={setProducerCode} />
            <FieldDetailsCarouselICP fields={props.fields} />
        </BatchMainInfoWrapperSCP>
    )
}

const BatchMainInfoWrapperSCP = styled.div`
    min-height: 100vh;
    height: 100%;
    padding: 20px;
    background: ${props => props.theme.primaryColor};
    background: linear-gradient(135deg,#73169C 0%,#951EC0 100%);
    position: relative;
    box-shadow: 10px 10px 43px 0px rgba(0,0,0,0.29);
   
`
