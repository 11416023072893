import { FinancialRoutes } from 'modules/financial/FinancialRoutes'
import { BatchRoutes } from 'modules/batch/BatchRoutes'
import { HarvestRoutes } from 'modules/harvest/HarvestRoutes'
import { ManeuverRoutes } from 'modules/maneuver/ManeuverRoutes'
import { PersonRoutes } from 'modules/person/routes/PersonRoutes'
import { ReportRoutes } from 'modules/report/ReportRoutes'
import { StructureRoutes } from 'modules/structure/StructureRoutes'
import React from 'react'
import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { PropertyRoutes } from '../../property/PropertyRoutes'
import { AppRoutes } from '../AppRoutes'
import { CompanyRoutes } from 'modules/company/CompanyRoutes'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { IUserLoginResponseDTO } from '../../auth/services/dtos/responses/IUserLoginResponseDTO'

export function PrivateUserRouter(): JSX.Element {
    const loggedUser: IUserLoginResponseDTO = useSelector((state: IReduxState) => state.loggedUser)
    return (
        <Switch>
            <Route path={ReportRoutes.ROOT} component={ReportRoutes} />
            <Route path={StructureRoutes.ROOT} component={StructureRoutes} />
            <Route path={BatchRoutes.ROOT} component={BatchRoutes} />
            <Route path={HarvestRoutes.ROOT} component={HarvestRoutes} />
            <Route path={PropertyRoutes.ROOT} component={PropertyRoutes} />
            <Route path={ManeuverRoutes.ROOT} component={ManeuverRoutes} />
            <Route path={PersonRoutes.ROOT} component={PersonRoutes} />
            <Route path={FinancialRoutes.ROOT} component={FinancialRoutes} />
            {loggedUser.account === 126 && (
                <Route path={CompanyRoutes.ROOT} component={CompanyRoutes} />
            )}
            <Redirect from={AppRoutes.ROOT} to={PropertyRoutes.ROOT} />
        </Switch>
    )
}
