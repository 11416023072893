import { faCrown, faSeedling } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { BestBatchesReportContent } from 'modules/report/components/bast-batches-report-content/BestBatchesReportContentCP'
import { FieldReportContentCP } from 'modules/report/components/field-report-content/FieldReportContentCP'
import React from 'react'
import styled from 'styled-components'

export function BestBatchesScreen(): JSX.Element {

    return (
        <LayoutCP
            header={
                <HeaderCP icon={<FontAwsomeIconCP icon={faCrown} />} title={'Melhores lotes'} />
            }
            content={
                <BestBatchesReportContent />
            }
        />
    )
}

const WrapperSCP = styled.div`
`
