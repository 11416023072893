import { TextCP } from 'common/components/text/TextCP'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { IBenefitResDTO } from 'modules/batch/services/dtos/responses/IBenefitResDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IBenefitDetailsContentICPProps {
    benefit: IBenefitResDTO
    language?: LanguageEnum
}

const LABELS_PT = {
    spoutOne: 'Bica 1',
    spoutTwo: 'Bica 2',
    choice: 'Escolha',
    humidity: 'Umidade',
    bags: 'sacas'
}

const LABELS_EN = {
    spoutOne: 'Spout 1',
    spoutTwo: 'Spout 2',
    choice: 'Choice',
    humidity: 'Humidity',
    bags: 'bags'
}
export function BenefitDetailsContentICP(props: IBenefitDetailsContentICPProps): JSX.Element {
    const [labels, setLabels] = useState(LABELS_PT)

    useEffect(translateLabels, [props.language])

    function translateLabels(): void {
        if (props.language === LanguageEnum.EN)
            setLabels(LABELS_EN)
    }

    return (
        <ContentInfoSCP>
            {
                !!props.benefit.spoutOne &&
                <RowSCP>
                    <TextCP text={`${labels.spoutOne}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.benefit.spoutOne} ${labels.bags}`} />
                </RowSCP>

            }
            {
                !!props.benefit.spoutTwo &&
                <RowSCP>
                    <TextCP text={`${labels.spoutTwo}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.benefit.spoutTwo}  ${labels.bags}`} />
                </RowSCP>

            }
            {
                !!props.benefit.choice &&
                <RowSCP>
                    <TextCP text={`${labels.choice}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.benefit.choice}  ${labels.bags}`} />
                </RowSCP>

            }
            {
                !!props.benefit.humidity &&
                <RowSCP>
                    <TextCP text={`${labels.humidity}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.benefit.humidity}%`} />
                </RowSCP>

            }

        </ContentInfoSCP>
    )
}

const WrapperSCP = styled.div`
`

const ContentInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography{
        font-size: 12px;
    }
    
`