import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { ProductTypeEnum } from 'modules/maneuver/enums/ProductTypeEnum'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'
import React from 'react'

/**
 * @todo comentario
 */
export const ProductUtils = {

    getUnityLabel(isLiquid: boolean): string {
        if (isLiquid) {
            return ' litros'
        }

        return ' kg'
    },

    translateUnitOfMeasurement(unitOfMeasurement: UnitOfMeasurementEnum): string {
        switch (unitOfMeasurement) {
            case UnitOfMeasurementEnum.LITERS:
                return 'Litros'
            case UnitOfMeasurementEnum.KILOGRAMS:
                return 'Kilogramas'
            case UnitOfMeasurementEnum.UNITY:
                return 'Unidade'
            default:
                return ''
        }
    },

    getUnitOfMeasurementAbbreviation(unitOfMeasurement: UnitOfMeasurementEnum): string {
        switch (unitOfMeasurement) {
            case UnitOfMeasurementEnum.LITERS:
                return 'L'
            case UnitOfMeasurementEnum.KILOGRAMS:
                return 'Kg'
            case UnitOfMeasurementEnum.UNITY:
                return 'Un'
            default:
                return ''
        }
    },

    translateTypeLabel(type: ProductTypeEnum): string {
        switch (type) {         
            case ProductTypeEnum.FERTILIZER:
                return 'Fertilizante'
            case ProductTypeEnum.HERBICIDE:
                return 'Herbicida'
            case ProductTypeEnum.FUNGICIDE:
                return 'Fungicida'
            case ProductTypeEnum.BACTERICIDE:
                return 'Bactericida'
            case ProductTypeEnum.INSECTICIDE:
                return 'Inseticida'
            case ProductTypeEnum.FUEL:
                return 'Combustível'
            case ProductTypeEnum.SEED:
                return 'Semente'
            case ProductTypeEnum.PIECE:
                return 'Peça'
            default:
                return ''
        }

    },

    getUsageAlertIcon(remainingQuantity: number, quantitySpent: number): JSX.Element {
        if (quantitySpent <= remainingQuantity) {
            return <FontAwsomeIconCP icon={faCheckCircle} color={ProductUtils.getUsageAlertColor(remainingQuantity, quantitySpent)} />
        }

        return <FontAwsomeIconCP icon={faExclamationCircle} color={ProductUtils.getUsageAlertColor(remainingQuantity, quantitySpent)} />
    },

    getUsageAlertColor(remainingQuantity: number, quantitySpent: number): string {

        if (remainingQuantity < 1) {
            return CostumizedThemeConfig.errorColor
        }

        if (quantitySpent <= remainingQuantity) {
            return CostumizedThemeConfig.successColor
        }

        return CostumizedThemeConfig.errorColor
    },

    calculateRemainingQtdAfterAplication(remainingQuantity: number, quantitySpent: number, isLiquid: boolean): number | string {
        if (quantitySpent > remainingQuantity) {
            return 'Não há quantidade suficiente em estoque!'
        }

        return `${MaskUtils.applyNumberMask(remainingQuantity - quantitySpent)}${ProductUtils.getUnityLabel(isLiquid)}`
    },

    calculateRemainingQtdAfterUpdate(remainingQuantity: number, quantitySaved: number, newQuantity: number, isLiquid: boolean): number | string {
        if (newQuantity === quantitySaved) {
            return ` (${MaskUtils.applyNumberMask(remainingQuantity)}${ProductUtils.getUnityLabel(isLiquid)} - Não haverá alteração no estoque)`
        }

        if (newQuantity > (remainingQuantity + quantitySaved)) {
            return `${MaskUtils.applyNumberMask(newQuantity - (remainingQuantity + quantitySaved))}${ProductUtils.getUnityLabel(isLiquid)} excedentes. O gasto não será aplicado!`
        }

        if (newQuantity < quantitySaved) {
            return `${MaskUtils.applyNumberMask(remainingQuantity + (quantitySaved - newQuantity))}${ProductUtils.getUnityLabel(isLiquid)} (${MaskUtils.applyNumberMask(quantitySaved - newQuantity)}${ProductUtils.getUnityLabel(isLiquid)} serão retornados para o estoque) `
        }

        if (newQuantity > quantitySaved) {
            return `${MaskUtils.applyNumberMask(remainingQuantity - (newQuantity - quantitySaved))}${ProductUtils.getUnityLabel(isLiquid)} (${MaskUtils.applyNumberMask(newQuantity - quantitySaved)}${ProductUtils.getUnityLabel(isLiquid)} serão abatidos do estoque)`
        }

        return ''
    },
}

