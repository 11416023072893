import { TabsCP } from 'common/components/tabs/TabsCP'
import { FieldListCP } from 'modules/field/components/field-list/FieldListCP'

import { IFieldResponseDTO } from 'modules/field/services/dtos/responses/IFieldResponseDTO'
import { PropertyDescriptionEngICP } from 'modules/property/components/property-details/inner/PropertyDescriptionEngICP'
import { PropertyDescriptionICP } from 'modules/property/components/property-details/inner/PropertyDescriptionICP'
import { PropertyPhotoGalleryICP } from 'modules/property/components/property-details/inner/PropertyPhotoGalleryICP'
import { PropertyDocumentListCP } from 'modules/property/components/property-document-list/PropertyDocumentListCP'
import React from 'react'

interface IPropertyInfoTabsICPProps {
    reloadData?: number
    activeKey: string
    editField: (field: IFieldResponseDTO) => void
    setActiveKey: (key: string) => void
}

export function PropertyInfoTabsICP(props: IPropertyInfoTabsICPProps): JSX.Element {
    return (
        <TabsCP
            defaultActiveKey={'description'}
            activeKey={props.activeKey}
            onChange={props.setActiveKey}
            spread={true}
            tabs={[
                {
                    title: 'Descrição',
                    content: <div>
                        <PropertyDescriptionICP />
                        <PropertyDescriptionEngICP />
                    </div>,
                    key: 'description'
                },
                {
                    title: 'Talhões',
                    content: <FieldListCP reload={props.reloadData} editField={props.editField} />,
                    key: 'fields'
                },
                {
                    title: 'Fotos',
                    content: <PropertyPhotoGalleryICP />,
                    key: 'photos'
                },
                {
                    title: 'Documentos',
                    content: <PropertyDocumentListCP />,
                    key: 'documents'
                }
            ]}
        />
    )
}