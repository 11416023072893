import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DocumentCardICP } from 'modules/property/components/property-document-list/inner/DocumentCardICP'
import { IDocumentResponseDTO } from 'modules/property/services/dtos/responses/IDocumentResponseDTO'
import { PropertyRequests } from 'modules/property/services/PropertyRequests'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

export function PropertyDocumentListCP(): JSX.Element {
    const [documentList, setDocumentList] = useState<IDocumentResponseDTO[]>()
    const property = useSelector((state: IReduxState) => state.property)
    const request = useRequest<IGenericListResponseDTO<IDocumentResponseDTO>>()

    useEffect(searchDocuments, [property])
    useEffect(onRequestChange, [request.awaiting])

    function searchDocuments(): void {
        if (!property)
            return

        request.runRequest(PropertyRequests.searchDocuments(property.code))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar talhões!')
        }
        setDocumentList(request.returnData.list)
    }

    const loading = request.awaiting || !documentList

    return (
        <ListWrapper>
            <ListCP<IDocumentResponseDTO>
                loading={loading}
                itemLayout={'vertical'}
                size={'large'}
                loadMoreData={() => { }} dataSource={documentList}
                locale={{
                    emptyText: <EmptyCP description={'Você ainda não tem documentos cadastrados'} />
                }}
                renderItem={(item: IDocumentResponseDTO) => (<DocumentCardICP document={item} reloadList={searchDocuments} loading={loading} />
                )}
            />
        </ListWrapper>
    )
}

const ListWrapper = styled.div`
    margin-bottom: 10em;
    .ant-list-item{
        box-shadow: -5px 10px 16px -4px rgba(0,0,0,0.21); 
        position: relative;
        &:before{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            border-radius: 0 5px 5px 0;
            background: ${props => props.theme.primaryColor}
        }
    }
`
const ListHeaderSCP = styled.div`
    .ant-typography{
        text-transform: uppercase;
        white-space: pre-wrap;
        text-align: center;
    }
    display: grid;
    grid-template-columns: 2.3fr .8fr .8fr .8fr .8fr 1fr 1fr;
    column-gap: 5px;
    padding: 16px 24px;
   
`
