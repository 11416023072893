import styled from 'styled-components'
import React from 'react'
import { TextCP } from 'common/components/text/TextCP'
import { Row } from 'antd'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { ISearchPersonRequestDTO } from 'modules/person/services/dtos/requests/ISearchPersonRequestDTO'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import _ from 'lodash'

interface IPersonListFiltersCPProps {
    setFilters: (filters: ISearchPersonRequestDTO) => void
    filters?: ISearchPersonRequestDTO
}
export function PersonListFiltersCP(props: IPersonListFiltersCPProps): JSX.Element {
    const screenSize = useScreenSize()

    return (
        <WrapperSCP>
            <Row>
                <TextCP text={'Filtre por:'} />
            </Row>
            <GridWrapperSCP>
                <InputCP
                    placeholder={'Nome'}
                    onChange={_.debounce((name) => props.setFilters({ ...props.filters, name }), 750)}
                    value={props.filters?.name}
                />
            </GridWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
    .ant-typography{
        font-size: 12px;   
    }

`

const GridWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: .5fr 1fr;
    column-gap: 10px;
    margin-top: 10px;
    @media(max-width: 767px){
        grid-template-columns: 1fr;
    }
`