import { List, Skeleton } from 'antd'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface IListItemICPProps extends React.PropsWithChildren<any> {
    title?: string | JSX.Element
    content?: JSX.Element | JSX.Element[] | string
    avatar?: JSX.Element
    loading?: boolean
    style?: CSSProperties
    customSkeleton?: JSX.Element
}

export function ListItemICP(props: IListItemICPProps): JSX.Element {

    if (props.loading) {
        if (props.customSkeleton)
            return props.customSkeleton
        return <Skeleton active={true} />
    }

    return (
        <ListItemSCP style={props.style}>
            <ListItemContainerSCP>
                <List.Item.Meta
                    title={props.title}
                    description={props.content}
                    avatar={props.avatar}
                />
                {props.children}
            </ListItemContainerSCP>
        </ListItemSCP>
    )
}

const ListItemContainerSCP = styled.div`
    width: 100%;
    
    .ant-list-item-meta {
        align-items: center;
        margin-bottom: 5px;
    }

    .ant-list-item-meta-title {
        text-align: center;
    }

    .ant-list-item-meta-content {
        width: 100%;
    }

`

const ListItemSCP = styled(List.Item)`
    background: #fff;
    margin-bottom: 15px;
    /* box-shadow: -5px 10px 21px 6px rgba(214,214,214,1); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    &.ant-list-item-no-flex{
        padding: 0;
    }
`