import styled from 'styled-components'
import React from 'react'
import { RadioGroupCP, RadioOptionProps } from 'common/components/radio-group/RadioGroupCP'
import { AnalysisTypeEnum } from 'modules/analysis/enums/AnalysisTypeEnum'
import { AnalysisUtils } from 'modules/analysis/utils/AnalysisUtils'

interface IAnalysisTypeFilterCPProps {
    onChangeType: (type: AnalysisTypeEnum) => void
    type?: AnalysisTypeEnum
}

export function AnalysisTypeFilterCP(props: IAnalysisTypeFilterCPProps): JSX.Element {

    function getOptions(): RadioOptionProps[] {
        return Object.values(AnalysisTypeEnum).map(type => ({
            value: type,
            content: AnalysisUtils.translateType(type)
        }))
    }

    return (
        <WrapperSCP>
            <RadioGroupCP
                type={'button'}
                options={getOptions()}
                selected={props.type}
                onChange={props.onChangeType}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 200px;
    margin: 10px;
    @media(max-width: 768px){
        min-width: 95%;
    }
`

