/* eslint-disable react/jsx-props-no-spreading */
import { useSelector } from 'react-redux'
import { AppRoutes } from 'modules/app/AppRoutes'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { PageTitleCP } from 'common/components/page-title/PageTitleCP'
import { Error404Screen } from 'common/screens/Error404Screen'
import { BatchPresentationRoutes } from 'modules/batch-presentation/BatchPresentationRoutes'
import { CompanyLoginScreen } from 'modules/auth/screens/CompanyLoginScreen'
import { SchemaVerifier } from '../components/schema-verifier/SchemaVerifierCP'
import { SchemaDataTP } from 'common/redux/ReduxTypes'

interface IPublicRouterProps {

}
export function SchemaPublicRouter(props: IPublicRouterProps): JSX.Element | null {
    const history = useHistory()
    const location = window.location.pathname
    const schemaData: SchemaDataTP | null = useSelector((state: IReduxState) => _.get(state, 'schemaData'))
    const [schemaBasename, setSchemaBasename] = useState<string>()
    useEffect(redirectSchema, [schemaData])

    function redirectSchema(): void {
        if (!!schemaData) {

            setSchemaBasename(schemaData.domain.substring(schemaData.domain.lastIndexOf('/') + 1))

            if (location.includes('coffees'))
                return


            if (location.lastIndexOf('/') <= 1) {
                if (schemaData.domain.includes(location))
                    return history.push(AuthRoutes.LOGIN)

                history.push(`${schemaData.domain.substring(schemaData.domain.lastIndexOf('/') + 1)}/${AuthRoutes.LOGIN}`)
            }
        }
    }

    if (!schemaData)
        return null

    return (
        <Switch>
            <Route
                path={AuthRoutes.LOGIN}
                render={(renderProps) => (
                    <>
                        <PageTitleCP pageName={'Login'} />
                        <CompanyLoginScreen {...renderProps} />
                    </>
                )}
            />

            <Route path={BatchPresentationRoutes.ROOT} component={BatchPresentationRoutes} />
            <Route
                path={AppRoutes.ROOT}
                render={(renderProps) => (<SchemaVerifier {...renderProps} />)}
            />
            <Route component={Error404Screen} />
        </Switch>
    )
}
