import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PopConfirmCP } from 'common/components/pop-confirm/PopConfirmCP'
import React from 'react'
import styled from 'styled-components'

interface IFieldActionsICPProps{
    onDelete: () => void
    onEdit: () => void
    loading: boolean
}

export function FieldActionsICP(props: IFieldActionsICPProps): JSX.Element {
    return (
        <WrapperSCP>
            <ButtonCP
                onClick={props.onEdit}
                type={'primary'}
                icon={<FontAwsomeIconCP icon={faPencilAlt} />}
                tooltip={'Editar'}
            />

            <PopConfirmCP title={'Deseja mesmo remover este talhão?'} onConfirm={props.onDelete}>
                <ButtonCP
                    type={'primary'}
                    icon={<FontAwsomeIconCP icon={faTrash} />}
                    tooltip={'Excluir'}
                    loading={props.loading}
                />
            </PopConfirmCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 5px;
        @media(max-width: 768px){
            max-width: 80px;
        }
`