import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'
import { BatchMainInfoCP } from 'modules/batch-landing-page/components/batch-main-info/BatchMainInfoCP'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { useRequest } from 'common/request-helper/UseRequest'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'
import { useParams } from 'react-router-dom'
import { BatchTraceabilityContentCP } from 'modules/batch-landing-page/components/batch-traceability-content/BatchTraceabilityContentCP'
import { LoggedUserDataTP } from 'common/redux/ReduxTypes'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import styled from 'styled-components'

type RouteParamsTP = { batchCode: string }
const DEFAULT_BREAKPOINT = 767
const WINDOW_WIDTH = window.innerWidth

export function BatchLandingPageScreen(): JSX.Element {
    const loggedUserData: LoggedUserDataTP | null = useSelector((state: IReduxState) => _.get(state, 'loggedUser'))
    const [siderWidth, setSiderWidth] = useState<string>()
    const [batchCode, setBatchCode] = useState<number>()
    const [batch, setBatch] = useState<IBatchResponseDTO>()
    const request = useRequest<IBatchResponseDTO>()

    const routeParams = useParams<RouteParamsTP>()

    useLayoutEffect(initLayout, [])
    useEffect(() => setBatchCode(+routeParams.batchCode), [routeParams])
    useEffect(getDetails, [loggedUserData, batchCode])
    useEffect(onRequestChange, [request.awaiting])

    function getDetails(): void {
        if (batchCode && loggedUserData)
            request.runRequest(BatchRequests.get(batchCode))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar lote')
        }

        setBatch(request.returnData)
    }

    function initLayout(): void {
        if (WINDOW_WIDTH >= DEFAULT_BREAKPOINT)
            setSiderWidth('40%')
        else
            setSiderWidth('100%')
    }

    if (!batch)
        return <LoadingOverlayCP show={true} />

    return (
        <WrapperSCP>
            <LayoutCP
                sider={
                    <SiderSCP width={siderWidth}>
                        <BatchMainInfoCP
                            fields={batch.fields}
                        />
                    </SiderSCP>
                }
                content={
                    <ContentWrapperSCP>
                        <BatchTraceabilityContentCP
                            batch={batch}
                        />
                    </ContentWrapperSCP>

                }
            />
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
   @media(max-width: 768px){
    .ant-layout.ant-layout-has-sider{
        flex-direction: column;
    }   
   } 
     
`
const SiderSCP = styled(SiderCP)`
   &.ant-layout-sider{
        height: auto;
    }

    @media(max-width: 768px){
           &.ant-layout-sider{
                height: 100%;
            }
            
    }
   
`

const ContentWrapperSCP = styled.div`
    width: 60%;
    padding: 0 10px;
    @media(max-width: 786px){
        width: 100%;
    }
`