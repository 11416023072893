import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { PropertyDrawerCP } from 'modules/property/components/property-drawer/PropertyDrawerCP'
import { PropertyDetailsHeaderICP } from 'modules/property/components/property-details/inner/PropertyDetailsHeaderICP'
import { PropertyInfoTabsICP } from 'modules/property/components/property-details/inner/PropertyInfoTabsICP'
import React, { CSSProperties, useState } from 'react'
import { useSelector } from 'react-redux'
import { IFieldResponseDTO } from 'modules/field/services/dtos/responses/IFieldResponseDTO'
import styled from 'styled-components'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faFileAlt, faLeaf, faImages, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { IFabSubActions } from 'common/components/float-action-button/inner/IFabSubActions'
import { IPropertyResponseDTO } from 'modules/property/services/dtos/responses/IPropertyResponseDTO'
import { FieldDrawerCP } from 'modules/field/components/field-drawer/FieldDrawerCP'
import { FileTP, PropertyFileUploadDrawerCP } from 'modules/property/components/property-file-upload-drawer/PropertyFileUploadDrawerCP'
import { PropertyActionsDropdownButtonCP } from './property-actions-dropdown-button/PropertyActionsDropdownButtonCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'

export function PropertyDetailsCP(): JSX.Element {
    const property: IPropertyResponseDTO = useSelector((state: IReduxState) => state.property)
    const screenSize = useScreenSize()
    const [editProperty, setEditProperty] = useState<IPropertyResponseDTO | undefined>(property)
    const [showPropertyForm, setShowPropertyForm] = useState(false)
    const [showNewField, setShowNewField] = useState(false)
    const [field, setField] = useState<IFieldResponseDTO>()
    const [showNewFile, setShowNewFile] = useState(false)
    const [fileType, setFileType] = useState<FileTP>()
    const [shouldReload, setShouldReload] = useState(0)
    const [activeKey, setActiveKey] = useState<string>('description')

    const actionBtnStyle: CSSProperties = screenSize.smd ?
        {
            position: 'absolute',
            top: '32em',
            left: '2em',
        }
        :
        {
            position: 'absolute',
            top: '17em',
            right: '3em'
        }

    if (!property) {
        return (
            <LoadingCard>
                <LoadingOverlayCP show={true} />
            </LoadingCard>
        )
    }

    return (
        <WrapperSCP data-simplebar={true}>
            <PropertyDetailsHeaderICP
                editInfo={() => {
                    setEditProperty(property)
                    setShowPropertyForm(true)
                }}
                property={property}
            />
            <PropertyActionsDropdownButtonCP
                setActiveKey={setActiveKey}
                setShouldReload={setShouldReload}
                activeKey={activeKey}
                style={actionBtnStyle}
            />
            <PropertyInfoTabsICP reloadData={shouldReload} activeKey={activeKey} setActiveKey={setActiveKey} editField={setField} />

            <PropertyDrawerCP
                property={editProperty}
                onSuccess={() => setShowPropertyForm(false)}
                visible={showPropertyForm}
                onClose={() => setShowPropertyForm(false)}
            />

            <FieldDrawerCP
                visible={showNewField}
                onClose={() => setShowNewField(false)}
                propertyCode={property.code}
                field={field}
                reloadList={() => setShouldReload(Date.now())}
            />
            <PropertyFileUploadDrawerCP
                open={showNewFile}
                type={fileType!}
                closeDrawer={() => setShowNewFile(false)}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    position: relative;
    height: 82vh;
    overflow: overlay;
    -webkit-overflow-scrolling: touch;
    border-radius: 10px;
       
`
const LoadingCard = styled.div`
    position: relative;
    overflow: overlay;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    border-radius: 10px;
    min-height: 100vh;
`