import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import styled from 'styled-components'
import React from 'react'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import moment from 'moment-timezone'
import _ from 'lodash'
interface ISeasonSelectorCPProps<FModelTP extends FormModelValidator> {
    formStateManager?: IFormStateManager<FModelTP>
    required?: boolean
    onChange?: (season: number) => void
    onSearch?: (season: string) => void
    label?: string
    value?: number
    allowClear?: boolean
    defaultValue?: number
    loading?: boolean
}

export function SeasonSelectorCP<FModelTP extends FormModelValidator = any>(props: ISeasonSelectorCPProps<FModelTP>): JSX.Element {

    function getOptions(): SelectOptionTP[] {
        const current = moment().year()
        const min = +current - 50
        const max = +current + 5

        const options: SelectOptionTP[] = []

        for (let i = min; i < max; i++) {

            options.push({
                label: `${i}`,
                value: i
            })
        }

        return _.orderBy(options, ['value'], ['desc'])
    }

    return (
        <WrapperSCP>
            <SelectCP
                showSearch={true}
                options={getOptions()}
                label={props.label}
                allowClear={props.allowClear}
                formStateManager={props.formStateManager}
                fieldName={'season'}
                required={props.required}
                placeholder={'Safra'}
                onChange={props.onChange}
                onSearch={props.onSearch}
                value={props.value ?? props.defaultValue}
                loading={props.loading}
            />
        </WrapperSCP>

    )
}
const WrapperSCP = styled.div`
  
   

`
