import { IsOptional, IsBoolean } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IProductUsageReqDTO } from 'modules/maneuver/services/dtos/requests/IProductUsageReqDTO'
import { ProductUtils } from '../../../../maneuver/utils/ProductUtils'

export class ProductUsageFormValidator extends FormModelValidator {
    @IsOptional()
    code?: number

    @IsNotEmpty()
    productCode: number

    @IsNotEmpty()
    quantity: number

    @IsOptional()
    implementCode?: number

    @IsOptional()
    @IsBoolean()
    excludeFuel?: boolean

    constructor(dto?: IProductUsageReqDTO) {

        super()
        if (dto) {
            this.code = dto.code
            this.productCode = dto.productCode
            this.quantity = dto.quantity
            this.implementCode = dto.implementCode
            this.excludeFuel = dto.excludeFuel
        }

    }
}
