import { Skeleton } from 'antd'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { HarvestFormValidator } from 'modules/harvest/components/harvest-form/inner/HarvestFormValidator'
import { HarvestTypeSelectorCP } from 'modules/harvest/components/harvest-type-selector/HarvestTypeSelectorCP'
import { HarvestTypeEnum } from 'modules/harvest/enums/HarvestTypeEnum'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import { LogbookFormValidator } from 'modules/logbook/components/logbook-form/inner/LogbookFormValidator'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IHarvestFormCPProps {
    fieldCode: number
    isUpdate?: boolean
    harvest?: IHarvestResponseDTO
    formStateManager: IFormStateManager<LogbookFormValidator>
}

export function HarvestFormCP(props: IHarvestFormCPProps): JSX.Element {

    const screenSize = useScreenSize()

    const [totalLiters, setTotalLiters] = useState<number>(0)
    const [hvstCode, setHvstCode] = useState<number>()
    const [loadedValues, setLoadedValues] = useState<boolean>(false)

    const [formValidator, setFormValidator] = useState<HarvestFormValidator>()
    const formStateManager = useFormStateManager(formValidator)

    useEffect(init, [props.harvest])

    function init(): void {

        if (!!props.harvest) {
            setFormValidator(new HarvestFormValidator(props.harvest))
            setValidator(props.harvest)
            setTotalLiters(props.harvest.liters)
            setHvstCode(props.harvest.code)
        }

        if (!props.isUpdate)
            setFormValidator(new HarvestFormValidator())
        setLoadedValues(true)
    }

    async function setValidator(hvt: IHarvestResponseDTO): Promise<void> {

        formStateManager.setConsiderAllErrors(true)

        if (!await formStateManager.validate() || !hvt)
            return

        props.formStateManager.changeFieldValue('harvest', {
            code: hvstCode,
            liters: +hvt.liters,
            type: hvt.type,
            typeOther: hvt.typeOther,
            description: hvt.description,
        })

    }

    if (!loadedValues)
        return (<Skeleton active={true} />)

    return (
        <WrapperSCP>
            <TwoColWrapperSCP>
                <HarvestTypeSelectorCP<HarvestFormValidator>
                    label={'Tipo:'}
                    formStateManager={formStateManager}
                    onSelect={async (type) => {
                        const formValues = formStateManager.getFormValues()
                        if (!type)
                            return
                        await setValidator({
                            ...formValues,
                            type,
                            code: hvstCode ?? 0,
                        } as IHarvestResponseDTO)
                    }}
                    fieldName={'type'}
                />
               
            </TwoColWrapperSCP>
            <TextAreaCP
                label={'Descrição'}
                fieldName={'description'}
                formStateManager={formStateManager}
                required={false}
                fontSize={screenSize.smd ? 'large' : 'normal'}
                loading={false}
            />
            <TwoColWrapperSCP>
                <InputCP
                    label={'Litragem total:'}
                    placeholder={'Litragem total'}
                    fieldName={'liters'}
                    type={'number'}
                    formStateManager={formStateManager}
                    onChange={async (liters) => {
                        const formValues = formStateManager.getFormValues()
                        await setValidator({
                            ...formValues,
                            code: hvstCode ?? 0,
                            liters,
                        } as IHarvestResponseDTO)

                    }}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    loading={false}
                    icon={<span> litros </span>}
                />
            </TwoColWrapperSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
   display: grid;
   grid-template-columns: 1fr;
   row-gap: 10px;
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    column-gap: 35px;
    align-items: end;
    .ant-btn{
        margin-bottom: 5px;
        width: 100%;
    }
    @media(max-width:768px ){
        column-gap: 10px;
    }
`