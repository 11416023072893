import { StructureScreen } from 'modules/structure/screens/StructureScreen'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { UserPermissionsEnum } from 'common/enums/UserPermissionsEnum'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'

StructureRoutes.ROOT = '/estruturas'

export function StructureRoutes(): JSX.Element {
    return (
        <AccessControlCP module={SystemModulesEnum.POST_HARVEST}>
            <Switch>
                <Route path={StructureRoutes.ROOT} exact={true} component={StructureScreen} />
            </Switch>
        </AccessControlCP>
    )
}
