import { ValidationError } from 'class-validator'
import * as _ from 'lodash'
import { FormStateManager } from 'common/form-state-manager/classes/FormStateManager'
import { FormValuesMapTP } from 'common/form-state-manager/FormStateTypes'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { IFormModelValidator } from 'common/form-state-manager/interfaces/IFormModelValidator'
import { useEffect, useState } from 'react'

type HookStateTP<FModelTP extends IFormModelValidator> = { formState: FormStateManager<FModelTP> }

/**
 * HOOK
 * Expoe gerenciador de estado de formularios para uso em componentes funcionais.
 *
 * @author hjcostabr
 */
export function useFormStateManager<FModelTP extends IFormModelValidator>(formModel?: FModelTP): IFormStateManager<FModelTP> {

    const [hookState, setHookState] = useState<HookStateTP<FModelTP>>()

    useEffect(onValidatorSet, [formModel])

    function onValidatorSet(): void {
        if (!!formModel)
            setHookState({ formState: new FormStateManager<FModelTP>(formModel) })
    }

    const changeFieldValue = (fieldName: keyof FModelTP, value: any): void => {
        if (!hookState)
            return
        const { formState } = hookState
        formState.changeFieldValue(fieldName as any, value)
        setHookState({ formState })
    }

    const validateForm = async (): Promise<boolean> => {
        if (!hookState)
            return false
        const { formState } = hookState
        const isValid = await formState.validate()
        setHookState({ formState })
        return isValid
    }

    const getFieldError = (fieldName: keyof FModelTP): ValidationError | undefined => {
        if (!hookState)
            return
        const { formState } = hookState
        const error = formState.getFieldError(fieldName as any)
        setHookState({ formState })
        return error
    }

    const getFieldValue = (fieldName: keyof FModelTP): any => {
        if (!hookState)
            return
        const { formState } = hookState
        return formState.getFieldValue(fieldName as any)
    }

    const getFormValues = (): FormValuesMapTP<FModelTP> | undefined => {
        if (!hookState)
            return
        const { formState } = hookState
        return formState.getFormValues()
    }

    const reset = async (replacingData?: FModelTP): Promise<void> => {
        if (!hookState)
            return
        const { formState } = hookState
        await formState.reset(replacingData)
        setHookState({ formState })
    }

    const setConsiderAllErrors = (consider: boolean): void => {
        if (!hookState)
            return
        const { formState } = hookState
        formState.considerAllErrors = consider
        setHookState({ formState })
    }

    const setFieldDirty = (fieldName: keyof FModelTP): void => {
        if (!hookState)
            return
        const { formState } = hookState
        formState.setFieldDirty(fieldName)
        setHookState({ formState })
    }

    const debugFieldValues = (): void => {
        if (!!hookState)
            hookState.formState.debugFieldValues()
    }

    const debugErrors = (): void => {
        if (!!hookState)
            hookState.formState.debugErrors()
    }

    return {
        isValid: _.get(hookState, 'formState.isValid', false),
        isDirty: _.get(hookState, 'formState.isDirty', false),
        validationsCount: _.get(hookState, 'formState.validationsCount', 0),
        considerAllErrors: _.get(hookState, 'formState.considerAllErrors', false),
        changeFieldValue,
        getFieldValue,
        validate: validateForm,
        getFormValues,
        getFieldError,
        reset,
        setConsiderAllErrors,
        setFieldDirty,
        debugFieldValues,
        debugErrors
    }
}
