import { IsString, IsIn, ValidateIf, IsNumber, IsOptional, IsArray } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { BatchMethodEnum } from 'modules/batch/enums/BatchProcessEnum'
import { ISaveBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISaveBatchRequestDTO'
import { ISaveHarvestBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISaveHarvestBatchRequestDTO'
import { IHarvestLitersPerMaturation } from 'modules/harvest/interfaces/IHarvestLitersPerMaturation'

export class BatchFormValidator extends FormModelValidator {
    @IsNotEmpty()
    @IsString()
    @IsIn(Object.values(BatchMethodEnum))
    method: BatchMethodEnum

    @IsNotEmpty()
    @IsString()
    date: string

    @IsNotEmpty()
    @IsNumber()
    liters: number

    @IsOptional()
    litersPerMaturation?: IHarvestLitersPerMaturation

    @IsNotEmpty()
    season?: number

    @IsNotEmpty()
    @IsArray()
    harvests: ISaveHarvestBatchRequestDTO[]

    @IsNotEmpty()
    @IsArray()
    fieldCodes: number[]

    @IsOptional()
    @IsString()
    farmId?: string

    constructor(dto?: ISaveBatchRequestDTO) {
        super(dto)
        Object.assign(this, dto)

    }
}