import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DescriptionSectionCP } from 'common/components/description-section/DescriptionSectionCP'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { ISensoryAnalysisResDTO } from 'modules/analysis/services/dtos/responses/ISensoryAnalysisResDTO'
import { SystemConfig } from 'config/SystemConfig'
import { SensoryAnalysesResumeContentCP } from 'modules/batch-landing-page/components/sensory-analyses-resume/SensoryAnalysesResumeContentCP'
import { AnalysesScoreCP } from 'modules/batch/components/batch-analyses/inner/AnalysesScoreCP'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { FieldUtils } from '../../../field/utils/FieldUtils'
import { IconInfoGridCP } from '../../../../common/components/icon-info-grid/IconInfoGridCP'
import { BatchProcessAlbumDrawerCP } from 'modules/batch/components/batch-process-album-drawer/BatchProcessAlbumDrawerCP'
import _ from 'lodash'
import { BatchProcessDetailsCP } from 'modules/batch/components/batch-process-details.tsx/BatchProcessDetailsCP'
import { BatchOriginDetailsEntryICP } from 'modules/batch/components/batch-process-details-content/inner/BatchOriginDetailsEntryICP'
import { BatchProcessFullAlbumCP } from 'modules/batch-landing-page/batch-process-full-album/BatchProcessFullAlbumCP'
import { PropertiesAlbumCP } from 'modules/batch-landing-page/components/properties-album/PropertesAlbumCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { MergeTreeICP } from 'modules/batch/components/batch-tree-drawer/inner/MergeTreeICP'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IBatchProcessResDTO } from 'modules/batch/services/dtos/responses/IBatchProcessResDTO'
import { LogbookEntryICP } from 'modules/logbook/components/logbook-entries-list/inner/LogbookEntryICP'
import { LogbookEntriesListCP } from 'modules/logbook/components/logbook-entries-list/LogbookEntriesListCP'

interface IBatchTraceabilityContentCPProps {
    batch: IBatchResponseDTO
}
export interface IGeneralFieldData {
    code: number
    bags: number
    score: number
}
export function BatchTraceabilityContentCP(props: IBatchTraceabilityContentCPProps): JSX.Element {
    const [processCode, setProcessCode] = useState<number>()
    const [albumDrawerVisible, setAlbumDrawerVisible] = useState<boolean>(false)

    const [properties, setProperties] = useState<number[]>([])
    const [processList, setProcessList] = useState<IBatchProcessResDTO[]>([])
    const [sensoryAnalyses, setSensoryAnalyses] = useState<ISensoryAnalysisResDTO[]>([])
    const [parents, setParents] = useState<IBatchResponseDTO[]>([])

    const getProcessessReq = useRequest<IBatchResponseDTO>()
    useEffect(onProcessReqChange, [getProcessessReq.awaiting])

    const getAnalysesReq = useRequest<IBatchResponseDTO>()
    useEffect(onAnalysesRequestChange, [getAnalysesReq.awaiting])

    const getParentsReq = useRequest<IBatchResponseDTO>()
    useEffect(onParentsReqChange, [getParentsReq.awaiting])

    useEffect(getDetails, [props.batch])

    function getDetails(): void {
        setProperties(props.batch.fields.map(fie => +fie.propertyCode))
        if (!!props.batch.code) {
            getProcessessReq.runRequest(BatchRequests.getProcesses(props.batch.code))
            getAnalysesReq.runRequest(BatchRequests.getAnalyses(props.batch.code))
            getParentsReq.runRequest(BatchRequests.getOriginData(props.batch.code))
        }
    }

    function onProcessReqChange(): void {

        if (getProcessessReq.awaiting || !getProcessessReq.tried)
            return

        if (!getProcessessReq.success || !getProcessessReq.returnData) {
            if (getProcessessReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getProcessessReq.returnData, getProcessessReq.error)
            return RequestUtils.showDefaultErrorNotification(getProcessessReq.error, 'Some error occurred. Please, reload the page.')
        }

        setProcessList(_.orderBy(getProcessessReq.returnData.processes, ['endDate', 'startDate'], ['desc', 'desc']))
    }

    function onAnalysesRequestChange(): void {

        if (getAnalysesReq.awaiting || !getAnalysesReq.tried)
            return

        if (!getAnalysesReq.success || !getAnalysesReq.returnData) {
            if (getAnalysesReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getAnalysesReq.returnData, getAnalysesReq.error)
            return RequestUtils.showDefaultErrorNotification(getAnalysesReq.error, 'Some error occurred. Please, reload the page.')
        }
        if (!!getAnalysesReq.returnData.sensoryAnalysis)
            setSensoryAnalyses(getAnalysesReq.returnData.sensoryAnalysis)
    }

    function onParentsReqChange(): void {

        if (getParentsReq.awaiting || !getParentsReq.tried)
            return

        if (!getParentsReq.success || !getParentsReq.returnData) {
            if (getParentsReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getParentsReq.returnData, getParentsReq.error)
            return RequestUtils.showDefaultErrorNotification(getParentsReq.error, 'Some error occurred. Please, reload the page.')
        }
        if (!!getParentsReq.returnData.parents)
            setParents(getParentsReq.returnData.parents)
    }

    const data: IGeneralFieldData = {
        code: props.batch.code,
        bags: props.batch.bags ?? props.batch.liters,
        score: calculateAverageScore()
    }

    function calculateAverageScore(): number {
        if (!sensoryAnalyses.length)
            return 0
        const totalAnalyses = sensoryAnalyses.length === 0 ? 1 : sensoryAnalyses.length
        const average = sensoryAnalyses.reduce((sum: number, analyse: ISensoryAnalysisResDTO) => {
            return sum + (analyse.score ?? 0)
        }, 0) / totalAnalyses
        return average
    }

    return (
        <ContentWrapperSCP>
            <LogoWrapperSCP>
                <img src={`${SystemConfig.getInstance().uiBaseUrl}/assets/flowins-logo.png`} width={150} />
            </LogoWrapperSCP>
            <DescriptionSectionCP
                mainColor={CostumizedThemeConfig.primaryColor}
                extra={
                    <IconInfoGridCP<IGeneralFieldData>
                        gridColumns={3}
                        mainColor={CostumizedThemeConfig.primaryColor}
                        sourceData={data}
                        columns={FieldUtils.getGeneralDataIconColumnsConfig(!!props.batch.bags)}
                    />
                }
            />

            <DescriptionSectionCP
                title={'Process'}
                upperTitle={'Timeline'}
                mainColor={CostumizedThemeConfig.primaryColor}
                extra={
                    <LogbookEntriesListCP<IBatchProcessResDTO>
                        source={processList}
                        style={{ marginBottom: 0 }}
                        renderItem={(item: IBatchProcessResDTO) => (
                            <EntryWrapperSCP>
                                <LogbookEntryICP
                                    date={item.startDate}
                                    //isLastOne={!!processList.length && item.code === processList[processList.length - 1].code}
                                    language={LanguageEnum.EN}
                                    content={<BatchProcessDetailsCP process={item} language={LanguageEnum.EN} />}
                                />

                                {
                                    !!processList.length &&
                                    item.code === processList[processList.length - 1].code &&
                                    !!props.batch &&
                                    <BatchOriginDetailsEntryICP
                                        batchCode={props.batch.code}
                                        language={LanguageEnum.EN}
                                    />
                                }
                            </EntryWrapperSCP>

                        )}
                    />

                }
            />
            <BatchProcessFullAlbumCP batchCode={props.batch.code} />
            {
                sensoryAnalyses.length ?
                    <DescriptionSectionCP
                        title={'Analyses'}
                        upperTitle={'Sensory'}
                        mainColor={CostumizedThemeConfig.primaryColor}
                        extra={
                            <div>
                                {sensoryAnalyses.map((analyse: ISensoryAnalysisResDTO) => (
                                    <AnalysesWrapperSCP key={analyse.code}>
                                        <AnalysesScoreCP
                                            score={calculateAverageScore()}
                                            language={LanguageEnum.EN}
                                        />
                                        <SensoryAnalysesResumeContentCP
                                            item={analyse as any}
                                            language={LanguageEnum.EN}
                                        />
                                    </AnalysesWrapperSCP>
                                ))}
                            </div>
                        }
                    />
                    :
                    <></>
            }
            {

                !!parents.length &&
                <DescriptionSectionCP
                    title={'Tree'}
                    upperTitle={'Origin'}
                    mainColor={CostumizedThemeConfig.primaryColor}
                    extra={
                        <MergeTreeICP
                            batch={props.batch}
                            parents={parents}
                            isExternalPage={true}
                        />
                    }
                />
            }

            {
                !!properties.length &&
                <DescriptionSectionCP
                    title={'Photos'}
                    upperTitle={'Property'}
                    mainColor={CostumizedThemeConfig.primaryColor}
                    extra={
                        <PropertiesAlbumCP propertyCodes={properties} />
                    }
                />
            }

            {
                !!props.batch && !!processCode &&
                <BatchProcessAlbumDrawerCP
                    show={albumDrawerVisible}
                    processCode={processCode}
                    batchCode={props.batch.code}
                    onCancel={() => setAlbumDrawerVisible(false)}
                    isExternalPage={true}
                />
            }

        </ContentWrapperSCP>
    )
}
const ContentWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 30px 10px;
    background: #fff;
`
const LogoWrapperSCP = styled.div`
    width: 100%;
    text-align: center;
`

const AnalysesWrapperSCP = styled.div`
   
`
const EntryWrapperSCP = styled.div``
const WrapperSCP = styled.div`
margin-top: 15px;
background: #fff;
min-height: 100px;
border-radius: 10px;
position: relative;
padding: 10px;
`

const ContentInfoSCP = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
    .ant-typography{
    font-size: 12px;
}
@media(max-width: 768px) {
    grid-template-columns: 1fr;
}
`

const RowSCP = styled.div`
display: flex;
flex-wrap: wrap;
    .ant-typography{
    font-size: 12px;
}

`
const ExtraWrapperSCP = styled.div`


    `

const ActionsWrapperSCP = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end;
margin-bottom: 20px;
.ant-btn{
    width: 100%;
    justify-content: center;
}
@media(max-width: 768px){

     .ant-btn {            
        margin: 4px!important;
        width: 100%;
    }
}
`
const MainSCP = styled.div`
width: 100%;
margin: 10px;
@media(max-width: 768px){
    margin: 0;
}
`