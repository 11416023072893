import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { ProductTypeSelectorCP } from 'modules/maneuver/components/product-type-selector/ProductTypeSelectorCP'
import { ISearchProductRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchProductRequestDTO'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IProductFiltersDrawerCPProps {
    show: boolean
    onClose: () => void
    setFilters: (filters: ISearchProductRequestDTO) => void
    filters?: ISearchProductRequestDTO
}

export function ProductFiltersDrawerCP(props: IProductFiltersDrawerCPProps): JSX.Element {
    const type = !!props.filters && !!props.filters.types?.length ? props.filters.types[0] : undefined
    const [filters, setFilters] = useState<ISearchProductRequestDTO>()
    const screenSize = useScreenSize()
    useEffect(setFilterValues, [props.filters])

    function setFilterValues(): void {
        if (!props.filters)
            return
        setFilters(props.filters)
    }
    return (
        <DrawerCP
            title={'Mais filtros'}
            visible={props.show}
            width={'35%'}
            onClose={props.onClose}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'primary'}
                        wrapperStyle={{ width: '100%' }}
                        onClick={() => {
                            if (!!filters)
                                props.setFilters(filters)
                            props.onClose()
                        }}
                    >
                        Filtrar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >
            <WrapperSCP>
                <ProductTypeSelectorCP
                    value={type}
                    label={'Tipo'}
                    onSelect={(type) => setFilters({ ...props.filters, types: [type] })}
                />
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`

const ButtonWrapperSCP = styled.div`
     display: flex;
    justify-content: center;
    .ant-btn {
        width: 100%;
        justify-content: center;
    }
`