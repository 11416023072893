import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'

interface IListHeaderICPProps {
    gridColumns: string
    gap?: string
    labels: string[]
    mobile?: boolean
    noPadding?: boolean
}

export function ListHeaderICP(props: IListHeaderICPProps): JSX.Element {

    return (
        <WrapperSCP style={{ gridTemplateColumns: props.gridColumns, columnGap: props.gap, padding: props.noPadding ? '0px' : '10px' }}>
            {
                props.labels.map(label => (
                    <TextCP key={label} text={label} />
                ))
            }

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    align-items: center;
  
    .ant-typography {
        font-weight: 600;
        font-size: 11px;
    }

    @media(max-width: 768px){
         display: none;
        }
`
