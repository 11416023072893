import { IsOptional } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsEmail } from 'common/validation/decorators/IsEmail'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsString } from 'common/validation/decorators/IsString'
import { IUpdateUserReqDTO } from 'modules/person/services/dtos/requests/IUpdateUserReqDTO'

export class UpdateUserFormValidator extends FormModelValidator {

    @IsString()
    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    phone: string

    @IsEmail()
    @IsOptional()
    email: string

    constructor(dto?: IUpdateUserReqDTO) {
        super(dto)
        Object.assign(this, dto)

    }
}
