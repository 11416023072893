import { AvatarCP } from 'common/components/avatar/AvatarCP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { TextCP } from 'common/components/text/TextCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { IPersonResponseDTO } from 'modules/person/services/dtos/responses/IPersonResponseDTO'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { ButtonCP } from 'common/components/button/ButtonCP'
import moment from 'moment-timezone'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { ISaveInvoiceReqDTO } from 'modules/financial/services/dtos/request/ISaveInvoiceReqDTO'
import { MathUtils } from 'common/utils/MathUtils'
import { StatementPaymentDrawerCP } from 'modules/person/components/statement-payment-drawer/StatementPaymentDrawerCP'

interface IPersonResumeCardICPProps {
    personData: IPersonResponseDTO
    totalPaid: number
    totalToPay: number
    dateRange: {
        startDate: string,
        endDate: string,
    }
    itemsToPay: number[]
}

export function PersonResumeCardICP(props: IPersonResumeCardICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const valueStyle = { fontSize: `${screenSize.smd ? '15px' : '23px'}`, fontWeight: '600', lineHeight: '22px' }

    const [invoiceDrawerVisible, setInvoiceDrawerVisible] = useState<boolean>(false)
    const [invoiceData, setInvoiceData] = useState<ISaveInvoiceReqDTO>()

    useEffect(defineInvoiceData, [props.totalToPay, props.dateRange])

    function defineInvoiceData(): void {

        if (!props.totalToPay)
            return

        setInvoiceData({
            date: DateUtils.formatDate(moment()
                .format(), DateFormatEnum.BR_WITHOUT_TIME),
            description: `Pagamento a ${props.personData.name} - Referente às datas ${DateUtils.formatDate(props.dateRange.startDate, DateFormatEnum.BR_WITHOUT_TIME)} a ${DateUtils.formatDate(props.dateRange.endDate, DateFormatEnum.BR_WITHOUT_TIME)}
            | ${MaskUtils.applyCurrencyMask(props.totalToPay)}`,
            isCredit: false,
            isPaid: false,
            paymentDate: DateUtils.formatDate(moment()
                .add(5, 'days')
                .format(), DateFormatEnum.BR_WITHOUT_TIME),
            value: `${MathUtils.round(props.totalToPay)}`,
            season: +moment()
                .year(),
        })
    }

    return (
        <WrapperSCP>
            <CardHeaderICP
                highlight={<AvatarCP src={props.personData.picture} size={35} />}
                title={`${props.personData.name}`}
                itemCode={props.personData.code}
                subtitle={props.personData.occupation}
            />

            {!!props.totalPaid &&

                <ActionWppSCP>
                    <TotalSCP>
                        <TextCP text={'Total por período: '} style={{ fontSize: '12px' }} />
                        <TextCP text={MaskUtils.applyCurrencyMask(props.totalPaid)} style={valueStyle} />
                    </TotalSCP>
                    {
                        !!props.itemsToPay.length &&
                        <TotalSCP>
                            <TextCP text={'Total selecionado: '} style={{ fontSize: '12px' }} />
                            <TextCP text={MaskUtils.applyCurrencyMask(props.totalToPay)} style={valueStyle} />
                        </TotalSCP>
                    }
                    {
                        !!props.itemsToPay.length &&
                        <ButtonCP
                            type={'primary'}
                            onClick={() => setInvoiceDrawerVisible(true)}
                        >
                            Gerar pagamento
                        </ButtonCP>
                    }
                </ActionWppSCP>}
            {
                !!invoiceData &&
                <StatementPaymentDrawerCP
                    show={invoiceDrawerVisible}
                    onCancel={() => setInvoiceDrawerVisible(false)}
                    onSuccess={() => {
                        setInvoiceDrawerVisible(false)
                    }}
                    personCode={props.personData.code}
                    personName={props.personData.name}
                    itemsToConsolidate={props.itemsToPay}
                    previewData={invoiceData}
                />

            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.primaryColor};
  padding: 10px;
  margin-bottom: 8px;
  align-items: center;
  @media (max-width: 768px) {
    grid-template-columns: 1fr
  }
`
const TotalSCP = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: flex-end;
  color: ${props => props.theme.primaryColor};
`
const ActionWppSCP = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  row-gap: 10px;
  @media (max-width: 768px) {
    align-items: flex-start;
    row-gap: 5px;
    margin-top: 10px;
  }
`