import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'

import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { AlbumContentICP } from 'modules/batch/components/batch-process-album-drawer/inner/AlbumContentICP'
import { NewAlbumDrawerICP } from 'modules/batch/components/batch-process-album-drawer/inner/NewAlbumDrawerICP'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IProcessAlbumResponseDTO } from 'modules/batch/services/dtos/responses/IProcessAlbumResponseDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'

interface IBatchProcessAlbumDrawerCPProps {
    batchCode: number
    processCode: number
    isExternalPage?: boolean
    show: boolean
    onCancel: () => void
}

export function BatchProcessAlbumDrawerCP(props: IBatchProcessAlbumDrawerCPProps): JSX.Element {
    const [newAlbumDrawerVisible, setNewAlbumDrawerVisible] = useState<boolean>(false)
    const [albumList, setAlbumList] = useState<IProcessAlbumResponseDTO[]>([])

    const request = useRequest<IGenericListResponseDTO<IProcessAlbumResponseDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(getAlbumList, [props.batchCode, props.show, props.processCode])

    function getAlbumList(): void {
        if (!props.show || !props.batchCode || !props.processCode)
            return

        request.runRequest(BatchRequests.searchProcessAlbum(props.batchCode, props.processCode))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar álbums do processo!')
        }
        setAlbumList(_.orderBy(request.returnData.list, ['date'], ['desc']))

    }

    return (
        <DrawerCP
            title={`#${props.processCode} - ${!props.isExternalPage ? 'Process album' : 'Álbum do processo'}`}
            visible={props.show}
            width={'80%'}
            onClose={() => {
                props.onCancel()
            }}
            footer={
                !props.isExternalPage ?
                    <ButtonWrapperSCP>
                        <ButtonCP
                            type={'primary'}
                            onClick={() => setNewAlbumDrawerVisible(true)}
                            wrapperStyle={{ width: '100%' }}
                        >
                            Nova coleção
                        </ButtonCP>
                    </ButtonWrapperSCP>
                    : undefined
            }
        >

            <WrapperSCP>
                {
                    request.awaiting &&
                    <LoadingOverlayCP show={true} />
                }
                {
                    _.isEmpty(albumList) ?
                        <EmptyCP description={`${props.isExternalPage ? 'Sorry ): Nothing here' : 'Não há fotos para este processo'}`} />
                        :
                        albumList.map(album => (
                            <AlbumContentICP
                                key={`${album.code}_key`}
                                albumData={album}
                                processCode={props.processCode}
                                batchCode={props.batchCode}
                                loading={request.awaiting}
                                onUpdate={getAlbumList}
                                isExternalPage={props.isExternalPage}
                            />
                        ))
                }

            </WrapperSCP>
            <NewAlbumDrawerICP
                show={newAlbumDrawerVisible}
                processCode={props.processCode}
                batchCode={props.batchCode}
                onCancel={() => setNewAlbumDrawerVisible(false)}
                onSuccess={() => {
                    setNewAlbumDrawerVisible(false)
                    getAlbumList()
                }}
            />
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
    position: relative;
`
const GaleryWrapperSCP = styled.div`
    margin-top: 30px;
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`