import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/RequestManagerTypes'
import { SystemConfig } from 'config/SystemConfig'
import { ISaveHarvestRequestDTO } from './dtos/requests/ISaveHarvestRequestDTO'
import { ISearchHarvestRequestDTO } from './dtos/requests/ISearchHarvestRequestDTO'

export class HarvestRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/harvests`
    private constructor() { }

    static search = (dto?: ISearchHarvestRequestDTO): RequestConfigTP<ISearchHarvestRequestDTO> => ({
        url: `${HarvestRequests.baseApiUrl}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static save = (dto: ISaveHarvestRequestDTO): RequestConfigTP<ISaveHarvestRequestDTO> => ({
        url: `${HarvestRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static update = (code: number, dto: ISaveHarvestRequestDTO): RequestConfigTP<ISaveHarvestRequestDTO> => ({
        url: `${HarvestRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${HarvestRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })
}
