import _ from 'lodash'
import { IHarvestResponseDTO } from '../../harvest/services/dtos/responses/IHarvestResponseDTO'
import { HarvestTypeEnum } from '../../harvest/enums/HarvestTypeEnum'

export type BatchHarvestOrigin = {
    id: string,
    type: HarvestTypeEnum,
    code: number,
    harvestPlanningCode?: number,
    date: string,
}

export class BatchOriginUtils {
    static parseOriginHarvests(harvests: IHarvestResponseDTO[]): BatchHarvestOrigin[] {
        const parsedHarvests = harvests.map((harvest) => {
            return {
                id: `${harvest.harvestPlanningCode ?? harvest.code}${harvest.date}${harvest.type}`,
                type: harvest.type,
                code: harvest.code,
                harvestPlanningCode: harvest.harvestPlanningCode,
                date: harvest.date,
            }
        })

        return _.uniqBy(parsedHarvests, 'id')
    }
}
