import { TextCP } from 'common/components/text/TextCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { FinancialReportByFieldTableICP } from 'modules/report/components/financial-report-by-field/inner/FinancialReportByFieldTableICP'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'
import { IFinancialReportByFieldResDTO } from 'modules/report/services/dtos/responses/IFinancialReportByFieldResDTO'
import { IFinancialReportResDTO } from 'modules/report/services/dtos/responses/IFinancialReportResDTO'
import { ReportRequests } from 'modules/report/services/ReportRequests'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IFinancialReportByFieldCPProps {
    filters: ISearchReportReqDTO
}

export function FinancialReportByFieldCP(props: IFinancialReportByFieldCPProps): JSX.Element {

    const [report, setReport] = useState<IFinancialReportByFieldResDTO[]>()

    const getFinacialReportReq = useRequest<IGenericListResponseDTO<IFinancialReportByFieldResDTO>>()
    useEffect(onRequestChange, [getFinacialReportReq.awaiting])
    useEffect(getReport, [props.filters])

    function getReport(): void {
        if (!!props.filters.propertyCode) {
            getFinacialReportReq.runRequest(ReportRequests.getFinancialReportByField(props.filters))
        }
    }

    function onRequestChange(): void {
        if (getFinacialReportReq.awaiting || !getFinacialReportReq.tried)
            return

        if (!getFinacialReportReq.success || !getFinacialReportReq.returnData) {
            if (getFinacialReportReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getFinacialReportReq.returnData, getFinacialReportReq.error)
            return RequestUtils.showDefaultErrorNotification(getFinacialReportReq.error, 'Erro ao buscar dados!')
        }
        setReport(getFinacialReportReq.returnData.list)
    }


    return (
        <WrapperSCP>
            <HeaderSCP>
                <TextCP text={'Indicadores por talhão'} style={{ fontSize: '15px', fontWeight: 600 }} />

            </HeaderSCP>
            {
                !!report &&
                <FinancialReportByFieldTableICP reportData={report} />
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

`
const HeaderSCP = styled.div`
    display: flex;
    justify-content: flex-start;
    
    margin: 15px 0;
`