import React from 'react'
import styled from 'styled-components'
import { BatchDetailsHeaderICP } from 'modules/batch/components/batch-process-details-content/inner/BatchDetailsHeaderICP'
import { ProcessTimelineCP } from 'modules/batch/components/process-timeline/ProcessTimelineCP'

type RouteParamsTP = { code?: string }

interface IBatchProcessDetailsContentCPProps {
    setSaleUnavailable: (unavailable: boolean) => void
    setHasSales: (hasSales: boolean) => void
    hasSales: boolean
}

export function BatchProcessDetailsContentCP(props: IBatchProcessDetailsContentCPProps): JSX.Element {
    return (
        <WrapperSCP>
            <HeaderWrapperSCP>
                <BatchDetailsHeaderICP setSaleUnavailable={props.setSaleUnavailable} setHasSales={props.setHasSales} />
            </HeaderWrapperSCP>
            <ProcessTimelineCP hasSales={props.hasSales}/>
        </WrapperSCP>
    )
}

const ActionsWrapperSCP = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    .ant-btn{
        width: 100%;
        justify-content: center;
    }
    @media(max-width: 768px){
    
         .ant-btn {            
            margin: 4px!important;
            width: 100%;
        }
    }
`
const EntryWrapperSCP = styled.div``
const MainSCP = styled.div`
    width: 100%;
    margin: 10px;
    @media(max-width: 768px){
        margin: 0;
    }
`

const OtherActionsSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;    
    width: 100%;
    @media(max-width: 768px){
        column-gap: 5px;  
    }
`

const WrapperSCP = styled.div`
    border-radius: 10px;
    margin-bottom: 4em;
    max-height: 75vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;  
    }
`
const HeaderWrapperSCP = styled.div`
    background: #fff;
    min-height: 100px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    @media(max-width: 768px) {
        min-height: 62px;
        padding: 0 10px 7px 10px;
        margin-bottom: 10px;
    }
`

const POWrapperSCP = styled.div`
width: 100%;
display: flex;
align-items: flex-end;

`

const ContentWrapperSCP = styled.div`
margin-top: 15px;
background: #fff;
min-height: 100px;
border-radius: 10px;
position: relative;
padding: 10px;
`

const ContentInfoSCP = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
    .ant-typography{
    font-size: 12px;
}
@media(max-width: 768px) {
    grid-template-columns: 1fr;
}
`

const RowSCP = styled.div`
display: flex;
flex-wrap: wrap;
    .ant-typography{
    font-size: 12px;
}

`
const ExtraWrapperSCP = styled.div`


    `