import React from 'react'
import styled from 'styled-components'
import { IIconInfoGridColumnProps } from 'common/components/icon-info-grid/inner/IIconInfoGridColumnProps'
import { TitleCP } from 'common/components/title/TitleCP'

interface IIconInfoGridCPProps<T> {
    mainColor?: string
    gridColumns?: number
    columns: Array<IIconInfoGridColumnProps<T>>
    sourceData: T
}

/**
 * COMPONENTE
 * Wrapper genérico para exibição em grade de icones + informações básicas de um item
 * @author kiaravzm
 */
export function IconInfoGridCP<T>(props: IIconInfoGridCPProps<T>): JSX.Element {

    return (
        <IconInfoGridSCP gridColumns={props.gridColumns ?? props.columns.length} mainColor={props.mainColor ?? '#fff'}>
            {
                props.columns.map((column: IIconInfoGridColumnProps<T>) => {
                    return (
                        <GridItemSCP key={column.title} align={column.align}>
                            <IconWrapperSCP>
                                {column.icon}
                            </IconWrapperSCP>
                            <InfoWrapperSCP>
                                <TitleCP style={{ margin: 0, color: props.mainColor }}>
                                    {' '}
                                    {column.title}
                                </TitleCP>
                                {column.renderValue(props.sourceData)}
                            </InfoWrapperSCP>
                        </GridItemSCP>
                    )
                })
            }
        </IconInfoGridSCP>
    )
}
const IconInfoGridSCP = styled.div<{gridColumns: number, mainColor: string}>`
    color: ${props => props.mainColor};
    width: 100%;
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin: 12px 0;
    grid-template-columns: repeat(${props => props.gridColumns}, 1fr);

    @media(max-width: 767px){
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
`
const GridItemSCP = styled.div<{align?: string}>`
    display: flex;
    justify-content: ${props => props.align ?? 'flex-start'}
`
const IconWrapperSCP = styled.div`
    padding-right: 10px;
    font-size: 28px;
`
const InfoWrapperSCP = styled.div``
