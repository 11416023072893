import { Row } from 'antd'
import { TextCP } from 'common/components/text/TextCP'
import { MainFilterFieldsICP } from 'modules/batch/components/batch-list-filters/inner/MainFilterFieldsICP'
import { ISearchBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISearchBatchRequestDTO'
import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
interface IBatchListFiltersCPProps {
    setFilters: (filters: ISearchBatchRequestDTO) => void
    filters?: ISearchBatchRequestDTO
}

export function BatchListFiltersCP(props: IBatchListFiltersCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <Row>
                <TextCP text={'Filtre por: '} />
            </Row>
            <MainFilterFieldsICP
                onChangeSeason={(season) => props.setFilters({ ...props.filters, season, page: 1 })}
                onChangeBatchCodes={(batchCodes) => props.setFilters({ ...props.filters, batchCodes, page: 1 })}
                onChangeCode={_.debounce((code) => props.setFilters({ ...props.filters, batchId: +code, page: 1 }), 750)}
                onChangeFarmId={(farmId) => props.setFilters({ ...props.filters, farmId, page: 1 })}
                onChangeField={(fieldCode) => props.setFilters({ ...props.filters, fieldCode, page: 1 })}
                onChangeProperty={(propertyCode) => props.setFilters({ ...props.filters, propertyCode, page: 1 })}
                filters={props.filters}
                setFilters={props.setFilters}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
background: white;
border-radius: 10px;
padding: 10px;
display: flex;
flex-direction: column;
margin-bottom: 3px;
.ant-typography{
    font-size: 12px;   
}

`

const GridWrapperSCP = styled.div`
display: grid;
grid-template-columns: .8fr 1.5fr 1.8fr 3fr;
column-gap: 10px;
margin-top: 10px;
`