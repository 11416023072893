import React from 'react'
import { RegistrationWizard } from '../components/registration-wizard/RegistrationWizard'
import { RegistrationLayout } from '../components/RegistrationLayout'
import '../registration.less'

export function RegistrationScreen(): JSX.Element {
    return (
        <RegistrationLayout>
            <RegistrationWizard />
        </RegistrationLayout>
    )
}