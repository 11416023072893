import { Select } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { FormItemICP } from 'common/components/form-fields/inner/FormItemICP'
import { InputValueCallbackTP } from 'common/components/form-fields/inner/InputValueCallbackTP'
import { IFormItemCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import React from 'react'
import styled from 'styled-components'

const getOptionByValueAux = (options: SelectOptionTP[], singleValue?: number): SelectOptionTP | undefined =>
    options.find(option => (typeof option === 'object' && option.value === singleValue))

interface ISelectCPProps<FModelTP extends FormModelValidator = any> extends IFormItemCommonProps<FModelTP> {
    options: SelectOptionTP[]
    selectedOptions?: string[]
    mode?: 'multiple' | 'tags'
    isMultiple?: boolean
    loading?: boolean
    onClear?: () => void
    showSearch?: boolean
    allowClear?: boolean
    onDeselect?: () => void
    defaultOpen?: boolean
    onSearch?: InputValueCallbackTP<string>
    onClose?: () => void
    returnFullOption?: boolean
    notFoundContent?: string | JSX.Element
    placeholder?: string
    size?: SizeType
    removeIcon?: JSX.Element
    dropdownVisible?: boolean
}

/**
 * COMPONENTE
 * Input de multipla escolha para formularios.
 *
 * @author hjcostabr
 */
export function SelectCP<FModelTP extends FormModelValidator = any>(props: ISelectCPProps): JSX.Element {

    const showSearch = !!props.onSearch

    function renderOption(option: SelectOptionTP): JSX.Element {

        let key
        let value
        let label

        if (typeof option !== 'string') {
            value = option.value
            label = option.label || option.value
            key = option.key ?? option.value

        } else
            key = value = label = option

        return (
            <Select.Option key={key} value={value}>
                {label}
            </Select.Option>
        )
    }

    function handleChange(value: any): void {

        if (!props.onChange)
            return

        if (value === undefined)
            return props.onChange(undefined)

        const valueArr = Array.isArray(value) ? value : [value]
        const selectedOptions = !!props.returnFullOption ? valueArr.map(_value => getOptionByValue(_value)) : valueArr
        props.onChange(!!props.isMultiple ? selectedOptions : selectedOptions[0])
    }

    function getOptionByValue(singleValue?: number): SelectOptionTP | undefined {

        let option = getOptionByValueAux(props.options, singleValue)

        if (!option && !!props.selectedOptions)
            option = getOptionByValueAux(props.selectedOptions, singleValue)

        return option
    }

    return (
        <SelectWrapperSCP>
            <FormItemICP<FModelTP, IFormItemCommonProps>
                label={props.label}
                onChange={handleChange}
                fieldName={props.fieldName}
                formStateManager={props.formStateManager}
                required={props.required}
                width={props.width}
                value={props.value}
                errorMessage={props.errorMessage}
            >
                <Select
                    size={props.size}
                    disabled={props.disabled}
                    optionFilterProp={'children'}
                    placeholder={props.placeholder}
                    value={props.value}
                    allowClear={props.allowClear}
                    onDeselect={props.onDeselect}
                    mode={!!props.isMultiple ? 'multiple' : props.mode}
                    loading={props.loading}
                    // showSearch={props.showSearch ?? showSearch}
                    onClear={props.onClear}
                    showSearch={true}
                    defaultOpen={props.defaultOpen}
                    notFoundContent={props.notFoundContent ?? 'Nenhum item disponível'}
                    onSearch={props.onSearch}
                    // open={props.dropdownVisible}
                    // onDropdownVisibleChange={isVisible => {
                    //     if (!isVisible && !!props.onClose)
                    //         props.onClose()

                    // }}
                    removeIcon={props.removeIcon}
                >
                    {
                        props.options.map((opt: SelectOptionTP) => renderOption(opt))
                    }
                </Select>
            </FormItemICP>
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div`
    width: 100%;

    .ant-select-single:not(.ant-select-sm) .ant-select-selector, .ant-select-multiple:not(.ant-select-sm) .ant-select-selector {
        min-height: 40px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        border: none;
        background: #f5f5f5;
        border-radius: 5px;
    }
    

    .ant-select-single .ant-select-selector, .ant-select-multiple .ant-select-selector {
        width: 100%;
        border: none;
        background: ${props => props.theme.inputBg};
        padding: 7px 11px;
        border-radius: 5px;
        box-shadow: none !important;
        overflow: overlay;
        &:focus {
            box-shadow: none;
            border-bottom-width: 1px;
            border-bottom-color: ${props => props.theme.black};
        }
    }

    .has-error.ant-select:focus {
        box-shadow: none;
    }
`
