import { MenuMode } from 'antd/lib/menu'
import { MenuItemICP } from 'common/components/menu/inner/MenuItemICP'
import { MenuCP } from 'common/components/menu/MenuCP'
import { MENU_OPTIONS } from 'modules/app/components/user-main-menu/inner/MenuOptions'
import React, { useMemo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import styled from 'styled-components'
import { ReportActions } from 'modules/report/ReportActions'
import { ManeuverActions } from 'modules/maneuver/ManeuverActions'
import { ManeuverMenuCP } from 'modules/maneuver/components/maneuver-menu/ManeuverMenuCP'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'
import { ReportsMenuCP } from 'modules/report/components/reports-menu/ReportsMenuCP'
import _ from 'lodash'
import { CompanyRoutes } from 'modules/company/CompanyRoutes'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IAuthUserResponseDTO } from 'modules/auth/services/dtos/responses/IAuthUserResponseDTO'
import { IUserLoginResponseDTO } from '../../../auth/services/dtos/responses/IUserLoginResponseDTO'

interface IUserMainMenuCPProps extends RouteComponentProps {
    mode?: MenuMode
    isCollapsed?: boolean
    shouldCollapse?: (collapse: boolean) => void
}

export const UserMainMenuCP = withRouter((props: IUserMainMenuCPProps): JSX.Element => {
    const loggedUser: IUserLoginResponseDTO = useSelector((state: IReduxState) => state.loggedUser)
    const activeMenu = MENU_OPTIONS.find((opt) => isMenuActive(opt.route))

    const [menuOptions, setMenuOptions] = useState(MENU_OPTIONS)

    // Maneuver child menu
    const maneuverChildMenuOpened = useSelector((state: IReduxState) => state.maneuverChildMenuOpened)
    const maneuverChildMenu = useMemo(() => activeMenu?.key === 'maneuver' &&
        maneuverChildMenuOpened && <ManeuverMenuCP isCollapsed={props.isCollapsed} shouldCollapse={props.shouldCollapse} />, [activeMenu, maneuverChildMenuOpened, props.isCollapsed])

    // Reports child menu
    const reportChildMenuOpened = useSelector((state: IReduxState) => state.reportChildMenuOpened)
    const reportsChildMenu = useMemo(() => activeMenu?.key === 'reports' &&
        reportChildMenuOpened && <ReportsMenuCP isCollapsed={props.isCollapsed} shouldCollapse={props.shouldCollapse} />, [activeMenu, reportChildMenuOpened, props.isCollapsed])

    const [showMainMenu, setShowMainMenu] = useState<boolean>(true)
    useEffect(handleMainMenu, [reportChildMenuOpened, maneuverChildMenuOpened])
    useEffect(handleActiveMenu, [activeMenu])
    useEffect(addSchemaOptions, [loggedUser])

    function handleMainMenu(): void {

        if (reportChildMenuOpened && maneuverChildMenuOpened)
            return setShowMainMenu(false)

        setShowMainMenu(true)

    }

    function handleActiveMenu(): void {
        if (!!activeMenu && !(activeMenu.key === 'reports' || activeMenu.key === 'maneuver')) {
            ManeuverActions.toggleChildMenu(false)
            ReportActions.toggleChildMenu(false)
            setShowMainMenu(true)
        }
    }

    function addSchemaOptions(): void {

        if (!!loggedUser.account && loggedUser.account === 1) {
            setMenuOptions(
                [
                    ...MENU_OPTIONS,
                    {
                        key: 'company',
                        route: CompanyRoutes.ROOT,
                        title: 'Empresas',
                        icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faFlag} /></IconSCP>
                    }
                ]
            )
        }
    }

    function isMenuActive(route: string): boolean {
        const path = props.location.pathname
        return `/${path.split('/')[1]}` === route
    }

    return (
        <WrapperSCP>
            <MenuCP
                mode={'inline'}
                selectable={false}
                inlineCollapsed={props.isCollapsed}
            >
                {maneuverChildMenu}
                {reportsChildMenu}
                {showMainMenu &&
                    menuOptions
                        .filter(option => PermissionUtils.hasAccess(option.permissions))
                        .map(opt => (
                            <MenuItemICP
                                title={opt.title}
                                icon={opt.icon}
                                menuActive={isMenuActive(opt.route)}
                                onClick={() => {
                                    if (opt.key === 'maneuver')
                                        ManeuverActions.toggleChildMenu(true)
                                    if (opt.key === 'reports')
                                        ReportActions.toggleChildMenu(true)

                                    props.history.push(opt.route)
                                }}
                                key={`menu-opts-route${opt.route}`}
                            />
                        ))}

            </MenuCP>
        </WrapperSCP>

    )
})

const WrapperSCP = styled.div`
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: auto;

    @media(max-width: 788px){
        height: calc(100% - 18px);  
    }
`
const ExternalLinkWrapperSCP = styled.div`
     margin: 0 2em;
     a{
        display: flex;
         align-items: center;
     }
     span{
        margin-left: 26px;
        color: ${props => props.theme.primaryColor};
        font-weight: 600;
     }
`

const IconSCP = styled.span`
    svg {
        color: ${props => props.theme.primaryColor};
        fill: ${props => props.theme.primaryColor};
        font-size: 1.75em;
    }
`