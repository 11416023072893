import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ProgressBarCP } from 'common/components/progress/ProgressBarCP'
import React from 'react'
import styled from 'styled-components'

interface IFieldWidgetCPProps {
    title?: string
    subtitle?: string
    percent: number
    icon: JSX.Element
    value: string
    loading?: boolean
}

export function FieldWidgetCP(props: IFieldWidgetCPProps): JSX.Element {

    return (
        <WidgetWrapperSCP>
            <IconSCP>{props.icon}</IconSCP>
            {props.loading
                ? <GenericRowSkeletonICP columns={1} rows={3} />
                : (
                    <GridWidgetWrapper>
                        <HeaderWrapperSCP>
                            {props.title && (<TitleSCP>{props.title}</TitleSCP>)}
                        </HeaderWrapperSCP>
                        <GridValueWrapperSCP>
                            <SubtitleSCP>{props.subtitle}</SubtitleSCP>
                            {
                                !props.loading ?
                                    <ValueSCP>{props.value}</ValueSCP>
                                    : <GenericRowSkeletonICP columns={1} rows={1} />
                            }
                        </GridValueWrapperSCP>
                        <ProgressBarCP width={6} percent={props.percent} showInfo={false} />
                    </GridWidgetWrapper>)}
        </WidgetWrapperSCP>
    )

}

const WidgetWrapperSCP = styled.div`
    padding: 10px;
    background-color: ${props => props.theme.white};
    border-radius: 5px;
    display: flex;
    place-items: center;
`

const GridWidgetWrapper = styled.div`
    margin-left: 10px;
    width: 85%;

    .ant-progress-success-bg, .ant-progress-bg {
        background-color: ${props => props.theme.primaryColor};
    }
`

const IconSCP = styled.div`
    background-color: ${props => props.theme.primaryColor};
    border-radius: 100%;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    display: flex;

    svg {
        color: #fff;
        align-content: center;
        margin: 0;
        font-size: 20px;
    }
`

const GridValueWrapperSCP = styled.div`
    display: flex;
    place-content: space-between;
`

const SubtitleSCP = styled.span`
    font-size: 11px;
    color: ${props => props.theme.gray};
`

const ValueSCP = styled.span`
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: bolder;
`

const TitleSCP = styled.span`
    font-weight: 500;
    font-size: 12px;
    flex: 1;
`

const HeaderWrapperSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
`

