import { LogoCP } from 'common/components/logo/LogoCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { SystemConfig } from 'config/SystemConfig'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { InputCP } from '../../../../common/components/form-fields/input/InputCP'
import { HttpStatusEnum } from '../../../../common/enums/HttpStatusEnum'
import { useFormStateManager } from '../../../../common/form-state-manager/UseFormStateManager'
import { RequestUtils } from '../../../../common/request-helper/RequestUtils'
import { useRequest } from '../../../../common/request-helper/UseRequest'
import { AuthActions } from '../../AuthActions'
import { AuthRequests } from '../../AuthRequests'
import { IUserLoginResponseDTO } from '../../services/dtos/responses/IUserLoginResponseDTO'
import { AuthFormFooterCP } from '../auth-form-footer/AuthFormFooterCP'
import { UserLoginFormValidator } from './inner/UserLoginFormValidator'
interface IUserLoginFormCPProps {
    onLoginSuccess: (IUserLoginResponseDTO) => void
}

export function UserLoginFormCP(props: IUserLoginFormCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const logo = screenSize.smd
        ? `${SystemConfig.getInstance().uiBaseUrl}/assets/logo-inverse-02.png`
        : `${SystemConfig.getInstance().uiBaseUrl}/assets/flowins-logo.png`

    const [formValidator] = useState<UserLoginFormValidator>(new UserLoginFormValidator())
    const [token, setToken] = useState<string>()

    const formStateManager = useFormStateManager(formValidator)
    const request = useRequest<any>(undefined, 'none')

    useEffect(onRequestChange, [request.awaiting])

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar dados!')
        }

        console.log('request.returnData: ', request.returnData)
        const resDto = request.returnData
        if (!resDto)
            return
        setToken(resDto.token)

        AuthActions.logUserIn(resDto)
        props.onLoginSuccess(resDto)
    }

    async function onFormSubmit(): Promise<void> {
        formStateManager.setConsiderAllErrors(true)
        if (!(await formStateManager.validate()))
            return

        const formValues = formStateManager.getFormValues()

        const loginDto: any = {
            username: formValues?.username.trim(),
            password: formValues?.password.trim(),
        }

        request.runRequest(AuthRequests.getUserLoginReqConfig(loginDto))
    }

    return (
        <LoginFormWrapperSCP>
            <FormContainerSCP>
                <LogoCP
                    style={{ marginBottom: '100px', maxWidth: '100%' }}
                    inverse={screenSize.smd}
                />
                <InputCP
                    label={'Usuário'}
                    fieldName={'username'}
                    formStateManager={formStateManager}
                    required={true}
                />

                <InputCP
                    label={'Senha'}
                    type={'password'}
                    fieldName={'password'}
                    formStateManager={formStateManager}
                    required={true}
                    onFormSubmit={onFormSubmit}
                />

                <AuthFormFooterCP
                    button={{
                        text: 'Entrar',
                        onClick: onFormSubmit,
                    }}
                    awaiting={request.awaiting}
                />
            </FormContainerSCP>
        </LoginFormWrapperSCP>
    )
}

const FormContainerSCP = styled.div`
    width: 100%;
    margin: 3em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    height: 100%;
`

const LoginFormWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 365px;
    height: 65%;

    @media (max-width: 768px) {
        .ant-form-item-label > label {
            color: #fff !important;
        }
        .ant-form-item-required::before {
            color: #fff !important;
        }
        .ant-btn-primary {
            background-color: #fff;
            color: ${(props) => props.theme.primaryColor};
        }
    }
`
