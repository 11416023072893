import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'

import { MergeTreeICP } from 'modules/batch/components/batch-tree-drawer/inner/MergeTreeICP'
import { SplitTreeICP } from 'modules/batch/components/batch-tree-drawer/inner/SplitTreeICP'
import { BatchOriginEnum } from 'modules/batch/enums/BatchOriginEnum'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IBatchTreeDrawerCPProps {
    batchCode: number
    show: boolean
    onCancel: () => void
}

export function BatchTreeDrawerCP(props: IBatchTreeDrawerCPProps): JSX.Element {
    const request = useRequest<IBatchResponseDTO>()

    const [mainBatch, setMainBatch] = useState<IBatchResponseDTO>()
    const [parents, setParents] = useState<IBatchResponseDTO[]>([])
    const [children, setChildren] = useState<IBatchResponseDTO[]>([])

    useEffect(onRequestChange, [request.awaiting])
    useEffect(getBatchParents, [props.batchCode, props.show])

    function getBatchParents(): void {
        if (!!props.batchCode && props.show)
            request.runRequest(BatchRequests.getOriginData(props.batchCode))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar detalhes!')
        }
        setMainBatch(request.returnData)
        setParents(request.returnData.parents ?? [])
        setChildren(request.returnData.children ?? [])
    }

    return (
        <DrawerCP
            title={'Arvóre do lote'}
            visible={props.show}
            width={'50%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={props.onCancel}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Fechar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {
                props.onCancel()
            }}
        >

            {
                !mainBatch || request.awaiting ?
                    <LoadingOverlayCP show={true} />
                    :

                    <WrapperSCP>
                        {

                            ((mainBatch.origin === BatchOriginEnum.MERGE) ||
                                (mainBatch.origin === BatchOriginEnum.SPLIT)) &&
                                <MergeTreeICP
                                    batch={mainBatch}
                                    parents={parents}
                                />
                        }
                        {
                            !!children.length &&
                            <SplitTreeICP
                                batch={mainBatch}
                                childrenBatches={children}
                            />
                        }

                        {
                            !parents.length &&
                            <EmptyCP
                                description={'Este lote não possui outros lotes relacionados'}
                            />
                        }

                    </WrapperSCP>
            }

        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
  
`

const MainBatchSCP = styled.div`
 
`

const TreeSCP = styled.div`
 
`
const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`

const SubTitleSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;  
    width: 100%;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }
    
`

const CardWrapperSCP = styled.div`
    border: 1px solid ${props => props.theme.primaryColor};
    padding: 10px;
    border-radius: 8px;
    background: #fff;
`