import styled from 'styled-components'
import React from 'react'
import { TextCP } from 'common/components/text/TextCP'
import { Row } from 'antd'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { ManeuverMobileFiltersICP } from 'modules/maneuver/components/maneuver-list-filters/inner/ManeuverMobileFiltersICP'
import { ManeuverFilterFieldsICP } from 'modules/maneuver/components/maneuver-list-filters/inner/ManeuverFilterFieldsICP'
import { ISearchManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchManeuverRequestDTO'
import _ from 'lodash'
interface IManeuverListFastFiltersCPProps {
    setFilters: (filters?: ISearchManeuverRequestDTO) => void
    filters?: ISearchManeuverRequestDTO
}

export function ManeuverListFastFiltersCP(props: IManeuverListFastFiltersCPProps): JSX.Element {
    const screenSize = useScreenSize()

    if (screenSize.smd) {
        return (
            <ManeuverMobileFiltersICP
                onChangeStatus={(status) => props.setFilters({ ...props.filters, status })}
                setFilters={props.setFilters}
                filters={props.filters}
            />
        )
    }

    return (
        <WrapperSCP>
            <Row>
                <TextCP text={'Filtre por:'} />
            </Row>
            <ManeuverFilterFieldsICP
                setFilters={props.setFilters}
                filters={props.filters}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
    .ant-typography{
        font-size: 12px;   
    }

`

const GridWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: .8fr 1.5fr 1.8fr 3fr;
    column-gap: 10px;
    margin-top: 10px;
`