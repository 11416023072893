import { BatchRoutes } from 'modules/batch/BatchRoutes'
import { CompanyRoutes } from 'modules/company/CompanyRoutes'
import { FinancialRoutes } from 'modules/financial/FinancialRoutes'
import { HarvestRoutes } from 'modules/harvest/HarvestRoutes'
import { ManeuverRoutes } from 'modules/maneuver/ManeuverRoutes'
import { PersonRoutes } from 'modules/person/routes/PersonRoutes'
import { PropertyRoutes } from 'modules/property/PropertyRoutes'
import { ReportRoutes } from 'modules/report/ReportRoutes'
import { StructureRoutes } from 'modules/structure/StructureRoutes'

export const AppUtils = {
    getInternalRoutes(code?: string): string[] {
        let routesWithParam: string[] = []
        if (!!code) {
            routesWithParam = [
                `${HarvestRoutes.LOGBOOK_WITHOUT_PARAM}/${code}`,
                `${PropertyRoutes.INFO_WITHOUT_PARAM}/${code}`,
                `${BatchRoutes.PROCESS_WITHOUT_PARAM}/${code}`,
                `${BatchRoutes.ANALISES_WITHOUT_PARAM}/${code}`,
                `${ReportRoutes.FIELD_WITHOUT_PARAM}/${code}`,
                `${ReportRoutes.BATCH_WITHOUT_PARAM}/${code}`,
                `${ReportRoutes.BATCH_WITHOUT_PARAM}/${code}`,
                `${ManeuverRoutes.LOGBOOK_WITHOUT_PARAM}/${code}`
            ]
        }

        return [
            PropertyRoutes.ROOT,
            PropertyRoutes.INFO,
            PersonRoutes.ROOT,
            CompanyRoutes.ROOT,
            StructureRoutes.ROOT,
            HarvestRoutes.ROOT,
            HarvestRoutes.PLANNING,
            HarvestRoutes.LOGBOOK,
            BatchRoutes.ROOT,
            BatchRoutes.PROCESS,
            BatchRoutes.ANALISES,
            FinancialRoutes.ROOT,
            ReportRoutes.ROOT,
            ReportRoutes.QUALITY,
            ReportRoutes.PRODUCTION,
            ReportRoutes.FINANCIAL,
            ReportRoutes.FIELD,
            ReportRoutes.BEST_BATCHES,
            ReportRoutes.BATCH,
            ManeuverRoutes.ROOT,
            ManeuverRoutes.PRODUCTS,
            ManeuverRoutes.INSTRUCTIONS,
            ManeuverRoutes.MAINTENANCE,
            ManeuverRoutes.LOGBOOK,
            ManeuverRoutes.EQUIPMENTS,
            ManeuverRoutes.VEHICLES,
            ...routesWithParam
        ]
    }
}