
import { ThemeConfig } from 'config/ThemeConfig'
import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { PublicRouter } from './PublicRouter'

export function GeneralRouter(): JSX.Element | null {

    return (
        <Suspense fallback={'loading'}>
            <ThemeProvider theme={ThemeConfig}>
                <BrowserRouter>
                    <PublicRouter />
                </BrowserRouter>
            </ThemeProvider>
        </Suspense>
    )
}
