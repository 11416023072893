import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { CardListItemICP } from 'common/components/list/inner/CardListItemICP'

import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'

import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { LitersPerMaturationCP } from 'modules/harvest-planning/components/liters-per-maturation/LitersPerMaturationCP'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import { HarvestUtils } from 'modules/harvest/utils/HarvestUtils'
import React from 'react'
import styled from 'styled-components'
import { MaskUtils } from 'common/utils/MaskUtils'
interface IHarvestCardCPProps {
    harvest: IHarvestResponseDTO
    loading: boolean
}

export function HarvestCardCP(props: IHarvestCardCPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }

    return (
        <WrapperSCP>
            <CardListItemICP
                key={props.harvest.code}
                item={props.harvest}
                loading={props.loading}
                customSkeleton={<GenericRowSkeletonICP columns={1} rows={2} />}
                header={
                    <CardHeaderICP
                        highlight={`#${props.harvest.code}`}
                        title={` ${HarvestUtils.translateType(props.harvest.type)} - ${MaskUtils.applyNumberMask(props.harvest.remainingLiters)} litros restantes`}
                        categoryTitle={
                            <TitleSCP>
                                {props.harvest.field ? `${props.harvest.field.name}: ` : ''}
                                {DateUtils.getFormatted(props.harvest.date, DateFormatEnum.BR_WITHOUT_TIME)}
                                <PopOverCP
                                    placement={'right'}
                                    trigger={'click'}
                                    content={
                                        <WrapperSCP>
                                            {!!props.harvest.remainingLitersPerMaturation ?
                                                <LitersPerMaturationCP litersPerMaturation={props.harvest.remainingLitersPerMaturation} />
                                                :
                                                <span>Litros por maturação não informados</span>}
                                        </WrapperSCP>
                                    }
                                >
                                    <FontAwsomeIconCP icon={faEye} style={{ marginLeft: '20px;' }} />
                                </PopOverCP>
                            </TitleSCP>
                        }
                        itemCode={props.harvest.code}
                        isSelectable={true}
                    />
                }
                content={<span />}
                footer={<span />}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    
`
const TitleSCP = styled.div`
   display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    svg{
        font-size: 17px;
        margin-left: 13px;
        color: ${props => props.theme.primaryColor}
    }
`
const RowSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    margin-bottom: 5px;
    .ant-typography{
        font-size: 13px;
    }
`