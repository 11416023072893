export enum CoffeeTypeEnum {
    ARABICA = 'ARABICA',
    CANEFORA = 'CANEFORA',
}

export enum ArabicaVarietyEnum {
    ARARA = 'ARARA',
    YELLOW_BOURBON = 'YELLOW_BOURBON',
    RED_BOURBON = 'RED_BOURBON',
    PINK_BOURBON = 'PINK_BOURBON',
    YELLOW_CATUAI = 'YELLOW_CATUAI',
    RED_CATUAI = 'RED_CATUAI',
    CATUCAI = 'CATUCAI',
    YELLOW_ICATU = 'YELLOW_ICATU',
    RED_ICATU = 'RED_ICATU',
    MUNDO_NOVO = 'MUNDO_NOVO',
    RUBI = 'RUBI',
    TOPAZIO = 'TOPAZIO',
    PARAISO = 'PARAISO',
    GEISHA = 'GEISHA',
    MARAGOGIPE = 'MARAGOGIPE',
    ACAIA = 'ACAIA',
}

export enum CaneforaVarietyEnum {
    ROBUSTA = 'ROBUSTA',
    CONILON = 'CONILON'
}
