import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment-timezone'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'

import { TextCP } from 'common/components/text/TextCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { BatchRoutes } from 'modules/batch/BatchRoutes'
import { BatchTreeResumeCardICP } from 'modules/batch/components/batch-tree-drawer/inner/BatchTreeResumeCardICP'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IMergeBatchesRequestDTO } from 'modules/batch/services/dtos/requests/IMergeBatchesRequestDTO'
import { IBatchProcessResDTO } from 'modules/batch/services/dtos/responses/IBatchProcessResDTO'
import { ISearchBatchResponseDTO } from 'modules/batch/services/dtos/responses/ISearchBatchResponseDTO'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'lodash'
import { ListActions } from 'common/components/list/inner/ListActions'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'

import { BatchOriginEnum } from 'modules/batch/enums/BatchOriginEnum'
import { ThemeConfig } from 'config/ThemeConfig'
interface IMergeBatchesDrawerCPProps {
    show: boolean
    batches: number[]
    onCancel: () => void
    onSuccess: () => void
}

export function MergeBatchesDrawerCP(props: IMergeBatchesDrawerCPProps): JSX.Element {
    const history = useHistory()

    const [season, setSeason] = useState<number>(moment().year())
    const [farmId, setFarmId] = useState<string>()
    const [date, setDate] = useState<string>(DateUtils.getFormatted(moment().format(), DateFormatEnum.BR_WITHOUT_TIME))

    const [totalLiters, setTotalLiters] = useState<number>()
    const [disableAction, setDisableAction] = useState<boolean>(false)

    const [batchesToMerge, setBatchesToMergeList] = useState<ISearchBatchResponseDTO[]>([])
    useEffect(validateAction, [batchesToMerge])

    const searchBatchRequest = useRequest<IGenericListResponseDTO<ISearchBatchResponseDTO>>()
    useEffect(onSearchRequestChange, [searchBatchRequest.awaiting])

    const mergeReq = useRequest<IBatchProcessResDTO>()
    useEffect(onMergeReqChange, [mergeReq.awaiting])

    useEffect(getBatchList, [props.show, props.batches])

    function getBatchList(): void {
        if (!!props.batches && props.show)
            searchBatchRequest.runRequest(BatchRequests.search({ batchCodes: props.batches }))
    }

    function validateAction(): void {
        const mixed = batchesToMerge.some((b) => !!b.bags) && batchesToMerge.some((b) => !b.bags)
        const hasSales = batchesToMerge.some((b) => !!b.bags) && batchesToMerge.some((b) => b.bags !== b.availableBags)
        if (!!batchesToMerge.length)
            setDisableAction(mixed || hasSales)

    }


    function onMergeReqChange(): void {
        if (mergeReq.awaiting || !mergeReq.tried)
            return

        if (!mergeReq.success || !mergeReq.returnData) {
            if (mergeReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', mergeReq.returnData, mergeReq.error)
            return RequestUtils.showDefaultErrorNotification(mergeReq.error, 'Erro ao buscar lote!')
        }
        props.onSuccess()
        history.push(`${BatchRoutes.ROOT}/processos/${mergeReq.returnData.code}`)
    }

    function onSave(): void {

        const dto: IMergeBatchesRequestDTO = {
            date: DateUtils.getDateFormattedToApi(date),
            season,
            farmId,
            batchCodes: props.batches
        }

        mergeReq.runRequest(BatchRequests.mergeBatches(dto))
    }

    function onSearchRequestChange(): void {

        if (searchBatchRequest.awaiting || !searchBatchRequest.tried) {
            ListActions.isUpdating()
            return
        }

        if (!searchBatchRequest.success || !searchBatchRequest.returnData)
            return RequestUtils.showDefaultErrorNotification(searchBatchRequest.error, 'Erro obter lista de lotes')

        setBatchesToMergeList(searchBatchRequest.returnData.list)
        setTotalLiters(searchBatchRequest.returnData.list.map(batch => batch.liters).reduce((v1, v2) => v1 + v2))
    }

    function getMergedBatchResume(): ISearchBatchResponseDTO {
        const fieldNames = new Set(_.flatten(batchesToMerge.map(batch => batch.fieldNames))) //remove duplicados

        return {
            code: 0,
            date: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            season,
            farmId,
            bags: _.sumBy(batchesToMerge, 'bags'),
            origin: BatchOriginEnum.MERGE,
            fieldNames: [...fieldNames],
            liters: totalLiters ?? 0
        }
    }
    return (
        <DrawerCP
            title={'Mesclar lotes'}
            visible={props.show}
            onClose={() => {
                props.onCancel()
            }}
            width={'60%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        wrapperStyle={{ width: '100%' }}
                        onClick={onSave}
                        loading={mergeReq.awaiting}
                        disabled={disableAction}
                    >
                        Salvar mescla
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >
            <WrapperSCP>
                {/* <GridSCP>
                    {
                        props.batches.map(btCode => (
                            <HighlightSCP key={`${btCode}_key`}>
                                #{btCode}
                            </HighlightSCP>
                        ))
                    }

                </GridSCP> */}

                <TwoColWrapperSCP>
                    <InputCP
                        label={'Data:'}
                        type={'date'}
                        required={true}
                        onChange={setDate}
                        value={date}
                        placeholder={'Data'}
                        loading={mergeReq.awaiting}
                        mask={MaskUtils.applyDateMask}
                        fontSize={'normal'}
                    />
                    <InputCP
                        label={'ID na propriedade:'}
                        placeholder={'ID na propriedade'}
                        onChange={setFarmId}
                        value={farmId}
                        loading={mergeReq.awaiting}
                    />
                    <SeasonSelectorCP
                        label={'Safra:'}
                        required={true}
                        value={season}
                        allowClear={false}
                        onChange={setSeason}
                    />
                </TwoColWrapperSCP>
                <RowSCP>
                    <TextCP
                        text={'Lotes selecionados:'}
                        style={{
                            fontSize: '12px',
                            fontWeight: 600,
                        }}
                    />
                </RowSCP>
                <BatchGridSCP>
                    {
                        !_.isEmpty(batchesToMerge) &&
                        batchesToMerge.map(batch => (
                            <CardWrapperSCP key={`${batch.code}_key`}>
                                <BatchTreeResumeCardICP
                                    batch={batch}
                                    isMainBatch={true}
                                />
                            </CardWrapperSCP>

                        ))
                    }
                </BatchGridSCP>
                <RowSCP>
                    <TextCP
                        text={'Resultado da mescla:'}
                        style={{
                            fontSize: '12px',
                            fontWeight: 600,
                        }}
                    />
                </RowSCP>
                <CardWrapperSCP>
                    <BatchTreeResumeCardICP
                        batch={getMergedBatchResume()}
                        isMainBatch={true}
                    />
                </CardWrapperSCP>

                {
                    disableAction ?
                        <InfoWarningSCP>
                            <FontAwsomeIconCP icon={faExclamationCircle} />
                            <TextWrapperSCP>
                                <TextCP text={'Não é possível mesclar lotes já beneficados com lotes ainda não beneficiados'} color={ThemeConfig.errorColor} />
                                <TextCP text={'ou que já possuem sacas vendidas!'} color={ThemeConfig.errorColor} />
                            </TextWrapperSCP>
                        </InfoWarningSCP>
                        :
                        <InfoWarningSCP>
                            <FontAwsomeIconCP icon={faExclamationCircle} />
                            <TextWrapperSCP>
                                <TextCP text={`Atenção! Note que lote gerado da mescla irá conter exatamente  ${totalLiters ?? '-'}L, total de litros somados de todos os lotes selecionados. `} />
                                <TextCP text={'Caso deseje mesclar somente um parte dos lotes, faça as divisões antes de prosseguir com a mescla.'} style={{ fontWeight: 500 }} />
                            </TextWrapperSCP>
                        </InfoWarningSCP>
                }

            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`
const GridSCP = styled.div`
    display: flex;
    column-gap: 8px;
    flex-wrap: wrap;
    row-gap: 10px;
`
const InfoWarningSCP = styled.div`
    padding: 10px;
    margin: 20px 0;   
    align-items: center;
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    padding-bottom: 10px;
    column-gap: 5px;
    svg {
        font-size: 40px;
        color: ${props => props.theme.primaryColor}
    }
`
const RowSCP = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
    svg{
            margin-left: 10px;
    }
`
const TextWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    .ant-typography {
        font-size: 12px;
    }
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const HighlightSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 55px;
    width: 55px;
    font-size:  15px; 
    @media(max-width: 768px){
        height: 55px;
        min-width: 55px;
    }
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin: 15px 0;
`
const BatchGridSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin: 15px 0;
    row-gap: 10px;
`

const CardWrapperSCP = styled.div`
    border: 1px solid ${props => props.theme.primaryColor};
    padding: 0px 10px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    min-height: 115px;
    flex: 1 0 30%;
`