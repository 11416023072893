import { LoadingOutlined } from '@ant-design/icons'
import * as React from 'react'
import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'

interface ILoadingOverlayCPProps {
    show: boolean
}

/**
 * Componente de loading que ocupa espaco inteiro do container
 * dentro do qual for inserido.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function LoadingOverlayCP(props: ILoadingOverlayCPProps): JSX.Element | null {

    if (!props.show)
        return null

    return (
        <LoadingWrapperSCP>
            <LoadingOutlined spin={true} style={{ fontSize: 35 }} />
        </LoadingWrapperSCP>
    )
}

const LoadingWrapperSCP = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: 999999;
    border-radius: 8px;
    svg {
        color: ${props => props.theme.primaryColor};
    }
`
