import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaintenanceListCP } from 'modules/maneuver/components/maintenance-list/MaintenanceListCP'
import { MaintenanceHeaderCP } from 'modules/maneuver/components/maintenance-header/MaintenanceHeaderCP'

import { IImplementResponseDTO } from 'modules/maneuver/services/dtos/responses/IImplementResponseDTO'
import { ImplementRequests } from 'modules/maneuver/services/ImplementRequests'

import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'
import styled from 'styled-components'

type RouteParamsTP = { code?: string }

export function MaintenanceScreen(): JSX.Element {
    const [showFormDrawer, setShowFormDrawer] = useState<boolean>(false)
    const [implementCode, setImplementCode] = useState<number>()
    const [implementData, setImplementData] = useState<IImplementResponseDTO>()

    const history = useHistory()
    const routeParams = useParams<RouteParamsTP>()

    useEffect(getImplementData, [implementCode])

    const getImplementRequest = useRequest<IImplementResponseDTO>()
    useEffect(onFindOneReqChange, [getImplementRequest.awaiting])

    useEffect(() => {
        if (!routeParams.code)
            return

        setImplementCode(+routeParams.code)
    }, [routeParams])

    function getImplementData(): void {
        if (!!implementCode)
            getImplementRequest.runRequest(ImplementRequests.getImplement(implementCode))

    }

    function onFindOneReqChange(): void {
        if (getImplementRequest.awaiting || !getImplementRequest.tried)
            return

        if (!getImplementRequest.success || !getImplementRequest.returnData) {
            if (getImplementRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getImplementRequest.returnData, getImplementRequest.error)
            return RequestUtils.showDefaultErrorNotification(getImplementRequest.error, 'Erro ao buscar detalhes do item!')
        }
        setImplementData(getImplementRequest.returnData)
    }

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={`Manutenções: ${implementData ? `${implementData.brand} - ${implementData.model}` : '---'}`}
                    icon={
                        <FontAwsomeIconCP icon={faArrowLeft} onClick={() => history.goBack()} size={'1x'} />
                    }
                />
            }
            content={
                <ContentCP>
                    <MaintenanceHeaderCP
                        implementData={implementData}
                    />
                    {
                        !!implementData ?
                            <MaintenanceListCP
                                implementCode={implementData.code}
                            />
                            :
                            <WrapperSCP>
                                <LoadingOverlayCP show={true} />
                            </WrapperSCP>
                    }
                </ContentCP>
            }
        />
    )
}

const WrapperSCP = styled.div`
    margin-top: 15px;
    background: #fff;
    min-height: 100px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
`