import { Radio } from 'antd'
import React from 'react'
import styled, { CSSProperties } from 'styled-components'

export interface RadioOptionProps {
    value: any
    icon?: JSX.Element
    content: string
    disabled?: boolean
}
interface IRadioGroupCPProps {
    options: RadioOptionProps[]
    type?: 'radio' | 'button'
    direction?: 'vertical' | 'horizontal'
    size?: 'large' | 'middle' | 'small'
    selected: any
    onChange?: (value: any) => void
    inverseColors?: boolean
}

/**
 * Radio group do ant estilizado para o projeto usando o tipo button
 * @param props
 * @author Lucas Rosa
 */
export function RadioGroupCP(props: IRadioGroupCPProps): JSX.Element {
    const radioStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
    }

    return (
        <RadioGroupSCP
            value={props.selected}
            onChange={(e) => {
                props.onChange?.(e.target.value)
            }}
            size={props.size}
            style={props.direction === 'vertical' ? radioStyle : {}}
        >

            {props.options.map(opt => (
                props.type === 'button' ?
                    <RadioButtonSCP
                        key={`opt-radio-group-${opt.value}`}
                        value={opt.value}
                        disabled={opt.disabled}
                    >
                        {opt.icon}
                        {opt.content}
                    </RadioButtonSCP>
                    :
                    <RadioSCP key={`opt-radio-group-${opt.value}`} value={opt.value} disabled={opt.disabled}>
                        {opt.icon}
                        {' '}
                        {opt.content}
                    </RadioSCP>))}
        </RadioGroupSCP>
    )
}

const RadioGroupSCP = styled(Radio.Group)`
    &.ant-radio-group {
        display: flex;
        width: 100%;
    }
`
const RadioButtonSCP = styled(Radio.Button)`
    color: ${(props) => props.theme.darkerGray};
    &:active, &:focus, &:hover{
       color: #fff!important;
       background: ${(props) => props.theme.primaryColor};
      
       box-shadow: none;
    }
    &.ant-radio-button-wrapper:first-child{
           border-radius: 5px 0 0 5px;
            border: ${(props) => props.theme.primaryColor} solid 1px;

    }
    &.ant-radio-button-wrapper:last-child{
        border-radius: 0 5px 5px 0;
        border: ${(props) => props.theme.primaryColor} solid 1px;
    }
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: #fff!important;
        
        background: ${(props) => props.theme.primaryColor};
        border: ${(props) => props.theme.primaryColor} solid 1px;
       border-radius: 3px;
        box-shadow: none;
    }
    &.ant-radio-button-wrapper {
        text-align: center;
        height: 37px;
        line-height: 17px;
        align-items: center;
        justify-content: center;  
        display: flex;
        width: 100%;
        border: ${(props) => props.theme.primaryColor} solid 1px;
    }
    &.ant-radio-button-wrapper:not(:first-child)::before{
        background-color: ${(props) => props.theme.primaryColor};
    }
    &.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
        border-right-color: ${(props) => props.theme.primaryColor};
    }
`
const RadioSCP = styled(Radio)`
     .ant-radio-checked .ant-radio-inner{
         
        border-color: ${(props) => props.theme.primaryColor};
        &:hover{
            border-color: ${(props) => props.theme.primaryColor};
        }
    }
    .ant-radio-inner::after{
        background-color: ${(props) => props.theme.primaryColor};
    }
    span.ant-radio + *{
        color: ${(props) => props.theme.darkGray};
        font-family: ${(props) => props.theme.textFontFamily};
        font-weight: ${(props) => props.theme.textFontWeights.regular};
        font-size: ${(props) => props.theme.fontSizes.small}
    }  
    .ant-radio:hover .ant-radio-inner{
        border-color: ${(props) => props.theme.primaryColor};
    }
`