import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { ImplementDrawerCP } from 'modules/maneuver/components/implement-drawer/ImplementDrawerCP'
import { ImplementListCP } from 'modules/maneuver/components/implement-list/ImplementListCP'
import React, { useState } from 'react'
import { EquipmentIcon } from 'common/components/icons/EquipmentIcon'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ImplementTypeEnum } from '../enums/ImplementTypeEnum'

export function EquipmentScreen(): JSX.Element {
    const [showFormDrawer, setShowFormDrawer] = useState<boolean>(false)
    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={'Equipamentos'}
                    icon={
                        <EquipmentIcon color={'white'} />
                    }
                >
                    <ButtonCP
                        icon={<FontAwsomeIconCP icon={faPlus} />}
                        size={'middle'}
                        type={'ghost'}
                        onClick={() => setShowFormDrawer(true)}
                    >
                        Novo
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <ImplementListCP
                        filterType={FilterTypesEnum.EQUIPMENT}
                        implementType={ImplementTypeEnum.EQUIPMENT}
                    />
                    <ImplementDrawerCP
                        show={showFormDrawer}
                        onCancel={() => setShowFormDrawer(false)}
                        onSuccess={() => {
                            ListActions.mustUpdate()
                            setShowFormDrawer(false)
                        }}
                        type={ImplementTypeEnum.EQUIPMENT}
                    />
                </ContentCP>
            }
        />
    )
}
