import { IsOptional } from 'class-validator'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { DateUtils } from 'common/utils/DateUtils'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'

import { IsString } from 'common/validation/decorators/IsString'
import { ISaveBatchProcessLogbookReqDTO } from 'modules/batch/services/dtos/requests/ISaveBatchProcessLogbookReqDTO'

export class BatchProcessLogbookFormValidator extends FormModelValidator {
    @IsNotEmpty()
    date: string

    @IsOptional()
    averageTemperature?: number

    @IsOptional()
    turnings?: number

    @IsOptional()
    humidity?: number

    @IsOptional()
    @IsString()
    notes?: string

    constructor(dto?: ISaveBatchProcessLogbookReqDTO) {
        super()
        if (dto) {
            this.date = DateUtils.getFormatted(dto.date, DateFormatEnum.BR_WITHOUT_TIME)
            this.averageTemperature = dto.averageTemperature
            this.turnings = dto.turnings
            this.humidity = dto.humidity
            this.notes = dto.notes
        }

    }
}
