import { SliderCarouselCP } from 'common/components/slider-carousel/SliderCarouselCP'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { AnalysisContentICP } from 'modules/batch-presentation/components/analysis-carrousel/inner/AnalysisContentICP'
import { LoadingBoxCP } from 'modules/batch-presentation/components/loading-box/LoadingBoxCP'
import { BatchPresentationRequests } from 'modules/batch-presentation/services/BatchPresentationRequests'
import { IAnalysisResumeResDTO } from 'modules/batch-presentation/services/dtos/response/IAnalysisResumeResDTO'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

type RouteParamsTP = { batchCode: string }
interface IAnalysisCarrouseCPProps {

}

export function AnalysisCarrouselCP(props: IAnalysisCarrouseCPProps): JSX.Element {
    const routeParams = useParams<RouteParamsTP>()
    const [resume, setResume] = useState<IAnalysisResumeResDTO[]>()

    const [batchCode, setBatchCode] = useState<number>()
    useEffect(() => setBatchCode(+routeParams.batchCode), [routeParams])

    const getInfoReq = useRequest<IAnalysisResumeResDTO[]>()
    useEffect(onRequestChange, [getInfoReq.awaiting])

    useEffect(getDetails, [batchCode])

    function getDetails(): void {
        if (!!batchCode)
            getInfoReq.runRequest(BatchPresentationRequests.getAnalysisResume(batchCode))
    }

    function onRequestChange(): void {

        if (getInfoReq.awaiting || !getInfoReq.tried)
            return

        if (!getInfoReq.success) {
            if (getInfoReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getInfoReq.returnData, getInfoReq.error)
            return RequestUtils.showDefaultErrorNotification(getInfoReq.error, 'Some error occurred. Please, try to reload the page.')
        }

        setResume(_.orderBy(getInfoReq.returnData, ['date'], ['asc']))
    }

    if (!resume || getInfoReq.awaiting) {
        return (
            <div></div>
        )
    }
    return (
        <WrapperSCP>
            <TextCP
                text={'sensoryAnalysis.plur'}
                style={{ fontSize: '23px', fontWeight: 600, marginBottom: '1em', marginLeft: '1.5em' }}
                translationFiles={'batch'}
                translationNameSpace={'batch'}
            />
            <SliderWppSCP>
                <SliderCarouselCP
                    slidesToShow={resume.length > 1 ? 2 : 1}
                    slidesToScroll={1}
                    dots={true}
                    infinite={true}
                    speed={500}
                    draggable={true}
                    responsive={[
                        {
                            breakpoint: 1600,
                            settings: {
                                slidesToShow: resume.length > 1 ? 2 : 1,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                initialSlide: 1
                            }
                        },

                    ]}
                >
                    {
                        resume.map(item => (<AnalysisContentICP key={item.code} batchCode={batchCode ?? 0} analysis={item} />))
                    }
                </SliderCarouselCP>

            </SliderWppSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 2em 0 8em 0;
    background: white;
    @media(max-width: 768px ){
        padding: 2em 0 5em 0;
    }
`
const SliderWppSCP = styled.div`
    margin-top: 2em;
    .slick-slider .slick-next {
        right: 9px;
    }
    .slick-slider .slick-prev {
        left: 2px;
    }
`
