import { faPenAlt } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CardFooterICP } from 'common/components/list/inner/CardFooterICP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { CardListItemICP } from 'common/components/list/inner/CardListItemICP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { BatchCardSkeletonICP } from 'modules/batch/components/batch-list/inner/BatchCardSkeletonICP'
import { CompanyDrawerCP } from 'modules/company/components/company-drawer/CompanyDrawerCP'
import { ICompanyResDTO } from 'modules/company/services/dtos/response/ICompanyResDTO'
import React, { useState } from 'react'
import styled from 'styled-components'

interface ICompanyCardICPProps {
    company: ICompanyResDTO
    loading: boolean
}

export function CompanyCardICP(props: ICompanyCardICPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }
    const [companyDrawerVisible, setCompanyDrawervisible] = useState<boolean>(false)

    if (!props.company || props.loading)
        return <BatchCardSkeletonICP />

    return (
        <WrapperSCP>

            <CardListItemICP
                key={props.company.code}
                item={props.company}
                loading={props.loading}
                customSkeleton={<BatchCardSkeletonICP />}
                header={
                    <CardHeaderICP
                        highlight={`#${props.company.code}`}
                        title={`${props.company.name}`}
                        itemCode={props.company.code}
                        subtitle={props.company.domain}
                        actions={
                            [
                                {
                                    label: 'Editar',
                                    action: (itemCode) => setCompanyDrawervisible(true)
                                }
                            ]

                        }
                    />
                }
                content={
                    <ContentWrapperSCP>
                        {/* <RowSCP>
                            <TextCP text={'Capacidade: '} />
                            <TextCP
                                text={`${props.company.capacity ? `${props.company.capacity} ${ProductUtils.translateUnitOfMeasurement(props.company.unitOfMeasurement)}` : 'Não informada'}`} style={valueStyle}
                            />
                        </RowSCP> */}
                    </ContentWrapperSCP>
                }
                footer={
                    <CardFooterICP>
                        <ActionsSCP>
                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px', height: '100%' }}
                                onClick={() => setCompanyDrawervisible(true)}
                                icon={<FontAwsomeIconCP icon={faPenAlt} />}

                            >
                                Editar
                            </ButtonCP>
                        </ActionsSCP>
                    </CardFooterICP>
                }
            />

            <CompanyDrawerCP
                show={companyDrawerVisible}
                onSuccess={() => {
                    setCompanyDrawervisible(false)

                }}
                onCancel={() => {
                    setCompanyDrawervisible(false)
                }}
                companyCode={props.company.code}
            />

        </WrapperSCP>
    )
}

const ActionsSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const WrapperSCP = styled.div`
  
`

const POWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .ant-btn{
        margin-left: 10px;
        margin-bottom: 5px;
        min-width: 150px;
    }
    
`

const ContentWrapperSCP = styled.div`
    /* padding: 0 10px;   
    min-height: 20px; */
`
const RowSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    margin-bottom: 5px;
    .ant-typography{
        font-size: 13px;
    }
`
