import { ButtonCP } from 'common/components/button/ButtonCP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { TextCP } from 'common/components/text/TextCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IInvoiceResDTO } from 'modules/financial/services/dtos/responses/IInvoiceResDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { FinancialUtils } from 'modules/financial/utils/FinancialUtils'
import { InvoiceTypeEnum } from 'modules/financial/enums/InvoiceType'
import { faCheckDouble, faPaperclip, faPen, faThumbsUp, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { MaskUtils } from 'common/utils/MaskUtils'
import { InvoiceFormDrawerCP } from 'modules/financial/components/invoice-form-drawer/InvoiceFormDrawerCP'
import { DocsDrawerCP } from 'modules/maneuver/components/purchase-docs-drawer/DocsDrawerCP'
import moment from 'moment-timezone'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { FinancialRequests } from 'modules/financial/services/FinancialRequests'
import { ISaveInvoiceReqDTO } from 'modules/financial/services/dtos/request/ISaveInvoiceReqDTO'

interface IInvoiceListItemCPProps {
    invoice: IInvoiceResDTO
    loading?: boolean
    key: string
    type: InvoiceTypeEnum
}

export function InvoiceListItemCP(props: IInvoiceListItemCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const todaysDate = DateUtils.formatDate(moment().format(), DateFormatEnum.BR_WITHOUT_TIME)
    const [paymentDate, setPaymentDate] = useState<string>(props.invoice.paymentDate ? DateUtils.getFormatted(props.invoice.paymentDate, DateFormatEnum.BR_WITHOUT_TIME) : todaysDate)
    const [detailsVisible, setDetailsVisible] = useState<boolean>(false)
    const [docsDrawerVisible, setDocsDrawerVisible] = useState<boolean>(false)

    const payInvoiceReq = useRequest()
    useEffect(onRequestChange, [payInvoiceReq.awaiting])

    const deleteReq = useRequest(FinancialRequests.delete(props.invoice.code))
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    function onRequestChange(): void {

        if (payInvoiceReq.awaiting || !payInvoiceReq.tried)
            return

        if (!payInvoiceReq.success) {
            if (payInvoiceReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', payInvoiceReq.returnData, payInvoiceReq.error)
            return RequestUtils.showDefaultErrorNotification(payInvoiceReq.error, 'Erro pagar fatura!')
        }

        ListActions.mustUpdate()
    }

    function onDeleteReqChange(): void {
        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success) {
            if (deleteReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteReq.returnData, deleteReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Erro ao excluir fatura!')
        }

        ListActions.mustUpdate()
    }

    function payInvoice(): void {
        if (!paymentDate)
            return

        const dto: ISaveInvoiceReqDTO = {
            date: props.invoice.date,
            description: props.invoice.description,
            isCredit: props.type === InvoiceTypeEnum.REVENUE,
            value: +props.invoice.value,
            season: props.invoice.season,
            isPaid: true,
            paymentDate: DateUtils.getDateFormattedToApi(paymentDate)
        }
        payInvoiceReq.runRequest(FinancialRequests.update(props.invoice.code, dto))
    }

    return (
        <ListItemICP
            key={props.key}
            isMobile={screenSize.smd}
            loading={props.loading}
            style={{ padding: '0', margin: '0' }}
            customSkeleton={<GenericRowSkeletonICP rows={1} columns={5} />}
        >
            <WrapperSCP>
                <MobileLabelsSCP>

                    <TextCP text={'Data:'} />
                    <TextCP text={'Categoria:'} />
                    <TextCP text={'Descrição:'} />
                    <TextCP text={'Valor total:'} />
                    <TextCP text={'Status:'} />
                    <TextCP text={'Data do pagamento:'} />

                </MobileLabelsSCP>

                <ValuesSCP>
                    <TextCP text={DateUtils.getFormatted(props.invoice.date, DateFormatEnum.BR_WITHOUT_TIME)} />
                    <TextCP text={FinancialUtils.translateCategory(props.invoice.category)} />
                    <TextCP text={props.invoice.description} />
                    <TextCP text={`${MaskUtils.applyCurrencyMask(props.invoice.value)}`} />
                    <TextCP
                        style={{ color: !props.invoice.isPaid ? '#ffcc00' : '#40b971' }}
                        text={props.invoice.isPaid ? 'Pago' : 'Pendente'}
                    />
                    <TextCP text={props.invoice.paymentDate ? DateUtils.getFormatted(props.invoice.paymentDate, DateFormatEnum.BR_WITHOUT_TIME) : ' - '} />
                </ValuesSCP>
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={() => setDocsDrawerVisible(true)}
                        tooltip={'Arquivos'}
                        icon={<FontAwsomeIconCP icon={faPaperclip} color={'#fff'} />}
                    />
                    <PopOverCP
                        placement={'left'}
                        title={props.invoice.isPaid ? 'Editar data de pagamento' : 'Marcar como pago'}
                        trigger={'click'}
                        content={
                            <POWrapperSCP>
                                <InputCP
                                    label={'Data'}
                                    placeholder={'__/__/____'}
                                    onChange={setPaymentDate}
                                    value={paymentDate}
                                    type={'date'}
                                    required={true}
                                    mask={MaskUtils.applyDateMask}
                                />
                                <ButtonCP
                                    type={'primary'}
                                    icon={<FontAwsomeIconCP icon={faCheckDouble} />}
                                    onClick={payInvoice}
                                    loading={payInvoiceReq.awaiting}
                                />
                            </POWrapperSCP>

                        }
                    >
                        <ButtonCP
                            type={'primary'}
                            tooltip={props.invoice.isPaid ? 'Editar data de pagamento' : 'Marcar como pago'}
                            icon={<FontAwsomeIconCP icon={props.invoice.isPaid ? faThumbsUp : faCheckDouble} color={'#fff'} />}
                        />
                    </PopOverCP>
                    <ButtonCP
                        type={'primary'}
                        tooltip={'Editar'}
                        disabled={!!props.invoice.entityId}
                        onClick={() => setDetailsVisible(true)}
                        icon={<FontAwsomeIconCP icon={faPen} color={'#fff'} />}
                    />
                    <ButtonCP
                        type={'ghost'}
                        onClick={deleteReq.runRequest}
                        confirmMsg={'Tem certeza que deseja excluir este lançamento?'}
                        disabled={!props.invoice.canDelete}
                        loading={deleteReq.awaiting}
                        icon={<FontAwsomeIconCP icon={faTrashAlt} />}
                    />
                </ButtonWrapperSCP>

            </WrapperSCP>
            <InvoiceFormDrawerCP
                type={props.type}
                show={detailsVisible}
                code={props.invoice.code}
                onCancel={() => setDetailsVisible(false)}
                onSuccess={() => {
                    setDetailsVisible(false)
                }}
            />
            <DocsDrawerCP
                show={docsDrawerVisible}
                type={'financial'}
                itemCode={props.invoice.code}
                onClose={() => setDocsDrawerVisible(false)}

            />
        </ListItemICP>
    )
}

const WrapperSCP = styled.div`   
    position:relative;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    column-gap: 10px;
    display: grid;
    grid-template-columns: 1.3fr .3fr;
    @media(max-width: 768px){
        grid-template-columns: .5fr 1fr;
          display: grid;
          padding-bottom: 45px;
          margin-bottom: 2em;
          border-bottom: 1px solid ${props => props.theme.lightGray};
        /* .ant-btn{
            position: absolute;
            bottom: 7px;
            width: 100%;
            right: -2px;
        } */
    }
`

const ValuesSCP = styled.div`
    display: grid;
    grid-template-columns:10% 10% 36% 15% 10% 12%;
    margin: 0;
    width:100%;
    padding: 15px 0;
    word-break: break-all;
    column-gap: 15px;
    align-items: center;
    .ant-list-bordered.ant-list-lg .ant-list-item{
        padding: 0!important;
    }
  
    .ant-typography{
        font-size: 12px;
    }

    @media(max-width: 768px){
        grid-template-columns: 1fr;    
        row-gap: 7px;   
        padding: 0; 
    }
`

const RowSCP = styled.div`
    display: grid;
    grid-template-columns: 80% 18%;
    column-gap: 2%;
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    width: 100%;
    place-content: flex-end;

    .ant-btn {
        height: 35px;
        margin: 0 2px;
        span svg {
            width: auto;
            margin: 0px;
        }
    }
    @media(max-width: 768px){
        position: absolute;
        width: 100%;
        right: 0;
        bottom: -10px;
        column-gap: 5px;
        display: flex;
    }
`

const POWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .ant-btn{
        margin-left: 10px;
        margin-bottom: 10px;
    }
    @media(max-width: 768px){
        .ant-btn{
            margin: 0;
        }
    }
`
const MobileLabelsSCP = styled.div`
    display: none;
    .ant-typography{
        font-weight: 600;
    }
    @media(max-width: 768px){
        display: grid;
        grid-template-rows: 1.5fr 1.7fr 2fr 1fr 1fr 2fr;
        grid-template-columns: 1fr;
        row-gap: 10px;
        width: 100%;
    }
`