import { Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'

export function PropertyCardSkeletonICP(): JSX.Element {
    return (
        <ContentWrapperSCP>
            <HeaderWrapperSCP>
                <Skeleton.Image />
            </HeaderWrapperSCP>
            <TitleWrapperSCP>
                <Skeleton active={true} paragraph={{ rows: 1 }} />
            </TitleWrapperSCP>
            <IconsWrapperSCP>
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
            </IconsWrapperSCP>
            <TitleWrapperSCP>
                <Skeleton active={true} paragraph={{ rows: 2 }} title={false} />
            </TitleWrapperSCP>
        </ContentWrapperSCP>
    )
}

const ContentWrapperSCP = styled.div`
    display: grid;
    grid-template-rows: 1fr .2fr .2fr .5fr;
    row-gap: 10px;
    background: #fff;
    border-radius: 10px;
    margin: 15px;
    box-shadow: 0px 6px 20px 3px rgba(0,0,0,0.1);
`
const HeaderWrapperSCP = styled.div`
    .ant-skeleton-element, .ant-skeleton-image{
        width: 100%;
        height: 200px;
        border-radius: 10px 10px 0 0 ;
    }
`

const IconsWrapperSCP = styled.div`
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    max-width: 120px;

`
const TitleWrapperSCP = styled.div`
    padding: 0 10px;
`