import React from 'react'

export function CoffeeBeansIcon(props: any): JSX.Element {
    return (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
            <svg
                {...props}
                xmlns={'http://www.w3.org/2000/svg'}
                viewBox={'0 0 396.302 396.302'}
            >
                <g fill={'#fff'}>
                    <path
                        d={`M72.877,359.087c-18.312-32.301-5.855-82.955,32.033-120.847c43.029-43.022,102.518-53.277,132.891-22.907
                            c0.032,0.038,0.07,0.081,0.116,0.122c-6.079,17.688-25.441,55.314-83.465,75.659C101.292,309.758,80.79,339.41,72.877,359.087z
                            M219.918,264.958c-13.877,13.889-33.601,27.519-61.541,37.324c-56.703,19.881-72.545,52.438-76.966,68.203
                            c0.214,0.214,0.387,0.438,0.588,0.635c30.37,30.372,89.867,20.115,132.885-22.906c38.282-38.282,50.596-89.587,31.458-121.833
                            C241.736,237.121,233.724,251.151,219.918,264.958z M102.58,125.039c61.256,5.368,94.394-20.947,107.215-34.556
                            c-0.022-0.054-0.038-0.115-0.055-0.164c-15.16-40.181-73.596-55.344-130.52-33.866C29.088,75.37-3.137,116.393,0.242,153.368
                            C15.561,138.703,46.459,120.13,102.58,125.039z M134.123,201.97c50.658-19.108,83.004-60.789,78.852-98.058
                            c-8.622,7.891-21.704,17.379-39.97,24.273c-18.364,6.928-41.955,11.229-71.449,8.646c-59.854-5.248-87.71,17.893-98.239,30.446
                            c0.107,0.279,0.182,0.553,0.279,0.815C18.759,208.284,77.197,223.453,134.123,201.97z M311.404,118.178
                            c36.752-49.309,31.765-91.334,26.918-109.391c-0.056-0.006-0.111-0.027-0.163-0.039c-42.158-8.208-85.759,33.555-97.388,93.271
                            c-10.241,52.607,7.745,101.567,40.979,118.119C277.319,199.398,277.751,163.354,311.404,118.178z M351.421,13.137
                            c2.188,11.486,3.388,27.601-0.343,46.759c-3.748,19.272-12.489,41.603-30.175,65.347c-35.907,48.175-30.851,84.027-25.7,99.579
                            c0.294,0.061,0.569,0.136,0.841,0.196c42.162,8.208,85.758-33.548,97.385-93.27C403.784,78.604,385.309,29.175,351.421,13.137z`}
                    />
                </g>
            </svg>
        </span>
    )
}
