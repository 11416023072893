import { PaginationConfig } from 'antd/lib/pagination'
import { TablePaginationTP } from 'common/components/table/TableCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IPurchaseResponseDTO } from 'modules/maneuver/services/dtos/responses/IPurchaseResponseDTO'
import React, { useEffect, useState } from 'react'
import { ISearchPurchaseRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchPurchaseRequestDTO'
import styled from 'styled-components'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'
import { ProductRequests } from 'modules/maneuver/services/ProductRequests'
import { PurchaseListItemICP } from 'modules/maneuver/components/purchase-list/inner/PurchaseListItemICP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { TextCP } from 'common/components/text/TextCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { useSelector } from 'react-redux'

interface IPurchaseListProps {
    productCode: number
    unitOfMeasurement?: UnitOfMeasurementEnum
    reload?: number
}

const DEFAULT_PAGINATION: TablePaginationTP = {
    current: 1,
    pageSize: 9999,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`
}
const DEFAULT_FILTERS: ISearchPurchaseRequestDTO = {
    itemsPerPage: DEFAULT_PAGINATION.pageSize,
    page: 1
}

export function PurchaseListCP(props: IPurchaseListProps): JSX.Element {
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const [purchaseList, setPurchaseList] = useState<IPurchaseResponseDTO[]>([])
    const [pagination, setPagination] = useState<PaginationConfig>()
    const [filters, setFilters] = useState<ISearchPurchaseRequestDTO>(DEFAULT_FILTERS)

    const getPurchaseListReq = useRequest<IGenericListResponseDTO<IPurchaseResponseDTO>>()
    useEffect(onRequestChange, [getPurchaseListReq.awaiting])

    useEffect(getList, [props.productCode])
    useEffect(onItemChange, [updateStatus])

    function getList(): void {
        getPurchaseListReq.runRequest(ProductRequests.getPurchaseList(props.productCode))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE)
            getList()
    }

    function onRequestChange(): void {
        if (getPurchaseListReq.awaiting || !getPurchaseListReq.tried)
            return
        if (!getPurchaseListReq.success || !getPurchaseListReq.returnData)
            return RequestUtils.showDefaultErrorNotification(getPurchaseListReq.error, 'Erro obter compras')

        setPurchaseList(getPurchaseListReq.returnData.list)

        setPagination({
            ...pagination,
            total: getPurchaseListReq.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                setFilters({ ...filters, page, itemsPerPage: pageSize })
            }
        })
        ListActions.updateIsDone()
    }

    return (
        <WrapperSCP>
            <ListWrapperSCP>
                <ListHeaderSCP>
                    <TextCP text={'#'} />
                    <TextCP text={'Safra'} />
                    <TextCP text={'Data'} />
                    <TextCP text={'Qtd.'} />
                    <TextCP text={'Valor'} />
                    <TextCP text={'Obs.'} />
                </ListHeaderSCP>
                <ListCP<IPurchaseResponseDTO>
                    loading={getPurchaseListReq.awaiting}
                    itemLayout={'vertical'}
                    size={'large'}
                    dataSource={purchaseList}
                    hasStateControl={true}
                    locale={{
                        emptyText: <EmptyCP description={'Sem resultados'} />
                    }}
                    renderItem={(item: IPurchaseResponseDTO) => (
                        <PurchaseListItemICP
                            productCode={props.productCode}
                            purchase={item}
                            key={`${item.code}${Math.random()}`}
                            loading={getPurchaseListReq.awaiting}
                        />
                    )}
                />
            </ListWrapperSCP>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
`

const ListWrapperSCP = styled.div`
    margin-top: 20px;
`

const ListHeaderSCP = styled.div`
    .ant-typography{     
        white-space: pre-wrap;
        text-align: center;
        font-weight: 600;
    }
    display: grid;
    grid-template-columns: .2fr .3fr .6fr .6fr .6fr 2fr 1fr;
    column-gap: 5px;
    @media(max-width: 768px){
      display: none;
   
    }

`