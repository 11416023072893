import { PlusOutlined } from '@ant-design/icons'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { PropertyListCP } from 'modules/property/components/property-list/PropertyListCP'
import React, { useEffect, useState } from 'react'
import { ButtonCP } from '../../../common/components/button/ButtonCP'
import { useHistory } from 'react-router-dom'
import { PropertyRoutes } from 'modules/property/PropertyRoutes'
import { PropertyDrawerCP } from 'modules/property/components/property-drawer/PropertyDrawerCP'
import { faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function PropertyListScreen(): JSX.Element {
    const history = useHistory()
    const [visible, setVisible] = useState(false)

    function redirectToInfo(code?: number): void {
        history.push(`${PropertyRoutes.ROOT}/${code!}`)
    }

    useEffect(() => {
        const listEl = document.getElementsByClassName('ant-list').item(0)
        if (listEl) {
            if (listEl.clientHeight < listEl.scrollHeight)
                (listEl as HTMLElement).style.padding = '0 24px 0 0'

        }
    }, [])

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={'Propriedades'}
                    icon={<FontAwesomeIcon icon={faWarehouse} style={{ fontSize: 18 }} />}
                >
                    <ButtonCP
                        icon={<PlusOutlined />}
                        size={'middle'}
                        type={'ghost'}
                        onClick={() => setVisible(true)}
                    >
                        Nova Propriedade
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <PropertyListCP />
                    <PropertyDrawerCP
                        visible={visible}
                        onClose={() => setVisible(false)}
                        onSuccess={redirectToInfo}
                    />
                </ContentCP>
            }
        />
    )
}

