import styled from 'styled-components'
import React from 'react'
import { RadioGroupCP, RadioOptionProps } from 'common/components/radio-group/RadioGroupCP'
import { InvoiceTypeEnum } from 'modules/financial/enums/InvoiceType'
import { FinancialUtils } from 'modules/financial/utils/FinancialUtils'

interface IFinancialDashboardTypeCPProps {
    onChangeType: (type: InvoiceTypeEnum) => void
    type?: InvoiceTypeEnum
}

export function FinancialDashboardTypeCP(props: IFinancialDashboardTypeCPProps): JSX.Element {

    function getOptions(): RadioOptionProps[] {
        return Object.values(InvoiceTypeEnum).map(type => ({
            value: type,
            content: FinancialUtils.getFinancialType(type)
        }))
    }

    return (
        <WrapperSCP>
            <RadioGroupCP
                type={'button'}
                options={getOptions()}
                selected={props.type}
                onChange={props.onChangeType}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .ant-radio-button-wrapper {
        border-color: #fff;
        background: ${props => props.theme.primaryColor};
        color: #fff;

        :hover {
            color: ${props => props.theme.primaryColor}!important;
            background: #fff;
            box-shadow: none;
        }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: ${props => props.theme.primaryColor}!important;
        background: #fff;
        box-shadow: none;
    }
    @media( max-width: 768px){
        margin-bottom: 5px;
       .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
            background: ${props => props.theme.primaryColor};
            color: #fff!important;
       }

       .ant-radio-button-wrapper {
        border-color: ${props => props.theme.primaryColor};
        background: #fff;
        color: ${props => props.theme.primaryColor};

        :hover {
            background: ${props => props.theme.primaryColor}!important;
            color: #fff;
            box-shadow: none;
        }
    }
    }
`

