import React from 'react'

export function CoffeeVarietyIconCP(props: any): JSX.Element {
    return (
        <span style={{ display: 'flex', justifyContent: 'center', width: props.width, alignItems: 'start', marginRight: '10px' }} >
            <svg
                {...props}
                xmlns={'http://www.w3.org/2000/svg'}
                x={'0px'}
                y={'0px'}
                viewBox={'0 0 100 100'}
                width={30}
                enableBackground={'new 0 0 100 100'}
            >
                <g fill={'#fff'}>
                    <path d='M6.17,68.15c0.28-1.55,0.42-3.13,0.86-4.63c3.65-12.64,11.88-20.58,24.66-23.69c3.36-0.82,6.81-0.78,10.34-0.08
                        c-0.27-1.31-0.58-2.52-0.78-3.75c-1.13-7.3-0.08-14.17,4.3-20.27c3.78-5.26,8.99-8.22,15.43-8.98c0.16-0.02,0.31-0.1,0.47-0.15
                        c1.35,0,2.7,0,4.06,0c1.48,0.3,2.99,0.5,4.44,0.9c7.68,2.16,13.48,6.84,17.7,13.52c3.46,5.47,5.27,11.45,5.04,17.94
                        c-0.27,7.46-2.81,13.97-8.81,18.71c-6.42,5.08-13.67,5.93-21.39,3.59c-0.78-0.24-1.54-0.51-2.31-0.77c-0.02-0.01-0.05,0.02,0,0
                        c-0.14,1.91-0.19,3.8-0.43,5.66c-0.52,4.02-1.93,7.74-4,11.21c-0.56,0.94-1.54,1.22-2.38,0.72c-0.83-0.5-1.08-1.48-0.52-2.43
                        c1.66-2.85,2.85-5.86,3.44-9.11c0.43-2.37,0.52-4.75,0.24-7.15c-0.07-0.59-0.28-1-0.83-1.35c-1.18-0.75-2.3-1.61-3.37-2.38
                        c-1.02,1.52-1.92,3.16-3.09,4.58c-4.24,5.13-9.77,7.58-16.41,7.69c-5.68,0.1-10.25,2.52-13.72,7c-2.03,2.63-3.11,5.65-3.46,8.94
                        c-0.06,0.59,0.09,0.99,0.56,1.38c4.28,3.5,9.22,4.81,14.66,4.45c4.15-0.28,7.98-1.58,11.56-3.68c0.75-0.44,1.48-0.56,2.19,0.02
                        c0.97,0.8,0.78,2.15-0.43,2.86c-3.23,1.92-6.68,3.24-10.38,3.86c-6.29,1.05-12.27,0.22-17.65-3.35c-5.84-3.88-9.01-9.45-9.83-16.39
                        c-0.03-0.27-0.11-0.54-0.16-0.81C6.17,70.86,6.17,69.51,6.17,68.15z M57.77,10.86c1.17,0.79,2.31,1.42,3.3,2.25
                        c7.03,5.9,9.42,13.35,7.26,22.25c-2.11,8.72,2.64,17.62,11.07,20.66c0.54,0.19,0.93,0.14,1.4-0.19c3.45-2.44,5.84-5.69,7.19-9.66
                        c2.45-7.23,1.6-14.25-1.84-20.94c-3.95-7.67-9.98-12.9-18.57-14.79C64.29,9.71,61.02,9.85,57.77,10.86z M75.85,58.24
                        c-0.23-0.17-0.31-0.24-0.4-0.29c-7.79-4.09-12.99-13.64-10.26-24.19c2.26-8.72-2.55-17.55-11-20.68c-0.53-0.2-0.93-0.16-1.39,0.17
                        c-2.54,1.78-4.54,4.04-5.96,6.79c-2.79,5.39-3.34,11.09-1.92,16.91c2.41,9.84,8.1,17.02,17.68,20.77
                        C66.86,59.39,71.25,59.58,75.85,58.24z M12.66,81.15c0.92-4,2.66-7.34,5.37-10.13c4.08-4.2,9.06-6.32,14.93-6.48
                        c7.69-0.22,13.15-3.89,16.39-10.87c0.21-0.46,0.22-0.78-0.15-1.19c-2.24-2.48-4.01-5.28-5.45-8.3c-0.15-0.32-0.55-0.63-0.9-0.73
                        c-3.36-0.98-6.78-1.09-10.2-0.38c-8.42,1.74-14.77,6.46-19.2,13.74c-3.38,5.56-4.72,11.59-3.38,18.06
                        C10.51,77.04,11.32,79.09,12.66,81.15z'
                    />
                    <path d='M49.57,80.96c0.93,0.02,1.64,0.75,1.63,1.68c-0.01,0.92-0.75,1.66-1.66,1.67c-0.94,0-1.71-0.79-1.68-1.74
	C47.89,81.65,48.63,80.94,49.57,80.96z'
                    />
                </g>
            </svg>
        </span>
    )
}

