import { RingProgressChartCP } from 'common/components/charts/RingProgressChartCP'

import { GenericCardWidgetCP } from 'common/components/widgets/GenericCardWidgetCP'
import { WidgetGenericValueICP } from 'common/components/widgets/inner/WidgetGenericValueICP'

import React from 'react'
import styled from 'styled-components'

interface ITotalSpecialReportCardICPProps {
    total?: number
    totalSpecial?: number
    loading?: boolean
}

export function TotalSpecialReportCardICP(props: ITotalSpecialReportCardICPProps): JSX.Element {

    return (
        <GenericCardWidgetCP
            gridArea={'total-special'}
            title={'Lotes especiais'}
            content={
                <WrapperSCP>

                    <WidgetGenericValueICP
                        value={props.totalSpecial}
                        label={`de ${props.total ?? 0} lotes`}
                        loading={props.loading}
                    />

                    <ChartWrapperSCP>
                        {
                            !!props.totalSpecial && !!props.total &&
                            <RingProgressChartCP
                                percent={props.totalSpecial / props.total}
                                label={'do total'}
                                width={120}
                                height={120}
                            />
                        }
                    </ChartWrapperSCP>
                </WrapperSCP>
            }
        />
    )
}

const WrapperSCP = styled.div`
    position: relative;
`
const ChartWrapperSCP = styled.div`
    position: absolute;
    top: -40px;
    right: 0;
`
