import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaskUtils } from 'common/utils/MaskUtils'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ButtonCP } from 'common/components/button/ButtonCP'
import { BatchRoutes } from 'modules/batch/BatchRoutes'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { TextCP } from 'common/components/text/TextCP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'

interface IBatchSearchSelectorCPProps {
    setBatchCode: (batchCode: number) => void
    batchCode?: number
    loading?: boolean
    required?: boolean
}

export function BatchSearchSelectorCP(props: IBatchSearchSelectorCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [batch, setBatch] = useState<IBatchResponseDTO>()

    const [batchSearchResume, setBatchSearchResume] = useState<string>('Digite o código do lote e clique em Buscar')
    const [codeFilter, setCodeFilter] = useState<number>()

    const request = useRequest<IGenericListResponseDTO<IBatchResponseDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(init, [props.batchCode])

    function init(): void {
        if (!!props.batchCode) {
            setCodeFilter(props.batchCode)
            searchBatches(props.batchCode)
        }
    }

    function searchBatches(code: number): void {

        request.runRequest(BatchRequests.search({ batchId: code }))
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar itens!')
        }

        if (!!request.returnData.list.length) {
            const _batch = request.returnData.list[0]
            setBatch(request.returnData.list[0])
            props.setBatchCode(request.returnData.list[0].code)
            setBatchSearchResume(`#${_batch.code} ${_batch.fieldNames?.map(name => name).join(', ') ?? '-'} - ${MaskUtils.applyNumberMask(_batch.availableBags ?? 0)} sacas disponívies`)
            return
        }
        setBatchSearchResume('Lote não encontrado no sistema')

    }

    return (
        <WrapperSCP>
            <TwoColWrapperSCP>
                <InputCP
                    label={'Digite o código do lote'}
                    placeholder={'Código do lote'}
                    required={props.required}
                    onChange={setCodeFilter}
                    value={codeFilter}
                    type={'number'}
                    disabled={request.awaiting}
                    loading={props.loading ?? request.awaiting}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                />
                <ButtonCP
                    type={'primary'}
                    wrapperStyle={{ marginTop: '33px', width: '100%' }}
                    icon={<FontAwsomeIconCP icon={faSearch} color={'#fff'} />}
                    onClick={() => searchBatches(codeFilter!)}
                >
                    Buscar
                </ButtonCP>
                {
                    !!batch &&
                    <ButtonCP
                        type={'primary'}
                        wrapperStyle={{ marginTop: '33px', width: '100%' }}
                        onClick={() => window.open(`${BatchRoutes.PROCESS_WITHOUT_PARAM}/${batch.code}`, 'Detalhes')}
                    >
                        Ver detalhes
                    </ButtonCP>
                }
            </TwoColWrapperSCP>

            <TextCP
                text={batchSearchResume}
                style={{ fontSize: '12px', margin: '15px 0' }}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    .ant-typography{
        color: ${props => props.theme.primaryColor};
    }
`
const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    column-gap: 10px;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }
`