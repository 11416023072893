import { StructureTypeEnum } from 'modules/batch/enums/StructureTypeEnum'

export const StructureUtils = {
    translateType(type: StructureTypeEnum): string {
        switch (type) {
            case StructureTypeEnum.TERRACE:
                return 'Terreiro'
            case StructureTypeEnum.AFRICAN_BED:
                return 'Terreiro suspenso'
            case StructureTypeEnum.GREENHOUSE:
                return 'Estufa'
            case StructureTypeEnum.GREENHOUSE_AFRICAN_BED:
                return 'Estufa com terreiro suspenso'
            case StructureTypeEnum.ROTARY_DRYER:
                return 'Secador rotativo'
            case StructureTypeEnum.SEMI_STATIC_DRYER:
                return 'Secador semi-estático'
            case StructureTypeEnum.STATIC_DRYER:
                return 'Secador estático'
            case StructureTypeEnum.SHADED_TERRACE:
                return 'Terreiro sombreado'
            case StructureTypeEnum.SHADED_AFRICAN_BED:
                return 'Terreiro suspenso sombreado'
            case StructureTypeEnum.FERMENTATION_TANK:
                return 'Tanque de fermentação'
            case StructureTypeEnum.PULPER:
                return 'Descascador'
            case StructureTypeEnum.HOPPER:
                return 'Moega'
            case StructureTypeEnum.GRANARY:
                return 'Tulha'
            case StructureTypeEnum.WASHER:
                return 'Lavador'
        
            default:
                return ''
        }
    },

    translateTypeToEnglish(type: StructureTypeEnum): string {
        switch (type) {
            case StructureTypeEnum.TERRACE:
                return 'Terrace'
            case StructureTypeEnum.AFRICAN_BED:
                return 'African bed'
            case StructureTypeEnum.GREENHOUSE:
                return 'Greenhouse'
            case StructureTypeEnum.GREENHOUSE_AFRICAN_BED:
                return 'Greenhouse african bed'
            case StructureTypeEnum.ROTARY_DRYER:
                return 'Rotary dryer'
            case StructureTypeEnum.SEMI_STATIC_DRYER:
                return 'Semi-static dryer'
            case StructureTypeEnum.STATIC_DRYER:
                return 'Static dryer'
            case StructureTypeEnum.SHADED_TERRACE:
                return 'Shaded terrace'
            case StructureTypeEnum.SHADED_AFRICAN_BED:
                return 'Shaded african bed'
            case StructureTypeEnum.FERMENTATION_TANK:
                return 'Fermentation tank'
            case StructureTypeEnum.PULPER:
                return 'Pulper'
            case StructureTypeEnum.HOPPER:
                return 'Hopper'
            case StructureTypeEnum.GRANARY:
                return 'Granary'
            case StructureTypeEnum.WASHER:
                return 'Washer'
            
            default:
                return ''
        }
    }
}
