import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsArray, IsOptional, ValidateIf, ValidateNested } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsString } from 'common/validation/decorators/IsString'
import { DateUtils } from 'common/utils/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'

import { HarvestFormValidator } from 'modules/harvest/components/harvest-form/inner/HarvestFormValidator'
import { ProductUsageFormValidator } from 'modules/logbook/components/product-usage/inner/ProductUsageFormValidator'
import { ISavePersonResponsibleReqDTO } from 'modules/logbook/services/dtos/request/ISavePersonResponsibleReqDTO'
import { ILogbookResponseDTO } from 'modules/harvest-planning/services/dtos/response/ILogbookResponseDTO'

export class LogbookFormValidator extends FormModelValidator {

    @IsNotEmpty()
    date: string

    @IsNotEmpty()
    @ValidateNested()
    @IsArray()
    peopleResponsible: ISavePersonResponsibleReqDTO[]

    @ValidateIf((dto) => !dto.harvestPlanningCode)
    @IsNotEmpty()
    maneuverCode?: number

    @ValidateIf((dto) => !dto.maneuverCode)
    @IsNotEmpty()
    harvestPlanningCode?: number

    @ValidateIf((dto) => !!dto.harvestPlanningCode)
    @IsNotEmpty()
    @ValidateNested()
    harvest: HarvestFormValidator

    @IsOptional()
    @ValidateNested()
    @IsArray()
    productUsages?: ProductUsageFormValidator[]

    @ValidateIf((dto) => !!dto.vehicleCode)
    @IsNotEmpty()
    startHourMeter?: number

    @IsOptional()
    endHourMeter?: number

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    pictures?: string[]

    @IsOptional()
    equipmentCode?: number

    @IsOptional()
    vehicleCode?: number

    @IsOptional()
    @ValidateNested()
    equipmentFuelConsume?: ProductUsageFormValidator

    @IsOptional()
    @ValidateNested()
    vehicleFuelConsume?: ProductUsageFormValidator

    @IsOptional()
    @IsString()
    notes?: string

    constructor(dto?: ILogbookResponseDTO) {
        super()

        if (dto) {
            this.date = DateUtils.getFormatted(dto.date, DateFormatEnum.BR_WITHOUT_TIME)
            // this.peopleResponsible = dto.peopleResponsible.map(pRes => ({
            //     code: pRes.code,
            //     personCode: pRes.person.code,
            //     quantity: pRes.quantity,
            //     paymentUnity: pRes.paymentUnity,
            //     paidValue: pRes.paidValue,
            //     notes: pRes.notes,
            //     valuesUnitPayment: pRes.person.valuesUnitPayment,
            // }))
            this.maneuverCode = dto.maneuverCode
            this.harvestPlanningCode = dto.harvestPlanningCode
            this.startHourMeter = dto.startHourMeter
            this.endHourMeter = dto.endHourMeter
            this.pictures = dto.pictures
            this.notes = dto.notes
            //   this.harvest = new HarvestFormValidator(dto.harvest)
            this.equipmentCode = dto.equipment ? dto.equipment.code : dto.equipmentCode
            this.vehicleCode = dto.vehicle ? dto.vehicle.code : dto.vehicleCode
            this.equipmentFuelConsume = !!dto.equipmentSummary && !!dto.equipmentSummary.fuelConsume
                ? new ProductUsageFormValidator({
                    ...dto.equipmentSummary.fuelConsume,
                    implementCode: dto.equipmentSummary.code,
                })
                : undefined
            this.vehicleFuelConsume = !!dto.vehicleSummary && !!dto.vehicleSummary.fuelConsume
                ?
                new ProductUsageFormValidator({
                    implementCode: dto.vehicleSummary.code,
                    ...dto.vehicleSummary.fuelConsume,
                })
                : undefined
            this.productUsages = !!dto.productUsages ? dto.productUsages.map(prdUsg => new ProductUsageFormValidator(prdUsg)) : undefined
        }

    }
}