/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsNumber } from 'common/validation/decorators/IsNumber'
import { IPurchaseResponseDTO } from 'modules/maneuver/services/dtos/responses/IPurchaseResponseDTO'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { IsOptional } from 'class-validator'

export class PurchaseFormValidator extends FormModelValidator {

    @IsNumber()
    @IsNotEmpty()
    productCode: number

    @IsNotEmpty()
    quantity: number

    @IsNotEmpty()
    date: string

    @IsNotEmpty()
    paidValue: number

    @IsNotEmpty()
    season: number

    @IsOptional()
    notes: string

    @IsOptional()
    paymentDate: string

    constructor(dto?: IPurchaseResponseDTO) {
        super(dto)
        Object.assign(this, dto)
        if (dto?.quantity)
            this.quantity = +dto.quantity
        if (dto?.date)
            this.date = DateUtils.toMoment(dto.date, DateFormatEnum.EUA_WITHOUT_TIME).format('DD/MM/YYYY')
        if (dto?.paymentDate)
            this.paymentDate = DateUtils.toMoment(dto.paymentDate, DateFormatEnum.EUA_WITHOUT_TIME).format('DD/MM/YYYY')
    }
}