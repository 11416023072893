import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { StepsCP } from 'common/components/steps/StepsCP'
import { BatchGenerationUtils } from 'modules/batch/utils/BatchGenerationUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { HarvestSelectionStepICP } from 'modules/batch/components/batch-generation-drawer/inner/HarvestSelectionStepICP'
import { BatchInformationsStepCP } from 'modules/batch/components/batch-generation-drawer/inner/BatchInformationsStepICP'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import { ISaveBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISaveBatchRequestDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { ISaveMultipleBatchesReqDTO } from 'modules/batch/services/dtos/requests/ISaveMultipleBatchesReqDTO'
import { DateUtils } from 'common/utils/DateUtils'
import { ListActions } from 'common/components/list/inner/ListActions'

interface IBatchGenerationDrawerCPProps {
    show: boolean
    onCancel: () => void
    onSuccess: () => void
}

export function BatchGenerationDrawerCP(props: IBatchGenerationDrawerCPProps): JSX.Element {
    const [selectedHarvests, setSelectedHarvests] = useState<IHarvestResponseDTO[]>([])

    const [currentStep, setCurrentStep] = useState<number>(0)
    const [disableNext, setDisableNext] = useState<boolean>(false)
    const [disablePrev, setDisablePrev] = useState<boolean>(false)

    const [batches, setBatches] = useState<ISaveBatchRequestDTO[]>([])

    const saveRequest = useRequest()
    useEffect(onRequestChange, [saveRequest.awaiting])

    useEffect(onNavigate, [currentStep, selectedHarvests])
    useEffect(init, [props.show])

    function init(): void {
        if (props.show) {
            setCurrentStep(0)
            setBatches([])
            setSelectedHarvests([])
            ListActions.unselectAll()
        }
    }

    function onNavigate(): void {
        if (currentStep === 0)
            setDisableNext(_.isEmpty(selectedHarvests))

    }

    function onSave(): void {

        const dto: ISaveMultipleBatchesReqDTO = {
            batches: batches.map(bt => {
                const maturationValues = (bt.litersPerMaturation && Object.values(bt.litersPerMaturation))

                return ({
                    ...bt,
                    litersPerMaturation: !!bt.litersPerMaturation && !_.every(maturationValues, mt => mt === undefined) ? bt.litersPerMaturation : undefined,
                    date: DateUtils.getDateFormattedToApi(bt.date!)
                })
            }),
            harvests: selectedHarvests.map(hv => hv.code),
            fieldCodes: [...new Set(selectedHarvests.map(hvt => hvt.field.code))]
        }

        saveRequest.runRequest(BatchRequests.saveMultiple(dto))
    }

    function onRequestChange(): void {
        if (saveRequest.awaiting || !saveRequest.tried)
            return

        if (!saveRequest.success || !saveRequest.returnData) {
            if (saveRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveRequest.returnData, saveRequest.error)
            return RequestUtils.showDefaultErrorNotification(saveRequest.error, 'Erro ao salvar lotes!')
        }

        NotificationHelper.success('Lotes salvos com sucesso!')
        ListActions.unselectAll()
        props.onSuccess()
    }

    const harvestSelectionStep = (
        <HarvestSelectionStepICP
            setSelectedHarvests={setSelectedHarvests}
            selectedHarvests={selectedHarvests}
        />
    )

    const batchInformationStep = (
        <BatchInformationsStepCP
            harvests={selectedHarvests}
            batches={batches}
            setBatches={setBatches}
        />
    )

    return (
        <DrawerCP
            title={'Gerar lotes'}
            visible={props.show}
            width={'80%'}
            push={false}
            onClose={() => {
                setCurrentStep(0)
                ListActions.unselectAll()
                props.onCancel()
            }}
        >
            <WrapperSCP>

                <StepsCP
                    labelPlacement={'horizontal'}
                    type={'navigation'}
                    current={currentStep}
                    direction={'horizontal'}
                    showNavigationButtons={true}
                    disableNext={disableNext}
                    disablePrev={disablePrev}
                    onChange={setCurrentStep}
                    onFinish={onSave}
                    loading={saveRequest.awaiting}
                    prevNavigationConfirmMsg={'Antenção! Ao voltar para a etapa anterior os dados já preenchidos nessa etapa podem ser perdidos. Deseja voltar mesmo assim?'}
                    steps={BatchGenerationUtils.getStepsConfig(
                        harvestSelectionStep,
                        batchInformationStep,
                    )}
                />
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`

const ActionsWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }  
`