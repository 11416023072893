import React from 'react'
import { ReadMoreCP } from 'common/components/read-more/ReadMoreCP'
import styled from 'styled-components'
import { faLayerGroup, faLeaf, faTractor } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'

interface IPropertyCardContentICPProps{
    totalArea: number
    dedicatedArea: number
    description: string
    totalFields: number
}

export function PropertyCardContentICP(props: IPropertyCardContentICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <ResumeWrapperSCP>
                <DataWrapperSCP>
                    <FontAwsomeIconCP icon={faLayerGroup} />
                    {`${props.totalArea} ha`}
                </DataWrapperSCP>
                <DataWrapperSCP>
                    <FontAwsomeIconCP icon={faTractor} />
                    {`${props.dedicatedArea} ha`}

                </DataWrapperSCP>
                <DataWrapperSCP>
                    <FontAwsomeIconCP icon={faLeaf} />
                    {props.totalFields}
                </DataWrapperSCP>
            </ResumeWrapperSCP>
            <DescriptionWrapperSCP>
                <ReadMoreCP
                    minTextLength={80}
                    idealTextLength={100}
                    maxTextLength={200}
                    text={props.description}
                />
            </DescriptionWrapperSCP>
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div`
    padding: 0 15px;
    min-height: 120px;
`
const ResumeWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 85%;
`
const DescriptionWrapperSCP = styled.div`
   margin: 10px 0;
`
const DataWrapperSCP = styled.div`
   display: grid;
   grid-template-columns: 1fr 2fr;
   svg{
        font-size: 20px;
   }
`
