/* eslint-disable react/jsx-one-expression-per-line */
import styled from 'styled-components'
import React from 'react'
import { ModalCP } from 'common/components/modal/ModalCP'
import { ButtonCP } from 'common/components/button/ButtonCP'

interface ITermsOfUseDrawerCPProps {
    show: boolean
    onAccept: () => void
    onClose: () => void
    loading: boolean
}

export function TermsOfUseDrawerCP(props: ITermsOfUseDrawerCPProps): JSX.Element {

    return (
        <ModalCP
            title={'Termos de uso '}
            visible={props.show}
            disableOutsideClickClosing={true}
            footer={[
                <ButtonWrapperSCP key={'md_footer'}>
                    <ButtonCP
                        type={'primary'}
                        onClick={props.onAccept}
                        wrapperStyle={{ width: '100%' }}
                        loading={props.loading}
                    >
                        Continuar
                    </ButtonCP>
                </ButtonWrapperSCP>
            ]}
            onClose={props.onClose}
        >
            <WrapperSCP>
                <p className={'c7'}><span className={'c6 c0'}>TERMOS E CONDI&Ccedil;&Otilde;ES DE USO (PRODUTORES)</span></p>
                <p className={'c1'}><span className={'c0'}>1. Introdu&ccedil;&atilde;o e disposi&ccedil;&otilde;es gerais</span></p>
                <p className={'c1'}><span className={'c5'}>Seja bem-vindo a </span><span className={'c0'}>Flowins</span><span className={'c5'}>! Este documento, Termos e Condi&ccedil;&otilde;es de Uso, rege o acesso e uso do servi&ccedil;o &ldquo;</span><span className={'c0'}>Gerente Flowins</span><span className={'c5'}>&rdquo;, no Brasil, pelos produtores rurais de caf&eacute;,</span><span className={'c0'}>&nbsp;</span><span className={'c5'}>devidamente regularizados perante os &oacute;rg&atilde;os competentes, denominados doravante</span><span className={'c0'}>&nbsp;Produtor Membro,</span><span className={'c5'}>&nbsp;por meio de aplica&ccedil;&atilde;o tecnol&oacute;gica (conhecido como aplicativo), sites (s&iacute;tios) de internet e servi&ccedil;os e produtos disponibilizados pela </span><span className={'c0'}>FLOWINS PLATAFORMA DE TECNOLOGIA LTDA</span><span className={'c5'}>, inscrita no CNPJ sob o n&uacute;mero 33.350.408/0001-42, com sede em Rua Joana Silveria da Cruz Brito, n&deg; 10, Bairro S&eacute;culo, em Tr&ecirc;s Pontas/MG.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>Leia com aten&ccedil;&atilde;o os termos de uso antes de iniciar ou acessar o uso dos servi&ccedil;os</span></p>
                <p className={'c1'}><span className={'c5'}>Em conson&acirc;ncia com a Legisla&ccedil;&atilde;o Vigente, a </span><span className={'c0'}>Flowins (empresa)</span><span className={'c5'}>&nbsp;possui Declara&ccedil;&atilde;o de Privacidade de Usu&aacute;rios, dispon&iacute;vel no aplicativo e no s&iacute;tio. A empresa coloca-se a disposi&ccedil;&atilde;o para esclarecimentos atrav&eacute;s de seus canais de relacionamento com o Produtor Membro.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro ao efetivar o cadastro e utilizar o servi&ccedil;o &ldquo;</span><span className={'c0'}>Gerente Flowins</span><span className={'c5'}>&rdquo;, declara ter lido e entendido os Termos de Uso e que est&aacute; de acordo com os termos e condi&ccedil;&otilde;es dispostos neste documento, aceitando todas as suas disposi&ccedil;&otilde;es e estabelecendo um relacionamento contratual entre o Produtor Membro e a </span><span className={'c0'}>Flowins</span><span className={'c5'}>. </span></p>
                <p className={'c1'}><span className={'c5'}>Outros Termos de Uso Espec&iacute;ficos poder&atilde;o ser inclusos mediante presta&ccedil;&atilde;o de servi&ccedil;o espec&iacute;fico, tais como habilita&ccedil;&otilde;es para determinadas fun&ccedil;&otilde;es, licenciamentos, promo&ccedil;&otilde;es, atividades de recompensa. Os Termos de uso Espec&iacute;ficos prevalecem sobre os Termos de Uso em caso de conflito somente naquilo que forem espec&iacute;ficos.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro permite que a empresa envie notifica&ccedil;&otilde;es administrativas pelo aplicativo, e-mail ou SMS ou por qualquer meio disponibilizado com conte&uacute;do de natureza informativa e promocional relacionado aos servi&ccedil;os.</span></p>
                <p className={'c1'}><span className={'c5'}>A empresa pode a qualquer momento realizar altera&ccedil;&otilde;es e atualiza&ccedil;&otilde;es nos Termos de Uso sem aviso pr&eacute;vio ao Produtor Membro. Havendo restri&ccedil;&atilde;o de direitos ao Produtor Membro, a empresa pode comunic&aacute;-lo para que aceite os novos Termos de Uso. O Produtor Membro deve periodicamente verificar a altera&ccedil;&atilde;o dos Termos de Uso. O fato de o Produtor Membro continuar acessando a sua conta e continuar usando os servi&ccedil;os </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo;</span><span className={'c5'}>&nbsp;representa o seu consentimento em vincular-se aos Termos alterados.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>2. Dados do Produtor Membro</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;possui a Pol&iacute;tica de Privacidade e as informa&ccedil;&otilde;es fornecidas est&atilde;o sujeitas ao tratamento referido nas Pol&iacute;ticas de Privacidade. O Produtor Membro entende e concorda que o seu uso e a presta&ccedil;&atilde;o do servi&ccedil;o envolvem a coleta e utiliza&ccedil;&atilde;o de informa&ccedil;&otilde;es, incluindo o envio dos dados para outras localidades (inclusive para o exterior) com o intuito de armazenamento, tratamento e processamento para utiliza&ccedil;&atilde;o da </span><span className={'c0'}>Flowins</span><span className={'c5'}>, para envio &agrave;s autoridades competentes para fins de valida&ccedil;&atilde;o ou informa&ccedil;&atilde;o, para fins da presta&ccedil;&atilde;o de servi&ccedil;o e demais finalidades previstas na pol&iacute;tica de privacidade. A pol&iacute;tica de privacidade da </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;constitui parte integrante dos termos.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro concorda, desde j&aacute;, que a </span><span className={'c0'}>Flowins </span><span className={'c5'}>proceda no envio dos dados declarados pelo Produtor Membro nos campos dispon&iacute;veis no sistema para potenciais compradores e para exportadores, com finalidades comerciais, inclusive a descri&ccedil;&atilde;o da propriedade, localiza&ccedil;&atilde;o, fotos, informa&ccedil;&otilde;es das colheitas e p&oacute;s-colheitas, processos, beneficiamento, armazenagem, caracter&iacute;sticas dos lotes e avalia&ccedil;&otilde;es.</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;n&atilde;o repassar&aacute; os documentos que o Produtor Membro fizer </span><span className={'c3'}>upload</span><span className={'c5'}>&nbsp;na aba &ldquo;Documentos&rdquo; de cada propriedade cadastrada e nem o n&uacute;mero da inscri&ccedil;&atilde;o estadual.</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;armazenar&aacute; os dados do Produtor Membro por at&eacute; tr&ecirc;s meses ap&oacute;s o encerramento da conta do servi&ccedil;o </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo;</span><span className={'c5'}>, seja qual for a modalidade de encerramento. Transcorrido o prazo, caso o Produtor Membro queira reativar o servi&ccedil;o, ter&aacute; de fazer nova conta e inserir novamente todas as informa&ccedil;&otilde;es e documentos.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>3. Cadastro na Plataforma</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro deve realizar o cadastro e manter apenas uma conta vinculada a </span><span className={'c0'}>Flowins</span><span className={'c5'}>. O Produtor Membro deve ter capacidade civil e possuir os requisitos legais para o exerc&iacute;cio da atividade econ&ocirc;mica, em especial a manuten&ccedil;&atilde;o da regularidade da inscri&ccedil;&atilde;o estadual. O Produtor Membro que deseja utilizar o servi&ccedil;o </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo; </span><span className={'c5'}>deve preencher os campos de cadastro exigidos e responder&aacute; pela veracidade das informa&ccedil;&otilde;es declaradas. O Produtor Membro deve manter as informa&ccedil;&otilde;es atualizadas e corretas. A n&atilde;o observ&acirc;ncia quanto &agrave; veracidade dos dados pode acarretar o bloqueio do acesso ao servi&ccedil;o, a crit&eacute;rio exclusivo da empresa.</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>, ao receber a documenta&ccedil;&atilde;o e a confirma&ccedil;&atilde;o do cadastro, efetuar&aacute; uma an&aacute;lise e pode, a seu crit&eacute;rio exclusivo, aceitar ou recusar a solicita&ccedil;&atilde;o do Produtor. A empresa tamb&eacute;m pode realizar pesquisas que considerar oportunas em diversos locais, governamentais ou n&atilde;o, a fim de realizar a checagem das informa&ccedil;&otilde;es cadastradas.</span></p>
                <p className={'c1'}><span className={'c5'}>O acesso &agrave; conta e ao perfil do Produtor Membro ao servi&ccedil;o </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo;</span><span className={'c5'}>&nbsp;somente ser&aacute; disponibilizado ap&oacute;s a confirma&ccedil;&atilde;o do pagamento do servi&ccedil;o contratado. A empresa ter&aacute; o prazo de at&eacute; cinco dias &uacute;teis ap&oacute;s a confirma&ccedil;&atilde;o do pagamento para franquear acesso ao sistema e aos servi&ccedil;os contratados.</span></p>
                <p className={'c1'}><span className={'c5'}>O perfil e conta do Produtor Membro s&atilde;o intransfer&iacute;veis. O Produtor Membro n&atilde;o deve compartilhar ou transferir sua conta com terceiros. A transfer&ecirc;ncia ou o compartilhamento do perfil com outras pessoas implica no imediato cancelamento da Conta do Produtor Membro, al&eacute;m da possibilidade de encaminhamento do caso &agrave;s autoridades p&uacute;blicas para eventual penalidade criminal e civil.</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;pode, a seu crit&eacute;rio, solicitar informa&ccedil;&otilde;es adicionais bem como utilizar m&eacute;todos de identifica&ccedil;&atilde;o do Produtor Membro, tanto na efetiva&ccedil;&atilde;o do cadastro como no uso dos servi&ccedil;os contratados, a qualquer tempo. Havendo diverg&ecirc;ncias &eacute; poss&iacute;vel o cancelamento do perfil, a crit&eacute;rio exclusivo da </span><span className={'c0'}>Flowins</span><span className={'c5'}>.</span></p>
                <p className={'c1'}><span className={'c5'}>As informa&ccedil;&otilde;es prestadas no cadastramento da conta s&atilde;o de exclusiva responsabilidade de quem as inseriu. Eventuais danos ou preju&iacute;zos decorrentes deste cadastro, ser&atilde;o tomadas medidas cab&iacute;veis pela empresa a fim de resguardar o interesse e a integridade dos usu&aacute;rios, dos compradores e da pr&oacute;pria empresa. Neste sentido, o Produtor Membro se compromete a utilizar sua conta e o aplicativo da empresa apenas com os fins estritamente legais e permitidos por este Termo de Uso.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>4. Servi&ccedil;os ofertados pelo &ldquo;Gerente Flowins&rdquo;</span></p>
                <p className={'c1'}><span className={'c5'}>Os servi&ccedil;os prestados pela empresa consistem na disponibiliza&ccedil;&atilde;o de ferramenta de rastreabilidade, emiss&atilde;o de laudos e relat&oacute;rios da produ&ccedil;&atilde;o de caf&eacute; do Produtor Membro, de acordo com os quantitativos indicados nos diferentes pacotes do servi&ccedil;o &ldquo;</span><span className={'c0'}>Gerente Flowins</span><span className={'c5'}>&rdquo;, bem como de intermedia&ccedil;&atilde;o de propostas de compra e venda dos lotes de caf&eacute; que o Produtor Membro especificamente destinar &agrave; venda atrav&eacute;s da plataforma por terceiros-compradores.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro, ao escolher um dos pacotes de servi&ccedil;os ofertados pelo </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo;</span><span className={'c5'}>, declara estar ciente e que concorda com a exist&ecirc;ncia de diferen&ccedil;as quantitativas e qualitativas nos servi&ccedil;os disponibilizados e oferecidos, bem como h&aacute; um diferente valor de anuidade para cada pacote, conforme indica&ccedil;&atilde;o feita no momento da contrata&ccedil;&atilde;o do pacote. O Produtor Membro n&atilde;o poder&aacute; solicitar servi&ccedil;os que n&atilde;o estejam contemplados no pacote por ele contratado.</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins </span><span className={'c5'}>poder&aacute; unilateralmente alterar o valor das anuidades dos pacotes e os servi&ccedil;os disponibilizados em cada um dos pacotes, n&atilde;o alcan&ccedil;ando ao Produtor Membro que j&aacute; contratou algum dos pacotes do </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo;</span><span className={'c5'}>&nbsp;direito &agrave; revis&atilde;o da anuidade j&aacute; paga ou &agrave; inclus&atilde;o de novos servi&ccedil;os.</span></p>
                <p className={'c1'}><span className={'c5'}>Condicionado aos termos deste contrato, &eacute; outorgado ao Produtor Membro a permiss&atilde;o de uso do software, sendo outorgado ao Produtor Membro uma licen&ccedil;a limitada, n&atilde;o exclusiva, revog&aacute;vel e n&atilde;o transfer&iacute;vel atrav&eacute;s de dispositivos compat&iacute;veis com o aplicativo. Cabe observar que a sublicen&ccedil;a tamb&eacute;m n&atilde;o &eacute; permitida. Quaisquer direitos n&atilde;o expressamente outorgados neste Termo s&atilde;o reservados &agrave; </span><span className={'c0'}>Flowins</span><span className={'c5'}>. </span></p>
                <p className={'c1'}><span className={'c5'}>A empresa n&atilde;o se responsabiliza por quaisquer danos sofridos pelo Produtor Membro em raz&atilde;o de c&oacute;pia, transfer&ecirc;ncia, distribui&ccedil;&atilde;o, &ldquo;pirataria&rdquo; ou qualquer outra forma de utiliza&ccedil;&atilde;o dos conte&uacute;dos protegidos disponibilizados no aplicativo sen&atilde;o aqueles fornecidos pela empresa.</span></p>
                <p className={'c1'}><span className={'c5'}>O mapeamento, o rastreamento e os relat&oacute;rios da produ&ccedil;&atilde;o ser&atilde;o gerados a partir das informa&ccedil;&otilde;es declaradas pelo Produtor Membro e disponibilizados na plataforma </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo; </span><span className={'c5'}>para consulta. Dessa forma, eventual inconsist&ecirc;ncia decorrente de informa&ccedil;&otilde;es inexatas declaradas pelo Produtor Membro n&atilde;o ser&aacute; de responsabilidade da </span><span className={'c0'}>Flowins.</span></p>
                <p className={'c1'}><span className={'c5'}>As avalia&ccedil;&otilde;es das amostras enviadas pelo Produtor Membro &agrave; </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;ser&atilde;o realizadas por profissional habilitado, que atuar&aacute; com independ&ecirc;ncia t&eacute;cnica. O resultado da avalia&ccedil;&atilde;o n&atilde;o poder&aacute; ser negociado entre as partes, pois decorre da an&aacute;lise de crit&eacute;rios t&eacute;cnicos por profissional habilitado.</span></p>
                <p className={'c1'}><span className={'c5'}>Atrav&eacute;s do servi&ccedil;o disponibilizado o Produtor Membro poder&aacute; receber propostas de compras dos lotes especificamente destinados &agrave; venda pelo Produtor Membro no sistema, cabendo exclusivamente ao Produtor Membro o aceite das propostas, conforme seus crit&eacute;rios pessoais. A </span><span className={'c0'}>Flowins</span><span className={'c6 c5'}>&nbsp;n&atilde;o se responsabiliza pelas propostas e nem garante que os lotes destinados &agrave; venda pela plataforma pelo Produtor Membro receber&atilde;o propostas de compra. A empresa tamb&eacute;m n&atilde;o se responsabiliza pelo cumprimento das condi&ccedil;&otilde;es ofertadas pelos compradores, cabendo ao Produtor Membro a busca da tutela das condi&ccedil;&otilde;es acordadas.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro e a </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;poder&atilde;o ajustar valor m&iacute;nimo de negocia&ccedil;&atilde;o, especificando o c&oacute;digo do caf&eacute;, a quantidade de sacas, a qualidade e o armaz&eacute;m onde localizado, a fim de individualizar o caf&eacute; submetido ao valor m&iacute;nimo. Tal ajuste poder&aacute; se dar atrav&eacute;s de qualquer meio, seja pela assinatura de instrumento de &ldquo;autoriza&ccedil;&atilde;o de negocia&ccedil;&atilde;o&rdquo;, seja atrav&eacute;s de contato das partes por e-mail, SMS, aplicativo de conversa, espa&ccedil;o disponibilizado na plataforma e at&eacute; mesmo oralmente para representante da </span><span className={'c0'}>Flowins</span><span className={'c6 c5'}>. </span></p>
                <p className={'c1'}><span className={'c6 c5'}>A indica&ccedil;&atilde;o de valor m&iacute;nimo vincula o Produtor Membro perante terceiros, caso algum comprador fa&ccedil;a oferta em valor igual ou superior ao m&iacute;nimo, dentro das especifica&ccedil;&otilde;es e do per&iacute;odo de validade, n&atilde;o podendo haver a retrata&ccedil;&atilde;o do Produtor Membro e respondendo o Produtor Membro por perdas e danos no caso de impossibilidade de realiza&ccedil;&atilde;o da transa&ccedil;&atilde;o. Caso n&atilde;o haja indica&ccedil;&atilde;o de per&iacute;odo de validade, o valor m&iacute;nimo indicado valer&aacute; at&eacute; nova manifesta&ccedil;&atilde;o do Produtor Membro no sentido de alterar o valor m&iacute;nimo ou retirar o lote de caf&eacute; do sistema como dispon&iacute;vel para venda.</span></p>
                <p className={'c1'}><span className={'c5'}>A empresa pode prestar outros servi&ccedil;os ou oferecer produtos gratuitos ou onerosos, a seu crit&eacute;rio. Salvo se diferentemente definidos nos Termos Espec&iacute;ficos, ser&atilde;o regidos por estes Termos e definidos como servi&ccedil;o para todos os efeitos legais deste Termo.</span></p>
                <p className={'c1 c4'}><span className={'c6 c8'} /></p>
                <p className={'c1'}><span className={'c0'}>5. Do Pagamento pelos Servi&ccedil;os Contratados</span></p>
                <p className={'c1'}><span className={'c5'}>O pagamento dos servi&ccedil;os pelo Produtor Membro &agrave; </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;ser&aacute; cobrado em todo ou em parte, a crit&eacute;rio da empresa. Originalmente, o licenciamento &eacute; realizado a t&iacute;tulo oneroso. A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;cobra do Produtor Membro um valor anual de acordo com os crit&eacute;rios estabelecidos entre as partes no ato da contrata&ccedil;&atilde;o, de acordo com o pacote escolhido pelo Produtor Membro.</span></p>
                <p className={'c1'}><span className={'c5'}>O pagamento da anuidade franquear&aacute; ao Produtor Membro o acesso aos servi&ccedil;os contratados por doze meses a partir da confirma&ccedil;&atilde;o do pagamento, n&atilde;o havendo a possibilidade de rompimento antecipado do contrato e nem a possibilidade de reembolso por desist&ecirc;ncia.</span></p>
                <p className={'c1'}><span className={'c5'}>Tamb&eacute;m ser&aacute; cobrado do Produtor Membro o percentual de 0,5% do valor bruto de cada venda realizada pelo Produtor Membro para comprador intermediado pela </span><span className={'c0'}>Flowins. </span><span className={'c5'}>Este percentual n&atilde;o ser&aacute; cobrado do Produtor Membro caso ela tenha aderido tamb&eacute;m ao servi&ccedil;o &ldquo;Conta Digital Flowins&rdquo;.</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;disponibiliza ao Produtor Membro a nota fiscal por meio eletr&ocirc;nico. O comprovante de pagamento pode ser disponibilizado por meio eletr&ocirc;nico conforme solicita&ccedil;&atilde;o do Produtor Membro a </span><span className={'c0'}>Flowins </span><span className={'c5'}>atrav&eacute;s dos canais de relacionamento.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>6. Inexist&ecirc;ncia de Rela&ccedil;&atilde;o de Trabalho, V&iacute;nculo de Emprego e Outros</span></p>
                <p className={'c1'}><span className={'c5'}>N&atilde;o existe v&iacute;nculo de emprego, qualquer v&iacute;nculo de natureza societ&aacute;ria, empregat&iacute;cia e/ou econ&ocirc;mica entre o Produtor Membro e a </span><span className={'c0'}>Flowins</span><span className={'c5'}>, sendo livre o Produtor Membro para lan&ccedil;ar e utilizar o sistema disponibilizado da maneira que melhor entender, bem como sendo livre para aceitar ou recusar qualquer proposta de compra de lotes que seja intermediada pela </span><span className={'c0'}>Flowins.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro &eacute; quem decide em que momento far&aacute; os lan&ccedil;amentos de informa&ccedil;&otilde;es no sistema, quais informa&ccedil;&otilde;es lan&ccedil;ar&aacute; e tamb&eacute;m se responsabiliza pela veracidade e integridade destas informa&ccedil;&otilde;es. </span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>7.Responsabilidade pelos Servi&ccedil;os: </span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;&eacute; respons&aacute;vel por todos os problemas aos quais der causa injustificada. A empresa deve manter ainda o bom funcionamento dos servi&ccedil;os atrav&eacute;s do s&iacute;tio ou do aplicativo na maior medida poss&iacute;vel e disponibiliza canais para auxiliar em d&uacute;vidas e problemas aos Usu&aacute;rios e Prestadores de Servi&ccedil;o, observado os termos que seguem.</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;&eacute; respons&aacute;vel pela avalia&ccedil;&atilde;o das amostras de caf&eacute; enviadas para an&aacute;lise, n&atilde;o podendo o </span><span className={'c5 c9'}>Produtor Membro alterar as avalia&ccedil;&otilde;es e pontua&ccedil;&otilde;es constantes do sistema para os lotes de caf&eacute; cadastrados. Ademais,</span><span className={'c5'}>&nbsp;n&atilde;o h&aacute; garant</span><span className={'c5 c9'}>ia</span><span className={'c5'}>&nbsp;que os compradores concordar&atilde;o com a an&aacute;lise e pontua&ccedil;&atilde;o aplicadas &agrave;s amostras e tampouco h&aacute; responsabilidade da empresa sobre discord&acirc;ncias em rela&ccedil;&atilde;o a suas an&aacute;lises.</span></p>
                <p className={'c1 c4'}><span className={'c10'} /></p>
                <p className={'c1'}><span className={'c0'}>8. Responsabilidades do Produtor Membro:</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro deve agir em conformidade com as regras deste Termo de uso e ter a conduta condizente com a legisla&ccedil;&atilde;o aplic&aacute;vel. O descumprimento dos Termos de uso ou mesmo da legisla&ccedil;&atilde;o vigente pode acarretar a exclus&atilde;o do Produtor Membro, a crit&eacute;rio da </span><span className={'c0'}>Flowins</span><span className={'c5'}>, impossibilitando o acesso ao servi&ccedil;o. Ao usar o servi&ccedil;o o Prestador de Servi&ccedil;o aceita o Termo de Uso e obriga-se a:</span></p>
                <p className={'c1'}><span className={'c5'}>- Efetuar o pagamento &agrave; </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;referente ao servi&ccedil;o contratado, seja anuidade, mensalidade, ou, quando aplic&aacute;vel, o percentual sobre vendas efetuadas;</span></p>
                <p className={'c1'}><span className={'c5'}>- Estar ciente e obedecer a todas as exig&ecirc;ncias legais e regulat&oacute;rias da atividade desenvolvida;</span></p>
                <p className={'c1'}><span className={'c5'}>- Ser respons&aacute;vel pelos produtos fornecidos;</span></p>
                <p className={'c1'}><span className={'c5'}>- Ser respons&aacute;vel pelo meio de pagamento no caso de vendas intermediadas pela </span><span className={'c0'}>Flowins</span><span className={'c5'}>.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro &eacute; respons&aacute;vel por todas as informa&ccedil;&otilde;es prestadas &agrave; </span><span className={'c0'}>Flowins</span><span className={'c5'}>, sendo sua inteira responsabilidade por eventuais repercuss&otilde;es jur&iacute;dicas decorrentes de informa&ccedil;&otilde;es enganosas, falsas ou desatualizadas.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro se compromete a seguir todas as normas ambientais, administrativas, c&iacute;veis, trabalhistas e tribut&aacute;rias, n&atilde;o havendo qualquer responsabilidade solid&aacute;ria ou subsidi&aacute;ria da </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;por eventuais descumprimentos, bem como deve sempre manter regular a sua inscri&ccedil;&atilde;o estadual.</span></p>
                <p className={'c1'}><span className={'c5'}>Caso o Produtor Membro opte por destinar lote para venda atrav&eacute;s da plataforma do </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo;</span><span className={'c5'}>, ele dever&aacute; informar o prazo em que a </span><span className={'c0'}>Flowins </span><span className={'c5'}>ter&aacute; exclusividade na intermedia&ccedil;&atilde;o da venda e ter&aacute; a responsabilidade por atentar a esse prazo, devendo arcar com eventuais preju&iacute;zos decorrentes de negocia&ccedil;&otilde;es frustradas pelo descumprimento do prazo de exclusividade.</span></p>
                <p className={'c1'}><span className={'c5'}>Quando a </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;encaminhar proposta de compra por lote especificamente destinado pelo Produtor Membro para venda pela plataforma, o Produtor Membro dever&aacute; responder se aceita a proposta apresentada </span><span className={'c5 c9'}>em at&eacute; cinco dias &uacute;teis</span><span className={'c5'}>. Caso transcorra o prazo sem resposta do Produtor Membro, o </span><span className={'c0'}>Flowins</span><span className={'c6 c5'}>&nbsp;poder&aacute; considerar a proposta como rejeitada.</span></p>
                <p className={'c1'}><span className={'c6 c5'}>No caso de pr&eacute;via indica&ccedil;&atilde;o de valor m&iacute;nimo de venda pelo Produtor Membro, ele se obriga a concretizar a venda para o comprador que oferecer valor igual ou superior ao m&iacute;nimo indicado, pelo caf&eacute; especificado quando da indica&ccedil;&atilde;o do valor m&iacute;nimo, sob pena de indeniza&ccedil;&atilde;o da Flowins e do comprador pelas perdas e danos decorrentes do desrespeito ao disposto neste par&aacute;grafo.</span></p>
                <p className={'c1'}><span className={'c5'}>No caso de vendas internacionais intermediadas pela </span><span className={'c0'}>Flowins</span><span className={'c5'}>, o Produtor Membro declara ter ci&ecirc;ncia que o valor em moeda corrente nacional poder&aacute; variar, em decorr&ecirc;ncia da volatilidade do d&oacute;lar americano ou de outra moeda internacional em que a venda internacional venha a ser negociada, n&atilde;o tendo a </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;qualquer responsabilidade sobre eventuais diferen&ccedil;as decorrentes de varia&ccedil;&otilde;es cambiais.</span></p>
                <p className={'c1'}><span className={'c5'}>A emiss&atilde;o de Nota Fiscal pelas vendas realizadas pelo Produtor Membro e intermediadas pela </span><span className={'c0'}>Flowins</span><span className={'c6 c5'}>&nbsp;&eacute; de responsabilidade do Produtor Membro.</span></p>
                <p className={'c1'}><span className={'c5'}>Nas vendas intermediadas pela </span><span className={'c0'}>Flowins</span><span className={'c5'}>, o transporte da mercadoria ser&aacute; de responsabilidade do Produtor Membro. Qualquer intercorr&ecirc;ncia, atraso, dano ou deteriora&ccedil;&atilde;o decorrente do transporte ser&aacute; de inteira responsabilidade do Produtor Membro.</span></p>
                <p className={'c1'}><span className={'c5'}>Nos casos em que aplic&aacute;vel a comiss&atilde;o de 0,5% sobre o valor bruto das vendas realizadas pelo Produtor Membro e intermediadas pela </span><span className={'c0'}>Flowins</span><span className={'c5'}>, o Produtor Membro dever&aacute; pagar &agrave; </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;o valor integral, mesmo no caso de eventual inadimpl&ecirc;ncia do comprador.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro se compromete a enviar para an&aacute;lise amostra fiel ao conte&uacute;do do lote, n&atilde;o tendo a </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;qualquer responsabilidade sobre eventual discord&acirc;ncia de terceiros ou compradores que decorra da diverg&ecirc;ncia de caracter&iacute;sticas entre o lote e a amostra enviada para an&aacute;lise.</span></p>
                <p className={'c1'}><span className={'c5'}>No caso de vendas intermediadas pela </span><span className={'c0'}>Flowins</span><span className={'c5'}>, o Produtor Membro garante que far&aacute; a entrega exatamente do lote de caf&eacute; lan&ccedil;ado no sistema como destinado &agrave; venda pela plataforma e submetido a an&aacute;lise e avalia&ccedil;&atilde;o pela</span><span className={'c0'}>&nbsp;Flowins.</span><span className={'c5'}>&nbsp;Caso o comprador aponte alguma diverg&ecirc;ncia, em especial em rela&ccedil;&atilde;o &agrave; an&aacute;lise, &agrave; avalia&ccedil;&atilde;o e &agrave; pontua&ccedil;&atilde;o do lote, a responsabilidade ser&aacute; toda do Produtor Membro, inclusive se da diverg&ecirc;ncia entre as informa&ccedil;&otilde;es constantes do sistema e as apontadas pelo comprador implicar o pagamento a menor do valor acordado, caso em que o preju&iacute;zo ser&aacute; suportado pelo Produtor Membro.</span></p>
                <p className={'c1'}><span className={'c5'}>Se o comprador entrar em disputa ap&oacute;s o recebimento da carga de caf&eacute; do lote comprado, discordando das caracter&iacute;sticas, da an&aacute;lise, da avalia&ccedil;&atilde;o e/ou da pontua&ccedil;&atilde;o do caf&eacute; enviado, haver&aacute; tratativas para alinhar as diverg&ecirc;ncias e o comprador poder&aacute; apresentar nova proposta de valor de compra. O aceite da nova proposta cabe ao Produtor Membro, n&atilde;o conferindo a </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;qualquer garantia de que o comprador concordar&aacute; com as caracter&iacute;sticas, com a an&aacute;lise, com a avalia&ccedil;&atilde;o e/ou com a pontua&ccedil;&atilde;o do caf&eacute; e nem de que haver&aacute; a manuten&ccedil;&atilde;o do valor apresentado na proposta inicial no caso de diverg&ecirc;ncia ou disputa.</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;n&atilde;o poder&aacute; ser responsabilizada por quaisquer perdas, preju&iacute;zos, ou danos de qualquer natureza que sejam oriundos da rela&ccedil;&atilde;o entre Produtor Membro e comprador. O Produtor Membro entende e concorda que a </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;n&atilde;o ser&aacute; respons&aacute;vel por eventual dano, inadimpl&ecirc;ncia ou mora que possam ser causados por um comprador ao Produtor Membro. Cabe ressaltar que a </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;n&atilde;o tem responsabilidade sobre a inadimpl&ecirc;ncia do comprador, sendo o comprador o &uacute;nico respons&aacute;vel pelo cumprimento das condi&ccedil;&otilde;es de compra acordadas.</span></p>
                <p className={'c1'}><span className={'c5'}>Caso o Produtor Membro tenha cadastrado um lote para venda na aba &ldquo;venda seu caf&eacute; conosco&rdquo; e vend&ecirc;-lo sem a intermedia&ccedil;&atilde;o da </span><span className={'c0'}>Flowins</span><span className={'c6 c5'}>, o Produtor Membro se compromete a retirar o lote do sistema como dispon&iacute;vel para venda.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro se compromete a fazer </span><span className={'c3'}>upload </span><span className={'c5'}>apenas</span><span className={'c3'}>&nbsp;</span><span className={'c5'}>de fotografias de sua leg&iacute;tima e exclusiva autoria, que n&atilde;o infrinjam qualquer direito autoral existente. Caso haja qualquer disputa pelo uso de imagem de autoria de terceiro, o Produtor Membro ser&aacute; exclusivamente respons&aacute;vel pelas indeniza&ccedil;&otilde;es de cunho material, moral e autoral decorrentes do uso de imagem de terceiro que o Produtor Membro tiver feito </span><span className={'c3'}>upload</span><span className={'c5 c6'}>&nbsp;na plataforma ou que por qualquer meio tenha enviado &agrave; Flowins para uso nas atividades relacionadas ao servi&ccedil;o contratado.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>9. Danos causados pelo Produtor Membro</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro ser&aacute; respons&aacute;vel por eventuais danos causados a compradores e a terceiros e concorda em indenizar quaisquer demandas, preju&iacute;zos, danos direta ou indiretamente relacionados a atos ou fatos causados pelo Produtor Membro. O Produtor Membro &eacute; o &uacute;nico e exclusivo respons&aacute;vel pelos problemas relativos ao fornecimento dos lotes disponibilizados para venda bem como por condutas inadequadas.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>10. Acesso &agrave; rede de dados</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro &eacute; respons&aacute;vel pela conex&atilde;o &agrave; rede de dados necess&aacute;ria para o uso do servi&ccedil;o. As taxas e encargos deste acesso ficam a cargo do Produtor Membro. A atualiza&ccedil;&atilde;o de softwares, equipamentos e dispositivos necess&aacute;rios ao uso dos servi&ccedil;os contratados &eacute; de responsabilidade do Produtor Membro. A defici&ecirc;ncia na rede de dados ou de equipamentos pode acarretar funcionamento inadequado do servi&ccedil;o e a </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;n&atilde;o garante o bom funcionamento de seus servi&ccedil;os neste ambiente.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>11. Modifica&ccedil;&atilde;o ou encerramento do servi&ccedil;o</span></p>
                <p className={'c1'}><span className={'c5'}>A empresa pode a qualquer tempo modificar ou descontinuar o Servi&ccedil;o, ou parte dele, independente de notifica&ccedil;&atilde;o. O Produtor Membro entende que a empresa n&atilde;o pode ser responsabilizada e n&atilde;o tem qualquer obriga&ccedil;&atilde;o adicional para com o Produtor Membro em raz&atilde;o de qualquer desativa&ccedil;&atilde;o, suspens&atilde;o ou altera&ccedil;&atilde;o do servi&ccedil;o.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>12. Pagamento pelo produto vendido aos compradores </span></p>
                <p className={'c1'}><span className={'c5'}>O pagamento ao Produtor Membro &eacute; efetuado diretamente pelo comprador, em uma das modalidades dispon&iacute;veis, diretamente, a crit&eacute;rio do Produtor Membro. </span></p>
                <p className={'c1'}><span className={'c5'}>O comprador pode cancelar a compra antes do envio do lote adquirido. Nestes eventos, n&atilde;o haver&aacute; qualquer valor ou taxa de cancelamento a ser repassado ao Produtor Membro.</span></p>
                <p className={'c1'}><span className={'c5'}>A responsabilidade de emiss&atilde;o da Nota Fiscal de venda de lotes de caf&eacute; &eacute; do Produtor Membro.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>13. Avalia&ccedil;&atilde;o</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro concorda que poder&aacute; ser avaliado pelos compradores e pela </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;sobre a qualidade dos produtos, aceita&ccedil;&atilde;o, entrega, cumprimento de prazos e outras caracter&iacute;sticas inerentes ao servi&ccedil;o prestado. O Produtor Membro que for reiteradamente mal avaliado pode ter sua conta cancelada, a crit&eacute;rio da empresa, impedindo o acesso ao servi&ccedil;o.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro compreende que a </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;n&atilde;o &eacute; obrigada a controlar, censurar ou remover avalia&ccedil;&otilde;es realizadas por terceiros e compradores em p&aacute;ginas de avalia&ccedil;&atilde;o ou redes sociais da </span><span className={'c0'}>Flowins</span><span className={'c5'}>, sendo os terceiros e compradores os &uacute;nicos respons&aacute;veis pelo conte&uacute;do publicado. As avalia&ccedil;&otilde;es de terceiros e compradores n&atilde;o representam a opini&atilde;o da empresa sobre o Produtor Membro.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro entende e aceita que registros internos (dados coletados) sobre os servi&ccedil;os prestados, fazendas e lotes cadastrados, amostras analisadas e vendas realizadas podem ser utilizados para realizar a avalia&ccedil;&atilde;o do Produtor Membro, conforme legisla&ccedil;&atilde;o vigente. Neste sentido, os dados considerados sens&iacute;veis n&atilde;o estar&atilde;o dispon&iacute;veis.</span></p>
                <p className={'c1'} id={'h.gjdgxs'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;pode suspender por tempo indeterminado a conta do Produtor Membro pelo descumprimento dos Termos de Uso e, em determinados casos como o da avalia&ccedil;&atilde;o negativa reiterada, sugerir a reciclagem.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>14. Suspeita de fraude</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;n&atilde;o se responsabiliza por a&ccedil;&otilde;es fraudulentas do Produtor Membro. Por estas a&ccedil;&otilde;es, a conta do Produtor Membro pode ficar suspensa ou cancelada, inclusive podendo gerar a&ccedil;&otilde;es judiciais ou extrajudiciais contra o envolvido.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>15. Suspens&atilde;o e cancelamento da conta </span></p>
                <p className={'c1'}><span className={'c6 c5'}>O descumprimento, pelo Produtor Membro, de qualquer obriga&ccedil;&atilde;o prevista nestes Termos de Uso o sujeitar&aacute; a ter a conta suspensa ou cancelada.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro concorda que o encerramento da sua conta e consequente bloqueio de acesso ao aplicativo pode ocorrer sem notifica&ccedil;&atilde;o.</span></p>
                <p className={'c1'}><span className={'c5'}>A </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;pode agir judicialmente ou extrajudicialmente motivada por danos sofridos, em nome pr&oacute;prio ou em nome de terceiros, inclusive poder&aacute; dar in&iacute;cio a instru&ccedil;&otilde;es em processos criminais, administrativos e c&iacute;veis, se julgar necess&aacute;rio.</span></p>
                <p className={'c1'}><span className={'c5'}>A suspens&atilde;o ou extin&ccedil;&atilde;o dos Termos de Uso n&atilde;o isenta o Produtor Membro de valores eventualmente devidos, os quais poder&atilde;o ser cobrados ou compensados pela </span><span className={'c0'}>Flowins</span><span className={'c5'}>. </span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro n&atilde;o tem direito de compensa&ccedil;&atilde;o ou de indeniza&ccedil;&atilde;o pela suspens&atilde;o ou extin&ccedil;&atilde;o de qualquer Termo de Uso pela empresa.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>16. Encerramento da conta pelo Produtor Membro</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro poder&aacute; encerrar voluntariamente a sua conta </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo;</span><span className={'c5'}>. Neste caso, n&atilde;o far&aacute; jus a qualquer esp&eacute;cie de devolu&ccedil;&atilde;o de valores pagos antecipadamente a t&iacute;tulo de anuidade e/ou mensalidade, </span><span className={'c5 c9'}>bem como dever&aacute; quitar em at&eacute; 30 dias d&eacute;bitos existentes a outros t&iacute;tulos, sob pena de sofrer as medidas judiciais cab&iacute;veis.</span></p>
                <p className={'c1'}><span className={'c5 c9'}>O Produtor Membro que encerrar voluntariamente sua conta e retornar ao servi&ccedil;o </span><span className={'c0 c9'}>&ldquo;Gerente Flowins&rdquo; </span><span className={'c5 c9'}>no prazo de at&eacute; tr&ecirc;s meses poder&aacute; recuperar seus dados de cadastro da conta encerrada. Ap&oacute;s este prazo, ter&aacute; de fazer novo cadastro e inserir novamente no sistema todas as informa&ccedil;&otilde;es e documentos anteriormente inseridos. Em ambos os casos, o Produtor Membro se sujeitar&aacute; ao pagamento de nova anuidade e/ou mensalidade.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>17. Propriedade Intelectual</span></p>
                <p className={'c1'}><span className={'c5'}>Condicionada aos termos deste contrato, a </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;outorga ao Produtor Membro uma licen&ccedil;a limitada, n&atilde;o exclusiva, n&atilde;o permitida a sublicen&ccedil;a, revog&aacute;vel e n&atilde;o transfer&iacute;vel atrav&eacute;s de dispositivos compat&iacute;veis com o servi&ccedil;o. Quaisquer direitos n&atilde;o expressamente outorgados neste Termo s&atilde;o reservados &agrave; </span><span className={'c0'}>Flowins</span><span className={'c5'}>.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro n&atilde;o deve remover avisos de direito autoral, marca ou outro aviso de direito de propriedade de qualquer parte de servi&ccedil;os e n&atilde;o deve reproduzir, modificar, preparar obras derivadas, distribuir, licenciar, locar, vender ou, de qualquer outro modo, explorar os Servi&ccedil;os exceto da forma permitida pela </span><span className={'c0'}>Flowins</span><span className={'c5'}>.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro tamb&eacute;m n&atilde;o deve realizar engenharia reversa ou desmontar os Servi&ccedil;os, conectar, espelhar ou recortar os servi&ccedil;os, fazer ou lan&ccedil;ar quaisquer programas ou </span><span className={'c3'}>scripts</span><span className={'c5'}>&nbsp;com a finalidade de fazer </span><span className={'c3'}>scraping</span><span className={'c5'}>, indexa&ccedil;&atilde;o ou outros meios que possibilitem a coleta de dados.</span></p>
                <p className={'c1'}><span className={'c5'}>Os servi&ccedil;os e os direitos sobre os servi&ccedil;os s&atilde;o e permanecer&atilde;o de propriedade da </span><span className={'c0'}>Flowins</span><span className={'c5'}>. Estes Termos n&atilde;o outorgam ou conferem direitos sobre servi&ccedil;os (exceto os supracitados), n&atilde;o conferem direitos de usar ou referenciar nomes societ&aacute;rios, nomes de produtos ou de servi&ccedil;os e de marcas comerciais da </span><span className={'c0'}>Flowins</span><span className={'c5'}>.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c6 c0'}>18. Da permiss&atilde;o de uso da imagem e cess&atilde;o de direitos autorais</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro autoriza o uso de sua imagem e de todas as imagens que fizer </span><span className={'c3'}>upload</span><span className={'c5'}>&nbsp;na plataforma, inclusive de imagens de sua propriedade e de seus produtos, a t&iacute;tulo gratuito, pela </span><span className={'c0'}>Flowins</span><span className={'c5'}>, em todo o territ&oacute;rio nacional e no exterior, em qualquer modalidade de uso, inclusive usando na </span><span className={'c3'}>home page</span><span className={'c5'}>&nbsp;e nas aplica&ccedil;&otilde;es da </span><span className={'c0'}>Flowins</span><span className={'c5'}>, enviando imagens para potenciais compradores e parceiros comerciais, uso em folhetos, cartazes, </span><span className={'c3'}>out-doors</span><span className={'c5'}>&nbsp;e </span><span className={'c3'}>banners</span><span className={'c6 c5'}>, emprego para divulga&ccedil;&atilde;o em geral, inclusive em m&iacute;dia impressa e audiovisual, sem que nada possa ser reclamado a t&iacute;tulo de direitos conexos &agrave; imagem ou a qualquer outro, sejam direitos patrimoniais, morais ou autorais.</span></p>
                <p className={'c1'}><span className={'c5'}>O Produtor Membro autoriza a cess&atilde;o e a transfer&ecirc;ncia dos direitos autorais sobre todas as fotografias que fizer </span><span className={'c3'}>upload</span><span className={'c5'}>&nbsp;na plataforma ou que, de qualquer outra forma, enviar &agrave; </span><span className={'c0'}>Flowins</span><span className={'c5'}>, autorizando a sua utiliza&ccedil;&atilde;o e publica&ccedil;&atilde;o, bem como concedendo por tempo ilimitado &agrave; </span><span className={'c0'}>Flowins</span><span className={'c6 c5'}>&nbsp;todos os direitos sobre as fotografias referenciadas para publica&ccedil;&atilde;o, comercializa&ccedil;&atilde;o, impress&atilde;o, reimpress&atilde;o, atualiza&ccedil;&atilde;o, exibi&ccedil;&atilde;o, reprodu&ccedil;&atilde;o, tradu&ccedil;&atilde;o, distribui&ccedil;&atilde;o, transmiss&atilde;o, difus&atilde;o e comunica&ccedil;&atilde;o da fotografia ao p&uacute;blico, por qualquer formato ou meio, diretamente ou por meio de terceiros, sem que isso implique direito &agrave; percep&ccedil;&atilde;o de qualquer valor, inclusive a t&iacute;tulo de direitos autorais.</span></p>
                <p className={'c1 c4'}><span className={'c6 c0'} /></p>
                <p className={'c1'}><span className={'c0'}>19. Da degusta&ccedil;&atilde;o</span></p>
                <p className={'c1'}><span className={'c5'}>A crit&eacute;rio da </span><span className={'c0'}>Flowins</span><span className={'c5'}>, poder&aacute; ser franqueado acesso gratuito e por tempo limitado ao sistema &ldquo;</span><span className={'c0'}>Gerente Flowins&rdquo;</span><span className={'c5'}>&nbsp;a produtor que demonstrar interesse em adquirir o servi&ccedil;o, apenas com a finalidade de conhecer o sistema. Neste caso, se aplicam todos termos, condi&ccedil;&otilde;es e normas de uso do servi&ccedil;o constante deste Termo, com exce&ccedil;&atilde;o da anuidade do servi&ccedil;o </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo;.</span></p>
                <p className={'c1'}><span className={'c5'}>No per&iacute;odo de &ldquo;degusta&ccedil;&atilde;o&rdquo;, n&atilde;o se prestar&aacute; qualquer servi&ccedil;o ao interessado e tampouco ser&aacute; disponibilizada a possibilidade de intermediar vendas pelo sistema, servindo o per&iacute;odo apenas para conhecimento do ambiente virtual e familiaridade com o uso das ferramentas.</span></p>
                <p className={'c1'}><span className={'c5'}>Caso o interessado tenha interesse em adquirir o servi&ccedil;o ap&oacute;s o prazo estabelecido para o per&iacute;odo de degusta&ccedil;&atilde;o, dever&aacute; adimplir com a anuidade correspondente ao pacote de servi&ccedil;os escolhido no prazo de at&eacute; </span><span className={'c5 c9'}>0</span><span className={'c5'}>5 dias ap&oacute;s o t&eacute;rmino do per&iacute;odo de degusta&ccedil;&atilde;o. Ap&oacute;s a confirma&ccedil;&atilde;o do pagamento da anuidade, o Produtor Membro poder&aacute; utilizar a plataforma e os servi&ccedil;os constantes no pacote escolhido. Caso n&atilde;o seja realizado o pagamento, a conta ser&aacute; exclu&iacute;da sem a necessidade de aviso pr&eacute;vio e os dados do interessado ser&atilde;o exclu&iacute;dos.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>
                <p className={'c1'}><span className={'c0'}>20. Disposi&ccedil;&otilde;es finais</span></p>
                <p className={'c1'}><span className={'c5'}>Estes Termos n&atilde;o podem ser transferidos, total ou parcialmente, pelo Produtor Membro sem pr&eacute;via aprova&ccedil;&atilde;o por escrito da empresa. O Produtor Membro concorda que a empresa ceda estes Termos, parcialmente ou em sua totalidade, para adquirentes de participa&ccedil;&otilde;es acion&aacute;rias ou outros neg&oacute;cios da empresa, bem como para um sucessor em raz&atilde;o de qualquer opera&ccedil;&atilde;o societ&aacute;ria. N&atilde;o h&aacute; joint-venture, sociedade ou emprego ou rela&ccedil;&atilde;o de representa&ccedil;&atilde;o entre os compradores e a empresa ou quaisquer produtores membros como resultado do contrato entre o Produtor Membro e a empresa.</span></p>
                <p className={'c1'}><span className={'c5'}>Caso qualquer disposi&ccedil;&atilde;o destes Termos seja tida como ilegal, inv&aacute;lida ou inexequ&iacute;vel total ou parcialmente, por qualquer legisla&ccedil;&atilde;o, essa disposi&ccedil;&atilde;o ou parte dela ser&aacute;, naquela medida, considerada como n&atilde;o existente para os efeitos destes Termos, mas a legalidade, validade e exequibilidade das demais disposi&ccedil;&otilde;es contidas nestes Termos n&atilde;o ser&atilde;o afetadas. A invalidade de qualquer termo n&atilde;o invalida as demais cl&aacute;usulas destes Termos e Condi&ccedil;&otilde;es de Uso.</span></p>
                <p className={'c1'}><span className={'c5'}>Estes Termos constituem a totalidade do acordo e entendimento das partes sobre este assunto e substituem e prevalecem sobre todos os entendimentos e compromissos anteriores sobre este assunto.</span></p>
                <p className={'c1'}><span className={'c5'}>Este Termo &eacute; regido pela legisla&ccedil;&atilde;o brasileira e fica eleito o foro da Comarca de Belo Horizonte/MG</span><span className={'c5 c11'}>&nbsp;</span><span className={'c5'}>como sendo o &uacute;nico competente para dirimir quaisquer lit&iacute;gios e/ou demandas que venham a ocorrer envolvendo as partes em rela&ccedil;&atilde;o ao servi&ccedil;o </span><span className={'c0'}>&ldquo;Gerente Flowins&rdquo;</span><span className={'c5'}>. As partes concordam em submeter-se &agrave; compet&ecirc;ncia &uacute;nica e exclusiva dos tribunais localizados no Brasil.</span></p>
                <p className={'c1'}><span className={'c5'}>As notifica&ccedil;&otilde;es direcionadas &agrave; </span><span className={'c0'}>Flowins</span><span className={'c5'}>&nbsp;devem ocorrer no endere&ccedil;o da sede da empresa, descrito no pre&acirc;mbulo dos termos.</span></p>
                <p className={'c1'}><span className={'c5'}>Se a </span><span className={'c0'}>Flowins</span><span className={'c5'}>, deixar de exercer qualquer direito ou dispositivo constantes dos Termos de Uso, isto n&atilde;o caracteriza ren&uacute;ncia a tal direito ou dispositivo e, tamb&eacute;m, n&atilde;o caracteriza nova&ccedil;&atilde;o. </span></p>
                <p className={'c1'}><span className={'c0'}>O Produtor Membro n&atilde;o deve ceder ou transferir estes Termos, em sua totalidade ou parcialmente, sem a pr&eacute;via aprova&ccedil;&atilde;o por escrito da Flowins. O Produto Membro concede sua aprova&ccedil;&atilde;o para que a empresa ceda e transfira estes Termos (total ou parcialmente) para adquirente das participa&ccedil;&otilde;es acion&aacute;rias, neg&oacute;cios ou bens da Flowins ou para um sucessor por virtude de qualquer opera&ccedil;&atilde;o societ&aacute;ria.</span></p>
                <p className={'c1'}><span className={'c5'}>Estes Termos e Condi&ccedil;&otilde;es de Uso t&ecirc;m vig&ecirc;ncia a partir de abril de 2021.</span></p>
                <p className={'c1 c4'}><span className={'c2'} /></p>

            </WrapperSCP>

        </ModalCP>

    )
}

const WrapperSCP = styled.div`
    max-height: 55vh;
    overflow: overlay;
    .c0{
        font-weight: 600;
    }
`
const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`
