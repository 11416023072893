import { DatePicker } from 'antd'
import { IDateRangerFilter } from 'common/components/date-range-picker/inner/IDateRangerFilter'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import moment from 'moment'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { FastDateRangeFilterTP, FastFilterICP } from './inner/FastFilterICP'

interface IDateRangePickerCPProps {
    onChange: (dates: IDateRangerFilter, advancedFilterType?: FastDateRangeFilterTP) => void
    initialValue?: any
    value?: [any, any]
    label?: string
    placeholder?: [string, string]
    fastFilter?: FastDateRangeFilterTP
    allowClear?: boolean
    disabledDate?: ((current: moment.Moment | null) => boolean) | undefined
}

/**
 * COMPONENTE
 * Date picker customizado para range de datas (data inicial + data final).
 * @todo tipar as props de VALUE corretamente
 * @author Lucas Rosa
 * @author hjcostabr
 */
export const DateRangePickerCP = (props: IDateRangePickerCPProps): JSX.Element => {
    const screenSize = useScreenSize()
    function onDateRangeSelection(dates: any, dateStrings: [string, string]): void {

        const dateFilter: IDateRangerFilter = {
            beginDate: !!dateStrings[0] ? moment(dateStrings[0], 'DD/MM/YYYY').toDate() : undefined,
            endDate: !!dateStrings[0] ? moment(dateStrings[1], 'DD/MM/YYYY').toDate() : undefined
        }

        props.onChange(dateFilter, undefined)
    }

    function getPopupElement(originPanel: ReactNode): ReactNode {
        return (
            <PopupWrapperSCP>
                {originPanel}
            </PopupWrapperSCP>
        )
    }

    return (
        <DatePickerContainerSCP>
            {
                !!props.label &&
                <LabelSCP>
                    <TextCP text={props.label} />
                </LabelSCP>

            }
            <DateRangePickerSCP
                defaultValue={props.initialValue}
                onChange={onDateRangeSelection}
                format={'DD/MM/YYYY'}
                value={props.value}
                placeholder={props.placeholder ?? ['Data inicial', 'Data final']}
                allowClear={props.allowClear}
                panelRender={getPopupElement}
                renderExtraFooter={
                    !props.fastFilter
                        ? undefined
                        : () => (
                            <FastFilterICP
                                onFilterSelection={props.onChange}
                                currentFilter={props.fastFilter}
                            />
                        )
                }
            />
        </DatePickerContainerSCP>
    )

}

const DateRangePickerSCP = styled(DatePicker.RangePicker)`
    &.ant-calendar-picker{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        border: none;
        box-shadow: none;
        .ant-calendar-picker-icon{
            width: 28px;
            height: 28px;
            margin-top: -9px;
            left: 0;
            &:after{
                font-size: 20px;
                color: ${props => props.theme.primaryColor};
            }
        }
        .ant-calendar-picker-input{
            border: none;
            padding-left: 35px;
            color: ${props => props.theme.black};

            .ant-calendar-range-picker-separator{
                margin: 5px;
            }

            &:focus{
                box-shadow: none;
            }

            input::placeholder{
                color: ${props => props.theme.black};
            }
        }
        input{
            border: none;
            width: 40%;
            font-size: .8rem;
            color: ${props => props.theme.black};
        }
    }
    .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled){
        border: none;
        box-shadow: none;
    }
    &.ant-picker{
        border: none;
        background: ${props => props.theme.inputBg};
        padding: 10px 11px;
        border-radius: 5px;
        min-height: 21px;
        width: 100%;
    }
`

const DatePickerContainerSCP = styled.div`
   
    padding-bottom: 2px;
    width: 100%;
    margin: 10px 0;
`
const LabelSCP = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: #494949;
    margin-bottom: 5px;
`

const PopupWrapperSCP = styled.div`
    @media(max-width: 768px){
        position: absolute;
        z-index: 9;
        background: white;  
        .ant-picker-panels{
            display: flex;
            flex-direction: column;
        }
    }

`