
import { IsOptional } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsEmail } from 'common/validation/decorators/IsEmail'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsString } from 'common/validation/decorators/IsString'
import { ISaveCompanyReqDTO } from 'modules/company/services/dtos/requests/ISaveCompanyReqDTO'

export class CompanyFormValidator extends FormModelValidator {
    @IsNotEmpty()
    @IsString()
    name: string

    @IsNotEmpty()
    @IsString()
    domain: string

    @IsNotEmpty()
    @IsString()
    color: string

    @IsEmail()
    @IsNotEmpty()
    email: string


    constructor(dto?: ISaveCompanyReqDTO) {
        super(dto)
        Object.assign(this, dto)

    }
}
