import { Card } from 'antd'
import styled from 'styled-components'

interface ICardCPProps {
    noSpacing?: boolean
    noInternalSpacing?: boolean
    loading?: boolean
    noSideSpacing?: boolean
    noTopBottomSpacing?: boolean
    smallInternalSpacing?: boolean
    shadow?: boolean
    disabled?: boolean
}

/**
 * COMPONENTE
 * Card antd com estilo para projeto
 *
 * @author Lucas Rosa
 * @author Stella
 * @author hjcostabr
 */
const CardSCP = styled(Card)<ICardCPProps>`

    &.ant-card {
        opacity: ${props => (props.disabled ? .4 : 1)};
        pointer-events: ${props => (props.disabled ? 'none' : 'all')};
        box-shadow: ${props => (props.shadow ? '-4px 5px 15px 5px rgba(0,0,0,0.13)' : 'none')};
        border-radius: 0.5rem;
        margin: ${props => (props.noSpacing ? '0' : (props.noSideSpacing ? '20px 0' : (props.noTopBottomSpacing ? '0 20px' : '20px')))};

        .ant-card-body {
            padding: ${props => (props.noInternalSpacing ? '0' : (props.smallInternalSpacing ? '5px 10px' : '15px 25px'))};
            height:100%;
        }

        .ant-card-head{

            border-bottom: #f7f7f7 solid 1px;

            :after{
                content: none;
            }

            .ant-card-head-wrapper {

                display: flex;
                flex-direction: row;
                justify-content: center;

                .ant-card-head-title {
                    color: ${props => props.theme.primaryColor};
                    font-family: 'Poppins',sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    border: none;
                }
            }
        }
    }
    @media(max-width: 788px){
        &.ant-card .ant-card-body{
            overflow: hidden;
        }
        &.ant-card {
            box-shadow: none;
        }
    }
`
export const CardCP = CardSCP
