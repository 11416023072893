import { StructureTypeEnum } from 'modules/batch/enums/StructureTypeEnum'
import { StructureTypeSelectorCP } from 'modules/structure/components/structure-type-selector/StructureTypeSelectorCP'
import { ISearchStructureReqDTO } from 'modules/structure/services/dtos/request/ISearchStructureReqDTO'
import React from 'react'
import styled from 'styled-components'

interface IStructureFilterFieldsICPProps {
    onChangeType: (type: StructureTypeEnum) => void
    filters?: ISearchStructureReqDTO
}

export function StructureFilterFieldsICP(props: IStructureFilterFieldsICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <GridWrapperSCP>
                <StructureTypeSelectorCP
                    onSelect={props.onChangeType}
                    value={props.filters?.type}
                />

            </GridWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
padding: 3px 0;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
margin-bottom: 3px;
`

const GridWrapperSCP = styled.div`
width: 100%;
display: grid;
grid-template-columns: .4fr 1fr;
column-gap: 10px;
row-gap: 10px;
margin-top: 10px;

label, .ant-typography div{
    display: none;
}
.ant-row .ant-form-item-label > label{
    display: none;
}
@media(max-width: 767px){
    grid-template-columns: 1fr;
     label, .ant-typography div{
               display: block;
     }
}
`

const StatusSCP = styled.div`
@media(max-width: 767px){
   display: none;
}

`