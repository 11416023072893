import { faSeedling, faWarehouse, faCubes, faFlask, faUsers, faCampground, faHandHoldingUsd, faChartArea } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnalysisRoutes } from 'modules/analysis/AnalysisRoutes'
import { FinancialRoutes } from 'modules/financial/FinancialRoutes'
import { BatchRoutes } from 'modules/batch/BatchRoutes'
import { HarvestRoutes } from 'modules/harvest/HarvestRoutes'
import { ReportRoutes } from 'modules/report/ReportRoutes'
import { ManeuverRoutes } from 'modules/maneuver/ManeuverRoutes'
import * as React from 'react'
import { CostumizedThemeConfig } from '../../../../../config/CostumizedThemeConfig'
import { PropertyRoutes } from '../../../../property/PropertyRoutes'
import { PersonRoutes } from 'modules/person/routes/PersonRoutes'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'
import { ManeuverActivitiesIcon } from 'common/components/icons/ManeuverActivitiesIcon'
import { StructureRoutes } from 'modules/structure/StructureRoutes'
import styled from 'styled-components'

export interface IMenuOption {
    key: string
    route: string
    title: string
    icon: JSX.Element
    permissions?: SystemModulesEnum
    submenu?: IMenuOption[]
}
const IconSCP = styled.span`
    svg {
        color: ${props => props.theme.primaryColor};
        fill: ${props => props.theme.primaryColor};
        font-size: 1.75em;
    }
`
export const MENU_OPTIONS: IMenuOption[] = [
    {
        key: 'property',
        route: PropertyRoutes.ROOT,
        title: 'Propriedades',
        icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faWarehouse} /></IconSCP>
    },
    {
        key: 'team',
        route: PersonRoutes.ROOT,
        title: 'Equipe',
        icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faUsers} /></IconSCP>
    },
    {
        key: 'structure',
        route: StructureRoutes.ROOT,
        title: 'Estruturas',
        permissions: SystemModulesEnum.POST_HARVEST,
        icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faCampground} /></IconSCP>
    },
    {
        key: 'harvest',
        route: HarvestRoutes.PLANNING,
        title: 'Colheita',
        icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faSeedling} /></IconSCP>
    },
    {
        key: 'batch',
        route: BatchRoutes.ROOT,
        title: 'Lotes',
        permissions: SystemModulesEnum.POST_HARVEST,
        icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faCubes} /></IconSCP>
    },
    {
        key: 'maneuver',
        route: ManeuverRoutes.ROOT,
        title: 'Manejo >',
        permissions: SystemModulesEnum.MANEUVER,
        icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><ManeuverActivitiesIcon /></IconSCP>,
    },

    {
        key: 'financial',
        route: FinancialRoutes.ROOT,
        title: 'Financeiro',
        permissions: SystemModulesEnum.FINANCIAL,
        icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faHandHoldingUsd} /></IconSCP>
    },
    {
        key: 'reports',
        route: ReportRoutes.ROOT,
        title: 'Relatórios >',
        icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwesomeIcon icon={faChartArea} /></IconSCP>
    },
]

