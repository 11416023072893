import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { PaginationConfig } from 'antd/lib/pagination'
import { ISearchManeuverResponseDTO } from 'modules/maneuver/services/dtos/responses/ISearchManeuverResponseDTO'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { ManeuverCardICP } from 'modules/maneuver/components/maneuver-list/inner/ManeuverCardICP'
import { ManeuverRequests } from 'modules/maneuver/services/ManeuverRequests'
import { ListActions } from 'common/components/list/inner/ListActions'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { CardCP } from 'common/components/card/CardCP'
import { ISearchManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchManeuverRequestDTO'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { ActivityStatusEnum } from 'modules/maneuver/enums/ActivityStatusEnum'
import { ManeuverListFastFiltersCP } from '../maneuver-list-filters/ManeuverListFastFiltersCP'
import moment from 'moment-timezone'

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchManeuverRequestDTO = {
    status: ActivityStatusEnum.IN_PROGRESS,
    title: undefined,
    code: undefined,
    propertyCode: undefined,
    fieldCode: undefined,
    type: undefined,
    season: moment().year(),
    page: DEFAULT_PAGINATION.current,
    itemsPerPage: DEFAULT_PAGINATION.pageSize
}
export function ManeuverListCP(): JSX.Element {
    const [maneuverList, setManeuverList] = useState<ISearchManeuverResponseDTO[]>([])
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const currentFilters: ISearchManeuverRequestDTO | null = useSelector((state: IReduxState) => _.get(state, 'searchState.filter'))
    const [pagination, setPagination] = useState<PaginationConfig>()
    const [total, setTotal] = useState<number>(0)

    const type: FilterTypesEnum = useSelector((state: IReduxState) => _.get(state, 'searchState.type'))

    const request = useRequest<IGenericListResponseDTO<ISearchManeuverResponseDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(getManeuverList, [currentFilters])
    useEffect(onItemChange, [updateStatus])
    useEffect(loadPersistedData, [])

    function loadPersistedData(): void {

        if (type !== FilterTypesEnum.MANEUVER)
            return SearchActions.setFilter<ISearchManeuverRequestDTO>(DEFAULT_FILTERS, FilterTypesEnum.MANEUVER)

        if (!currentFilters) {
            SearchActions.setFilter<ISearchManeuverRequestDTO>({
                ...DEFAULT_FILTERS,
                page: 1,
                itemsPerPage: 12,
            }, FilterTypesEnum.MANEUVER)
        } else {
            SearchActions.setFilter<ISearchManeuverRequestDTO>({
                ...currentFilters,
                page: 1,
                itemsPerPage: 12,
            }, FilterTypesEnum.MANEUVER)
        }
    }
    function getManeuverList(): void {

        if (!!currentFilters && type === FilterTypesEnum.MANEUVER)
            request.runRequest(ManeuverRequests.search(currentFilters))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE) {
            setManeuverList([])
            SearchActions.setFilter<ISearchManeuverRequestDTO>({
                ...currentFilters
            }, FilterTypesEnum.MANEUVER)
        }
    }

    function onChangeFilter(filter?: ISearchManeuverRequestDTO): void {
        setManeuverList([])
        SearchActions.setFilter({
            ...filter,
            page: DEFAULT_PAGINATION.current,
            itemsPerPage: DEFAULT_PAGINATION.pageSize,
        }, FilterTypesEnum.MANEUVER)
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried) {
            ListActions.isUpdating()
            return
        }

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar lista de atividades!')
        }
        setManeuverList(request.returnData.list)
        setTotal(request.returnData.total)
        setPagination({
            ...pagination,
            total: request.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                SearchActions.setFilter({ ...currentFilters, page, itemsPerPage: pageSize }, FilterTypesEnum.MANEUVER)
            }
        })
        ListActions.updateIsDone()
    }
    return (
        <WrapperSCP>
            <ManeuverListFastFiltersCP
                setFilters={onChangeFilter}
                filters={currentFilters && type === FilterTypesEnum.MANEUVER ? currentFilters : undefined}
            />
            <TotalSCP>
                <TextCP
                    text={`${total} registros encontrados`}
                />
                <TextCP
                    text={'Limpar filtros'}
                    onClick={() => {
                        setPagination(DEFAULT_PAGINATION)
                        SearchActions.setFilter({
                            ...DEFAULT_FILTERS,
                            page: DEFAULT_PAGINATION.current,
                            itemsPerPage: DEFAULT_PAGINATION.pageSize,
                        }, FilterTypesEnum.MANEUVER)
                    }}
                    style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
                />
            </TotalSCP>
            <ListWrapperSCP>
                <ListCP<ISearchManeuverResponseDTO>
                    grid={{ gutter: 16, column: 3, md: 1, sm: 1, xs: 1, lg: 2, xl: 3, xxl: 3 }}
                    loading={request.awaiting}
                    bordered={false}
                    itemLayout={'vertical'}
                    size={'large'}
                    dataSource={maneuverList}
                    hasStateControl={true}
                    locale={{
                        emptyText: <CardCP noSpacing={true} noSideSpacing={true}>
                            {' '}
                            <EmptyCP description={'Você ainda não tem atividades agrícolas'} />
                            {' '}
                        </CardCP>,
                    }}
                    renderItem={(item: ISearchManeuverResponseDTO) => (
                        <ManeuverCardICP
                            item={item}
                            loading={request.awaiting}
                        />
                    )}
                    pagination={pagination}
                />
            </ListWrapperSCP>
        </WrapperSCP>
    )
}
const ListWrapperSCP = styled.div`
    
`
const WrapperSCP = styled.div`
  border-radius: 10px;
  margin-bottom: 4em;
  max-height: 75vh;
  overflow: overlay;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-right: 4px;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 1px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.primaryColor};
  }

  @media (max-width: 768px ) and (max-height: 600px ) {
    max-height: 75vh;
  }
  @media (max-width: 768px ) and (min-height: 600px ) {
    max-height: 82vh;
  }
`

const Empty = styled.div`
  border-radius: 10px;
  @media (max-width: 786px) {
    margin-left: 0;
  }
`

const TotalSCP = styled.div`
    margin-left: 3px;
    display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      

   
    }
    @media(max-width: 768px){        
    margin-top: 17px;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`
