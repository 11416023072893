import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { PropertyFormCP } from 'modules/property/components/property-form/PropertyFormCP'
import React from 'react'
import { IPropertyResponseDTO } from '../../services/dtos/responses/IPropertyResponseDTO'

interface IProps {
    property?: IPropertyResponseDTO
    onSuccess: (code?: number) => void
    onClose: () => void
    visible: boolean
}
export function PropertyDrawerCP(props: IProps): JSX.Element {
    return (
        <DrawerCP
            title={`${props.property ? 'Editar' : 'Nova'} Propriedade`}
            visible={props.visible}
            onClose={props.onClose}
        >
            <PropertyFormCP
                property={props.property}
                onSuccess={props.onSuccess}
            />

        </DrawerCP>
    )
}