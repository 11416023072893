import { ButtonCP } from 'common/components/button/ButtonCP'
import { PhotoGalleryCP } from 'common/components/custom-photo-gallery/PhotoGalleryCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'

import { RequestUtils } from 'common/request-helper/RequestUtils'

import { useRequest } from 'common/request-helper/UseRequest'
import { FinancialRequests } from 'modules/financial/services/FinancialRequests'
import { UploadDocsDrawerCP } from 'modules/maneuver/components/purchase-docs-drawer/inner/UploadDocsDrawerICP'
import { ImplementRequests } from 'modules/maneuver/services/ImplementRequests'
import { ProductRequests } from 'modules/maneuver/services/ProductRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IDocsDrawerCPProps {
    type: 'purchase' | 'maintenance' | 'financial'
    show: boolean
    onClose: () => void
    itemCode: number
    subItemCode?: number
}

export function DocsDrawerCP(props: IDocsDrawerCPProps): JSX.Element {
    const [images, setImages] = useState<string[]>([])

    const [uploadDrawerVisible, setUploadDrawerVisible] = useState<boolean>(false)

    const getDocsReq = useRequest<string[]>()
    useEffect(onGetDocsReqChange, [getDocsReq.awaiting])

    const deleteImageReq = useRequest()
    useEffect(onDeleteImageReqChange, [deleteImageReq.awaiting])

    useEffect(() => { getDocs() }, [props.subItemCode, props.itemCode, props.show])

    async function getDocs(): Promise<void> {
        if (!props.show || !props.type)
            return

        switch (props.type) {
            case 'purchase':
                return await getDocsReq.runRequest(ProductRequests.getPurchasePictures(props.itemCode, props.subItemCode!))
            case 'maintenance':
                return await getDocsReq.runRequest(ImplementRequests.getMaintenancePictures(props.itemCode, props.subItemCode!))
            case 'financial':
                return await getDocsReq.runRequest(FinancialRequests.getInvoicePictures(props.itemCode))
            default:
                return
        }

    }

    function onGetDocsReqChange(): void {
        if (getDocsReq.awaiting || !getDocsReq.tried)
            return

        if (!getDocsReq.success || !getDocsReq.returnData)
            return RequestUtils.showDefaultErrorNotification(getDocsReq.error, 'Erro ao bucar arquivos')
        setImages(getDocsReq.returnData)
    }

    function onDeleteImageReqChange(): void {
        if (deleteImageReq.awaiting || !deleteImageReq.tried)
            return

        if (!deleteImageReq.success || !deleteImageReq.returnData) {
            if (deleteImageReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteImageReq.returnData, deleteImageReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteImageReq.error, 'Erro ao excluir imagem!')
        }
        getDocs()
    }

    function removeImage(url: string): void {

        switch (props.type) {
            case 'purchase':
                deleteImageReq.runRequest(ProductRequests.removePurchasePictures(props.itemCode, props.subItemCode!, { pictures: [url] }))
                return
            case 'maintenance':
                deleteImageReq.runRequest(ImplementRequests.removeMaintenancePictures(props.itemCode, props.subItemCode!, { pictures: [url] }))
                return
            case 'financial':
                deleteImageReq.runRequest(FinancialRequests.deleteImages(props.itemCode, { pictures: [url] }))
                return
            default:
                return
        }
    }

    return (
        <DrawerCP
            title={`Documentos de ${props.subItemCode ?? props.itemCode}`}
            visible={props.show}
            width={'50%'}
            onClose={() => {
                props.onClose()
            }}
            footer={
                <WrapperButtonCP>

                    <ButtonCP
                        type={'primary'}
                        onClick={() => {
                            setUploadDrawerVisible(true)
                        }}
                        loading={getDocsReq.awaiting}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Adicionar arquivos
                    </ButtonCP>

                </WrapperButtonCP>
            }
        >
            <WrapperSCP>

                <PhotoGalleryCP
                    images={images.map(pic => ({
                        url: pic,
                        onDelete: () => removeImage(pic),
                        loading: deleteImageReq.awaiting,
                        previewConfig: {
                            mask: <MaskWrapperSCP />
                        }
                    }))}
                />
                <UploadDocsDrawerCP
                    type={props.type}
                    itemCode={props.itemCode}
                    subItemCode={props.subItemCode}
                    show={uploadDrawerVisible}
                    onClose={() => setUploadDrawerVisible(false)}
                    onSuccess={() => {
                        getDocs()
                        setUploadDrawerVisible(false)
                    }}
                />
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`

const WrapperButtonCP = styled.div`
    margin: 20px 0;
    .ant-btn{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`

const MaskWrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    opacity: .5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
`