/* eslint-disable max-params */
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import * as _ from 'lodash'
import { LanguageTP } from 'common/components/translation/types/LanguageTP'

export class LanguageActions {

    static readonly SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'

    private constructor() { /* private constructor to avoid instantiation */ }

    static swithLanguage(language: LanguageTP): void {
        ReduxHelper.getInstance().getStore()
            .dispatch({
                type: LanguageActions.SWITCH_LANGUAGE,
                payload: language
            })

    }

}
