import { ButtonCP } from 'common/components/button/ButtonCP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { TextCP } from 'common/components/text/TextCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { UnityUtils } from 'common/utils/UnityUtils'
import { HarvestTypeEnum } from 'modules/harvest/enums/HarvestTypeEnum'
import { HarvestUtils } from 'modules/harvest/utils/HarvestUtils'
import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'
import { RecipeUtils } from 'modules/maneuver/utils/RecipeUtils'
import { ActivityTypeEnum } from 'modules/person/enums/ActivityTypeEnum'
import { IPaymentStatementItemResDTO } from 'modules/person/services/dtos/responses/IPaymentStatementItemResDTO'
import { PersonUtils } from 'modules/person/utils/PersonUtils'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import React, { useState } from 'react'
import styled from 'styled-components'

import { LogbookDrawerCP } from 'modules/logbook/components/logbook-drawer/LogbookDrawerCP'
import { InvoiceFormDrawerCP } from 'modules/financial/components/invoice-form-drawer/InvoiceFormDrawerCP'
import { InvoiceTypeEnum } from 'modules/financial/enums/InvoiceType'
import { CheckboxCP } from 'common/components/form-fields/checkbox/CheckboxCP'

interface IStatementItemICPProps {
    statement: IPaymentStatementItemResDTO
    loading?: boolean
    key: string
    includeItem: (code: number) => void
    removeItem: (code: number) => void
    selected: number[]
    onUpdate: () => void
}

export function StatementItemICP(props: IStatementItemICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [detailsVisible, setDetailsVisible] = useState<boolean>(false)

    return (
        <ListItemICP
            key={props.key}
            isMobile={screenSize.smd}
            loading={props.loading}
            style={{ padding: '0', margin: '0' }}
            customSkeleton={<GenericRowSkeletonICP rows={1} columns={8} />}
        >
            <WrapperSCP>
                <MobileLabelsSCP>
                    <TextCP text={''} />
                    <TextCP text={'Data:'} />
                    <TextCP text={'Atividade:'} />
                    <TextCP text={'Tipo:'} />
                    <TextCP text={'Talhão:'} />
                    <TextCP text={'Unidade:'} />
                    <TextCP text={'Qtd.:'} />
                    <TextCP text={'Valor:'} />
                    <TextCP text={'Status:'} />
                </MobileLabelsSCP>
                <ValuesSCP>
                    <CheckboxWrapperSCP>
                        <CheckboxCP
                            isChecked={props.selected.includes(props.statement.personResCode)}
                            onChange={(checked) => {
                                if (checked)
                                    props.includeItem(props.statement.personResCode)
                                else
                                    props.removeItem(props.statement.personResCode)
                            }}
                            isDisabled={props.statement.consolidated}
                        />
                    </CheckboxWrapperSCP>
                    <TextCP text={DateUtils.getFormatted(props.statement.date, DateFormatEnum.BR_WITHOUT_TIME)} />
                    <TextCP text={PersonUtils.translateActivityType(props.statement.activity)} />
                    <TextCP
                        text={
                            props.statement.activity === ActivityTypeEnum.MANEUVER ?
                                RecipeUtils.translateType(props.statement.type as RecipeTypeEnum) :
                                HarvestUtils.translateType(props.statement.type as HarvestTypeEnum)
                        }
                    />
                    <TextCP text={props.statement.field ? props.statement.field.name : ''} />
                    <TextCP text={UnityUtils.translatePaymentUnity(props.statement.paymentUnit)} />
                    <TextCP text={`${props.statement.quantity ?? ' - '} ${props.statement.paymentUnit ? UnityUtils.getUnityAbreviation(props.statement.paymentUnit) : '-'}`} />
                    <TextCP text={props.statement.paidValue ? MaskUtils.applyCurrencyMask(props.statement.paidValue) : '-'} />
                    <TextCP
                        style={{ color: !props.statement.consolidated ? '#ffcc00' : '#40b971', fontSize: '12px' }}
                        text={props.statement.consolidated ? 'Consolidado' : 'Pendente'} />
                </ValuesSCP>
                <ButtonCP
                    type={'primary'}
                    onClick={() => setDetailsVisible(true)}
                >
                    Ver atividade
                </ButtonCP>

            </WrapperSCP>
            <LogbookDrawerCP
                show={detailsVisible}
                logbookEntryCode={props.statement.code}
                onCancel={() => setDetailsVisible(false)}
                onSuccess={() => {
                    setDetailsVisible(false)
                    props.onUpdate()
                }}
                harvestPlanningCode={props.statement.harvestPlanningCode}
                maneuverCode={props.statement.maneuverCode}
                fieldCode={props.statement.field ? props.statement.field.code : undefined}
                noListUpdate={true}
            />

        </ListItemICP>
    )
}

const WrapperSCP = styled.div`   
    position:relative;
    display: grid;
    grid-template-columns: 1fr .1fr ; 
    column-gap: 10px;
    @media(max-width: 768px){
        grid-template-columns: .5fr 1fr;
          display: grid;
          padding-bottom: 75px;
          margin-bottom: 2em;
          border-bottom: 1px solid ${props => props.theme.lightGray};
        .ant-btn{
            position: absolute;
            bottom: 12px;
            width: 100%;
        }
    }
`
const MobileLabelsSCP = styled.div`
    display: none;
    .ant-typography{
        font-weight: 600;
    }
    @media(max-width: 768px){
        display:grid;
        grid-template-rows: 1fr 2fr 1fr 1fr 1fr 1fr;
        row-gap: 6px;
        grid-template-rows: 1fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
        margin-top: -25px;
    }
`
const ValuesSCP = styled.div`
    display: grid;
    grid-template-columns: .1fr 1.2fr 1fr 2fr 2fr 1fr 1fr 1fr 1fr;
    margin: 0;
    padding: 10px 0;
    column-gap: 10px;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    align-items: center;
    .ant-list-bordered.ant-list-lg .ant-list-item{
        padding: 0!important;
    }

    @media(max-width: 768px){
        grid-template-columns: 1fr;
        row-gap: 6px;
        grid-template-rows: 1fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr;
     }
`

const CheckboxWrapperSCP = styled.div`
    align-items: start;
    display: flex;
    justify-content:center;
    align-items: center;
    @media(max-width: 768px){
        position: absolute;
        right: 0;
    }
`