import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { RecipeTypeSelectorCP } from 'modules/maneuver/components/recipe-type-selector/RecipeTypeSelectorCP'
import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'
import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'
import { ManeuverRequests } from 'modules/maneuver/services/ManeuverRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IRecipeSelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    label?: string
    value?: number
    onSelect?: (value: number) => void
    reload?: number
    type?: RecipeTypeEnum
}

export function RecipeSelectorCP<FModelTP extends FormModelValidator = any>(props: IRecipeSelectorCPProps<FModelTP>): JSX.Element {
    const screenSize = useScreenSize()

    const [recipeOptions, setRecipeOptions] = useState<SelectOptionTP[]>([])
    const [type, setType] = useState<RecipeTypeEnum | undefined>(props.type)
    useEffect(getRecipeList, [type])

    useEffect(() => {
        if (!!props.reload)
            getRecipeList()
    }, [props.reload])

    const request = useRequest<IGenericListResponseDTO<IRecipeResponseDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    function getRecipeList(): void {
        request.runRequest(ManeuverRequests.getRecipeList({ type }))
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar instruçãos!')
        }

        const options = request.returnData.list.map(recipe => ({ label: `${recipe.title}`, value: recipe.code }))
        setRecipeOptions(options)

    }

    return (
        <WrapperSCP>
            <RecipeTypeSelectorCP
                value={type}
                label={'Tipo'}
                onSelect={setType}
            />

            {
                !screenSize.smd &&
                <FontAwsomeIconCP icon={faArrowRight} />
            }

            <SelectCP
                options={recipeOptions}
                label={props.label}
                formStateManager={props.formStateManager}
                fieldName={'recipeCode'}
                placeholder={'Instrução'}
                required={true}
                showSearch={true}
                onChange={props.onSelect}
                value={props.value}
                loading={request.awaiting}
                disabled={request.awaiting}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
        display: grid;
        grid-template-columns: 1fr .1fr 1fr ;
        column-gap: 10px;
        align-items: end;
        svg {
            margin-bottom: 18px;
            color: ${props => props.theme.primaryColor}
        }
        @media(max-width: 768px){
            grid-template-columns: 1fr ;
        }
        .ant-row.has-error .ant-form-explain{
            display: none;
        }
    `
