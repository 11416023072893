import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'

/**
 * Utilitario pra calculos relacionados a instrução
 */
export const RecipeUtils = {
    calculateProductSpend(productQuantity: number, fieldArea: number): number {
        return productQuantity * fieldArea

    },

    translateType(type: RecipeTypeEnum): string {
        switch (type) {
            case RecipeTypeEnum.GENERAL:
                return 'Geral'
            case RecipeTypeEnum.POST_HARVEST:
                return 'Pós-colheita'
            case RecipeTypeEnum.BUSH_CONTROL:
                return 'Controle de mato'
            case RecipeTypeEnum.FERTILIZING:
                return 'Adubação'
            case RecipeTypeEnum.PRAGUE_CONTROL:
                return 'Controle de praga'
            case RecipeTypeEnum.SPROUT_CLEANUP:
                return 'Desbrota'
            case RecipeTypeEnum.SKELETON:
                return 'Poda'
            case RecipeTypeEnum.UNDER_CLEANUP:
                return 'Arruação'
            case RecipeTypeEnum.SOIL_PREPARE:
                return 'Preparo de solo'
            default:
                return ''
        }
    },

    getRecipeTypeInitials(
        type: RecipeTypeEnum
    ): string {
        switch (type) {
            case RecipeTypeEnum.BUSH_CONTROL:
                return 'CM'
            case RecipeTypeEnum.FERTILIZING:
                return 'A'
            case RecipeTypeEnum.GENERAL:
                return 'G'
            case RecipeTypeEnum.POST_HARVEST:
                return 'PC'
            case RecipeTypeEnum.PRAGUE_CONTROL:
                return 'CP'
            case RecipeTypeEnum.SKELETON:
                return 'E'
            case RecipeTypeEnum.SPROUT_CLEANUP:
                return 'P'
            case RecipeTypeEnum.UNDER_CLEANUP:
                return 'AR'
            case RecipeTypeEnum.SOIL_PREPARE:
                return 'PS'
            default:
                return ''
        }
    }

}

