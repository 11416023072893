import React, { CSSProperties, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SystemConfig } from 'config/SystemConfig'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { SchemaDataTP } from 'common/redux/ReduxTypes'
import { FontAwsomeIconCP } from '../icon/FontAwsomeIconCP'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
interface ILogoCPProps {
    height?: number
    onlySymbol?: boolean
    style?: CSSProperties
    direction?: 'vertical' | 'horizontal'
    inverse?: boolean
}

/**
 * COMPONENTE
 * Logo do sistema.
 */
export function LogoCP(props: ILogoCPProps): JSX.Element {
    const company: SchemaDataTP | null = useSelector((state: IReduxState) => _.get(state, 'schemaData'))
    const defaultLogo = `${SystemConfig.getInstance().uiBaseUrl}/assets/logo-flowins.png`
    const [logo, setLogo] = useState<string>()

    useEffect(defineLogoVariation, [props.direction, props.onlySymbol, props.inverse, company])

    function defineLogoVariation(): void {
        if (!!company) {
            if (props.inverse)
                return setLogo(company.inverseLogo)
            if (props.onlySymbol)
                return setLogo(company.icon)
            return setLogo(company.logo)
        }

        if (props.inverse)
            return setLogo(`${SystemConfig.getInstance().uiBaseUrl}/assets/logo-inverse-02.png`)

        if (props.onlySymbol)
            return setLogo(`${SystemConfig.getInstance().uiBaseUrl}/assets/symbol-flowins.png`)

        setLogo(defaultLogo)

    }

    if (!logo)
        return <WrapperSCP><FontAwsomeIconCP icon={faSpinner} spin={true} /></WrapperSCP>

    return (
        <ImgSCP
            src={logo}
            alt={'Flowins'}
            style={{ ...props.style, height: `${!!props.height ? `${props.height}px` : 'auto'}` }}
        />
    )
}

const ImgSCP = styled.img<{ height?: number }>`
   // height: ${props => (props.height ? `${props.height}px` : '25%')};
    @media(max-width: 768px){
        margin: 5px;
    }
`
const WrapperSCP = styled.div`
    display: flex;
    align-items:center;
    justify-content: flex-start;
    min-height: 30px; 
`