import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/RequestManagerTypes'
import { SystemConfig } from 'config/SystemConfig'

export class TranslationRequests {
    private constructor() { }

    static translate = (dto: any): RequestConfigTP => {
        return ({
            url: `${SystemConfig.getInstance().gfApiBaseUrl}/translate`,
            method: HttpMethodEnum.POST,
            params: dto
        })
    }

}
