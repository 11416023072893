import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CardFooterICP } from 'common/components/list/inner/CardFooterICP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { CardListItemICP } from 'common/components/list/inner/CardListItemICP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BatchCardSkeletonICP } from 'modules/batch/components/batch-list/inner/BatchCardSkeletonICP'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IProductResponseDTO } from 'modules/maneuver/services/dtos/responses/IProductResponseDTO'
import { NotificationHelper } from 'common/NotificationHelper'
import { ProductTypeEnum } from 'modules/maneuver/enums/ProductTypeEnum'
import { PurchaseDrawerCP } from 'modules/maneuver/components/purchase-drawer/PurchaseDrawerCP'
import { ProductCardContentICP } from 'modules/maneuver/components/product-list/inner/ProductCardContentICP'
import { ProductRequests } from 'modules/maneuver/services/ProductRequests'
import { PurchaseListDrawerCP } from 'modules/maneuver/components/purchase-list/PurchaseListDrawerCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ProductDrawerCP } from 'modules/maneuver/components/product-drawer/ProductDrawerCP'

interface IProductCardICPProps {
    product: IProductResponseDTO
    loading: boolean
}

export function ProductCardICP(props: IProductCardICPProps): JSX.Element {
    const disableEdit = props.product.type === ProductTypeEnum.FUEL

    const [productDrawerVisible, setProductDrawerVisible] = useState<boolean>(false)
    const [showPurchaseDrawer, setShowPurchaseDrawer] = useState<boolean>(false)
    const [showPurchaseListDrawer, setShowPurchaseListDrawer] = useState<boolean>(false)

    const deleteReq = useRequest(ProductRequests.deleteProduct(props.product.code))
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    function onDeleteReqChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success || !deleteReq.returnData)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Ocorreu um erro ao remover o produto. Tente novamente.')

        NotificationHelper.success('Produto removido com sucesso')
        ListActions.mustUpdate()
    }

    if (!props.product || props.loading)
        return <BatchCardSkeletonICP />

    return (
        <WrapperSCP>

            <CardListItemICP
                key={props.product.code}
                item={props.product}
                loading={props.loading}
                customSkeleton={<BatchCardSkeletonICP />}
                header={
                    <CardHeaderICP
                        highlight={`#${props.product.code}`}
                        title={`${props.product.name}`}
                        itemCode={props.product.code}
                        actions={disableEdit ? undefined :
                            [
                                {
                                    label: 'Editar',
                                    action: () => setProductDrawerVisible(true)
                                }
                            ]}
                    />
                }
                content={
                    <ProductCardContentICP
                        unitOfMeasurement={props.product.unitOfMeasurement}
                        remainingQuantity={props.product.remainingQuantity}
                        lastPurchase={props.product.lastPurchase}
                    />
                }
                footer={
                    <CardFooterICP
                        onDelete={props.product.type !== ProductTypeEnum.FUEL ? deleteReq.runRequest : undefined}
                        deleteLoading={false}
                        confirmLabel={'Tem certeza que quer excluir este produto?'}
                    >
                        <ActionsSCP>

                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px' }}
                                onClick={() => setShowPurchaseDrawer(true)}
                                icon={<FontAwsomeIconCP icon={faCartPlus} style={{ margin: '0 5px' }} />}
                            />
                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px', height: '100%' }}
                                onClick={() => setShowPurchaseListDrawer(true)}
                            >
                                Ver compras
                            </ButtonCP>
                        </ActionsSCP>
                    </CardFooterICP>
                }
            />

            <PurchaseDrawerCP
                productCode={props.product.code}
                unitOfMeasurement={props.product.unitOfMeasurement}
                show={showPurchaseDrawer}
                onSuccess={() => {
                    setShowPurchaseDrawer(false)
                    setShowPurchaseListDrawer(true)
                    ListActions.mustUpdate()
                }}
                onCancel={() => {
                    setShowPurchaseDrawer(false)
                }}
            />
            <PurchaseListDrawerCP
                show={showPurchaseListDrawer}
                productName={props.product.name}
                productCode={props.product.code}
                onCancel={() => {
                    setShowPurchaseListDrawer(false)
                }}
            />

            <ProductDrawerCP
                show={productDrawerVisible}
                productCode={props.product.code}
                onSuccess={() => {
                    setProductDrawerVisible(false)
                }}
                onCancel={() => {
                    setProductDrawerVisible(false)
                }}
            />
        </WrapperSCP>
    )
}

const ActionsSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const WrapperSCP = styled.div`
  
`

const POWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .ant-btn{
        margin-left: 10px;
        margin-bottom: 5px;
        min-width: 150px;
    }
    
`