import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import React, { useState } from 'react'
import styled from 'styled-components'

import { ProductSelectorCP } from 'modules/maneuver/components/product-selector/ProductSelectorCP'
import { ProductTypeEnum } from 'modules/maneuver/enums/ProductTypeEnum'
import { MaintenanceFormValidator } from 'modules/maneuver/components/maintenance-drawer/inner/MaintenanceFormValidator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { ISaveProductMaintenanceRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveProductMaintenanceRequestDTO'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { TextCP } from 'common/components/text/TextCP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import { MaskUtils } from '../../../../../common/utils/MaskUtils'

interface IProductMaintenanceFormFieldsICPProps<FModelTP extends FormModelValidator = MaintenanceFormValidator> {
    productMaintenance: ISaveProductMaintenanceRequestDTO
    prodMaintenanceList: ISaveProductMaintenanceRequestDTO[]
    setProdMaintenanceList: (prd: ISaveProductMaintenanceRequestDTO[]) => void
    removeFromList: () => void
    index: number
    loading?: boolean
}

export function ProductMaintenanceFormFieldsICP(props: IProductMaintenanceFormFieldsICPProps<MaintenanceFormValidator>): JSX.Element {
    const [unit, setUnit] = useState<UnitOfMeasurementEnum>()
    const [remainingQtt, setRemainingQtt] = useState<number>()

    function onChangeProduct(productCode: number, unitOfMeasurement?: UnitOfMeasurementEnum, remainingQuantity?: number): void {
        setUnit(unitOfMeasurement)
        setRemainingQtt(remainingQuantity)
        props.setProdMaintenanceList(props.prodMaintenanceList.map((item, i) => {
            if (props.index === i) {
                return {
                    ...item,
                    productCode,
                    unitOfMeasurement
                }
            }
            return item
        }))
    }

    function onChangeQuantity(quantity: number): void {
        props.setProdMaintenanceList(props.prodMaintenanceList.map((item, i) => {
            if (props.index === i) {
                return {
                    ...item,
                    quantity: +quantity
                }
            }
            return item
        }))
    }

    return (
        <WppSCP>
            <GridWrapperSCP>
                <ProductSelectorCP
                    //types={[ProductTypeEnum.PIECE, ProductTypeEnum.OTHER]}
                    placeholder={'Selecione o item'}
                    onSelect={onChangeProduct}
                    value={props.productMaintenance.productCode}
                />

                <InputCP
                    placeholder={'Quantidade'}
                    onChange={onChangeQuantity}
                    value={props.productMaintenance.quantity}
                    loading={props.loading}
                    icon={!!unit ? <span>{ProductUtils.getUnitOfMeasurementAbbreviation(unit)}</span> : undefined}
                />
                <ButtonCP
                    type={'ghost'}
                    icon={<FontAwesomeIcon icon={faTimes} />}
                    onClick={props.removeFromList}
                />
            </GridWrapperSCP>
            {!!remainingQtt &&
                !!unit &&
                <QuantityInfoSCP>
                    <FontAwsomeIconCP icon={faInfoCircle} size={'2x'} />
                    <InfoSCP>
                        <TextCP
                            text={`Em estoque: ${MaskUtils.applyNumberMask(remainingQtt)} ${ProductUtils.getUnitOfMeasurementAbbreviation(unit)}`}
                        />
                    </InfoSCP>
                </QuantityInfoSCP>}
        </WppSCP>

    )
}

const GridWrapperSCP = styled.div`
    display: grid;
   grid-template-columns: 1fr .3fr .1fr;
   column-gap: 10px;
`
const WppSCP = styled.div`
    display: block;
  
`
const QuantityInfoSCP = styled.div`
    display: grid;
    grid-template-columns: .2fr 1fr;    
    margin-bottom: 3px;
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    position: relative;
    border: 1px solid ${props => props.theme.primaryColor};
    svg {
        color:  ${props => props.theme.primaryColor};
    }

    .ant-typography{
        font-size: 12px;       
    }
`
const InfoSCP = styled.div`

`
const ItemSCP = styled.div`
    display: grid;
    grid-template-columns: .4fr .4fr 1fr;
    column-gap: 10px;
    align-items: center;
   
    @media(max-width: 767px){
        margin: 18px 0;
        grid-template-columns: 1fr;
    }
`