import { faSeedling } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'

import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { FieldUtils } from 'modules/field/utils/FieldUtils'
import { ISearchHarvestPlanningResDTO } from 'modules/harvest-planning/services/dtos/response/ISearchHarvestPlanningResDTO'
import { HarvestPlanningRequests } from 'modules/harvest-planning/services/HarvestPlanningRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Skeleton } from 'antd'
interface IHarvestPlanningResumeCardCPProps {
    harvestPlanningCode: number
}

export function HarvestPlanningResumeCardCP(props: IHarvestPlanningResumeCardCPProps): JSX.Element {
    const [harvestPlanningData, setHarvestPlanningData] = useState<ISearchHarvestPlanningResDTO>()

    useEffect(getHvtPlanningDetails, [props.harvestPlanningCode])

    const request = useRequest<ISearchHarvestPlanningResDTO>()
    useEffect(onRequestChange, [request.awaiting])

    function getHvtPlanningDetails(): void {

        if (!!props.harvestPlanningCode)
            request.runRequest(HarvestPlanningRequests.get(props.harvestPlanningCode))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar resumo do planejamento!')
        }

        setHarvestPlanningData(request.returnData)
    }

    if (request.awaiting || !harvestPlanningData) {
        return (
            <WrapperSCP>
                <HeaderWrappseSCP>
                    <Skeleton.Avatar active={true} size={50} shape={'circle'} />
                    <TitleSCP>
                        <Skeleton.Button active={true} size={'small'} shape={'square'} />
                        <Skeleton.Button active={true} size={'small'} shape={'square'} />
                        <Skeleton.Button active={true} size={'small'} shape={'square'} />
                    </TitleSCP>
                </HeaderWrappseSCP>
            </WrapperSCP>
        )
    }
console.log(harvestPlanningData.field.coffeeVariety)
    return (

        <WrapperSCP>
            <GridSCP>
                <HighLightSCP>
                    <FontAwsomeIconCP icon={faSeedling} />
                </HighLightSCP>

                <InfoWrapperSCP>
                    <CategoryTitleSCP>{`Talhão: ${harvestPlanningData.field.name} (${FieldUtils.translateVarietysEnum(harvestPlanningData.field.coffeeVariety, harvestPlanningData.field.varieties.map(v => v.name).join(', '))} ${harvestPlanningData.field.plantingYear})`}</CategoryTitleSCP>

                    <ContentInfoSCP>
                        <RowSCP>
                            <TextCP text={'Tamanho:\u00A0'} style={{ fontWeight: '500 ' }} />
                            <TextCP text={`${harvestPlanningData.field.totalArea} ha`} />
                        </RowSCP>
                        <RowSCP>
                            <TextCP text={'Quantidade de pés:\u00A0'} style={{ fontWeight: '500 ' }} />
                            <TextCP text={`${harvestPlanningData.field.treeCount}`} />
                        </RowSCP>
                        <RowSCP>
                            <TextCP text={'Litragem total estimada:\u00A0'} style={{ fontWeight: '500 ' }} />
                            <TextCP text={`${harvestPlanningData.estimatedLiters * harvestPlanningData.field.treeCount} litros`} />
                        </RowSCP>
                        <RowSCP>
                            <TextCP text={'Litragem atual:\u00A0'} style={{ fontWeight: '500 ' }} />
                            <TextCP text={`${harvestPlanningData.totalLiters ?? 0} litros`} />
                        </RowSCP>
                    </ContentInfoSCP>

                </InfoWrapperSCP>
            </GridSCP>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    display: flex;
    margin: 15px 0;
    width: 100%;
    box-shadow: 0px 9px 18px -4px rgba(0,0,0,0.08);
    border-radius: 8px;
    padding: 10px;
    position: relative;
        &:before{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            border-radius: 0 5px 5px 0;
            background: ${props => props.theme.primaryColor}
        }
`

const GridSCP = styled.div`
    display: grid;
    grid-template-columns: 60px 1fr;
    column-gap: 5px;
    margin: 10px 10px 0 10px;
    align-items: center;
    position: relative;
    width: 100%;
`

const HeaderWrappseSCP = styled.div`
    display: grid;
    grid-template-columns: 50px 80%;   
    column-gap: 15px;
    margin-bottom: 8px;
    width: 100%;    
 
`

const TitleSCP = styled.div`
   display: flex;
   flex-direction: column;
   .ant-skeleton{
       margin: 5px 0;
       margin: 3px 0;
       span{
            width: 100%;
       }
   }
`

const HighLightSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    svg{
            font-size: 20px;
    }
`
const InfoWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
`

const CategoryTitleSCP = styled.div`
    font-size: 12px;
    color: ${props => props.theme.primaryColor};
    line-height: 15px;
    font-weight: 600;
`

const ContentInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 5px 0;
    width: 100%;
    .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const RowSCP = styled.div`
    display: flex;
    
`