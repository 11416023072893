import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { ProductRequests } from 'modules/maneuver/services/ProductRequests'
import { IProductResponseDTO } from 'modules/maneuver/services/dtos/responses/IProductResponseDTO'
import React, { useEffect, useState } from 'react'
import { ISearchProductRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchProductRequestDTO'
import { PaginationConfig } from 'antd/lib/pagination'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { useSelector } from 'react-redux'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import styled from 'styled-components'
import { CardCP } from 'common/components/card/CardCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { ProductListFiltersCP } from 'modules/maneuver/components/product-list-filters/ProductListFiltersCP'
import { TextCP } from 'common/components/text/TextCP'
import _ from 'lodash'
import { ProductCardICP } from 'modules/maneuver/components/product-list/inner/ProductCardICP'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchProductRequestDTO = {
    searchString: undefined,
    types: undefined,
    page: DEFAULT_PAGINATION.current,
    itemsPerPage: DEFAULT_PAGINATION.pageSize
}

function ProductList(): JSX.Element {
    const [productList, setProductList] = useState<IProductResponseDTO[]>([])
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const currentFilters: ISearchProductRequestDTO | null = useSelector((state: IReduxState) => _.get(state, 'searchState.filter'))
    const [pagination, setPagination] = useState<PaginationConfig>()
    const [total, setTotal] = useState<number>(0)

    const type: FilterTypesEnum = useSelector((state: IReduxState) => _.get(state, 'searchState.type'))

    const request = useRequest<IGenericListResponseDTO<IProductResponseDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(getProductList, [currentFilters])
    useEffect(onItemChange, [updateStatus])
    useEffect(loadPersistedData, [])

    function loadPersistedData(): void {

        if (type !== FilterTypesEnum.PRODUCT)
            return SearchActions.setFilter<ISearchProductRequestDTO>(DEFAULT_FILTERS, FilterTypesEnum.PRODUCT)

        if (!currentFilters) {
            SearchActions.setFilter<ISearchProductRequestDTO>({
                ...DEFAULT_FILTERS,
                page: 1
            }, FilterTypesEnum.PRODUCT)
        } else {
            SearchActions.setFilter<ISearchProductRequestDTO>({
                ...currentFilters,
                page: 1
            }, FilterTypesEnum.PRODUCT)
        }
    }
    function getProductList(): void {
        if (!!currentFilters && type === FilterTypesEnum.PRODUCT)
            request.runRequest(ProductRequests.getProductList(currentFilters))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE) {
            setProductList([])
            SearchActions.setFilter<ISearchProductRequestDTO>({
                ...DEFAULT_FILTERS,
                page: 1
            }, FilterTypesEnum.PRODUCT)
        }
    }

    function onChangeFilter(filter?: ISearchProductRequestDTO): void {
        setProductList([])
        SearchActions.setFilter({
            ...filter,
            page: DEFAULT_PAGINATION.current,
            itemsPerPage: DEFAULT_PAGINATION.pageSize,
        }, FilterTypesEnum.PRODUCT)
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried) {
            ListActions.isUpdating()
            return
        }

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar lista de atividades!')
        }
        setProductList(request.returnData.list)
        setTotal(request.returnData.total)
        setPagination({
            ...pagination,
            total: request.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                SearchActions.setFilter({ ...currentFilters, page, itemsPerPage: pageSize }, FilterTypesEnum.PRODUCT)
            }
        })
        ListActions.updateIsDone()
    }

    return (
        <WrapperSCP>
            <ProductListFiltersCP
                setFilters={onChangeFilter}
                filters={currentFilters ?? undefined}
            />
            <TotalSCP>
                <TextCP
                    text={`${total} registros encontrados`}

                />
                <TextCP
                    text={'Limpar filtros'}
                    onClick={() => {
                        setPagination(DEFAULT_PAGINATION)
                        SearchActions.setFilter({ ...DEFAULT_FILTERS }, FilterTypesEnum.PRODUCT)
                    }}
                    style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
                />
            </TotalSCP>
            <ListCP<IProductResponseDTO>
                grid={{ gutter: 16, column: 3, md: 1, sm: 1, xs: 1, lg: 2, xl: 3, xxl: 3 }}
                loading={request.awaiting}
                bordered={false}
                itemLayout={'vertical'}
                size={'large'}
                dataSource={productList}
                hasStateControl={true}
                locale={{
                    emptyText: <CardCP noSpacing={true} noSideSpacing={true}>
                        {' '}
                        <EmptyCP description={'Nenhum resultado'} />
                        {' '}
                    </CardCP>
                }}
                renderItem={(item: IProductResponseDTO) => (
                    <ProductCardICP product={item} loading={request.awaiting} />
                )}
                pagination={pagination}
            />
        </WrapperSCP>

    )
}

export const ProductListCP = React.memo(ProductList)

const WrapperSCP = styled.div`
     border-radius: 10px;
    margin-bottom: 4em;
    max-height: 75vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 79vh;        
    }
    
`

const Empty = styled.div`
     border-radius: 10px;
    @media(max-width: 786px){
        margin-left: 0;    
    }
`

const TotalSCP = styled.div`
    margin-left: 3px;
        display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      
   
    }
    @media(max-width: 768px){        
    margin-top: 17px;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`