import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { BatchLandingPageScreen } from 'modules/batch-landing-page/screens/BatchLandingPageScreen'

BatchLandingPageRoutes.ROOT = '/coffees/details'
BatchLandingPageRoutes.BATCH_DETAILS = `${BatchLandingPageRoutes.ROOT}/:batchCode`

export function BatchLandingPageRoutes(): JSX.Element {

    return (
        <Switch>
            <Route path={BatchLandingPageRoutes.BATCH_DETAILS} component={BatchLandingPageScreen} />
        </Switch>
    )
}
