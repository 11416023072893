import { PaymentUnityEnum } from 'modules/logbook/enums/PaymentUnityEnum'

export const UnityUtils = {
    translatePaymentUnity(
        unity: PaymentUnityEnum
    ): string {
        switch (unity) {
            case PaymentUnityEnum.LITERS:
                return 'litros'
            case PaymentUnityEnum.TREES:
                return 'pés'
            case PaymentUnityEnum.DAYS:
                return 'dias'
            case PaymentUnityEnum.HOURS:
                return 'horas'
            case PaymentUnityEnum.HECTARES:
                return 'hectares'
            default:
                return ''
        }
    },

    getUnityAbreviation(
        unity: PaymentUnityEnum
    ): string {
        switch (unity) {
            case PaymentUnityEnum.LITERS:
                return 'l'
            case PaymentUnityEnum.TREES:
                return 'pé'
            case PaymentUnityEnum.DAYS:
                return 'dia'
            case PaymentUnityEnum.HOURS:
                return 'h'
            case PaymentUnityEnum.HECTARES:
                return 'ha'
            default:
                return ''
        }
    }
}