import React, { useEffect, useState } from 'react'
import { Input } from 'antd'

interface IProps {
    mask: (value: any) => any
    length: number
    onChange?: (value: any) => void
    value?: string
}

export function MaskedInput(props: IProps): JSX.Element {
    const [value, setValue] = useState<string>('')

    useEffect(() => {
        if (props.value)
            setValue(props.value)
    }, [])

    useEffect(() => {
        if (!value)
            return

        if (props.onChange)
            props.onChange(value)

    }, [value])

    function onChange(e: any): void {
        setValue(e.target.value.replace(/\D/g, ''))
    }

    return <Input value={props.mask(value)} maxLength={props.length} onChange={onChange} />
}