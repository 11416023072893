import { PickerLocale } from 'antd/lib/date-picker/generatePicker'

export const localePtBR: PickerLocale = {
    lang: {
        locale: 'pt_BR',
        placeholder: 'Selecionar data',
        rangePlaceholder: ['Data inicial', 'Data final'],
        today: 'Hoje',
        now: 'Agora',
        backToToday: 'Voltar para hoje',
        ok: 'Ok',
        clear: 'Limpar',
        month: 'Mês',
        year: 'Ano',
        timeSelect: 'Selecionar horário',
        dateSelect: 'Selecionar data',
        monthSelect: 'Selecionar mês',
        yearSelect: 'Selecionar ano',
        decadeSelect: 'Selecionar década',
        yearFormat: 'YYYY',
        dateFormat: 'DD/MM/YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
        monthFormat: 'MMMM',
        monthBeforeYear: true,
        previousMonth: 'Mês anterior (PageUp)',
        nextMonth: 'Próximo mês (PageDown)',
        previousYear: 'Ano anterior (Control + left)',
        nextYear: 'Próximo ano (Control + right)',
        previousDecade: 'Década anterior',
        nextDecade: 'Próxima década',
        previousCentury: 'Último século',
        nextCentury: 'Próximo século',
        quarterPlaceholder: 'Selecionar trimestre',
        rangeMonthPlaceholder: ['Mês inicial', 'Mês final'],
        rangeWeekPlaceholder: ['Semana inicial', 'Semana final'],
        rangeYearPlaceholder: ['Ano inicial', 'Ano final'],
        weekPlaceholder: 'Selecionar semana',
        weekSelect: 'Selecionar semana',
        yearPlaceholder: 'Selecionar ano',
    },
    timePickerLocale: {
        placeholder: 'Selecionar horário',
        rangePlaceholder: ['Horário inicial', 'Horário final']
    },
    // dateFormat: 'DD/MM/YYYY',
    // dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
    // weekFormat: 'YYYY-wo',
    // monthFormat: 'YYYY-MM'
}
