import { InputCP } from 'common/components/form-fields/input/InputCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import React from 'react'
import styled from 'styled-components'
import { PrioritySelectorCP } from '../../priority-selector/PrioritySelectorCP'
import { IManeuverFields } from '../ManeuverDrawerCP'

interface IManeuverFieldsICPProps {
    data: IManeuverFields
    setData: (data: IManeuverFields) => void
}

export function ManeuverFieldsICP(props: IManeuverFieldsICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <PropertyFieldFieldsCP
                propertyCode={props.data.propertyCode}
                fieldCode={props.data.fieldCode}
                onChangeField={(fieldCode) => props.setData({ ...props.data, fieldCode })}
                onChangeProperty={(propertyCode) => props.setData({ ...props.data, propertyCode })}
            />

            <TwoColWrapperSCP>
                <InputCP
                    label={'Data de início'}
                    type={'date'}
                    value={props.data.startDate}
                    placeholder={'__/__/____'}
                    required={true}
                    mask={MaskUtils.applyDateMask}
                    onChange={(startDate) => props.setData({ ...props.data, startDate })}
                />
                <InputCP
                    label={'Estimativa de conclusão'}
                    placeholder={'__/__/____'}
                    required={true}
                    type={'date'}
                    value={props.data.estimatedEndDate}
                    mask={MaskUtils.applyDateMask}
                    onChange={(estimatedEndDate) => props.setData({ ...props.data, estimatedEndDate })}
                />
            </TwoColWrapperSCP>
            <PriorityWppSCP>
                <PriorityLabelSCP><label>Prioridade</label></PriorityLabelSCP>
                <PrioritySelectorCP
                    priority={props.data.priority}
                    onChangepriority={(priority) => props.setData({ ...props.data, priority })}
                />
            </PriorityWppSCP>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div``

const TwoColWrapperSCP = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  align-items: end;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const PriorityWppSCP = styled.div`
  padding-bottom: 5px;
  width: 60%;
`

const PriorityLabelSCP = styled.div`
  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: rgba(73, 73, 73, 0.85);
    font-weight: 500;
    font-size: 14px;
    color: #494949;
  }

`