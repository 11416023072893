import { InvoiceTypeEnum } from 'modules/financial/enums/InvoiceType'
import { InvoiceStatusEnum } from 'modules/financial/enums/InvoiceStatusEnum'
import { InvoiceCategoryEnum } from 'modules/financial/enums/InvoiceCategoryEnum'

export class FinancialUtils {

    static getFinancialType(type: InvoiceTypeEnum): string {
        switch (type) {
            case InvoiceTypeEnum.EXPENSE:
                return 'Despesa'
            case InvoiceTypeEnum.REVENUE:
                return 'Receita'
            default:
                return ''
        }
    }

    static translateFinancialStatusLabel(typeStatus: InvoiceStatusEnum, typeView: InvoiceTypeEnum): string {
        switch (typeStatus) {
            case InvoiceStatusEnum.ALL:
                return 'Todos'
            case InvoiceStatusEnum.FINISHED:

                return typeView === InvoiceTypeEnum.EXPENSE ? 'Pago' : 'Recebido'
            case InvoiceStatusEnum.PENDING:
                return 'Pendente'
            default:
                return ''
        }
    }

    static translateCategory(category: InvoiceCategoryEnum): string {

        switch (category) {
            case InvoiceCategoryEnum.SALES:
                return 'Venda'
            case InvoiceCategoryEnum.GENERAL:
                return 'Geral'
            case InvoiceCategoryEnum.PURCHASE:
                return 'Compra'
            case InvoiceCategoryEnum.MAINTENANCE:
                return 'Manutenção'
            case InvoiceCategoryEnum.PRODUCT:
                return 'Produtos'
            case InvoiceCategoryEnum.TEAM:
                return 'Equipe'

            default:
                return ''
        }
    }
}
