import { faCubes } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { TextCP } from 'common/components/text/TextCP'
import { GenericCardWidgetCP } from 'common/components/widgets/GenericCardWidgetCP'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface ITotalBatchesReportCardICPProps {
    total?: number
    withSensorialAnalyses?: number
    withPhysicalAnalyses?: number
    loading?: boolean
}
export function TotalBatchesReportCardICP(props: ITotalBatchesReportCardICPProps): JSX.Element {
    const labelStyle: CSSProperties = { fontSize: '11px' }
    const valueStyle: CSSProperties = { fontSize: '16px', fontWeight: 600 }

    return (
        <GenericCardWidgetCP
            gridArea={'total-batches'}
            title={'Lotes'}
            icon={<FontAwsomeIconCP icon={faCubes} />}
            content={
                <WrapperSCP>
                    <RowSCP>
                        <TextCP text={'Total'} style={labelStyle} />
                        {!!props.total ?
                            <TextCP text={`${props.total}`} style={valueStyle} />
                            : props.loading ?
                                <GenericRowSkeletonICP columns={1} rows={1} />
                                : <TextCP text={'0'} style={valueStyle} />}
                    </RowSCP>
                    <RowSCP>
                        <TextCP text={'Com análise sensorial'} style={labelStyle} />
                        {!!props.withSensorialAnalyses ?
                            <TextCP text={`${props.withSensorialAnalyses}`} style={valueStyle} />
                            : props.loading ?
                                <GenericRowSkeletonICP columns={1} rows={1} />
                                : <TextCP text={'0'} style={valueStyle} />}
                    </RowSCP>
                    <RowSCP>
                        <TextCP text={'Com análise física'} style={labelStyle} />
                        {!!props.withPhysicalAnalyses ?
                            <TextCP text={`${props.withPhysicalAnalyses}`} style={valueStyle} />
                            : props.loading ?
                                <GenericRowSkeletonICP columns={1} rows={1} />
                                : <TextCP text={'0'} style={valueStyle} />}
                    </RowSCP>
                </WrapperSCP>

            }
        />
    )
}

const WrapperSCP = styled.div`
    margin: 10px 0;
`
const RowSCP = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin: 5px 0;
    border-bottom: 1px solid ${props => props.theme.lightGray};
`