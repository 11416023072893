import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { LogoCP } from 'common/components/logo/LogoCP'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { SystemConfig } from 'config/SystemConfig'
import { AuthRequests } from 'modules/auth/AuthRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IResetPasswordFormCPProps {
    onSuccess: () => void
}

export function ResetPasswordFormCP(props: IResetPasswordFormCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const logo = screenSize.smd ? `${SystemConfig.getInstance().uiBaseUrl}/assets/logo-inverse-02.png` :
        `${SystemConfig.getInstance().uiBaseUrl}/assets/flowins-logo.png`

    const [email, setEmail] = useState<string>()
    const request = useRequest()

    useEffect(onRequestChange, [request.awaiting])

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Credenciais Inválidas!')
        }

        NotificationHelper.warning('Sua senha foi enviada para o seu e-mail!')
        props.onSuccess()
    }

    async function onFormSubmit(): Promise<void> {
        if (!email)
            return NotificationHelper.warning('Informe um e-mail válido!')
        request.runRequest(AuthRequests.recoverPassword({ email }))
    }

    return (
        <LoginFormWrapperSCP>
            <FormContainerSCP>
                <LogoCP style={{ marginBottom: '100px', maxWidth: '100%' }} inverse={screenSize.smd} />

                <InputCP
                    label={'E-mail'}
                    required={true}
                    onChange={setEmail}
                    value={email}
                />
                <ButtonCP
                    size={'middle'}
                    type={'primary'}
                    onClick={onFormSubmit}
                    wrapperStyle={{ width: '100%' }}
                >
                    Recuperar senha
                </ButtonCP>
                <InfoWarningSCP>
                    <FontAwsomeIconCP icon={faExclamationCircle} />
                    <TextWrapperSCP>
                        <TextCP text={'O e-mail informado deve ser o mesmo usado no seu cadastro na plataforma. Caso não se lembre do e-mail, por favor, entre em contato com nosso suporte.'} />
                    </TextWrapperSCP>
                </InfoWarningSCP>
            </FormContainerSCP>
        </LoginFormWrapperSCP>
    )
}

const FormContainerSCP = styled.div`
    width: 100%;
    margin: 3em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    height: 100%;
    .ant-btn{
        width: 100%;
        margin: 20px 0;
    }
`

const LoginFormWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 365px;
    height: 65%;

    @media(max-width: 768px){
        .ant-form-item-label > label{
           color: #fff!important;
       }
       .ant-form-item-required::before{
          color: #fff !important
       }
      .ant-btn-primary{
           background-color: #fff;
           color:  ${props => props.theme.primaryColor}
       }
    }
`

const InfoWarningSCP = styled.div`
    padding: 10px;
     margin: 10px 0;
    border: 1px solid ${props => props.theme.primaryColor};
    border-radius: 8px;
    align-items: center;
    display: grid;
    grid-template-columns: .2fr 1fr;
    padding-bottom: 10px;
    column-gap: 5px;
    svg {
        font-size: 20px;
        color: ${props => props.theme.primaryColor}
    }
    @media(max-width: 768px){
        color: white;
        border: 1px solid white;
        svg {
        color: white;
      }
    }
`
const TextWrapperSCP = styled.div`
display: flex;
    flex-direction: column;
    .ant-typography {
        font-size: 12px;
        color: ${props => props.theme.gray}
    }
    @media(max-width: 768px){
        .ant-typography {
            color: white;
        }
    }
`