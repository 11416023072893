import { Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

interface IGenericRowSkeletonICPProps {
    columns: number
    rows?: number
}
export function GenericRowSkeletonICP(props: IGenericRowSkeletonICPProps): JSX.Element {
    const columns = _.fill(Array(props.columns), {})
    const rows = _.fill(Array(props.rows ?? 1), {})

    return (
        <WrapperSCP>
            {
                rows.map((item, index) => (
                    <ContentWrapperSCP key={`row_${index}`} style={{ gridTemplateColumns: `repeat(${props.columns}, 1fr)` }}>
                        {columns.map((_item, _index) => <Skeleton.Button key={`col_${Math.random()}`} active={true} size={'small'} shape={'square'} />)}
                    </ContentWrapperSCP>
                ))
            }
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
`
const ContentWrapperSCP = styled.div`
    margin-bottom: 3px;
    border-radius: 8px;
    padding: 0 6px;
    display: grid;
    column-gap: 10px;
    .ant-skeleton-element .ant-skeleton-button-sm{
        width: 100%;
        height: 20px;
        margin: 3px 0;
   }
`

const HeaderWrappseSCP = styled.div`
   display: grid;
   grid-template-columns: 1fr;
   column-gap: 15px;
   margin-bottom: 8px;
`

const TitleSCP = styled.div`
   display: flex;
   flex-direction: column;
`

const InfoListWrapperSCP = styled.div`
   display: flex;
   flex-direction: column;
`

const FooterWrapperSCP = styled.div`
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    column-gap: 8px;
`
