import { Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'

export function FieldCardSkeletonICP(): JSX.Element {
    return (
        <ContentWrapperSCP>
            <Skeleton active={true} paragraph={{ rows: 2 }} title={false} />
            <PlantingInfoSCP>
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
            </PlantingInfoSCP>
            <ActionWrapperSCP>
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
            </ActionWrapperSCP>
        </ContentWrapperSCP>
    )
}

const ContentWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 4fr 1fr;
    column-gap: 10px;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    position: relative;
    &:before{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            border-radius: 0 5px 5px 0;
            background: ${props => props.theme.primaryColor}
        }
    box-shadow: -5px 10px 16px -4px rgba(0,0,0,0.21);
    @media(max-width: 768px){
        grid-template-columns: none;
        row-gap: 12px;
    }
`

const PlantingInfoSCP = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
   column-gap: 15px;
`

const ActionWrapperSCP = styled.div`
    display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 5px;
        align-items: end;
        @media(max-width: 768px){
            max-width: 120px;
            margin-top: 15px;
        }
`
