import { Drawer } from 'antd'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { TitleCP } from '../title/TitleCP'

interface IDrawerCPProps extends React.PropsWithChildren<{
    title: string | JSX.Element,
    closeIcon?: JSX.Element,
    rightPadding?: number,
    visible?: boolean,
    onClose?: () => void,
    width?: string | number,
    height?: string | number,
    placement?: 'top' | 'right' | 'bottom' | 'left',
    footer?: JSX.Element,
    responsiveWidth?: string,
    clearHeaderStyle?: boolean,
    removeBodyPadding?: boolean,
    push?: boolean,
}> { }

/**
 * COMPONENTE
 * Drawer.
 *
 * @author Stella
 * @author hjcostabr
 */
export function DrawerCP(props: IDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [showDrawer, setShowDrawer] = useState<boolean>()
    const dimensions = useWindowDimensions()

    useEffect(() => setShowDrawer(!!props.visible), [props.visible])

    function handleClose(): void {
        if (!!props.onClose)
            props.onClose()
        else
            setShowDrawer(false)
    }

    return (
        <DrawerSCP
            vh={dimensions.height * 0.01}
            rightPadding={props.rightPadding}
            title={
                <TitleWrapperSCP>
                    <TitleCP underLine={false}>{props.title}</TitleCP>
                </TitleWrapperSCP>
            }
            clearHeaderStyle={props.clearHeaderStyle}
            removeBodyPadding={props.removeBodyPadding}
            closeIcon={props.closeIcon}
            visible={showDrawer}
            placement={props.placement ?? 'right'}
            closable={true}
            destroyOnClose={true}
            zIndex={10}
            footer={props.footer}
            push={props.push}
            onClose={handleClose}
            height={props.height}
            width={screenSize.smd ? props.responsiveWidth ?? 'inherit' : props.width ? props.width : 450}
        >
            {props.children}
        </DrawerSCP>
    )
}

const DrawerSCP = styled(Drawer) <{ rightPadding?: number, vh: number, clearHeaderStyle?: boolean, removeBodyPadding?: boolean }>`

    .ant-drawer-content {
        height: calc(${props => props.vh}px * 100);
    }
    .ant-drawer-wrapper-body {
        overflow: overlay !important;
        display: flex;
        flex-direction: column;
    }

    .ant-drawer-header {
        position: relative;
        padding: 8px 24px;
        color: rgba(1, 1, 2, 0.65);
        background: ${props => props.theme.white};
        border-bottom: ${props => (props.clearHeaderStyle ? 'none' : `1px solid ${props.theme.lightGray}`)};
        &::after{
            display:  ${props => (props.clearHeaderStyle ? 'none' : 'block')};
            content: '';
            position: absolute;
            bottom: 0;
            left: 25px;
            width: 70px;
            height: 6px;
            border-radius: 10px 10px 0 0;
            background: ${props => props.theme.primaryColor}
        }
    }
    .ant-drawer-body {
        margin-right: ${props => (props.rightPadding ? props.rightPadding : 0)}px;
        height: 100%;
        overflow-y: auto;
        padding: ${props => (props.removeBodyPadding ? 0 : '20px')};
    }

    .ant-drawer-footer {
        height: 80px;  
        z-index: 99;
    }
    
    .anticon{
        color: ${props => props.theme.black};
    }
`

const TitleWrapperSCP = styled.div`
    white-space: normal;
    overflow-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
`
