import React from 'react'

export function MethodIconCP(props: any): JSX.Element {
	return (
		<span style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', marginRight: '10px' }}>
			<svg
				{...props}
				xmlns={'http://www.w3.org/2000/svg'}
				x={'0px'}
				y={'0px'}
				viewBox={'0 0 100 100'}
				width={30}
				enableBackground={'new 0 0 100 100'}
			>
				<g fill={'#fff'}>
					<path d="M-51.1,87.71c-0.46,1.15-1.32,1.56-2.55,1.56c-22.58-0.02-45.16-0.01-67.74-0.01c-1.8,0-2.47-0.66-2.47-2.46
		c0-3.57,0-7.15,0-10.72c0-0.13,0-0.26,0-0.39c0.04-0.84,0.49-1.35,1.18-1.33c0.66,0.02,1.11,0.5,1.13,1.3
		c0.02,1.31,0.01,2.62,0.01,3.93c0,0.59,0,1.17,0,1.81c4.39,0,8.69,0,13.05,0c0-5.34,0-10.65,0-16.05c-0.59,0-1.14,0.01-1.7,0
		c-1.45-0.02-2.31-0.87-2.33-2.3c-0.01-1-0.01-2.01,0-3.01c0.02-1.39,0.88-2.26,2.27-2.27c4.63-0.01,9.26-0.01,13.89,0
		c0.84,0,1.36,0.44,1.38,1.13c0.01,0.71-0.53,1.18-1.41,1.18c-4.29,0.01-8.59,0-12.88,0c-0.28,0-0.56,0-0.88,0c0,0.99,0,1.93,0,2.91
		c10.76,0,21.49,0,32.28,0c0-0.95,0-1.88,0-2.91c-0.3,0-0.57,0-0.84,0c-3.83,0-7.66,0-11.49,0c-0.26,0-0.52,0.02-0.77-0.03
		c-0.6-0.13-0.97-0.53-0.96-1.14c0.01-0.64,0.38-1.04,1.04-1.14c0.15-0.02,0.31-0.01,0.46-0.01c4.14,0,8.28,0,12.42,0
		c1.68,0,2.5,0.82,2.51,2.51c0.01,0.87,0.01,1.75,0,2.62c-0.02,1.59-0.85,2.42-2.43,2.44c-0.53,0.01-1.07,0-1.64,0
		c0,5.39,0,10.7,0,16.06c4.35,0,8.66,0,13.06,0c0-0.31,0-0.58,0-0.86c0-7.74,0.01-15.48-0.01-23.22c0-0.41-0.12-0.87-0.33-1.22
		c-2.45-3.92-4.91-7.84-7.41-11.73c-0.34-0.53-0.87-1.03-1.43-1.33c-5.91-3.17-11.84-6.3-17.77-9.41c-0.28-0.15-0.76-0.16-1.03-0.02
		c-5.94,3.1-11.87,6.23-17.78,9.39c-0.58,0.31-1.13,0.83-1.48,1.38c-2.51,3.89-4.97,7.8-7.41,11.73c-0.22,0.35-0.34,0.81-0.34,1.22
		c-0.02,3.99-0.01,7.97-0.02,11.96c0,0.23,0.01,0.46,0,0.69c-0.04,0.83-0.51,1.35-1.19,1.33c-0.65-0.02-1.08-0.52-1.12-1.32
		c-0.01-0.21,0-0.41,0-0.62c0-2.75,0-5.5,0-8.25c0-0.27,0-0.55,0-0.93c-1.15,0.81-2.26,1.17-3.53,0.84
		c-2.38-0.62-3.44-3.13-2.14-5.21c3.44-5.49,6.89-10.97,10.38-16.42c0.38-0.6,0.98-1.16,1.61-1.49c7.43-3.98,14.88-7.9,22.32-11.86
		c0.96-0.51,1.81-0.32,2.7,0.15c3.42,1.83,6.85,3.64,10.28,5.45c0.24,0.13,0.49,0.24,0.84,0.41c0.02-0.36,0.03-0.63,0.04-0.89
		c0.05-2.65-0.11-5.32,0.19-7.93c0.76-6.62,6.28-12.01,12.9-12.85c0.17-0.02,0.34-0.09,0.51-0.14c1.03,0,2.06,0,3.09,0
		c0.14,0.05,0.28,0.11,0.43,0.14c6.41,1.08,10.6,4.72,12.57,10.91c0.26,0.82,0.39,1.69,0.58,2.53C-51.1,45.12-51.1,66.42-51.1,87.71
		z M-53.47,40.93c0-4.38,0-8.68,0-12.99c-8.55,0-17.04,0-25.58,0c0,1.62,0.02,3.19-0.01,4.75c-0.01,0.48,0.16,0.73,0.58,0.94
		c2.17,1.12,4.29,2.32,6.48,3.39c1.63,0.8,3.05,1.78,3.87,3.48c0.09,0.2,0.42,0.4,0.64,0.4C-62.85,40.94-58.2,40.93-53.47,40.93z
		 M-79.08,25.51c8.63,0,17.12,0,25.69,0c-0.17-4.86-2.16-8.67-6.31-11.12c-4.46-2.63-9.05-2.55-13.43,0.21
		C-77.06,17.09-78.96,20.81-79.08,25.51z M-126.37,59.09c0.51-0.47,1.02-0.78,1.31-1.23c3.1-4.85,6.17-9.72,9.22-14.6
		c0.63-1.01,1.42-1.78,2.48-2.34c5.94-3.11,11.87-6.22,17.77-9.4c1.13-0.61,2.04-0.6,3.15,0c5.79,3.11,11.6,6.19,17.43,9.23
		c1.25,0.65,2.2,1.52,2.94,2.72c3.01,4.84,6.07,9.66,9.12,14.48c0.57,0.9,1.41,1.14,2.15,0.64c0.65-0.44,0.68-1,0.09-1.93
		c-3.32-5.26-6.62-10.52-9.97-15.76c-0.31-0.49-0.82-0.93-1.34-1.21c-7.14-3.81-14.29-7.58-21.45-11.34
		c-0.28-0.15-0.76-0.16-1.04-0.02c-7.25,3.8-14.5,7.63-21.73,11.47c-0.37,0.2-0.75,0.49-0.97,0.84c-3.46,5.44-6.9,10.9-10.31,16.37
		c-0.18,0.29-0.25,0.85-0.09,1.12C-127.39,58.5-126.91,58.7-126.37,59.09z M-53.44,73.97c-3.61,0-7.14,0-10.72,0
		c0,4.33,0,8.61,0,12.91c3.59,0,7.15,0,10.72,0C-53.44,82.57-53.44,78.31-53.44,73.97z M-64.16,71.55c3.6,0,7.15,0,10.71,0
		c0-4.34,0-8.62,0-12.99c-1.31,0-2.59,0.02-3.87-0.01c-0.49-0.01-0.77,0.1-1,0.59c-0.48,1.04-1.34,1.65-2.46,1.91
		c-1.24,0.29-2.31-0.12-3.38-0.83C-64.16,64.04-64.16,67.75-64.16,71.55z M-53.47,56.25c0-4.39,0-8.67,0-12.93
		c-4.32,0-8.58,0-12.93,0c2.69,4.26,5.32,8.43,7.96,12.59c0.1,0.16,0.35,0.32,0.53,0.32C-56.44,56.26-54.98,56.25-53.47,56.25z
		 M-95.75,76.15c-3.46-3.12-6.84-6.17-10.31-9.3c0,6.27,0,12.36,0,18.59C-102.59,82.31-99.21,79.27-95.75,76.15z M-92.29,76.15
		c3.47,3.13,6.84,6.16,10.28,9.26c0-6.22,0-12.32,0-18.53C-85.48,70.01-88.85,73.05-92.29,76.15z M-83.83,65.41
		c-6.86,0-13.54,0-20.37,0c3.46,3.12,6.81,6.14,10.19,9.18C-90.63,71.53-87.29,68.52-83.83,65.41z M-104.19,86.88
		c6.85,0,13.54,0,20.34,0c-3.45-3.11-6.81-6.13-10.18-9.17C-97.43,80.78-100.76,83.79-104.19,86.88z M-66.55,83.82
		c-4.38,0-8.69,0-13.01,0c0,1.06,0,2.07,0,3.08c4.36,0,8.67,0,13.01,0C-66.55,85.86-66.55,84.87-66.55,83.82z M-121.49,83.81
		c0,1.09,0,2.08,0,3.09c4.36,0,8.67,0,12.98,0c0-1.05,0-2.06,0-3.09C-112.87,83.81-117.15,83.81-121.49,83.81z"/>
					<path d="M-93.92,39.03c4.47,0.1,7.9,3.68,7.81,8.14c-0.09,4.38-3.72,7.8-8.16,7.69c-4.3-0.11-7.79-3.77-7.67-8.05
		C-101.81,42.41-98.23,38.93-93.92,39.03z M-88.42,46.98c0.01-3.13-2.51-5.65-5.61-5.64c-3.09,0.02-5.57,2.5-5.59,5.59
		c-0.02,3.11,2.49,5.63,5.62,5.62C-90.92,52.55-88.43,50.07-88.42,46.98z"/>
				</g>
				<g>
					<path d="M33.2,35.6c5.5-6.75,14.83-6.07,17.52,0.79c0.72-0.79,1.38-1.64,2.16-2.35c2.75-2.46,5.95-3.67,9.65-2.96
		c2.73,0.52,4.73,2.11,5.94,4.65c0.11,0.22,0.2,0.45,0.35,0.8c1.31-1.93,2.89-3.43,4.9-4.4c3.47-1.66,6.94-1.89,10.22,0.41
		c2.89,2.02,4.13,6.15,3.07,9.76c-0.09,0.31-0.18,0.62-0.3,1.02c0.55,0.07,1.06,0.1,1.55,0.19c5.17,1,8.02,5.86,6.61,11.21
		c-0.6,2.27-1.81,4.17-3.64,5.98c1.76,0,3.31,0,4.85,0c1.79,0,2.24,0.46,2.24,2.27c0,1.7,0,3.4,0,5.1
		c-0.01,1.52-0.53,2.04-2.08,2.04c-9.74,0-19.49,0-29.23,0c-21.19,0-42.37,0-63.56,0c-2.06,0-2.45-0.38-2.45-2.46
		c0-1.7-0.01-3.4,0-5.1c0.01-1.28,0.58-1.84,1.86-1.86c0.97-0.01,1.95,0,2.92,0c0.01-0.12,0.05-0.2,0.02-0.24
		c-3.56-4.91-1.11-12.05,4.1-15.25c0.85-0.53,1.79-0.98,2.75-1.26c0.8-0.23,0.94-0.61,0.95-1.37C13.7,36.48,19,31.04,25.01,30.9
		c3.48-0.08,6.21,1.28,7.97,4.38C33.04,35.39,33.12,35.48,33.2,35.6z M95.14,63.91c-30.37,0-60.66,0-90.95,0c0,1.04,0,2.01,0,3.02
		c30.33,0,60.62,0,90.95,0C95.14,65.91,95.14,64.94,95.14,63.91z M27.04,59.8c2.45,1.78,6.55,0.82,9.28-2.05
		c2.29-2.41,3.2-6.67,1.43-8.66c-0.86,0.51-1.77,0.97-2.57,1.58c-0.42,0.32-0.81,0.87-0.9,1.38c-0.46,2.67-1.59,3.81-4.32,4.26
		c-0.41,0.07-0.92,0.29-1.15,0.61C28.16,57.83,27.62,58.83,27.04,59.8z M45.11,59.81c2.67,1.82,6.88,0.72,9.56-2.37
		c2.31-2.66,2.71-6.83,1.14-8.36c-0.8,0.48-1.6,0.98-2.43,1.44c-0.65,0.36-0.97,0.85-1.06,1.62c-0.29,2.43-1.7,3.78-4.11,4.12
		c-0.45,0.06-1.02,0.25-1.27,0.58C46.26,57.78,45.71,58.82,45.11,59.81z M80.46,59.83c2.69,1.68,6.67,0.76,9.25-2.05
		c2.47-2.7,3.15-6.49,1.5-8.72c-0.83,0.49-1.65,1-2.5,1.48c-0.65,0.37-0.96,0.86-1.03,1.63c-0.18,1.99-1.45,3.71-3.28,3.9
		c-1.67,0.17-2.48,1.05-3.12,2.36C81.04,58.9,80.75,59.34,80.46,59.83z M63.19,59.83c2.59,1.66,6.5,0.82,9.11-1.89
		c2.58-2.69,3.32-6.54,1.65-8.87c-0.83,0.49-1.65,1-2.5,1.47c-0.65,0.36-0.97,0.85-1.04,1.63c-0.18,1.99-1.44,3.73-3.27,3.9
		c-1.73,0.16-2.5,1.13-3.17,2.45C63.74,58.95,63.47,59.35,63.19,59.83z M20.56,49.05c-0.99,0.6-2.01,1.17-2.96,1.84
		c-0.29,0.21-0.47,0.7-0.52,1.09c-0.33,2.55-1.68,3.92-4.18,4.29c-0.45,0.07-1.01,0.27-1.26,0.6c-0.68,0.93-1.23,1.97-1.83,2.96
		c2.63,1.67,6.59,0.8,9.18-1.96C21.53,55.17,22.23,51.37,20.56,49.05z M65.31,36.55c-0.88,0.53-1.71,1.06-2.57,1.53
		c-0.63,0.35-0.91,0.83-1,1.55c-0.3,2.51-1.75,3.86-4.27,4.2c-0.49,0.07-0.94,0.44-1.26,0.6c1.22,1.54,2.29,2.89,3.4,4.29
		c1.13-1.7,2.78-3.1,4.72-4.16c0.08-0.04,0.14-0.14,0.2-0.21C66.3,42.39,66.66,39.02,65.31,36.55z M38.12,44.43
		c1.22,1.55,2.3,2.93,3.22,4.1c1.36-1.13,2.85-2.38,4.34-3.61c0.2-0.16,0.46-0.26,0.65-0.43c1.81-1.75,2.25-5.6,0.88-7.91
		c-0.86,0.52-1.78,0.96-2.57,1.58c-0.44,0.34-0.86,0.92-0.95,1.45c-0.44,2.6-1.73,3.88-4.36,4.24
		C38.86,43.9,38.43,44.27,38.12,44.43z M74.21,44.45c1.14,1.31,2.13,2.45,3.14,3.62c1.35-1.66,3.06-3.04,5.17-3.89
		c0.17-0.07,0.34-0.21,0.45-0.36c1.51-2.03,1.68-5.41,0.39-7.25c-0.96,0.58-1.97,1.12-2.89,1.78c-0.31,0.23-0.54,0.74-0.59,1.15
		c-0.33,2.56-1.76,3.99-4.28,4.32C75.09,43.89,74.63,44.25,74.21,44.45z M30.01,36.56c-0.95,0.57-1.88,1.05-2.72,1.66
		c-0.37,0.27-0.71,0.79-0.77,1.24c-0.38,2.64-1.73,4.01-4.34,4.37c-0.49,0.07-0.94,0.45-1.32,0.64c1.13,1.3,2.11,2.42,3.1,3.55
		c1.35-1.71,3.12-3.05,5.26-3.9c0.2-0.08,0.37-0.27,0.5-0.44C31.11,41.82,31.26,38.68,30.01,36.56z M24.84,57.3
		c0.37-0.59,0.61-0.93,0.8-1.29c0.9-1.71,2.22-2.78,4.22-2.88c0.95-0.05,1.24-0.71,1.3-1.54c0.11-1.63,0.95-2.8,2.33-3.62
		c0.55-0.33,1.08-0.66,1.83-1.12c-3.05-0.75-5.47-0.01-7.61,1.79C25.09,50.83,24.03,53.61,24.84,57.3z M78.37,57.05
		c0.05-0.04,0.11-0.07,0.15-0.12c0.09-0.13,0.19-0.27,0.26-0.41c0.98-1.96,2.36-3.33,4.72-3.43c0.78-0.03,1.04-0.68,1.08-1.4
		c0.09-1.63,0.89-2.83,2.27-3.67c0.57-0.35,1.15-0.69,1.81-1.09c-2.73-1.03-6.18,0.11-8.48,2.67
		C78.15,51.86,77.36,55.12,78.37,57.05z M71.42,46.9c-3.11-1.02-6.64,0.32-8.92,3.17c-1.57,1.96-2.44,5.58-1.34,7
		c0.15-0.23,0.31-0.44,0.42-0.66c0.94-1.91,2.32-3.2,4.58-3.31c0.84-0.04,1.1-0.69,1.15-1.45c0.09-1.59,0.87-2.77,2.21-3.6
		C70.1,47.69,70.68,47.34,71.42,46.9z M18.04,46.89c-3.13-1.02-6.74,0.35-8.96,3.27c-1.7,2.23-2.3,5.38-1.27,6.9
		c0.15-0.23,0.31-0.44,0.42-0.67c0.94-1.92,2.32-3.19,4.59-3.3c0.84-0.04,1.09-0.7,1.14-1.46c0.1-1.55,0.84-2.72,2.14-3.54
		C16.69,47.71,17.29,47.35,18.04,46.89z M43.09,57.04c0.19-0.3,0.4-0.59,0.56-0.91c0.91-1.78,2.26-2.92,4.35-3.02
		c0.9-0.05,1.19-0.69,1.24-1.49c0.1-1.64,0.93-2.82,2.3-3.65c0.55-0.33,1.09-0.66,1.64-0.99c-3.14-0.96-5.73,0.02-7.88,2.14
		C43.12,51.29,42.09,53.9,43.09,57.04z M27.36,34.47c-5.17-1.79-11.4,3.61-10.53,8.89c0.66,0.27,1.1,0.14,1.5-0.55
		c0.77-1.32,1.98-2.03,3.53-2.14c1.06-0.08,1.42-0.45,1.51-1.54c0.12-1.55,0.86-2.71,2.14-3.53C26.08,35.24,26.66,34.9,27.36,34.47z
		 M33.97,43.31c0.84,0.4,1.22,0.05,1.59-0.56c0.76-1.25,1.91-1.93,3.37-2.06c1.22-0.11,1.52-0.38,1.64-1.62
		c0.16-1.59,0.94-2.75,2.29-3.55c0.54-0.32,1.08-0.66,1.8-1.11c-2.71-0.83-4.93-0.16-6.93,1.29C35.16,37.55,33.8,40.06,33.97,43.31z
		 M80.83,34.41c-2.83-0.68-5.18-0.21-7.23,1.46c-2.35,1.92-3.63,4.38-3.47,7.46c0.77,0.33,1.18,0.12,1.57-0.54
		c0.78-1.32,2-2.03,3.55-2.11c0.94-0.05,1.42-0.55,1.47-1.49c0.09-1.63,0.9-2.83,2.28-3.66C79.53,35.2,80.06,34.88,80.83,34.41z
		 M52.08,43.32c0.67,0.28,1.11,0.2,1.49-0.46c0.78-1.36,2.01-2.1,3.6-2.18c0.95-0.05,1.43-0.55,1.48-1.48
		c0.09-1.72,0.96-2.93,2.41-3.78c0.51-0.3,1-0.61,1.6-0.98C57.65,32.59,51.66,37.7,52.08,43.32z M41.16,60.65
		c-0.45-0.81-0.85-1.53-1.27-2.3c-0.65,0.77-1.27,1.5-1.95,2.3C39.04,60.65,40.06,60.65,41.16,60.65z M56.04,60.62
		c1.11,0,2.14,0,3.19,0c-0.44-0.8-0.84-1.52-1.26-2.27C57.32,59.12,56.7,59.85,56.04,60.62z M75.55,59
		c-0.52,0.58-0.96,1.07-1.49,1.65c0.89,0,1.63,0,2.48,0C76.2,60.09,75.91,59.61,75.55,59z M20.71,60.63c0.87,0,1.59,0,2.4,0
		c-0.36-0.57-0.66-1.05-0.98-1.57C21.64,59.6,21.22,60.07,20.71,60.63z"/>

				</g>
			</svg>
		</span >
	)
}
