import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from 'antd'
import { TextCP } from 'common/components/text/TextCP'
import { ReportItemICP } from 'modules/harvest-planning/components/harvest-general-report/inner/ReportItemICP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { HarvestPlanningRequests } from 'modules/harvest-planning/services/HarvestPlanningRequests'
import { IHarvestReportResDTO } from 'modules/harvest-planning/services/dtos/request/IHarvestReportResDTO'
import { ISearchHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISearchHarvestPlanningReqDTO'

import { useScreenSize } from 'common/hooks/UseScreenSize'
import { ReportSkeletonICP } from 'modules/harvest-planning/components/harvest-general-report/inner/ReportSkeletonICP'

interface IHarvestGeneralReportCPProps {
    filters?: ISearchHarvestPlanningReqDTO
}

export function HarvestGeneralReportCP(props: IHarvestGeneralReportCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [reportData, setReportData] = useState<IHarvestReportResDTO>()

    const request = useRequest<IHarvestReportResDTO>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(getReport, [props.filters])

    function getReport(): void {
        if (!!props.filters)
            request.runRequest(HarvestPlanningRequests.getGeneralReport(props.filters))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar relatório de colheita!')
        }

        setReportData(request.returnData)
    }

    if (!reportData || request.awaiting) {
        return (
            <WrapperSCP>
                <ReportSkeletonICP />
            </WrapperSCP>
        )
    }

    return (
        <WrapperSCP>
            {
                !screenSize.smd &&
                <RowSCP>
                    <TextCP text={'Resultados:'} />
                </RowSCP>
            }
            <ItemsWrapperSCP>
                <ReportItemICP
                    value={reportData.plannedLiters ?? 0}
                    unit={'L'}
                    label={'planejados'}
                />
                <ReportItemICP
                    value={reportData.harvestedLiters}
                    unit={'L'}
                    label={'colhidos'}
                />
                <ReportItemICP
                    value={reportData.processedLiters}
                    unit={'L'}
                    label={'processados'}
                />
                <ReportItemICP
                    value={reportData.benefitedLiters}
                    unit={'L'}
                    label={'beneficiados'}
                />
                <ReportItemICP
                    value={reportData.totalBags}
                    label={'sacas'}
                />
                <ReportItemICP
                    value={reportData.revenue ?? 0}
                    unit={'L'}
                    label={'rendimento'}
                />
                {/* <ChartWrapperSCP>
                    <RingProgressChartCP
                        percent={MathUtils.round(reportData.usePercentage)}
                        label={'aprv.'}
                    />
                </ChartWrapperSCP> */}
            </ItemsWrapperSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
    .ant-typography{
        font-size: 12px;   
    }
`
const RowSCP = styled(Row)`
    margin-bottom: 3px;
`
const ItemsWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr  1fr 1fr 1fr;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media(max-width: 768px){
        grid-template-columns: 1fr 1fr;
        row-gap: 30px;
    }
`
const ChartWrapperSCP = styled.div`
    position: absolute;
    right: -14px;
    top: -28px;
    @media(max-width: 767px){
        position: absolute;      
        right: 2em;
        bottom: -1em;
        top: unset;
    }

`