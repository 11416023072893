import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { BatchMethodEnum } from 'modules/batch/enums/BatchProcessEnum'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import React from 'react'
import styled from 'styled-components'

interface IBatchMethodSelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    label?: string
    value?: BatchMethodEnum
    loading?: boolean
    required?: boolean
    onSelect?: (value: BatchMethodEnum) => void
}

export function BatchMethodSelectorCP<FModelTP extends FormModelValidator = any>(props: IBatchMethodSelectorCPProps<FModelTP>): JSX.Element {

    function getOptions(): SelectOptionTP[] {
        return Object.values(BatchMethodEnum).map(e => ({ label: BatchUtils.translateMethod(e), value: e }))
    }

    return (
        <SelectCP
            options={getOptions()}
            label={props.label}
            placeholder={'Método'}
            formStateManager={props.formStateManager}
            fieldName={'method'}
            required={props.required}
            showSearch={true}
            onChange={props.onSelect}
            value={props.value}
            loading={props.loading}
        />
    )
}

const WrapperSCP = styled.div`
`
