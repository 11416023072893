import React from 'react'
import { Modal } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import styled from 'styled-components'

type AntProps = {
    visible?: boolean,
    title?: string,
    onOk?: () => void,
    onCancel?: () => void,
    okText?: string,
    cancelText?: string,
    width?: string,
    zIndex?: number,
    okButtonProps?: ButtonProps,
    destroyOnClose?: boolean,
    centered?: boolean,
    footer?: JSX.Element[],
    noCancel?: boolean,
}

type CustomProps = {
    onClose?: (okClicked: boolean) => void,
    noFooter?: boolean,
    disableOutsideClickClosing?: boolean,
    loading?: boolean,
}

interface IModalCPProps extends React.PropsWithChildren<AntProps & CustomProps> { }

/**
 * Componente wrapper de MODAL
 *
 * @author Kiara
 * @author renatofs
 * @author hjcostabr
 */
export function ModalCP(props: IModalCPProps): JSX.Element {

    function onClose(okClicked: boolean): void {

        if (!!props.onClose)
            props.onClose(okClicked)

        if (okClicked && !!props.onOk)
            props.onOk()

        if (!okClicked && !!props.onCancel)
            props.onCancel()
    }

    return (
        <ModalSCP
            centered={props.centered}
            title={props.title}
            width={props.width}
            visible={props.visible}
            onOk={() => onClose(true)}
            onCancel={() => onClose(false)}
            okText={props.okText ?? 'Salvar'}
            cancelText={props.cancelText ?? 'Cancelar'}
            zIndex={props.zIndex}
            maskClosable={props.disableOutsideClickClosing !== false}
            destroyOnClose={props.destroyOnClose}
            cancelButtonProps={{ ghost: props.noCancel }}
            noCancel={props.noCancel}
            okButtonProps={props.okButtonProps}
            confirmLoading={props.loading}
            noFooter={props.noFooter}
            footer={props.footer}
        >
            {props.children!}
        </ModalSCP>
    )

}

const ModalSCP = styled(Modal) <{ noFooter?: boolean, noCancel?: boolean }>`
    .ant-modal-footer {
        display: ${props => (props.noFooter ? 'none' : 'block')};

        .ant-btn-background-ghost {
            display: ${props => (props.noCancel ? 'none' : 'block')};
        }
    }
    &.ant-modal{
       top: 40px;
    }
`
