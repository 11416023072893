import React, { useEffect } from 'react'
import { useRequest } from 'common/request-helper/UseRequest'
import { PropertyRequests } from 'modules/property/services/PropertyRequests'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { NotificationHelper } from 'common/NotificationHelper'
import { PropertyRoutes } from 'modules/property/PropertyRoutes'
import { faArrowCircleRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { PopConfirmCP } from 'common/components/pop-confirm/PopConfirmCP'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'

interface IPropertyCardFooterICPProps {
    code: number
    reloadList: () => void
}

export function PropertyCardFooterICP(props: IPropertyCardFooterICPProps): JSX.Element {
    const deleteRequest = useRequest()
    const history = useHistory()
    useEffect(onDeleteRequestChange, [deleteRequest.awaiting])

    function deleteProperty(code: number): void {
        deleteRequest.runRequest(PropertyRequests.delete(code))
    }

    function onDeleteRequestChange(): void {

        if (deleteRequest.awaiting || !deleteRequest.tried)
            return

        if (!deleteRequest.success || !deleteRequest.returnData) {
            if (deleteRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteRequest.returnData, deleteRequest.error)
            return RequestUtils.showDefaultErrorNotification(deleteRequest.error, 'Erro ao remover propriedade!')
        }

        NotificationHelper.success('Propriedade removida com sucesso!')
        props.reloadList()
    }

    function redirectToInfo(): void {
        history.push(`${PropertyRoutes.ROOT}/${props.code}`)
    }

    return (
        <WrapperSCP>
            <PopConfirmCP
                title={'Tem certeza que deseja remover esta propriedade ?'}
                okText={'Sim'}
                cancelText={'Não'}
                onConfirm={() => deleteProperty(props.code)}
            >
                <FontAwsomeIconCP icon={faTrashAlt} size={'2x'} tooltip={'Remover'} loading={deleteRequest.awaiting} />
            </PopConfirmCP>
            <DetailsLinkSCP onClick={redirectToInfo}>
                Ver detalhes
                <FontAwsomeIconCP icon={faArrowCircleRight} />
            </DetailsLinkSCP>

        </WrapperSCP>
    )
}
const WrapperSCP = styled.div`
    padding: 15px;
    display:flex;
    justify-content: space-between;
`

const DetailsLinkSCP = styled.div`
    color: ${props => props.theme.primaryColor};
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg{
        margin-left: 5px;
    }
`