import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { Upload } from 'antd'
import styled from 'styled-components'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IPurchaseResponseDTO } from 'modules/maneuver/services/dtos/responses/IPurchaseResponseDTO'
import { FileUploadCP } from 'common/components/file-upload/FileUploadCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { ProductRequests } from 'modules/maneuver/services/ProductRequests'
import { ImplementRequests } from 'modules/maneuver/services/ImplementRequests'
import { FinancialRequests } from 'modules/financial/services/FinancialRequests'

interface IReceiptFileUploaderCPProps {
    type: 'purchase' | 'maintenance' | 'financial'
    subItemCode?: number
    itemCode?: number
    fireSaveReq?: number
    loading: boolean
    onUploadDone: () => void
    instructions?: string
    setHasFilesToSave?: () => void
    style?: CSSProperties
    setLoading?: (loading: boolean) => void
}

/**
 * Componente responsável por fazer o upload de imagem
 * Utiliza handlers customizados para fazer este upload.
 * @author kiara.vz
 */
export function ReceiptFileUploaderCP(props: IReceiptFileUploaderCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [files, setFiles] = useState<any>([])
    const timeoutId = useRef<any>()

    const pictureTypes = [
        { mime: 'image/jpeg', extension: 'jpeg' },
        { mime: 'image/jpg', extension: 'jpg' },
        { mime: 'image/png', extension: 'png' },
    ]

    const request = useRequest<IPurchaseResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(prepareToSave, [props.itemCode, props.subItemCode, props.fireSaveReq])
    //  useEffect(onFileListChange, [files])

    // function onFileListChange(): void {
    //     if (files.length && !!props.setHasFilesToSave)
    //         props.setHasFilesToSave()
    // }
    function prepareToSave(): void {

        if (!props.itemCode || !props.fireSaveReq)
            return

        if (files.length === 0)
            return props.onUploadDone()

        if (!!timeoutId.current)
            clearTimeout(timeoutId.current)

        timeoutId.current = setTimeout(() => saveImages(), 1000)
    }

    function appendImages(option: any): void {
        setFiles([...files, option.file])
    }

    function saveImages(): void {
        if (!props.itemCode || !props.fireSaveReq)
            return

        const formData = new FormData()
        for (const file of files)
            formData.append('files', file)

        switch (props.type) {
            case 'purchase':
                request.runRequest(ProductRequests.addImagesToPurchase(props.itemCode, props.subItemCode!, formData))
                return
            case 'maintenance':
                request.runRequest(ImplementRequests.addImagesToMaintenance(props.itemCode, props.subItemCode!, formData))
                return
            case 'financial':
                request.runRequest(FinancialRequests.addDocumentsToInvoice(props.itemCode, formData))
                return
            default:
                return
        }
    }

    function onRequestChange(): void {
        if (!!props.setLoading)
            props.setLoading(request.awaiting)

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao subir imagens!')
        }

        props.onUploadDone()
        setFiles([])
    }

    return (
        <FileUploaderWrapperSCP>
            <FileUploadCP
                uploadFiles={appendImages}
                fileTypes={pictureTypes}
                loading={request.awaiting}
                showUploadList={true}
                multiple={true}
            />
            <InstructionsSCP>
                {props.instructions}
            </InstructionsSCP>
            <LoadingOverlayCP show={props.loading} />
        </FileUploaderWrapperSCP>
    )
}

const InstructionsSCP = styled(Upload)`
.ant-upload-select-text{
    span{
        font-size: 12px;
    }
    margin-top: 10px;
}
`
const FileUploaderWrapperSCP = styled.div`
        margin: 20px 0;
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
        justify-content: center;
        display: flex;
        flex-direction: column;
        span{
            text-align: center;
            width: 100%;
        }
        .ant-upload.ant-upload-select{
            width: 100%;
        }
`