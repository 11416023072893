import React from 'react'
import styled from 'styled-components'

export function VersionMenuItemICP(): JSX.Element {
    return (
        <VersionSCP>
            v2.14.2
        </VersionSCP>
    )
}

const VersionSCP = styled.div`
  text-align: center;
  font-size: 12px;
  color: #cdcdcd;
  background: #fff;
  border-radius: 0 0 10px 10px;
  
`
