import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { UnityUtils } from 'common/utils/UnityUtils'
import { PaymentUnityEnum } from 'modules/logbook/enums/PaymentUnityEnum'
import React from 'react'
import styled from 'styled-components'

interface IPaymentUnitySelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    label?: string
    value?: PaymentUnityEnum
    required?: boolean
    loading?: boolean
    onSelect?: (type: PaymentUnityEnum) => void
}

export function PaymentUnitySelectorCP(props: IPaymentUnitySelectorCPProps): JSX.Element {

    function getOptions(): SelectOptionTP[] {
        return Object.values(PaymentUnityEnum).map(e => ({ label: UnityUtils.translatePaymentUnity(e), value: e }))
    }

    return (
        <SelectCP
            options={getOptions()}
            label={props.label}
            placeholder={'Unidade'}
            formStateManager={props.formStateManager}
            fieldName={'paymentUnity'}
            required={props.required}
            onChange={props.onSelect}
            value={props.value}
            loading={props.loading}
        />
    )
}

const WrapperSCP = styled.div`
`
