import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { ProductTypeEnum } from 'modules/maneuver/enums/ProductTypeEnum'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import React from 'react'

interface IProductTypeSelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    fieldName?: string
    label?: string
    required?: boolean
    value?: ProductTypeEnum
    onSelect?: (value: ProductTypeEnum) => void
}

export function ProductTypeSelectorCP<FModelTP extends FormModelValidator = any>(props: IProductTypeSelectorCPProps<FModelTP>): JSX.Element {

    function getOptions(): SelectOptionTP[] {
        const options: SelectOptionTP[] = Object.values(ProductTypeEnum).map(e => ({ label: ProductUtils.translateTypeLabel(e), value: e }))
        options.unshift({ label: 'Todos', value: undefined })

        return options
    }

    return (
        <SelectCP
            options={getOptions()}
            label={props.label}
            placeholder={'Tipo'}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName ?? 'type'}
            required={props.required}
            onChange={props.onSelect}
            value={props.value}
        />
    )
}