import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useRequest } from 'common/request-helper/UseRequest'
import { IFieldResponseDTO } from 'modules/field/services/dtos/responses/IFieldResponseDTO'
import { FieldRequests } from 'modules/field/services/FieldRequests'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { DescriptionSectionCP } from 'common/components/description-section/DescriptionSectionCP'
import { IconInfoGridCP } from 'common/components/icon-info-grid/IconInfoGridCP'
import { FieldUtils } from 'modules/field/utils/FieldUtils'
import { Skeleton } from 'antd'

interface IFieldDetailsCPProps {
    fieldCode: number
    propertyCode: number
}

export function FieldDetailsCP(props: IFieldDetailsCPProps): JSX.Element {
    const request = useRequest<IFieldResponseDTO>()
    const [fieldData, setFieldData] = useState<IFieldResponseDTO>()
    useEffect(getFieldInfo, [props.fieldCode])
    useEffect(onRequestChange, [request.awaiting])

    function getFieldInfo(): void {
        if (!props.fieldCode || !props.propertyCode)
            return
        request.runRequest(FieldRequests.get(props.propertyCode, props.fieldCode))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Some error ocurred!')
        }
        setFieldData(request.returnData)

    }

    if (!fieldData) {
        return (
            <FieldDetailsWrapperSCP>
                <Skeleton paragraph={{ rows: 6, style: { opacity: 0.3 }}} active={true} title={false} />
            </FieldDetailsWrapperSCP>
        )
    }

    return (
        <FieldDetailsWrapperSCP>
            <DescriptionSectionCP
                title={fieldData.name}
                mainColor={'#fff'}
                upperTitle={'Field'}
                extra={
                    <IconInfoGridCP<IFieldResponseDTO>
                        gridColumns={3}
                        mainColor={'#fff'}
                        sourceData={fieldData}
                        columns={FieldUtils.getIconGrigColumnsConfig()}
                    />
                }
            />
        </FieldDetailsWrapperSCP>
    )
}
const FieldDetailsWrapperSCP = styled.div`
    margin: 30px 0; 
`
