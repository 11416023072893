import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface ICardListItemICPProps<T> {
    key: number
    item: T
    header: JSX.Element
    content: JSX.Element
    footer: JSX.Element
    loading: boolean
    customSkeleton: JSX.Element
    style?: CSSProperties
}

/**
 * COMPONENTE
 * Item de uma lista em formato de CARD customizado para listas padrao do projeto
 */
export function CardListItemICP<T>(props: ICardListItemICPProps<T>): JSX.Element {
    return (
        <CardWrapperSCP style={props.style}>
            <ListItemICP
                key={props.key}
                title={props.header}
                loading={props.loading}
                customSkeleton={props.customSkeleton}
            >
                {props.content}
                {props.footer}
            </ListItemICP>
        </CardWrapperSCP>

    )
}
const CardWrapperSCP = styled.div`
    margin-top: 10px;  
`
