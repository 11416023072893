import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { PersistConfig } from 'redux-persist'
import createEncryptor from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage'
import { SystemConfig } from 'config/SystemConfig'
import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'

/**
 * CONFIG
 * Encapsula configuracoes para persistencia local de valores
 * mantidos no redux com biblioteca 'redux-persist'.
 *
 * @author hjcostabr
 */
export class ReduxPersistorHelper {

    static readonly ACTION_REHYDRATE = 'persist/REHYDRATE'

    private static readonly PERSIST_WHITELIST: Array<keyof IReduxState> = [
        'loggedUser',
        'schemaData',
    ]

    private static readonly PERSIST_CONFIG_DEFAULT: PersistConfig<IReduxState> = {
        key: 'root',
        storage,
    }

    private constructor() { }

    /** Gera & retorna parametros de configuracao do 'persistor'. */
    static getPersistorConfig(): PersistConfig<IReduxState> {

        const encryptor = createEncryptor({
            secretKey: SystemConfig.getInstance().reduxEncryptKey,
            onError: error => {
                console.error('FALHA - ReduxPersistorHelper.getPersistorConfig.createEncryptor: ', error)
            }
        })

        return {
            ...this.PERSIST_CONFIG_DEFAULT,
            whitelist: this.PERSIST_WHITELIST,
            transforms: SystemConfig.getInstance().environment === EnvironmentEnum.DEV ? [] : [encryptor]
        }
    }
}

