import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { LoggedUserDataTP, SchemaDataTP } from 'common/redux/ReduxTypes'
import { useRequest } from 'common/request-helper/UseRequest'
import { ThemeUtils } from 'common/utils/ThemeUtils'
import { ThemeConfig } from 'config/ThemeConfig'
import _ from 'lodash'
import { AuthActions } from 'modules/auth/AuthActions'
import { SchemaNotFoundScreen } from 'modules/auth/screens/SchemaNotFoundScreen'
import { SchemaActions } from 'modules/company/actions/SchemaActions'
import { CompanyRequests } from 'modules/company/services/CompanyRequests'
import { ICompanyResDTO } from 'modules/company/services/dtos/response/ICompanyResDTO'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GeneralRouter } from '../routers/GeneralRouter'
import { SchemaRouter } from '../routers/SchemaRouter'
import { AppUtils } from '../utils/AppUitils'

/**
 * MIDDLEWARE
 * Verifica rota para busca de empresas e definição do schema
 */
export function SchemaMiddleware(): JSX.Element {
    const path = window.location.pathname
    const param = path.replace(/\D/g, '')
    const location = path.replace('/', '').replace('/entrar', '')
    const persistedSchema: SchemaDataTP | null = useSelector((state: IReduxState) => _.get(state, 'schemaData'))

    const [rootLocationData, setRootLocation] = useState<string>(location)

    const schemaReq = useRequest<ICompanyResDTO>()
    useEffect(onSchemaReqChange, [schemaReq.awaiting])

    useEffect(verifySchema, [])

    function verifySchema(): void {

        if (!!persistedSchema) {
            const base = persistedSchema.domain.substring(+persistedSchema.domain.lastIndexOf('/') + 1)
            console.log(base)
            setRootLocation(base)
            if (location.includes(base))
                return
        }
        getSchema()
    }

    async function getSchema(): Promise<void> {
        let trimLocation = location

        if (!!location) {
            if (location === 'entrar' || location === 'coffees' || AppUtils.getInternalRoutes(param).includes(path)) {
                SchemaActions.clearSchemaData()
                return
            }

            if (location.split('/').length > 1) // caso esteja em uma rota interna (ex.: schema/manejo), remove o final da rota
                trimLocation = location.substring(0, location.indexOf('/'))

            if (trimLocation === 'coffees')
                return

            return await schemaReq.runRequest(CompanyRequests.getCompany({ schema: trimLocation }))
        }
    }

    function onSchemaReqChange(): void {
        if (schemaReq.awaiting || !schemaReq.tried)
            return

        if (!schemaReq.success) {
            SchemaActions.clearSchemaData()
            console.log('ERRO AO BUSCAR SCHEMA')
        }

        if (!!schemaReq.returnData) {
            SchemaActions.persistSchema(schemaReq.returnData)
            AuthActions.logout()
            setRootLocation(schemaReq.returnData.domain.substring(+schemaReq.returnData.domain.lastIndexOf('/') + 1))
        }
    }


    if (schemaReq.awaiting)
        return <LoadingOverlayCP show={true} />

    if (!!persistedSchema && !!schemaReq.awaiting)
        return <LoadingOverlayCP show={true} />

    if (!!persistedSchema)
        return <SchemaRouter basename={rootLocationData} />

    if (location === 'entrar' || location === '' || location.includes('coffees') || location.includes('index.html') || AppUtils.getInternalRoutes(param).includes(path))
        return <GeneralRouter />

    return <SchemaNotFoundScreen />

}
