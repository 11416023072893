import { TextCP } from 'common/components/text/TextCP'
import { BatchTreeResumeCardICP } from 'modules/batch/components/batch-tree-drawer/inner/BatchTreeResumeCardICP'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import React from 'react'
import styled from 'styled-components'

interface ISplitTreeICPProps {
    batch: IBatchResponseDTO
    childrenBatches: IBatchResponseDTO[]
}

export function SplitTreeICP(props: ISplitTreeICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TextCP
                text={'Lote dividido'}
                style={
                    {
                        fontWeight: '500 ',
                        marginBottom: '20px',
                        justifyContent: 'center',
                        display: 'flex'
                    }
                }
            />
            <MainSCP>
                <CardWrapperSCP key={`${props.batch.code}_key`} className={'parent'}>
                    <BatchTreeResumeCardICP
                        batch={props.batch}
                        isMainBatch={true}
                    />
                </CardWrapperSCP>
            </MainSCP>
            {
                props.childrenBatches.length % 2 === 0 &&
                <ContectorLineSCP />
            }
            <TreeSCP>
                {
                    props.childrenBatches.map(parent => (
                        <CardWrapperSCP key={`${parent.code}_key`} className={'children'} parentQtt={props.childrenBatches.length}>
                            <BatchTreeResumeCardICP
                                batch={parent}
                                isMainBatch={false}
                            />
                        </CardWrapperSCP>
                    ))
                }
            </TreeSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
const TreeSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    min-height: 123px;  
    row-gap: 8px;
`

const MainSCP = styled.div`
    display: flex;  
    align-items: center;
    justify-content: center;
    margin-bottom: 81px;
`

const CardWrapperSCP = styled.div<{ parentQtt?: number }>`
    border: 1px solid ${props => props.theme.primaryColor};
    padding: 0px 10px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    min-height: 137px;
    flex: 1 0 30%;
    &.children {
        &::after{      
            position: absolute;
            content: '';
            background: ${props => props.theme.primaryColor};
            width: 1px;
            height: 81px;
            bottom: 136px;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: -1;
        }
           
    }
    &.parent{
        max-width: 300px;     
        border: 2px solid ${props => props.theme.primaryColor};  
        &::after{      
            position: absolute;
            content: '';
            background: ${props => props.theme.primaryColor};
            width: 1px;
            height: 80px;
            top: 135px;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: -1;
        }
    }

    @media(max-width: 768px){
        &.parent{
            &:after{
                top: 166px;
            }
        }
        &.children {
            &:after{
                height: 85px;
                bottom: 190px;
            }
        }
    }
   
`
const ContectorLineSCP = styled.div`
    background: ${props => props.theme.primaryColor};  
    width: 50%;
    height: 1px;  
    margin: 0 auto;
    margin-bottom: 80px;
    @media(max-width: 768px){
     
    }
`