import { Row } from 'antd'
import { TextCP } from 'common/components/text/TextCP'
import { StructureFilterFieldsICP } from 'modules/structure/components/structure-list-filters/inner/StructureFilterFieldsICP'
import { ISearchStructureReqDTO } from 'modules/structure/services/dtos/request/ISearchStructureReqDTO'
import React from 'react'
import styled from 'styled-components'

interface IStructureListFiltersCPProps {
    setFilters: (filters: ISearchStructureReqDTO) => void
    filters?: ISearchStructureReqDTO
}

export function StructureListFiltersCP(props: IStructureListFiltersCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <Row>
                <TextCP text={'Filtre por:'} />
            </Row>
            <StructureFilterFieldsICP
                onChangeType={(type) => props.setFilters({ ...props.filters, type })}
                filters={props.filters}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
    .ant-typography{
        font-size: 12px;   
    }

`