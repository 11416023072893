import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/RequestManagerTypes'
import { SystemConfig } from 'config/SystemConfig'

export class EnumRequests {
    private constructor() { }

    static search = (enumName: string): RequestConfigTP => {
        return ({
            url: `${SystemConfig.getInstance().oldApiBaseUrl}/enum/${enumName}`,
            method: HttpMethodEnum.GET,
        })
    }

}
