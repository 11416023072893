import React, { useEffect, useState } from 'react'

import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { DocumentActionsICP } from 'modules/property/components/property-document-list/inner/DocumentActionsICP'
import { DocumentCardSkeletonICP } from 'modules/property/components/property-document-list/inner/DocumentCardSkeletonICP'
import { DocumentPreviewDrawerCP } from 'modules/property/components/property-details/document-preview-drawer/DocumentPreviewDrawerCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IDocumentResponseDTO } from 'modules/property/services/dtos/responses/IDocumentResponseDTO'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { PropertyRequests } from 'modules/property/services/PropertyRequests'
import { PropertyUtils } from 'modules/property/utils/PropertyUtils'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { TextCP } from 'common/components/text/TextCP'
import _ from 'lodash'
import styled from 'styled-components'
import { useRequest } from 'common/request-helper/UseRequest'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { useSelector } from 'react-redux'

interface IDocumentCardICPProps {
    document: IDocumentResponseDTO
    loading: boolean
    reloadList: () => void
}

/**
 * COMPONENTE
 * Card de item de 1 documento cadastrado na proriedade (pdf, xlsx, doc, jpg)
 * @todo investigar porque crasha quando usa o loading da lista
 */
export function DocumentCardICP(props: IDocumentCardICPProps): JSX.Element {
    const property = useSelector((state: IReduxState) => state.property)
    const screenSize = useScreenSize()

    const [showPreview, setShowPreview] = useState<boolean>(false)
    const [showFieldDrawer, setShowFieldDrawer] = useState<boolean>(false)

    const deleteRequest = useRequest()
    useEffect(onDeleteRequestChange, [deleteRequest.awaiting])

    function onDeleteRequestChange(): void {

        if (deleteRequest.awaiting || !deleteRequest.tried)
            return

        if (!deleteRequest.success || !deleteRequest.returnData) {
            if (deleteRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteRequest.returnData, deleteRequest.error)
            return RequestUtils.showDefaultErrorNotification(deleteRequest.error, 'Erro ao remover documento!')
        }
        props.reloadList()
    }

    function removeItem(): void {
        deleteRequest.runRequest(PropertyRequests.removeDocuments(property.code, { files: [props.document.src] }))
    }

    if (_.isEmpty(props.document))
        return <DocumentCardSkeletonICP />

    return (
        <WrapperSCP>
            <ListItemICP
                isMobile={screenSize.smd}
                key={props.document.src}
                loading={props.loading}
                customSkeleton={<DocumentCardSkeletonICP />}
            >

                <ContentWrapperSCP>
                    <MainInfoWrapperSCP>
                        {PropertyUtils.getDocumentThumb(props.document.src)}
                        <TextInfoSCP>
                            <TextCP text={PropertyUtils.getDocumentName(props.document.src)} size={'large'} />
                            <TextCP text={PropertyUtils.getDocumentType(props.document.type)} size={'small'} style={{ color: `${CostumizedThemeConfig.gray}` }} />
                        </TextInfoSCP>
                    </MainInfoWrapperSCP>
                    <DocumentActionsICP
                        onDownload={() => window.open(props.document.src)}
                        onDelete={removeItem}
                        onPreview={() => setShowPreview(true)}
                        loading={deleteRequest.awaiting}
                    />
                    <DocumentPreviewDrawerCP
                        visible={showPreview}
                        onClose={() => setShowPreview(false)}
                        document={props.document}
                    />
                </ContentWrapperSCP>

            </ListItemICP>
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div`

`

const ContentWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    @media(max-width: 786px){
        flex-direction: column;
    }
`

const TextInfoSCP = styled.div`
    overflow: hidden;
   
`
const MainInfoWrapperSCP = styled.div`
    display:grid;
    grid-template-columns: 1fr auto;
    column-gap: 15px;
    align-items: center;
   svg{
       font-size: 30px;
       color: ${props => props.theme.primaryColor}
   }
   @media(max-width: 786px){
        grid-template-columns: .5fr auto;     
       margin-bottom: 15px;
    svg{
       font-size: 50px;
    }
   }
`

const ActionsWrapperSCP = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 5px;
        @media(max-width: 768px){
            max-width: 120px;
        }
`