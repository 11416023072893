import {
    faArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MenuItemICP } from 'common/components/menu/inner/MenuItemICP'
import { MenuCP } from 'common/components/menu/MenuCP'
import { TextCP } from 'common/components/text/TextCP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { ReportActions } from 'modules/report/ReportActions'
import { ReportRoutes } from 'modules/report/ReportRoutes'
import { ReportMenuUtils } from 'modules/report/utils/ReportMenuUtils'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

export function ReportsMenuCP(props: { isCollapsed?: boolean, shouldCollapse?: (collapse: boolean) => void }): JSX.Element {
    const history = useHistory()
    const location = useLocation()

    return (
        <WrapperSCP>
            <WrapperTitleCP
                active={true}
                onClick={() => {
                    history.push(ReportRoutes.ROOT)
                    ReportActions.toggleChildMenu(false)
                }}
            >
                <TextCP
                    text={'Voltar'}
                    iconCP={<FontAwesomeIcon icon={faArrowLeft} size={'2x'} style={{ marginRight: '20px' }} />}
                />
            </WrapperTitleCP>
            <MenuCP
                mode={'inline'}
                selectable={false}
                inlineCollapsed={props.isCollapsed}
            >
                {ReportMenuUtils.getMainMenuOptions().map(opt => {
                    return (
                        <MenuItemICP
                            title={opt.title}
                            icon={opt.icon}
                            menuActive={location.pathname === opt.route}
                            onClick={() => history.push(opt.route)}
                            key={`menu-opts-route${opt.route}`}
                        />
                    )
                })}
            </MenuCP>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    padding-top: 20px;
`

const WrapperTitleCP = styled.div`
  padding: 16px 25px;
  margin-top: -20px;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  .ant-typography{
      color:${props => props.theme.primaryColor};
  }

  svg {
     color:${props => props.theme.primaryColor};
     fill:${props => props.theme.primaryColor};
  }

  ${({ active }: { active?: boolean }) =>
        active &&
        `
        font-weight: bold;
    `}
`