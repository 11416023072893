import styled from 'styled-components'
import React from 'react'
import { RadioGroupCP, RadioOptionProps } from 'common/components/radio-group/RadioGroupCP'
import { ActivityStatusEnum } from 'modules/maneuver/enums/ActivityStatusEnum'
import { ManeuverUtils } from 'modules/maneuver/utils/ManeuverUtils'

interface IActivityStatusFilterICPProps {
    onChangeStatus: (status: ActivityStatusEnum) => void
    status?: ActivityStatusEnum
}

export function ActivityStatusFilterICP(props: IActivityStatusFilterICPProps): JSX.Element {

    function getOptions(): RadioOptionProps[] {
        return Object.values(ActivityStatusEnum).map(status => ({
            value: status,
            content: ManeuverUtils.translateActivityStatusLabel(status)
        }))
    }

    return (
        <WrapperSCP>
            <RadioGroupCP
                type={'button'}
                options={getOptions()}
                selected={props.status}
                onChange={props.onChangeStatus}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

