import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PaginationConfig } from 'antd/lib/pagination'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DividerCP } from 'common/components/divider/DividerCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import _ from 'lodash'
import { ManeuverFormValidator } from 'modules/maneuver/components/maneuver-drawer/inner/ManeuverFormValidator'
import { RecipeCardCP } from 'modules/maneuver/components/recipe-card/RecipeCardCP'
import { RecipeDrawerCP } from 'modules/maneuver/components/recipe-drawer/RecipeDrawerCP'
import { RecipeSelectorCP } from 'modules/maneuver/components/recipe-selector/RecipeSelectorCP'
import { ActivityStatusEnum } from 'modules/maneuver/enums/ActivityStatusEnum'
import { PriorityEnum } from 'modules/maneuver/enums/PriorityEnum'
import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'
import { ManeuverRoutes } from 'modules/maneuver/ManeuverRoutes'
import { ISaveManeuverFieldsReqDTO } from 'modules/maneuver/services/dtos/requests/ISaveManeuverFieldsReqDTO'
import { ISaveManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveManeuverRequestDTO'
import { ISearchManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchManeuverRequestDTO'
import { IManauverItemResponseDTO } from 'modules/maneuver/services/dtos/responses/IManauverItemResponseDTO'
import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'
import { ISearchManeuverResponseDTO } from 'modules/maneuver/services/dtos/responses/ISearchManeuverResponseDTO'
import { ManeuverRequests } from 'modules/maneuver/services/ManeuverRequests'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ManeuverFieldsICP } from './inner/ManeuverFieldsICP'

export interface IManeuverFields {
    startDate?: string
    estimatedEndDate?: string
    fieldCode?: number
    propertyCode?: number
    priority: PriorityEnum
}
interface IManeuverDrawerCProps {
    show: boolean
    maneuverCode?: number
    recipeType?: RecipeTypeEnum
    recipeCode?: number
    onCancel: () => void
    onSuccess: (recipeData?: ISearchManeuverRequestDTO) => void
}

const RAW_DATA: IManeuverFields = {
    startDate: undefined,
    estimatedEndDate: undefined,
    fieldCode: undefined,
    propertyCode: undefined,
    priority: PriorityEnum.MEDIUM
}
export function ManeuverDrawerCP(props: IManeuverDrawerCProps): JSX.Element {
    const screenSize = useScreenSize()
    const history = useHistory()
    const currentFilters: ISearchManeuverRequestDTO | null = useSelector((state: IReduxState) => _.get(state, 'searchState.filter'))
    const type: FilterTypesEnum = useSelector((state: IReduxState) => _.get(state, 'searchState.type'))

    const [recipeCode, setRecipeCode] = useState<number>()
    const [recipeData, setRecipeData] = useState<IRecipeResponseDTO>()
    const [maneuverFields, setManeuverFields] = useState<IManeuverFields[]>([RAW_DATA])
    const [reloadRecipeList, setReloadRecipeList] = useState<number>()
    const [recipeDrawerVisible, setRecipeDrawerVisible] = useState<boolean>(false)

    const [formValidator, setFormValidator] = useState<ManeuverFormValidator>(new ManeuverFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    useEffect(() => {
        if (!props.recipeCode || !props.show)
            return

        formStateManager.changeFieldValue('recipeCode', props.recipeCode)
        setRecipeCode(props.recipeCode)
    }, [props.recipeCode, props.show])

    const request = useRequest<ISearchManeuverResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const getDetailsReq = useRequest<IManauverItemResponseDTO>()
    useEffect(onGetDetailsReqChange, [getDetailsReq.awaiting])

    function getDetails(): void {

        if (!!props.maneuverCode && props.show)
            getDetailsReq.runRequest(ManeuverRequests.get(props.maneuverCode))
    }

    useEffect(getDetails, [props.maneuverCode, props.show])

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return

        const fields = maneuverFields.filter(mf => !!mf.startDate && !!mf.estimatedEndDate && (!!mf.propertyCode || !!mf.fieldCode)) as ISaveManeuverFieldsReqDTO[]

        const dto: ISaveManeuverRequestDTO = {
            recipeCode: formValues.recipeCode,
            season: formValues.season,
            maneuverFields: fields.map(fie => ({
                ...fie,
                startDate: DateUtils.getDateFormattedToApi(fie.startDate),
                estimatedEndDate: DateUtils.getDateFormattedToApi(fie.estimatedEndDate),
            }))
        }

        request.runRequest(props.maneuverCode ?
            ManeuverRequests.update(props.maneuverCode, dto) :
            ManeuverRequests.save(dto))
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar atividade agrícola!')
        }

        NotificationHelper.success('Item salvo com sucesso!')
        formStateManager.reset()
        const fields = maneuverFields.filter(mf => !!mf.startDate && !!mf.estimatedEndDate && (!!mf.propertyCode || !!mf.fieldCode)) as ISaveManeuverFieldsReqDTO[]

        if (fields.length <= 1) {
            history.push(`${ManeuverRoutes.LOGBOOK_WITHOUT_PARAM}/${request.returnData.code}`)
            props.onSuccess()
        } else
            props.onSuccess(recipeData)

    }

    function onGetDetailsReqChange(): void {
        if (getDetailsReq.awaiting || !getDetailsReq.tried)
            return

        if (!getDetailsReq.success || !getDetailsReq.returnData) {
            if (getDetailsReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getDetailsReq.returnData, getDetailsReq.error)
            return RequestUtils.showDefaultErrorNotification(getDetailsReq.error, 'Ocorreu um erro ao tentar buscar os dados da atividade. Tente novamente.')
        }

        const editItem: ISaveManeuverRequestDTO = {
            recipeCode: getDetailsReq.returnData.recipeCode,
            season: getDetailsReq.returnData.season,
            maneuverFields: [{
                fieldCode: getDetailsReq.returnData.fieldCode,
                propertyCode: getDetailsReq.returnData.property.code,
                startDate: DateUtils.getFormatted(getDetailsReq.returnData.startDate, DateFormatEnum.BR_WITHOUT_TIME),
                estimatedEndDate: DateUtils.getFormatted(getDetailsReq.returnData.estimatedEndDate, DateFormatEnum.BR_WITHOUT_TIME),
                priority: getDetailsReq.returnData.priority
            }]
        }

        setFormValidator(new ManeuverFormValidator(editItem))
        setManeuverFields(editItem.maneuverFields)

    }

    function onChangeManeuverField(data: IManeuverFields, index: number): void {
        setManeuverFields(maneuverFields.map((item, i) => {
            if (i === index) {
                return {
                    startDate: data.startDate,
                    estimatedEndDate: data.estimatedEndDate,
                    fieldCode: data.fieldCode,
                    propertyCode: data.propertyCode,
                    priority: data.priority
                }
            }
            return item
        }))
    }

    function addManeuverField(): void {
        setManeuverFields([...maneuverFields, RAW_DATA])
    }

    function removeManeuverField(index: number): void {
        setManeuverFields(maneuverFields.filter((data, i) => i !== index))
    }

    return (
        <>
            <DrawerCP
                title={`${props.maneuverCode ? 'Editar' : 'Nova'} atividade agrícola`}
                visible={props.show}
                width={'60%'}
                onClose={() => {
                    props.onCancel()
                    setManeuverFields([])
                    setRecipeCode(undefined)
                    formStateManager.reset()
                }}
                footer={
                    <ButtonWrapperSCP>
                        <ButtonCP
                            type={'primary'}
                            onClick={onFormSubmit}
                            loading={request.awaiting}
                            style={{ width: '100%' }}
                            wrapperStyle={{ width: '100%' }}
                        >
                            Salvar
                        </ButtonCP>
                    </ButtonWrapperSCP>

                }
            >
                <RecipeCardCP
                    recipeCode={recipeCode}
                    fieldCodes={_.compact(maneuverFields.map(mfie => mfie.fieldCode))}
                    setRecipe={setRecipeData}
                />
                <FormWrapperSCP>
                    <RecipeWrapperSCP>
                        <RecipeSelectorCP
                            label={'Selecione a instrução'}
                            formStateManager={formStateManager}
                            onSelect={setRecipeCode}
                            reload={reloadRecipeList}
                            type={props.recipeType}
                        />
                        <ButtonCP
                            type={'primary'}
                            style={{ width: '100%', justifyContent: 'center' }}
                            onClick={() => setRecipeDrawerVisible(true)}
                        >
                            Criar nova
                        </ButtonCP>

                    </RecipeWrapperSCP>
                    <SeasonSelectorCP
                        formStateManager={formStateManager}
                        label={'Safra'}
                    />
                    <DividerCP text={'Propriedade/Talhão'} orientation={'left'} />
                    {
                        maneuverFields.map((data, index) => (
                            <ManFieldWppSCP key={data.fieldCode}>
                                <ManeuverFieldsICP
                                    data={data}
                                    setData={(_data) => onChangeManeuverField(_data, index)}
                                />
                                <RemoveSCP>
                                    <ButtonCP
                                        onClick={() => removeManeuverField(index)}
                                        type={'primary'}
                                        size={'small'}
                                        disabled={index === 0}
                                        icon={<FontAwesomeIcon icon={faTrash} />}
                                    />
                                </RemoveSCP>

                            </ManFieldWppSCP>

                        ))
                    }
                    <ButtonCP
                        onClick={addManeuverField}
                        size={'small'}
                        icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />}
                        type={'ghost'}
                        disabled={!!props.maneuverCode}
                        style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
                    >
                        Adicionar propriedade/talhão
                    </ButtonCP>

                </FormWrapperSCP>
            </DrawerCP>
            <RecipeDrawerCP
                show={recipeDrawerVisible}
                onSuccess={(code) => {
                    setReloadRecipeList(Date.now())
                    setRecipeCode(code)
                    formStateManager.changeFieldValue('recipeCode', code)
                    setRecipeDrawerVisible(false)
                }}
                onCancel={() => {
                    setRecipeDrawerVisible(false)
                }}
            />
        </>
    )
}

const FormWrapperSCP = styled.div`

`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    width: 100%;
`
const RecipeWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 10px;
    margin-bottom: 10px;
   
    .ant-btn {
        margin-bottom: .5em;
        width: 100%;
    }
`

const ManFieldWppSCP = styled.div`
    padding: 20px 0;
    border-bottom: 1px solid ${props => props.theme.primaryColor};
    
`
const RemoveSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    
`