import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { TermsOfUseDrawerCP } from 'common/components/terms-of-use/TermsOfUseDrawerCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { LoggedUserDataTP } from 'common/redux/ReduxTypes'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { AppRoutes } from 'modules/app/AppRoutes'
import { AuthActions } from 'modules/auth/AuthActions'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { ContentUserLoginCP } from 'modules/auth/components/content-user-login/ContentUserLoginCP'
import { SiderAuthCP } from 'modules/auth/components/sider-auth/SiderAuthCP'
import { IUserLoginResponseDTO } from 'modules/auth/services/dtos/responses/IUserLoginResponseDTO'
import { SchemaActions } from 'modules/company/actions/SchemaActions'
import { CompanyRequests } from 'modules/company/services/CompanyRequests'
import { ICompanyResDTO } from 'modules/company/services/dtos/response/ICompanyResDTO'
import { RegistrationRoutes } from 'modules/registration/RegistrationRoutes'
import { UserRequests } from 'modules/user/services/UserRequests'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import styled from 'styled-components'

const CLASS_MAIN_LAYOUT = 'layout_main'
type RouteParamsTP = { domain?: string }
interface ICompanyLoginScreenProps {
    history: any

}
export function CompanyLoginScreen(props: ICompanyLoginScreenProps): JSX.Element {
    const currentUserData: LoggedUserDataTP = useSelector((state: IReduxState) => _.get(state, 'loggedUser'))
    const loggedUser = _.get(currentUserData, 'user')
    const screenSize = useScreenSize()

    const [mainInfoDrawerVisible, setMainInfoDrawerVisible] = useState<boolean>(false)

    const request = useRequest<any>()
    useEffect(onTermsReqChange, [request.awaiting])

    function onAcceptTerms(): void {
        request.runRequest(UserRequests.acceptTerms(loggedUser.id))
    }

    function onTermsReqChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar termos de uso!')

        setMainInfoDrawerVisible(false)
        props.history.push(AppRoutes.ROOT)
    }

    function goToLoggedScreen(user: IUserLoginResponseDTO): void {

        setMainInfoDrawerVisible(true)
    }

    function onCloseModal(): void {
        setMainInfoDrawerVisible(false)
        AuthActions.logout()
    }

    if (!_.isEmpty(loggedUser) && !mainInfoDrawerVisible)
        return <Redirect to={AppRoutes.ROOT} />

    return (
        <WrapperSCP>
            <LayoutCP
                mainLayout={true}
                sider={screenSize.smd ? undefined : <SiderAuthCP />}
                content={
                    <ContentUserLoginCP
                        history={props.history}
                        authUser={loggedUser}
                        goToLoggedScreen={(user) => goToLoggedScreen(user)}
                        goToRegister={() => props.history.push(RegistrationRoutes.ROOT)}
                    />
                }
            />
            <TermsOfUseDrawerCP
                onClose={onCloseModal}
                loading={request.awaiting}
                show={mainInfoDrawerVisible}
                onAccept={onAcceptTerms}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
.${CLASS_MAIN_LAYOUT} {
        > .ant-layout {              
            background: #fff;
        }
        
    }
@media(max-width: 768px){
    .${CLASS_MAIN_LAYOUT} {
        > .ant-layout {              
            background: ${props => props.theme.primaryColor};
        }
        
    }
}
`