import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/RequestManagerTypes'
import { SystemConfig } from 'config/SystemConfig'
import { IFinishProcessRequestDTO } from 'modules/batch/services/dtos/requests/IFinishProcessRequestDTO'
import { IProcessAlbumRequestDTO } from 'modules/batch/services/dtos/requests/IProcessAlbumRequestDTO'
import { ISaveBatchProcessLogbookReqDTO } from 'modules/batch/services/dtos/requests/ISaveBatchProcessLogbookReqDTO'
import { ISaveBatchProcessReqDTO } from 'modules/batch/services/dtos/requests/ISaveBatchProcessReqDTO'
import { ISaveBatchSaleReqDTO } from 'modules/batch/services/dtos/requests/ISaveBatchSaleReqDTO'
import { ISaveMultipleBatchesReqDTO } from 'modules/batch/services/dtos/requests/ISaveMultipleBatchesReqDTO'
import { IUpdateBatchRequestDTO } from 'modules/batch/services/dtos/requests/IUpdateBatchRequestDTO'
import { IMergeBatchesRequestDTO } from './dtos/requests/IMergeBatchesRequestDTO'
import { ISaveBatchRequestDTO } from './dtos/requests/ISaveBatchRequestDTO'
import { ISearchBatchRequestDTO } from './dtos/requests/ISearchBatchRequestDTO'
import { ISplitBatchRequestDTO } from './dtos/requests/ISplitBatchRequestDTO'

export class BatchRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/batches`
    private static readonly baseNewApiUrl = `${SystemConfig.getInstance().oldApiBaseUrl}/property-batch`
    private constructor() { }

    static search = (dto: ISearchBatchRequestDTO): RequestConfigTP<ISearchBatchRequestDTO> => ({
        url: `${BatchRequests.baseApiUrl}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static save = (dto: ISaveBatchRequestDTO): RequestConfigTP<ISaveBatchRequestDTO> => ({
        url: `${BatchRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static saveMultiple = (dto: ISaveMultipleBatchesReqDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static getProcess = (batchCode: number, code: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${code}`,
        method: HttpMethodEnum.GET,
    })

    static saveProcess = (batchCode: number, dto: ISaveBatchProcessReqDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updateProcess = (batchCode: number, code: number, dto: ISaveBatchProcessReqDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static deleteProcess = (batchCode: number, code: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static markAsConcluded = (batchCode: number, code: number, dto: IFinishProcessRequestDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${code}/finish`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static update = (code: number, dto: IUpdateBatchRequestDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static get = (code: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static getAnalyses = (code: number): RequestConfigTP => ({
        url: `${BatchRequests.baseNewApiUrl}/${code}/analysis`,
        method: HttpMethodEnum.GET,
    })

    static getOriginData = (code: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}/origin`,
        method: HttpMethodEnum.GET,
    })

    static getProcesses = (code: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}/processes`,
        method: HttpMethodEnum.GET,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static splitBatch = (code: number, dto: ISplitBatchRequestDTO): RequestConfigTP<ISplitBatchRequestDTO> => ({
        url: `${BatchRequests.baseApiUrl}/${code}/split`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static mergeBatches = (dto: IMergeBatchesRequestDTO): RequestConfigTP<IMergeBatchesRequestDTO> => ({
        url: `${BatchRequests.baseApiUrl}/merge`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static undoSplit = (code: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}/undo-split`,
        method: HttpMethodEnum.PUT,
    })

    static undoMerge = (code: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}/undo-merge`,
        method: HttpMethodEnum.PUT,
    })

    static updateTree = (batchCode: number, date: string): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/update-tree`,
        method: HttpMethodEnum.PUT,
        params: { date },
    })

    static searchProcessAlbum = (batchCode: number, processCode: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/album`,
        method: HttpMethodEnum.GET,
    })

    static saveProcessAlbum = (batchCode: number, processCode: number, dto: IProcessAlbumRequestDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/album`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static searchBatchPhotos = (batchCode: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/album`,
        method: HttpMethodEnum.GET,
    })

    static findProcessAlbum = (batchCode: number, processCode: number, albumCode: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/album/${albumCode}`,
        method: HttpMethodEnum.GET,
    })

    static deleteProcessAlbum = (batchCode: number, processCode: number, albumCode: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/album/${albumCode}`,
        method: HttpMethodEnum.DELETE,
    })

    static removePicturesFromAlbum = (batchCode: number, processCode: number, albumCode: number, dto: { pictures: string[] }): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/album/${albumCode}/pictures`,
        method: HttpMethodEnum.DELETE,
        params: dto,
    })

    static updateProcessAlbum = (batchCode: number, processCode: number, albumCode: number, dto: IProcessAlbumRequestDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/album/${albumCode}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static uploadImagesToAlbum = (batchCode: number, processCode: number, albumCode: number, data: FormData): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/album/${albumCode}/pictures`,
        method: HttpMethodEnum.PUT,
        params: data,
        headers: { 'content-type': 'multipart/form-data' }
    })

    static searchProcessLogbook = (batchCode: number, processCode: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/logbook`,
        method: HttpMethodEnum.GET,
    })

    static saveProcessLogbook = (batchCode: number, processCode: number, dto: ISaveBatchProcessLogbookReqDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/logbook`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updateProcessLogbook = (batchCode: number, processCode: number, processLogbookCode: number, dto: ISaveBatchProcessLogbookReqDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/logbook/${processLogbookCode}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static findProcessLogbook = (batchCode: number, processCode: number, processLogbookCode: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/logbook/${processLogbookCode}`,
        method: HttpMethodEnum.GET,
    })

    static deleteProcessLogbook = (batchCode: number, processCode: number, processLogbookCode: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${batchCode}/processes/${processCode}/logbook/${processLogbookCode}`,
        method: HttpMethodEnum.DELETE,
    })

    static searchSales = (code: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}/sales`,
        method: HttpMethodEnum.GET,
    })

    static saveSale = (code: number, dto: ISaveBatchSaleReqDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}/sales/`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static getSale = (code: number, saleCode: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}/sales/${saleCode}`,
        method: HttpMethodEnum.GET,
    })

    static updateSale = (code: number, saleCode: number, dto: ISaveBatchSaleReqDTO): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}/sales/${saleCode}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static deleteSale = (code: number, saleCode: number): RequestConfigTP => ({
        url: `${BatchRequests.baseApiUrl}/${code}/sales/${saleCode}`,
        method: HttpMethodEnum.DELETE,
    })

}
