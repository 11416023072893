export enum PhysicalDefectEnum {
    BLACK_BEAN = 'BLACK_BEAN',
    BIG_STONE = 'BIG_STONE',
    MEDIUM_STONE = 'MEDIUM_STONE',
    SMALL_STONE = 'SMALL_STONE',
    BIG_STICK = 'BIG_STICK',
    MEDIUM_STICK = 'MEDIUM_STICK',
    SMALL_STICK = 'SMALL_STICK',
    COCONUT = 'COCONUT',
    BIG_BARK = 'BIG_BARK',
    SMALL_BARK = 'SMALL_BARK',
    FLAMED = 'FLAMED',
    SAILOR = 'SAILOR',
    DRILL = 'DRILL',
    LACY_DRILL = 'LACY_DRILL',
    SHELL = 'SHELL',
    GREEN = 'GREEN',
    BROKEN = 'BROKEN',
    HOLLOW = 'HOLLOW',
}
