import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import React, { useState } from 'react'
import styled from 'styled-components'

import { ProductionReportDataTP } from 'modules/report/types/ProductionReportDataTP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ReportRoutes } from 'modules/report/ReportRoutes'
import { useHistory } from 'react-router-dom'
import { MobileListItemICP } from 'common/components/list/inner/MobileListItemICP'

interface IProductionReportListItemICPProps {
    reportData: ProductionReportDataTP
    loading?: boolean
    key: number
}

export function ProductionReportListItemICP(props: IProductionReportListItemICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const history = useHistory()

    if (screenSize.smd) {
        return (
            <MobileListItemICP
                loading={!!props.loading}
                key={`${props.key}`}
                data={[
                    { label: 'Talhão', value: props.reportData.item ?? '-' },
                    { label: 'Área de produção', value: `${MaskUtils.applyNumberMask(props.reportData.usedArea ?? 0)} ha` },
                    { label: 'Total de litros', value: `${MaskUtils.applyNumberMask(props.reportData.totalLiters ?? 0)} L` },
                    { label: 'Total de sacas', value: MaskUtils.applyNumberMask(props.reportData.totalBags ?? 0) },
                    { label: 'Litros/pé', value: `${MaskUtils.applyNumberMask(props.reportData.litersPerTree ?? 0)} L` },
                    { label: 'Sacas/hectare', value: `${MaskUtils.applyNumberMask(props.reportData.bagsPerHectare ?? 0)}` },
                ]}
                actions={[
                    {
                        action: () => history.push(`${ReportRoutes.FIELD_WITHOUT_PARAM}/${props.reportData.itemCode}`),
                        icon: <FontAwsomeIconCP icon={faEye} />,
                        label: 'Detalhes'
                    }
                ]}

            />
        )
    }

    return (
        <ListItemICP
            key={props.key}
            isMobile={screenSize.smd}
            loading={props.loading}
            style={{ padding: '0', margin: '0' }}
            customSkeleton={<GenericRowSkeletonICP rows={7} columns={7} />}
        >
            <WrapperSCP>
                <MobileLabelsSCP>
                    <TextCP text={'Talhão:'} />
                    <TextCP text={'Área de produção:'} />
                    <TextCP text={'Total de litros:'} />
                    <TextCP text={'Total de sacas:'} />
                    <TextCP text={'Litros/pé:'} />
                    <TextCP text={'Sacas/hectare:'} />
                </MobileLabelsSCP>
                <RowSCP>
                    <ValuesSCP>
                        <TextCP text={props.reportData.item ?? '-'} />
                        <TextCP text={`${MaskUtils.applyNumberMask(props.reportData.usedArea ?? 0)} ha`} />
                        <TextCP text={`${MaskUtils.applyNumberMask(props.reportData.totalLiters ?? 0)} L`} />
                        <TextCP text={MaskUtils.applyNumberMask(props.reportData.totalBags ?? 0)} />
                        <TextCP text={`${MaskUtils.applyNumberMask(props.reportData.litersPerTree ?? 0)} L`} />
                        <TextCP text={`${MaskUtils.applyNumberMask(props.reportData.bagsPerHectare ?? 0)}`} />
                    </ValuesSCP>
                    <ButtonWrapperSCP>
                        <ButtonCP
                            wrapperStyle={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                cursor: 'pointer',
                            }}
                            icon={<FontAwsomeIconCP icon={faEye} />}
                            onClick={() => history.push(`${ReportRoutes.FIELD_WITHOUT_PARAM}/${props.reportData.itemCode}`)}
                            type={'primary'}
                        />
                    </ButtonWrapperSCP>
                </RowSCP>
            </WrapperSCP>
        </ListItemICP>
    )
}

const WrapperSCP = styled.div`   
    position:relative;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    column-gap: 10px;
    margin: 10px;
    @media(max-width: 768px){
        grid-template-columns: .5fr 1fr;
          display: grid;
          padding-bottom: 45px;
          margin-bottom: 2em;
          border-bottom: 1px solid ${props => props.theme.lightGray};
        .ant-btn{
            position: absolute;
            bottom: 12px;
            width: 100%;
        }
    }
`
const MobileLabelsSCP = styled.div`
    display: none;
    .ant-typography{
        font-weight: 600;
    }
    @media(max-width: 768px){
        display: grid;
        padding: 10px 0;
        grid-template-columns: 1fr;
        row-gap: 5px;   
        width: 100%;
    }
`
const ValuesSCP = styled.div`
    display: grid;
    width: 90%;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
    margin: 0;
    padding: 15px 0;
    word-break: break-all;
    align-items: center;
    .ant-list-bordered.ant-list-lg .ant-list-item{
        padding: 0!important;
    }

    .ant-typography {
        font-size: 12px;
    }

    @media(max-width: 768px){
        padding: 0;
        grid-template-columns: 1fr;
         row-gap: 10px;

    }
`

const RowSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    width: 10%;
    place-content: space-evenly;

    .ant-btn {
        height: 35px;

        span svg {
            width: auto;
            margin: 0px;
        }
    }
`