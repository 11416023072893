import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'
import { RecipeUtils } from 'modules/maneuver/utils/RecipeUtils'
import React from 'react'

interface IRecipeTypeSelectorCPProps {
    label?: string
    value?: RecipeTypeEnum
    onSelect?: (value: RecipeTypeEnum) => void
    required?: boolean
}

export function RecipeTypeSelectorCP(props: IRecipeTypeSelectorCPProps): JSX.Element {

    function getOptions(): SelectOptionTP[] {
        const options: SelectOptionTP[] = Object.values(RecipeTypeEnum).map(e => ({ label: RecipeUtils.translateType(e), value: e }))
        options.unshift({ label: 'Todos', value: undefined })

        return options
    }

    return (
        <SelectCP
            showSearch={true}
            options={getOptions()}
            label={props.label}
            placeholder={'Tipo'}
            required={props.required}
            onChange={props.onSelect}
            value={props.value}
        />
    )
}