import { TextCP } from 'common/components/text/TextCP'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { StoragePlaceEnum } from 'modules/batch/enums/StoragePlaceEnum'
import { StorageTypeEnum } from 'modules/batch/enums/StorageTypeEnum'
import { IStorageResDTO } from 'modules/batch/services/dtos/responses/IStorageResDTO'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IStorageDetailsContentCPProps {
    storage: IStorageResDTO
    language?: LanguageEnum
}

const LABELS_PT = {
    type: 'Tipo',
    place: 'Local',
    placeName: 'Nome do local:',
    humidity: 'Umidade',
    storageType: 'Tipo de armazenamento',
    other: 'Outro'
}

const LABELS_EN = {
    type: 'Type',
    place: 'Place',
    placeName: 'Place name',
    humidity: 'Umidity',
    storageType: 'Storage type',
    other: 'Other'
}

export function StorageDetailsContentCP(props: IStorageDetailsContentCPProps): JSX.Element {
    const [labels, setLabels] = useState(LABELS_PT)
    const [storagePlaceTranslateFunc, setStoragePlaceTranslateFunc] = useState<(place: StoragePlaceEnum) => string>(() => BatchUtils.translateStoragePlace)
    const [typeTranslateFunc, setTypeTranslateFunc] = useState<(type: StorageTypeEnum) => string>(() => BatchUtils.translateStorageType)

    useEffect(translateLabels, [props.language])

    function translateLabels(): void {
        if (props.language === LanguageEnum.EN) {
            setLabels(LABELS_EN)
            setStoragePlaceTranslateFunc(() => BatchUtils.translateStoragePlaceToEnglish)
            setTypeTranslateFunc(() => BatchUtils.translateStorageTypeToEnglish)
        }
    }

    return (
        <ContentInfoSCP>
            <RowSCP>
                <TextCP text={`${labels.place}:\u00A0`} style={{ fontWeight: '500 ' }} />
                <TextCP text={`${storagePlaceTranslateFunc(props.storage.place)}`} />
            </RowSCP>
            {
                !!props.storage.placeName &&
                <RowSCP>
                    <TextCP text={`${labels.placeName}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.storage.placeName}`} />
                </RowSCP>
            }

            <RowSCP>
                <TextCP text={`${labels.storageType}:\u00A0`} style={{ fontWeight: '500 ' }} />
                <TextCP text={`${typeTranslateFunc(props.storage.type)}`} />
            </RowSCP>
            {
                !!props.storage.typeOther &&
                <RowSCP>
                    <TextCP text={`${labels.other}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.storage.typeOther}`} />
                </RowSCP>
            }
            {
                !!props.storage.humidity &&
                <RowSCP>
                    <TextCP text={`${labels.humidity}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.storage.humidity}`} />
                </RowSCP>
            }
        </ContentInfoSCP>
    )
}

const WrapperSCP = styled.div`
`

const ContentInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography{
        font-size: 12px;
    }
    
`