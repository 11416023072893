import { Divider } from 'antd'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface IDividerCPProps {
    text?: string
    type?: 'horizontal' | 'vertical'
    orientation?: 'left' | 'right' | 'center'
    dashed?: boolean
    style?: CSSProperties
    plain?: boolean
}

/**
 * COMPONENTE divisor com texto
 */
export function DividerCP(props: IDividerCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <Divider
                type={props.type}
                orientation={props.orientation}
                dashed={props.dashed}
                style={props.style}
                plain={props.plain}
            >
                {props.text}
            </Divider>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    .ant-divider-inner-text {   
        color: ${props => props.theme.black};
        font-weight: 500;
        font-size: 12px;
    }
    margin: 25px 0;
`
