
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { SensoryAnalisysRadarCP } from 'modules/analysis/components/sensory-radar/SensoryAnalisysRadarCP'
import { ISensoryAnalysisResDTO } from 'modules/analysis/services/dtos/responses/ISensoryAnalysisResDTO'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { CoffeeFeaturesICP } from 'modules/batch-presentation/components/analysis-carrousel/inner/CoffeeFeaturesICP'
import { UserCardInfoICP } from 'modules/batch-presentation/components/analysis-carrousel/inner/UserCardInfoICP'
import { IAnalysisResumeResDTO } from 'modules/batch-presentation/services/dtos/response/IAnalysisResumeResDTO'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

interface IAnalysisContentICPProps {
    analysis: IAnalysisResumeResDTO
    batchCode: number
}

export function AnalysisContentICP(props: IAnalysisContentICPProps): JSX.Element {
    const currentLang = useSelector((state: IReduxState) => state.language)
    const lang: LanguageEnum = currentLang === 'pt' ? LanguageEnum.PT : LanguageEnum.EN
    const data: ISensoryAnalysisResDTO = {
        batchCode: props.batchCode,
        bodyValue: props.analysis.body,
        createDate: '',
        ...props.analysis
    }

    return (

        <MainWppSCP>
            <WrapperSCP>
                <UserCardInfoICP
                    userName={props.analysis.userName}
                    score={props.analysis.score}
                />
                <CoffeeFeaturesICP
                    brewMethods={props.analysis.brewMethods}
                    brewMethodsOther={props.analysis.brewMethodOther}
                    aromas={props.analysis.aromaOptions}
                    aromasOther={props.analysis.aromaOther}
                    flavors={props.analysis.flavorOptions}
                    flavorsOther={props.analysis.flavorOther}
                />
                <RadarWppSCP>
                    <SensoryAnalisysRadarCP
                        item={data as any}
                        language={lang}
                    />
                </RadarWppSCP>

            </WrapperSCP>

        </MainWppSCP>

    )
}

const WrapperSCP = styled.div`
    border: 1px solid ${props => props.theme.primaryColor};
    border-radius: 50px;
    padding: 0 3em;
    margin: 0 3em;
    @media(max-width: 768px){
        padding: 0;
        margin: 0 1em;
    }
`
const RadarWppSCP = styled.div`
    margin-bottom: 4em;
`
const MainWppSCP = styled.div`
    position: relative;
    min-height: 105vh;
    @media(max-width: 768px){
        min-height: 139vh;
    }
`