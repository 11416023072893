import React from 'react'

export function PlantIcon(props: any): JSX.Element {
    return (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
            <svg {...props} xmlns={'http://www.w3.org/2000/svg'} width={'25'} height={'25'} viewBox={'0 0 10 12.32'}>
                <g transform={'translate(-0.5)'}>
                    <path d={'M86.774,435a4.094,4.094,0,0,0-5.79,0l-.066.067a.361.361,0,0,0,.255.616h5.412a.361.361,0,0,0,.255-.616Zm0,0'} transform={'translate(-78.379 -423.363)'} />
                    <path d={'M10.139,1.546H8.592A1.909,1.909,0,0,0,6.685,3.453h0a1.9,1.9,0,0,0,.432,1.207L5.861,6.168V5.341a3.083,3.083,0,0,0-.636-1.87l-.231-.3a1.9,1.9,0,0,0,.48-1.263h0A1.909,1.909,0,0,0,3.567,0H2.021A.361.361,0,0,0,1.66.361V1.908a1.906,1.906,0,0,0,2.763,1.7l.23.3a2.852,2.852,0,0,1,.486,1.43V9.024L3.883,7.516A1.9,1.9,0,0,0,4.315,6.31h0A1.909,1.909,0,0,0,2.408,4.4H.861A.361.361,0,0,0,.5,4.763V6.31A1.906,1.906,0,0,0,3.329,7.979L4.817,9.764A4.875,4.875,0,0,1,5.5,9.716q.182,0,.361.013V7.368L7.671,5.123A1.906,1.906,0,0,0,10.5,3.454V1.907A.361.361,0,0,0,10.139,1.546Zm0,0'} />
                </g>
            </svg>
        </span>
    )
}