import { SliderCarouselCP } from 'common/components/slider-carousel/SliderCarouselCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { Image } from 'antd'
import { BatchPresentationRequests } from 'modules/batch-presentation/services/BatchPresentationRequests'
import { IProducerResumeRedDTO } from 'modules/batch-presentation/services/dtos/response/IProducerResumeRedDTO'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ProducerContentICP } from 'modules/batch-presentation/components/producer-carrousel/inner/ProducerContentICP'
import { LoadingBoxCP } from 'modules/batch-presentation/components/loading-box/LoadingBoxCP'
import { TextCP } from 'common/components/text/TextCP'
type RouteParamsTP = { batchCode: string }
interface IProducerCarrouselCPProps {
}

export function ProducerCarrouselCP(props: IProducerCarrouselCPProps): JSX.Element {
    const routeParams = useParams<RouteParamsTP>()
    const [resume, setResume] = useState<IProducerResumeRedDTO[]>()
    const [allPictures, setAllPictures] = useState<string[]>([])

    const [batchCode, setBatchCode] = useState<number>()
    useEffect(() => setBatchCode(+routeParams.batchCode), [routeParams])

    const getInfoReq = useRequest<IProducerResumeRedDTO[]>()
    useEffect(onRequestChange, [getInfoReq.awaiting])

    useEffect(getDetails, [batchCode])

    function getDetails(): void {
        if (!!batchCode)
            getInfoReq.runRequest(BatchPresentationRequests.getProducerResume(batchCode))
    }

    function onRequestChange(): void {

        if (getInfoReq.awaiting || !getInfoReq.tried)
            return

        if (!getInfoReq.success && !getInfoReq.returnData) {
            if (getInfoReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getInfoReq.returnData, getInfoReq.error)
            return RequestUtils.showDefaultErrorNotification(getInfoReq.error, 'Some error occurred. Please, try to reload the page.')
        }

        setResume(getInfoReq.returnData)
        if (!!getInfoReq.returnData && !!getInfoReq.returnData.length)
            setAllPictures(_.flatten(getInfoReq.returnData.map(item => item.pictures)))
    }

    if (!resume || getInfoReq.awaiting) {
        return (
            <div></div>
        )
    }

    return (

        <WrapperSCP>
            <PicturesSliderSCP>
                <TextCP
                    text={'knowProducer'}
                    translationFiles={'batch'}
                    translationNameSpace={'batch'}
                    style={{ fontSize: '23px', fontWeight: 600, marginBottom: '1em', marginLeft: '3em' }}
                />
                <SliderCarouselCP
                    slidesToShow={3}
                    dots={true}
                    infinite={true}
                    speed={500}
                    centerMode={true}
                    draggable={true}
                >
                    {
                        allPictures.map(item => (
                            <BlockSCP key={item}>
                                <ImageSCP style={{ backgroundImage: `url(${item})` }} />
                            </BlockSCP>
                        ))
                    }
                </SliderCarouselCP>
            </PicturesSliderSCP>

            {/* <DescSliderSCP>
                <SliderCarouselCP
                    slidesToShow={1}
                    slidesToScroll={1}
                    dots={true}
                    infinite={true}
                    speed={500}
                    draggable={true}
                >
                    {
                        resume.map(item => (<ProducerContentICP key={item.code} producer={item} />))
                    }
                </SliderCarouselCP>

            </DescSliderSCP> */}

        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    background: white;
`
const PicturesSliderSCP = styled.div`
    
`
const DescSliderSCP = styled.div`
`

const ImageSCP = styled.div`
   width: 453px;
    height: 398px;
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
`

const BlockSCP = styled.div`
    padding: 10px;
`