import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IProductResponseDTO } from 'modules/maneuver/services/dtos/responses/IProductResponseDTO'
import { ProductRequests } from 'modules/maneuver/services/ProductRequests'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import React, { useEffect, useState } from 'react'
import { ProductTypeEnum } from 'modules/maneuver/enums/ProductTypeEnum'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'

interface IProductSelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    label?: string
    placeholder?: string
    fieldName?: string
    types?: ProductTypeEnum[]
    value?: number
    onSelect?: (value: number, unitOfMeasurement?: UnitOfMeasurementEnum, remainingQuantity?: number) => void
    required?: boolean
    loading?: boolean
}

export function ProductSelectorCP<FModelTP extends FormModelValidator = any>(props: IProductSelectorCPProps<FModelTP>): JSX.Element {
    const screenSize = useScreenSize()
    const [productOptions, setProductOptions] = useState<SelectOptionTP[]>([])
    const [products, setProducts] = useState<IProductResponseDTO[]>([])
    useEffect(getProductList, [])

    const request = useRequest<IGenericListResponseDTO<IProductResponseDTO>>(ProductRequests.getProductList({ types: props.types }))
    useEffect(onRequestChange, [request.awaiting])

    function getProductList(): void {
        request.runRequest()
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar itens!')
        }

        const options = request.returnData.list.map(product => ({ label: product.name, value: product.code }))
        setProductOptions(options)
        setProducts(request.returnData.list)
    }

    function getUnitOfMeasurement(code: number): UnitOfMeasurementEnum | undefined {
        return products.find(p => p.code === code)?.unitOfMeasurement
    }

    function getRemainingQtd(code: number): number | undefined {
        return products.find(p => +p.code === +code)?.remainingQuantity
    }

    return (
        <SelectCP
            options={productOptions}
            label={props.label}
            placeholder={props.placeholder ?? props.label}
            fieldName={props.fieldName ?? 'productCode'}
            formStateManager={props.formStateManager}
            required={props.required}
            onChange={(value: number) => props.onSelect?.(value, getUnitOfMeasurement(value), getRemainingQtd(value))}
            value={props.value}
            disabled={request.awaiting}
            loading={props.loading}
            fontSize={screenSize.smd ? 'large' : 'normal'}
        />
    )
}