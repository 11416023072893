import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'

import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { HarvestLogbookHeader } from 'modules/harvest-planning/components/harvest-logbook-header/HarvestLogbookHeader'
import { ISearchHarvestPlanningResDTO } from 'modules/harvest-planning/services/dtos/response/ISearchHarvestPlanningResDTO'

import { LogbookEntriesListCP } from 'modules/logbook/components/logbook-entries-list/LogbookEntriesListCP'
import { ILogbookResponseDTO } from 'modules/harvest-planning/services/dtos/response/ILogbookResponseDTO'
import { LogbookEntryICP } from 'modules/logbook/components/logbook-entries-list/inner/LogbookEntryICP'
import { TextCP } from 'common/components/text/TextCP'
import { HarvestUtils } from 'modules/harvest/utils/HarvestUtils'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faCamera, faPenAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { HarvestPlanningRequests } from 'modules/harvest-planning/services/HarvestPlanningRequests'
import { LogbookHeaderSkeletonCP } from 'modules/logbook/components/logbook-header-skeleton/LogbookHeaderSkeletonCP'
import { LitersPerMaturationCP } from 'modules/harvest-planning/components/liters-per-maturation/LitersPerMaturationCP'
import { NotificationHelper } from 'common/NotificationHelper'
import { LogbookRequests } from 'modules/logbook/services/LogbookRequests'
import { LogbookDetailsDrawerCP } from 'modules/logbook/components/logbook-details-drawer/LogbookDetailsDrawerCP'
import { LogbookDrawerCP } from 'modules/logbook/components/logbook-drawer/LogbookDrawerCP'
import { LogbookAlbumDrawerCP } from 'modules/harvest-planning/components/logbook-album-drawer/LogbookAlbumDrawerCP'
import { HarvestTypeEnum } from 'modules/harvest/enums/HarvestTypeEnum'

type RouteParamsTP = { code?: string }

interface HarvestLogbookContentCPProps {

}

export function HarvestLogbookContentCP(props: HarvestLogbookContentCPProps): JSX.Element {
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const routeParams = useParams<RouteParamsTP>()
    const [hvtPlanningCode, setHvtPlanningCode] = useState<number>()
    const [harvestItem, setHarvestItem] = useState<ISearchHarvestPlanningResDTO>()
    const [logbookList, setLogbookList] = useState<ILogbookResponseDTO[]>([])

    const [logbookFormVisible, setLogbookFormVisible] = useState<boolean>(false)
    const [logbookDetailsVisible, setLogbookDetailsVisible] = useState<boolean>(false)
    const [logbookAlbumVisible, setLogbookAlbumVisible] = useState<boolean>(false)

    const [logbookEntryCode, setLogbookEntryCode] = useState<number>()

    const request = useRequest<ISearchHarvestPlanningResDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const deleteReq = useRequest()
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    useEffect(() => {
        if (!!routeParams.code)
            setHvtPlanningCode(+routeParams.code)
    }, [routeParams])

    useEffect(getHvtPlanningDetails, [hvtPlanningCode])
    useEffect(onItemChange, [updateStatus])

    function getHvtPlanningDetails(): void {
        if (!!hvtPlanningCode)
            request.runRequest(HarvestPlanningRequests.get(hvtPlanningCode))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE)
            getHvtPlanningDetails()
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar detalhes!')
        }

        setHarvestItem(request.returnData)
        setLogbookList(_.orderBy(request.returnData?.logbookEntries, ['date', 'startTime'], ['desc', 'desc']))
        ListActions.updateIsDone()
    }

    function onDeleteReqChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success) {
            if (deleteReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteReq.returnData, deleteReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Erro ao remover registro!')
        }

        NotificationHelper.success('Registro removido com sucesso')
        getHvtPlanningDetails()
    }

    return (
        <WrapperSCP>

            <HeaderWrapperSCP>
                {
                    request.awaiting || !harvestItem ?
                        <LogbookHeaderSkeletonCP /> :
                        <HarvestLogbookHeader
                            harvestPlanningData={harvestItem}
                        />
                }
            </HeaderWrapperSCP>
            <LogbookEntriesListCP<ILogbookResponseDTO>
                source={logbookList}
                renderItem={(item: ILogbookResponseDTO) => (
                    <LogbookEntryICP
                        date={item.date}
                        peopleResponsible={item.peopleResponsible}
                        notes={item.notes}
                        isLastOne={!!logbookList.length && item.code === logbookList[logbookList.length - 1].code}
                        content={
                            <ExtraWrapperSCP>
                                {
                                    !!item.harvest &&

                                    <ContentInfoSCP>
                                        <RowSCP>
                                            <TextCP text={'Tipo:\u00A0'} style={{ fontWeight: '500 ' }} />
                                            <TextCP text={`${HarvestUtils.translateType(item.harvest.type)}`} />
                                        </RowSCP>
                                       
                                        <RowSCP>
                                            <TextCP text={'Quantidade colhida:\u00A0'} style={{ fontWeight: '500' }} />
                                            <TextCP text={`${item.harvest.liters} litros`} />
                                        </RowSCP>

                                        {
                                            !!item.equipment &&
                                            <RowSCP>
                                                <TextCP text={'Equipamento:\u00A0'} style={{ fontWeight: '500' }} />
                                                <TextCP text={`${item.equipment.brand}/${item.equipment.model}`} />
                                            </RowSCP>
                                        }
                                        {
                                            !!item.vehicle &&
                                            <RowSCP>
                                                <TextCP text={'Veículo:\u00A0'} style={{ fontWeight: '500' }} />
                                                <TextCP text={`${item.vehicle.brand}/${item.vehicle.model}`} />
                                            </RowSCP>
                                        }

                                    </ContentInfoSCP>
                                }
                                <>

                                    {
                                        !!item.harvest &&
                                        !!item.harvest.description &&
                                        <RowSCP>
                                            <TextCP text={'Descrição:\u00A0'} style={{ fontWeight: '500' }} />
                                            <TextCP text={`${item.harvest.description}`} />
                                        </RowSCP>
                                    }
                                    {
                                        !!item.harvest &&
                                        !!item.harvest.litersPerMaturation &&
                                        <LitersPerMaturationCP litersPerMaturation={item.harvest.litersPerMaturation} />
                                    }
                                </>

                            </ExtraWrapperSCP>
                        }
                        actions={
                            <ActionsWrapperSCP>
                                <MainSCP>
                                    <ButtonCP
                                        type={'primary'}
                                        onClick={() => {
                                            setLogbookEntryCode(item.code)
                                            setLogbookDetailsVisible(true)
                                        }}
                                        style={{ width: '100%', marginBottom: '10px' }}
                                    >
                                        Detalhes
                                    </ButtonCP>
                                </MainSCP>
                                <OtherActionsSCP>
                                    <ButtonCP
                                        type={'ghost'}
                                        confirmMsg={'Tem certeza que deseja remover este registro?'}
                                        onClick={() => deleteReq.runRequest(LogbookRequests.delete(item.code))}
                                        icon={<FontAwsomeIconCP icon={faTrashAlt} />}
                                        loading={deleteReq.awaiting}
                                    />
                                    <ButtonCP
                                        type={'primary'}
                                        onClick={() => {
                                            setLogbookEntryCode(item.code)
                                            setLogbookAlbumVisible(true)
                                        }}
                                        icon={<FontAwsomeIconCP icon={faCamera} />}
                                    />
                                    <ButtonCP
                                        type={'primary'}
                                        onClick={() => {
                                            setLogbookEntryCode(item.code)
                                            setLogbookFormVisible(true)
                                        }}
                                        icon={<FontAwsomeIconCP icon={faPenAlt} />}
                                    />

                                </OtherActionsSCP>
                            </ActionsWrapperSCP>
                        }
                    />
                )}
            />

            {
                !!logbookEntryCode && !!harvestItem &&
                <LogbookDrawerCP
                    show={logbookFormVisible}
                    logbookEntryCode={logbookEntryCode}
                    onCancel={() => setLogbookFormVisible(false)}
                    onSuccess={() => setLogbookFormVisible(false)}
                    harvestPlanningCode={harvestItem.code}
                    fieldCode={harvestItem.field.code}
                />
            }

            {
                !!logbookEntryCode &&
                <LogbookDetailsDrawerCP
                    show={logbookDetailsVisible}
                    logbookEntryCode={logbookEntryCode}
                    onCancel={() => setLogbookDetailsVisible(false)}
                />
            }
            {
                !!logbookEntryCode &&
                <LogbookAlbumDrawerCP
                    show={logbookAlbumVisible}
                    logbookEntryCode={logbookEntryCode}
                    onCancel={() => setLogbookAlbumVisible(false)}
                />
            }
        </WrapperSCP>
    )
}

const ActionsWrapperSCP = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    .ant-btn{
        width: 100%;
        justify-content: center;
    }
    @media(max-width: 768px){
        align-items: start;
         justify-content: center;
         .ant-btn {
            
            margin: 4px!important;
            width: 100%!;
        }
    }
`
const MainSCP = styled.div`
    width: 100%;
`

const OtherActionsSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;    
    @media(max-width: 768px){      
        width: 100%;
    }
`

const WrapperSCP = styled.div`
    border-radius: 10px;
    margin-bottom: 4em;
    max-height: 100vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
   
`

const HeaderWrapperSCP = styled.div`
    background: #fff;
    min-height: 100px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    @media(max-width: 768px){
        min-height: 62px;
        padding: 0 10px 7px 10px;
        margin-bottom: 10px;
    }
`

const ContentWrapperSCP = styled.div`
   
`

const ContentInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography{
        font-size: 12px;
    }
    
`
const ExtraWrapperSCP = styled.div`
  
    
`