/* eslint-disable react/jsx-props-no-spreading */
import { AppRoutes } from 'modules/app/AppRoutes'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import React from 'react'
import { Route, Switch } from 'react-router'
import { PageTitleCP } from 'common/components/page-title/PageTitleCP'
import { Error404Screen } from 'common/screens/Error404Screen'
import { LoginVerifierCP } from 'modules/app/components/login-verifier/LoginVerifierCP'
import { UserLoginScreen } from 'modules/auth/screens/UserLoginScreen'
import { BatchPresentationRoutes } from 'modules/batch-presentation/BatchPresentationRoutes'

export function PublicRouter(): JSX.Element {
    return (
        <Switch>
            <Route
                path={AuthRoutes.LOGIN}
                render={(renderProps) => (
                    <>
                        <PageTitleCP pageName={'Login'} />
                        <UserLoginScreen {...renderProps} />
                    </>
                )}
            />
            <Route
                path={AppRoutes.ROOT}
                render={(renderProps) => (<LoginVerifierCP {...renderProps} />)}
            />
            <Route component={Error404Screen} />
        </Switch>
    )
}
