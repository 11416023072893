import { Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'

export function BatchCardSkeletonICP(): JSX.Element {
    return (
        <ContentWrapperSCP>
            <HeaderWrappseSCP>
                <Skeleton.Avatar active={true} size={50} shape={'circle'} />
                <TitleSCP>
                    <Skeleton.Button active={true} size={'small'} shape={'square'} />
                    <Skeleton.Button active={true} size={'small'} shape={'square'} />
                </TitleSCP>
            </HeaderWrappseSCP>

            <InfoListWrapperSCP>
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
                <Skeleton.Button active={true} size={'small'} shape={'square'} />
            </InfoListWrapperSCP>

            <FooterWrapperSCP>
                <Skeleton.Button active={true} size={'large'} shape={'square'} />
                <Skeleton.Button active={true} size={'large'} shape={'square'} />
                <Skeleton.Button active={true} size={'large'} shape={'square'} />
            </FooterWrapperSCP>
        </ContentWrapperSCP>
    )
}

const ContentWrapperSCP = styled.div`
    background: #fff;
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    .ant-skeleton-element .ant-skeleton-button-sm{
        width: 100%;
        height: 20px;
        margin: 3px 0;
   }
`

const HeaderWrappseSCP = styled.div`
   display: grid;
   grid-template-columns: 1fr 4fr;
   column-gap: 15px;
   margin-bottom: 8px;
`

const TitleSCP = styled.div`
   display: flex;
   flex-direction: column;
`

const InfoListWrapperSCP = styled.div`
   display: flex;
   flex-direction: column;
`

const FooterWrapperSCP = styled.div`
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    column-gap: 8px;
`
