import _ from 'lodash'

import { AnalysisTypeEnum } from 'modules/analysis/enums/AnalysisTypeEnum'
import { IntensityEnum } from 'modules/analysis/enums/IntensityEnum'
import { PhysicalDefectEnum } from 'modules/analysis/enums/PhysicalDefectEnum'
import { ISensoryAnalysisResDTO } from 'modules/analysis/services/dtos/responses/ISensoryAnalysisResDTO'
import { AfterTasteEnum } from '../enums/AfterTasteEnum'
import { BrewMethodEnum } from '../enums/BrewMethodEnum'
import { CoffeeFlavorEnum } from '../enums/CoffeeFlavorEnum'
import { CupAcidityEnum } from '../enums/CupAcidityEnum'
import { CupBodyEnum } from '../enums/CupBodyEnum'
import { PhysicalColorEnum } from '../enums/PhysicalColorEnum'
import { RoastProfileEnum } from '../enums/RoastProfileEnum'

export class AnalysisUtils {
    static translatePhysicalDefect(defect: PhysicalDefectEnum): string {
        switch (defect) {
            case PhysicalDefectEnum.BLACK_BEAN:
                return 'Grão preto';
            case PhysicalDefectEnum.BIG_STONE:
                return 'Pedra grande';
            case PhysicalDefectEnum.MEDIUM_STONE:
                return 'Pedra média';
            case PhysicalDefectEnum.SMALL_STONE:
                return 'Pedra pequena';
            case PhysicalDefectEnum.BIG_STICK:
                return 'Pau grande';
            case PhysicalDefectEnum.MEDIUM_STICK:
                return 'Pau médio';
            case PhysicalDefectEnum.SMALL_STICK:
                return 'Pau pequeno';
            case PhysicalDefectEnum.COCONUT:
                return 'Coco';
            case PhysicalDefectEnum.BIG_BARK:
                return 'Casca grande';
            case PhysicalDefectEnum.SMALL_BARK:
                return 'Casca pequena';
            case PhysicalDefectEnum.FLAMED:
                return 'Ardido';
            case PhysicalDefectEnum.SAILOR:
                return 'Marinheiro';
            case PhysicalDefectEnum.DRILL:
                return 'Broca';
            case PhysicalDefectEnum.LACY_DRILL:
                return 'Broca rendada';
            case PhysicalDefectEnum.SHELL:
                return 'Concha';
            case PhysicalDefectEnum.GREEN:
                return 'Verde';
            case PhysicalDefectEnum.BROKEN:
                return 'Quebrado';
            case PhysicalDefectEnum.HOLLOW:
                return 'Choco';

            default:
                return ''
            }
    }

    static translatePhysicalDefectToEnglish(defect: PhysicalDefectEnum): string {
        switch (defect) {
            case PhysicalDefectEnum.BLACK_BEAN:
                return 'Grão preto';
            case PhysicalDefectEnum.BIG_STONE:
                return 'Pedra grande';
            case PhysicalDefectEnum.MEDIUM_STONE:
                return 'Pedra média';
            case PhysicalDefectEnum.SMALL_STONE:
                return 'Pedra pequena';
            case PhysicalDefectEnum.BIG_STICK:
                return 'Pau grande';
            case PhysicalDefectEnum.MEDIUM_STICK:
                return 'Pau médio';
            case PhysicalDefectEnum.SMALL_STICK:
                return 'Pau pequeno';
            case PhysicalDefectEnum.COCONUT:
                return 'Coco';
            case PhysicalDefectEnum.BIG_BARK:
                return 'Casca grande';
            case PhysicalDefectEnum.SMALL_BARK:
                return 'Casca pequena';
            case PhysicalDefectEnum.FLAMED:
                return 'Ardido';
            case PhysicalDefectEnum.SAILOR:
                return 'Marinheiro';
            case PhysicalDefectEnum.DRILL:
                return 'Broca';
            case PhysicalDefectEnum.LACY_DRILL:
                return 'Broca rendada';
            case PhysicalDefectEnum.SHELL:
                return 'Concha';
            case PhysicalDefectEnum.GREEN:
                return 'Verde';
            case PhysicalDefectEnum.BROKEN:
                return 'Quebrado';
            case PhysicalDefectEnum.HOLLOW:
                return 'Choco';

            default:
                return ''
            }
    }

    static translateCoffeeFlavor(flavor: CoffeeFlavorEnum): string {
        switch (flavor) {
            case CoffeeFlavorEnum.CHOCOLATE:
                return 'Chocolate'
            case CoffeeFlavorEnum.CARAMEL:
                return 'Caramelo'
            case CoffeeFlavorEnum.VANILLA:
                return 'Baunilha'
            case CoffeeFlavorEnum.YELLOW_FRUIT:
                return 'Frutas Amarelas'
            case CoffeeFlavorEnum.RED_FRUIT:
                return 'Frutas Vermelhas'
            case CoffeeFlavorEnum.CITRIC_FRUIT:
                return 'Frutas Cítricas'
            case CoffeeFlavorEnum.HONEY:
                return 'Mel'
            case CoffeeFlavorEnum.FLORAL:
                return 'Floral'
            case CoffeeFlavorEnum.TEA:
                return 'Chá'
            case CoffeeFlavorEnum.SWEET_CORN:
                return 'Doce de Milho'
            case CoffeeFlavorEnum.SPICES:
                return 'Especiarias'

            case CoffeeFlavorEnum.DARK_CHOCOLATE:
                return 'Chocolate Amargo'
            case CoffeeFlavorEnum.CACAO:
                return 'Cacau'
            case CoffeeFlavorEnum.SWEET_MILK:
                return 'Doce de Leite'
            case CoffeeFlavorEnum.BROWN_SUGAR:
                return 'Açucar Mascavo'
            case CoffeeFlavorEnum.SUGAR_CANE:
                return 'Cana de Açúcar'
            case CoffeeFlavorEnum.MOLASSES:
                return 'Melaço'
            case CoffeeFlavorEnum.PEANUT_CANDY:
                return 'Doce de Amendoim'
            case CoffeeFlavorEnum.PEANUT:
                return 'Amendoim'
            case CoffeeFlavorEnum.BRAZILIAN_NUTS:
                return 'Castanha do Pará'
            case CoffeeFlavorEnum.PURPLE_FRUITS:
                return 'Frutas Roxas'
            case CoffeeFlavorEnum.BLACK_FRUITS:
                return 'Frutas Negras'
            case CoffeeFlavorEnum.WHITE_FRUITS:
                return 'Frutas Brancas'
            case CoffeeFlavorEnum.ACOHOLIC:
                return 'Alcoólico'
            case CoffeeFlavorEnum.OLIVE:
                return 'Olíva'
            case CoffeeFlavorEnum.GUARANA:
                return 'Guaraná'
            case CoffeeFlavorEnum.HERBACEOUS:
                return 'Hebácio'
            case CoffeeFlavorEnum.VEGETABLE:
                return 'Vegetais'
            case CoffeeFlavorEnum.JELLY:
                return 'Geléia'
            case CoffeeFlavorEnum.FRUIT_SYRUP:
                return 'Xarope de frutas'
           

            case CoffeeFlavorEnum.ALMOND:
                return 'Amêndoa'
            case CoffeeFlavorEnum.HAZELNUT:
                return 'Avelã'
            case CoffeeFlavorEnum.HARD_MOLASSES:
                return 'Rapadura'
            case CoffeeFlavorEnum.MILK_CHOCOLATE:
                return 'Chocolate ao Leite'
            case CoffeeFlavorEnum.BITTER_CHOCOLATE:
                return 'Chocolate Amargo'
            case CoffeeFlavorEnum.NUT:
                return 'Noz'
            case CoffeeFlavorEnum.COCOA:
                return 'Cacau'
            case CoffeeFlavorEnum.BLACK_TEA:
                return 'Chá Preto'
            case CoffeeFlavorEnum.DRY_FRUITS:
                return 'Frutas Secas'
            case CoffeeFlavorEnum.WINEY:
                return 'Vinhoso'
            case CoffeeFlavorEnum.WHISKEY:
                return 'Whiskey'
            case CoffeeFlavorEnum.BARLEY:
                return 'Cevada'
            case CoffeeFlavorEnum.MALT:
                return 'Malte'
            case CoffeeFlavorEnum.FERMENTED:
                return 'Fermentado'
            case CoffeeFlavorEnum.OLIVE_OIL:
                return 'Azeite de Oliva'
            case CoffeeFlavorEnum.HERBAL:
                return 'Herbal'
            case CoffeeFlavorEnum.MINT:
                return 'Hortelã'
            case CoffeeFlavorEnum.PEPPER_MINT:
                return 'Menta'
            case CoffeeFlavorEnum.RAW:
                return 'Cru'
            case CoffeeFlavorEnum.PAPER:
                return 'Papel'
            case CoffeeFlavorEnum.MUSTY:
                return 'Mofo'
            case CoffeeFlavorEnum.CHEMICAL:
                return 'Químico'
            case CoffeeFlavorEnum.BURNED:
                return 'Queimado'
            case CoffeeFlavorEnum.CEREAL:
                return 'Cereal'
            case CoffeeFlavorEnum.ROLL_SMOKE:
                return 'Fumo de Rolo'
            case CoffeeFlavorEnum.TOBACCO:
                return 'Tabaco'
            case CoffeeFlavorEnum.RUBBER:
                return 'Borracha'
            default:
                return ''
        }
    }

    static translateCoffeeFlavorToEnglish(flavor: CoffeeFlavorEnum): string {
        switch (flavor) {
            case CoffeeFlavorEnum.CHOCOLATE:
                return 'Chocolate'
            case CoffeeFlavorEnum.CARAMEL:
                return 'Caramel'
            case CoffeeFlavorEnum.VANILLA:
                return 'Vanilla'
            case CoffeeFlavorEnum.YELLOW_FRUIT:
                return 'Yellow Fruit'
            case CoffeeFlavorEnum.RED_FRUIT:
                return 'Red Fruit'
            case CoffeeFlavorEnum.CITRIC_FRUIT:
                return 'Citric Fruit'
            case CoffeeFlavorEnum.HONEY:
                return 'Honey'
            case CoffeeFlavorEnum.FLORAL:
                return 'Floral'
            case CoffeeFlavorEnum.TEA:
                return 'Tea'
            case CoffeeFlavorEnum.SWEET_CORN:
                return 'Sweet corn'
            case CoffeeFlavorEnum.SPICES:
                return 'Spices'
            case CoffeeFlavorEnum.DARK_CHOCOLATE:
                return 'Dark Chocolate'
            case CoffeeFlavorEnum.CACAO:
                return 'Cacao'
            case CoffeeFlavorEnum.SWEET_MILK:
                return 'Sweet Milk'
            case CoffeeFlavorEnum.BROWN_SUGAR:
                return 'Brown Sugar'
            case CoffeeFlavorEnum.SUGAR_CANE:
                return 'Sugar Cane'
            case CoffeeFlavorEnum.MOLASSES:
                return 'Molasses'
            case CoffeeFlavorEnum.PEANUT_CANDY:
                return 'Peanut Candy'
            case CoffeeFlavorEnum.PEANUT:
                return 'Peanut'
            case CoffeeFlavorEnum.BRAZILIAN_NUTS:
                return 'Brazilian Nuts'
            case CoffeeFlavorEnum.PURPLE_FRUITS:
                return 'Purple Fuits'
            case CoffeeFlavorEnum.BLACK_FRUITS:
                return 'Black Fruits'
            case CoffeeFlavorEnum.WHITE_FRUITS:
                return 'White Fruits'
            case CoffeeFlavorEnum.ACOHOLIC:
                return 'Alcoholic'
            case CoffeeFlavorEnum.OLIVE:
                return 'Olive'
            case CoffeeFlavorEnum.GUARANA:
                return 'Guarana'
            case CoffeeFlavorEnum.HERBACEOUS:
                return 'Herbaceous'
            case CoffeeFlavorEnum.VEGETABLE:
                return 'Vegetable'
            case CoffeeFlavorEnum.JELLY:
                return 'Jelly'
            case CoffeeFlavorEnum.FRUIT_SYRUP:
                return 'Fruit Syrup'
          
            case CoffeeFlavorEnum.ALMOND:
                return 'Almond'
            case CoffeeFlavorEnum.HAZELNUT:
                return 'Hazelnut'
            case CoffeeFlavorEnum.HARD_MOLASSES:
                return 'Hard Molasses'
            case CoffeeFlavorEnum.MILK_CHOCOLATE:
                return 'Milk Chocolate'
            case CoffeeFlavorEnum.BITTER_CHOCOLATE:
                return 'Bitter Chocolate'
            case CoffeeFlavorEnum.NUT:
                return 'Nut'
            case CoffeeFlavorEnum.COCOA:
                return 'Cocoa'
            case CoffeeFlavorEnum.BLACK_TEA:
                return 'BlackTea'
            case CoffeeFlavorEnum.DRY_FRUITS:
                return 'DryFruits'
            case CoffeeFlavorEnum.WINEY:
                return 'Winey'
            case CoffeeFlavorEnum.WHISKEY:
                return 'Whiskey'
            case CoffeeFlavorEnum.BARLEY:
                return 'Barley'
            case CoffeeFlavorEnum.MALT:
                return 'Malt'
            case CoffeeFlavorEnum.FERMENTED:
                return 'Fermented'
            case CoffeeFlavorEnum.OLIVE_OIL:
                return 'Olive Oil'
            case CoffeeFlavorEnum.HERBAL:
                return 'Herbal'
            case CoffeeFlavorEnum.MINT:
                return 'Mint'
            case CoffeeFlavorEnum.PEPPER_MINT:
                return 'Pepper Mint'
            case CoffeeFlavorEnum.RAW:
                return 'Raw'
            case CoffeeFlavorEnum.PAPER:
                return 'Paper'
            case CoffeeFlavorEnum.MUSTY:
                return 'Musty'
            case CoffeeFlavorEnum.CHEMICAL:
                return 'Chemical'
            case CoffeeFlavorEnum.BURNED:
                return 'Burned'
            case CoffeeFlavorEnum.CEREAL:
                return 'Cereal'
            case CoffeeFlavorEnum.ROLL_SMOKE:
                return 'RollSmoke'
            case CoffeeFlavorEnum.TOBACCO:
                return 'Tobacco'
            case CoffeeFlavorEnum.RUBBER:
                return 'Rubber'
            default:
                return ''
        }
    }

    static translateCupAcidity(item: CupAcidityEnum): string {
        switch (item) {
            case CupAcidityEnum.CITRIC:
                return 'Cítrica'
            case CupAcidityEnum.MALIC:
                return 'Málica'
            case CupAcidityEnum.PHOSPHORIC:
                return 'Fosfórica'
            case CupAcidityEnum.ACETIC:
                return 'Acética'
        
            case CupAcidityEnum.LACTIC:
                return 'Láctica'
            case CupAcidityEnum.TARTARIC:
                return 'Tartárica'
            default:
                return ''
        }
    }

    static translateCupAcidityToEnglish(item: CupAcidityEnum): string {
        switch (item) {
            case CupAcidityEnum.CITRIC:
                return 'Citric'
            case CupAcidityEnum.MALIC:
                return 'Malic'
            case CupAcidityEnum.PHOSPHORIC:
                return 'Phosphoric'
            case CupAcidityEnum.ACETIC:
                return 'Acetic'
            case CupAcidityEnum.LACTIC:
                return 'Lactic'
            case CupAcidityEnum.TARTARIC:
                return 'Tartaric'
            default:
                return ''
        }
    }

    static translateCupBody(item: CupBodyEnum): string {
        switch (item) {
            case CupBodyEnum.CREAMY:
                return 'Cremoso'
            case CupBodyEnum.DELICATE:
                return 'Delicado'
            case CupBodyEnum.VELVETY:
                return 'Aveludado'
            case CupBodyEnum.THIN:
                return 'Fino'
            case CupBodyEnum.THICK:
                return 'Grosso'
            case CupBodyEnum.LIGHT:
                return 'Leve'
            case CupBodyEnum.DENSE:
                return 'Denso'
            default:
                return ''
        }
    }

    static translateCupBodyToEnglish(item: CupBodyEnum): string {
        switch (item) {
            case CupBodyEnum.CREAMY:
                return 'Creamy'
            case CupBodyEnum.DELICATE:
                return 'Delicate'
            case CupBodyEnum.VELVETY:
                return 'Velvet'
            case CupBodyEnum.THIN:
                return 'Thin'
            case CupBodyEnum.THICK:
                return 'Thick'
            case CupBodyEnum.LIGHT:
                return 'Light'
            case CupBodyEnum.DENSE:
                return 'Dense'
            default:
                return ''
        }
    }

    static translateColor(item: PhysicalColorEnum): string {
        switch (item) {
            case PhysicalColorEnum.BLUISH:
                return 'Azulado'
            case PhysicalColorEnum.FLAGGED:
                return 'Bandeirado'
            case PhysicalColorEnum.FOX_BEAN:
                return 'Fox Bean'
            case PhysicalColorEnum.GREENISH:
                return 'Esverdeado'
            case PhysicalColorEnum.SMOKY:
                return 'Esfumaçado'
            case PhysicalColorEnum.YELLOWISH:
                return 'Yellowish'
            case PhysicalColorEnum.SPOTTED:
                return 'Manchado'
            default:
                return ''
        }
    }

    static translateAfterTaste(item: AfterTasteEnum): string {
        switch (item) {
            case AfterTasteEnum.SHORT:
                return 'Curto'
            case AfterTasteEnum.LONG:
                return 'Prolongado'
            case AfterTasteEnum.PERSISTENT:
                return 'Persistente'
            case AfterTasteEnum.NICE:
                return 'Agradável'
            case AfterTasteEnum.ASTRINGENT:
                return 'Adstringente'
            case AfterTasteEnum.DRY:
                return 'Seco'
            case AfterTasteEnum.ROUGH:
                return 'Bruto'
            default:
                return ''
        }
    }

    static translateIntensity(item: IntensityEnum): string {
        switch (item) {
            case IntensityEnum.LOW:
                return 'Baixa'
            case IntensityEnum.MEDIUM:
                return 'Média'
            case IntensityEnum.HIGH:
                return 'Alta'
            default:
                return ''
        }
    }

    static translateIntensityToEnglish(item: IntensityEnum): string {
        switch (item) {
            case IntensityEnum.LOW:
                return 'Low'
            case IntensityEnum.MEDIUM:
                return 'Medium'
            case IntensityEnum.HIGH:
                return 'High'
            default:
                return ''
        }
    }

    static translateAfterTasteToEnglish(item: AfterTasteEnum): string {
        switch (item) {
            case AfterTasteEnum.SHORT:
                return 'Shor'
            case AfterTasteEnum.LONG:
                return 'Long'
            case AfterTasteEnum.PERSISTENT:
                return 'Persistent'
            case AfterTasteEnum.NICE:
                return 'Nice'
            case AfterTasteEnum.ASTRINGENT:
                return 'Astringent'
            case AfterTasteEnum.DRY:
                return 'Dry'
            case AfterTasteEnum.ROUGH:
                return 'Rough'
            default:
                return ''
        }
    }

    static translateBrewMethod(item: BrewMethodEnum): string {
        switch (item) {
            case BrewMethodEnum.FRENCH_PRESS:
                return 'Prensa Francesa'
            case BrewMethodEnum.FILTERED:
                return 'Filtrado'
            case BrewMethodEnum.AEROPRESS:
                return 'Aeropress'
            case BrewMethodEnum.EXPRESSO:
                return 'Espresso'
            case BrewMethodEnum.COLDBREW:
                return 'Coldbrew'
            default:
                return ''
        }
    }

    static translateBrewMethodToEnglish(item: BrewMethodEnum): string {
        switch (item) {
            case BrewMethodEnum.FRENCH_PRESS:
                return 'French Press'
            case BrewMethodEnum.FILTERED:
                return 'Filtered'
            case BrewMethodEnum.AEROPRESS:
                return 'Aeropress'
            case BrewMethodEnum.EXPRESSO:
                return 'Express'
            case BrewMethodEnum.COLDBREW:
                return 'Coldbrew'
            default:
                return ''
        }
    }

    static translateRoastProfile(item: RoastProfileEnum): string {
        switch (item) {
            case RoastProfileEnum.LIGHT:
                return 'Claro'
            case RoastProfileEnum.PERFECT:
                return 'Perfeito'
            case RoastProfileEnum.DARK:
                return 'Escuro'
            case RoastProfileEnum.VERY_DARK:
                return 'Muito Escuro'
            default:
                return ''
        }
    }

    static translateRoastProfileToEnglish(item: RoastProfileEnum): string {
        switch (item) {
            case RoastProfileEnum.LIGHT:
                return 'Light'
            case RoastProfileEnum.PERFECT:
                return 'Perfect'
            case RoastProfileEnum.DARK:
                return 'Dark'
            case RoastProfileEnum.VERY_DARK:
                return 'Very Dark'
            default:
                return ''
        }
    }

    static getFirstAnalysisDate(analyses: ISensoryAnalysisResDTO[]): string {
        const ordered = _.orderBy(analyses, ['createDate'], ['desc'])
        return ordered[0].createDate
    }

    static translateType(type: AnalysisTypeEnum): string {
        switch (type) {
            case AnalysisTypeEnum.SENSORY:
                return 'Sensorial'
            case AnalysisTypeEnum.PHYSICAL:
                return 'Física'
            default:
                return ''
        }
    }
}
