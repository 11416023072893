import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { IsOptional, ValidateNested } from 'class-validator'
import { ISaveProductMaintenanceRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveProductMaintenanceRequestDTO'
import { ISaveMaintenanceRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveMaintenancesRequestDTO'

export class MaintenanceFormValidator extends FormModelValidator {

    @IsNotEmpty()
    cost: number

    @IsNotEmpty()
    date: string

    @IsOptional()
    notes: string

    @IsNotEmpty()
    season: number

    @IsOptional()
    @ValidateNested()
    products: ISaveProductMaintenanceRequestDTO[]

    @IsOptional()
    paymentDate: string

    constructor(dto?: ISaveMaintenanceRequestDTO) {
        super(dto)
        Object.assign(this, dto)
        if (dto?.date)
            this.date = DateUtils.getFormatted(dto.date, DateFormatEnum.BR_WITHOUT_TIME)
        if (dto?.paymentDate)
            this.paymentDate = DateUtils.toMoment(dto.paymentDate, DateFormatEnum.EUA_WITHOUT_TIME).format('DD/MM/YYYY')
    }
}