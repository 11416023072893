import { ReportFiltersCP } from 'modules/report/components/report-filters/ReportFiltersCP'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { IFieldReportResDTO } from 'modules/report/services/dtos/responses/IFieldReportResDTO'
import { BestBatchListCP } from './inner/BestBatchListCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { ReportRequests } from 'modules/report/services/ReportRequests'
import { useRequest } from 'common/request-helper/UseRequest'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useHistory, useParams } from 'react-router-dom'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { FieldReportGridICP } from 'modules/report/components/field-report-content/inner/FieldReportGridICP'
import { ReportRoutes } from 'modules/report/ReportRoutes'
import { FieldFinancialReportCP } from 'modules/report/components/field-financial-report/FieldFinancialReportCP'
import { CostPerCategoryCP } from 'modules/report/components/cost-per-category/CostPerCategoryCP'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { useSelector } from 'react-redux'

type RouteParamsTP = { code?: string }
const DEFAULT_FILTERS: ISearchReportReqDTO = {
    season: moment().year(),
}

export function FieldReportContentCP(): JSX.Element {
    const [filters, setFilters] = useState<ISearchReportReqDTO>(DEFAULT_FILTERS)
    const [report, setReport] = useState<IFieldReportResDTO>()
    const currentFilters: ISearchReportReqDTO | null = useSelector((state: IReduxState) => _.get(state, 'searchState.filter'))
    const type: FilterTypesEnum = useSelector((state: IReduxState) => _.get(state, 'searchState.type'))

    const history = useHistory()
    const routeParams = useParams<RouteParamsTP>()

    useEffect(setRoutParamFilter, [routeParams])

    useEffect(getReportData, [filters])
    const getReportReq = useRequest<IFieldReportResDTO>()
    useEffect(onRequestChange, [getReportReq.awaiting])


    function setRoutParamFilter(): void {
        if (type !== FilterTypesEnum.REPORT)
            return SearchActions.clearState()

        if (!!routeParams.code) {
            setFilters({
                ...filters,
                fieldCode: +routeParams.code,
                season: !!currentFilters ? currentFilters.season : filters.season
            })
        }
    }

    function getReportData(): void {
        // if (!!filters.fieldCode)
        //     history.push(`${ReportRoutes.FIELD_WITHOUT_PARAM}/${filters.fieldCode}`)
        if (!!filters.propertyCode && !!filters.fieldCode)
            getReportReq.runRequest(ReportRequests.getFieldReport(filters))

    }

    function onRequestChange(): void {
        if (getReportReq.awaiting || !getReportReq.tried)
            return

        if (!getReportReq.success || !getReportReq.returnData) {
            if (getReportReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getReportReq.returnData, getReportReq.error)
            return RequestUtils.showDefaultErrorNotification(getReportReq.error, 'Erro ao buscar dados!')
        }
        setReport(getReportReq.returnData)
    }


    return (
        <WrapperSCP>
            <ReportFiltersCP
                filters={filters}
                onChangeFilters={setFilters}
                showFields={['season', 'property', 'field']}
            />
            {
                !!filters.fieldCode ?
                    <FieldReportGridICP report={report} loading={getReportReq.awaiting} />
                    : <WrapperGridSCP><EmptyCP description={'Selecione um talhão para ver os dados do relatório.'} /></WrapperGridSCP>
            }
            {
                !!filters.fieldCode && !!report &&
                <FieldFinancialReportCP
                    filters={filters}
                    mostCommonAromas={report.mostCommonAromas}
                    mostCommonDefects={report.mostCommonDefects}
                />
            }
            <WrapperListFieldsSCP>
                {
                    !!filters.fieldCode &&
                    <BestBatchListCP loading={getReportReq.awaiting} filters={filters} showTitle={true} />
                }
                {
                    !!filters.fieldCode &&
                    <CostPerCategoryCP loading={getReportReq.awaiting} filters={filters} />
                }
            </WrapperListFieldsSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    margin-bottom: 4em;
    max-height: 75vh;   
    overflow: overlay;
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    }
`
const WrapperGridSCP = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    min-height: 50vh;
    border-radius: 8px;
`


const WrapperListFieldsSCP = styled.div`
    display: grid;
    grid-template-columns: 70% 28%;
    column-gap: 10px;
    @media(max-width: 768px ){
        grid-template-columns: 100%;
    }

`