import { DividerCP } from 'common/components/divider/DividerCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'

import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { ISensoryAnalysisResDTO } from 'modules/analysis/services/dtos/responses/ISensoryAnalysisResDTO'
import { SensoryAnalysesResumeContentCP } from 'modules/batch-landing-page/components/sensory-analyses-resume/SensoryAnalysesResumeContentCP'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import React from 'react'
import styled from 'styled-components'
import { IBatchSensoryAnalysisDTO } from '../../../batch/services/dtos/responses/IBatchAnalysisResponseDTO'

interface ISensoryAnalysisListCPProps {
    analysis: IBatchSensoryAnalysisDTO[]
}

export function SensoryAnalysisListCP(props: ISensoryAnalysisListCPProps): JSX.Element {

    if (!props.analysis.length)
        return (<EmptyCP description={'Este lote ainda não possui análises sensoriais'} />)

    return (
        <WrapperSCP>
            {
                props.analysis.map((analysis: IBatchSensoryAnalysisDTO) => (
                    <AnalysesWrapperSCP key={analysis.id}>
                        <DividerCP text={`${DateUtils.formatDate(analysis.createdAt, DateFormatEnum.BR_WITHOUT_TIME)}`} orientation={'left'} />
                        <SensoryAnalysesResumeContentCP
                            item={analysis}
                            language={LanguageEnum.PT}
                        />
                    </AnalysesWrapperSCP>
                ))
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: #fff;
   
   
`

const AnalysesWrapperSCP = styled.div`
   
`
