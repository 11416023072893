import { faFunnelDollar, faMedal } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CoffeeBeansIcon } from 'common/components/icons/CoffeeBeans'
import { DuoWidget } from 'common/components/widgets/DuoWidgetCP'
import { GenericCardWidgetCP } from 'common/components/widgets/GenericCardWidgetCP'
import { WidgetGenericValueICP } from 'common/components/widgets/inner/WidgetGenericValueICP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaskUtils } from 'common/utils/MaskUtils'
import { CoffeeFlavorEnum } from 'modules/analysis/enums/CoffeeFlavorEnum'
import { PhysicalDefectEnum } from 'modules/analysis/enums/PhysicalDefectEnum'
import { FlavorAndAromaWidgetICP } from 'modules/report/components/quality-report-grid/inner/FlavorAndAromaWidgetICP'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'
import { IBatchResumeResDTO } from 'modules/report/services/dtos/responses/IBatchResumeResDTO'
import { IFinancialReportResDTO } from 'modules/report/services/dtos/responses/IFinancialReportResDTO'
import { ReportRequests } from 'modules/report/services/ReportRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IFieldFinancialReportCPProps {
    filters: ISearchReportReqDTO
    mostCommonAromas: CoffeeFlavorEnum[]
    mostCommonDefects: PhysicalDefectEnum[]
}

export function FieldFinancialReportCP(props: IFieldFinancialReportCPProps): JSX.Element {
    const [reportData, setReportData] = useState<IFinancialReportResDTO>()

    const getFinacialReportReq = useRequest<IFinancialReportResDTO>()
    useEffect(onRequestChange, [getFinacialReportReq.awaiting])
    useEffect(getReport, [props.filters])

    function getReport(): void {
        if (!!props.filters.fieldCode)
            getFinacialReportReq.runRequest(ReportRequests.getFinancialReport(props.filters))
    }

    function onRequestChange(): void {
        if (getFinacialReportReq.awaiting || !getFinacialReportReq.tried)
            return

        if (!getFinacialReportReq.success || !getFinacialReportReq.returnData) {
            if (getFinacialReportReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getFinacialReportReq.returnData, getFinacialReportReq.error)
            return RequestUtils.showDefaultErrorNotification(getFinacialReportReq.error, 'Erro ao buscar dados!')
        }
        setReportData(getFinacialReportReq.returnData)
    }

    return (
        <WrapperGridSCP>
            <RowFirstGridSCP>

                <GenericCardWidgetCP
                    gridArea={'income'}
                    title={'Faturamento total'}
                    icon={<FontAwsomeIconCP icon={faFunnelDollar} />}
                    content={
                        <WidgetGenericValueICP
                            value={!!reportData?.totalIncome ? `${MaskUtils.applyCurrencyMask(reportData.totalIncome)}` : 'R$0'}
                            label={''}
                            loading={getFinacialReportReq.awaiting}
                        />
                    }
                />
                <FlavorAndAromaWidgetICP
                    mostCommonAromas={props.mostCommonAromas}
                    mostCommonDefects={props.mostCommonDefects}
                    loading={getFinacialReportReq.awaiting}
                />
                <GenericCardWidgetCP
                    title={'Custo por saca'}
                    gridArea={'cost-per-bag'}
                    content={
                        <WidgetGenericValueICP
                            value={!!reportData?.costPerBag ? `${MaskUtils.applyCurrencyMask(reportData.costPerBag)}` : 'R$0'}
                            label={''}
                            loading={getFinacialReportReq.awaiting}
                        />
                    }
                />

                <GenericCardWidgetCP
                    title={'Custo total'}
                    gridArea={'total-cost'}
                    content={
                        <WidgetGenericValueICP
                            value={!!reportData?.totalCost ? `${MaskUtils.applyCurrencyMask(reportData.totalCost)}` : 'R$0'}
                            label={''}
                            loading={getFinacialReportReq.awaiting}
                        />
                    }
                />

                <DuoWidget
                    gridArea={'sale-value'}
                    dataWidget={
                        [
                            {
                                title: 'Valor de venda médio por saca',
                                value: !!reportData && !!reportData.averageValuePerBag ? `${MaskUtils.applyCurrencyMask(reportData.averageValuePerBag)} ` : 'R$0'
                            },
                            {
                                title: 'Lucro por saca',
                                value: !!reportData && !!reportData.profitPerBag ? `${MaskUtils.applyCurrencyMask(reportData.profitPerBag)}` : 'R$0'
                            },
                        ]
                    }
                />

            </RowFirstGridSCP>
        </WrapperGridSCP>
    )
}

const WrapperSCP = styled.div`
`

const WrapperGridSCP = styled.div`
    margin: 15px 0;
    display: grid;
`

const RowFirstGridSCP = styled.div`
    display: grid;
    margin-bottom: 10px;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    grid-template-areas:
        "income cost-per-bag sale-value sale-value"
        "flavor-aroma total-cost sale-value sale-value"
    ;

    @media(max-width: 768px){
        grid-template-columns: 100%;
        grid-template-areas: 
            "income"
            "cost-per-bag"           
            "flavor-aroma"
            "total-cost"
            "sale-value"
            "sale-value";
    }
`
