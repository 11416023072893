import React, { useState } from 'react'

import { ActivityStatusEnum } from 'modules/maneuver/enums/ActivityStatusEnum'
import { ActivityStatusFilterICP } from 'modules/maneuver/components/maneuver-list-filters/inner/ActivityStatusFilterICP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ISearchManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchManeuverRequestDTO'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ManeuverFiltersDrawerICP } from './ManeuverFiltersDrawerICP'
import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'
import { RecipeTypeSelectorCP } from 'modules/maneuver/components/recipe-type-selector/RecipeTypeSelectorCP'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import _ from 'lodash'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { useScreenSize } from 'common/hooks/UseScreenSize'

interface IManeuverFilterFieldsICPProps {
    filters?: ISearchManeuverRequestDTO
    setFilters: (filters?: ISearchManeuverRequestDTO) => void
    columns?: boolean
}

export function ManeuverFilterFieldsICP(props: IManeuverFilterFieldsICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [filterDrawerVisible, setFilterDrawerVisible] = useState<boolean>(false)


    return (
        <WrapperSCP>
            <GridWrapperSCP style={{
                gridTemplateColumns: props.columns ? '1fr' : '.8fr 1fr 1fr  3fr .4fr'
            }}>
                <SeasonSelectorCP
                    label={'Safra'}
                    onChange={(season) => props.setFilters({ ...props.filters, season })}
                    value={props.filters?.season}
                />
                <InputCP
                    placeholder={'Cód.'}
                    label={'Cód.'}
                    onChange={(code) => props.setFilters({ ...props.filters, code })}
                    value={props.filters?.code}
                />
                <InputCP
                    placeholder={'Título'}
                    label={'Título'}
                    onChange={(title) => props.setFilters({ ...props.filters, title })}
                    value={props.filters?.title}
                />
                {
                    !!props.columns &&

                    <InputCP
                        placeholder={'Colaborador'}
                        label={'Colaborador'}
                        onChange={_.debounce((personRes) => props.setFilters({ ...props.filters, personRes }), 750)}
                        value={props.filters?.personRes}
                    />

                }

                {
                    !!props.columns &&

                    <PropertyFieldFieldsCP
                        onChangeField={(fieldCode) => props.setFilters({ ...props.filters, fieldCode })}
                        onChangeProperty={(propertyCode) => props.setFilters({ ...props.filters, propertyCode })}
                        fieldCode={props.filters?.fieldCode}
                        propertyCode={props.filters?.propertyCode}
                    />

                }

                <StatusSCP>
                    <ActivityStatusFilterICP
                        onChangeStatus={(status) => props.setFilters({ ...props.filters, status })}
                        status={props.filters?.status}
                    />
                </StatusSCP>
                {
                    !props.columns &&
                    <ButtonCP
                        onClick={() => setFilterDrawerVisible(true)}
                        type={'primary'}
                        style={{ width: 50, height: 50, zIndex: 1, borderRadius: '100%' }}
                        icon={<FontAwsomeIconCP icon={faFilter} color={'#fff'} />}
                    />}

            </GridWrapperSCP>
            <ManeuverFiltersDrawerICP
                show={filterDrawerVisible}
                onClose={() => setFilterDrawerVisible(false)}
                filters={props.filters}
                setFilters={props.setFilters}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 3px;
`

const GridWrapperSCP = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: .8fr 1fr 1fr  3fr .4fr;
    column-gap: 10px;
    align-items: center;
    row-gap: 10px;
    margin-top: 10px;

    label, .ant-typography div{
      display: none;
    }

    @media(max-width: 767px){
        grid-template-columns: 1fr;
         label, .ant-typography div{
                   display: block;
         }
    }
`

const StatusSCP = styled.div`
    @media(max-width: 767px){
       display: none;
    }

`