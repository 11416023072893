import { RecipeTypeEnum } from '../enums/RecipeTypeEnum'
import { ImplementTypeEnum } from '../enums/ImplementTypeEnum'
import React from 'react'
import { faBug, faCut, faDotCircle, faHiking, faTractor, faWater } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { GrassIcon } from 'common/components/icons/GrassIcon'
import { PlantIcon } from 'common/components/icons/PlantIcon'
import { RakeIcon } from 'common/components/icons/RakeIcon'
import { FertilizationIcon } from 'common/components/icons/FertilizationIcon'
import { ActivityStatusEnum } from 'modules/maneuver/enums/ActivityStatusEnum'
import { EquipmentIcon } from 'common/components/icons/EquipmentIcon'
import { PriorityEnum } from '../enums/PriorityEnum'
import { ThemeConfig } from 'config/ThemeConfig'

export class ManeuverUtils {

    static getImplementTypeIcon(type: ImplementTypeEnum): JSX.Element {
        switch (type) {
            case ImplementTypeEnum.EQUIPMENT:
                return <EquipmentIcon color={'#fff'} style={{ fontSize: '1.75em' }} />
            case ImplementTypeEnum.VEHICLE:
                return <FontAwsomeIconCP icon={faTractor} color={'#fff'} />
            default:
                return <EquipmentIcon color={'#fff'} style={{ fontSize: '1.75em' }} />
        }
    }

    static translateImplementType(type: ImplementTypeEnum, lowercase?: boolean): string {
        switch (type) {
            case ImplementTypeEnum.EQUIPMENT:
                return lowercase ? 'equipamento' : 'Equipamento'
            case ImplementTypeEnum.VEHICLE:
                return lowercase ? 'veículo' : 'Veículo'
            default:
                return ''
        }
    }

    static translateActivityStatusLabel(type: ActivityStatusEnum): string {
        switch (type) {
            case ActivityStatusEnum.IN_PROGRESS:
                return 'Em andamento'
            case ActivityStatusEnum.CONCLUDED:
                return 'Concluídas'
            case ActivityStatusEnum.NOT_INITIATED:
                return 'Não iniciadas'
            default:
                return ''
        }
    }

    static translatePriority(type: PriorityEnum): string {
        switch (type) {
            case PriorityEnum.HIGH:
                return 'Alta'
            case PriorityEnum.MEDIUM:
                return 'Média'
            case PriorityEnum.LOW:
                return 'Baixa'
            default:
                return ''
        }
    }

    static getPriorityColor(type: PriorityEnum): string {
        switch (type) {
            case PriorityEnum.HIGH:
                return ThemeConfig.errorColor
            case PriorityEnum.MEDIUM:
                return ThemeConfig.warningColor
            case PriorityEnum.LOW:
                return ThemeConfig.successColor
            default:
                return ''
        }
    }

    static getRecipeTypeIcon(type: RecipeTypeEnum): JSX.Element {
        switch (type) {
            case RecipeTypeEnum.GENERAL:
                return <FontAwsomeIconCP icon={faDotCircle} color={'#fff'} />
            case RecipeTypeEnum.BUSH_CONTROL:
                return <GrassIcon fill={'#fff'} />
            case RecipeTypeEnum.FERTILIZING:
                return <FertilizationIcon fill={'#fff'} />
            case RecipeTypeEnum.PRAGUE_CONTROL:
                return <FontAwsomeIconCP icon={faBug} color={'#fff'} />
            case RecipeTypeEnum.SPROUT_CLEANUP:
                return <PlantIcon fill={'#fff'} />
            case RecipeTypeEnum.SKELETON:
                return <FontAwsomeIconCP icon={faCut} color={'#fff'} />
            case RecipeTypeEnum.SOIL_PREPARE:
                return <FontAwsomeIconCP icon={faWater} color={'#fff'} />
            case RecipeTypeEnum.POST_HARVEST:
                return <FontAwsomeIconCP icon={faHiking} color={'#fff'} />
            case RecipeTypeEnum.UNDER_CLEANUP:
                return <RakeIcon fill={'#fff'} />
            default:
                return <span>-</span>
        }
    }
}
