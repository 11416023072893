/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { TextCP } from 'common/components/text/TextCP'
import { FieldUtils } from 'modules/field/utils/FieldUtils'
import React, { CSSProperties, useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { DateUtils } from 'common/utils/DateUtils'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faArrowDown, faArrowUp, faFeatherAlt, faSeedling, faToggleOn, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { ISearchHarvestPlanningResDTO } from 'modules/harvest-planning/services/dtos/response/ISearchHarvestPlanningResDTO'
import { HarvestStatisticsICP } from 'modules/harvest-planning/components/harvest-logbook-content/inner/HarvestStatisticsICP'
import { LogbookDrawerCP } from 'modules/logbook/components/logbook-drawer/LogbookDrawerCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { HarvestPlanningRequests } from 'modules/harvest-planning/services/HarvestPlanningRequests'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IConcludeHarvestPlanningRequestDTO } from 'modules/harvest-planning/services/dtos/request/IConcludeHarvestPlanningRequestDTO'

interface IHarvestLogbookHeaderProps {
    harvestPlanningData: ISearchHarvestPlanningResDTO
}

export function HarvestLogbookHeader(props: IHarvestLogbookHeaderProps): JSX.Element {
    const [conclusionDate, setConclusionDate] = useState<string>(DateUtils.formatDate(moment().format(), DateFormatEnum.BR_WITHOUT_TIME))
    const [disableNewEntry, setDisableNewEntry] = useState<boolean>(false)
    const btnPositionStyle: CSSProperties = { position: 'absolute', top: '1em', right: '1em' }
    const screenSize = useScreenSize()

    const [logbookDrawerVisible, setLogbookDrawerVisible] = useState<boolean>(false)

    const [showMobileDetails, setShowMobileDetails] = useState<boolean>(!screenSize.smd)

    const markAsConcludedReq = useRequest()
    useEffect(onRequestChange, [markAsConcludedReq.awaiting])

    useEffect(() => {
        setDisableNewEntry(!!props.harvestPlanningData.realEndDate)
    }, [props.harvestPlanningData])

    function onRequestChange(): void {

        if (markAsConcludedReq.awaiting || !markAsConcludedReq.tried)
            return

        if (!markAsConcludedReq.success) {
            if (markAsConcludedReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', markAsConcludedReq.returnData, markAsConcludedReq.error)
            return RequestUtils.showDefaultErrorNotification(markAsConcludedReq.error, 'Erro ao buscar detalhes do manejo!')
        }

        ListActions.mustUpdate()
    }

    function reopen(): void {
        markAsConcludedReq.runRequest(HarvestPlanningRequests.reopen(props.harvestPlanningData.code))
    }

    function concludeManeuver(): void {
        if (!conclusionDate)
            return

        const dto: IConcludeHarvestPlanningRequestDTO = {
            date: DateUtils.getDateFormattedToApi(conclusionDate)
        }

        markAsConcludedReq.runRequest(HarvestPlanningRequests.markAsConcluded(props.harvestPlanningData.code, dto))
    }

    return (
        <WrapperSCP>
            <CardWrapperSCP>
                <CardHeaderICP
                    highlight={<FontAwsomeIconCP icon={faSeedling} />}
                    title={`Tamanho: ${props.harvestPlanningData.field.totalArea} ha | ${props.harvestPlanningData.field.treeCount} pés`}
                    categoryTitle={`Talhão: ${props.harvestPlanningData.field.name} (${FieldUtils.translateVarietysEnum(props.harvestPlanningData.field.coffeeVariety,  props.harvestPlanningData.field.varieties.map(v => v.name).join(', '))}, ${props.harvestPlanningData.field.plantingYear})`}
                    subtitle={
                        <ContentInfoSCP style={{ display: `${showMobileDetails ? 'grid' : 'none'}` }}>
                            <RowSCP>
                                <TextCP text={'Safra:\u00A0'} style={{ fontWeight: '500 ' }} />
                                <TextCP text={`${props.harvestPlanningData.season}`} />
                            </RowSCP>

                            <RowSCP>
                                <TextCP text={'Estimativa de conclusão:\u00A0'} style={{ fontWeight: '500 ' }} />
                                <TextCP text={`${props.harvestPlanningData.plannedEndDate ? moment(props.harvestPlanningData.plannedEndDate).format('DD/MM/YYYY') : '-'}`} />
                            </RowSCP>

                            <RowSCP>
                                <TextCP text={'Iniciado em:\u00A0'} style={{ fontWeight: '500 ' }} />
                                <TextCP text={`${props.harvestPlanningData.plannedStartDate ? moment(props.harvestPlanningData.plannedStartDate).format('DD/MM/YYYY') : 'Não informado'}`} />
                            </RowSCP>
                        </ContentInfoSCP>
                    }
                />
                {
                    screenSize.smd &&
                    <ButtonCP
                        onClick={() => setShowMobileDetails(!showMobileDetails)}
                        size={'small'}
                        style={btnPositionStyle}
                        icon={<FontAwsomeIconCP icon={!showMobileDetails ? faArrowDown : faArrowUp} />}
                        type={'primary'}
                    />

                }
            </CardWrapperSCP>
            <DateInfoWrapper>
                <HarvestStatisticsICP
                    estimatedTotalLiters={props.harvestPlanningData.estimatedLiters ? `${MaskUtils.applyNumberMask(props.harvestPlanningData.estimatedLiters * props.harvestPlanningData.field.treeCount)} ` : '0'}
                    totalLiters={props.harvestPlanningData.totalLiters ?? 0}
                    totalLitersProcessed={props.harvestPlanningData.totalProcessed ?? 0}
                    totalBags={props.harvestPlanningData.totalBags ?? 0}
                    benefitedLiters={props.harvestPlanningData.benefitedLiters ?? 0}
                    revenue={props.harvestPlanningData.revenue ?? 0}
                    realStartDate={props.harvestPlanningData.realStartDate}
                    endDate={props.harvestPlanningData.plannedEndDate}
                    totalEntries={0}
                />
                <ButtonWrapperSCP>
                    {
                        disableNewEntry ?
                            <ButtonCP
                                type={'ghost'}
                                icon={<FontAwsomeIconCP icon={faToggleOn} />}
                                loading={markAsConcludedReq.awaiting}
                                onClick={reopen}
                            >
                                Reabrir
                            </ButtonCP>
                            :
                            <PopOverCP
                                placement={'bottom'}
                                title={'Concluir item'}
                                trigger={'click'}
                                content={
                                    <POWrapperSCP>
                                        <InputCP
                                            label={'Data'}
                                            placeholder={'__/__/____'}
                                            onChange={setConclusionDate}
                                            value={conclusionDate}
                                            type={'date'}
                                            required={true}
                                            mask={MaskUtils.applyDateMask}
                                        />
                                        <ButtonCP
                                            type={'primary'}
                                            icon={<FontAwsomeIconCP icon={faCheckDouble} />}
                                            onClick={concludeManeuver}
                                            loading={markAsConcludedReq.awaiting}
                                            disabled={disableNewEntry}
                                        />
                                    </POWrapperSCP>

                                }
                            >
                                <ButtonCP
                                    type={'ghost'}
                                    icon={<FontAwsomeIconCP icon={faCheckDouble} />}
                                    loading={markAsConcludedReq.awaiting}
                                    disabled={disableNewEntry}
                                >
                                    Concluir
                                </ButtonCP>
                            </PopOverCP>
                    }

                    <ButtonCP
                        type={'primary'}
                        onClick={() => setLogbookDrawerVisible(true)}
                        icon={<FontAwsomeIconCP icon={faFeatherAlt} />}
                        disabled={disableNewEntry}
                    >
                        Novo registro
                    </ButtonCP>
                </ButtonWrapperSCP>

            </DateInfoWrapper>

            <LogbookDrawerCP
                show={logbookDrawerVisible}
                onCancel={() => setLogbookDrawerVisible(false)}
                onSuccess={() => setLogbookDrawerVisible(false)}
                harvestPlanningCode={props.harvestPlanningData.code}
                fieldCode={props.harvestPlanningData.field.code}
            />

        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 20px;
    @media (max-width: 768px){
        grid-template-columns: 1fr;
    }
  
`

const CardWrapperSCP = styled.div`
    position: relative;

`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 0;
    .ant-btn{
        margin-left: 10px;
    }
    @media(max-width: 1280px){
        flex-direction: column;
        .ant-btn{
            margin-bottom: 10px;
        }
    }
    @media(max-width: 768px){
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 10px;
        width: 100%;
        .ant-btn{
            width: 100%;
            margin: 0;
            justify-content: center;
        }
    }
`
const RowSCP = styled.div`
    display: flex;
    margin-bottom: 5px;

`

const DateInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;   
    align-items: flex-end;
   width: 100%;
    justify-content: space-between;
    .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px){
         margin: 10px 0;
        align-items: flex-start;
        flex-direction: column-reverse;   
    }
`

const SubTitleSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    width: 100%;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }

`

const ContentInfoSCP = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    margin: 5px 0;
    .ant-typography{
        font-size: 12px;
    }

    @media (max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const POWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .ant-btn{
        margin-left: 10px;
        margin-bottom: 10px;
    }
    @media(max-width: 768px){
        .ant-btn{
            margin: 0;
        }
    }
`
