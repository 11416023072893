import React, { useState } from 'react'
import { faHandHoldingUsd, faPlus } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { FinancialDashboardCP } from 'modules/financial/components/financial-dashboard/FinancialDashboardCP'
import { FinancialDashboardTypeCP } from 'modules/financial/components/financial-dashboard-type/FinancialDashboardTypeCP'
import { InvoiceTypeEnum } from 'modules/financial/enums/InvoiceType'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { InvoiceFormDrawerCP } from 'modules/financial/components/invoice-form-drawer/InvoiceFormDrawerCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'

export function FinancialMainScreen(): JSX.Element {
    const screenSize = useScreenSize()
    const [typeDashboard, setTypeDashboard] = useState<InvoiceTypeEnum>(InvoiceTypeEnum.REVENUE)
    const [newInvoiceDrawerVisible, setNewInvoiceDrawerVisible] = useState<boolean>(false)
    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={'Financeiro'}
                    icon={<FontAwesomeIcon icon={faHandHoldingUsd} style={{ fontSize: 18 }} />}
                >
                    <HeaderActionsWrapperSCP>
                        {
                            !screenSize.smd &&
                            <FinancialDashboardTypeCP type={typeDashboard} onChangeType={setTypeDashboard} />

                        }
                        <ButtonCP
                            icon={<FontAwsomeIconCP icon={faPlus} />}
                            size={'middle'}
                            type={'ghost'}
                            onClick={() => setNewInvoiceDrawerVisible(true)}
                        >
                            Adicionar
                        </ButtonCP>
                    </HeaderActionsWrapperSCP>
                </HeaderCP>
            }
            content={
                <ContentCP>

                    <FinancialDashboardCP type={typeDashboard} setTypeDashboard={setTypeDashboard} />
                    <InvoiceFormDrawerCP
                        type={typeDashboard}
                        show={newInvoiceDrawerVisible}
                        onCancel={() => setNewInvoiceDrawerVisible(false)}
                        onSuccess={() => {
                            setNewInvoiceDrawerVisible(false)
                        }}
                    />
                </ContentCP>
            }
        />
    )
}

const HeaderActionsWrapperSCP = styled.div`
    display: flex;
    flex-direction: space-between;
    width: 490px;
    .ant-btn{
        margin-left: 20px;
    }
    @media(max-width: 768px){
      
        width: 100%;
    }
`