import React, { CSSProperties } from 'react'
import { Upload } from 'antd'
import styled from 'styled-components'
import { UploadButtonICP } from './inner/UploadButtonICP'
import { RcFile } from 'antd/lib/upload'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'
import { AvatarCP } from 'common/components/avatar/AvatarCP'

interface IProfileImageUploaderCPProps {
    onChange: (info: any) => void
    beforeUpload: (file: RcFile, fileList: RcFile[]) => boolean | Promise<any>
    customRequest?: (option: object) => void
    imageUrl?: string
    fileTypes?: string
    loading: boolean
    instructions?: string
    multiple?: boolean
    showUploadList?: boolean
    disabled?: boolean
    style?: CSSProperties
    size?: number
}

/**
 * Componente responsável por fazer o upload de imagem
 * Utiliza handlers customizados para fazer este upload.
 * @author kiara.vz
 */
export function ProfileImageUploaderCP(props: IProfileImageUploaderCPProps): JSX.Element {

    return (
        <FileUploaderWrapperSCP>
            <Upload
                beforeUpload={props.beforeUpload}
                customRequest={props.customRequest}
                disabled={props.disabled}
                onChange={props.onChange}
                accept={props.fileTypes}
                multiple={props.multiple}
                showUploadList={props.showUploadList}
            >
                {props.imageUrl ?
                    <AvatarCP
                        src={props.imageUrl}
                        size={props.size ?? 150}
                    />
                    :
                    <UploadButtonICP style={props.style} disabled={props.disabled} loading={props.loading} isAvatar={true} />}
            </Upload>
            <InstructionsSCP>
                {props.instructions}
            </InstructionsSCP>
            <LoadingOverlayCP show={props.loading} />
        </FileUploaderWrapperSCP>
    )
}

const InstructionsSCP = styled(Upload)`
.ant-upload-select-text{
    span{
        font-size: 12px;
    }
    margin-top: 10px;
}
`
const FileUploaderWrapperSCP = styled.div`
        cursor: pointer;
        justify-content: center;
        display: flex;
        flex-direction: column;
        span{
            text-align: center;
        }
`