export enum FilterTypesEnum {
    STRUCTURE = 'STRUCTURE',
    HARVEST_PLANNING = 'HARVEST_PLANNING',
    HARVEST = 'HARVEST',
    BATCH = 'BATCH',
    MANEUVER = 'MANEUVER',
    RECIPE = 'RECIPE',
    PRODUCT = 'PRODUCT',
    EQUIPMENT = 'EQUIPMENT',
    VEHICLE = 'VEHICLE',
    REPORT = 'REPORT',
    PERSON = 'PERSON',
}