import React from 'react'
import styled from 'styled-components'
import { Image } from 'antd'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IImagePreviewConfig } from 'common/components/custom-photo-gallery/inner/IImageData'
import { faDownload, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ButtonCP } from 'common/components/button/ButtonCP'

interface IImageBlockICPProps {
    url: string
    previewWidth?: number | string
    onDelete?: (url: string) => void
    previewConfig?: IImagePreviewConfig
    loading?: boolean
}

export function ImageBlockICP(props: IImageBlockICPProps): JSX.Element {
    const screenSize = useScreenSize()

    return (
        <WrapperSCP>
            <Image
                width={screenSize.smd ? '100%' : props.previewWidth}
                src={props.url}
                preview={props.previewConfig}
            />
            <ActionBarSCP>
                <FontAwsomeIconCP
                    icon={faDownload}
                    onClick={() => window.open(props.url)}
                />
                {
                    !!props.onDelete &&
                    <FontAwsomeIconCP icon={props.loading ? faSpinner : faTrash} spin={props.loading} onClick={() => props.onDelete!(props.url)} />
                }
            </ActionBarSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  position: relative;
  max-height: 200px;
  overflow: hidden;

  svg {
    &:hover {
      color: ${props => props.theme.primaryColor}
    }
  }
`

const ActionsSCP = styled.div`
  position: absolute;
  bottom: 17px;
  right: 15px;
  z-index: 99999;

  svg {
    color: ${props => props.theme.primaryColor};
    opacity: .5;
    font-size: 25px;
  }
`

const ActionBarSCP = styled.div`
  display: flex;
  background: #0009;
  position: absolute;
  height: 30px;
  z-index: 999;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
  padding: 10px;

  svg {
    color: #fff;
    font-size: 14px;
  }
`