import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/RequestManagerTypes'
import { SystemConfig } from 'config/SystemConfig'
import { ISaveCompanyReqDTO } from 'modules/company/services/dtos/requests/ISaveCompanyReqDTO'

export class CompanyRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/company`
    private constructor() { }

    static search = (): RequestConfigTP => ({
        url: `${CompanyRequests.baseApiUrl}/search`,
        method: HttpMethodEnum.GET,
    })

    static getSysAdmin = (dto: { email: string }): RequestConfigTP => ({
        url: `${CompanyRequests.baseApiUrl}/sysadmin`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getCompany = (dto: { schema: string }): RequestConfigTP => ({
        url: `${CompanyRequests.baseApiUrl}/`,
        method: HttpMethodEnum.GET,
        params: dto,
        noAuth: true
    })

    static findOne = (code: number): RequestConfigTP => ({
        url: `${CompanyRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static save = (dto: ISaveCompanyReqDTO): RequestConfigTP => ({
        url: `${CompanyRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static update = (code: number, dto: ISaveCompanyReqDTO): RequestConfigTP => ({
        url: `${CompanyRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static saveLogo = (code: number, type: 'logo' | 'icon' | 'inverse-logo', data: FormData): RequestConfigTP => ({
        url: `${CompanyRequests.baseApiUrl}/${code}/${type}`,
        method: HttpMethodEnum.PUT,
        params: data,
        headers: { 'content-type': 'multipart/form-data' }
    })

}
