import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'

interface ILoadingBoxCPProps {
}

export function LoadingBoxCP(props: ILoadingBoxCPProps): JSX.Element {

  return (
    <WrapperSCP>
      <TextCP
        text={'loadingBatch'}
        translationFiles={'batch'}
        translationNameSpace={'batch'}
      />

      <DotFloatingLoadSCP />

    </WrapperSCP>
  )
}

const WrapperSCP = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: ${props => props.theme.primaryColor};
    z-index: 99999;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    
    .ant-typography{
      font-size: 28px;
      font-weight: 600;
      color: white;
      text-align: center
    }
`
const DotFloatingLoadSCP = styled.div`
 
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dotFloating 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1);


  &::before,  &::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;


&::before {
  left: -12px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dotFloatingBefore 3s infinite ease-in-out;
}

&::after {
  left: -24px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dotFloatingAfter 3s infinite cubic-bezier(0.4, 0, 1, 1);
}

@keyframes dotFloating {
  0% {
    left: calc(-50% - 5px);
  }
  75% {
    left: calc(50% + 105px);
  }
  100% {
    left: calc(50% + 105px);
  }
}

@keyframes dotFloatingBefore {
  0% {
    left: -50px;
  }
  50% {
    left: -12px;
  }
  75% {
    left: -50px;
  }
  100% {
    left: -50px;
  }
}

@keyframes dotFloatingAfter {
  0% {
    left: -100px;
  }
  50% {
    left: -24px;
  }
  75% {
    left: -100px;
  }
  100% {
    left: -100px;
  }
}

`