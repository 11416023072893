import { IsOptional } from 'class-validator'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { DateUtils } from 'common/utils/DateUtils'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'

import { IsString } from 'common/validation/decorators/IsString'
import { ISaveBatchSaleReqDTO } from 'modules/batch/services/dtos/requests/ISaveBatchSaleReqDTO'

export class BatchSaleFormValidator extends FormModelValidator {

    @IsNotEmpty()
    bags: number

    @IsNotEmpty()
    @IsString()
    buyer: string

    @IsNotEmpty()
    date: string

    @IsOptional()
    @IsString()
    notes: string

    @IsNotEmpty()
    unitValue: number

    @IsOptional()
    paymentDate?: string

    constructor(dto?: ISaveBatchSaleReqDTO) {
        super()
        Object.assign(this, dto)
        if (dto) {
            this.date = DateUtils.getFormatted(dto.date, DateFormatEnum.BR_WITHOUT_TIME)
            this.paymentDate = dto.paymentDate ? DateUtils.getFormatted(dto.paymentDate, DateFormatEnum.BR_WITHOUT_TIME) : undefined
            this.bags = dto.bags
            this.unitValue = +dto.unitValue
            this.notes = dto.notes
            this.buyer = dto.buyer
        }
    }
}