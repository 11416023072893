import styled from 'styled-components'
import React from 'react'
import { RadioGroupCP, RadioOptionProps } from 'common/components/radio-group/RadioGroupCP'
import { InvoiceStatusEnum } from 'modules/financial/enums/InvoiceStatusEnum'
import { InvoiceTypeEnum } from 'modules/financial/enums/InvoiceType'
import { FinancialUtils } from 'modules/financial/utils/FinancialUtils'

interface IInvoiceStatusFilterICPProps {
    onChangeStatus: (status: InvoiceStatusEnum) => void
    status?: InvoiceStatusEnum
    type: InvoiceTypeEnum
}

export function InvoiceStatusFilterICP(props: IInvoiceStatusFilterICPProps): JSX.Element {

    function getOptions(): RadioOptionProps[] {
        return Object.values(InvoiceStatusEnum).map(status => ({
            value: status,
            content: FinancialUtils.translateFinancialStatusLabel(status, props.type)
        }))
    }

    return (
        <WrapperSCP>
            <RadioGroupCP
                type={'button'}
                options={getOptions()}
                selected={props.status}
                onChange={props.onChangeStatus}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

