import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/RequestManagerTypes'
import { SystemConfig } from 'config/SystemConfig'
import { IUserLoginRequestDTO } from './services/dtos/requests/IUserLoginRequestDTO'

export class AuthRequests {
    private static readonly newApiUrl = `${SystemConfig.getInstance().newApiUrl}`
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/auth`
    private constructor() {}

    static getUserLoginReqConfig = (
        dto: IUserLoginRequestDTO,
    ): RequestConfigTP<IUserLoginRequestDTO> => ({
        url: `${AuthRequests.newApiUrl}/auth/login`,
        method: HttpMethodEnum.POST,
        params: dto,
        noAuth: true,
    })

    static recoverPassword = (dto: { email: string }): RequestConfigTP => ({
        url: `${AuthRequests.baseApiUrl}/recover-password`,
        method: HttpMethodEnum.POST,
        params: dto,
        noAuth: true,
    })

    // static changePasswordFirstAcess = (
    //     dto: IUserChangePasswordFirstAccessReqDTO,
    // ): RequestConfigTP<IUserChangePasswordFirstAccessReqDTO> => ({
    //     url: `${AuthRequests.baseApiUrl}/change-password`,
    //     method: HttpMethodEnum.PUT,
    //     params: dto,
    // })
}
