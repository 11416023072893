/**
 * ENUM
 * Determina formatos de exibicao de data/hora utilizados no sistema.
 *
 * @author hjcostabr
 */
export enum DateFormatEnum {
    BR_WITH_TIME_H_M = 'DD/MM/YYYY - HH:mm',
    BR_WITH_TIME_H_M_S = 'DD/MM/YYYY [às] HH:mm:ss',
    BR_WITHOUT_TIME = 'DD/MM/YYYY',
    BR_DAY_MONTH = 'DD/MM',
    BR_MONTH_YEAR = 'MM/YYYY',
    BR_EXT_MONTH_YEAR = 'MMM/YYYY',
    BR_TIME = 'HH:mm',
    INTERNATIONAL_WITHOUT_TIME = 'MM/DD/YYYY',
    EUA_WITHOUT_TIME = 'YYYY-MM-DD',
    EUA_WITH_TIME_H_M = 'YYYY-MM-DD - HH:mm',
}
