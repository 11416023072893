import { PlusOutlined } from '@ant-design/icons'
import { PaginationConfig } from 'antd/lib/pagination'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { ManeuverActivitiesIcon } from 'common/components/icons/ManeuverActivitiesIcon'
import { ListActions } from 'common/components/list/inner/ListActions'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { ManeuverDrawerCP } from 'modules/maneuver/components/maneuver-drawer/ManeuverDrawerCP'
import { ManeuverListCP } from 'modules/maneuver/components/maneuver-list/ManeuverListCP'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import styled from 'styled-components'
import { ActivityStatusEnum } from '../enums/ActivityStatusEnum'
import { ISearchManeuverRequestDTO } from '../services/dtos/requests/ISearchManeuverRequestDTO'
const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchManeuverRequestDTO = {
    status: ActivityStatusEnum.IN_PROGRESS,
    title: undefined,
    code: undefined,
    propertyCode: undefined,
    fieldCode: undefined,
    type: undefined,
    season: moment().year(),
    page: DEFAULT_PAGINATION.current,
    itemsPerPage: DEFAULT_PAGINATION.pageSize
}
export function ManeuverMainScreen(): JSX.Element {
    const [showFormDrawer, setShowFormDrawer] = useState<boolean>(false)

    return (
        <LayoutCP
            header={
                <HeaderCP icon={<ManeuverActivitiesIcon color={'white'} />} title={'Atividades Agrícolas'}>
                    <ButtonCP
                        icon={<PlusOutlined />}
                        size={'middle'}
                        type={'ghost'}
                        onClick={() => setShowFormDrawer(true)}
                    >
                        Novo
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>

                    <ManeuverListCP />
                    <ManeuverDrawerCP
                        show={showFormDrawer}
                        onCancel={() => setShowFormDrawer(false)}
                        onSuccess={(recipeData) => {
                            SearchActions.setFilter<ISearchManeuverRequestDTO>({
                                ...DEFAULT_FILTERS,
                                status: ActivityStatusEnum.NOT_INITIATED,
                                title: recipeData?.title
                            }, FilterTypesEnum.MANEUVER)
                            setShowFormDrawer(false)
                        }}
                    />
                </ContentCP>
            }
        />
    )
}

const TotalSCP = styled.div`
    margin-left: 3px;
        display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      
   
    }
    @media(max-width: 768px){        
    margin-top: 7px;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`