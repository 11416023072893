import React, { useState, useEffect } from 'react'
import { Popover } from 'antd'
import styled from 'styled-components'

interface IPopOverCPProps {
    children: JSX.Element
    content?: JSX.Element | false
    title?: string
    visible?: boolean
    trigger?: 'hover' | 'click' | 'focus'
    placement?: 'left' | 'right' | 'top' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom'
    onClose?: () => void
}

/**
 * COMPONENTE
 * Exibe modal posicionado rente ao conteudo envolto pelo componente.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function PopOverCP(props: IPopOverCPProps): JSX.Element {

    const [visible, setVisible] = useState<boolean>(false)

    useEffect(onVisiblePropChange, [props.visible])

    function onVisiblePropChange() {
        if (props.visible !== undefined)
            setVisible(props.visible)
    }

    function onVisibilityStateChange(_visible: boolean): void {
        setVisible(_visible)
        if (!_visible && !!props.onClose)
            props.onClose()
    }

    return (
        <PopoverSCP
            content={props.content}
            title={props.title}
            visible={visible}
            trigger={props.trigger ?? 'contextMenu'}
            placement={props.placement ?? 'top'}
            onVisibleChange={onVisibilityStateChange}
        >
            {props.children}
        </PopoverSCP>
    )
}
const PopoverSCP = styled(Popover)`
  `


