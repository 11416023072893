import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'

export class BatchPresentationRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/batches`

    private constructor() { }

    static getBasicInfo = (code: number): RequestConfigTP => ({
        url: `${BatchPresentationRequests.baseApiUrl}/${code}/presentation/resume`,
        method: HttpMethodEnum.GET,
    })

    static getProcessesResume = (code: number): RequestConfigTP => ({
        url: `${BatchPresentationRequests.baseApiUrl}/${code}/presentation/processes`,
        method: HttpMethodEnum.GET,
    })

    static getAnalysisResume = (code: number): RequestConfigTP => ({
        url: `${BatchPresentationRequests.baseApiUrl}/${code}/presentation/analysis`,
        method: HttpMethodEnum.GET,
    })

    static getProducerResume = (code: number): RequestConfigTP => ({
        url: `${BatchPresentationRequests.baseApiUrl}/${code}/presentation/producer`,
        method: HttpMethodEnum.GET,
    })

    static getAllPictures = (code: number): RequestConfigTP => ({
        url: `${BatchPresentationRequests.baseApiUrl}/${code}/presentation/pictures`,
        method: HttpMethodEnum.GET,
    })

}
