import { faCheckDouble, faList } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CardFooterICP } from 'common/components/list/inner/CardFooterICP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { CardListItemICP } from 'common/components/list/inner/CardListItemICP'
import { RecipeUtils } from 'modules/maneuver/utils/RecipeUtils'
import { ManeuverCardContenICP } from 'modules/maneuver/components/maneuver-list/inner/ManeuverCardContenICP'
import { ISearchManeuverResponseDTO } from 'modules/maneuver/services/dtos/responses/ISearchManeuverResponseDTO'
import { ManeuverUtils } from 'modules/maneuver/utils/ManeuverUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import { ManeuverRoutes } from 'modules/maneuver/ManeuverRoutes'
import { BatchCardSkeletonICP } from 'modules/batch/components/batch-list/inner/BatchCardSkeletonICP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { ManeuverRequests } from 'modules/maneuver/services/ManeuverRequests'
import { ManeuverDrawerCP } from 'modules/maneuver/components/maneuver-drawer/ManeuverDrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { IConcludeManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/IConcludeManeuverRequestDTO'
import { ModalCP } from 'common/components/modal/ModalCP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
interface IManeuverCardICPProps {
    item: ISearchManeuverResponseDTO
    loading: boolean
}

export function ManeuverCardICP(props: IManeuverCardICPProps): JSX.Element {
    const history = useHistory()
    const [showFormDrawer, setShowFormDrawer] = useState<boolean>(false)
    const [conclusionDate, setConclusionDate] = useState<string>(DateUtils.formatDate(moment().format(), DateFormatEnum.BR_WITHOUT_TIME))
    const [concludeModalVisible, setConcludeModalVisible] = useState<boolean>(false)

    const markAsConcludedReq = useRequest()
    useEffect(onRequestChange, [markAsConcludedReq.awaiting])

    const deleteReq = useRequest(ManeuverRequests.delete(props.item.code))
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    function onRequestChange(): void {

        if (markAsConcludedReq.awaiting || !markAsConcludedReq.tried)
            return

        if (!markAsConcludedReq.success) {
            if (markAsConcludedReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', markAsConcludedReq.returnData, markAsConcludedReq.error)
            return RequestUtils.showDefaultErrorNotification(markAsConcludedReq.error, 'Erro ao buscar detalhes do manejo!')
        }
        ListActions.mustUpdate()
        setConcludeModalVisible(false)
    }

    function onDeleteReqChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success) {
            if (deleteReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteReq.returnData, deleteReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Erro remover item!')
        }
        ListActions.mustUpdate()
    }

    function concludeManeuver(): void {
        if (!conclusionDate)
            return

        const dto: IConcludeManeuverRequestDTO = {
            date: DateUtils.getDateFormattedToApi(conclusionDate)
        }

        markAsConcludedReq.runRequest(ManeuverRequests.markAsConcluded(props.item.code, dto))
    }
    if (!props.item)
        return <BatchCardSkeletonICP />

    const fieldOrPropertyName = props.item.field ?
        props.item.field.name :
        props.item.property ?
            props.item.property.name :
            'Talhão/Propriedade'

    return (
        <WrapperSCP>

            <CardListItemICP
                key={props.item.code}
                item={props.item}
                loading={props.loading}
                customSkeleton={<BatchCardSkeletonICP />}
                header={
                    <CardHeaderICP
                        highlight={ManeuverUtils.getRecipeTypeIcon(props.item.type)}
                        title={`#${props.item.code} - ${props.item.recipeTitle}`}
                        categoryTitle={`${RecipeUtils.translateType(props.item.type)}: ${fieldOrPropertyName}`}
                        actions={props.item.realEndDate ? <FontAwsomeIconCP icon={faCheckDouble} color={CostumizedThemeConfig.successColor} /> : [
                            {
                                label: 'Editar',
                                action: (code) => setShowFormDrawer(true)
                            },
                            {
                                label: 'Concluir',
                                action: (code) => setConcludeModalVisible(true)
                            }
                        ]}
                        itemCode={props.item.code}
                    />
                }
                content={
                    <ManeuverCardContenICP
                        recipeType={props.item.type}
                        startDate={moment(props.item.startDate).format('DD/MM/YYYY')}
                        estimateEndDate={moment(props.item.estimatedEndDate).format('DD/MM/YYYY')}
                        realEndDate={props.item.realEndDate ? moment(props.item.realEndDate).format('DD/MM/YYYY') : undefined}
                        lastLogbookEntry={props.item.lastLogbookEntry}
                        season={props.item.season}
                        priority={props.item.priority}
                    />
                }
                footer={
                    <CardFooterICP
                        onDelete={() => deleteReq.runRequest()}
                        deleteLoading={false}
                        confirmLabel={'Tem certeza que deseja remover esta atividade?'}
                    >
                        <ActionsSCP>
                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px' }}
                                onClick={() => history.push(`${ManeuverRoutes.ROOT}/logbook/${props.item.code}`)}
                                icon={<FontAwsomeIconCP icon={faList} style={{ margin: '0 5px' }} />}
                            >
                                Ver diário de bordo
                            </ButtonCP>
                        </ActionsSCP>
                    </CardFooterICP>
                }
            />
            <ModalCP
                visible={concludeModalVisible}
                onClose={() => setConcludeModalVisible(false)}
                title={`Concluir item #${props.item.code}`}
                noFooter={true}
            >

                <POWrapperSCP>
                    <InputCP
                        label={'Data'}
                        placeholder={'__/__/____'}
                        onChange={setConclusionDate}
                        value={conclusionDate}
                        type={'date'}
                        required={true}
                        mask={MaskUtils.applyDateMask}
                    />
                    <ButtonCP
                        type={'primary'}
                        icon={<FontAwsomeIconCP icon={faCheckDouble} />}
                        onClick={concludeManeuver}
                        loading={markAsConcludedReq.awaiting}
                    >
                        Concluir
                    </ButtonCP>
                </POWrapperSCP>

            </ModalCP>
            <ManeuverDrawerCP
                maneuverCode={props.item.code}
                recipeType={props.item.type}
                show={showFormDrawer}
                onCancel={() => setShowFormDrawer(false)}
                onSuccess={() => ListActions.mustUpdate()}
            />
        </WrapperSCP>
    )
}

const SubTitleSCP = styled.div`
    display: grid;
    grid-template-columns: .6fr 1fr;
    width: 100%;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }

`
const ActionsSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const WrapperSCP = styled.div`
    min-heigth: 367px;
`

const POWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .ant-btn{
        margin-left: 10px;
        margin-bottom: 5px;
        min-width: 150px;
    }
    
`