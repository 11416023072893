import { AltitudeIconCP } from 'common/components/icons/AltitudeIconCP'
import { CalendarIconCP } from 'common/components/icons/CalendarIconCP'
import { CoffeeVarietyIconCP } from 'common/components/icons/CoffeeVarietyIconCP'
import { HarvestIconCP } from 'common/components/icons/HarvestIconCP'
import { MethodIconCP } from 'common/components/icons/MethodIconCP'
import { ProcessIconCP } from 'common/components/icons/ProcessIconCP'
import { ScoreIconCP } from 'common/components/icons/ScoreIconCP'
import { StructureIconCP } from 'common/components/icons/StructureIconCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import React from 'react'
import styled from 'styled-components'
import { BatchPropertyItemICP } from './inner/BatchPropertyItemICP'

interface IBatchDetailsIconGridCPProps {
    code: number
    methods: string[]
    bags: number
    varieties: string[]
    processMethods: string[]
    harvestTypes: string[]
    structures: string[]
    averageScore: number
    postHarvestDays: number
    altitude: { min: number, max: number }
}

export function BatchDetailsIconGridCP(props: IBatchDetailsIconGridCPProps): JSX.Element {
    const exceptions = [5755, 4570, 4571]
    const replacement = ['NATURAL']
    const scoreText = !!props.averageScore && props.averageScore >= 80
        ? `${MaskUtils.applyNumberMask(props.averageScore)}`
        : props.averageScore === null ? 'noAnalysis' : 'notSpecial'

    return (
        <WrapperSCP>

            <BatchPropertyItemICP
                icon={<CoffeeVarietyIconCP />}
                label={'variety.plur'}
                value={[...props.varieties]}
            />

            <BatchPropertyItemICP
                icon={<AltitudeIconCP />}
                label={'Altitude'}
                value={[`${props.altitude.min}m - ${props.altitude.max}m`]}
                noTranslationLabel={true}
                noTranslationValue={true}
            />
            {
                !!props.harvestTypes.length &&
                <BatchPropertyItemICP
                    icon={<HarvestIconCP />}
                    label={'harvestType.plur'}
                    value={[...props.harvestTypes]}
                />

            }

            {
                !exceptions.includes(props.code) ?
                    !!props.methods.length &&
                    <BatchPropertyItemICP
                        icon={<MethodIconCP />}
                        label={'method.plur'}
                        value={[...props.methods]}
                    />
                    :
                    <BatchPropertyItemICP
                        icon={<MethodIconCP />}
                        label={'method.plur'}
                        value={replacement}
                    />
            }

            <BatchPropertyItemICP
                icon={<ProcessIconCP />}
                label={'processMethod.plur'}
                value={[...props.processMethods]}
            />

            <BatchPropertyItemICP
                icon={<StructureIconCP />}
                label={'structure.plur.uppercase'}
                value={props.structures}
            />

            <BatchPropertyItemICP
                icon={<ScoreIconCP />}
                label={'score'}
                noTranslationValue={!!props.averageScore && props.averageScore >= 80}
                value={[scoreText]}

            />

            <BatchPropertyItemICP
                icon={<CalendarIconCP />}
                label={'postHarvestDays'}
                noTranslationValue={true}
                value={[MaskUtils.applyNumberMask(props.postHarvestDays)]}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    margin: 10px 0;
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`