import { AnalysisCarrouselCP } from 'modules/batch-presentation/components/analysis-carrousel/AnalysisCarrouselCP'
import { BatchPhotoGalleryCP } from 'modules/batch-presentation/components/batch-photo-gallery/BatchPhotoGalleryCP'
import { ProcessCarrouselCP } from 'modules/batch-presentation/components/process-carrousel/ProcessCarrouselCP'
import { ProducerCarrouselCP } from 'modules/batch-presentation/components/producer-carrousel/ProducerCarrouselCP'
import React from 'react'
import styled from 'styled-components'

interface IBatchPresentationContentCPProps {
}

export function BatchPresentationContentCP(props: IBatchPresentationContentCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <ProcessCarrouselCP />
            <AnalysisCarrouselCP />
            <ProducerCarrouselCP />
            <BatchPhotoGalleryCP />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor}
`
