import React, { useState } from 'react'
import styled from 'styled-components'
import { LogoCP } from 'common/components/logo/LogoCP'
import { CollapseTriggerCP } from 'modules/app/components/main-menu-sider/inner/CollapseTriggerCP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { BannerUpdateProfileCP } from 'common/components/banner-update-profile/BannerUpdateProfileCP'
import { faUserShield } from '@fortawesome/free-solid-svg-icons'
import { PrivacyPolicyCP } from 'common/components/privacy-policy/PrivacyPolicyCP'
import { useSelector } from 'react-redux'
import { LoggedUserDataTP } from 'common/redux/ReduxTypes'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
interface IMainHeaderCPProps {
    isMobile?: boolean
    onCollapseMenu?: () => void
}

export function MainHeaderCP(props: IMainHeaderCPProps): JSX.Element {
    const loggedUser: LoggedUserDataTP = useSelector((state: IReduxState) => _.get(state, 'loggedUser'))
    const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState<boolean>(false)

    return (
        <WrapperSCP>
            <HeaderSCP>
                <MainInfoWrapper>
                    <LogoWrppSCP>
                        <LogoCP height={35} onlySymbol={props.isMobile} />
                        {
                            props.isMobile &&
                            <CollapseTriggerCP isMobile={true} onClick={props.onCollapseMenu} />
                        }
                    </LogoWrppSCP>
                    <PrivacyPolicyInfoSCP>
                        <FontAwsomeIconCP
                            icon={faUserShield}
                            onClick={() => setPrivacyPolicyVisible(true)}
                            tooltip={'Política de Privaciadade'}
                        />
                    </PrivacyPolicyInfoSCP>
                </MainInfoWrapper>
                <HeaderBackground />
                <PrivacyPolicyCP
                    show={privacyPolicyVisible}
                    onCancel={() => setPrivacyPolicyVisible(false)}
                />
            </HeaderSCP>
        </WrapperSCP>
    )
}

const HeaderSCP = styled.div`
    position: relative; 
`

const WrapperSCP = styled.div` 
        
`
const MainInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 52px;
    min-height: 45px;
    @media(max-width: 768px){
        min-height: 25px;
        margin: 0 8px;
    }

    
`
const HeaderBackground = styled.div`
    position: absolute;   
    height: 230px;
    width: 100%;
    background: ${props => props.theme.primaryColor};
    &::before{
        position: absolute;
        content: '';
        background-image: url('/assets/element-curves-1.svg');
        width: 500px;
        height: 500px;
        left: -10em;
        bottom: -5em;
    }
    &::after{
        position: absolute;
        content: '';
        background-image: url('/assets/element-curves-2.svg');
        width: 340px;
        height: 440px;
        right: 0;
        bottom: -16em;
    }
    @media(max-width: 768px){
        height: 70px;
        &::before{
           display: none;
        }
        &::after{
           display: none;
        }
    }

`

const LogoWrppSCP = styled.div`
    display: flex;
    align-items: center;
`

const PrivacyPolicyInfoSCP = styled.div`
    svg {
        color: ${props => props.theme.primaryColor};
        font-size: 20px;
    }
   
`
