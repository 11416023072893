import React, { useState, useEffect } from 'react'
import { FileUtils } from 'common/utils/FileUtils'
import { NotificationHelper } from 'common/NotificationHelper'
import { ProfileImageUploaderCP } from 'common/components/profile-image-uploader/ProfileImageUploaderCP'
import { useRequest } from 'common/request-helper/UseRequest'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { IPersonResponseDTO } from 'modules/person/services/dtos/responses/IPersonResponseDTO'
import { IAuthUserResponseDTO } from 'modules/auth/services/dtos/responses/IAuthUserResponseDTO'
import { RequestUtils } from 'common/request-helper/RequestUtils'

import { AuthActions } from 'modules/auth/AuthActions'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { ButtonCP } from 'common/components/button/ButtonCP'

import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { LoggedUserDataTP } from 'common/redux/ReduxTypes'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import styled from 'styled-components'
import { ISearchPersonRequestDTO } from 'modules/person/services/dtos/requests/ISearchPersonRequestDTO'
import { UpdateUserFormValidator } from 'modules/app/components/user-profile-settings/inner/UpdateUserFormValidator'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { IUpdateUserReqDTO } from 'modules/person/services/dtos/requests/IUpdateUserReqDTO'
import { MaskUtils } from 'common/utils/MaskUtils'

interface IUserProfileDrawerCPProps {
    visible: boolean
    onClose: () => void
}

const MAX_FILE_SIZE = 5

export function UserProfileDrawerCP(props: IUserProfileDrawerCPProps): JSX.Element {
    const loggedUserAuth: LoggedUserDataTP = useSelector((state: IReduxState) => _.get(state, 'loggedUser'))

    const [formValidator, setFormValidator] = useState<UpdateUserFormValidator>(new UpdateUserFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const [showUploadLoading, setUploadLoading] = useState(false)
    const [imgFile, setImageFile] = useState<File>()
    const [imgUrl, setImgUrl] = useState<string>()

    useEffect(() => { getDetails() }, [loggedUserAuth, props.visible])

    const getPersonRequest = useRequest<IPersonResponseDTO, ISearchPersonRequestDTO>()
    useEffect(onGetDetailsReqChange, [getPersonRequest.awaiting])

    const saveImageReq = useRequest<{ url: string }>()
    // useEffect(() => setImgUrl(loggedUserAuth.user.picture), [loggedUserAuth.])
    useEffect(onRequestChange, [saveImageReq.awaiting])

    const saveUserReq = useRequest<IPersonResponseDTO>()
    useEffect(onUserReqRequestChange, [saveUserReq.awaiting])

    async function getDetails(): Promise<void> {
        if (props.visible)
            await getPersonRequest.runRequest(PersonRequests.getPerson(loggedUserAuth.user_id))
    }

    function onGetDetailsReqChange(): void {
        if (getPersonRequest.awaiting || !getPersonRequest.tried)
            return

        if (!getPersonRequest.success || !getPersonRequest.returnData) {
            if (getPersonRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getPersonRequest.returnData, getPersonRequest.error)
            return RequestUtils.showDefaultErrorNotification(getPersonRequest.error, 'Erro ao buscar pessoa!')
        }

        setFormValidator(new UpdateUserFormValidator({
            name: getPersonRequest.returnData.name,
            email: getPersonRequest.returnData.email,
            phone: getPersonRequest.returnData.phone
        }))
    }

    async function saveImage(): Promise<void> {
        if (!imgFile)
            return

        const formData = new FormData()
        formData.append('profilePicture', imgFile)

        await saveImageReq.runRequest(PersonRequests.saveProfilePicture(loggedUserAuth.user_id, formData))
    }

    function onRequestChange(): void {
        if (saveImageReq.awaiting || !saveImageReq.tried)
            return

        if (!saveImageReq.success || !saveImageReq.returnData)
            NotificationHelper.error('Falha ao  salvar imagem')
    }

    async function saveUser(): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return

        if (imgFile)
            await saveImage()

        const dto: IUpdateUserReqDTO = {
            name: formValues.name,
            phone: formValues.phone,
            email: formValues.email,
        }
        saveUserReq.runRequest(PersonRequests.updatePersonProfile(loggedUserAuth.user_id, dto))
    }

    function onUserReqRequestChange(): void {

        if (saveUserReq.awaiting || !saveUserReq.tried)
            return

        if (!saveUserReq.success || !saveUserReq.returnData) {
            if (saveUserReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveUserReq.returnData, saveUserReq.error)
            return RequestUtils.showDefaultErrorNotification(saveUserReq.error, 'Erro ao buscar dados do usuário!')
        }

        AuthActions.logUserIn(loggedUserAuth)
        NotificationHelper.success('Dados alterados com sucesso!')

        props.onClose()
    }

    function validateImage(file: File): boolean {

        const allowedTypes = ['image/jpeg', 'image/png']
        if (!allowedTypes.includes(file.type)) {
            NotificationHelper.error('Apenas JPEG e PNG são permitidos')
            return false
        }

        const isLt5M = file.size / 1024 / 1024 < MAX_FILE_SIZE
        if (!isLt5M) {
            NotificationHelper.error('Somente é permitido arquivos menores que 5 MB')
            return false
        }

        FileUtils.getBase64(file, imageUrl => {
            setUploadLoading(true)
            setImgUrl(imageUrl)
        })

        return false
    }

    return (
        <DrawerCP
            onClose={props.onClose}
            title={'Editar perfil'}
            visible={props.visible}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        size={'large'}
                        onClick={saveUser}
                        wrapperStyle={{ width: '100%' }}
                        loading={getPersonRequest.awaiting || saveUserReq.awaiting || saveImageReq.awaiting}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >
            <WrapperSCP>
                <ProfileImageUploaderCP
                    imageUrl={imgUrl}
                    fileTypes={'.png, .jpg'}
                    multiple={false}
                    showUploadList={false}
                    loading={saveUserReq.awaiting || saveImageReq.awaiting}
                    onChange={(info) => setImageFile(info.file)}
                    beforeUpload={validateImage}
                    instructions={'A imagem deve estar no formato .JPG ou PNG e não deve conter mais de 5MB.'}
                    style={{ width: '400px', height: '400px' }}
                />

                <WrapperInputsSCP>
                    <InputCP
                        label={'Nome'}
                        fieldName={'name'}
                        formStateManager={formStateManager}
                        required={true}
                        loading={getPersonRequest.awaiting || saveUserReq.awaiting || saveImageReq.awaiting}
                    />
                    <InputCP
                        label={'Telefone'}
                        fieldName={'phone'}
                        mask={MaskUtils.applyPhoneMask}
                        formStateManager={formStateManager}
                        required={true}
                        loading={getPersonRequest.awaiting || saveUserReq.awaiting || saveImageReq.awaiting}
                    />
                    <InputCP
                        label={'E-mail'}
                        fieldName={'email'}
                        formStateManager={formStateManager}
                        loading={getPersonRequest.awaiting || saveUserReq.awaiting || saveImageReq.awaiting}
                    />
                </WrapperInputsSCP>
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
    width: 100%;
    place-content: center;

    .ant-btn {
        width: 100%; 
    }
`

const WrapperInputsSCP = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
`

const ButtonWrapperSCP = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: center;
    .ant-btn {
        width: 100%;
        justify-content: center;
    }
`