import React, { useEffect } from 'react'
import styled from 'styled-components'
import { AvatarCP } from 'common/components/avatar/AvatarCP'
import { useRequest } from 'common/request-helper/UseRequest'
import { UserRequests } from 'modules/user/services/UserRequests'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { Skeleton } from 'antd'
import { IAuthUserResponseDTO } from 'modules/auth/services/dtos/responses/IAuthUserResponseDTO'

interface IProducerInfoHeaderCPProps {
    producerCode?: number
}

export function ProducerInfoHeaderCP(props: IProducerInfoHeaderCPProps): JSX.Element {
    const request = useRequest<IAuthUserResponseDTO>()

    useEffect(getProducerInfo, [props.producerCode])
    useEffect(onRequestChange, [request.awaiting])

    function getProducerInfo(): void {
        if (!props.producerCode)
            return
        request.runRequest(UserRequests.findOne(props.producerCode))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar dados do produtor!')
        }
    }

    if (!request.returnData || !props.producerCode) {
        return (
            <PropertyDetailsWrapperSCP>
                <Skeleton.Avatar size={150} active={true} shape={'circle'} style={{ background: '#a153c1' }} />
                <Skeleton.Input style={{ width: 200, marginTop: '10px', background: '#a153c1' }} active={true} size={'small'} />
            </PropertyDetailsWrapperSCP>
        )
    }

    return (
        <PropertyDetailsWrapperSCP>
            <AvatarCP size={150} src={request.returnData.picture} />
            <ProducerNameWrapperSCP>{request.returnData.name}</ProducerNameWrapperSCP>
            <span style={{ fontStyle: 'italic', color: '#fff' }}>Producer</span>
        </PropertyDetailsWrapperSCP>
    )
}
const PropertyDetailsWrapperSCP = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const ProducerNameWrapperSCP = styled.span`
    font-size: 18px;
    font-weight: 600;
    color: #fff;
`
