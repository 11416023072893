import { CompanyScreen } from 'modules/company/screens/CompanyScreen'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

CompanyRoutes.ROOT = '/empresas'

export function CompanyRoutes(): JSX.Element {

    return (
        <Switch>
            <Route path={CompanyRoutes.ROOT} exact={true} component={CompanyScreen} />
        </Switch>
    )
}
