import { PlusOutlined } from '@ant-design/icons'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { PaginationConfig } from 'antd/lib/pagination'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import _ from 'lodash'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { useSelector } from 'react-redux'
import { HavestPlanningDrawerCP } from 'modules/harvest-planning/components/harvest-planning-drawer/HarvestPlanningDrawerCP'
import { HarvestPlanningListCP } from 'modules/harvest-planning/components/harvest-planning-list/HarvestPlanningListCP'
import { ISearchHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISearchHarvestPlanningReqDTO'
import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'

export function HarvestPlanningScreen(): JSX.Element {

    const [harvestPlanningDrawerVisible, setHarvestPlanningDrawerVisible] = useState<boolean>(false)


    return (
        <LayoutCP
            header={
                <HeaderCP icon={<FontAwsomeIconCP icon={faClipboardList} />} title={'Colheita'}>
                    <ButtonCP
                        icon={<PlusOutlined />}
                        size={'middle'}
                        type={'ghost'}
                        onClick={() => setHarvestPlanningDrawerVisible(true)}
                    >
                        Novo
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <HarvestPlanningListCP />

                    <HavestPlanningDrawerCP
                        show={harvestPlanningDrawerVisible}
                        onCancel={() => setHarvestPlanningDrawerVisible(false)}
                        onSuccess={() => setHarvestPlanningDrawerVisible(false)}
                    />

                </ContentCP>
            }
        />
    )
}

