import { ButtonCP } from 'common/components/button/ButtonCP'
import { CardFooterICP } from 'common/components/list/inner/CardFooterICP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { CardListItemICP } from 'common/components/list/inner/CardListItemICP'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BatchCardSkeletonICP } from 'modules/batch/components/batch-list/inner/BatchCardSkeletonICP'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { NotificationHelper } from 'common/NotificationHelper'
import { IImplementResponseDTO } from 'modules/maneuver/services/dtos/responses/IImplementResponseDTO'
import { ImplementRequests } from 'modules/maneuver/services/ImplementRequests'
import { ManeuverContext, ManeuverRoutes } from 'modules/maneuver/ManeuverRoutes'
import { ImplementCardContentICP } from 'modules/maneuver/components/implement-list/inner/ImplementCardContentICP'
import { ManeuverEntityTypeEnum } from 'modules/maneuver/enums/ManeuverEntityTypeEnum'
import { ImplementTypeEnum } from 'modules/maneuver/enums/ImplementTypeEnum'
import { faScrewdriver } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { MaintenanceDrawerCP } from 'modules/maneuver/components/maintenance-drawer/MaintenanceDrawerCP'
import { useHistory } from 'react-router-dom'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ImplementDrawerCP } from '../../implement-drawer/ImplementDrawerCP'

interface IImplementCardICPProps {
    implement: IImplementResponseDTO
    loading: boolean
}

export function ImplementCardICP(props: IImplementCardICPProps): JSX.Element {
    const impType: ManeuverEntityTypeEnum = props.implement.type === ImplementTypeEnum.EQUIPMENT
        ? ManeuverEntityTypeEnum.EQUIPMENT
        : ManeuverEntityTypeEnum.VEHICLE

    const [showFormDrawer, setShowFormDrawer] = useState<boolean>(false)
    const history = useHistory()

    const [maintenanceDrawerVisible, setMaintenanceDrawerVisible] = useState<boolean>(false)

    const { reload, toggleItemDetail, toggleReload } = useContext(ManeuverContext)
    const deleteReq = useRequest(ImplementRequests.deleteImplement(props.implement.code))
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    function onDeleteReqChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success || !deleteReq.returnData)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Ocorreu um erro ao remover este item. Tente novamente.')

        NotificationHelper.success('Equipamento removido com sucesso')

    }

    if (!props.implement || props.loading)
        return <BatchCardSkeletonICP />

    return (
        <WrapperSCP>

            <CardListItemICP
                key={props.implement.code}
                item={props.implement}
                loading={props.loading}
                customSkeleton={<BatchCardSkeletonICP />}
                header={
                    <CardHeaderICP
                        highlight={`#${props.implement.code}`}
                        title={`${props.implement.brand} - ${props.implement.model}`}
                        itemCode={props.implement.code}
                        actions={
                            [
                                {
                                    label: 'Editar',
                                    action: (itemCode) => setShowFormDrawer(true)
                                }
                            ]

                        }
                    />
                }
                content={
                    <ImplementCardContentICP
                        fuelName={props.implement.fuelName}
                        currentHourMeter={props.implement.currentHourMeter}
                        capacity={props.implement.capacity}
                        year={props.implement.year}
                        lastRevisionDate={props.implement.lastRevisionDate}
                    />

                }
                footer={
                    <CardFooterICP
                        onDelete={deleteReq.runRequest}
                        deleteLoading={false}
                        confirmLabel={'Tem certeza que quer excluir este produto?'}
                    >
                        <ActionsSCP>

                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px' }}
                                tooltip={'Nova manutenção'}
                                onClick={() => setMaintenanceDrawerVisible(true)}
                                icon={<FontAwsomeIconCP icon={faScrewdriver} style={{ margin: '0 5px' }} />}
                            />
                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px', height: '100%' }}
                                onClick={() => history.push(`${ManeuverRoutes.ROOT}/manutencoes/${props.implement.code}`)}
                            >
                                Ver manutenções
                            </ButtonCP>
                        </ActionsSCP>
                    </CardFooterICP>
                }
            />

            <MaintenanceDrawerCP
                show={maintenanceDrawerVisible}
                onCancel={() => setMaintenanceDrawerVisible(false)}
                implementCode={props.implement.code}
                onSuccess={() => {
                    setMaintenanceDrawerVisible(false)
                }}
            />

            <ImplementDrawerCP
                show={showFormDrawer}
                onCancel={() => setShowFormDrawer(false)}
                onSuccess={() => ListActions.mustUpdate()}
                implementCode={props.implement.code}
                type={props.implement.type}
            />

        </WrapperSCP>
    )
}

const ActionsSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const WrapperSCP = styled.div`
  
`

const POWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .ant-btn{
        margin-left: 10px;
        margin-bottom: 5px;
        min-width: 150px;
    }
    
`