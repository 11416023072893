import { IsString, NotContains } from 'class-validator'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { FormModelValidator } from '../../../../../common/form-state-manager/classes/FormModelValidator'

export class UserLoginFormValidator extends FormModelValidator {

    @IsString()
    @NotContains(' ')
    @IsNotEmpty()
    password: string

    @IsNotEmpty()
    username: string

    constructor(data?: any) {
        super(data)
    }
}
