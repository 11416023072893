import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { BatchSaleFormValidator } from 'modules/batch/components/batch-sale-form-drawer/inner/BatchSaleFormValidator'
import { BatchSearchSelectorCP } from 'modules/batch/components/batch-search-selector/BatchSearchSelectorCP'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { ISaveBatchSaleReqDTO } from 'modules/batch/services/dtos/requests/ISaveBatchSaleReqDTO'
import { IBatchSaleResDTO } from 'modules/batch/services/dtos/responses/IBatchSaleResDTO'
import { InvoiceCategorySelectorCP } from 'modules/financial/components/invoice-category-selector/InvoiceCategorySelectorCP'
import { GenericInvoiceFormICP } from 'modules/financial/components/invoice-form-drawer/inner/GenericInvoiceFormICP'
import { InvoiceFormValidator } from 'modules/financial/components/invoice-form-drawer/inner/InvoiceFormValidator'
import { SaleFormICP } from 'modules/financial/components/invoice-form-drawer/inner/SaleFormICP'
import { InvoiceTypeEnum } from 'modules/financial/enums/InvoiceType'
import { InvoiceCategoryEnum } from 'modules/financial/enums/InvoiceCategoryEnum'
import { ISaveInvoiceReqDTO } from 'modules/financial/services/dtos/request/ISaveInvoiceReqDTO'
import { IInvoiceResDTO } from 'modules/financial/services/dtos/responses/IInvoiceResDTO'
import { FinancialRequests } from 'modules/financial/services/FinancialRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { MaskUtils } from 'common/utils/MaskUtils'
import _ from 'lodash'

interface IInvoiceFormDrawerCPProps {
    type: InvoiceTypeEnum
    code?: number
    show: boolean
    previewData?: ISaveInvoiceReqDTO
    itemsToConsolidate?: number[]
    onCancel: () => void
    onSuccess: () => void
}

export function InvoiceFormDrawerCP(props: IInvoiceFormDrawerCPProps): JSX.Element {
    const [formValidator, setFormValidator] = useState<InvoiceFormValidator>(new InvoiceFormValidator(props.previewData))
    const formStateManager = useFormStateManager(formValidator)

    const [fieldCodes, setFieldCodes] = useState<Array<number | undefined>>([])
    const [batchCode, setBatchCode] = useState<number>()
    const [isPaid, setIsPaid] = useState<boolean>(false)
    const [entityId, setEntityId] = useState<number>()

    const [saleFormValidator, setSaleFormValidator] = useState<BatchSaleFormValidator>(new BatchSaleFormValidator())
    const saleFormStateManager = useFormStateManager(saleFormValidator)

    useEffect(() => setFormValidator(new InvoiceFormValidator(props.previewData)), [props.previewData])

    const invoiceTypeLabel = props.type === InvoiceTypeEnum.REVENUE ? 'receita' : 'despesa'
    const [category, setCategory] = useState<InvoiceCategoryEnum>(InvoiceCategoryEnum.GENERAL)
    useEffect(setDefaultForm, [props.type])

    useEffect(getEntity, [entityId])
    const getSale = useRequest<IBatchSaleResDTO>()
    useEffect(onGetSaleReqChange, [getSale.awaiting])

    useEffect(getDetails, [props.show, props.code])
    const findInvoiceReq = useRequest<IInvoiceResDTO>()
    useEffect(onFindInvoiceReqChange, [findInvoiceReq.awaiting])

    const consolidateReq = useRequest<string>()
    useEffect(onConsolidateReqChange, [consolidateReq.awaiting])

    const saveSaleReq = useRequest<IBatchSaleResDTO>()
    useEffect(onSaveSaleRequestChange, [saveSaleReq.awaiting])

    const saveInvoceReq = useRequest<IInvoiceResDTO>()
    useEffect(onSaveInvoiceRequestChange, [saveInvoceReq.awaiting])

    function getDetails(): void {
        if (!!props.code && !!props.show)
            findInvoiceReq.runRequest(FinancialRequests.findOne(props.code))
    }

    function getEntity(): void {
        if (!entityId)
            return
        if (category === InvoiceCategoryEnum.SALES && !!batchCode)
            getSale.runRequest(BatchRequests.getSale(batchCode, entityId))
    }

    function setDefaultForm(): void {
        if (props.type === InvoiceTypeEnum.EXPENSE)
            setCategory(InvoiceCategoryEnum.GENERAL)
        else
            setCategory(InvoiceCategoryEnum.SALES)
    }

    function markAsPaid(): void {
        if (!!props.itemsToConsolidate && !!props.itemsToConsolidate.length)
            consolidateReq.runRequest(PersonRequests.consolidateStatements(props.itemsToConsolidate))
    }

    async function onFormSubmit(): Promise<void> {
        if (category === InvoiceCategoryEnum.SALES) {
            const formValues = saleFormStateManager.getFormValues()

            saleFormStateManager.setConsiderAllErrors(true)

            if (!await saleFormStateManager.validate() || !formValues)
                return

            if (!batchCode)
                return

            const dto: ISaveBatchSaleReqDTO = {
                date: DateUtils.getDateFormattedToApi(formValues.date),
                bags: +formValues.bags,
                buyer: formValues.buyer,
                isPaid,
                paymentDate: formValues.paymentDate ? DateUtils.getDateFormattedToApi(formValues.paymentDate) : undefined,
                unitValue: +MaskUtils.removeMask(formValues.unitValue),
                notes: formValues.notes
            }

            await saveSaleReq.runRequest(BatchRequests.saveSale(batchCode, dto))
            return
        }

        const formValues = formStateManager.getFormValues()

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveInvoiceReqDTO = {
            date: DateUtils.getDateFormattedToApi(formValues.date),
            description: formValues.description,
            isCredit: props.type === InvoiceTypeEnum.REVENUE,
            isPaid,
            paymentDate: formValues.paymentDate ? DateUtils.getDateFormattedToApi(formValues.paymentDate) : undefined,
            value: +(`${formValues.value}`.replace(',', '.').replace(/[^\d.-]/g, '')),
            fieldCodes: _.compact(fieldCodes),
            season: formValues.season,
        }

        await saveInvoceReq.runRequest(!props.code ?
            FinancialRequests.save(dto)
            : FinancialRequests.update(props.code, dto))
    }

    function onSaveSaleRequestChange(): void {

        if (saveSaleReq.awaiting || !saveSaleReq.tried)
            return

        if (!saveSaleReq.success || !saveSaleReq.returnData) {
            if (saveSaleReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveSaleReq.returnData, saveSaleReq.error)
            return RequestUtils.showDefaultErrorNotification(saveSaleReq.error, 'Erro ao salvar venda!')
        }

        ListActions.mustUpdate()
        props.onSuccess()
        formStateManager.reset()
        saleFormStateManager.reset()
    }

    function onSaveInvoiceRequestChange(): void {

        if (saveInvoceReq.awaiting || !saveInvoceReq.tried)
            return

        if (!saveInvoceReq.success || !saveInvoceReq.returnData) {
            if (saveInvoceReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveInvoceReq.returnData, saveInvoceReq.error)
            return RequestUtils.showDefaultErrorNotification(saveInvoceReq.error, 'Erro ao salvar fatura!')
        }

        if (!!props.itemsToConsolidate && !!props.itemsToConsolidate.length)
            return markAsPaid()

        ListActions.mustUpdate()
        props.onSuccess()
        formStateManager.reset()
        saleFormStateManager.reset()

    }

    function onConsolidateReqChange(): void {

        if (consolidateReq.awaiting || !consolidateReq.tried)
            return

        if (!consolidateReq.success || !consolidateReq.returnData) {
            if (consolidateReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', consolidateReq.returnData, consolidateReq.error)
            return RequestUtils.showDefaultErrorNotification(consolidateReq.error, 'Ocorreu um erro!')
        }

        ListActions.mustUpdate()
        props.onSuccess()
        formStateManager.reset()
        saleFormStateManager.reset()
    }

    function onFindInvoiceReqChange(): void {
        if (findInvoiceReq.awaiting || !findInvoiceReq.tried)
            return

        if (!findInvoiceReq.success || !findInvoiceReq.returnData) {
            if (findInvoiceReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', findInvoiceReq.returnData, findInvoiceReq.error)
            return RequestUtils.showDefaultErrorNotification(findInvoiceReq.error, 'Erro ao buscar fatura!')
        }

        if (findInvoiceReq.returnData.category === InvoiceCategoryEnum.SALES) {
            setEntityId(findInvoiceReq.returnData.entityId)
            setCategory(findInvoiceReq.returnData.category)
            return
        }

        setFormValidator(new InvoiceFormValidator({
            ...findInvoiceReq.returnData,
            date: DateUtils.getFormatted(findInvoiceReq.returnData.date, DateFormatEnum.BR_WITHOUT_TIME),
            paymentDate: findInvoiceReq.returnData.paymentDate ? DateUtils.getFormatted(findInvoiceReq.returnData.paymentDate, DateFormatEnum.BR_WITHOUT_TIME) : undefined
        }))
        if (!!findInvoiceReq.returnData.fields)
            setFieldCodes(findInvoiceReq.returnData.fields.map(fie => fie.code))
    }

    function onGetSaleReqChange(): void {
        if (getSale.awaiting || !getSale.tried)
            return

        if (!getSale.success || !getSale.returnData) {
            if (getSale.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getSale.returnData, getSale.error)
            return RequestUtils.showDefaultErrorNotification(getSale.error, 'Erro ao buscar venda!')
        }

        setSaleFormValidator(new BatchSaleFormValidator(getSale.returnData))
    }

    function onChangeField(fieldCode: number, index: number): void {
        setFieldCodes(fieldCodes.map((item, i) => {
            if (i === index)
                return fieldCode

            return item
        }))

    }

    function onAddField(): void {
        setFieldCodes([...fieldCodes, undefined])
    }

    function onRemoveField(index: number): void {
        setFieldCodes(fieldCodes.filter((data, i) => i !== index))
    }

    return (
        <DrawerCP
            title={!props.code ? `Novo lançamento de ${invoiceTypeLabel}` : `Editar lançamento de ${invoiceTypeLabel} - #${props.code}`}
            visible={props.show}
            width={'40%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFormSubmit}
                        wrapperStyle={{ width: '100%' }}
                        loading={saveInvoceReq.awaiting || saveSaleReq.awaiting || findInvoiceReq.awaiting || consolidateReq.awaiting}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {
                formStateManager.reset()
                saleFormStateManager.reset()
                props.onCancel()
                setBatchCode(undefined)
            }}
        >
            <WrapperSCP>
                {
                    props.type === InvoiceTypeEnum.REVENUE && !props.code &&
                    <InvoiceCategorySelectorCP
                        category={category}
                        onChange={setCategory}
                        disabled={!!props.code}
                    />
                }
                <FormWrapperSCP>
                    {
                        category === InvoiceCategoryEnum.SALES ?
                            <SaleFormWrapperSCP>
                                <BatchSearchSelectorCP
                                    batchCode={batchCode}
                                    setBatchCode={setBatchCode}
                                    loading={saveInvoceReq.awaiting || saveSaleReq.awaiting || findInvoiceReq.awaiting || consolidateReq.awaiting || consolidateReq.awaiting}
                                />
                                <SaleFormICP
                                    formStateManager={saleFormStateManager}
                                    loading={saveInvoceReq.awaiting || saveSaleReq.awaiting || findInvoiceReq.awaiting || consolidateReq.awaiting}
                                    isPaid={isPaid}
                                    markAsPaid={setIsPaid}
                                />
                            </SaleFormWrapperSCP>
                            :
                            <GenericInvoiceFormICP
                                isPaid={isPaid}
                                markAsPaid={setIsPaid}
                                onChangeField={onChangeField}
                                onRemoveField={onRemoveField}
                                onAddField={onAddField}
                                fieldCodes={fieldCodes}
                                formStateManager={formStateManager}
                                loading={saveInvoceReq.awaiting || saveSaleReq.awaiting || findInvoiceReq.awaiting || consolidateReq.awaiting}
                            />
                    }
                </FormWrapperSCP>

            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`

const SaleFormWrapperSCP = styled.div``

const FormWrapperSCP = styled.div`
    margin-top: 10px;
`