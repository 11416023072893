import { faTasks, faPlus } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { RecipeDrawerCP } from 'modules/maneuver/components/recipe-drawer/RecipeDrawerCP'
import { RecipeListCP } from 'modules/maneuver/components/recipe-list/RecipeListCP'
import styled from 'styled-components'
import React, { useState } from 'react'
import { ListActions } from 'common/components/list/inner/ListActions'

export function RecipeScreen(): JSX.Element {
    const [showFormDrawer, setShowFormDrawer] = useState<boolean>(false)

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={'Instruções'}
                    icon={
                        <FontAwsomeIconCP icon={faTasks} />
                    }
                >
                    <ButtonCP
                        icon={<FontAwsomeIconCP icon={faPlus} />}
                        size={'middle'}
                        type={'ghost'}
                        onClick={() => setShowFormDrawer(true)}
                    >
                        Nova
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <RecipeListCP />
                    <RecipeDrawerCP
                        show={showFormDrawer}
                        onCancel={() => setShowFormDrawer(false)}
                        onSuccess={() => {
                            setShowFormDrawer(false)
                            ListActions.mustUpdate()
                        }}
                    />
                </ContentCP>
            }
        />
    )
}

const TotalSCP = styled.div`
    margin-left: 3px;
        display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      
   
    }
    @media(max-width: 768px){
        height: 0;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`