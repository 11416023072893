import cep from 'cep-promise'
import IMask from 'imask'
import React, { useEffect, useState } from 'react'
import { MaskedInput } from './MaskedInput'

const mask = (value: string): string => IMask.createMask({ mask: '00000-000' }).resolve(value)
export function CepInput(props: any): JSX.Element {
    const [zipCode, setZipCode] = useState<string>('')

    useEffect(() => {
        if (!zipCode || zipCode.length < 8)
            return

        props.onSearchZipCode({ loading: true, data: null })
        cep(zipCode)
            .then(response => props.onSearchZipCode({ loading: false, data: response }))
            .catch(err => {
                props.onSearchZipCode({ loading: false, data: {}})
                console.error(err)
            })
    }, [zipCode])

    function onChange(value: any): void {
        setZipCode(value)
        if (props.onChange)
            props.onChange(value)
    }

    return (
        <MaskedInput
            {...props}
            length={9}
            mask={mask}
            onChange={onChange}
        />
    )
}