import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'

import { BatchAnalysesContentCP } from 'modules/batch/components/batch-analyses-content/BatchAnalysesContentCP'
import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

type RouteParamsTP = { code?: string }

export function BatchAnalisesScreen(): JSX.Element {
    const history = useHistory()
    const routeParams = useParams<RouteParamsTP>()

    const [batchCode, setBatchCode] = useState<number>()
    const [batchDrawerVisible, setBatchDrawerVisible] = useState<boolean>(false)

    useEffect(() => {
        if (!routeParams.code)
            return

        setBatchCode(+routeParams.code)
    }, [routeParams])

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={`Análises: Lote #${batchCode ?? '--'}`}
                    icon={
                        <FontAwsomeIconCP icon={faArrowLeft} onClick={() => history.goBack()} size={'1x'} />
                    }
                />
            }
            content={
                <ContentCP>
                    <BatchAnalysesContentCP />
                </ContentCP>
            }
        />
    )
}
