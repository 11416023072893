import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { DatePicker, Input, TimePicker } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'
import { FormItemICP } from 'common/components/form-fields/inner/FormItemICP'
import { IFormItemInputCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'
import { localePtBR } from 'common/components/form-fields/input/inner/LocalePtBR'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import React from 'react'
import { IMaskInput } from 'react-imask'
import styled, { CSSProperties } from 'styled-components'
import { DateFormatEnum } from '../../../enums/DateFormatEnum'
// import IMask from 'imask'

export type InputTypeTP = 'text' | 'email' | 'number' | 'password' | 'search' | 'date' | 'time' | 'tel' | 'datetime-local' | 'currency'

interface IInputCPProps<FModelTP extends FormModelValidator> extends IFormItemInputCommonProps<FModelTP> {
    type?: InputTypeTP
    icon?: JSX.Element
    min?: number
    max?: number
    step?: number
    maxLength?: number
    loading?: boolean
    mask?: (value: any) => any
    keepMask?: boolean
    placeholder?: string
    ref?: any
    prefix?: JSX.Element
    dateProps?: DatePickerProps
    wrapperStyle?: CSSProperties
}

/**
 * COMPONENTE
 * Input generico para formularios.
 *
 * @author hjcostabr
 * @author StellaMCR
 */
export function InputCP<FModelTP extends FormModelValidator = any>(props: IInputCPProps<FModelTP>): JSX.Element {

    function renderInput(): JSX.Element {

        let suffixIcon: JSX.Element | undefined

        if (!!props.loading)
            suffixIcon = <LoadingOutlined spin={true} style={{ width: 14 }} />
        else if (!!props.icon)
            suffixIcon = props.icon
        else if (props.type === 'search')
            suffixIcon = <SearchOutlined style={{ width: 14 }} />

        if (props.type === 'date') {
            return (
                <Input
                    placeholder={props.placeholder}
                    type={'tel'} // tipo tel usado para forçar o teclado numérico no mobile. number e pattern não funcionam com máscara aplicada
                    suffix={suffixIcon}
                    prefix={props.prefix}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    value={props.value}
                />
            )

        } else if (props.type === 'time') {
            return (
                <TimePickerSCP
                    format={DateFormatEnum.BR_TIME}
                    locale={localePtBR}
                    suffixIcon={undefined}
                    placeholder={undefined}
                />
            )

        } else if (props.type === 'currency') {
            return (
                <IMaskInput
                    type={'tel'}
                    className={'ant-input'}
                    mask={'R$num'}
                    blocks={{
                        num: {
                            mask: Number,
                            thousandsSeparator: '',
                            scale: 2,  // digits after point, 0 for integers
                            signed: false,  // disallow negative
                            normalizeZeros: false,  // appends or removes zeros at ends
                            radix: ',',  // fractional delimiter
                            mapToRadix: ['.'],  // symbols to process as radix
                        }
                    }}
                />
            )
        }

        return (
            <Input
                placeholder={props.placeholder}
                type={props.type ?? 'text'}
                suffix={suffixIcon}
                prefix={props.prefix}
                min={props.min}
                max={props.max}
                value={props.value}
                step={props.step}
                disabled={props.disabled}
                maxLength={props.maxLength}
            />
        )

    }

    return (
        <InputWrapperSCP fontSize={props.fontSize || 'normal'} ref={props.ref} style={props.wrapperStyle}>
            <FormItemICP<FModelTP, IFormItemInputCommonProps<FModelTP>>
                label={props.label}
                onChange={props.onChange}
                fieldName={props.fieldName}
                formStateManager={props.formStateManager}
                required={props.required}
                width={props.width}
                onFormSubmit={props.onFormSubmit}
                value={props.value}
                mask={props.mask}
                keepMask={props.keepMask}
                fontSize={props.fontSize}
                errorMessage={props.errorMessage}
                type={props.type}
            >
                {renderInput()}
            </FormItemICP>
        </InputWrapperSCP>
    )
}

const DatePickerSCP = styled(DatePicker)`
    width: 100%;
    border: none;
    background: ${props => props.theme.inputBg};
    padding: 10px 11px;
    border-radius: 5px;
`

const TimePickerSCP = styled(TimePicker)`
    width: 100%;
    border: none;
    background: ${props => props.theme.inputBg};
    padding: 10px 11px;
    border-radius: 5px;
`

const InputWrapperSCP = styled.div<{ fontSize: any }>`

    width: 100%;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;     
    }

    .ant-input, .ant-input-affix-wrapper{
        border: none;
        background: ${props => props.theme.inputBg};
        padding: 10px 11px;
        border-radius: 5px;
        min-height: 21px;
    }

`
