import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { FileUploadCP } from 'common/components/file-upload/FileUploadCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { NotificationHelper } from 'common/NotificationHelper'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { PropertyActions } from 'modules/property/PropertyActions'
import { IPropertyResponseDTO } from 'modules/property/services/dtos/responses/IPropertyResponseDTO'
import { PropertyRequests } from 'modules/property/services/PropertyRequests'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

export type FileTP = 'documents' | 'pictures'

interface IPropertyFileUploadDrawerCPProps {
    open: boolean
    type: FileTP
    closeDrawer: () => void
}

export function PropertyFileUploadDrawerCP(props: IPropertyFileUploadDrawerCPProps): JSX.Element {
    const property = useSelector((state: IReduxState) => state.property)
    const [files, setFiles] = useState<any>([])
    const request = useRequest<IPropertyResponseDTO>()

    const documentTypes = [
        { mime: 'application/pdf', extension: 'pdf' },
        { mime: 'image/jpeg', extension: 'jpeg' },
        { mime: 'image/jpg', extension: 'jpg' },
        { mime: 'application/msword', extension: 'doc' },
        { mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', extension: 'docx' },
        { mime: 'application/vnd.ms-excel', extension: 'xls' },
        { mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', extension: 'xlsx' }
    ]

    const pictureTypes = [
        { mime: 'image/jpeg', extension: 'jpeg' },
        { mime: 'image/jpg', extension: 'jpg' },
        { mime: 'image/png', extension: 'png' },
    ]

    useEffect(onRequestChange, [request.awaiting])

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao subir imagens!')
        }

        NotificationHelper.success('Images adicionadas com sucesso!')
        setFiles([])
        props.closeDrawer()
        PropertyActions.setProperty(request.returnData)
    }

    function appendImages(option: any): void {
        setFiles([...files, option.file])
    }

    function saveImages(): void {

        if (!property.code || !props.type)
            return

        const formData = new FormData()
        for (const file of files)
            formData.append('files', file)

        request.runRequest(PropertyRequests.addImages(property.code, props.type, formData))
    }

    return (
        <DrawerCP
            title={`Adicionar ${props.type === 'documents' ? 'Documentos' : 'Fotos'}`}
            visible={props.open}
            onClose={() => props.closeDrawer()}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={saveImages}
                        wrapperStyle={{ width: '100%' }}
                        loading={request.awaiting}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >
            <FileUploadCP
                uploadFiles={appendImages}
                fileTypes={props.type === 'documents' ? documentTypes : pictureTypes}
                loading={request.awaiting}
                multiple={true}
                showUploadList={true}
            />
        </DrawerCP>
    )
}


const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`
