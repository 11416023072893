import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { TextCP } from 'common/components/text/TextCP'

import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import _ from 'lodash'
import { UpdateBatchFormValidator } from 'modules/batch/components/batch-drawer/inner/UpdateBatchFormValidator'
import { BatchMethodSelectorCP } from 'modules/batch/components/batch-method-selector/BatchMethodSelectorCP'
import { BatchOriginEnum } from 'modules/batch/enums/BatchOriginEnum'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IUpdateBatchRequestDTO } from 'modules/batch/services/dtos/requests/IUpdateBatchRequestDTO'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { LitersPerMaturationFieldsICP } from 'modules/harvest/components/harvest-form/inner/LitersPerMaturationFieldsICP'
import { IHarvestLitersPerMaturation } from 'modules/harvest/interfaces/IHarvestLitersPerMaturation'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface IBatchDrawerCPProps {
    batchCode: number
    show: boolean
    onCancel: () => void
    onSuccess: () => void
}

export function BatchDrawerCP(props: IBatchDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()

    const [formValidator, setFormValidator] = useState<UpdateBatchFormValidator>(new UpdateBatchFormValidator())
    const formStateManager = useFormStateManager(formValidator)
    const [litersPerMaturation, setLitersPerMaturation] = useState<IHarvestLitersPerMaturation>()
    const [batchLiters, setBatchLiters] = useState<number>()
    const [batchOrigin, setBatchOrigin] = useState<BatchOriginEnum>()

    const saveRequest = useRequest<IBatchResponseDTO>()
    useEffect(onRequestChange, [saveRequest.awaiting])

    const getDetailsReq = useRequest<IBatchResponseDTO>()
    useEffect(onGetDetailsReqChange, [getDetailsReq.awaiting])

    useEffect(getDetails, [props.batchCode, props.show])

    function getDetails(): void {
        if (!!props.batchCode && props.show)
            getDetailsReq.runRequest(BatchRequests.get(props.batchCode))
    }

    function onGetDetailsReqChange(): void {
        if (getDetailsReq.awaiting || !getDetailsReq.tried)
            return

        if (!getDetailsReq.success || !getDetailsReq.returnData) {
            if (getDetailsReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getDetailsReq.returnData, getDetailsReq.error)
            return RequestUtils.showDefaultErrorNotification(getDetailsReq.error, 'Ocorreu um erro ao salvar. Tente novamente.')
        }
        setFormValidator(new UpdateBatchFormValidator(getDetailsReq.returnData))
        setLitersPerMaturation(getDetailsReq.returnData.litersPerMaturation)
        setBatchLiters(getDetailsReq.returnData.liters)
        setBatchOrigin(getDetailsReq.returnData.origin)
    }

    async function onFormSubmit(): Promise<void> {
        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)
        formStateManager.debugFieldValues()

        if (!await formStateManager.validate() || !formValues) {
            formStateManager.debugErrors()
            return
        }

        const dto: IUpdateBatchRequestDTO = {
            farmId: formValues.farmId,
            season: formValues.season,
            date: DateUtils.getDateFormattedToApi(formValues.date),
            method: formValues.method,
            litersPerMaturation: !!litersPerMaturation && Object.values(litersPerMaturation).every(lpm => !lpm) ? undefined : litersPerMaturation
        }

        saveRequest.runRequest(BatchRequests.update(props.batchCode, dto))
    }

    function onRequestChange(): void {
        if (saveRequest.awaiting || !saveRequest.tried)
            return

        if (!saveRequest.success || !saveRequest.returnData) {
            if (saveRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveRequest.returnData, saveRequest.error)
            return RequestUtils.showDefaultErrorNotification(saveRequest.error, 'Erro ao salvar planejamento de colheita!')
        }

        NotificationHelper.success('Item salvo com sucesso!')
        props.onSuccess()
        ListActions.mustUpdate()
    }

    return (
        <DrawerCP
            title={`Editar lote #${props.batchCode}`}
            visible={props.show}
            width={'35%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFormSubmit}
                        loading={saveRequest.awaiting}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {
                formStateManager.reset()
                setLitersPerMaturation(undefined)
                setBatchLiters(undefined)
                props.onCancel()
            }}
        >

            <WrapperSCP>
                <InputCP
                    label={'Data:'}
                    formStateManager={formStateManager}
                    fieldName={'date'}
                    type={'date'}
                    required={true}
                    placeholder={'Data'}
                    mask={MaskUtils.applyDateMask}
                    fontSize={'normal'}
                    loading={getDetailsReq.awaiting}
                />
                <SeasonSelectorCP
                    label={'Safra:'}
                    required={true}
                    formStateManager={formStateManager}
                    loading={getDetailsReq.awaiting}
                />
                {
                    !!batchOrigin && batchOrigin === BatchOriginEnum.HARVEST &&
                    <BatchMethodSelectorCP
                        label={'Método: '}
                        formStateManager={formStateManager}
                        required={true}
                        loading={getDetailsReq.awaiting}
                    />
                }
                <InputCP
                    label={'Id na propridade:'}
                    type={'text'}
                    required={false}
                    placeholder={'ID'}
                    formStateManager={formStateManager}
                    fieldName={'farmId'}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    loading={getDetailsReq.awaiting}
                />
                <RowSCP>
                    <TextCP text={'Litragem total do lote:\u00A0 '} />
                    <TextCP text={`   ${batchLiters} litros`} />
                </RowSCP>

                {
                    !!batchLiters &&
                    <LitersPerMaturationFieldsICP
                        totalLiters={batchLiters}
                        currentValues={litersPerMaturation}
                        onChange={setLitersPerMaturation}
                    />
                }

            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    row-gap: 10px;

`
const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`

const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    .ant-typography{
        font-size: 12px;
    }
    
`