import React from 'react'
import styled from 'styled-components'
import { Collapse } from 'antd'
import { CollapsePanelTP } from 'common/components/collapse/types/CollapsePanelTP'

interface IPanelProps {
    isActive?: boolean
    header?: React.ReactNode
    className?: string
    style?: React.CSSProperties
    showArrow?: boolean
    forceRender?: boolean
    disabled?: boolean
    extra?: React.ReactNode
}
interface ICollapseCPProps {
    panels: CollapsePanelTP[]
    defaultActiveKeys?: string | string[]
    onChange?: (key: string | string[]) => void
    showArrow?: boolean
    expandIcon?: (panelProps: IPanelProps) => JSX.Element
    expandIconPosition?: 'left' | 'right'
    accordion?: boolean
}

export function CollapseCP(props: ICollapseCPProps): JSX.Element {

    return (
        <ContentWrapperSCP>
            <Collapse
                bordered={false}
                defaultActiveKey={props.defaultActiveKeys}
                onChange={props.onChange}
                expandIconPosition={props.expandIconPosition}
                accordion={props.accordion}
                expandIcon={props.expandIcon}
            >
                {props.panels.map((panel: CollapsePanelTP) => (
                    <Collapse.Panel
                        header={panel.header}
                        key={panel.key}
                        disabled={panel.disabled}
                        showArrow={props.showArrow}
                        extra={panel.extra}
                    >
                        {panel.content}
                    </Collapse.Panel>
                ))}
            </Collapse>
        </ContentWrapperSCP>
    )
}
const ContentWrapperSCP = styled.div`
    margin: 10px 0; 
    .ant-collapse-borderless{
        background: transparent;
    }
    .ant-collapse-borderless > .ant-collapse-item{
        border-bottom: none;     
        border-radius: 5px;      
    }
    .ant-collapse-borderless > .ant-collapse-item .ant-collapse-header{
       
        font-weight: 600;
    }
`
