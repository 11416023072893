import { faChartPie, faFilter } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { HarvestGeneralReportDrawerCP } from 'modules/harvest-planning/components/harvest-general-report-drawer/HarvestGeneralReportDrawerCP'
import { HarvestPlanningFilterDrawerICP } from 'modules/harvest-planning/components/harvest-planning-filters/inner/HarvestPlanningFilterDrawerICP'
import { ISearchHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISearchHarvestPlanningReqDTO'
import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import React, { useState } from 'react'
import styled from 'styled-components'
interface IHarvestPlanningFilterFieldsICPProps {
    filters?: ISearchHarvestPlanningReqDTO
    setFilters: (filters: ISearchHarvestPlanningReqDTO) => void
}

export function HarvestPlanningFilterFieldsICP(props: IHarvestPlanningFilterFieldsICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [filterDrawerVisible, setFilterDrawerVisible] = useState<boolean>(false)
    const [reportDrawerVisible, setReportDrawerVisible] = useState<boolean>(false)

    return (
        <WrapperSCP>
            <GridWrapperSCP>
                <SeasonSelectorCP
                    label={'Safra'}
                    onChange={(season) => props.setFilters({ ...props.filters, season })}
                    value={props.filters?.season}
                />

                {
                    !screenSize.smd &&
                    <PropertyFieldFieldsCP
                        onChangeField={(fieldCode) => props.setFilters({ ...props.filters, fieldCode })}
                        fieldCode={props.filters?.fieldCode}
                        propertyCode={props.filters?.propertyCode}
                        onChangeProperty={(propertyCode) => props.setFilters({ ...props.filters, propertyCode })}
                        onClear={() => props.setFilters({ ...props.filters, propertyCode: undefined, fieldCode: undefined })}
                    />
                }

                <ButtonCP
                    onClick={() => setFilterDrawerVisible(true)}
                    type={'primary'}
                    style={{ width: 50, height: 50, zIndex: 1, borderRadius: '100%' }}
                    icon={<FontAwsomeIconCP icon={faFilter} color={'#fff'} />}
                />
                {
                    screenSize.smd &&
                    <ButtonCP
                        onClick={() => setReportDrawerVisible(true)}
                        type={'primary'}
                        style={{ width: 50, height: 50, zIndex: 1, borderRadius: '100%' }}
                        icon={<FontAwsomeIconCP icon={faChartPie} color={'#fff'} />}
                    />
                }

            </GridWrapperSCP>
            <HarvestPlanningFilterDrawerICP
                show={filterDrawerVisible}
                onClose={() => setFilterDrawerVisible(false)}
                filters={props.filters}
                setFilters={props.setFilters}
            />
            <HarvestGeneralReportDrawerCP
                show={reportDrawerVisible}
                filters={props.filters}
                onClose={() => setReportDrawerVisible(false)}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 3px;
`

const GridWrapperSCP = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: .4fr 1fr .2fr;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;
    align-items: center;

    label, .ant-typography div{
        display: none;
    }
    .ant-row .ant-form-item-label > label{
        display: none;
    }
    @media(max-width: 767px){
            grid-template-columns: 1fr .2fr .2fr;
            .ant-row .ant-form-item-label > label{
                display: none;
            }
        }
`

const StatusSCP = styled.div`
    @media(max-width: 767px){
    display: none;
    }

`