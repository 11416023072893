import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RegistrationScreen } from './screens/RegistrationScreen'

RegistrationRoutes.ROOT = '/registrar'

export function RegistrationRoutes(): JSX.Element {
    return (
        <Switch>
            <Route path={RegistrationRoutes.ROOT} component={RegistrationScreen} />
        </Switch>
    )
}
