import { faFlask, faList } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CardFooterICP } from 'common/components/list/inner/CardFooterICP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { CardListItemICP } from 'common/components/list/inner/CardListItemICP'
import { ListActions } from 'common/components/list/inner/ListActions'

import { TextCP } from 'common/components/text/TextCP'
import { ModalCP } from 'common/components/modal/ModalCP'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { BatchLandingPageRoutes } from 'modules/batch-landing-page/BatchLandingPageRoutes'
import { BatchRoutes } from 'modules/batch/BatchRoutes'
import { BatchCardContentICP } from 'modules/batch/components/batch-list/inner/BatchCardContentICP'
import { BatchCardSkeletonICP } from 'modules/batch/components/batch-list/inner/BatchCardSkeletonICP'
import { BatchOriginEnum } from 'modules/batch/enums/BatchOriginEnum'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { ISearchBatchResponseDTO } from 'modules/batch/services/dtos/responses/ISearchBatchResponseDTO'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { BatchPresentationRoutes } from 'modules/batch-presentation/BatchPresentationRoutes'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { useSelector } from 'react-redux'

interface IBatchCardICPProps {
    item: ISearchBatchResponseDTO
    loading: boolean
}

export function BatchCardICP(props: IBatchCardICPProps): JSX.Element {
    const persistedList: ISearchBatchResponseDTO[] | null = useSelector((state: IReduxState) => _.get(state, 'searchState.loadedItems'))
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
    const history = useHistory()
    const deleteBatchRequest = useRequest()
    useEffect(onDeleteRequestChange, [deleteBatchRequest.awaiting])

    function deleteBatch(): void {

        if (props.item.origin === BatchOriginEnum.MERGE || props.item.origin === BatchOriginEnum.SPLIT)
            return setShowModalDelete(true)

        if (!!persistedList && !!persistedList.length)
            SearchActions.saveLoaded<ISearchBatchResponseDTO>(persistedList.filter(bat => bat.code !== props.item.code))

        deleteBatchRequest.runRequest(BatchRequests.delete(props.item.code))
    }

    function onDeleteRequestChange(): void {

        if (deleteBatchRequest.awaiting || !deleteBatchRequest.tried)
            return

        if (!deleteBatchRequest.success || !deleteBatchRequest.returnData)
            return RequestUtils.showDefaultErrorNotification(deleteBatchRequest.error, 'Erro remover lote')

        NotificationHelper.success('Lote removido com sucesso!')
        ListActions.mustUpdate()
        ListActions.unselectAll()
    }

    return (
        <WrapperSCP>

            <CardListItemICP
                key={props.item.code}
                item={props.item}
                loading={props.loading}
                customSkeleton={<BatchCardSkeletonICP />}
                header={
                    <CardHeaderICP
                        highlight={`#${props.item.code}`}
                        highlighAction={() => history.push(`${BatchPresentationRoutes.ROOT}/${props.item.code}`)} //window.open('yourpage.htm','pagename','resizable,height=260,width=370'); return false;
                        highlightTooltipText={'Clique para ver a página de apresentação deste lote'}
                        title={props.item.fieldNames && props.item.fieldNames.join(', ')}
                        subtitle={
                            <SubTitleSCP>
                                <TextCP text={`Safra: ${props.item.season}`} />
                                <TextCP text={`${props.item.bags ? `${props.item.bags} sacas` : `${props.item.liters} litros`}`} />
                            </SubTitleSCP>
                        }
                        itemCode={props.item.code}
                        isSelectable={true}
                    />
                }
                content={
                    <BatchCardContentICP
                        code={props.item.code}
                        method={props.item.method}
                        methodOther={props.item.methodOther}
                        date={props.item.date}
                        origin={props.item.origin}
                        litersPerMaturation={props.item.litersPerMaturation}
                        farmId={props.item.farmId}
                        averageScore={props.item.averageScore}
                        averagePicking={props.item.averagePicking}
                        lastProcessEntry={props.item.lastProcessEntry}
                        bags={props.item.bags}
                        availableBags={props.item.availableBags ?? 0}
                        notSpecial={props.item.notSpecial}
                    />
                }
                footer={
                    <CardFooterICP
                        onDelete={deleteBatch}
                        deleteLoading={deleteBatchRequest.awaiting}
                        confirmLabel={'Tem certeza que quer excluir este lote?'}
                    >
                        <ActionsSCP>

                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px' }}
                                onClick={() => history.push(`${BatchRoutes.ROOT}/analises/${props.item.code}`)}
                                icon={<FontAwsomeIconCP icon={faFlask} style={{ margin: '0 5px' }} />}
                            >
                                Análises
                            </ButtonCP>

                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px' }}
                                onClick={() => history.push(`${BatchRoutes.ROOT}/processos/${props.item.code}`)}
                                icon={<FontAwsomeIconCP icon={faList} style={{ margin: '0 5px' }} />}
                            >
                                Detalhes
                            </ButtonCP>
                        </ActionsSCP>
                    </CardFooterICP>
                }
            />
            <ModalCP
                visible={showModalDelete}
                centered={true}
                okText={'Ok'}
                onClose={() => setShowModalDelete(false)}
                noCancel={true}
            >
                <TextCP text={'Para remover este lote, sua mescla ou divisão deve ser desfeita.'} />
            </ModalCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`

const SubTitleSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;  
    width: 100%;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }
    
`
const ActionsSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`
