import { faMedal } from '@fortawesome/free-solid-svg-icons'
import { AvatarCP } from 'common/components/avatar/AvatarCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { TextCP } from 'common/components/text/TextCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { IUserNameListItemResponseDTO } from 'modules/user/services/dtos/responses/IUserNameListItemResponseDTO'
import React from 'react'
import styled from 'styled-components'

interface IUserCardInfoICPProps {
    userName: string
    score: number
}

export function UserCardInfoICP(props: IUserCardInfoICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <UserWppSCP>
                <TextSCP>
                    <TextCP
                        text={props.userName}
                        style={{ fontSize: '18px', fontWeight: 600 }}
                    />
                    <TextCP
                        text={'Q-Grader'}
                        style={{ fontSize: '15px', fontStyle: 'italic' }}
                    />
                </TextSCP>

            </UserWppSCP>
            <ScoreWppSCP>
                <FontAwsomeIconCP icon={faMedal} size={'3x'} />
                <TextSCP>
                    <RowSCP>
                        <TextCP
                            text={MaskUtils.applyNumberMask(props.score)}
                            style={{ fontSize: '23px', fontWeight: 600 }}
                        />
                        <TextCP
                            text={'points'}
                            style={{ fontSize: '15px' }}
                            translationFiles={'batch'}
                            translationNameSpace={'batch'}
                        />
                    </RowSCP>

                    <TextCP
                        text={props.score >= 80 ? 'special' : 'notSpecial'}
                        style={{ fontSize: '15px', fontStyle: 'italic', fontWeight: 600 }}
                        translationFiles={'batch'}
                        translationNameSpace={'batch'}
                    />

                </TextSCP>

            </ScoreWppSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 60% 38%;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 3em;
    .ant-typography{
        color: ${props => props.theme.primaryColor};
    }
    @media(max-width: 768px){
        grid-template-columns: 100%;
        row-gap: 20px;
    }
`
const UserWppSCP = styled.div`
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-top: 2em;
    @media(max-width: 768px){
        justify-content: center;
    }
       
`
const ScoreWppSCP = styled.div`
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: flex-end;
    svg {
        color: ${props => props.theme.primaryColor};
    }
    @media(max-width: 768px){
        width: 100%;
      
        justify-content: center;
    }
`

const TextSCP = styled.div`
    
`

const RowSCP = styled.div`
    display: flex;
    column-gap: 5px;
    align-items: center;
`