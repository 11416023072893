import { faDotCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { MaturationEnum } from 'modules/harvest/enums/MaturationEnum'
import React from 'react'
import { HarvestTypeEnum } from '../enums/HarvestTypeEnum'

export class HarvestUtils {

    static getMethodIcon(method: HarvestTypeEnum): JSX.Element {
        switch (method) {
            case HarvestTypeEnum.FRUIT_DETACHING:
                return <FontAwsomeIconCP icon={faDotCircle} color={'#fff'} />
            case HarvestTypeEnum.MANUAL:
                return <FontAwsomeIconCP icon={faDotCircle} color={'#fff'} />
            case HarvestTypeEnum.MECHANIZED:
                return <FontAwsomeIconCP icon={faDotCircle} color={'#fff'} />
            case HarvestTypeEnum.SELECTIVE_MANUAL:
                return <FontAwsomeIconCP icon={faDotCircle} color={'#fff'} />
            case HarvestTypeEnum.SELECTIVE_MECHANIZED:
                return <FontAwsomeIconCP icon={faDotCircle} color={'#fff'} />
            
            default:
                return <FontAwsomeIconCP icon={faDotCircle} color={'#fff'} />
        }
    }

    static translateType(type: HarvestTypeEnum, typeOther?: string): string {
        switch (type) {
            case HarvestTypeEnum.FRUIT_DETACHING:
                return 'Derriça'
            case HarvestTypeEnum.MANUAL:
                return 'Manual'
            case HarvestTypeEnum.MECHANIZED:
                return 'Mecanizada'
            case HarvestTypeEnum.SELECTIVE_MANUAL:
                return 'Seletiva Manual'
            case HarvestTypeEnum.SELECTIVE_MECHANIZED:
                return 'Seletiva Mecanizada'
        
            default:
                return ''
        }
    }

    static getHarvestTypeInitials(type: HarvestTypeEnum, typeOther?: string): string {
        switch (type) {
            case HarvestTypeEnum.FRUIT_DETACHING:
                return 'DER'
            case HarvestTypeEnum.MANUAL:
                return 'MAN'
            case HarvestTypeEnum.MECHANIZED:
                return 'MEC'
            case HarvestTypeEnum.SELECTIVE_MANUAL:
                return 'SMAN'
            case HarvestTypeEnum.SELECTIVE_MECHANIZED:
                return 'SMEC'
           
            default:
                return ''
        }
    }

    static translateTypeToEnlgish(type: HarvestTypeEnum, typeOther?: string): string {
        switch (type) {
            case HarvestTypeEnum.FRUIT_DETACHING:
                return 'Fruit detaching'
            case HarvestTypeEnum.MANUAL:
                return 'Manual'
            case HarvestTypeEnum.MECHANIZED:
                return 'Mechanized'
            case HarvestTypeEnum.SELECTIVE_MANUAL:
                return 'Selective manual'
            case HarvestTypeEnum.SELECTIVE_MECHANIZED:
                return 'Selective mechanized'
          
            default:
                return ''
        }
    }

    static getMaturationColor(maturation: MaturationEnum): string {
        switch (maturation) {
            case MaturationEnum.UNRIPE:
                return '#acc39e'
            case MaturationEnum.SEMIRIPE:
                return '#d1dea3'
            case MaturationEnum.RIPE:
                return '#bd7073'
            case MaturationEnum.OVERRIPE:
                return '#9e8387'
            case MaturationEnum.DRIED:
                return '#949494'
            default:
                return ''
        }
    }

    static getMaturationLabel(maturation: MaturationEnum): string {
        switch (maturation) {
            case MaturationEnum.UNRIPE:
                return 'Verde'
            case MaturationEnum.SEMIRIPE:
                return 'Verde Cana'
            case MaturationEnum.RIPE:
                return 'Maduro'
            case MaturationEnum.OVERRIPE:
                return 'Boião'
            case MaturationEnum.DRIED:
                return 'Seco'
            default:
                return ''
        }
    }

    static convertMaturationToCamelCase(maturation: MaturationEnum): string {
        switch (maturation) {
            case MaturationEnum.UNRIPE:
                return 'unripe'
            case MaturationEnum.SEMIRIPE:
                return 'semiRipe'
            case MaturationEnum.RIPE:
                return 'ripe'
            case MaturationEnum.OVERRIPE:
                return 'overRipe'
            case MaturationEnum.DRIED:
                return 'dried'
            default:
                return ''
        }
    }

    static convertCamelCaseMaturationToENum(maturation: string): MaturationEnum {
        switch (maturation) {
            case 'unripe':
                return MaturationEnum.UNRIPE
            case 'semiRipe':
                return MaturationEnum.SEMIRIPE
            case 'ripe':
                return MaturationEnum.RIPE
            case 'overRipe':
                return MaturationEnum.OVERRIPE
            case 'dried':
                return MaturationEnum.DRIED
            default:
                return MaturationEnum.RIPE
        }
    }
}