import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { ListHeaderICP } from 'common/components/list/ListHeaderICP'
import { ProductionReportListItemICP } from 'modules/report/components/production-report-by-field-table/inner/ProductionReportListItemICP'
import { ProductionReportDataTP } from 'modules/report/types/ProductionReportDataTP'
import React from 'react'
import styled from 'styled-components'

interface IProductionReportByFieldTableCPProps {
    fields?: ProductionReportDataTP[]
    loading: boolean
}

export function ProductionReportByFieldTableCP(props: IProductionReportByFieldTableCPProps): JSX.Element {
    return (
        <WrapperSCP>
            <ListHeaderICP
                gridColumns={'1.5fr 1fr 1fr 1fr 1fr 1fr 1fr'}
                labels={[
                    'Talhão',
                    'Área de produção',
                    'Total de litros',
                    'Total de sacas',
                    'Litros/pé',
                    'Sacas/hectares',
                ]}
            />
            <ListCP<ProductionReportDataTP>
                bordered={false}
                itemLayout={'vertical'}
                size={'large'}
                loadMoreData={() => { }} dataSource={props.fields ?? []}
                loading={props.loading}
                locale={{
                    emptyText: <EmptyCP description={'Sem resultados para os fitros selecionados'} />
                }}
                renderItem={(item: ProductionReportDataTP, index: number) => (
                    <ProductionReportListItemICP key={index} reportData={item} loading={props.loading} />
                )}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: white;
    padding: 5px;
    border-radius: 8px;
    margin: 20px 0;

    .ant-typography {
        font-size: 12px;
    }
`
