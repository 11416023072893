import { SearchDataFilterTP } from 'common/redux/ReduxTypes'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'

export class SearchActions {

    static readonly TYPE_UPDATED_SEARCH_STATE = 'TYPE_UPDATED_SEARCH_STATE'

    private constructor() { }

    static setFilter<T>(filter: T | undefined, type: FilterTypesEnum): void {
        this.updateState({
            ...this.getCurrenState<T>(),
            filter,
            type,
        })
    }

    static saveLoaded<T>(loadedItems: T[]): void {

        this.updateState({
            ...this.getCurrenState<T>(),
            loadedItems
        })
    }

    static clearLoaded<T>(): void {
        this.updateState({
            ...this.getCurrenState<T>(),
            loadedItems: []
        })
    }

    static saveScrollPosition<T>(scrollPosition: number): void {
        this.updateState({
            ...this.getCurrenState<T>(),
            scrollPosition
        })
    }

    static getScrollPosition(): number | null {
        return ReduxHelper.getInstance().getStore().getState().searchState?.scrollPosition
    }

    static saveLastPage<T>(lastPage: number): void {
        this.updateState({
            ...this.getCurrenState<T>(),
            lastPage
        })
    }

    static updateState<T>(payload: SearchDataFilterTP<T> | null): void {
        ReduxHelper.getInstance().getStore()
            .dispatch({ type: this.TYPE_UPDATED_SEARCH_STATE, payload })
    }

    static clearState(): void {
        this.updateState(null)
    }

    static getCurrenState<T>(): SearchDataFilterTP<T> {
        return ReduxHelper.getInstance().getStore().getState().searchState
    }

    static getCurrentFilters<T>(): SearchDataFilterTP<T> {
        return ReduxHelper.getInstance().getStore().getState().searchState?.filters
    }
}
