import { ListUpdateStatusEnum } from './ListUpdateStatusEnum'
import { ListStateControlTP } from 'common/redux/ReduxTypes'
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import _ from 'lodash'
import { SearchActions } from './SearchActions'

/**
 * Actions para controle de estado de uma LISTA via REDUX
 * estados: necessidade de update, conteudo da lista e itens selecionados
 * @see ListUtils
 */
export class ListActions {

    static readonly TYPE_UPDATED_LIST_STATE = 'TYPE_UPDATED_LIST_STATE'


    private constructor(
        private readonly _searchActions: SearchActions
    ) { }

    static setList(_list: any[]): void {

        const list = _list.map(item => item.code)
        this.updateState({ ...this.getCurrenState(), list })
    }

    static mustUpdate(clearPersisted?: boolean): void {
        this.updateState({ ...this.getCurrenState(), updateStatus: ListUpdateStatusEnum.MUST_UPDATE })
        if (clearPersisted)
            SearchActions.clearLoaded()
    }

    static updateIsDone(): void {
        this.updateState({ ...this.getCurrenState(), updateStatus: ListUpdateStatusEnum.DONE })
    }

    static isUpdating(): void {
        this.updateState({ ...this.getCurrenState(), updateStatus: ListUpdateStatusEnum.UPDATING })
    }

    static addOrRemoveSelectedItem(itemCode: number): void {
        const selectedItems = this.getCurrenState().selectedItems

        if (!selectedItems) {
            this.updateState({ ...this.getCurrenState(), selectedItems: [itemCode] })
            return
        }

        const alreadySelected = !!this.getCurrenState().selectedItems?.includes(itemCode)

        if (alreadySelected) {
            const newList = _.filter(selectedItems, item => item !== itemCode)
            this.updateState({ ...this.getCurrenState(), selectedItems: newList })
        } else
            this.updateState({ ...this.getCurrenState(), selectedItems: [...selectedItems, itemCode] })
    }

    static selectOne(itemCode: number): void {
        this.updateState({ ...this.getCurrenState(), selectedOne: itemCode, showDrawer: true })
    }

    static unselectAll(): void {
        this.updateState({ ...this.getCurrenState(), selectedItems: [] })
    }

    static hideDetails(): void {
        this.updateState({ ...this.getCurrenState(), selectedOne: undefined, showDrawer: false, selectedItems: undefined })
    }

    static clearListState(): void {
        this.updateState(null)
    }

    static updateState(payload: ListStateControlTP | null): void {
        ReduxHelper.getInstance().getStore()
            .dispatch({ type: this.TYPE_UPDATED_LIST_STATE, payload })
    }

    static getCurrenState(): ListStateControlTP {
        return ReduxHelper.getInstance().getStore().getState().listState
    }
}
