import React, { CSSProperties } from 'react'
import { Upload } from 'antd'
import styled from 'styled-components'
import { RcFile } from 'antd/lib/upload'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'

import { UploadButtonICP } from 'common/components/profile-image-uploader/inner/UploadButtonICP'
import { useScreenSize } from 'common/hooks/UseScreenSize'

interface IPropertyMainImagePickerICPProps {
    onChange: (info: any) => void
    beforeUpload: (file: RcFile, fileList: RcFile[]) => boolean | Promise<any>
    customRequest?: (option: object) => void
    imageUrl?: string
    fileTypes?: string
    loading: boolean
    instructions?: string
    multiple?: boolean
    showUploadList?: boolean
    disabled?: boolean
    style?: CSSProperties
    size?: number
}

/**
 * Componente responsável por fazer o upload de imagem
 * Utiliza handlers customizados para fazer este upload.
 * @author kiara.vz
 */
export function PropertyMainImagePickerICP(props: IPropertyMainImagePickerICPProps): JSX.Element {
    const screenSize = useScreenSize()
    return (
        <FileUploaderWrapperSCP>
            <Upload
                beforeUpload={props.beforeUpload}
                customRequest={props.customRequest}
                disabled={props.disabled}
                onChange={props.onChange}
                accept={props.fileTypes}
                multiple={props.multiple}
                showUploadList={props.showUploadList}
            >
                {props.imageUrl ?
                    <img src={props.imageUrl} />
                    :
                    <UploadButtonICP style={{ width: '100%' }} disabled={props.disabled} loading={props.loading} />}
            </Upload>
            <InstructionsSCP>
                {props.instructions}
            </InstructionsSCP>
            <LoadingOverlayCP show={props.loading} />
        </FileUploaderWrapperSCP>
    )
}

const InstructionsSCP = styled(Upload)`
.ant-upload-select-text{
    span{
        font-size: 12px;
    }
    margin-top: 10px;
}
`
const FileUploaderWrapperSCP = styled.div`
        margin: 20px 0;
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        max-height: 150px;
        cursor: pointer;
        justify-content: center;
        display: flex;
        flex-direction: column;
        span{
            text-align: center;
            width: 100%;
        }
        .ant-upload.ant-upload-select{
            width: 100%;
        }
`