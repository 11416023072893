import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { ThemeConfig } from 'config/ThemeConfig'
import { SchemaActions } from 'modules/company/actions/SchemaActions'

export class CostumizedThemeConfig {

    public static primaryColor: string = ThemeConfig.primaryColor
    public static readonly errorColor: string = ThemeConfig.errorColor
    public static readonly lightGray: string = ThemeConfig.lightGray
    public static readonly gray: string = ThemeConfig.gray
    public static readonly successColor: string = ThemeConfig.successColor

    private constructor() {
        const company = ReduxHelper.getInstance().getStore().getState().schemaData
        if (!!company)
            CostumizedThemeConfig.primaryColor = company.color
    }

}
