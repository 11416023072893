import { ButtonCP } from 'common/components/button/ButtonCP'
import { DateRangePickerCP } from 'common/components/date-range-picker/DateRangePickerCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { DateUtils } from 'common/utils/DateUtils'
import { ISearchHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISearchHarvestPlanningReqDTO'
import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'

interface IHarvestPlanningFilterDrawerICPProps {
    show: boolean
    onClose: () => void
    setFilters: (filters: ISearchHarvestPlanningReqDTO) => void
    filters?: ISearchHarvestPlanningReqDTO
}
const DEFAULT_FILTERS: ISearchHarvestPlanningReqDTO = {
    season: moment().year(),
}
export function HarvestPlanningFilterDrawerICP(props: IHarvestPlanningFilterDrawerICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [filters, setFilters] = useState<ISearchHarvestPlanningReqDTO>()

    useEffect(setFilterValues, [props.filters])

    function setFilterValues(): void {
        if (!props.filters)
            return
        setFilters(props.filters)
    }

    return (
        <DrawerCP
            title={'Mais filtros'}
            visible={props.show}
            width={'35%'}
            onClose={props.onClose}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'ghost'}
                        wrapperStyle={{ width: '100%' }}
                        onClick={() => {
                            setFilters(DEFAULT_FILTERS)
                            props.setFilters(DEFAULT_FILTERS)
                            props.onClose()
                        }}
                    >
                        Limpar
                    </ButtonCP>
                    <ButtonCP
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'primary'}
                        wrapperStyle={{ width: '100%' }}
                        onClick={() => {
                            if (!!filters)
                                props.setFilters(filters)
                            props.onClose()
                        }}
                    >
                        Filtrar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >

            <WrapperSCP>
                <DateRangePickerCP
                    placeholder={['Iniciado entre as datas ...', '']}
                    value={(!!filters?.startDate && !!filters?.endDate) ?
                        [moment(filters.startDate), moment(filters.endDate)] :
                        undefined}
                    onChange={(dates) => setFilters(
                        {
                            ...filters,
                            startDate: DateUtils.getDateFormattedToApi(moment(dates.beginDate).format('DD-MM-YYYY')),
                            endDate: DateUtils.getDateFormattedToApi(moment(dates.endDate).format('DD-MM-YYYY'))
                        }
                    )}
                // allowClear={true}
                />
                <PropertyFieldFieldsCP
                    onChangeProperty={(propertyCode) => setFilters({ ...filters, propertyCode })}
                    propertyCode={filters?.propertyCode}
                    fieldCode={filters?.fieldCode}
                    onChangeField={(fieldCode) => setFilters({ ...filters, fieldCode })}
                />
            </WrapperSCP>

        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`

const OtherFiltersWppSCP = styled.div`

`
const TwoColWrapperSCP = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
column-gap: 8px;
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 10px;
    .ant-btn {
        width: 100%;
        justify-content: center;
    }
`