import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoggedUserDataTP, SchemaDataTP } from 'common/redux/ReduxTypes'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { UserMainLayoutCP } from 'modules/app/components/user-main-layout/UserMainLayoutCP'
import { useLocation } from 'react-router-dom'
import { useRequest } from 'common/request-helper/UseRequest'
import { SchemaActions } from 'modules/company/actions/SchemaActions'
import { CompanyRequests } from 'modules/company/services/CompanyRequests'
import { ICompanyResDTO } from 'modules/company/services/dtos/response/ICompanyResDTO'
import { LoginVerifierCP } from '../login-verifier/LoginVerifierCP'
import { LoadingOverlayCP } from 'common/components/loading-overlay/LoadingOverlayCP'
interface IProps {
    history: any

}
export function SchemaVerifier(props: IProps): JSX.Element | null {
    const loggedUserData: LoggedUserDataTP | null = useSelector((state: IReduxState) => _.get(state, 'loggedUser'))
    const persistedSchema: SchemaDataTP | null = useSelector((state: IReduxState) => _.get(state, 'persistedSchema'))
    const location = useLocation()

    const [basename, setBasename] = useState<string>()
    const schemaReq = useRequest<ICompanyResDTO>()
    useEffect(onSchemaReqChange, [schemaReq.awaiting])

    useEffect(verifySchema, [])

    function verifySchema(): void {

        if (!!persistedSchema) {
            const _basename = persistedSchema.domain.substring(+persistedSchema.domain.lastIndexOf('/') + 1)
            setBasename(_basename)

            if (location.pathname.includes(_basename))
                return
        }
        getSchema()
    }

    async function getSchema(): Promise<void> {
        if (!!basename) {
            return await schemaReq.runRequest(CompanyRequests.getCompany({ schema: basename }))
        }
    }

    function onSchemaReqChange(): void {
        if (schemaReq.awaiting || !schemaReq.tried)
            return

        if (!schemaReq.success) {
            SchemaActions.clearSchemaData()
            console.log('ERRO AO BUSCAR SCHEMA')
        }

        if (!!schemaReq.returnData) {
            const domain: string = schemaReq.returnData.domain.substring(+schemaReq.returnData.domain.lastIndexOf('/') + 1)
            SchemaActions.persistSchema(schemaReq.returnData)
            props.history.replace(`${domain}/entrar`)
        }
    }

    if (schemaReq.awaiting)
        return <LoadingOverlayCP show={true} />


    return <LoginVerifierCP history={props.history} domain={persistedSchema?.domain.substring(+persistedSchema.domain.lastIndexOf('/') + 1)} />
}
