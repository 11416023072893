import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'

import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { DateUtils } from 'common/utils/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { ILogbookResponseDTO } from 'modules/harvest-planning/services/dtos/response/ILogbookResponseDTO'
import { HarvestUtils } from 'modules/harvest/utils/HarvestUtils'

interface IHarvestItemPreviewICPProps {
    item?: ILogbookResponseDTO
}

export function HarvestItemPreviewICP(props: IHarvestItemPreviewICPProps): JSX.Element {

    if (!props.item || !props.item.harvest) {
        return (
            <EmptyContentSCP>
                Ainda não há registros
            </EmptyContentSCP>
        )
    }
    return (
        <WrapperSCP>
            <HighLightSCP>
                {HarvestUtils.getHarvestTypeInitials(props.item.harvest.type)}
            </HighLightSCP>
            <InfoWrapperSCP>
                <ResumeSCP>
                    <TextCP
                        text={`${DateUtils.getFormatted(props.item.date, DateFormatEnum.BR_WITHOUT_TIME)}`}
                        style={{ fontSize: '12px', fontWeight: 500 }}
                    />

                    <TextCP
                        text={` ${props.item.harvest.liters} litros colhidos`}
                        style={{ fontSize: '12px' }}
                    />

                </ResumeSCP>

                <TextCP text={`${props.item.peopleResponsible.map(p => p.person.name).join(', ')}`} style={{ fontSize: '12px', color: `${CostumizedThemeConfig.gray}` }} />
            </InfoWrapperSCP>
        </WrapperSCP>
    )
}

const EmptyContentSCP = styled.div`
    background: ${props => props.theme.lightGray};
    min-height: 60px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const InfoWrapperSCP = styled.div`
    display: flex;
   flex-direction: column;

`

const ResumeSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography{
        white-space: pre-wrap;
    }
`

const WrapperSCP = styled.div`
    margin: 8px 0;
    display: grid;
    grid-template-columns: 40px 200px;
    column-gap: 8px;
    background: #f1f1f1;
    padding: 5px;
    border-radius: 5px;
`

const HighLightSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    font-size: 9px;
`