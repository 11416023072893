import * as _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { LoggedUserDataTP } from 'common/redux/ReduxTypes'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { UserMainLayoutCP } from 'modules/app/components/user-main-layout/UserMainLayoutCP'
interface IProps {
    history: any
    domain?: string
}
export function LoginVerifierCP(props: IProps): JSX.Element | null {
    const loggedUserData: LoggedUserDataTP | null = useSelector((state: IReduxState) => _.get(state, 'loggedUser'))

    if (!loggedUserData || !loggedUserData.user_id) {

        if (!!props.domain)
            props.history.replace(`${props.domain}/${AuthRoutes.LOGIN}`)
        else
            props.history.replace(AuthRoutes.LOGIN)
        return null
    }

    return <UserMainLayoutCP />
}
