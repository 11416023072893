import React from 'react'
import ReadMoreReact from 'read-more-react'

interface IReadMoreCPProps {
    text: string
    minTextLength?: number
    maxTextLength?: number
    idealTextLength?: number
    extra?: JSX.Element
    mainColor?: string
    readMoreText?: string
}

/**
 * COMPONENTE
 * Wrapper genérico para exibição de texto longo expandível ao clicar
 * @author kiaravzm
 */
export function ReadMoreCP(props: IReadMoreCPProps): JSX.Element {

    return (
        <ReadMoreReact
            style={{ cursor: 'pointer', fontWeight: 'bolder' }}
            text={props.text}
            min={props.minTextLength ?? 80}
            ideal={props.idealTextLength ?? 100}
            max={props.maxTextLength ?? 200}
            readMoreText={props.readMoreText ?? 'Leia mais ->'}
        />
    )
}