import { Modal, notification } from 'antd'
import { ModalFunc } from 'antd/lib/modal/confirm'
import { ModalFuncProps } from 'antd/lib/modal/Modal'
import { ArgsProps, NotificationPlacement } from 'antd/lib/notification'

type ModalConfirmParamsTP = {
    title: string,
    content: JSX.Element,
    icon?: JSX.Element,
    onOk?: () => void,
    onCancel?: () => void,
    okText?: string,
    cancelText?: string,
}

/**
 * MANAGER
 * Gerencia exibicao de notificacoes, no sistema.
 *
 * @todo: Add opcao 'callback de fechamento'
 *
 * @author guilherme.diniz
 * @author StellaMCR
 * @author hjcostabr
 */
export class NotificationHelper {

    private static readonly DEFAULT_DURATION = 4.5
    private static readonly DEFAULT_PLACEMENT = 'topRight' as NotificationPlacement

    private constructor() {}

    /** Gera notificacao de sucesso. */
    static success(title: string, message?: string, duration?: number, placement?: NotificationPlacement): void {
        this.showNotification(notification.success, title, message, duration, placement)
    }

    /** Gera notificacao de info. */
    static info(title: string, message?: string, duration?: number, placement?: NotificationPlacement): void {
        this.showNotification(notification.info, title, message, duration, placement)
    }

    /** Gera notificacao de alerta. */
    static warning(title: string, message?: string, duration?: number, placement?: NotificationPlacement): void {
        this.showNotification(notification.warning, title, message, duration, placement)
    }

    /** Gera notificacao de falha. */
    static error(title: string, message?: string, duration?: number, placement?: NotificationPlacement): void {
        this.showNotification(notification.error, title, message, duration, placement)
    }

    /**
     * Exibe 01 modal customizado para forcar 01 interacao com usuario do tipo
     * 'SOLICITACAO DE CONFIRMACAO'
     */
    static showConfirmationModal(config: ModalConfirmParamsTP): void {
        this.showModal(Modal.confirm, config)
    }

    /**
     * Exibe 01 modal customizado para forcar 01 interacao com usuario do tipo
     * 'NOTIFICACAO DE FALHA'
     */
    static showErrorModal(config: ModalConfirmParamsTP): void {
        config = {
            okText: 'OK',
            ...config
        }
        this.showModal(Modal.error, config)
    }

    /**
     * Exibe 01 modal customizado para forcar 01 interacao com usuario do tipo
     * 'NOTIFICACAO DE SUCESSO'
     */
    static showSuccessModal(config: ModalConfirmParamsTP): void {
        config = {
            okText: 'OK',
            ...config
        }
        this.showModal(Modal.success, config)
    }

    /**
     * Gera modal generico para forcar interacoes com usuario.
     * @see Modal
     */
    private static showModal(method: ModalFunc, config: ModalFuncProps): void {

        config = {
            okText: 'Sim',
            cancelText: 'Cancelar',
            ...config
        }

        method(config)
    }

    /**
     * Gera 01 notificacao generica.
     * @see NotificationApi
     */
    private static showNotification(method: (args: ArgsProps) => void, title: string, message?: string, duration?: number, placement?: NotificationPlacement): void {
        method({
            message: title,
            description: message,
            duration: duration ?? this.DEFAULT_DURATION,
            placement: placement ?? this.DEFAULT_PLACEMENT
        })
    }
}
