import { IsArray, IsOptional } from 'class-validator'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { DateUtils } from 'common/utils/DateUtils'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsString } from 'common/validation/decorators/IsString'
import { IProcessAlbumRequestDTO } from 'modules/batch/services/dtos/requests/IProcessAlbumRequestDTO'

export class NewProcessAlbumFormValidator extends FormModelValidator {

    @IsNotEmpty()
    @IsString()
    date: string

    @IsOptional()
    @IsString()
    notes?: string

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    pictures?: string[]

    constructor(dto?: IProcessAlbumRequestDTO) {
        super(dto)
        console.log(dto)
        if (!!dto) {
            this.date = DateUtils.getFormatted(dto.date, DateFormatEnum.BR_WITHOUT_TIME)
            this.notes = dto.notes
            this.pictures = dto.pictures
        }
    }
}
