import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { HarvestLogbookContentCP } from 'modules/harvest-planning/components/harvest-logbook-content/HarvestLogbookContentCP'
import { HavestPlanningDrawerCP } from 'modules/harvest-planning/components/harvest-planning-drawer/HarvestPlanningDrawerCP'
import { HarvestRoutes } from 'modules/harvest/HarvestRoutes'
import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

type RouteParamsTP = { code?: string }

export function HarvestLogbookScreen(): JSX.Element {
    const history = useHistory()
    const routeParams = useParams<RouteParamsTP>()

    const [harvestPlanningCode, setHarvestPlanningCode] = useState<number>()
    const [harvestPlanningDrawerVisible, setHarvestPlanningDrawerVisible] = useState<boolean>(false)

    useEffect(() => {
        if (!routeParams.code)
            return

        setHarvestPlanningCode(+routeParams.code)
    }, [routeParams])

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={`Diário de bordo: Colheita #${harvestPlanningCode ?? '---'}`}
                    icon={
                        <FontAwsomeIconCP icon={faArrowLeft} onClick={() => history.push(HarvestRoutes.PLANNING)} size={'1x'} />
                    }
                >
                    <ButtonCP
                        icon={<FontAwsomeIconCP icon={faPen} />}
                        size={'middle'}
                        type={'ghost'}
                        onClick={() => setHarvestPlanningDrawerVisible(true)}
                    >
                        Editar
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <HarvestLogbookContentCP />
                    <HavestPlanningDrawerCP
                        show={harvestPlanningDrawerVisible}
                        onCancel={() => setHarvestPlanningDrawerVisible(false)}
                        onSuccess={() => setHarvestPlanningDrawerVisible(false)}
                        harvestPlanningCode={harvestPlanningCode}
                    />
                </ContentCP>
            }
        />
    )
}
