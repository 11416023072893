import { NotificationHelper } from 'common/NotificationHelper'
import { RequestErrorTP } from 'common/request-helper/types/RequestErrorTP'
import * as _ from 'lodash'

/**
 * UTILS
 * Reune metodos auxiliares uteis para lidar com requisicoes http
 * realizadas utilizando as funcoes do modulo 'requests helpers'.
 *
 * @todo: Criar metodo generico que trata alteracoes em requisicoes
 *
 * @author hjcostabr
 */
export class RequestUtils {

    private constructor() { /** Construtor privado impede instanciacao. */ }

    /** Gera & retorna mensagem de notificacao de falha em requisicao obtida via procedimento generico. */
    static getErrorNotificationTxt(error: RequestErrorTP, defaultMsg: string): string {
        const error404message = 'Ocorreu um erro ao salvar os dados. Verifique todos os campos e tente novamente.'

        const apiErrorMsg = _.get(error, 'data.message')

        return (typeof apiErrorMsg === 'string')
            ? error?.status === 400 ? error404message : apiErrorMsg
            : defaultMsg
    }

    /** Exibe mensagem de notificacao de falha em requisicao determinada via procedimento generico. */
    static showDefaultErrorNotification(error: RequestErrorTP, defaultMsg: string, notificationTitle = 'Ops!'): void {
        const errorMsg = this.getErrorNotificationTxt(error, defaultMsg)
        NotificationHelper.error(notificationTitle, errorMsg)
    }
}
