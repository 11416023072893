import { InputCP } from 'common/components/form-fields/input/InputCP'

import React from 'react'
import styled from 'styled-components'
import { IPersonValuesUnitPayment } from 'modules/person/interfaces/IPersonValuesUnitPayment'
import { UnityUtils } from 'common/utils/UnityUtils'
import { PaymentUnityEnum } from 'modules/logbook/enums/PaymentUnityEnum'
import { StringUtils } from 'common/utils/StringUtils'
import { MaskUtils } from 'common/utils/MaskUtils'

interface IValuesPaymentUnitFormFieldsCPProps {
    valuesUnitPayment: IPersonValuesUnitPayment
    setValuesUnitPayment: (values: IPersonValuesUnitPayment) => void
}

export function ValuesPaymentUnitFormFields(props: IValuesPaymentUnitFormFieldsCPProps): JSX.Element {
    function onChangeValue(quantity: string, unitPayment: string): void {

        props.setValuesUnitPayment({
            ...props.valuesUnitPayment,
            [unitPayment]: !!quantity ? +MaskUtils.removeMask(quantity) : ''
        })
    }

    return (
        <MainWrapperSCP>
            <WrapperSCP>
                <InputCP
                    label={StringUtils.capitalize(UnityUtils.translatePaymentUnity(PaymentUnityEnum.DAYS))}
                    value={`${props.valuesUnitPayment.days ?? ''}`}
                    onChange={(quantity) => onChangeValue(quantity, 'days')}
                    type={'currency'}
                    icon={
                        <span>
                            /
                            {UnityUtils.getUnityAbreviation(PaymentUnityEnum.DAYS)}
                        </span>
                    }
                />
                <InputCP
                    label={StringUtils.capitalize(UnityUtils.translatePaymentUnity(PaymentUnityEnum.HOURS))}
                    value={`${props.valuesUnitPayment.hours ?? ''}`}
                    onChange={(quantity) => onChangeValue(quantity, 'hours')}
                    type={'currency'}
                    icon={
                        <span>
                            /
                            {UnityUtils.getUnityAbreviation(PaymentUnityEnum.HOURS)}
                        </span>
                    }
                />
                <InputCP
                    label={StringUtils.capitalize(UnityUtils.translatePaymentUnity(PaymentUnityEnum.LITERS))}
                    value={`${props.valuesUnitPayment.liters ?? ''}`}
                    onChange={(quantity) => onChangeValue(quantity, 'liters')}
                    type={'currency'}
                    icon={
                        <span>
                            /
                            {UnityUtils.getUnityAbreviation(PaymentUnityEnum.LITERS)}
                        </span>
                    }
                />
                <InputCP
                    label={StringUtils.capitalize(UnityUtils.translatePaymentUnity(PaymentUnityEnum.TREES))}
                    value={`${props.valuesUnitPayment.trees ?? ''}`}
                    onChange={(quantity) => onChangeValue(quantity, 'trees')}
                    type={'currency'}
                    icon={
                        <span>
                            /
                            {UnityUtils.getUnityAbreviation(PaymentUnityEnum.TREES)}
                        </span>
                    }
                />
                <InputCP
                    label={StringUtils.capitalize(UnityUtils.translatePaymentUnity(PaymentUnityEnum.HECTARES))}
                    value={`${props.valuesUnitPayment.hectares ?? ''}`}
                    onChange={(quantity) => onChangeValue(quantity, 'hectares')}
                    type={'currency'}
                    icon={
                        <span>
                            /
                            {UnityUtils.getUnityAbreviation(PaymentUnityEnum.HECTARES)}
                        </span>
                    }
                />
            </WrapperSCP>
        </MainWrapperSCP>

    )
}

const MainWrapperSCP = styled.div``

const WrapperSCP = styled.div`
    margin: 10px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;

    @media(max-width: 786px){
        grid-template-columns: 1fr 1fr ;
        row-gap: 10px;
    }
`