import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { FieldFormCP } from 'modules/field/components/field-form/FieldFormCP'
import { IFieldResponseDTO } from 'modules/field/services/dtos/responses/IFieldResponseDTO'
import React from 'react'

interface IFieldDrawerCPProps{
    propertyCode: number
    field?: IFieldResponseDTO
    visible: boolean
    onClose: () => void
    reloadList: () => void
}

export function FieldDrawerCP(props: IFieldDrawerCPProps): JSX.Element {
    return (
        <DrawerCP
            title={'Novo Talhão'}
            visible={props.visible}
            onClose={props.onClose}
        >
            <FieldFormCP
                propertyCode={props.propertyCode}
                field={props.field}
                onSuccess={() => {
                    props.onClose()
                    props.reloadList()
                }}
            />
        </DrawerCP>
    )
}