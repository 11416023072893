/* eslint-disable react/jsx-one-expression-per-line */
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Steps } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { IStepProps } from 'common/components/steps/interface/IStepProps'
import React, { useState, CSSProperties } from 'react'
import styled from 'styled-components'

interface IStepsCPProps {
    type?: 'default' | 'navigation'
    current: number
    direction?: 'horizontal' | 'vertical'
    iconPrefix?: string
    initial?: number
    labelPlacement?: 'horizontal' | 'vertical'
    size?: 'default' | 'small'
    status?: 'wait' | 'process' | 'finish' | 'error'
    style?: CSSProperties
    percent?: number
    onChange: (current: number) => void
    onFinish: () => void
    showNavigationButtons?: boolean
    steps: IStepProps[]
    disableNext?: boolean
    disablePrev?: boolean
    loading?: boolean
    prevNavigationConfirmMsg?: string
}

export function StepsCP(props: IStepsCPProps): JSX.Element {
    const Step = Steps.Step
    const [confirmVisible, setConfirmVisible] = useState<boolean>(false)

    return (
        <StepWppSCP currentStep={props.current} stepQtt={props.steps.length}>
            <Steps
                type={props.type}
                current={props.current}
                direction={props.direction}
                iconPrefix={props.iconPrefix}
                initial={props.initial}
                labelPlacement={props.labelPlacement}
                size={props.size}
                status={props.status}
                style={props.style}
                percent={props.percent}
                onChange={props.onChange}
            >
                {props.steps.map((item, index) => (
                    <Step
                        key={`step_${index}`}
                        className={`step_${index}`}
                        title={item.title}
                        description={item.description}
                        icon={item.icon}
                        onClick={item.onClick}
                        status={item.status}
                        disabled={item.disabled}
                        subTitle={item.subTitle}
                        style={item.style}
                    />

                ))}
            </Steps>
            {
                !!props.steps[props.current].content &&
                <StepContentSCP>
                    {props.steps[props.current].content}
                    {
                        props.showNavigationButtons &&
                        <ContentFooterSCP>
                            {
                                props.current !== 0 ?
                                    <PrevBtnWrapperSCP>
                                        {
                                            !!props.prevNavigationConfirmMsg ?
                                                <PopOverCP
                                                    placement={'right'}
                                                    visible={confirmVisible}
                                                    onClose={() => setConfirmVisible(false)}
                                                    content={
                                                        <ConfirmMsgWppSCP>
                                                            <TextSCP><FontAwsomeIconCP icon={faExclamationCircle} /> {props.prevNavigationConfirmMsg}</TextSCP>
                                                            <ActionsSCP>
                                                                <ContinueActionSCP
                                                                    onClick={() => {
                                                                        setConfirmVisible(false)
                                                                        props.onChange(props.current - 1)
                                                                    }}
                                                                >
                                                                    Sim
                                                                </ContinueActionSCP>
                                                                <DenyActionSCP onClick={() => setConfirmVisible(false)}>
                                                                    Não
                                                                </DenyActionSCP>
                                                            </ActionsSCP>
                                                        </ConfirmMsgWppSCP>
                                                    }
                                                >
                                                    <ButtonCP
                                                        type={'ghost'}
                                                        onClick={() => setConfirmVisible(true)}
                                                        disabled={props.disablePrev}
                                                        loading={props.loading}
                                                    >
                                                        Anterior
                                                    </ButtonCP>
                                                </PopOverCP>
                                                :
                                                <ButtonCP
                                                    onClick={() => props.onChange(props.current - 1)}
                                                    type={'ghost'}
                                                    disabled={props.disablePrev}
                                                    loading={props.loading}
                                                >
                                                    Anterior
                                                </ButtonCP>
                                        }
                                    </PrevBtnWrapperSCP>
                                    :
                                    <span />
                            }
                            {
                                props.current === props.steps.length - 1 ?
                                    <ButtonCP
                                        type={'primary'}
                                        onClick={props.onFinish}
                                        loading={props.loading}
                                    >
                                        Salvar
                                    </ButtonCP>
                                    :
                                    <ButtonCP
                                        type={'primary'}
                                        onClick={() => props.onChange(props.current + 1)}
                                        disabled={props.disableNext}
                                        loading={props.loading}
                                    >
                                        Próximo
                                    </ButtonCP>
                            }
                        </ContentFooterSCP>
                    }
                </StepContentSCP>
            }
        </StepWppSCP>
    )
}

const StepWppSCP = styled.div<{ currentStep: number, stepQtt: number }>`
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description{
        max-width: 100%;
        span{
            color: ${props => props.theme.gray};
            font-size: 11px;
        }
    }
    .ant-steps-navigation .ant-steps-item-container{
        width: 100%;
    }
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item{
        padding-left: 38px;
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
        background: ${props => props.theme.primaryColor}!important;
    }

    @media(max-width: 767px){
       
        ${(props: { currentStep: number, stepQtt: number }): string[] => {
        const arr = [...Array(+props.stepQtt).keys()]
        return (arr.map(stp => {
            return (
                `.step_${stp}{
                            display: ${stp === props.currentStep ? 'block' : 'none'};
                        }`
            )
        }))
    }}
        
    }
`
const StepContentSCP = styled.div`
    margin: 10px;
    padding: 10px;
    @media(max-width: 767px){
        margin: 0;
        padding: 0;
    }
`
const ContentFooterSCP = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    width: 95%;
    background: white;
    border-top: 1px solid ${props => props.theme.lightGray};
    @media(max-width: 767px){
        width: 90%;
    }
`
const PrevBtnWrapperSCP = styled.div`
   position: relative;
`

const ConfirmMsgWppSCP = styled.div`
   max-width: 300px;
   svg {
       font-size: 14px;
       margin-right: 8px;
       color: ${props => props.theme.warningColor};
   }
}
`
const ActionsSCP = styled.div`
    margin: 7px 0;
    display: flex;
    justify-content: flex-end;
`

const ContinueActionSCP = styled.div`
    cursor: pointer;
    padding: 8px 13px;
    margin: 0 5px; 
    border-radius: 3px;
    background: ${props => props.theme.gray};
    color: white;
`

const DenyActionSCP = styled.div`
    cursor: pointer;
    padding: 8px 13px;
    margin: 0 5px; 
    border-radius: 3px;
    background: ${props => props.theme.primaryColor};
    color: white;
`

const TextSCP = styled.div`
      display: flex;
`