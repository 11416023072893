/* eslint-disable react/jsx-no-useless-fragment */

import React, { useEffect, useState } from 'react'

import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { EquipmentSelectorCP } from 'modules/maneuver/components/equipment-selector/EquipmentSelectorCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { IProductResponseDTO } from 'modules/maneuver/services/dtos/responses/IProductResponseDTO'
import { IProductUsageResDTO } from 'modules/maneuver/services/dtos/responses/IProductUsageResDTO'
import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'
import { ImplementTypeEnum } from 'modules/maneuver/enums/ImplementTypeEnum'
import { ImplementUsabilityCardCP } from 'modules/maneuver/components/implement-usability-card/ImplementUsabilityCardCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { LogbookFormValidator } from 'modules/logbook/components/logbook-form/inner/LogbookFormValidator'
import { ManeuverUtils } from 'modules/maneuver/utils/ManeuverUtils'
import { MaskUtils } from '../../../../../common/utils/MaskUtils'
import { ProductRequests } from 'modules/maneuver/services/ProductRequests'
import { ProductUsageFormValidator } from 'modules/logbook/components/product-usage/inner/ProductUsageFormValidator'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { Skeleton } from 'antd'
import { TextCP } from 'common/components/text/TextCP'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useRequest } from 'common/request-helper/UseRequest'
import { useScreenSize } from 'common/hooks/UseScreenSize'

interface IImplementFormFieldsICPProps {
    fieldCode?: number
    harvestPlanningCode?: number
    logbookEntryCode?: number
    recipe?: IRecipeResponseDTO
    formStateManager: IFormStateManager<LogbookFormValidator>
    implementType: ImplementTypeEnum
    savedHourMeter?: number
    savedImpCode?: number
}

/**
 * COMPONENTE
 * Campos de implemento de 1 formulario de logbook
 */
export function ImplementFormFieldsICP(props: IImplementFormFieldsICPProps): JSX.Element {
    const screenSize = useScreenSize()

    const fieldName = props.implementType === ImplementTypeEnum.EQUIPMENT ? 'equipmentCode' : 'vehicleCode'
    const fuelConsumeFieldName = props.implementType === ImplementTypeEnum.EQUIPMENT ? 'equipmentFuelConsume' : 'vehicleFuelConsume'

    const prodSelected = props.formStateManager.getFieldValue(fieldName)

    const [loadedValues, setLoadedValues] = useState<boolean>(false)
    const [noneLoaded, setNoneLoaded] = useState<boolean>(true)
    const [fuelFieldVisible, setFuelFieldVisible] = useState<boolean>(false)
    const [quantity, setQuantity] = useState<number>()
    const [fuelData, setFuelData] = useState<IProductResponseDTO>()
    const [fuelConsumeData, setFuelConsumeData] = useState<IProductUsageResDTO>()

    const getFuelDataReq = useRequest<IProductResponseDTO>()
    useEffect(onGetFuelDataReqChange, [getFuelDataReq.awaiting])

    const [formValidator, setFormValidator] = useState<ProductUsageFormValidator>(new ProductUsageFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    useEffect(init, [])

    function init(): void {
        if (!!props.formStateManager.getFieldValue(fuelConsumeFieldName)) {
            setFormValidator(new ProductUsageFormValidator({
                implementCode: props.formStateManager.getFieldValue(fuelConsumeFieldName).implementCode,
                ...props.formStateManager.getFieldValue(fuelConsumeFieldName),
            }))
            setFuelConsumeData(props.formStateManager.getFieldValue(fuelConsumeFieldName))
            setFuelFieldVisible(true)
            setLoadedValues(false)
            return
        }

        setLoadedValues(true)
    }

    function onGetFuelDataReqChange(): void {

        if (getFuelDataReq.awaiting || !getFuelDataReq.tried)
            return

        if (!getFuelDataReq.success || !getFuelDataReq.returnData) {
            if (getFuelDataReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getFuelDataReq.returnData, getFuelDataReq.error)

            return RequestUtils.showDefaultErrorNotification(getFuelDataReq.error, 'Erro ao buscar dados do combustível.')
        }

        setFuelData(getFuelDataReq.returnData)
    }

    function onSelectImplement(code: number, fuelCode?: number, currentHourMeter?: number): void {

        setLoadedValues(true)
        formStateManager.changeFieldValue('implementCode', code)

        if (currentHourMeter) {

            if (!!props.savedImpCode && code === props.savedImpCode)
                props.formStateManager.changeFieldValue('startHourMeter', props.savedHourMeter)

            else
                props.formStateManager.changeFieldValue('startHourMeter', currentHourMeter)

        }

        if (!fuelCode) {
            setFuelFieldVisible(false)
            formStateManager.reset()
        } else {
            setFuelFieldVisible(true)
            formStateManager.changeFieldValue('productCode', fuelCode)
            getFuelDataReq.runRequest(ProductRequests.getProduct(fuelCode))
        }
    }

    function onClearImplement(): void {
        props.formStateManager.changeFieldValue(fieldName, undefined)
        props.formStateManager.changeFieldValue(fuelConsumeFieldName, undefined)
        formStateManager.reset()
    }

    function onChangeQuantity(qtt: number): void {
        setQuantity(qtt)
        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)

        if (!qtt || !formValues)
            return

        props.formStateManager.changeFieldValue(fuelConsumeFieldName, new ProductUsageFormValidator({
            productCode: formValues.productCode,
            quantity: +qtt,
            implementCode: formValues.implementCode,
        }))

    }
    console.log(noneLoaded)
    return (
        <WrapperSCP>
            <EquipmentSelectorCP
                label={`Selecione o ${ManeuverUtils.translateImplementType(props.implementType, true)}`}
                formStateManager={props.formStateManager}
                onlyVehicles={props.implementType === ImplementTypeEnum.VEHICLE}
                onSelect={(code, isVehicle, fCode, currentHourMeter) => onSelectImplement(code, fCode, currentHourMeter)}
                onClear={onClearImplement}
                setNoneLoaded={setNoneLoaded}
            />
            {
                !loadedValues ?
                    <Skeleton active={true} /> :
                    <>
                        {
                            !!prodSelected && fuelFieldVisible &&
                            <UsageFieldWrpSCP>
                                <InputCP
                                    label={'Gasto de combustível'}
                                    type={'number'}
                                    required={true}
                                    placeholder={'Qtd. gasta'}
                                    formStateManager={formStateManager}
                                    fieldName={'quantity'}
                                    onChange={onChangeQuantity}
                                    fontSize={screenSize.smd ? 'large' : 'normal'}
                                    icon={<div>litros</div>}
                                    loading={getFuelDataReq.awaiting}
                                />
                                <InfoWrpSCP>

                                    <FontAwsomeIconCP icon={faInfoCircle} size={'2x'} />
                                    {
                                        getFuelDataReq.awaiting ?
                                            <FuelDataWrpSCP>
                                                <Skeleton.Input active={true} />
                                            </FuelDataWrpSCP>
                                            :
                                            <FuelDataWrpSCP>
                                                {
                                                    !!fuelConsumeData && !!fuelConsumeData.quantity &&
                                                    <TextCP text={`Gasto prévio: ${fuelConsumeData.quantity} litros`} />
                                                }
                                                {
                                                    !!fuelData &&
                                                    <TextCP
                                                        text={`(${fuelData.name}) Quantidade em estoque: ${MaskUtils.applyNumberMask(fuelData.remainingQuantity)} litros`}
                                                    />
                                                }

                                                {
                                                    !!quantity && !!fuelData &&
                                                    <>
                                                        {
                                                            !!fuelConsumeData && !!fuelConsumeData.quantity ?
                                                                <TextCP
                                                                    text={`Restante após aplicação: ${ProductUtils.calculateRemainingQtdAfterUpdate(fuelData.remainingQuantity, fuelConsumeData.quantity, quantity, true)}`}
                                                                />

                                                                :
                                                                <TextCP
                                                                    text={`Restante após aplicação: ${ProductUtils.calculateRemainingQtdAfterAplication(fuelData.remainingQuantity, quantity, true)}`}
                                                                />
                                                        }
                                                    </>
                                                }
                                            </FuelDataWrpSCP>

                                    }

                                </InfoWrpSCP>
                            </UsageFieldWrpSCP>

                        }
                        {
                            props.implementType === ImplementTypeEnum.VEHICLE
                            && !noneLoaded &&
                            <TwoColWrapperSCP>
                                <InputCP
                                    label={'Horímetro inicial'}
                                    fieldName={'startHourMeter'}
                                    type={'number'}
                                    required={true}
                                    placeholder={'Qtd. de horas'}
                                    formStateManager={props.formStateManager}
                                    fontSize={screenSize.smd ? 'large' : 'normal'}
                                    icon={<div>horas</div>}
                                />
                                <InputCP
                                    label={'Horímetro final'}
                                    type={'number'}
                                    placeholder={'Qtd. de horas'}
                                    fieldName={'endHourMeter'}
                                    formStateManager={props.formStateManager}
                                    fontSize={screenSize.smd ? 'large' : 'normal'}
                                    icon={<div>horas</div>}
                                />
                            </TwoColWrapperSCP>
                        }
                        {

                            !!props.recipe &&
                            !!props.recipe.formulas &&
                            !!props.recipe.formulas.length &&
                            !!prodSelected &&
                            !!props.fieldCode &&
                            <ImplementUsabilityCardCP
                                implementCode={props.formStateManager.getFieldValue('equipmentCode')}
                                recipe={props.recipe}
                                fieldCode={props.fieldCode}
                            />
                        }
                    </>
            }

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

`
const TwoColWrapperSCP = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`

const UsageFieldWrpSCP = styled.div`
  display: grid;
  grid-template-columns: .8fr 1fr;
  column-gap: 10px;
  align-items: end;
  justify-content: center;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

`

const FuelDataWrpSCP = styled.div`
  .ant-typography {
    font-size: 11px;
  }

`
const InfoWrpSCP = styled.div`
  display: grid;
  grid-template-columns: .2fr 1fr;
  column-gap: 10px;
  margin-bottom: 3px;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  position: relative;
  border: 1px solid ${props => props.theme.primaryColor};
  svg {
      color:  ${props => props.theme.primaryColor};
  }

  .ant-typography {
    font-size: 12px;
  }
`