import { IsOptional, ValidateIf } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-useless-constructor */

import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'
import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'

export class RecipeFormValidator extends FormModelValidator {

    @IsNotEmpty()
    title: string

    @IsNotEmpty()
    type: RecipeTypeEnum

    @IsOptional()
    description: string

    @IsOptional()
    mixTotalLiters?: number


    constructor(dto?: IRecipeResponseDTO) {
        super(dto)
        Object.assign(this, dto)
    }
}