import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { IProductUsageReqDTO } from 'modules/maneuver/services/dtos/requests/IProductUsageReqDTO'
import { ISaveProductUsagesReqDTO } from 'modules/maneuver/services/dtos/requests/ISaveProductUsagesReqDTO'
import { ISaveLogbookItemReqDTO } from 'modules/harvest-planning/services/dtos/request/ISaveLogbookItemReqDTO'

export class LogbookRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/logbooks`

    private constructor() { }

    static get = (code: number): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static save = (code: number, dto: ISaveLogbookItemReqDTO): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static update = (maneuverCode: number, code: number, dto: ISaveLogbookItemReqDTO): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static addImages = (code: number, data: FormData): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${code}/pictures`,
        method: HttpMethodEnum.PUT,
        params: data,
        headers: { 'content-type': 'multipart/form-data' }
    })

    static removeImages = (code: number, dto: { pictures: string[] }): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${code}/pictures`,
        method: HttpMethodEnum.DELETE,
        params: dto,
    })

    static getFuelConsumeData = (maneuverCode: number, logbookCode: number, implementCode: number): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${logbookCode}/fuel-consume/${implementCode}`,
        method: HttpMethodEnum.GET,
    })

    static getProductUsage = (maneuverCode: number, logbookCode: number): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${logbookCode}/product-usage/`,
        method: HttpMethodEnum.GET,
    })

    static saveProductUsage = (maneuverCode: number, logbookCode: number, dto: IProductUsageReqDTO): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${logbookCode}/product-usage/`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updateProductUsage = (code: number, maneuverCode: number, logbookCode: number, dto: IProductUsageReqDTO): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${logbookCode}/product-usage/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static saveProductUsageList = (maneuverCode: number, logbookCode: number, dto: ISaveProductUsagesReqDTO): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${logbookCode}/product-usages/`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updateProductUsageList = (maneuverCode: number, logbookCode: number, dto: ISaveProductUsagesReqDTO): RequestConfigTP => ({
        url: `${LogbookRequests.baseApiUrl}/${logbookCode}/product-usages/`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })
}
