import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { CarouselCP } from 'common/components/carousel/CarouselCP'
import { SliderCarouselCP } from 'common/components/slider-carousel/SliderCarouselCP'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { LoadingBoxCP } from 'modules/batch-presentation/components/loading-box/LoadingBoxCP'
import { ProcessCardICP } from 'modules/batch-presentation/components/process-carrousel/inner/ProcessCardICP'
import { BatchPresentationRequests } from 'modules/batch-presentation/services/BatchPresentationRequests'
import { IBatchProcessResumeResDTO } from 'modules/batch-presentation/services/dtos/response/IBatchProcessResumeResDTO'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'


type RouteParamsTP = { batchCode: string }

interface IProcessCarrouselCPProps {
}

export function ProcessCarrouselCP(props: IProcessCarrouselCPProps): JSX.Element {
    const routeParams = useParams<RouteParamsTP>()
    const [resume, setResume] = useState<IBatchProcessResumeResDTO[]>()


    const [showCarousel, setShowCarousel] = useState<boolean>(false)

    const [batchCode, setBatchCode] = useState<number>()
    useEffect(() => setBatchCode(+routeParams.batchCode), [routeParams])

    const getInfoReq = useRequest<IBatchProcessResumeResDTO[]>()
    useEffect(onRequestChange, [getInfoReq.awaiting])

    useEffect(getDetails, [batchCode])

    function getDetails(): void {
        if (!!batchCode)
            getInfoReq.runRequest(BatchPresentationRequests.getProcessesResume(batchCode))
    }

    function onRequestChange(): void {

        if (getInfoReq.awaiting || !getInfoReq.tried)
            return

        if (!getInfoReq.success) {
            if (getInfoReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getInfoReq.returnData, getInfoReq.error)
            return RequestUtils.showDefaultErrorNotification(getInfoReq.error, 'Some error occurred. Please, try to reload the page.')
        }

        setResume(_.orderBy(getInfoReq.returnData, (data) => new Date(data.date).getTime(), ['asc']))
    }

    if (!resume || getInfoReq.awaiting) {
        return (
            <LoadingBoxCP />
        )
    }

    return (
        <WrapperSCP>
            <TitleSCP>
                <TextCP
                    text={'postHarvestDetails'}
                    style={{ fontSize: '23px', fontWeight: 600 }}
                    translationFiles={'batch'}
                    translationNameSpace={'batch'}
                />
                <ButtonCP
                    onClick={() => setShowCarousel(!showCarousel)}
                    type={'primary'}
                    style={{
                        height: '50px',
                        width: '50px',
                        justifyContent: 'center',
                        borderRadius: '100%',
                        position: 'absolute',
                        top: '-.5em',
                        right: '-2em'
                    }}
                    icon={<FontAwesomeIcon icon={showCarousel ? faTimes : faPlus} />}
                />

            </TitleSCP>




            {
                showCarousel &&
                <CarouselWrapperSCP>
                    <SliderCarouselCP
                        slidesToShow={6}
                        slidesToScroll={1}
                        dots={true}
                        infinite={false}
                        speed={500}
                        draggable={true}
                        responsive={[
                            {
                                breakpoint: 1600,
                                settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 1,
                                    initialSlide: 1
                                }
                            },

                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                    initialSlide: 1
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]}
                    >
                        {
                            resume.map(item => (<ProcessCardICP key={item.date} process={item} />))
                        }
                    </SliderCarouselCP>
                </CarouselWrapperSCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 2em 3em 3em 3em;
    background: white;
    border-radius: 50px 0 0 0;
`

const CarouselWrapperSCP = styled.div`
    margin-top: 2em;
    @media(max-width: 768px ){
       
        .slick-slider .slick-prev {
            left: -31px;
        }
    }
`
const TitleSCP = styled.div`
    display: flex;
   position: relative;
`