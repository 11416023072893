import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import { MaskUtils } from '../../../../../common/utils/MaskUtils'

interface IImplementCardContentICPProps {
    capacity?: number
    year: number
    fuelName?: string
    currentHourMeter?: number
    lastRevisionDate?: string
}

/**
 * COMPONENTE
 * Conteudo central de um item da lista de equipamento/veículo
 */
export function ImplementCardContentICP(props: IImplementCardContentICPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }

    return (
        <WrapperSCP>
            <RowSCP>
                <TextCP text={'Ano: '} />
                <TextCP
                    text={`${props.year}`}
                    style={valueStyle}
                />
            </RowSCP>
            {!!props.fuelName &&
                <RowSCP>
                    <TextCP text={'Combustível: '} />
                    <TextCP
                        text={props.fuelName}
                        style={valueStyle}
                    />
                </RowSCP>}
            {!!props.currentHourMeter &&
                <RowSCP>
                    <TextCP text={'Horímetro atual: '} />
                    <TextCP
                        text={`${props.currentHourMeter}`}
                        style={valueStyle}
                    />
                </RowSCP>}
            {!!props.lastRevisionDate &&
                <RowSCP>
                    <TextCP text={'Data da úlitma revisão: '} />
                    <TextCP text={`${DateUtils.getFormatted(props.lastRevisionDate, DateFormatEnum.BR_WITHOUT_TIME)}`} style={valueStyle} />
                </RowSCP>}
            {!!props.capacity &&
                <RowSCP>
                    <TextCP text={'Capacidade: '} />
                    <TextCP
                        text={`${MaskUtils.applyNumberMask(props.capacity)} L`}
                        style={valueStyle}
                    />
                </RowSCP>}
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 0 10px;   
    min-height: 93px;
`
const RowSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    margin-bottom: 5px;
    .ant-typography{
        font-size: 13px;
    }
`
