import { LanguageActions } from 'common/components/translation/LanguageActions'
import { LanguageTP } from 'common/components/translation/types/LanguageTP'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

interface ILangSwitchCPProps {
}

export function LangSwitchCP(props: ILangSwitchCPProps): JSX.Element {
    const currentLang = useSelector((state: IReduxState) => state.language)
    useEffect(getLang, [])

    function getLang(): void {
        LanguageActions.swithLanguage(i18next.language as LanguageTP)
    }

    async function changeLanguage(lang: LanguageTP): Promise<void> {

        await i18next
            .changeLanguage(lang)
            .then((t) => {
                t(lang) // -> same as i18next.t
            })

        LanguageActions.swithLanguage(lang)
    }

    return (
        <WrapperSCP>
            <PtBrButtonSCP onClick={async () => await changeLanguage('pt')} style={{ fontWeight: currentLang === 'pt' ? 600 : 400 }}>
                Português
            </PtBrButtonSCP>
            <EnUsButtonSCP onClick={async () => await changeLanguage('en')} style={{ fontWeight: currentLang === 'en' ? 600 : 400 }}>
                English
            </EnUsButtonSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    position: absolute;
    right: 2em;
    top: 3.5em;
`
const PtBrButtonSCP = styled.div`
    color: white;
    cursor: pointer;
    font-weight: 600px;
`
const EnUsButtonSCP = styled.div`
    color: white;
    cursor: pointer;
    font-weight: 600px;
`