import { faArrowLeft, faHandHoldingUsd, faPen } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { BatchDrawerCP } from 'modules/batch/components/batch-drawer/BatchDrawerCP'
import { BatchProcessDetailsContentCP } from 'modules/batch/components/batch-process-details-content/BatchProcessDetailsContentCP'
import { BatchSaleDrawerCP } from 'modules/batch/components/batch-sale-drawer/BatchSaleDrawerCP'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'


type RouteParamsTP = { code?: string }

export function BatchProcessScreen(): JSX.Element {
    const history = useHistory()
    const routeParams = useParams<RouteParamsTP>()

    const [batchCode, setBatchCode] = useState<number>()
    const [batchDrawerVisible, setBatchDrawerVisible] = useState<boolean>(false)
    const [batchSaleDrawerVisible, setBatchSaleDrawerVisible] = useState<boolean>(false)
    const [saleUnavailable, setSaleUnavailable] = useState<boolean>(false)
    const [hasSales, setHasSales] = useState<boolean>(false)

    useEffect(() => {
        if (!routeParams.code)
            return

        setBatchCode(+routeParams.code)
    }, [routeParams])

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={`Processos: Lote #${batchCode ?? '--'}`}
                    icon={
                        <FontAwsomeIconCP icon={faArrowLeft} onClick={() => history.goBack()} size={'1x'} />
                    }
                >

                    <ButtonCP
                        icon={<FontAwsomeIconCP icon={faPen} />}
                        size={'middle'}
                        type={'ghost'}
                        disabled={!batchCode}
                        onClick={() => setBatchDrawerVisible(true)}
                    >
                        Editar
                    </ButtonCP>
                    <ButtonCP
                        icon={<FontAwsomeIconCP icon={faHandHoldingUsd} />}
                        size={'middle'}
                        type={'ghost'}
                        disabled={!batchCode}
                        onClick={() => setBatchSaleDrawerVisible(true)}
                    >
                        Venda
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <BatchProcessDetailsContentCP
                        setHasSales={setHasSales}
                        hasSales={hasSales}
                        setSaleUnavailable={setSaleUnavailable}
                    />
                    {!!batchCode &&
                        <BatchDrawerCP
                            batchCode={batchCode}
                            show={batchDrawerVisible}
                            onCancel={() => setBatchDrawerVisible(false)}
                            onSuccess={() => {
                                setBatchDrawerVisible(false)
                                ListActions.unselectAll()
                            }}
                        />}

                    {!!batchCode &&
                        <BatchSaleDrawerCP
                            batchCode={batchCode}
                            show={batchSaleDrawerVisible}
                            saleUnavailable={saleUnavailable}
                            onCancel={() => setBatchSaleDrawerVisible(false)}
                            onSuccess={() => ListActions.mustUpdate()}

                        />}
                </ContentCP>
            }
        />
    )
}
