import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { LogbookRequests } from 'modules/logbook/services/LogbookRequests'
import { TextCP } from 'common/components/text/TextCP'

import { AvatarCP } from 'common/components/avatar/AvatarCP'

import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { LogbookUtils } from 'modules/logbook/utils/LogbookUtils'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { ILogbookResponseDTO } from 'modules/harvest-planning/services/dtos/response/ILogbookResponseDTO'
import { HarvestDetailsICP } from 'modules/logbook/components/logbook-details-drawer/inner/HarvestDetailsICP'
import { ImplementDetailsICP } from 'modules/logbook/components/logbook-details-drawer/inner/ImplementDetailsICP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { UnityUtils } from 'common/utils/UnityUtils'

interface ILogbookDetailsDrawerCPProps {
    logbookEntryCode: number
    show: boolean
    onCancel: () => void
}

export function LogbookDetailsDrawerCP(props: ILogbookDetailsDrawerCPProps): JSX.Element {
    const [entry, setEntry] = useState<ILogbookResponseDTO>()
    useEffect(getDetails, [props.logbookEntryCode, props.show])

    const request = useRequest<ILogbookResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    function getDetails(): void {
        if (!!props.logbookEntryCode && props.show)
            request.runRequest(LogbookRequests.get(props.logbookEntryCode))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar detalhes do item!')
        }
        setEntry(request.returnData)
    }

    return (
        <DrawerCP
            title={`Detalhes do registro #${props.logbookEntryCode}`}
            visible={props.show}
            width={'60%'}

            onClose={() => {
                props.onCancel()

            }}
        >
            <MainWrapperSCP>
                {
                    !!entry ?
                        <>
                            <HeaderWrapperSCP>
                                <DateTimelineSCP>
                                    <TextCP
                                        text={moment(entry.date).format('DD')}
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            color: 'white',
                                            lineHeight: '18px',
                                            leterSpacing: '3px'
                                        }}
                                    />
                                    <TextCP
                                        text={moment(entry.date).locale('pt-br').format('MMM')}
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            color: 'white',
                                            lineHeight: '13px',
                                        }}
                                    />
                                </DateTimelineSCP>
                                <CenterInfoWrapperSCP>
                                    <DateAndTimeSCP>
                                        <TextCP
                                            text={`${LogbookUtils.getDateText(entry.date)}`}
                                            style={{ fontSize: '14px', fontWeight: 500, color: `${CostumizedThemeConfig.primaryColor}` }}
                                        />
                                    </DateAndTimeSCP>
                                    <ResponsibleWrapperSCP>
                                        {
                                            !!entry.peopleResponsible &&
                                            entry.peopleResponsible.map(personRes => (
                                                <ResponsibleInfoSCP key={`${personRes.person.name}_${personRes.code}_key`}>
                                                    <AvatarCP src={personRes.person.profilePicture} size={30} />
                                                    <NameAndOccupationSCP>
                                                        <TextCP
                                                            text={`${personRes.person.name}`}
                                                            strong={true}
                                                            style={{ fontSize: '12px' }}
                                                        />

                                                        <TextCP
                                                            text={`${personRes.quantity} ${UnityUtils.translatePaymentUnity(personRes.paymentUnity)}`}
                                                            style={{ fontSize: '12px', color: `${CostumizedThemeConfig.gray}` }}
                                                        />
                                                    </NameAndOccupationSCP>
                                                </ResponsibleInfoSCP>
                                            ))
                                        }
                                    </ResponsibleWrapperSCP>
                                    {
                                        entry.notes &&
                                        <NoteWrapperSCP>
                                            {entry.notes}
                                        </NoteWrapperSCP>
                                    }

                                </CenterInfoWrapperSCP>
                            </HeaderWrapperSCP>

                            <CenterInfoWrapperSCP>
                                {
                                    !!entry.harvest &&
                                    <HarvestDetailsICP harvest={entry.harvest} />
                                }

                                {
                                    !!entry.equipment &&
                                    <ImplementDetailsICP
                                        implement={entry.equipment}
                                        summary={entry.equipmentSummary}
                                    />
                                }
                                {
                                    !!entry.vehicle &&
                                    <ImplementDetailsICP
                                        implement={entry.vehicle}
                                        summary={entry.vehicleSummary}
                                    />
                                }
                            </CenterInfoWrapperSCP>
                        </>
                        :
                        <>
                            <SkeletonsSCP>
                                <GenericRowSkeletonICP columns={2} />
                                <GenericRowSkeletonICP columns={1} />
                                <GenericRowSkeletonICP columns={2} />
                            </SkeletonsSCP>
                            <SkeletonsSCP>
                                <GenericRowSkeletonICP columns={2} />
                                <GenericRowSkeletonICP columns={2} />
                                <GenericRowSkeletonICP columns={2} />
                            </SkeletonsSCP>
                            <SkeletonsSCP>
                                <GenericRowSkeletonICP columns={2} />
                                <GenericRowSkeletonICP columns={1} />
                                <GenericRowSkeletonICP columns={2} />
                            </SkeletonsSCP>
                        </>

                }
            </MainWrapperSCP>
        </DrawerCP>
    )
}

const MainWrapperSCP = styled.div`
   
    position: relative;
   
`

const HeaderWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: .3fr 5fr 1fr;
    margin: 10px;
    position: relative;
    column-gap: 20px;
    @media(max-width: 768px){
        grid-template-columns: 40px 1fr;
    }
`

const NoteWrapperSCP = styled.div`
    background: #e6e6e6;
    padding: 10px;
    border-radius: 5px;
`
const CenterInfoWrapperSCP = styled.div`
    margin: 10px 0 10px 12px;
    display: grid;
    row-gap: 8px;
`
const DateAndTimeSCP = styled.div`

`
const DateTimelineSCP = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    align-items: center;
    background: ${entry => entry.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    justify-content: center;
    position: relative;


`
const SkeletonsSCP = styled.div`
    margin: 35px 0;
`

const ActionsWrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .ant-btn{
        margin-left: 5px;
    }
`

const ResponsibleInfoSCP = styled.div`
    display: flex;
    margin: 5px 0;
`

const NameAndOccupationSCP = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`

const ResponsibleWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    @media(max-width: 768px){
        grid-template-columns: 1fr 1fr;
    }
`