import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { CheckboxCP } from 'common/components/form-fields/checkbox/CheckboxCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { MaintenanceFormValidator } from 'modules/maneuver/components/maintenance-drawer/inner/MaintenanceFormValidator'
import { ProductMaintenanceFormFieldsICP } from 'modules/maneuver/components/maintenance-drawer/inner/ProductMaintenanceFormFieldsICP'
import { ReceiptFileUploaderCP } from 'modules/maneuver/components/receipt-file-uploader/ReceiptFileUploaderCP'
import { ISaveMaintenanceRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveMaintenancesRequestDTO'
import { ISaveProductMaintenanceRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveProductMaintenanceRequestDTO'
import { IMaintenanceResponseDTO } from 'modules/maneuver/services/dtos/responses/IMaintenanceResponseDTO'
import { ImplementRequests } from 'modules/maneuver/services/ImplementRequests'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'




const RAW_PROD_MAINTENANCE_ITEM: ISaveProductMaintenanceRequestDTO = {
    productCode: undefined,
    quantity: undefined,
}

interface IMaintenanceDrawerCPProps {
    implementCode: number
    maintenanceCode?: number
    show: boolean
    onCancel: () => void
    onSuccess: () => void
}

export function MaintenanceDrawerCP(props: IMaintenanceDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()

    const [prodMaintenanceList, setProdMaintenanceList] = useState<ISaveProductMaintenanceRequestDTO[]>([])
    const [shouldUploadImages, setShouldUploadImages] = useState<number>()
    const [newMaintenanceCode, setNewMaintenanceCode] = useState<number | undefined>(props.maintenanceCode)
    const [isPaid, setIsPaid] = useState<boolean>(false)

    const [formValidator, setFormValidator] = useState<MaintenanceFormValidator>(new MaintenanceFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    useEffect(getMaintenance, [props.maintenanceCode, props.show])

    const getMaintenanceReq = useRequest<IMaintenanceResponseDTO>()
    useEffect(onGetMaintenanceReqChange, [getMaintenanceReq.awaiting, props.implementCode])

    const saveMaintenanceReq = useRequest<IMaintenanceResponseDTO>()
    useEffect(onSaveRequestChange, [saveMaintenanceReq.awaiting])

    function getMaintenance(): void {
        if (props.maintenanceCode && props.implementCode && props.show)
            getMaintenanceReq.runRequest(ImplementRequests.getMaintenance(props.implementCode, props.maintenanceCode))
        else
            setFormValidator(new MaintenanceFormValidator())

    }

    function onGetMaintenanceReqChange(): void {
        if (getMaintenanceReq.awaiting || !getMaintenanceReq.tried)
            return

        if (!getMaintenanceReq.success || !getMaintenanceReq.returnData) {
            if (getMaintenanceReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getMaintenanceReq.returnData, getMaintenanceReq.error)

            return RequestUtils.showDefaultErrorNotification(getMaintenanceReq.error, 'Erro ao buscar manutenções!')
        }

        setFormValidator(new MaintenanceFormValidator(getMaintenanceReq.returnData))
        if (!!getMaintenanceReq.returnData.products) {
            setProdMaintenanceList(getMaintenanceReq.returnData.products.map(prdM => ({
                code: prdM.code,
                productCode: prdM.product.code,
                quantity: +prdM.quantity,
            })))
        }
        setIsPaid(getMaintenanceReq.returnData.isPaid)

    }

    function onSaveRequestChange(): void {

        if (saveMaintenanceReq.awaiting || !saveMaintenanceReq.tried)
            return

        if (!saveMaintenanceReq.success || !saveMaintenanceReq.returnData) {
            if (saveMaintenanceReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveMaintenanceReq.returnData, saveMaintenanceReq.error)

            return RequestUtils.showDefaultErrorNotification(saveMaintenanceReq.error, 'Erro ao salvar manutenção!')
        }

        setNewMaintenanceCode(saveMaintenanceReq.returnData.code)
        setShouldUploadImages(Date.now())

    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveMaintenanceRequestDTO = {
            code: props.maintenanceCode,
            cost: +MaskUtils.removeMask(formValues.cost),
            date: DateUtils.getDateFormattedToApi(formValues.date),
            paymentDate: formValues.paymentDate ? DateUtils.getDateFormattedToApi(formValues.paymentDate) : undefined,
            isPaid,
            notes: formValues.notes,
            season: formValues.season,
            products: prodMaintenanceList,
        }

        await saveMaintenanceReq.runRequest(props.maintenanceCode ?
            ImplementRequests.updateMaintenance(props.implementCode, props.maintenanceCode, dto) :
            ImplementRequests.saveMaintenance(props.implementCode, dto))
    }

    function removePrdMaintenanceItem(index: number): void {
        setProdMaintenanceList(prodMaintenanceList.filter((pmtl, i) => i !== index))
    }

    return (
        <DrawerCP
            title={props.maintenanceCode ? `Editar manutenção #${props.maintenanceCode}` : 'Nova manutenção'}
            visible={props.show}
            width={'60%'}
            onClose={() => {
                formStateManager.reset()
                props.onCancel()
            }}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'primary'}
                        wrapperStyle={{ width: '100%' }}
                        isSubmit={true}
                        loading={saveMaintenanceReq.awaiting}
                        onClick={onFormSubmit}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>

            }
        >
            <FormWrapperSCP>
                <TwoColWrapperSCP>
                    <InputCP
                        label={'Custo'}
                        placeholder={'Custo'}
                        fieldName={'cost'}
                        type={'currency'}
                        formStateManager={formStateManager}
                        required={true}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        loading={saveMaintenanceReq.awaiting || getMaintenanceReq.awaiting}
                    />
                    <InputCP
                        label={'Data'}
                        placeholder={'__/__/____'}
                        fieldName={'date'}
                        type={'date'}
                        formStateManager={formStateManager}
                        required={true}
                        mask={MaskUtils.applyDateMask}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        maxLength={10}
                        loading={saveMaintenanceReq.awaiting || getMaintenanceReq.awaiting}
                    />
                    <InputCP
                        label={'Data do pagamento'}
                        placeholder={'__/__/____'}
                        fieldName={'paymentDate'}
                        type={'date'}
                        formStateManager={formStateManager}
                        required={false}
                        mask={MaskUtils.applyDateMask}
                        maxLength={10}
                        loading={saveMaintenanceReq.awaiting || getMaintenanceReq.awaiting}
                    />
                    <CheckboxWrapperSCP>
                        <CheckboxCP
                            isChecked={isPaid}
                            onChange={setIsPaid}
                            label={'Marcar como pago'}
                        />
                    </CheckboxWrapperSCP>
                </TwoColWrapperSCP>
                <SeasonSelectorCP
                    formStateManager={formStateManager}
                    label={'Safra'}
                />
                <TextAreaCP
                    label={'Observação'}
                    fieldName={'notes'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    loading={saveMaintenanceReq.awaiting || getMaintenanceReq.awaiting}
                />
                <ButtonWrapperSCP>
                    <ButtonCP
                        icon={<FontAwsomeIconCP icon={faPlus} />}
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'primary'}
                        wrapperStyle={{ width: '100%' }}
                        onClick={() => setProdMaintenanceList([...prodMaintenanceList, RAW_PROD_MAINTENANCE_ITEM])}
                    >
                        Adicionar novo item utilizado
                    </ButtonCP>
                </ButtonWrapperSCP>
                {
                    !!prodMaintenanceList.length &&
                    prodMaintenanceList.map((prdMaintenance, index) => {
                        return (
                            <ProductMaintenanceFormFieldsICP
                                key={`${index}`}
                                index={index}
                                loading={saveMaintenanceReq.awaiting || getMaintenanceReq.awaiting}
                                productMaintenance={prdMaintenance}
                                removeFromList={() => removePrdMaintenanceItem(index)}
                                prodMaintenanceList={prodMaintenanceList}
                                setProdMaintenanceList={setProdMaintenanceList}
                            />
                        )
                    })

                }

                <ReceiptFileUploaderCP
                    type={'maintenance'}
                    itemCode={props.implementCode}
                    subItemCode={newMaintenanceCode}
                    loading={saveMaintenanceReq.awaiting}
                    fireSaveReq={shouldUploadImages}
                    onUploadDone={() => {
                        formStateManager.reset()
                        ListActions.mustUpdate()
                        props.onSuccess()
                    }}
                />
            </FormWrapperSCP>

        </DrawerCP>
    )
}

const FormWrapperSCP = styled.div`

`

const TwoColWrapperSCP = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  align-items: end;
`

const ButtonWrapperSCP = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  width: 100%;

  .ant-btn {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .ant-typography {
    font-size: 11px;
    color: ${props => props.theme.lightGreen}
  }
`
const CheckboxWrapperSCP = styled.div`
  align-items: start;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
`