import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ProgressBarCP } from 'common/components/progress/ProgressBarCP'
import { ProgressPercentageDataTP } from 'common/components/widgets/types/ProgressPercentageDataTP'
import React from 'react'
import styled from 'styled-components'

interface IProgressPercentageListCPProps {
    data?: ProgressPercentageDataTP[]
    loading: boolean
}

export function ProgressPercentageListCP(props: IProgressPercentageListCPProps): JSX.Element {
    if (props.loading)
        return <WrapperSCP><GenericRowSkeletonICP rows={5} columns={2} /></WrapperSCP>
    return (
        <WrapperSCP>
            {
                !!props.data && props.data.map(data => (
                    <ItemSCP key={data.label}>
                        <LabelSCP>{data.label}</LabelSCP>
                        <ProgressWrapperSCP>
                            <ValuesSCP>
                                {
                                    data.value &&
                                    <ValueSCP> {data.value} </ValueSCP>
                                }
                                <PercentageSCP> {data.percentage}% </PercentageSCP>
                            </ValuesSCP>
                            <ProgressBarCP
                                percent={data.percentage}
                                size={'small'}
                                showInfo={false}
                                width={5}
                            />
                        </ProgressWrapperSCP>
                    </ItemSCP>
                ))
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin: 30px 0;
`
const ItemSCP = styled.div`
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`
const PercentageSCP = styled.div`
    font-weight: 500;
    color: ${props => props.theme.primaryColor};
`

const ValueSCP = styled.div`
`
const ValuesSCP = styled.div`
      display: flex;
      column-gap: 15px;
`

const LabelSCP = styled.div`
    font-weight: 500;
`
const ProgressWrapperSCP = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 60%;
`