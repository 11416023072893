import { Skeleton } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaskUtils } from 'common/utils/MaskUtils'
import { LogbookFormValidator } from 'modules/logbook/components/logbook-form/inner/LogbookFormValidator'
import { ISavePersonResponsibleReqDTO } from 'modules/logbook/services/dtos/request/ISavePersonResponsibleReqDTO'
import { IPersonResponsibleResDTO } from 'modules/logbook/services/dtos/response/IPersonResponsibleResDTO'
import { IPersonResponseDTO } from 'modules/person/services/dtos/responses/IPersonResponseDTO'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PeopleResponsibleFormFieldsICP } from './PeopleResponsibleFormFieldsICP'

const RAW_PRS_RS: ISavePersonResponsibleReqDTO = {
    personCode: undefined,
    quantity: undefined,
    paymentUnity: undefined
}

interface IResponsibleFormFieldsICPProps {
    formStateManager: IFormStateManager<LogbookFormValidator>
    peopleResponsible?: IPersonResponsibleResDTO[]
}

/**
* COMPONENTE
* Campos de responsaveis de um diário
*/
export function ResponsibleFormFieldsICP(props: IResponsibleFormFieldsICPProps): JSX.Element {
    const [personResponsibleList, setPersonResponsibleList] = useState<ISavePersonResponsibleReqDTO[]>([RAW_PRS_RS])
    const [personList, setPersonList] = useState<IPersonResponseDTO[]>([])

    const request = useRequest<IGenericListResponseDTO<IPersonResponseDTO>>(PersonRequests.getPersonList({}))
    useEffect(onRequestChange, [request.awaiting])

    useEffect(init, [props.peopleResponsible])

    function init(): void {
        if (!!props.peopleResponsible) {
            setPersonResponsibleList(props.peopleResponsible.map(prr => ({
                ...prr,
                personCode: prr.person.code,
                person: undefined,
            })))
            props.formStateManager.changeFieldValue('peopleResponsible', props.peopleResponsible)
        }

        request.runRequest()
    }

    function setResponsibleData(): void {
        if (!!props.formStateManager.getFieldValue('peopleResponsible'))
            setPersonResponsibleList(props.formStateManager.getFieldValue('peopleResponsible'))
    }

    function onChangePersonResponsible(prsRes, index): void {
        const list = personResponsibleList.map((prs, i) => {
            if (index === i) {
                return {
                    ...prsRes,
                    paidValue: !!prsRes.paidValue ? +MaskUtils.removeMask(`${prsRes.paidValue}`) : undefined
                }
            }
            return {
                ...prs,
                paidValue: !!prs.paidValue ? +MaskUtils.removeMask(`${prs.paidValue}`) : undefined
            }
        })
        setPersonResponsibleList(list)

        props.formStateManager.changeFieldValue('peopleResponsible', list)

    }

    function onRemovePerson(index): void {
        setPersonResponsibleList(personResponsibleList.filter((v, i) => i !== index))
        props.formStateManager.changeFieldValue('peopleResponsible', personResponsibleList.filter((v, i) => i !== index))
    }

    function onAddPerson(): void {
        setPersonResponsibleList([...personResponsibleList, RAW_PRS_RS])
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar lista de colaboradores!')
        }

        setPersonList(request.returnData.list)
    }

    if (request.awaiting)
        return (<Skeleton active={true} />)

    return (

        <>
            {
                personResponsibleList.map((prsResponsible, index) => (
                    <PeopleResponsibleFormFieldsICP
                        key={`${prsResponsible.personCode ?? '0'}${index}_key`}
                        personResponsible={prsResponsible}
                        onChangePersonResponsible={(_prsResponsible) => onChangePersonResponsible(_prsResponsible, index)}
                        removeFromList={() => onRemovePerson(index)}
                        disableRemove={personResponsibleList.length === 1}
                        personList={personList}
                    />
                ))
            }
            <ButtonWrapperSCP>
                <ButtonCP
                    type={'primary'}
                    wrapperStyle={{ width: '100%' }}
                    onClick={onAddPerson}

                >
                    Adicionar +
                </ButtonCP>
            </ButtonWrapperSCP>
        </>
    )
}

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`