import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { IPurchaseResponseDTO } from 'modules/maneuver/services/dtos/responses/IPurchaseResponseDTO'
import { MaskUtils } from 'common/utils/MaskUtils'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import React from 'react'
import { TextCP } from 'common/components/text/TextCP'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'
import styled from 'styled-components'

interface IProductCardContentICPProps {
    unitOfMeasurement: UnitOfMeasurementEnum
    remainingQuantity: number
    lastPurchase?: IPurchaseResponseDTO
}

/**
 * COMPONENTE
 * Conteudo central de um item da lista de produtos
 */
export function ProductCardContentICP(props: IProductCardContentICPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }
    const unity = ProductUtils.getUnitOfMeasurementAbbreviation(props.unitOfMeasurement)
    return (
        <WrapperSCP>
            <RowSCP>
                <TextCP text={'Em estoque: '} />
                <TextCP
                    text={`${MaskUtils.applyNumberMask(props.remainingQuantity)} ${unity}`}
                    style={valueStyle}
                />
            </RowSCP>
            {
                !!props.lastPurchase ?
                    <RowSCP>
                        <TextCP text={'Data da última compra: '} />
                        <TextCP text={`${DateUtils.getFormatted(props.lastPurchase.date, DateFormatEnum.BR_WITHOUT_TIME)}`} style={valueStyle} />
                    </RowSCP>
                    :
                    <RowSCP>
                        <TextCP text={'Nenhuma compra cadastrada'} style={valueStyle} />
                    </RowSCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 0 10px;   
    min-height: 46px;
`
const RowSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    margin-bottom: 5px;
    .ant-typography{
        font-size: 13px;
    }
`
