import IMask from 'imask'
import React from 'react'
import { InputCP } from '../form-fields/input/InputCP'

const mask = (value: string): string => IMask.createMask({ mask: '00/00/0000' }).resolve(value)
export function DateInput(props: any): JSX.Element {

    return (
        <InputCP
            {...props}
            length={10}
            mask={mask}
            keepMask={true}
        />
    )
}