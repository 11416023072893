import React from 'react'

export function HarvestIconCP(props: any): JSX.Element {
	return (
		<span style={{ display: 'flex', justifyContent: 'center', width: props.width, alignItems: 'start', marginRight: '10px' }} >
			<svg
				{...props}
				xmlns={'http://www.w3.org/2000/svg'}
				x={'0px'}
				y={'0px'}
				viewBox={'0 0 100 100'}
				width={30}
				enableBackground={'new 0 0 100 100'}
			>
				<g fill={'#fff'}>
					<path d="M139.7,43.14c0.45-1.64,1.56-2.68,2.96-3.58c12.84-8.25,25.67-16.51,38.48-24.81c1.61-1.05,3.04-1.06,4.66-0.01
		c8.4,5.46,16.84,10.87,25.27,16.31c0.24,0.15,0.49,0.3,0.71,0.48c0.56,0.46,0.69,1.21,0.29,1.75c-0.44,0.59-1.02,0.69-1.67,0.38
		c-0.23-0.11-0.44-0.27-0.65-0.41c-8.42-5.43-16.85-10.85-25.26-16.31c-0.73-0.48-1.25-0.48-1.98,0
		c-12.93,8.37-25.89,16.71-38.83,25.06c-1.42,0.91-1.77,1.94-1.08,3.13c0.67,1.16,1.89,1.46,3.08,0.7c2.09-1.32,4.17-2.68,6.25-4.02
		c9.76-6.3,19.54-12.58,29.29-18.9c1.59-1.03,3.01-1.01,4.59,0.02c11.59,7.51,23.21,14.98,34.81,22.47
		c0.29,0.19,0.57,0.38,0.87,0.54c1.09,0.59,2.19,0.33,2.83-0.68c0.69-1.07,0.48-2.26-0.62-2.98c-1.99-1.32-4.01-2.6-6.02-3.9
		c-0.72-0.46-1.45-0.91-2.15-1.39c-0.74-0.5-0.93-1.19-0.53-1.82c0.41-0.66,1.17-0.8,1.94-0.31c2.69,1.72,5.37,3.44,8.04,5.18
		c2,1.31,2.74,3.32,2.06,5.47c-0.67,2.11-2.38,3.31-4.74,3.3c-0.14,0-0.27,0-0.46,0c0,13.49,0,26.93,0,40.51
		c0.41,0,0.83-0.01,1.24,0c2.38,0.1,4.22,2.05,4.19,4.42c-0.03,2.32-1.9,4.23-4.23,4.32c-0.31,0.01-0.63,0-0.94,0
		c-25.68,0-51.35,0-77.03,0c-3.12,0-4.18-0.71-5.36-3.59c0-0.57,0-1.14,0-1.71c0.35-0.64,0.62-1.34,1.07-1.9
		c0.84-1.06,2.01-1.56,3.37-1.55c0.31,0,0.62,0,1.05,0c0-1.2,0-2.32,0-3.45c0-0.34-0.02-0.69,0.02-1.02
		c0.09-0.74,0.62-1.2,1.32-1.18c0.71,0.02,1.19,0.5,1.21,1.27c0.03,1.11,0.01,2.22,0.01,3.33c0,0.31,0,0.62,0,1.04
		c0.38,0,0.68,0,0.98,0c2.71,0,5.42,0.02,8.12-0.02c0.48-0.01,1.02-0.15,1.43-0.39c6.35-3.87,13.27-5.93,20.64-6.58
		c1.04-0.09,2.09-0.15,3.23-0.23c-0.21-2.4-1.06-4.44-2.77-6.09c-0.18-0.17-0.58-0.18-0.87-0.15c-3.37,0.31-6.72,0.21-10.01-0.7
		c-5.48-1.52-9.77-4.57-12.28-9.79c-0.53-1.11-0.82-2.36-1.01-3.59c-0.23-1.54,0.34-2.3,1.85-2.68c3.06-0.78,6.12-0.56,9.14,0.2
		c7.28,1.83,12.08,6.35,14.31,13.52c0.3,0.97,0.89,1.58,1.64,2.33c0.03-0.33,0.06-0.55,0.06-0.76c0-2.25,0.02-4.5-0.01-6.75
		c0-0.33-0.12-0.78-0.35-0.97c-6.29-5.38-7.4-12.09-5.04-19.68c1.08-3.46,2.83-6.58,4.97-9.49c0.92-1.26,2.48-1.29,3.39-0.03
		c3.19,4.43,5.58,9.2,6.05,14.75c0.48,5.68-1.48,10.39-5.77,14.13c-0.47,0.41-0.7,0.8-0.69,1.44c0.04,2.17,0.01,4.33,0.02,6.5
		c0,0.27,0.02,0.55,0.03,0.82c0.09,0.04,0.18,0.08,0.26,0.13c0.42-0.73,0.99-1.4,1.23-2.18c2.52-8.34,9.3-13.79,17.98-14.26
		c1.83-0.1,3.7,0.06,5.51,0.35c1.63,0.26,2.17,1.2,1.96,2.86c-0.41,3.3-2.15,5.91-4.45,8.17c-3.56,3.5-7.95,5.26-12.86,5.69
		c-1.98,0.17-3.98,0.05-5.97,0.01c-0.5-0.01-0.84,0.11-1.17,0.5c-1.38,1.67-2.16,3.58-2.45,5.74c1.6,0.14,3.16,0.25,4.71,0.42
		c6.8,0.77,13.16,2.83,19.04,6.38c0.43,0.26,1,0.43,1.5,0.43c2.71,0.04,5.42,0.02,8.12,0.02c0.28,0,0.56,0,0.96,0
		c0-0.38,0-0.68,0-0.98c0-13.31,0-26.62,0.01-39.93c0-0.61-0.18-0.97-0.7-1.3c-11.36-7.3-22.7-14.61-34.04-21.95
		c-0.73-0.47-1.25-0.48-1.99,0c-11.31,7.32-22.63,14.62-33.96,21.9c-0.56,0.36-0.79,0.74-0.79,1.42c0.03,9.89,0.02,19.78,0.02,29.67
		c0,0.28,0.02,0.57-0.02,0.85c-0.08,0.68-0.47,1.09-1.17,1.14c-0.7,0.05-1.15-0.31-1.32-0.97c-0.09-0.32-0.06-0.68-0.06-1.02
		c0-9.41,0-18.81,0-28.22c0-0.34,0-0.68,0-1.07c-0.21-0.02-0.37-0.05-0.53-0.05c-2.46,0.01-4.1-1.21-4.8-3.56
		c-0.04-0.13-0.11-0.25-0.17-0.38C139.7,44.28,139.7,43.71,139.7,43.14z M183.43,95.47c12.91,0,25.82,0,38.73,0
		c0.31,0,0.63,0.02,0.94-0.02c0.92-0.12,1.61-0.91,1.59-1.82c-0.01-0.92-0.71-1.67-1.65-1.76c-0.31-0.03-0.63-0.02-0.94-0.02
		c-25.74,0-51.47,0-77.21,0c-0.31,0-0.63-0.01-0.94,0.02c-0.94,0.08-1.65,0.82-1.68,1.73c-0.03,0.94,0.68,1.75,1.65,1.86
		c0.28,0.03,0.57,0.01,0.85,0.01C157.66,95.47,170.54,95.47,183.43,95.47z M203.79,89.24c-4.63-2.58-13.26-5.05-22.96-4.53
		c-6.12,0.33-11.99,1.7-17.49,4.53C176.76,89.24,190.2,89.24,203.79,89.24z M208.89,62.79c-6.36,2.94-12.4,6.27-18.07,10.52
		C198.75,73.9,207,69.72,208.89,62.79z M182.19,41.83c-1.75,2.65-2.99,5.47-3.6,8.54c-0.9,4.58-0.14,8.78,3,12.38
		c0.15,0.17,0.34,0.31,0.59,0.55C182.19,56.04,182.19,48.93,182.19,41.83z M184.86,41.89c0,7.09,0,14.19,0,21.34
		c1.31-1.08,2.64-3.18,3.24-5.21C189.87,52.09,187.77,46.9,184.86,41.89z M175.86,73.34c-3.06-2.46-15.99-9.94-17.71-10.26
		C160.06,69.49,167.65,73.76,175.86,73.34z M160.87,61.21c5.35,3.14,10.73,6.3,15.99,9.39C175.63,65.7,166.96,60.55,160.87,61.21z
		 M190.1,70.64c5.07-3.7,10.34-6.67,16.01-9.42C199.51,60.91,192.21,65.21,190.1,70.64z"/>
				</g>
				<g>
					<path d="M39.72,5.25c0.39,0.45,0.82,0.87,1.15,1.36c2.63,3.86,4.95,7.87,6.15,12.43c0.67,2.56,0.59,5.12-0.09,7.66
		c-0.9,3.36-2.82,5.19-6.3,6.04c0,2.24,0,4.49,0,6.91c3.2-2.45,6.31-4.83,9.43-7.21c-2.08-3.6-1.21-6.77,1.22-9.74
		c2.26-2.77,5.28-4.25,8.63-5.21c3.33-0.95,6.73-1.43,10.17-1.68c1.37-0.1,2.04,0.65,1.71,1.94c-1.11,4.36-2.58,8.57-5.05,12.37
		c-2.06,3.15-4.84,5.34-8.58,6.16c-2.36,0.52-4.42-0.18-6.31-1.74c-1.52,1.16-3.04,2.33-4.57,3.49c-1.98,1.52-3.95,3.06-5.96,4.54
		c-0.58,0.43-0.75,0.88-0.75,1.57c0.02,10.63,0.01,21.25,0.01,31.88c0,0.23,0,0.46,0,0.69c-0.02,1-0.58,1.62-1.41,1.6
		c-0.8-0.02-1.34-0.62-1.34-1.58c-0.01-3.56,0-7.12,0-10.68c0-7.38,0.01-14.76-0.02-22.14c0-0.35-0.14-0.83-0.39-1.03
		c-3.58-2.78-7.19-5.53-10.68-8.2c-1.16,0.55-2.2,1.18-3.32,1.53c-1.83,0.57-3.64,0.17-5.36-0.59c-3.08-1.36-5.36-3.59-7.02-6.49
		c-2.06-3.59-3.44-7.43-4.43-11.43c-0.29-1.17,0.35-1.96,1.54-1.88c4.54,0.31,9.01,0.96,13.27,2.7c2.42,0.99,4.44,2.52,6.03,4.58
		c2.23,2.88,2.89,5.95,0.9,9.34c3.11,2.37,6.2,4.74,9.4,7.19c0-2.34,0-4.58,0-6.86c-0.56-0.17-1.17-0.31-1.74-0.55
		c-2.83-1.18-4.09-3.53-4.73-6.35c-0.93-4.11,0.03-7.95,1.86-11.58c1.41-2.78,3.11-5.41,4.71-8.1c0.21-0.36,0.56-0.63,0.85-0.94
		C39.04,5.25,39.38,5.25,39.72,5.25z M39.22,9.02c-2.33,3.69-4.46,7.25-5.31,11.4c-0.47,2.29-0.29,4.58,0.66,6.75
		c0.62,1.4,1.65,2.35,3.26,2.72c0-0.4,0-0.72,0-1.03c0-3.04,0-6.09,0-9.13c0-0.2-0.01-0.4,0-0.6c0.05-0.88,0.59-1.46,1.37-1.46
		c0.81,0,1.37,0.59,1.38,1.54c0.01,3.24,0.01,6.49,0.01,9.73c0,0.3,0,0.6,0,0.96c1.8-0.44,2.83-1.56,3.43-3.11
		c0.69-1.8,0.89-3.69,0.61-5.59C43.96,16.71,41.65,12.95,39.22,9.02z M54.15,32.8c1.13,0.82,2.28,1.06,3.51,0.8
		c2.43-0.52,4.35-1.89,5.9-3.78c2.13-2.59,3.31-5.68,4.41-8.79c0.26-0.74,0.47-1.49,0.73-2.34c-1.02,0.13-1.9,0.22-2.78,0.35
		c-3.13,0.48-6.23,1.08-9.05,2.59c-2.09,1.12-3.79,2.7-4.79,4.9c-0.63,1.38-0.61,2.76,0.21,4.16c0.28-0.2,0.51-0.35,0.73-0.52
		c2.78-2.12,5.56-4.25,8.35-6.38c0.23-0.17,0.46-0.36,0.71-0.48c0.62-0.3,1.19-0.15,1.62,0.36c0.44,0.52,0.45,1.12,0.05,1.67
		c-0.18,0.25-0.45,0.44-0.7,0.63C60.13,28.22,57.19,30.47,54.15,32.8z M26.11,30.72c0.97-1.71,0.81-3.3-0.08-4.82
		c-1.22-2.09-2.93-3.75-5.2-4.59c-2.35-0.87-4.81-1.47-7.25-2.08c-1.23-0.31-2.51-0.37-3.88-0.56c1.16,3.76,2.4,7.3,4.59,10.42
		c1.42,2.03,3.3,3.5,5.65,4.31c1.49,0.51,2.91,0.41,4.3-0.58c-0.27-0.22-0.48-0.41-0.7-0.58c-2.73-2.09-5.47-4.19-8.2-6.28
		c-0.2-0.16-0.43-0.3-0.59-0.5c-0.47-0.54-0.53-1.16-0.09-1.73c0.46-0.6,1.08-0.75,1.76-0.37c0.37,0.21,0.7,0.49,1.05,0.75
		C20.31,26.29,23.16,28.47,26.11,30.72z"/>
					<path d="M94.37,69.54c-0.05,0.1-0.12,0.19-0.16,0.3c-0.67,1.97-2.03,3.01-4.12,3.01c-10.57,0-21.14,0.01-31.71-0.04
		c-3.72-0.01-6.66-3.04-6.77-6.77c-0.06-1.86,0.01-3.76-0.35-5.57c-0.76-3.83-3.05-6.6-6.51-8.41c-0.13-0.07-0.26-0.12-0.39-0.19
		c-0.93-0.47-1.25-1.18-0.9-1.93c0.37-0.77,1.13-1,2.06-0.56c4.27,2.03,7.04,5.33,8.33,9.87c0.6,2.1,0.55,4.26,0.52,6.41
		c-0.02,1.51,0.51,2.75,1.75,3.64c0.27,0.19,0.58,0.32,1.01,0.56c-0.12-4.43,0.48-8.75-1.18-12.89c-2.13-5.32-5.84-8.98-11.27-10.86
		c-0.08-0.03-0.17-0.05-0.25-0.08c-0.88-0.33-1.29-1.05-1.04-1.79c0.26-0.76,1.01-1.11,1.91-0.82c3.52,1.12,6.56,3.03,9.04,5.76
		c3.67,4.04,5.5,8.81,5.54,14.26c0,0.29,0.01,0.57,0.02,0.86c0,0.05,0.04,0.1,0.1,0.26c1.67,0,3.39,0,5.1,0
		c7.96,0.01,15.91,0.07,23.87-0.01c2.58-0.03,4.47,0.72,5.39,3.26C94.37,68.39,94.37,68.96,94.37,69.54z M59.93,70.03
		c0.19,0.02,0.27,0.03,0.36,0.03c9.5,0.04,19,0.08,28.49,0.11c0.57,0,1.19,0.04,1.7-0.16c0.42-0.16,0.93-0.58,1.02-0.98
		c0.1-0.4-0.15-1.02-0.46-1.34c-0.27-0.27-0.83-0.35-1.27-0.35c-9.7-0.03-19.4-0.03-29.1-0.04c-0.25,0-0.5,0.03-0.75,0.05
		C59.93,68.28,59.93,69.13,59.93,70.03z"/>
					<path d="M26.64,75.91c-0.66-1.86-1.49-3.67-1.94-5.57c-1.32-5.51-0.86-10.84,2.2-15.75c1.45-2.32,3.46-4.04,5.94-5.21
		c0.94-0.44,1.7-0.25,2.08,0.5c0.39,0.75,0.09,1.51-0.83,1.96c-4.19,2.03-6.33,5.58-7.05,9.99c-0.86,5.27,0.15,10.19,3.57,14.42
		c2.21,2.73,5.19,4.18,8.6,4.83c1.13,0.22,1.73,1.21,1.13,2.04c-0.28,0.38-0.94,0.7-1.43,0.69c-4.73-0.05-8.98-1.54-12.63-4.54
		c-5.3-4.36-7.89-10.01-7.75-16.89c0.01-0.26,0.01-0.52,0.05-0.77c0.12-0.87,0.73-1.42,1.49-1.35c0.78,0.07,1.29,0.66,1.24,1.57
		c-0.14,2.67,0.21,5.27,1.17,7.77C23.42,72,24.8,74.12,26.64,75.91z"/>
					<path d="M37.83,90.73c0-0.95-0.01-1.78,0-2.6c0.02-0.94,0.58-1.54,1.39-1.53c0.81,0.01,1.34,0.61,1.36,1.56
		c0.02,0.8,0.02,1.61,0.03,2.41c0,0.02,0.03,0.04,0.12,0.16c1.67,0,3.41,0,5.15,0c0.89,0,1.78-0.01,2.67,0
		c1.07,0.01,1.7,0.55,1.68,1.41c-0.02,0.83-0.64,1.34-1.66,1.35c-3.16,0.01-6.31,0-9.47,0c-3.1,0-6.2,0.01-9.3,0
		c-1.1,0-1.81-0.73-1.6-1.64c0.2-0.82,0.79-1.13,1.6-1.12c2.44,0.01,4.88,0,7.32,0C37.33,90.73,37.52,90.73,37.83,90.73z"/>
					<path d="M23.85,53.02c-0.46-0.42-1.04-0.7-1.18-1.12c-0.14-0.42,0.01-1.1,0.28-1.47c1.82-2.42,4.12-4.31,6.83-5.67
		c1.05-0.52,2.15-0.94,3.26-1.33c0.94-0.33,1.71,0.04,1.96,0.84c0.23,0.75-0.18,1.48-1.09,1.77c-3.58,1.14-6.52,3.19-8.87,6.12
		C24.79,52.48,24.35,52.67,23.85,53.02z"/>
					<path d="M25.41,92.13c-0.01,0.75-0.65,1.36-1.39,1.35c-0.75-0.01-1.36-0.65-1.35-1.39c0.01-0.75,0.64-1.36,1.39-1.35
		C24.81,90.75,25.42,91.38,25.41,92.13z"/>
					<path d="M54.37,93.48C53.62,93.48,53,92.85,53,92.1c0.01-0.75,0.63-1.37,1.38-1.36c0.75,0.01,1.37,0.63,1.36,1.38
		C55.74,92.87,55.11,93.49,54.37,93.48z"/>

				</g>
			</svg>
		</span>
	)
}

