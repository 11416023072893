import { PaginationConfig } from 'antd/lib/pagination'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import React, { useEffect, useState } from 'react'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import styled from 'styled-components'
import { CardCP } from 'common/components/card/CardCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { ISearchStructureReqDTO } from 'modules/structure/services/dtos/request/ISearchStructureReqDTO'
import { StructureRequests } from 'modules/structure/services/StructureRequests'
import { StructureCardICP } from 'modules/structure/components/structure-list/inner/StructureCardICP'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { TextCP } from 'common/components/text/TextCP'
import { StructureListFiltersCP } from '../structure-list-filters/StructureListFiltersCP'
import { IStructureResDTO } from 'modules/batch/services/dtos/responses/IStructureResDTO'

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchStructureReqDTO = {
    type: undefined
}
export function StructureListCP(): JSX.Element {
    const [structureList, setStructureList] = useState<IStructureResDTO[]>([])
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const currentFilters: ISearchStructureReqDTO | null = useSelector((state: IReduxState) => _.get(state, 'searchState.filter'))
    const [pagination, setPagination] = useState<PaginationConfig>(DEFAULT_PAGINATION)
    const [total, setTotal] = useState<number>(0)

    const type: FilterTypesEnum = useSelector((state: IReduxState) => _.get(state, 'searchState.type'))

    const request = useRequest<IGenericListResponseDTO<IStructureResDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(getStructureList, [currentFilters])
    useEffect(onItemChange, [updateStatus])
    useEffect(loadPersistedData, [])

    function loadPersistedData(): void {

        if (type !== FilterTypesEnum.STRUCTURE)
            return SearchActions.setFilter<ISearchStructureReqDTO>(DEFAULT_FILTERS, FilterTypesEnum.STRUCTURE)

        if (!currentFilters) {
            SearchActions.setFilter<ISearchStructureReqDTO>({
                ...DEFAULT_FILTERS,
                page: 1,
                itemsPerPage: 12,
            }, FilterTypesEnum.STRUCTURE)
        } else {
            SearchActions.setFilter<ISearchStructureReqDTO>({
                ...currentFilters,
                page: DEFAULT_PAGINATION.current,
                itemsPerPage: DEFAULT_PAGINATION.pageSize,
            }, FilterTypesEnum.STRUCTURE)
        }
    }
    function getStructureList(): void {
        if (!!currentFilters && type === FilterTypesEnum.STRUCTURE)
            request.runRequest(StructureRequests.search(currentFilters))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE) {
            setStructureList([])
            SearchActions.setFilter<ISearchStructureReqDTO>({
                ...DEFAULT_FILTERS,
                page: DEFAULT_PAGINATION.current,
                itemsPerPage: DEFAULT_PAGINATION.pageSize,
            }, FilterTypesEnum.STRUCTURE)
        }
    }

    function onChangeFilter(filter: ISearchStructureReqDTO): void {
        setStructureList([])
        SearchActions.setFilter({
            ...filter,
            page: DEFAULT_PAGINATION.current,
            itemsPerPage: DEFAULT_PAGINATION.pageSize,
        }, FilterTypesEnum.STRUCTURE)
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried) {
            ListActions.isUpdating()
            return
        }

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar lista de estruturas!')
        }
        setStructureList(request.returnData.list)
        setTotal(request.returnData.total)
        setPagination({
            ...pagination,
            total: request.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                SearchActions.setFilter({ ...currentFilters, page, itemsPerPage: pageSize }, FilterTypesEnum.STRUCTURE)
            }
        })
        ListActions.updateIsDone()
    }

    return (
        <WrapperSCP>
            <StructureListFiltersCP
                filters={currentFilters ?? DEFAULT_FILTERS}
                setFilters={onChangeFilter}
            />
            <TotalSCP>
                <TextCP
                    text={`${total} registros encontrados`}

                />
                <TextCP
                    text={'Limpar filtros'}
                    onClick={() => {
                        SearchActions.setFilter({
                            ...DEFAULT_FILTERS,
                            page: DEFAULT_PAGINATION.current,
                            itemsPerPage: DEFAULT_PAGINATION.pageSize,
                        }, FilterTypesEnum.STRUCTURE)
                        setPagination(DEFAULT_PAGINATION)
                    }}
                    style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
                />
            </TotalSCP>
            <ListWrapperSCP>
                <ListCP<IStructureResDTO>
                    grid={{ gutter: 16, column: 3, md: 1, sm: 1, xs: 1, lg: 2, xl: 3, xxl: 3 }}
                    loading={request.awaiting}
                    bordered={false}
                    itemLayout={'vertical'}
                    size={'large'}
                    dataSource={structureList}
                    hasStateControl={true}
                    locale={{
                        emptyText: <CardCP noSpacing={true} noSideSpacing={true}>
                            {' '}
                            <EmptyCP description={'Nenhum resultado'} />
                            {' '}
                        </CardCP>
                    }}
                    renderItem={(item: IStructureResDTO) => (
                        <StructureCardICP
                            structure={item}
                            loading={request.awaiting}
                        />
                    )}
                    pagination={pagination}
                />
            </ListWrapperSCP>
        </WrapperSCP>

    )
}
const ListWrapperSCP = styled.div`
    
`
const WrapperSCP = styled.div`
   border-radius: 10px;
    margin-bottom: 4em;
    max-height: 75vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 70vh;        
    }

`


const TotalSCP = styled.div`
    margin-left: 3px;
    display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      

   
    }
    @media(max-width: 768px){        
    margin-top: 17px;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`
