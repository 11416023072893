import { PaginationConfig } from 'antd/lib/pagination'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { IPaymentStatementItemResDTO } from 'modules/person/services/dtos/responses/IPaymentStatementItemResDTO'
import { IPaymentStatementResDTO } from 'modules/person/services/dtos/responses/IPaymentStatementResDTO'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { StatementItemICP } from 'modules/person/components/person-payment-statement/inner/StatementItemICP'
import { ISearchPaymentStatmentReqDTO } from 'modules/person/services/dtos/requests/ISearchPaymentStatmentReqDTO'
import moment from 'moment-timezone'
import { DateRangePickerCP } from 'common/components/date-range-picker/DateRangePickerCP'
import { DateUtils } from 'common/utils/DateUtils'
import { PersonResumeCardICP } from 'modules/person/components/person-payment-statement/inner/PersonResumeCardICP'
import { IPersonResponseDTO } from 'modules/person/services/dtos/responses/IPersonResponseDTO'
import { TextCP } from 'common/components/text/TextCP'
import _ from 'lodash'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons'

interface IPersonPaymentStatementDrawerCPProps {
    show: boolean
    onCancel: () => void
    person: IPersonResponseDTO
}

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 999999,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`
}

const DEFAULT_FILTERS: ISearchPaymentStatmentReqDTO = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().startOf('month').add(1, 'M')
        .format('YYYY-MM-DD'),
    itemsPerPage: DEFAULT_PAGINATION.pageSize,
    page: 1
}

export function PersonPaymentStatementDrawerCP(props: IPersonPaymentStatementDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()

    const [statementData, setStatementData] = useState<IPaymentStatementItemResDTO[]>([])
    const [totalValue, setTotalValue] = useState<number>(0)
    const [selected, setSelected] = useState<number[]>([])

    const [totalToPay, setTotalToPay] = useState<number>(0)
    const [dateRange, setDateRange] = useState<{ startDate: string, endDate: string }>({ startDate: '', endDate: '' })

    const [filters, setFilters] = useState<ISearchPaymentStatmentReqDTO>(DEFAULT_FILTERS)
    const [pagination, setPagination] = useState<PaginationConfig>(DEFAULT_PAGINATION)

    useEffect(searchPaymentStatement, [props.person, props.show, filters])

    const getPaymentStatementReq = useRequest<IPaymentStatementResDTO>()
    useEffect(onRequestChange, [getPaymentStatementReq.awaiting])

    useEffect(defineDateAndTotal, [selected.length, statementData])

    function searchPaymentStatement(): void {
        if (!!props.person && !!props.show && !!filters)
            getPaymentStatementReq.runRequest(PersonRequests.getPersonPaymentStatement(props.person.code, filters))
    }

    function onRequestChange(): void {

        if (getPaymentStatementReq.awaiting || !getPaymentStatementReq.tried)
            return

        if (!getPaymentStatementReq.success || !getPaymentStatementReq.returnData) {
            if (getPaymentStatementReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getPaymentStatementReq.returnData, getPaymentStatementReq.error)
            return RequestUtils.showDefaultErrorNotification(getPaymentStatementReq.error, 'Erro ao buscar extrato!')
        }
        setPagination({
            ...pagination,
            total: getPaymentStatementReq.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                setFilters({ ...filters, page, itemsPerPage: pageSize })
            }
        })
        setStatementData(getPaymentStatementReq.returnData.statements)
        setTotalValue(getPaymentStatementReq.returnData.totalValue)
        setSelected(getPaymentStatementReq.returnData.statements.filter(st => !st.consolidated).map(_st => _st.personResCode))

    }

    function defineDateAndTotal(): void {
        if (!statementData.length)
            return

        const statements = statementData.filter(stm => selected.includes(stm.personResCode))

        setTotalToPay(_.sum(statements.map(st => st.paidValue)))
        setDateRange({
            startDate: moment.min(statements.map(st => moment(st.date))).format('YYYY-MM-DD'),
            endDate: moment.max(statements.map(st => moment(st.date))).format('YYYY-MM-DD')
        })
    }

    function unSelectAll(): void {
        setSelected([])
    }

    function selectAll(): void {
        if (!!getPaymentStatementReq.returnData)
            setSelected(getPaymentStatementReq.returnData.statements.filter(st => !st.consolidated).map(_st => _st.personResCode))
    }

    return (
        <DrawerCP
            title={`${props.person.name} - Extrato`}
            visible={props.show}
            width={'80%'}
            onClose={() => {
                props.onCancel()
                setStatementData([])
                setTotalValue(0)
                setFilters(DEFAULT_FILTERS)
                setPagination(DEFAULT_PAGINATION)
            }}
        >
            <PersonResumeCardICP
                personData={props.person}
                totalPaid={totalValue}
                dateRange={{
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate
                }}
                itemsToPay={selected}
                totalToPay={totalToPay}
            />

            <FilterSCP>
                <DateRangePickerCP
                    placeholder={['Data inicial', 'Data final']}
                    value={(!!filters?.startDate && !!filters?.endDate) ?
                        [moment(filters.startDate), moment(filters.endDate)] :
                        undefined}
                    onChange={(dates) => {
                        if (!!dates.beginDate && !!dates.endDate) {
                            setFilters(
                                {
                                    ...filters,
                                    startDate: DateUtils.getDateFormattedToApi(moment(dates.beginDate).format('DD-MM-YYYY')),
                                    endDate: DateUtils.getDateFormattedToApi(moment(dates.endDate).format('DD-MM-YYYY'))
                                }
                            )
                        }
                    }}
                />
                {
                    !!selected.length &&
                    <ButtonCP
                        type={'primary'}
                        onClick={unSelectAll}
                        icon={<FontAwsomeIconCP icon={faSquare} />}
                    >
                        {!screenSize.smd ? 'Desmarcar todos' : ''}
                    </ButtonCP>
                }

                {
                    selected.length !== getPaymentStatementReq.returnData?.statements.length &&
                    <ButtonCP
                        type={'primary'}
                        onClick={selectAll}
                        icon={<FontAwsomeIconCP icon={faCheckSquare} />}
                    >
                        {!screenSize.smd ? 'Marcar todos' : ''}
                    </ButtonCP>
                }

            </FilterSCP>

            <ListWrapperSCP>
                <ListHeaderSCP>
                    <TextCP text={''} />
                    <TextCP text={'Data'} />
                    <TextCP text={'Atividade'} />
                    <TextCP text={'Tipo'} />
                    <TextCP text={'Talhão'} />
                    <TextCP text={'Unidade'} />
                    <TextCP text={'Qtd.'} />
                    <TextCP text={'Valor total'} />
                    <TextCP text={'Status'} />
                </ListHeaderSCP>
                <ListCP<IPaymentStatementItemResDTO>
                    loading={getPaymentStatementReq.awaiting}
                    itemLayout={'vertical'}
                    size={'large'}
                    dataSource={statementData}
                    locale={{
                        emptyText: <EmptyCP description={'Sem resultados para os filtros selecionados'} />
                    }}
                    renderItem={(item: IPaymentStatementItemResDTO) => (
                        <StatementItemICP
                            statement={item}
                            key={`${item.code}${Math.random()}`}
                            loading={getPaymentStatementReq.awaiting}
                            includeItem={(code) => setSelected([...selected, code])}
                            removeItem={(code) => setSelected(selected.filter(cd => cd !== code))}
                            selected={selected}
                            onUpdate={searchPaymentStatement}
                        />

                    )}
                />
            </ListWrapperSCP>

        </DrawerCP>
    )
}


const ListWrapperSCP = styled.div`
    margin-top: 20px;
`

const FilterSCP = styled.div`
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
`
const ListHeaderSCP = styled.div`
    .ant-typography{     
        white-space: pre-wrap;
        text-align: center;
        font-weight: 600;
    }
    display: grid;
    grid-template-columns: .6fr 1.2fr 1.2fr 2fr 2fr 1fr 1fr 1.2fr 1.2fr 1.2fr;
    column-gap: 5px;
    @media(max-width: 768px){
      display: none;
   
    }

`
