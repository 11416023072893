import { Form } from 'antd'
import { IFormItemCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { IFormItemInputCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import * as _ from 'lodash'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'

const CLASS_RISE_LABEL = 'rise-label'
const CLASS_ERROR = 'has-error'
const CLASS_ANIMATE = 'animate-label'

// interface IFormItemICPProps<FModelTP extends FormModelValidator, PropsTP extends IFormItemCommonProps<FModelTP>> extends PropsTP {
//     children: JSX.Element
// }

/**
 * COMPONENTE
 * Wrapper generico a ser utilizado por inputs de formulario.
 *
 * @author hjcostabr
 * @author Stella
 */
export function FormItemICP<FModelTP extends FormModelValidator, PropsTP extends IFormItemCommonProps<FModelTP>>(props: any): JSX.Element | null {

    const validationsCount = _.get(props, 'formStateManager.validationsCount', 0)

    const [value, setValue] = useState<string | number>(props.value)
    const [riseLabel, setRiseLabel] = useState<boolean>(false)
    const [validationErrMsg, setValidationErr] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [hasFocus, setHasFocus] = useState<boolean>(false)

    useEffect(setValueByProps, [props.value])
    useEffect(parseLabelRising, [value, hasFocus])
    useEffect(parseValidation, [validationsCount])
    useEffect(onFormStateManagerChange, [props.formStateManager])
    useEffect(() => setErrorMessage(props.errorMessage ?? validationErrMsg), [props.errorMessage, validationErrMsg])

    function setValueByProps(): void {

        setValue(props.value)
    }

    function parseLabelRising(): void {
        setRiseLabel(hasFocus || (value !== undefined && value !== null))
    }

    function parseValidation(): void {

        if (!hasStateManager() || props.formStateManager!.isValid)
            return setValidationErr(undefined)

        const fieldErrors = props.formStateManager!.getFieldError(props.fieldName as keyof FModelTP)
        const constraints = _.get(fieldErrors, 'constraints')

        if (!!constraints) {
            const errMessages: any = Object.values(constraints || [])
            if (!!errMessages.length)
                return setValidationErr(errMessages[0])
        }

        setValidationErr(undefined)
    }

    function onFormStateManagerChange(): void {
        if (!hasStateManager())
            return

        const stateValue = props.formStateManager!.getFieldValue(props.fieldName!)
        const isValidValue = (['number', 'string'].includes(typeof stateValue) || stateValue instanceof Array || stateValue instanceof moment)

        if (isValidValue)
            handleChange(stateValue)
    }

    function hasStateManager(): boolean {
        return (!!props.fieldName && !!props.formStateManager)
    }

    function hasMask(): boolean {
        return !!(props as IFormItemInputCommonProps).mask
    }

    function handleChange(eventOrValue: SyntheticEvent | string): void {

        let newValue = _.get(eventOrValue, 'target.value', eventOrValue)

        if (newValue === value)
            return

        if (props.type === 'currency' && newValue === 'R$')
            newValue = null

        setValue(newValue)

        if (!!props.onChange) {
            if (hasMask() && typeof newValue === 'string' && !props.keepMask)
                props.onChange(newValue.replace(/\D/g, ''))
            else
                props.onChange(newValue)
        }

        if (hasStateManager())
            props.formStateManager!.changeFieldValue(props.fieldName!, newValue)

    }

    function handleBlur(): void {
        setHasFocus(false)
        if (hasStateManager())
            props.formStateManager!.validate()
    }

    function handleFocus(): void {
        setHasFocus(true)
        if (hasStateManager())
            props.formStateManager!.setFieldDirty(props.fieldName!)
    }

    function getCssClasses(): string {

        let cssClasses = CLASS_ANIMATE

        if (riseLabel)
            cssClasses += ` ${CLASS_RISE_LABEL}`

        if (!!errorMessage)
            cssClasses += ` ${CLASS_ERROR}`

        return cssClasses
    }

    function handleKeyPress(event: any): void {
        if (event.key === 'Enter' && !!props.onFormSubmit)
            props.onFormSubmit()
    }
    function getValueForChildren(): any {
        if (props.type === 'currency') {
            if (value === 'R$' || value === null || value === undefined)
                return ''

            return `${value}`
        }

        return !!value && hasMask() ? props.mask(value) : value

    }

    return (
        <FormItemSCP
            label={props.label}
            colon={false}
            className={getCssClasses()}
            required={props.required}
            fontSize={props.fontSize}
            style={{ width: props.width }}
        >
            {
                React.cloneElement(
                    props.children,
                    {
                        onFocus: handleFocus,
                        onBlur: handleBlur,
                        onChange: props.type === 'currency' ? undefined : handleChange,
                        onAccept: props.type === 'currency' ? handleChange : undefined,
                        onKeyPress: handleKeyPress,
                        value: getValueForChildren(),
                    }
                )
            }

            {errorMessage &&
                <div className={'ant-form-explain'} style={{ opacity: !!errorMessage ? 1 : 0 }}>
                    {errorMessage}
                </div>}
        </FormItemSCP>
    )
}

const FormItemSCP = styled(Form.Item) <{ fontSize?: any, hasIcon?: any }>`

    width: 100%;

    &.ant-row {
        margin-bottom: 5px;
        flex-direction: column;
        justify-content: flex-start;

        .ant-form-item-label {
            text-align: left;
            color: ${props => props.theme.black};

            > label {
                font-weight: 500;
                font-size: 14px;
                color: #494949;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }

        &.has-error .ant-form-explain {
            margin: 4px 0 -4px 4px;
            color: ${(props) => props.theme.errorColor};
            transition: opacity .3s;
            opacity: 1;
            font-size: 13px;
            line-height: 1;
            height: 16px;
            display: block
        }
    }

    .ant-select-selection {
        background-color: transparent;
    }

    &.${CLASS_ERROR} {
        .ant-input-affix-wrapper {
            .ant-input:focus {
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }
    }

    .ant-select {
        width: 100%;
    }

    .ant-form-explain {
        display: none
    }

    .ant-form-item-required {
        ::before {
            color: #494949 !important;
            font-family: inherit !important;
            font-size: inherit !important;
            right: -1em;
            position: absolute;
        }
    }
`
