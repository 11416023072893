import { faEye } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { TextCP } from 'common/components/text/TextCP'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { MaskUtils } from 'common/utils/MaskUtils'

import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'

import { BatchMethodSelectorCP } from 'modules/batch/components/batch-method-selector/BatchMethodSelectorCP'
import { BatchFormValidator } from 'modules/batch/components/new-batch-drawer/inner/BatchFormValidator'
import { BatchMethodEnum } from 'modules/batch/enums/BatchProcessEnum'
import { ISaveBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISaveBatchRequestDTO'

import { LitersPerMaturationCP } from 'modules/harvest-planning/components/liters-per-maturation/LitersPerMaturationCP'
import { LitersPerMaturationFieldsICP } from 'modules/harvest/components/harvest-form/inner/LitersPerMaturationFieldsICP'
import { IHarvestLitersPerMaturation } from 'modules/harvest/interfaces/IHarvestLitersPerMaturation'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface IBatchFormCardICPProps {
    batch: ISaveBatchRequestDTO
    allHarvests: IHarvestResponseDTO[]
    index: number
    onChangeBatch: (batch: ISaveBatchRequestDTO) => void
    batchQtt: number
}

export function BatchFormCardICP(props: IBatchFormCardICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [formValidator] = useState<BatchFormValidator>(new BatchFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const [litersPerMaturationFieldsVisible, setLitersPerMaturationFieldsVisible] =
        useState<boolean>(false)
    const [usedLitersPerMaturation, setUsedLitersPerMaturation] =
        useState<IHarvestLitersPerMaturation>()
    const [totalLiters, setTotalLiters] = useState<number>(0)
    const [method, setMethod] = useState<BatchMethodEnum>()

    useEffect(onChangeBatchQtt, [props.batchQtt])

    function onChangeBatchQtt(): void {
        if (!props.batchQtt)
            return

        setTotalLiters(props.batch.liters)
    }

    function onChangeTotalLiters(liters: number): void {
        if (!props.batchQtt)
            return

        setTotalLiters(liters)
        props.onChangeBatch({
            ...props.batch,
            liters: +liters,
        })
    }

    function onChangeLitersPerMaturation(litersPerMaturation?: IHarvestLitersPerMaturation): void {
        if (!litersPerMaturation)
            return
        props.onChangeBatch({
            ...props.batch,
            litersPerMaturation,
        })
    }

    function onChangeMethod(_method: BatchMethodEnum): void {
        setMethod(_method)
        props.onChangeBatch({
            ...props.batch,
            method: _method,
        })
    }

    function onChangeId(id: string): void {
        props.onChangeBatch({
            ...props.batch,
            farmId: id,
        })
    }

    return (
        <WrapperSCP>
            <HeaderSCP>
                <HighLightSCP>
                    #
                    {props.index + 1}
                </HighLightSCP>
                <BasicInfoSCP>
                    <RowSCP>
                        <TextCP
                            text={`${MaskUtils.applyNumberMask(totalLiters)} litros`}
                            style={{ fontSize: '20px', fontWeight: 600 }}
                        />
                    </RowSCP>
                    {!!props.batch.litersPerMaturation && (
                        <PopOverCP
                            placement={'right'}
                            trigger={'click'}
                            content={
                                <GenericWrapperSCP>
                                    <LitersPerMaturationCP
                                        litersPerMaturation={props.batch.litersPerMaturation}
                                    />
                                </GenericWrapperSCP>
                            }
                        >
                            <RowSCP style={{ cursor: 'pointer' }}>
                                <TextCP
                                    text={'Ver litros por maturação'}
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        textDecoration: 'underline',
                                        maxWidth: '200px',
                                    }}
                                />
                                <FontAwsomeIconCP icon={faEye} style={{ marginLeft: '20px;' }} />
                            </RowSCP>
                        </PopOverCP>
                    )}
                </BasicInfoSCP>
            </HeaderSCP>
            <FormFieldsSCP>
                <LitersPerHarvestSCP>
                    <HarvestFormWppSCP>
                        <GridRowSCP>
                            <TextCP text={'Litragem total'} />
                            <InputCP
                                required={false}
                                type={'number'}
                                placeholder={'litros'}
                                value={totalLiters}
                                maxLength={5}
                                fontSize={screenSize.smd ? 'large' : 'normal'}
                                icon={<span>L</span>}
                                onChange={onChangeTotalLiters}
                            />
                        </GridRowSCP>
                    </HarvestFormWppSCP>
                </LitersPerHarvestSCP>
                <ButtonCP
                    type={'primary'}
                    disabled={!totalLiters}
                    onClick={() =>
                        setLitersPerMaturationFieldsVisible(!litersPerMaturationFieldsVisible)}
                >
                    {!litersPerMaturationFieldsVisible
                        ? 'Mostrar litros por maturação'
                        : 'Esconder litros por maturação'}
                </ButtonCP>

                {litersPerMaturationFieldsVisible && !!totalLiters && (
                    <LitersPerMaturationFieldsICP
                        totalLiters={totalLiters}
                        currentValues={usedLitersPerMaturation}
                        onChange={onChangeLitersPerMaturation}
                    />
                )}
                <BatchMethodSelectorCP
                    label={'Método: '}
                    onSelect={onChangeMethod}
                    required={true}
                />

                <InputCP
                    label={'Id na propridade:'}
                    type={'text'}
                    required={false}
                    placeholder={'ID'}
                    formStateManager={formStateManager}
                    fieldName={'farmId'}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    onChange={onChangeId}
                />
            </FormFieldsSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.primaryColor};
    border-radius: 8px;
    padding: 14px;
`

const GenericWrapperSCP = styled.div``
const HeaderSCP = styled.div`
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    column-gap: 10px;
    margin-bottom: 15px;
`

const HighLightSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    font-size: 16px;
    font-weight: 600;
`
const RowSCP = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-left: 10px;
    }
`

const BasicInfoSCP = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

    flex-direction: column;
    align-items: flex-start;

    .ant-typography {
        font-size: 13px;
        ${(props) => props.theme.primaryColor};
    }

    svg {
        ${(props) => props.theme.primaryColor};
    }
`

const FormFieldsSCP = styled.div`
    .ant-btn {
        width: 100%;
        margin: 14px 0;
    }
`
const HarvestFormWppSCP = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.lightGray};
`
const LitersPerHarvestSCP = styled.div`
    display: grid;
    row-gap: 5px;
    margin: 5px 0;
`
const GridRowSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 10px;
    .ant-typography {
        font-size: 12px;
    }
    svg {
        font-size: 30px;
    }
`
