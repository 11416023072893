import { Layout } from 'antd'
import React from 'react'
import styled from 'styled-components'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const CLASS_MAIN_LAYOUT = 'layout_main'

interface ILayoutCPProps {
    className?: string
    mainLayout?: boolean
    header?: JSX.Element
    content?: JSX.Element | false
    sider?: JSX.Element
    footer?: JSX.Element
}

/**
 * Componente que renderiza o layout padrao do sistema.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function LayoutCP(props: ILayoutCPProps): JSX.Element {
    const dimensions = useWindowDimensions()
    return (
        <WrapperSCP
            vh={dimensions.height * 0.01}
            className={!!props.mainLayout ? CLASS_MAIN_LAYOUT : `${props.className ?? ''}`}
        >
            <Layout>
                {props.header}

                <Layout>
                    {props.sider}
                    {props.content}
                </Layout>

                {props.footer}
            </Layout>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ vh: number }>`
    &,
    .ant-layout {
        overflow: hidden;
        width: 100%;
        background: transparent;
        &-header {
            background: transparent
        }
    }

    

    &.${CLASS_MAIN_LAYOUT} {
        > .ant-layout {
            height: calc(${props => props.vh}px * 100);
            width: 100vw;      
            background: #F1F1F1;    
        }
        
    }
`
