import { faMedal } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'

import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { QualityReportContentCP } from 'modules/report/components/quality-report-content/QualityReportContentCP'
import React from 'react'
import styled from 'styled-components'

export function QualityReportScreen(): JSX.Element {

    return (
        <LayoutCP
            header={
                <HeaderCP icon={<FontAwsomeIconCP icon={faMedal} />} title={'Indicadores de qualidade'} />
            }
            content={
                <QualityReportContentCP />
            }
        />
    )
}

const WrapperSCP = styled.div`
`
