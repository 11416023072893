import React from 'react'
import { Helmet } from 'react-helmet'

interface IPageTitleCPProps {
    pageName: string
}

/**
 * COMPONENTE
 * Title da página
 *
 * @author Victor
 */
export const PageTitleCP = (props: IPageTitleCPProps): JSX.Element => {
    return (
        <Helmet>
            <title>
                Flowins |
                {` ${props.pageName}`}
            </title>
        </Helmet>
    )
}
