/* eslint-disable multiline-ternary */
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { CollapseCP } from 'common/components/collapse/CollapseCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { HarvestPlanningResumeCardCP } from 'modules/harvest-planning/components/harvest-logbook-content/HarvestPlanningResumeCardCP'
import { ISaveLogbookItemReqDTO } from 'modules/harvest-planning/services/dtos/request/ISaveLogbookItemReqDTO'
import { ILogbookResponseDTO } from 'modules/harvest-planning/services/dtos/response/ILogbookResponseDTO'
import { HarvestFormValidator } from 'modules/harvest/components/harvest-form/inner/HarvestFormValidator'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import { LogbookFormValidator } from 'modules/logbook/components/logbook-form/inner/LogbookFormValidator'
import { LogbookCommonFieldsCP } from 'modules/logbook/components/logbook-form/LogbookCommonFieldsCP'
import { IPersonResponsibleResDTO } from 'modules/logbook/services/dtos/response/IPersonResponsibleResDTO'
import { LogbookRequests } from 'modules/logbook/services/LogbookRequests'
import { LogbookUtils } from 'modules/logbook/utils/LogbookUtils'
import { RecipeCardCP } from 'modules/maneuver/components/recipe-card/RecipeCardCP'
import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface ILogbookDrawerCPProps {
    show: boolean
    onCancel: () => void
    onSuccess: () => void
    harvestPlanningCode?: number
    recipeCode?: number
    maneuverCode?: number
    fieldCode?: number
    logbookEntryCode?: number
    noListUpdate?: boolean
}

export function LogbookDrawerCP(props: ILogbookDrawerCPProps): JSX.Element {
    const [formValidator, setFormValidator] = useState<LogbookFormValidator>(new LogbookFormValidator())
    const formStateManager = useFormStateManager(formValidator)
    const [loadedValues, setLoadedValues] = useState<boolean>(false)

    const [recipe, setRecipe] = useState<IRecipeResponseDTO>()
    const [harvest, setHarvest] = useState<IHarvestResponseDTO>()
    const [peopleResponsible, setPeopleResponsible] = useState<IPersonResponsibleResDTO[]>()

    useEffect(init, [props.harvestPlanningCode, props.show])

    const request = useRequest<ILogbookResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const findOneReq = useRequest<ILogbookResponseDTO>()
    useEffect(onFindOneRequestChange, [findOneReq.awaiting])

    function init(): void {
        if (!props.show)
            return

        if (!!props.harvestPlanningCode)
            formStateManager.changeFieldValue('harvestPlanningCode', props.harvestPlanningCode)

        if (!!props.maneuverCode)
            formStateManager.changeFieldValue('maneuverCode', props.maneuverCode)

        if (!!props.logbookEntryCode)
            findOneReq.runRequest(LogbookRequests.get(props.logbookEntryCode))
        else
            setLoadedValues(true)
    }

    async function onFormSubmit(): Promise<void> {
        const formValues = formStateManager.getFormValues()
        formStateManager.changeFieldValue('harvestPlanningCode', props.harvestPlanningCode)
        formStateManager.changeFieldValue('maneuverCode', props.maneuverCode)
        formStateManager.setConsiderAllErrors(true)

        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveLogbookItemReqDTO = {
            date: DateUtils.getDateFormattedToApi(formValues.date),
            peopleResponsible: formValues.peopleResponsible,
            harvestPlanningCode: props.harvestPlanningCode,
            maneuverCode: props.maneuverCode,
            harvest: formValues.harvest,
            pictures: formValues.pictures,
            notes: formValues.notes,
            equipmentCode: formValues.equipmentCode,
            vehicleCode: formValues.vehicleCode,
            equipmentFuelConsume: !!formValues.equipmentFuelConsume && !!formValues.equipmentCode ? {
                ...formValues.equipmentFuelConsume,
                quantity: formValues.equipmentFuelConsume.quantity,
            } : undefined,
            vehicleFuelConsume: !!formValues.vehicleFuelConsume && !!formValues.vehicleCode ? {
                ...formValues.vehicleFuelConsume,
                quantity: formValues.vehicleFuelConsume.quantity,
            } : undefined,
            startHourMeter: formValues.startHourMeter ? +formValues.startHourMeter : undefined,
            endHourMeter: formValues.endHourMeter ? +formValues.endHourMeter : undefined,
            productUsages: formValues.productUsages && formValues.productUsages.map(usage => ({
                ...usage,
                quantity: usage.quantity,
            })),

        }


        dto.peopleResponsible.map((people) => delete people.valuesUnitPayment)

        if (props.maneuverCode) {
            request.runRequest(!props.logbookEntryCode ?
                LogbookRequests.save(props.maneuverCode, dto)
                : LogbookRequests.update(props.maneuverCode, props.logbookEntryCode, dto))
        }

        if (props.harvestPlanningCode) {
            request.runRequest(!props.logbookEntryCode ?
                LogbookRequests.save(props.harvestPlanningCode, dto)
                : LogbookRequests.update(props.harvestPlanningCode, props.logbookEntryCode, dto))
        }

    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)

            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar registro!')
        }

        formStateManager.reset()
        setHarvest(undefined)
        setRecipe(undefined)
        props.onSuccess()

        if (!!props.noListUpdate)
            return
        ListActions.mustUpdate()

    }

    function onFindOneRequestChange(): void {

        if (findOneReq.awaiting || !findOneReq.tried)
            return

        if (!findOneReq.success || !findOneReq.returnData) {
            if (findOneReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', findOneReq.returnData, findOneReq.error)

            return RequestUtils.showDefaultErrorNotification(findOneReq.error, 'Erro ao buscar dados do registro!')
        }

        setHarvest(findOneReq.returnData.harvest)
        setPeopleResponsible(findOneReq.returnData.peopleResponsible)

        setFormValidator(new LogbookFormValidator({
            ...findOneReq.returnData,
            vehicleSummary: !!findOneReq.returnData.vehicleSummary ? {
                ...findOneReq.returnData.vehicleSummary,
                code: findOneReq.returnData.vehicleSummary.code,
                fuelConsume: !!findOneReq.returnData.vehicleSummary.fuelConsume && !!findOneReq.returnData.vehicleSummary.fuelConsume.code
                    ?
                    {
                        ...findOneReq.returnData.vehicleSummary.fuelConsume,
                        quantity: findOneReq.returnData.vehicleSummary.fuelConsume.quantity,
                    }
                    : undefined,
            }
                : undefined,
            equipmentSummary: !!findOneReq.returnData.equipmentSummary ? {
                ...findOneReq.returnData.equipmentSummary,
                code: findOneReq.returnData.equipmentSummary.code,
                fuelConsume: !!findOneReq.returnData.equipmentSummary.fuelConsume && !!findOneReq.returnData.equipmentSummary.fuelConsume.code
                    ?
                    {
                        ...findOneReq.returnData.equipmentSummary.fuelConsume,
                        quantity: findOneReq.returnData.equipmentSummary.fuelConsume.quantity,
                    }
                    : undefined,
            }
                : undefined,
        }))
        setLoadedValues(true)
    }

    if (!props.show || !loadedValues)
        return (<div />)

    return (
        <DrawerCP
            title={`${props.logbookEntryCode ? ` #${props.logbookEntryCode} Editar registro ` : 'Novo registro '}`}
            visible={props.show}
            width={'60%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFormSubmit}
                        loading={request.awaiting}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {
                props.onCancel()
                formStateManager.reset()
                setHarvest(undefined)
                setRecipe(undefined)
            }}
        >

            <WrapperSCP>

                {
                    !!props.harvestPlanningCode &&
                    <HarvestPlanningResumeCardCP
                        harvestPlanningCode={props.harvestPlanningCode}
                    />

                }

                {
                    !!props.recipeCode &&
                    <RecipeCardCP
                        recipeCode={props.recipeCode}
                        setRecipe={setRecipe}
                    />
                }
                <LogbookCommonFieldsCP
                    formStateManager={formStateManager}
                />
                <CollapseCP
                    defaultActiveKeys={['harvestFields', 'responsibleFields']}
                    panels={LogbookUtils.getLogbookFormPannels(
                        formStateManager,
                        props.logbookEntryCode,
                        props.fieldCode,
                        recipe,
                        harvest,
                        peopleResponsible,
                        props.harvestPlanningCode,
                        props.maneuverCode,
                        props.logbookEntryCode,
                        findOneReq.awaiting || request.awaiting,
                        findOneReq.returnData?.startHourMeter,
                        findOneReq.returnData?.vehicle?.code,
                    )}
                    expandIconPosition={'right'}
                    expandIcon={({ isActive }) => (
                        <FontAwsomeIconCP
                            icon={faCaretRight}
                            loading={false}
                            size={'2x'}
                            className={'colapse'}
                            style={
                                {
                                    transform: `rotate(${isActive ? 90 : 0}deg)`,
                                    position: 'absolute',
                                    right: 0,
                                }
                            }
                        />
                    )}
                />
            </WrapperSCP>

        </DrawerCP>
    )
}

const ButtonWrapperSCP = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;

  .ant-btn {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .ant-typography {
    font-size: 11px;
    color: ${props => props.theme.lightGreen}
  }
`

const WrapperSCP = styled.div`
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 0;
    border-bottom: 1px solid ${props => props.theme.lightGray};
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 0;
  }

  .ant-row.has-error .ant-form-explain {
    position: absolute;
    top: -27px;
    right: 0;
  }
    .colapse{
        svg {
            color:  ${props => props.theme.primaryColor};
            fill:  ${props => props.theme.primaryColor};
        }
    }
  @media (max-width: 768px) {
    .ant-row.has-error .ant-form-explain {
      position: absolute;
      top: 45px;
      left: 0;
      width: 200px;
    }
  }
`