import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { CostPerCategoryCP } from 'modules/report/components/cost-per-category/CostPerCategoryCP'
import { FinancialReportByFieldCP } from 'modules/report/components/financial-report-by-field/FinancialReportByFieldCP'
import { FinancialReportGridICP } from 'modules/report/components/financial-report-content/inner/FinancialReportGridICP'
import { ReportFiltersCP } from 'modules/report/components/report-filters/ReportFiltersCP'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'
import { IFinancialReportResDTO } from 'modules/report/services/dtos/responses/IFinancialReportResDTO'
import { ReportRequests } from 'modules/report/services/ReportRequests'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const DEFAULT_FILTERS: ISearchReportReqDTO = {
    season: moment().year(),
}
interface IFinancialReportContentCPProps {
}

export function FinancialReportContentCP(props: IFinancialReportContentCPProps): JSX.Element {
    const [filters, setFilters] = useState<ISearchReportReqDTO>(DEFAULT_FILTERS)
    const [report, setReport] = useState<IFinancialReportResDTO>()

    const getFinacialReportReq = useRequest<IFinancialReportResDTO>()
    useEffect(onRequestChange, [getFinacialReportReq.awaiting])
    useEffect(getReport, [filters])

    function getReport(): void {
        if (!!filters.propertyCode)
            getFinacialReportReq.runRequest(ReportRequests.getFinancialReport(filters))
    }

    function onRequestChange(): void {
        if (getFinacialReportReq.awaiting || !getFinacialReportReq.tried)
            return

        if (!getFinacialReportReq.success || !getFinacialReportReq.returnData) {
            if (getFinacialReportReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getFinacialReportReq.returnData, getFinacialReportReq.error)
            return RequestUtils.showDefaultErrorNotification(getFinacialReportReq.error, 'Erro ao buscar dados!')
        }
        setReport(getFinacialReportReq.returnData)
    }


    return (
        <WrapperSCP>
            <ReportFiltersCP
                filters={filters}
                onChangeFilters={setFilters}
                showFields={['season', 'property', 'field']}
            />

            <FinancialReportGridICP report={report} loading={getFinacialReportReq.awaiting} />
            <PerFieldWrapperSCP>
                <CostPerCategoryCP filters={filters} loading={getFinacialReportReq.awaiting} />
                <FinancialReportByFieldCP filters={filters} />
            </PerFieldWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
   margin-bottom: 4em;
    max-height: 75vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    } 
`
const PerFieldWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 30% 68%;
    column-gap: 10px;  
    
    @media(max-width: 768px ){
        grid-template-columns: 100%;
    }

`
