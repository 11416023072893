import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { ListCP } from 'common/components/list/ListCP'
import { TextCP } from 'common/components/text/TextCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { MaintenanceListItemICP } from 'modules/maneuver/components/maintenance-list-drawer/inner/MaintenanceListItemICP'
import { IMaintenanceResponseDTO } from 'modules/maneuver/services/dtos/responses/IMaintenanceResponseDTO'
import { ImplementRequests } from 'modules/maneuver/services/ImplementRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

interface IMaintenanceListCPProps {
    implementCode: number
}

export function MaintenanceListCP(props: IMaintenanceListCPProps): JSX.Element {
    const [mtnFormVisible, setMtnFormVisible] = useState<boolean>(false)
    const [mtnList, setMtnList] = useState<IMaintenanceResponseDTO[]>([])
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))

    useEffect(getMaintenanceList, [props.implementCode])
    useEffect(onItemChange, [updateStatus])

    const getMaintenanceListReq = useRequest<IGenericListResponseDTO<IMaintenanceResponseDTO>>()
    useEffect(onGetMaintenanceListReqChange, [getMaintenanceListReq.awaiting])

    function getMaintenanceList(): void {
        if (!!props.implementCode)
            getMaintenanceListReq.runRequest(ImplementRequests.searchMaintenances(props.implementCode))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE)
            getMaintenanceList()
    }

    function onGetMaintenanceListReqChange(): void {
        if (getMaintenanceListReq.awaiting || !getMaintenanceListReq.tried) {
            ListActions.isUpdating()
            return
        }

        if (!getMaintenanceListReq.success || !getMaintenanceListReq.returnData) {
            if (getMaintenanceListReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getMaintenanceListReq.returnData, getMaintenanceListReq.error)
            return RequestUtils.showDefaultErrorNotification(getMaintenanceListReq.error, 'Erro ao buscar manutenções!')
        }

        setMtnList(getMaintenanceListReq.returnData.list)
        ListActions.updateIsDone()
    }

    return (
        <WrapperSCP>
            <HeaderSCP>
                <TextCP text={'Cód.'} />
                <TextCP text={'Safra'} />
                <TextCP text={'Data'} />
                <TextCP text={'Custo'} />
                <TextCP text={'Observações'} />
            </HeaderSCP>
            <ListCP<IMaintenanceResponseDTO>
                bordered={false}
                itemLayout={'vertical'}
                size={'large'}
                loadMoreData={() => { }} dataSource={mtnList}
                hasStateControl={true}
                loading={getMaintenanceListReq.awaiting}
                locale={{
                    emptyText: <EmptyCP description={'Ainda não existem manutenções para este equipamento'} />
                }}
                renderItem={(item: IMaintenanceResponseDTO) => (
                    <MaintenanceListItemICP key={item.code} maintenanceData={item} implementCode={props.implementCode} loading={getMaintenanceListReq.awaiting} />
                )}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  margin-top: 15px;
    background: #fff;
    min-height: 100px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    background: #fff;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    margin-bottom:8em;
    max-height: 65vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 680px ){
        max-height: 52vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 61vh;        
    }
    @media(max-height: 680px ){
        max-height: 52vh;        
    }
   
    .ant-list-item{
        padding: 20px;
        margin-bottom: 2em;
    }
 
`
const HeaderSCP = styled.div`
    display: grid;
    grid-template-columns: .3fr .3fr .4fr .4fr 1fr 1fr;
    align-items: center;
    padding: 10px 10px;
    
  
    .ant-typography {
        font-weight: 600;
    }

    @media(max-width: 768px){
     display: none;
     }
`