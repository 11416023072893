import { useScreenSize } from 'common/hooks/UseScreenSize'
import { SelectCP } from '../form-fields/select/SelectCP'
import { IEnumResDTO } from 'common/dtos/responses/IEnumResDTO'
import { EnumRequests } from 'common/EnumRequests'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import React, { useEffect, useState } from 'react'
import { SelectOptionTP } from '../form-fields/select/inner/SelectOptionTP'

interface IEnumSelectorCPProps {
    enumName: string
    formStateManager?: any
    fieldName?: string
    onChange?: (value: number) => void
    isMultiple?: boolean
}
export function EnumSelectorCP(props: IEnumSelectorCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const request = useRequest<IEnumResDTO[]>()
    const [options, setOptions] = useState<SelectOptionTP[]>([])
    useEffect(search, [props.enumName])
    useEffect(onRequestChange, [request.awaiting])

    function search(): void {
        if (!props.enumName) return
        request.runRequest(EnumRequests.search(props.enumName))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar enum!')
        }
        setOptions(request.returnData.map(e => ({
            label: e.labelPtBr,
            value: e.id,
            key: e.name
        })))

    }
    return (
        <SelectCP
            options={options}
            loading={request.awaiting}
            label={'Variedade do café'}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            required={true}
            fontSize={screenSize.smd ? 'large' : 'normal'}
            onChange={props.onChange}
            isMultiple={props.isMultiple}
        />
    )
}