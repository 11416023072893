import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { useSelector } from 'react-redux'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { FieldDrawerCP } from 'modules/field/components/field-drawer/FieldDrawerCP'
import { FieldActionsICP } from 'modules/field/components/field-list/inner/FieldActionsICP'
import { MainFieldInfoICP } from 'modules/field/components/field-list/inner/MainFieldInfoICP'
import { PlantingInfoICP } from 'modules/field/components/field-list/inner/PlantingInfoICP'
import { IFieldResponseDTO } from 'modules/field/services/dtos/responses/IFieldResponseDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { FieldRequests } from 'modules/field/services/FieldRequests'
import { FieldCardSkeletonICP } from 'modules/field/components/field-list/inner/FieldCardSkeletonICP'

interface IFieldCardICPProps {
    field: IFieldResponseDTO
    loading: boolean
    reloadList: () => void
}

export function FieldCardICP(props: IFieldCardICPProps): JSX.Element {
    const property = useSelector((state: IReduxState) => state.property)
    const screenSize = useScreenSize()
    const [showFieldDrawer, setShowFieldDrawer] = useState<boolean>(false)

    const deleteRequest = useRequest(FieldRequests.delete(property.code, props.field.code))
    useEffect(onDeleteRequestChange, [deleteRequest.awaiting])

    function onDeleteRequestChange(): void {

        if (deleteRequest.awaiting || !deleteRequest.tried)
            return

        if (!deleteRequest.success || !deleteRequest.returnData) {
            if (deleteRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteRequest.returnData, deleteRequest.error)
            return RequestUtils.showDefaultErrorNotification(deleteRequest.error, 'Erro ao remover talhão!')
        }

        NotificationHelper.success('Talhão removido com sucesso!')
        props.reloadList()
    }

    return (
        <WrapperSCP>
            <ListItemICP
                isMobile={screenSize.smd}
                key={props.field.code}
                loading={props.loading}
                customSkeleton={<FieldCardSkeletonICP />}
            >

                <ContentWrapperSCP>
                    <MainFieldInfoICP
                        name={props.field.name}
                        year={props.field.plantingYear}
                        type={props.field.coffeeVariety}
                        typeOther={props.field.varieties?.map(v => v.name).join(', ')}
                    />
                    <PlantingInfoICP
                        totalArea={props.field.totalArea}
                        altitude={{ min: props.field.minAltitude, max: props.field.maxAltitude }}
                        spacing={{ line: props.field.lineSpacing, street: props.field.streetSpacing }}
                        treeCount={props.field.treeCount}
                    />
                    <FieldActionsICP
                        onEdit={() => setShowFieldDrawer(true)}
                        onDelete={deleteRequest.runRequest}
                        loading={deleteRequest.awaiting}
                    />
                </ContentWrapperSCP>
            </ListItemICP>
            <FieldDrawerCP
                propertyCode={property?.code}
                visible={showFieldDrawer}
                onClose={() => setShowFieldDrawer(false)}
                field={props.field}
                reloadList={props.reloadList}
            />
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div`

`

const ContentWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 4fr 1fr;
    column-gap: 10px;
    align-items: center;
    @media(max-width: 768px){
        grid-template-columns: none;
        row-gap: 12px;
    }
`
