import { TextCP } from 'common/components/text/TextCP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { ISaveBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISaveBatchRequestDTO'
import { BatchGenerationUtils } from 'modules/batch/utils/BatchGenerationUtils'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment-timezone'
import { BatchFormCardICP } from 'modules/batch/components/batch-generation-drawer/inner/BatchFormCardICP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { AlertCP } from 'common/components/alert/AlertCP'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { MathUtils } from 'common/utils/MathUtils'

interface IBatchInformationsStepCPProps {
    harvests: IHarvestResponseDTO[]
    batches: ISaveBatchRequestDTO[]
    setBatches: (batches: ISaveBatchRequestDTO[]) => void
}

export function BatchInformationsStepCP(props: IBatchInformationsStepCPProps): JSX.Element {
    const totalLiters = MathUtils.round(_.sum(props.harvests.map(hvt => hvt.remainingLiters)))
    const namesSet = new Set(props.harvests.map(hvt => hvt.field.name))
    const fieldNames = ([...namesSet].join(', '))

    const [alertText, setAlertText] = useState<string>()
    const [date, setDate] = useState<string>(DateUtils.transformDateStrFormat(moment().format('YYYY-MM-DD'), DateFormatEnum.EUA_WITHOUT_TIME, DateFormatEnum.BR_WITHOUT_TIME))
    const [season, setSeason] = useState<number>(moment().year())
    const [batchQtt, setBatchQtt] = useState<number>(1)
    const [batches, setBatches] = useState<ISaveBatchRequestDTO[]>([])
    const [usedTotalLiters, setTotalUsedLiters] = useState<number>(0)

    useEffect(onBatchQttChange, [batchQtt])
    useEffect(onChangeDateOrSeason, [date, season])
    useEffect(onChangeTotalLiters, [usedTotalLiters])
    useEffect(onChangeBatchList, [batches])

    function onChangeBatchList(): void {
        setTotalUsedLiters(MathUtils.round(_.sum(batches.map(bt => bt.liters))))
        props.setBatches(batches)
    }

    function onChangeTotalLiters(): void {

        if (totalLiters < usedTotalLiters)
            setAlertText(`Atenção! O total processado ultrapassa em ${usedTotalLiters - totalLiters} litros o total  disponível.`)
        else
            setAlertText(undefined)
    }

    function onBatchQttChange(): void {

        if (!batchQtt)
            return

        if (batchQtt === 1)
            return onAddBatch(batchQtt)

        if (batchQtt > batches.length) {
            const qtt = batchQtt - batches.length
            return onAddBatch(qtt)
        }

        if (batchQtt < batches.length)
            return onRemoveBatch(batchQtt)

    }

    function onAddBatch(qtt: number): void {
        if (!batchQtt)
            return

        const totalRemainingLiters = _.sum(props.harvests.map(hv => hv.remainingLiters))
        const totalUsed = _.sum(batches.map(bt => bt.liters))
        const totalRemainingLitersPerMaturation = BatchGenerationUtils.getTotalLitersPerMaturation(props.harvests)

        // lote inicial sempre com o valor total de litros disponível
        if (batchQtt === 1) {
            return setBatches([{
                date,
                season,
                liters: MathUtils.round(totalRemainingLiters),
                litersPerMaturation: totalRemainingLitersPerMaturation
            }])
        }

        const list = _.fill(Array(qtt), {
            date,
            season,
            liters: MathUtils.round(totalRemainingLiters - totalUsed)
        })

        return setBatches([
            ...batches,
            ...list
        ])

    }

    function onRemoveBatch(qtt: number): void {
        setBatches(batches.filter((b, i) => i < qtt))
    }

    function onChangeDateOrSeason(): void {
        if (!batches.length)
            return

        const newBatches = batches.map((bt) => ({
            ...bt,
            date,
            season
        }))
        setBatches(newBatches)
    }

    function onChangeBatch(batch: ISaveBatchRequestDTO, index: number): void {

        const newBtList = batches.map((bat, i) => {
            if (i === index)
                return batch
            return bat
        })

        setBatches(newBtList)
    }

    return (
        <MainWrapperSCP>
            <HeaderSCP>
                <BasicInfoSCP>
                    <RowSCP>
                        <TextCP text={'Talhões:'} />
                        <TextCP text={`${fieldNames}`} style={{ marginLeft: '10px', fontSize: '12px', fontWeight: 600, maxWidth: '200px' }} />
                    </RowSCP>
                    <RowSCP>
                        <TextCP text={'Litros disponíveis:'} />
                        <TextCP text={`${MaskUtils.applyNumberMask(totalLiters)} L`} style={{ marginLeft: '10px', fontSize: '16px', fontWeight: 600 }} />
                    </RowSCP>
                    <RowSCP>
                        <TextCP text={'Litros a processar:'} />
                        <TextCP text={`${MaskUtils.applyNumberMask(usedTotalLiters)} L`} style={{ marginLeft: '10px', fontSize: '16px', fontWeight: 600 }} />
                    </RowSCP>
                    {
                        !!alertText &&
                        <AlertCP
                            type={'error'}
                            message={alertText}
                        />
                    }
                </BasicInfoSCP>

                <GenericFieldsSCP>
                    <SeasonSelectorCP
                        label={'Safra: *'}
                        required={true}
                        value={season}
                        onChange={setSeason}
                    />
                    <InputCP
                        label={'Data: *'}
                        value={date}
                        onChange={setDate}
                        type={'date'}
                        required={true}
                        placeholder={'Data'}
                        mask={MaskUtils.applyDateMask}
                        fontSize={'normal'}
                    />

                    <InputCP
                        label={'Qtd. de lotes: '}
                        value={batchQtt}
                        onChange={(val: number) => setBatchQtt(+val)}
                        type={'number'}
                        required={true}
                        placeholder={'Qtd. de lotes'}
                        fontSize={'normal'}
                    />

                </GenericFieldsSCP>
            </HeaderSCP>
            <BatchCardListSCP>
                {
                    !!batches.length &&
                    batches.map((batch, index) => (
                        <CardWrapperSCP key={`${index}`}>
                            <BatchFormCardICP
                                batch={batch}
                                index={index}
                                allHarvests={props.harvests}
                                onChangeBatch={(bat) => onChangeBatch(bat, index)}
                                batchQtt={batchQtt}
                            />
                            {
                                index === batches.length - 1 &&
                                <AddRemoveBtnWrapperSCP>
                                    <ButtonCP
                                        type={'primary'}
                                        onClick={() => setBatchQtt(batchQtt + 1)}
                                        icon={<FontAwsomeIconCP icon={faPlus} />}
                                    />
                                    <ButtonCP
                                        type={'primary'}
                                        onClick={() => setBatchQtt(batchQtt - 1)}
                                        icon={<FontAwsomeIconCP icon={faMinus} />}
                                        disabled={batchQtt === 1}
                                    />
                                </AddRemoveBtnWrapperSCP>

                            }

                        </CardWrapperSCP>

                    ))
                }
            </BatchCardListSCP>

        </MainWrapperSCP>
    )
}

const MainWrapperSCP = styled.div`
`
const WrapperSCP = styled.div`
`
const CardWrapperSCP = styled.div`
    display: flex;
    position: relative;
    align-items: flex-start;
    position: relative;
`

const HeaderSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr .5fr;
    column-gap: 10px;
    margin-bottom: 15px;
    @media(max-width: 767px){         
         grid-template-columns: 1fr;
         row-gap: 30px;
    }
    
`
const RowSCP = styled.div`
    display: flex;
    align-items: center;
    svg{
            margin-left: 10px;
    }
`

const BasicInfoSCP = styled.div`
    display: flex;
    width: 100%; 
    justify-content: center;  
    flex-direction: column;
    align-items: flex-start;
   
    .ant-typography{
        font-size: 13px;
        ${props => props.theme.primaryColor};
    }

    svg {
        ${props => props.theme.primaryColor};
    }
`
const AddRemoveBtnWrapperSCP = styled.div`
        margin-left: 10px;
        position: absolute;
        margin: auto 10px;
        display: flex;
        flex-direction: column;
        right: -60px;
        min-height: 86px;
        justify-content: space-between;

        @media(max-width: 768px){
            flex-direction: row-reverse;
            right: 0;
            left: 0;
            margin: 0 auto;
            bottom: -90px;
            min-height: 86px; 
            justify-content: center;    
            .ant-btn {
                margin: 10px;
            } 
        }

`
const GenericFieldsSCP = styled.div`
  display: grid;
    .ant-row{
        flex-direction: row;
    }
`

const BatchCardListSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 20px;
    margin-bottom: 50px;
    margin-right: 20px;
    @media(max-width: 767px){         
         grid-template-columns: 1fr;
         row-gap: 10px;
         margin-bottom: 120px;
        margin-right: 0;
     }
`