import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { HarvestPlanningFormValidator } from 'modules/harvest-planning/components/harvest-planning-drawer/HarvestPlanningFormValidator'
import { ISaveHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISaveHarvestPlanningReqDTO'
import { IHarvestPlanningItemResDTO } from 'modules/harvest-planning/services/dtos/response/IHarvestPlanningItemResDTO'
import { HarvestPlanningRequests } from 'modules/harvest-planning/services/HarvestPlanningRequests'
import { HarvestRoutes } from 'modules/harvest/HarvestRoutes'

import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface IHavestPlanningDrawerCPProps {
    harvestPlanningCode?: number
    show: boolean
    onCancel: () => void
    onSuccess: () => void
}

export function HavestPlanningDrawerCP(props: IHavestPlanningDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const history = useHistory()

    const [fieldCode, setFieldCode] = useState<number>()
    const [formValidator, setFormValidator] = useState<HarvestPlanningFormValidator>(new HarvestPlanningFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const saveRequest = useRequest<IHarvestPlanningItemResDTO>()
    useEffect(onRequestChange, [saveRequest.awaiting])

    const getDetailsReq = useRequest<IHarvestPlanningItemResDTO>()
    useEffect(onGetDetailsReqChange, [getDetailsReq.awaiting])

    useEffect(getDetails, [props.harvestPlanningCode, props.show])

    function getDetails(): void {
        if (!!props.harvestPlanningCode && props.show)
            getDetailsReq.runRequest(HarvestPlanningRequests.get(props.harvestPlanningCode))
    }

    function onGetDetailsReqChange(): void {
        if (getDetailsReq.awaiting || !getDetailsReq.tried)
            return

        if (!getDetailsReq.success || !getDetailsReq.returnData) {
            if (getDetailsReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getDetailsReq.returnData, getDetailsReq.error)
            return RequestUtils.showDefaultErrorNotification(getDetailsReq.error, 'Ocorreu um erro ao tentar buscar os dados do planejamento. Tente novamente.')
        }

        const item: ISaveHarvestPlanningReqDTO = {
            fieldCode: getDetailsReq.returnData.field.code,
            plannedStartDate: DateUtils.getFormatted(getDetailsReq.returnData.plannedStartDate, DateFormatEnum.BR_WITHOUT_TIME),
            plannedEndDate: DateUtils.getFormatted(getDetailsReq.returnData.plannedEndDate, DateFormatEnum.BR_WITHOUT_TIME),
            estimatedLiters: getDetailsReq.returnData.estimatedLiters,
            season: getDetailsReq.returnData.season
        }
        setFormValidator(new HarvestPlanningFormValidator(item))
        setFieldCode(getDetailsReq.returnData.field.code)
    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)

        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveHarvestPlanningReqDTO = {
            fieldCode: formValues.fieldCode,
            plannedStartDate: !!formValues.plannedStartDate ? DateUtils.getDateFormattedToApi(formValues.plannedStartDate) : undefined,
            plannedEndDate: !!formValues.plannedEndDate ? DateUtils.getDateFormattedToApi(formValues.plannedEndDate) : undefined,
            estimatedLiters: !!formValues.estimatedLiters ? +formValues.estimatedLiters : undefined,
            season: formValues.season
        }

        saveRequest.runRequest(props.harvestPlanningCode ?
            HarvestPlanningRequests.update(props.harvestPlanningCode, dto) :
            HarvestPlanningRequests.save(dto))
    }

    function onRequestChange(): void {
        if (saveRequest.awaiting || !saveRequest.tried)
            return

        if (!saveRequest.success || !saveRequest.returnData) {
            if (saveRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveRequest.returnData, saveRequest.error)
            return RequestUtils.showDefaultErrorNotification(saveRequest.error, 'Erro ao salvar planejamento de colheita!')
        }

        NotificationHelper.success('Item salvo com sucesso!')
        formStateManager.reset()
        props.onSuccess()
        ListActions.mustUpdate()
        history.push(`${HarvestRoutes.LOGBOOK_WITHOUT_PARAM}/${saveRequest.returnData.code}`)
    }

    return (
        <DrawerCP
            title={props.harvestPlanningCode ? `Editar planejamento de colheita #${props.harvestPlanningCode}` : 'Novo planejamento de colheita'}
            visible={props.show}
            width={'60%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFormSubmit}
                        loading={saveRequest.awaiting || getDetailsReq.awaiting}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {
                props.onCancel()
            }}
        >

            <WrapperSCP>
                <PropertyFieldFieldsCP<HarvestPlanningFormValidator>
                    fieldFieldName={'fieldCode'}
                    formStateManager={formStateManager}
                    required={true}
                />
                {/* <PropertyFieldCascaderCP
                    onChangeCascader={(propertyCode?: number, _fieldCode?: number, totalArea?: number) => {
                        setFieldCode(fieldCode)
                        formStateManager.changeFieldValue('fieldCode', _fieldCode)
                    }}
                    label={'Propriedade/Talhão'}
                    changeOnSelect={true}
                    allowAllFields={true}
                    expandTrigger={'hover'}
                    eager={true}
                    fieldValue={fieldCode}
                /> */}
                <TwoColWrapperSCP>

                    <InputCP
                        label={'Data de início'}
                        placeholder={'__/__/____'}
                        fieldName={'plannedStartDate'}
                        type={'date'}
                        formStateManager={formStateManager}
                        required={false}
                        mask={MaskUtils.applyDateMask}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        maxLength={10}
                        loading={saveRequest.awaiting || getDetailsReq.awaiting}
                    />
                    <InputCP
                        label={'Data de finalização (estimativa)'}
                        placeholder={'__/__/____'}
                        type={'date'}
                        mask={MaskUtils.applyDateMask}
                        fieldName={'plannedEndDate'}
                        formStateManager={formStateManager}
                        required={false}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        maxLength={10}
                        loading={saveRequest.awaiting || getDetailsReq.awaiting}
                    />
                </TwoColWrapperSCP>
                <TwoColWrapperSCP>
                    <InputCP
                        label={'Litragem estimada:'}
                        type={'number'}
                        fieldName={'estimatedLiters'}
                        formStateManager={formStateManager}
                        required={false}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        icon={<span>litros/pé de café</span>}
                        loading={saveRequest.awaiting || getDetailsReq.awaiting}
                    />
                    <SeasonSelectorCP
                        formStateManager={formStateManager}
                        label={'Safra'}
                        required={true}
                    />
                </TwoColWrapperSCP>
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    row-gap: 10px;

`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`