import { faChartArea, faFileAlt, faFileCsv, faFileImage, faFilePdf, faFileWord, faTractor } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { IIconInfoGridColumnProps } from 'common/components/icon-info-grid/inner/IIconInfoGridColumnProps'
import { IPropertyResponseDTO } from 'modules/property/services/dtos/responses/IPropertyResponseDTO'
import React from 'react'

export const PropertyUtils = {

    getDocumentThumb(file: string): JSX.Element {
        const extension = file.substring(file.lastIndexOf('.'))

        switch (extension) {
            case '.doc':
                return <FontAwsomeIconCP icon={faFileWord} />
            case '.docx':
                return <FontAwsomeIconCP icon={faFileWord} />
            case '.xls':
                return <FontAwsomeIconCP icon={faFileCsv} />
            case '.xlsx':
                return <FontAwsomeIconCP icon={faFileCsv} />
            case '.pdf':
                return <FontAwsomeIconCP icon={faFilePdf} />
            case '.jpg':
                return <FontAwsomeIconCP icon={faFileImage} />
            default:
                return <FontAwsomeIconCP icon={faFileAlt} />
        }
    },

    getDocumentType(file: string): string {

        console.log('file: ' + file)
        const extension = file.substring(file.lastIndexOf('.'))

        switch (extension) {
            case 'doc':
                return 'Documento do Word'
            case 'docx':
                return 'Documento do Word'
            case 'xls':
                return 'Planilha do Excel'
            case 'xlsx':
                return 'Planilha do Excel'
            case 'pdf':
                return 'Documento PDF'
            case 'jpg':
                return 'Arquivo de imagem'
            case 'jpeg':
                return 'Arquivo de imagem'
            default:
                return 'Formato não identificado'
        }
    },

    getDocumentName(file: string): string {
        const documentName = file.substring(file.lastIndexOf('_') + 1, file.lastIndexOf('.'))
        const documentNameSplitted = documentName.split('?')
        return documentNameSplitted.length === 1 ? documentName : documentNameSplitted[0]
    },

    getDocumentExtension(file: string): string {
        return file.substring(file.lastIndexOf('.') + 1)
    },

    getIconGrigColumnsConfig(): Array<IIconInfoGridColumnProps<IPropertyResponseDTO>> {
        return [
            {
                title: 'Total area',
                key: 'areaTotal',
                renderValue: (info: IPropertyResponseDTO) => `${info.totalArea}ha`,
                icon: <FontAwesomeIcon icon={faChartArea} />,
            },
            {
                title: 'Dedicated area',
                key: 'dedicatedArea',
                renderValue: (info: IPropertyResponseDTO) => `${info.dedicatedArea}ha`,
                icon: <FontAwesomeIcon icon={faTractor} />,
            },
        ]
    },

}