import moment from 'moment-timezone'
import { PhotoGalleryCP } from 'common/components/custom-photo-gallery/PhotoGalleryCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { TextCP } from 'common/components/text/TextCP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { IBatchProcessAlbumByDateResDTO } from 'modules/batch/services/dtos/responses/IBatchProcessAlbumByDateResDTO'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import { LogbookUtils } from 'modules/logbook/utils/LogbookUtils'
import React from 'react'
import styled from 'styled-components'

interface IAllProcessPicturesDrawerCPProps {
    show: boolean
    onClose: () => void
    albumsByDate: IBatchProcessAlbumByDateResDTO[]
}

export function AllProcessPicturesDrawerCP(props: IAllProcessPicturesDrawerCPProps): JSX.Element {

    return (
        <DrawerCP
            title={'Processes'}
            visible={props.show}
            width={'80%'}
            onClose={() => {
                props.onClose()
            }}
        >
            <WrapperSCP>
                {
                    props.albumsByDate.map(album => (
                        <AlbumWrapperSCP key={`${album.date}_${Math.random()}_key`}>
                            <HeaderSCP>
                                <InfoWrapperSCP>
                                    <DateTimelineSCP>
                                        <TextCP
                                            text={moment(album.date).format('DD')}
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                color: 'white',
                                                lineHeight: '18px',
                                                leterSpacing: '3px'
                                            }}
                                        />
                                        <TextCP
                                            text={moment(album.date).locale('en-us').format('MMM')}
                                            style={{
                                                fontSize: '12px',
                                                fontWeight: 'normal',
                                                color: 'white',
                                                lineHeight: '13px',
                                            }}
                                        />
                                    </DateTimelineSCP>
                                    <CenterInfoWrapperSCP>
                                        <DateAndTimeSCP>
                                            <TextCP
                                                text={`${LogbookUtils.getDateText(album.date)}`}
                                                style={{ fontSize: '14px', fontWeight: 500, color: `${CostumizedThemeConfig.primaryColor}` }}
                                            />
                                        </DateAndTimeSCP>

                                        <NoteWrapperSCP>
                                            {album.processTypes.map(pt => BatchUtils.translateProcessToEnglish(pt)).join(', ')}
                                        </NoteWrapperSCP>

                                    </CenterInfoWrapperSCP>
                                </InfoWrapperSCP>
                            </HeaderSCP>
                            <PhotoGalleryCP
                                images={!!album.pictures ?
                                    album.pictures.map(pic => ({
                                        url: pic,
                                        previewConfig: {
                                            mask: <MaskWrapperSCP />
                                        }
                                    }))
                                    :
                                    []}
                            />
                        </AlbumWrapperSCP>
                    ))
                }
            </WrapperSCP>
        </DrawerCP>

    )
}

const WrapperSCP = styled.div`
`
const AlbumWrapperSCP = styled.div`
`
const InfoWrapperSCP = styled.div`
    display: flex;
    @media(max-width: 768px){
      margin-bottom: 10px;
       
   }
`
const HeaderSCP = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin: 20px 0;
   @media(max-width: 768px){
        flex-direction: column;
       
   }
`

const DateTimelineSCP = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 60px;
    min-width: 60px;
    justify-content: center;
    position: relative;
`
const CenterInfoWrapperSCP = styled.div`
    margin: 10px 0 10px 12px;
    display: grid;
    row-gap: 8px;
`
const DateAndTimeSCP = styled.div`
`

const MaskWrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    opacity: .5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
`
const NoteWrapperSCP = styled.div`    
    font-style: italic;
`