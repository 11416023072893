import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'
import { LoggingManager } from 'common/logging/LoggingManager'
import { ReduxPersistorHelper } from 'common/redux/helpers/ReduxPersistorHelper'
import { IAction } from 'common/redux/interfaces/IAction'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { Reducers } from 'common/redux/Reducers'
import { ReduxUtils } from 'common/redux/ReduxUtils'
import { SystemConfig } from 'config/SystemConfig'
import * as _ from 'lodash'
import { AuthActions } from 'modules/auth/AuthActions'
import { applyMiddleware, combineReducers, createStore, Store, StoreEnhancer } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import reduxThunkMiddleware from 'redux-thunk'

/**
 * REDUX
 * Encapsula logica para configuracao do gerenciamento de
 * estado da aplicacao via redux.
 *
 * @todo: Usar 'config params' novo (quando for migrado)
 *
 * @author hjcostabr
 */
export class ReduxHelper {

    /** Store (guarda estado global da aplicacao). */
    private readonly store: Store

    /** Persistor (elemento que garante persistencia de partes do estado global). */
    private readonly persistor: Persistor

    /** Flag: Habilita OU NAO middleware para debug de actions do redux. */
    private static readonly ENABLE_LOG = false

    /** Instancia unica da classe. */
    private static instance?: ReduxHelper

    private constructor() {
        const combinedReducers = combineReducers<IReduxState>(Reducers)
        const persistedReducer = persistReducer(ReduxPersistorHelper.getPersistorConfig(), combinedReducers)
        this.store = createStore(persistedReducer, ReduxHelper.getMiddleware())
        this.persistor = persistStore(this.store)
    }

    /** Gera & retorna instancia unica da manager. */
    static getInstance(): ReduxHelper {
        if (!this.instance)
            this.instance = new ReduxHelper()
        return this.instance
    }

    /** Gera & retorna lista de middleware a ser aplicado no redux. */
    private static getMiddleware(): StoreEnhancer {

        const middlewareList: any[] = [
            reduxThunkMiddleware,
            ReduxUtils.createMiddleware(this.rehydrateMWCallback, ReduxPersistorHelper.ACTION_REHYDRATE),  // Rehydrate middleware
        ]

        // Incluir middleware para log/debug do redux (se necessario)
        if (this.ENABLE_LOG)
            middlewareList.push(ReduxUtils.createMiddleware(LoggingManager.logReduxAction))

        const appliedMiddleware = applyMiddleware(...middlewareList)
        return SystemConfig.getInstance().environment === EnvironmentEnum.DEV ? composeWithDevTools(appliedMiddleware) : appliedMiddleware
    }

    /**
     * Callback para middleware que intercepta action de 'reidratacao' de estado
     * (redux-persist).
     */
    private static rehydrateMWCallback(action: IAction): void {
        const token = _.get(action, 'payload.loggedUser.accessToken')
        if (!!token)
            AuthActions.refreshToken(token)
    }

    getStore(): Store {
        return this.store
    }

    getPersistor(): Persistor {
        return this.persistor
    }
}

