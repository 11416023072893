/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { IsOptional } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { ISaveBenefitReqDTO } from 'modules/batch/services/dtos/requests/ISaveBenefitReqDTO'

export class BenefitFormValidator extends FormModelValidator {

    @IsOptional()
    code?: number

    @IsOptional()
    spoutOne?: number

    @IsOptional()
    spoutTwo?: number

    @IsOptional()
    choice?: number

    @IsOptional()
    other?: number

    @IsOptional()
    humidity?: number

    constructor(dto?: ISaveBenefitReqDTO) {
        super(dto)
        Object.assign(this, dto)
    }
}