import { CloudUploadOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import React, { useState } from 'react'

const { Dragger } = Upload

interface Props {
    name: string
    multiple: boolean
    accept?: string[]
    onUpload: (url: any) => void
    files?: any[]
}

export function UploadInput(props: Props): JSX.Element {
    const [fileList, setFileList] = useState<any>([])

    return (
        <Dragger
            name={props.name}
            multiple={props.multiple}
            accept={props.accept?.join(',')}
            beforeUpload={() => false}
            onChange={(info) => {
                setFileList(info.fileList.splice(-1))
                props.onUpload(info.file)
            }}
            fileList={fileList}
        >
            <p className={'ant-upload-drag-icon'}>
                <CloudUploadOutlined />
            </p>
            <p className={'ant-upload-text'}>Clique ou arraste um arquivo para esta área </p>
        </Dragger>
    )
}