import { faFunnelDollar } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { FinancialReportContentCP } from 'modules/report/components/financial-report-content/FinancialReportContentCP'
import React from 'react'
import styled from 'styled-components'

export function FinancialReportScreen(): JSX.Element {

    return (
        <LayoutCP
            header={
                <HeaderCP icon={<FontAwsomeIconCP icon={faFunnelDollar} />} title={'Indicadores financeiros'} />
            }
            content={
                <FinancialReportContentCP />
            }
        />
    )
}

const WrapperSCP = styled.div`
`
