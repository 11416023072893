/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable max-params */
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { LoggedUserDataTP } from 'common/redux/ReduxTypes'
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestHelper } from 'common/request-helper/RequestHelper'
import * as _ from 'lodash'
import { ManeuverActions } from 'modules/maneuver/ManeuverActions'
import { AuthRoutes } from './AuthRoutes'

export class AuthActions {
    static readonly TYPE_UPDATE_LOGGED_USER = 'UPDATE_LOGGED_USER'

    private static handlingUnauthorizedError = false

    private constructor() {

        /* private constructor to avoid instantiation */
    }

    static logUserIn(data: LoggedUserDataTP): void {
        this.logUserInOrOut(data)
    }

    static authorizePublicRoutes(token: string): void {
        this._authorizePublicRoutes(token)
    }

    static logout(): void {
        AuthActions.logUserInOrOut()
        AuthActions.refreshToken(null)
    }

    static onUnauthorizedRequestResponse(error: any, history: any): void {
        if (this.handlingUnauthorizedError)
            return

        const currentState: IReduxState = ReduxHelper.getInstance().getStore().getState()

        if (!_.isEmpty(currentState.loggedUser)) {
            this.handlingUnauthorizedError = true
            setTimeout(() => (AuthActions.handlingUnauthorizedError = false), 5)
        }

        this.logout()
        history.replace(`${currentState.schemaData.domain}/${AuthRoutes.LOGIN}`)
    }

    static refreshToken(token: string | null): void {
        RequestHelper.setBearerAuthToken(token ?? undefined)
    }

    private static _authorizePublicRoutes(token?: string): void {
        const isLogin = !!token
        const payload = isLogin ? ({ token } as LoggedUserDataTP) : null

        ReduxHelper.getInstance().getStore().dispatch({
            type: this.TYPE_UPDATE_LOGGED_USER,
            payload,
        })
        if (isLogin)
            AuthActions.refreshToken(token!)
    }

    private static logUserInOrOut(data?: LoggedUserDataTP): void {
        console.log('payload: ', data)

        const payload = data ?? null

        ReduxHelper.getInstance().getStore().dispatch({
            type: this.TYPE_UPDATE_LOGGED_USER,
            payload,
        })

        if (!data) {
            ReduxHelper.getInstance().getStore().dispatch({
                type: ManeuverActions.TYPE_CHILD_MENU_OPENED,
                payload: null,
            })
            ReduxHelper.getInstance().getStore().dispatch({
                type: ManeuverActions.TYPE_INSTRUCTIONS_TYPE_FILTERED,
                payload: null,
            })
            ReduxHelper.getInstance().getStore().dispatch({
                type: SearchActions.TYPE_UPDATED_SEARCH_STATE,
                payload: null,
            })
        }

        if (!!data?.token)
            AuthActions.refreshToken(data.token)
    }
}
