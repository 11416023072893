import { BestBatchListCP } from 'modules/report/components/field-report-content/inner/BestBatchListCP'
import { ReportFiltersCP } from 'modules/report/components/report-filters/ReportFiltersCP'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'
import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'

const DEFAULT_FILTERS: ISearchReportReqDTO = {
    season: moment().year(),
}
interface IBestBatchesReportContentProps {
}

export function BestBatchesReportContent(props: IBestBatchesReportContentProps): JSX.Element {
    const [filters, setFilters] = useState<ISearchReportReqDTO>(DEFAULT_FILTERS)
    return (
        <WrapperSCP>
            <ReportFiltersCP
                filters={filters}
                onChangeFilters={setFilters}
                showFields={['season', 'property', 'field']}
            />
            <BestBatchListCP loading={false} filters={filters} showTitle={false} />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    margin-bottom: 4em;
    max-height: 75vh;   
    overflow: overlay;
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    }
`
