import { Layout } from 'antd'
import { PageTitleCP } from 'common/components/page-title/PageTitleCP'
import { TitleCP } from 'common/components/title/TitleCP'
import React from 'react'
import styled from 'styled-components'
import { useScreenSize } from 'common/hooks/UseScreenSize'

interface IHeaderCPProps extends React.PropsWithChildren<{
    title?: string,
    onClickBack?: () => void,
    loading?: boolean,
    breadcrumb?: JSX.Element,
    icon?: JSX.Element,
}> { }

/**
 * Componente de cabeçalho do sistema.
 *
 * @author kiaravzm
 */
export function HeaderCP(props: IHeaderCPProps): JSX.Element {
    const screenSize = useScreenSize()
    return (
        <WrapperSCP>
            <Layout.Header>
                <HeaderTitleSCP>

                    <TitleCP textSize={screenSize.smd ? 14 : 18}>
                        {props.icon}
                        {props.title}
                    </TitleCP>
                    {props.breadcrumb}
                    <PageTitleCP pageName={props.title ?? ''} />
                </HeaderTitleSCP>
                <ContainerSCP>
                    {props.children}
                </ContainerSCP>
            </Layout.Header>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    background-color: transparent;
    padding: 0;
    margin: 0 2em;
    @media(max-width: 768px){
        margin-left: 0;
        margin: 0 3em;
    }

    .ant-layout-header{
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 10px;
        height: 40px;
        h3{
            display:flex;
            color: #fff;
            align-items: center;
            svg {
                margin-right: 8px;
            }
        }

        @media(max-width: 768px){
            align-items: center;
            width: 95%;
            h3 {
                margin: 0;
            }

            svg {
                font-size: 14px
            }
        }
    }

    @media(max-width: 788px){
        margin: 0 0 1em 0;
    }
`

const ContainerSCP = styled.div`
    align-items: center;
    justify-content: flex-end;
    display: flex;
    /* @media(max-width: 788px){
        margin-right: 2em;
        position: absolute;
        right: 0;
    } */
`
const HeaderTitleSCP = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 24px;
    @media(max-width: 786px){
            h3 {
              line-height: 28px;
              margin: 0;
        }
    }
`
