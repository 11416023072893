import { faPlus, faTractor } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { ImplementDrawerCP } from 'modules/maneuver/components/implement-drawer/ImplementDrawerCP'
import { ImplementTypeEnum } from 'modules/maneuver/enums/ImplementTypeEnum'
import React, { useState } from 'react'
import { PaginationConfig } from 'antd/lib/pagination'
import { ImplementListCP } from 'modules/maneuver/components/implement-list/ImplementListCP'
import { ISearchImplementRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchImplementRequestDTO'
import styled from 'styled-components'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { ListActions } from 'common/components/list/inner/ListActions'

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchImplementRequestDTO = {
    type: ImplementTypeEnum.VEHICLE
}

export function VehicleScreen(): JSX.Element {
    const [showFormDrawer, setShowFormDrawer] = useState<boolean>(false)
    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={'Veículos'}
                    icon={
                        <FontAwsomeIconCP icon={faTractor} />
                    }
                >
                    <ButtonCP
                        icon={<FontAwsomeIconCP icon={faPlus} />}
                        size={'middle'}
                        type={'ghost'}
                        onClick={() => setShowFormDrawer(true)}
                    >
                        Novo
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <ImplementListCP
                        filterType={FilterTypesEnum.VEHICLE}
                        implementType={ImplementTypeEnum.VEHICLE}
                    />
                    <ImplementDrawerCP
                        show={showFormDrawer}
                        onCancel={() => setShowFormDrawer(false)}
                        onSuccess={() => {
                            ListActions.mustUpdate()
                            setShowFormDrawer(false)
                        }}
                        type={ImplementTypeEnum.VEHICLE}
                    />
                </ContentCP>
            }
        />
    )
}

const TotalSCP = styled.div`
    margin-left: 3px;
        display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      

   
    }
    @media(max-width: 768px){        
    margin-top: 17px;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`
