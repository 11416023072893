import { AvatarCP } from 'common/components/avatar/AvatarCP'
import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'

interface IProducerCardICPProps {
    name: string
    photo: string
}

export function ProducerCardICP(props: IProducerCardICPProps): JSX.Element {

    return (
        <UserWppSCP>
            <AvatarCP src={props.photo} size={50} />
            <TextSCP>
                <TextCP
                    text={'producer'}
                    style={{ fontSize: '12px', fontWeight: 300, letterSpacing: '1px' }}
                    translationFiles={'batch'}
                    translationNameSpace={'batch'}
                />
                <TextCP
                    text={props.name}
                    style={{ fontSize: '15px', fontWeight: 600 }}
                />
            </TextSCP>

        </UserWppSCP>
    )
}
const UserWppSCP = styled.div`
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-top: 1em;
  
`
const TextSCP = styled.div`
    .ant-typography{
        color: white;
    }
    
`