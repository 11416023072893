import { PaginationConfig } from 'antd/lib/pagination'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IPersonResponseDTO } from 'modules/person/services/dtos/responses/IPersonResponseDTO'
import React, { useCallback, useEffect, useState } from 'react'
import { ISearchPersonRequestDTO } from 'modules/person/services/dtos/requests/ISearchPersonRequestDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import styled from 'styled-components'
import { CardCP } from 'common/components/card/CardCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { PersonCardICP } from 'modules/person/components/person-list/inner/PersonCardICP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { TextCP } from 'common/components/text/TextCP'
import { PersonListFiltersCP } from '../person-list-filters/PersonListFiltersCP'

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchPersonRequestDTO = {

}
interface IPersonListProps { }
export function PersonListCP(props: IPersonListProps): JSX.Element {
    const [personList, setPersonList] = useState<IPersonResponseDTO[]>([])
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))

    const [total, setTotal] = useState<number>(0)
    const [filters, setFilters] = useState<ISearchPersonRequestDTO>(DEFAULT_FILTERS)
    const [pagination, setPagination] = useState<PaginationConfig>(DEFAULT_PAGINATION)

    useEffect(onItemChange, [updateStatus])

    const getPersonListRequest = useRequest<IGenericListResponseDTO<IPersonResponseDTO>>()
    useEffect(onRequestChange, [getPersonListRequest.awaiting])

    useEffect(getPersonList, [filters])

    function getPersonList(): void {
        if (!!filters)
            getPersonListRequest.runRequest(PersonRequests.getPersonList(filters))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE)
            getPersonList()
    }

    function onRequestChange(): void {
        if (getPersonListRequest.awaiting || !getPersonListRequest.tried) {
            ListActions.isUpdating()
            return
        }

        if (!getPersonListRequest.success || !getPersonListRequest.returnData) {
            if (getPersonListRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getPersonListRequest.returnData, getPersonListRequest.error)
            return RequestUtils.showDefaultErrorNotification(getPersonListRequest.error, 'Erro ao buscar lista de pessoas!')
        }

        setPagination({
            ...pagination,
            total: getPersonListRequest.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                setFilters({ ...filters, page, itemsPerPage: pageSize })
            }
        })
        setTotal(getPersonListRequest.returnData.total)
        setPersonList(getPersonListRequest.returnData.list)

        ListActions.updateIsDone()
    }

    function onChangeFilter(filter: ISearchPersonRequestDTO): void {
        setFilters({ ...filter, page: DEFAULT_PAGINATION.current, itemsPerPage: DEFAULT_PAGINATION.pageSize })
        setPagination(DEFAULT_PAGINATION)
    }

    return (
        <MainWppSCP>
            <PersonListFiltersCP
                filters={filters}
                setFilters={onChangeFilter}
            />
            <TotalSCP>
                <TextCP
                    text={`${total} registros encontrados`}

                />
                <TextCP
                    text={'Limpar filtros'}
                    onClick={() => {
                        setFilters(DEFAULT_FILTERS)
                        setPagination(DEFAULT_PAGINATION)
                    }}
                    style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
                />
            </TotalSCP>
            <ListWrapperSCP>
                <ListCP<IPersonResponseDTO>
                    grid={{ gutter: 16, column: 3, md: 1, sm: 1, xs: 1, lg: 2, xl: 3, xxl: 3 }}
                    loading={getPersonListRequest.awaiting}
                    bordered={false}
                    itemLayout={'vertical'}
                    size={'large'}
                    dataSource={personList}
                    hasStateControl={true}
                    locale={{
                        emptyText: <CardCP noSpacing={true} noSideSpacing={true}>
                            {' '}
                            <EmptyCP description={'Nenhum resultado'} />
                            {' '}
                        </CardCP>
                    }}
                    renderItem={(item: IPersonResponseDTO) => (
                        <PersonCardICP person={item} loading={getPersonListRequest.awaiting} />
                    )}
                    pagination={pagination}
                />
            </ListWrapperSCP>
        </MainWppSCP>

    )
}

const ListWrapperSCP = styled.div`
   border-radius: 10px;
    margin-bottom: 4em;
    max-height: 75vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 70vh;        
    }

`
const MainWppSCP = styled.div``
const TotalSCP = styled.div`
    margin-left: 3px;
    display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      

   
    }
    @media(max-width: 768px){        
    margin-top: 17px;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`
