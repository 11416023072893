import { LangSwitchCP } from 'common/components/language-switch/LangSwitchCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { BatchBasicInfoCP } from 'modules/batch-presentation/components/batch-basic-info/BatchBasicInfoCP'
import { BatchSlideshowCP } from 'modules/batch-presentation/components/batch-slideshow/BatchSlideshowCP'
import { LoadingBoxCP } from 'modules/batch-presentation/components/loading-box/LoadingBoxCP'
import { ProducerCardICP } from 'modules/batch-presentation/components/producer-carrousel/inner/ProducerCardICP'
import { BatchPresentationRequests } from 'modules/batch-presentation/services/BatchPresentationRequests'
import { IBatchBasicInfoResDTO } from 'modules/batch-presentation/services/dtos/response/BatchBasicInfoResDTO'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { PropertyResumeCP } from '../property-resume/PropertyResumeCP'

type RouteParamsTP = { batchCode: string }

export function BatchPresentationHeaderCP(): JSX.Element {
    const routeParams = useParams<RouteParamsTP>()
    const [resume, setResume] = useState<IBatchBasicInfoResDTO>()

    const [batchCode, setBatchCode] = useState<number>()
    useEffect(() => setBatchCode(+routeParams.batchCode), [routeParams])

    const getInfoReq = useRequest<IBatchBasicInfoResDTO>()
    useEffect(onRequestChange, [getInfoReq.awaiting])

    useEffect(getDetails, [batchCode])

    function getDetails(): void {
        if (!!batchCode)
            getInfoReq.runRequest(BatchPresentationRequests.getBasicInfo(batchCode))
    }

    function onRequestChange(): void {

        if (getInfoReq.awaiting || !getInfoReq.tried)
            return

        if (!getInfoReq.success) {
            if (getInfoReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getInfoReq.returnData, getInfoReq.error)
            return RequestUtils.showDefaultErrorNotification(getInfoReq.error, 'Some error occurred. Please, try to reload the page.')
        }

        setResume(getInfoReq.returnData)
    }

    if (!resume || getInfoReq.awaiting) {
        return (
            <LoadingBoxCP />
        )
    }

    return (
        <MainWppSCP>
            <WrapperSCP>
                <BatchBasicInfoCP
                    resume={resume}
                />
                <BatchSlideshowCP />
                <LangSwitchCP />
            </WrapperSCP>
            <PropertyResumeCP />
        </MainWppSCP>

    )
}

const WrapperSCP = styled.div`
    display: grid;
   
    grid-template-columns: 50% 50%;
    background-color: ${props => props.theme.primaryColor};
    padding: 1em 4em 0 4em;
    
    @media(max-width: 980px){
        padding: 0 1em;
        grid-template-columns:100%;
    }   
`

const MainWppSCP = styled.div`
   display: flex;
    min-height: 95vh;
    flex-direction: column;
    background-color: ${props => props.theme.primaryColor};
     
`
