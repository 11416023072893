import { CheckCircleFilled, CloudUploadOutlined, FileDoneOutlined, FormOutlined, PlayCircleOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Steps } from 'antd'
import useWindowDimensions from 'common/hooks/useWindowDimensions'
import React, { useState } from 'react'
import { RegistrationAddressDataStep } from './steps/RegistrationAddressDataStep'
import { RegistrationBasicDataStep } from './steps/RegistrationBasicDataStep'
import { RegistrationCompleteStep } from './steps/RegistrationCompleteStep'
import { RegistrationFileDataStep } from './steps/RegistrationFileDataStep'
import { RegistrationInstructionsData } from './steps/RegistrationInstructionsData'
import { RegistrationPrimaryDataStep } from './steps/RegistrationPrimaryDataStep'

const { Step } = Steps

export function RegistrationWizard(): JSX.Element {
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [personData, setPersonData] = useState<any>({})
    const { width } = useWindowDimensions()

    // useEffect(() => {
    //     console.log('PERSON ', personData)
    // }, [personData])

    function onNextContent(values?: any): void {
        setCurrentStep(currentStep + 1)
        setPersonData({
            ...personData,
            ...values
        })
    }

    function onPreviousContent(): void {
        setCurrentStep(currentStep - 1)
    }

    return (
        <>
            <Steps current={currentStep} type={'navigation'} direction={'horizontal'}>
                {width >= 720 ?
                    steps.map((item, index) => (
                        <Step key={item.stepTitle} title={item.stepTitle} icon={currentStep > index ? <CheckCircleFilled /> : item.icon} />
                    ))
                    : <Step
                        key={steps[currentStep].stepTitle}
                        description={steps[currentStep].cardTitle}
                        title={steps[currentStep].stepTitle}
                        icon={steps[currentStep].icon}
                        className={'single-step'}
                    />}
            </Steps>
            <Card
                style={{ width: '100%', marginTop: '20px', boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.30)' }}
            >
                <Card.Meta
                    title={width >= 720 ? steps[currentStep].cardTitle : ''}
                    description={steps[currentStep].content(onPreviousContent, onNextContent, personData)}
                />
            </Card>
        </>
    )
}

const steps = [
    {
        stepTitle: 'Instruções',
        cardTitle: 'Desfrute de todos os benefícios da Flowins!',
        content: (onPrev, onNext, values) => <RegistrationInstructionsData onNext={onNext} />,
        icon: <PlayCircleOutlined />
    },
    {
        stepTitle: 'Dados Iniciais',
        cardTitle: 'Preencha seus dados iniciais',
        content: (onPrev, onNext, values) => <RegistrationPrimaryDataStep onNext={onNext} values={values} />,
        icon: <UserOutlined />
    },
    {
        stepTitle: 'Dados Básicos',
        cardTitle: 'Complete suas informações de contato',
        content: (onPrev, onNext, values) => <RegistrationBasicDataStep onNext={onNext} onPrev={onPrev} values={values} />,
        icon: <SolutionOutlined />
    },
    {
        stepTitle: 'Dados Adicionais',
        cardTitle: 'Acrescente seu endereço para contato',
        content: (onPrev, onNext, values) => <RegistrationAddressDataStep onNext={onNext} onPrev={onPrev} values={values} />,
        icon: <FormOutlined />
    },
    {
        stepTitle: 'Documentos',
        cardTitle: 'Suba suas fotos para validarmos seu cadastro',
        content: (onPrev, onNext, values) => <RegistrationFileDataStep onNext={onNext} onPrev={onPrev} values={values} />,
        icon: <CloudUploadOutlined />
    },
    {
        stepTitle: 'Cadastro Finalizado',
        cardTitle: '',
        content: (onPrev, onNext, values) => <RegistrationCompleteStep values={values} />,
        icon: <FileDoneOutlined />
    }
]