import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { BatchProcessMethodEnum } from 'modules/batch/enums/BatchProcessMethodEnum'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import React from 'react'
import styled from 'styled-components'

interface IBatchProcessMethodSelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    label?: string
    value?: BatchProcessMethodEnum
    required?: boolean
    onSelect?: (value: BatchProcessMethodEnum) => void
}

export function BatchProcessMethodSelectorCP<FModelTP extends FormModelValidator = any>(props: IBatchProcessMethodSelectorCPProps<FModelTP>): JSX.Element {

    function getOptions(): SelectOptionTP[] {
        return Object.values(BatchProcessMethodEnum).map(e => ({ label: BatchUtils.translateProcessMethod(e), value: e }))
    }

    return (
        <SelectCP
            options={getOptions()}
            label={props.label}
            placeholder={'Processamento'}
            formStateManager={props.formStateManager}
            fieldName={'method'}
            required={props.required}
            showSearch={true}
            onChange={props.onSelect}
            value={props.value}
        />
    )
}

const WrapperSCP = styled.div`
`
