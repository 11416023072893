import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PurchaseListCP } from 'modules/maneuver/components/purchase-list/PurchaseListCP'
import { PurchaseDrawerCP } from 'modules/maneuver/components/purchase-drawer/PurchaseDrawerCP'
import React, { useState } from 'react'
import styled from 'styled-components'

interface IPurchaseListDrawerCPProps {
    productCode: number
    productName: string
    onCancel: () => void
    show: boolean

}

export function PurchaseListDrawerCP(props: IPurchaseListDrawerCPProps): JSX.Element {
    const [showPurchase, setShowPurchase] = useState<boolean>(false)

    return (
        <DrawerCP
            title={`Compras de ${props.productName}`}
            visible={props.show}
            width={'70%'}
            onClose={() => {
                props.onCancel()
            }}
            footer={
                <WrapperButtonCP>
                    <ButtonCP
                        type={'primary'}
                        icon={<FontAwsomeIconCP icon={faPlus} />}
                        onClick={() => {
                            setShowPurchase(true)
                        }}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Lançar uma nova compra
                    </ButtonCP>
                </WrapperButtonCP>
            }
        >
            <WrapperSCP>
                <PurchaseListCP
                    productCode={props.productCode}
                />
                <PurchaseDrawerCP
                    productCode={props.productCode}
                    show={showPurchase}
                    onSuccess={() => {
                        setShowPurchase(false)
                    }}
                    onCancel={() => {
                        setShowPurchase(false)
                    }}
                />
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`

`

const WrapperButtonCP = styled.div`
    margin: 20px 0;
    .ant-btn{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`