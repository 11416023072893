import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ISplitDataReqDTO } from 'modules/batch/services/dtos/requests/ISplitDataReqDTO'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import React from 'react'
import styled from 'styled-components'

interface ISplitValueFieldICPProps {
    mainBatchData: IBatchResponseDTO
    splitData: ISplitDataReqDTO
    splitValues: ISplitDataReqDTO[]
    setSplitValues: (value: ISplitDataReqDTO[]) => void
    setAlertText: (text?: string) => void
    onRemoveItem: () => void
    index: number
    loading: boolean
    disableRemove: boolean
    isAfterBenefit: boolean
    disableFields: boolean
}

export function SplitValueFieldICP(props: ISplitValueFieldICPProps): JSX.Element {

    function onChangeLitersOrBags(litersOrBags: number): void {

        const data = props.splitValues.map((sv, i) => {
            if (i === props.index) {
                return {
                    ...sv,
                    litersOrBags: +litersOrBags
                }
            }
            return sv
        })

        const _litersOrBags = data.map(l => +l.litersOrBags)
        const total = +(_litersOrBags.reduce((v1, v2) => v1 + v2))
        const difference = total - (props.isAfterBenefit ? +props.mainBatchData.bags : props.mainBatchData.liters)

        if (difference > 0)
            props.setAlertText(`Atenção! O total ultrapassa em ${difference} ${props.isAfterBenefit ? 'sacas' : 'litros'} o total disponível`)
        else
            props.setAlertText(undefined)

        props.setSplitValues(data)
    }

    function onChangeFarmId(farmId: string): void {

        props.setSplitValues(props.splitValues.map((sv, i) => {
            if (i === props.index) {
                return {
                    ...sv,
                    farmId
                }
            }
            return sv
        }))
    }

    return (
        <WrapperSCP>
            <GridWrapperSCP>
                <HighlightSCP>
                    #
                    {props.index + 1}
                </HighlightSCP>
                <FieldSCP>
                    <InputCP
                        placeholder={'ID na propriedade'}
                        type={'text'}
                        onChange={onChangeFarmId}
                        value={props.splitData.farmId}
                        loading={props.loading}
                        disabled={props.disableFields}
                    />
                    <InputCP
                        placeholder={'Litros'}
                        type={'number'}
                        onChange={onChangeLitersOrBags}
                        value={props.splitData.litersOrBags}
                        loading={props.loading}
                        disabled={props.disableFields}
                        icon={<span>{props.isAfterBenefit ? 'sacas' : 'litros'}</span>}
                    />
                    <ButtonCP
                        type={'ghost'}
                        icon={<FontAwesomeIcon icon={faTimes} />}
                        onClick={props.onRemoveItem}
                        disabled={props.disableRemove}
                    />
                </FieldSCP>

            </GridWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`

const GridWrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    column-gap: 10px;
    align-items: center;
`
const HighlightSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    font-size:  15px;
    @media(max-width: 768px){
        height: 40px;
        min-width: 40px;
    }
`

const FieldSCP = styled.div`
    display: flex;
    max-width: 400px;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    .ant-btn{
        margin-left: 10px
    }
`