import { UserPermissionsEnum } from 'common/enums/UserPermissionsEnum'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'
import React from 'react'
import { Redirect } from 'react-router-dom'

interface IAccessControlCPProps {
    children: React.ReactNode
    module?: SystemModulesEnum
    showInvalidAccessNotification?: boolean
    redirect?: boolean
}

/**
 * COMPONENTE
 * Wrapper que abstrai tratamento generico para restricao de acesso a
 * recursos do sistema dependendo da compatibilidade entre a combinacao
 * de restricoes definidas para o recurso, os modulos habilitados para o
 * schema e as permissoes do usuario atual logado.
 *
 * @author hjcostabr
 */
export function AccessControlCP(props: IAccessControlCPProps): JSX.Element | null {

    if (PermissionUtils.hasAccess(props.module))
        return <>{props.children}</>

    if (props.redirect === false)
        return null

    if (props.showInvalidAccessNotification !== false)
        PermissionUtils.showDefaultDeniedAccessMsg()

    return <Redirect to={'/propriedades'} />
}
