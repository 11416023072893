export enum CoffeeFlavorEnum {
    BROWN_SUGAR = 'BROWN_SUGAR',
    MOLASSES = 'MOLASSES',
    ALMOND = 'ALMOND',
    HAZELNUT = 'HAZELNUT',
    VANILLA = 'VANILLA',
    HARD_MOLASSES = 'HARD_MOLASSES',
    HONEY = 'HONEY',
    CARAMEL = 'CARAMEL',
    MILK_CHOCOLATE = 'MILK_CHOCOLATE',
    BITTER_CHOCOLATE = 'BITTER_CHOCOLATE',
    NUT = 'NUT',
    COCOA = 'COCOA',
    PEANUT = 'PEANUT',
    SPICES = 'SPICES',
    BLACK_TEA = 'BLACK_TEA',
    FLORAL = 'FLORAL',
    RED_FRUIT = 'RED_FRUIT',
    DRY_FRUITS = 'DRY_FRUIT',
    YELLOW_FRUIT = 'YELLOW_FRUIT',
    CITRIC_FRUIT = 'CITRIC_FRUIT',
    PURPLE_FRUITS = 'PURPLE_FRUITS',
    WHITE_FRUITS = 'WHITE_FRUITS',
    WINEY = 'WINEY',
    WHISKEY = 'WHISKEY',
    BARLEY = 'BARLEY',
    MALT = 'MALT',
    FERMENTED = 'FERMENTED',
    OLIVE_OIL = 'OLIVE_OIL',
    HERBAL = 'HERBAL',
    MINT = 'MINT',
    PEPPER_MINT = 'PEPPER_MINT',
    RAW = 'RAW',
    VEGETABLE = 'VEGETABLE',
    PAPER = 'PAPER',
    MUSTY = 'MUSTY',
    CHEMICAL = 'CHEMICAL',
    BURNED = 'BURNED',
    CEREAL = 'CEREAL',
    ROLL_SMOKE = 'ROLL_SMOKE',
    TOBACCO = 'TOBACCO',
    RUBBER = 'RUBBER',
    ACOHOLIC = 'ACOHOLIC',


    // NAO DEVEM APARECER NO CUPPING, FORAM DEPRECADAS
    /**
     * @deprecated
     */
    BLACK_FRUITS = 'BLACK_FRUITS',

    /**
     * @deprecated
     */
    BRAZILIAN_NUTS = 'BRAZILIAN_NUTS',

    /**
     * @deprecated
     */
    CHOCOLATE = 'CHOCOLATE',

    /**
     * @deprecated
     */
    DARK_CHOCOLATE = 'DARK_CHOCOLATE',

    /**
     * @deprecated
     */
    FRUIT_SYRUP = 'FRUIT_SYRUP',

    /**
     * @deprecated
     */
    GUARANA = 'GUARANA',

    /**
     * @deprecated
     */
    HERBACEOUS = 'HERBACEOUS',

    /**
     * @deprecated
     */
    JELLY = 'JELLY',

    /**
     * @deprecated
     */
    OLIVE = 'OLIVE',

    /**
     * @deprecated
     */
    PEANUT_CANDY = 'PEANUT_CANDY',

    /**
     * @deprecated
     */
    SUGAR_CANE = 'SUGAR_CANE',

    /**
     * @deprecated
     */
    SWEET_CORN = 'SWEET_CORN',

    /**
     * @deprecated
     */
    SWEET_MILK = 'SWEET_MILK',

    /**
     * @deprecated
     */
    TEA = 'TEA',

    /**
     * @deprecated
     */
    CACAO = 'CACAO',
}
