import { Row } from 'antd'
import { TextCP } from 'common/components/text/TextCP'
import { HarvestPlanningFilterFieldsICP } from 'modules/harvest-planning/components/harvest-planning-filters/inner/HarvestPlanningFilterFieldsICP'
import { ISearchHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISearchHarvestPlanningReqDTO'
import React from 'react'
import styled from 'styled-components'

interface IHarvestPlanningFiltersCPProps {
    setFilters: (filters: ISearchHarvestPlanningReqDTO) => void
    filters?: ISearchHarvestPlanningReqDTO
}

export function HarvestPlanningFiltersCP(props: IHarvestPlanningFiltersCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <Row>
                <TextCP text={'Filtre por:'} />
            </Row>
            <HarvestPlanningFilterFieldsICP
                filters={props.filters}
                setFilters={props.setFilters}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
    .ant-typography{
        font-size: 12px;   
    }

`