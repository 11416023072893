import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'
import { FinancialMainScreen } from 'modules/financial/screens/FinancialMainScreen'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

FinancialRoutes.ROOT = '/financeiro'

export function FinancialRoutes(): JSX.Element {
    return (
        <AccessControlCP module={SystemModulesEnum.FINANCIAL}>
            <Switch>
                <Route path={FinancialRoutes.ROOT} component={FinancialMainScreen} />
            </Switch>
        </AccessControlCP>
    )
}
