/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { TextCP } from 'common/components/text/TextCP'
import { MaturationEnum } from 'modules/harvest/enums/MaturationEnum'
import { IHarvestLitersPerMaturation } from 'modules/harvest/interfaces/IHarvestLitersPerMaturation'
import { HarvestUtils } from 'modules/harvest/utils/HarvestUtils'
import { ColorUtils } from 'common/utils/ColorUtils'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { MaskUtils } from 'common/utils/MaskUtils'

interface ILitersPerMaturationCPProps {
    litersPerMaturation: IHarvestLitersPerMaturation
}
export function LitersPerMaturationCP(props: ILitersPerMaturationCPProps): JSX.Element {

    return (
        <WrapperSCP>
            {
                Object.values(MaturationEnum).map((mat: MaturationEnum, index: number) => (
                    <MaturationWrpSCP key={`${mat}`}>
                        <CircleIndicatorSCP
                            style={
                                {
                                    borderRadius: `${index === 0 ? '3px 0 0 3px' : index === 4 ? '0 3px 3px 0' : ''}`,
                                    backgroundColor: `${HarvestUtils.getMaturationColor(mat)}`,
                                }
                            }
                        >
                            <TextCP
                                text={`${MaskUtils.applyNumberMask(props.litersPerMaturation[HarvestUtils.convertMaturationToCamelCase(mat)] ?? 0)} L`}
                            />
                        </CircleIndicatorSCP>
                    </MaturationWrpSCP>
                ))
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    margin: 10px 0;
    display: flex;
`

const MaturationWrpSCP = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; 
`

const CircleIndicatorSCP = styled.div<{ style: CSSProperties }>`   
    height: 40px;    
    padding: 5px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .ant-typography{
        color:${props => ColorUtils.darkenColor(props.style.backgroundColor ?? 'white', 60)};
        font-size: 11px!important;
        font-weight: 600;
        .wrpClass{
            overflow-wrap: anywhere;
        }       
    }
    @media(max-width: 767px){     
        
        .ant-typography{    
             font-size: 11px;
        }
    }
`