import { CardCP } from 'common/components/card/CardCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListCP } from 'common/components/list/ListCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { CompanyCardICP } from 'modules/company/components/company-list/inner/CompanyCardICP'
import { CompanyRequests } from 'modules/company/services/CompanyRequests'
import { ICompanyResDTO } from 'modules/company/services/dtos/response/ICompanyResDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface ICompanyListCPProps {
}

export function CompanyListCP(props: ICompanyListCPProps): JSX.Element {
    const request = useRequest<IGenericListResponseDTO<ICompanyResDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    const [companyList, setCompanyList] = useState<ICompanyResDTO[]>([])
    useEffect(searchCompanies, [])

    function searchCompanies(): void {
        request.runRequest(CompanyRequests.search())
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried) {
            ListActions.isUpdating()
            return
        }

        if (!request.success || !request.returnData)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro obter lista de planejamentos!')

        setCompanyList(request.returnData.list)
        ListActions.updateIsDone()
    }

    return (
        <WrapperSCP>
            <ListCP<ICompanyResDTO>
                grid={{ gutter: 16, column: 3, md: 1, sm: 1, xs: 1, lg: 2, xl: 3, xxl: 3 }}
                loading={request.awaiting}
                bordered={false}
                itemLayout={'vertical'}
                size={'large'}
                dataSource={companyList}
                hasStateControl={true}
                locale={{
                    emptyText: <CardCP noSpacing={true} noSideSpacing={true}>
                        {' '}
                        <EmptyCP description={'Nenhuma empresa cadastrada'} />
                        {' '}
                    </CardCP>
                }}
                renderItem={(item: ICompanyResDTO) => (
                    <CompanyCardICP company={item} loading={request.awaiting} />
                )}

            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
   border-radius: 10px;
    margin-bottom: 4em;
    max-height: 75vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    } 
`
