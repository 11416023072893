import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ISearchRecipeRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchRecipeRequestDTO'
import { RecipeFilterFieldsICP } from 'modules/maneuver/components/recipe-list-filters/inner/RecipeFilterFieldsICP'

interface IRecipeMobileFiltersICPProps {
    setFilters: (filters?: ISearchRecipeRequestDTO) => void
    filters?: ISearchRecipeRequestDTO
}

export function RecipeMobileFiltersICP(props: IRecipeMobileFiltersICPProps): JSX.Element {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)
    const [filters, setFilters] = useState<ISearchRecipeRequestDTO | undefined>()

    useEffect(init, [props.filters])

    function init(): void {
        if (!!props.filters)
            setFilters(props.filters)
    }

    function resetFilters(): void {
        const defaultFilters = {
            code: undefined,
            title: undefined,
            propertyCode: undefined,
            fieldCode: undefined,
        }

        props.setFilters(defaultFilters)
        setOpenDrawer(false)
    }

    function onFilter(): void {
        props.setFilters(filters)
        setOpenDrawer(false)
    }

    return (
        <WrapperSCP>
            <ButtonCP
                onClick={() => setOpenDrawer(true)}
                type={'primary'}
                style={{ position: 'absolute', bottom: 20, right: 20, height: 60, width: 60, zIndex: 1, borderRadius: '100%' }}
                icon={<FontAwsomeIconCP icon={faFilter} color={'#fff'} />}
            />
            <DrawerCP
                title={'Todos os filtros'}
                visible={openDrawer}
                height={'98%'}
                placement={'bottom'}
                onClose={() => {
                    setOpenDrawer(false)
                }}
                footer={
                    <ButtonsWrapperSCP>
                        <ButtonCP
                            type={'ghost'}
                            onClick={resetFilters}
                        >
                            Limpar
                        </ButtonCP>
                        <ButtonCP
                            type={'primary'}
                            onClick={onFilter}
                        >
                            Filtrar
                        </ButtonCP>
                    </ButtonsWrapperSCP>
                }
            >
                <FieldsWrapperSCP>
                    <RecipeFilterFieldsICP
                        onChangeCode={(code) => setFilters({ ...props.filters, code: +code ? code : undefined })}
                        onChangeName={(title) => setFilters({ ...props.filters, title: title ? title : undefined })}
                        onChangeType={(type) => setFilters({ ...props.filters, type })}
                        onChangeArchived={(archived) => props.setFilters({ ...props.filters, archived })}
                        filters={filters}
                    />

                </FieldsWrapperSCP>
            </DrawerCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 3px;
`

const FieldsWrapperSCP = styled.div`


`
const ButtonsWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;

`

const GridWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: .8fr 1.5fr 1.8fr 3fr;
    column-gap: 10px;
    margin-top: 10px;
`