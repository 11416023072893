import { faCampground, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { StructureDrawerCP } from 'modules/structure/components/structure-drawer/StructureDrawerCP'
import { StructureListCP } from 'modules/structure/components/structure-list/StructureListCP'
import React, { useState } from 'react'


export function StructureScreen(): JSX.Element {
    const [showDrawer, setShowDrawer] = useState<boolean>(false)


    return (
        <LayoutCP
            header={
                <HeaderCP title={'Estruturas'} icon={<FontAwesomeIcon icon={faCampground} />}>
                    <ButtonCP
                        style={{ border: 'none' }}
                        size={'middle'}
                        type={'ghost'}
                        icon={<FontAwsomeIconCP icon={faPlus} />}
                        onClick={() => {
                            setShowDrawer(true)
                        }}
                    >
                        Nova estrutura
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <StructureListCP />
                    <StructureDrawerCP
                        show={showDrawer}
                        onSuccess={() => {
                            setShowDrawer(false)
                        }}
                        onCancel={() => {
                            setShowDrawer(false)

                        }}
                    />
                </ContentCP>
            }
        />
    )
}
