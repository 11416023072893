import { faMedal, faChartLine, faSeedling, faCrown, faCube, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ReportRoutes } from 'modules/report/ReportRoutes'
import React from 'react'
import styled from 'styled-components'

const IconSCP = styled.span`
    svg {
        color: ${props => props.theme.primaryColor};
        fill: ${props => props.theme.primaryColor};
        font-size: 1.75em;
    }
`
export const ReportMenuUtils = {
    getMainMenuOptions() {
        return [
            {
                key: 'production',
                route: ReportRoutes.PRODUCTION,
                title: 'Produção',
                icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwsomeIconCP icon={faChartLine} /></IconSCP>
            },
            {
                key: 'financeiro',
                route: ReportRoutes.FINANCIAL,
                title: 'Financeiro',
                icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwsomeIconCP icon={faHandHoldingUsd} /></IconSCP>
            },
            {
                key: 'qualidade',
                route: ReportRoutes.QUALITY,
                title: 'Qualidade',
                icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwsomeIconCP icon={faMedal} /></IconSCP>
            },
            {
                key: 'best-batches',
                title: 'Melhores Lotes',
                disabled: true,
                route: ReportRoutes.BEST_BATCHES,
                icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwsomeIconCP icon={faCrown} /></IconSCP>
            },
            {
                key: 'field-report',
                title: 'Relatório do talhão',
                disabled: true,
                route: ReportRoutes.FIELD_WITHOUT_PARAM,
                icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwsomeIconCP icon={faSeedling} /></IconSCP>
            },
            // {
            //     key: 'batch-report',
            //     title: 'Relatório do Lote',
            //     disabled: true,
            //     route: ReportRoutes.BATCH_WITHOUT_PARAM,
            //     icon: <IconSCP className={'anticon'} style={{ marginRight: 10 }}><FontAwsomeIconCP icon={faCube} /></IconSCP>
            // },
        ]
    },

}
