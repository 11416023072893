import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { faSpinner, faPlus, faStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvatarCP } from 'common/components/avatar/AvatarCP'

interface IUploadButtonICPProps {
    loading: boolean
    disabled?: boolean
    style?: CSSProperties
    isAvatar?: boolean
    size?: number
}

export function UploadButtonICP(props: IUploadButtonICPProps): JSX.Element {

    return (

        props.isAvatar ?
            <AvatarUploadWrapperSCP>
                <AvatarCP
                    size={props.size ?? 150}
                />
            </AvatarUploadWrapperSCP>
            :
            <UploadButtonSCP disabled={props.disabled!} style={props.style}>
                {!!props.disabled ?

                    <FontAwesomeIcon icon={faStop} />

                    : <ContentWrapperSCP style={props.style}>
                        {props.loading ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : <FontAwesomeIcon icon={faPlus} />}
                        <span>Selecione a imagem</span>
                      </ContentWrapperSCP>}
            </UploadButtonSCP>

    )
}
const UploadButtonSCP = styled.div<{ disabled: boolean }>`
   cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
   background: #f4f4f4;
 
   border-radius: 10px;
   display: flex;
   align-items: center;
   justify-content:center;
   flex-direction: column;
   border: 1px #cacaca dashed;
   span{
       font-size: 14px;
       color: #1c2247;
       font-weight: 600;
   }
   .anticon{
    font-size: 35px;
    margin: 10px 0;
   }
`
const AvatarUploadWrapperSCP = styled.div`
    cursor: ponter;
`
const ContentWrapperSCP = styled.div`
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`