import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRequest } from 'common/request-helper/UseRequest'
import { IPropertyResponseDTO } from 'modules/property/services/dtos/responses/IPropertyResponseDTO'
import { PropertyRequests } from 'modules/property/services/PropertyRequests'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { Skeleton } from 'antd'
import { DescriptionSectionCP } from 'common/components/description-section/DescriptionSectionCP'
import { IconInfoGridCP } from 'common/components/icon-info-grid/IconInfoGridCP'
import { PropertyUtils } from 'modules/property/utils/PropertyUtils'

import { TranslationRequests } from 'common/TranslationRequest'
import { ITranslateResDTO } from 'common/dtos/responses/IITranslateResDTO'

interface IPropertyDetailsCPProps {
    propertyCode: number
    setProducerCode: (code: number) => void
}

export function PropertyDetailsCP(props: IPropertyDetailsCPProps): JSX.Element {
    const request = useRequest<IPropertyResponseDTO>()
    const translationRequest = useRequest<ITranslateResDTO>()
    const [propertyData, setPropertyData] = useState<IPropertyResponseDTO>()

    useEffect(getPropertyInfo, [props.propertyCode])
    useEffect(getTranslation, [request.returnData])
    useEffect(onRequestChange, [request.awaiting])

    function getPropertyInfo(): void {
        if (!props.propertyCode)
            return
        request.runRequest(PropertyRequests.get(props.propertyCode))
    }

    function getTranslation(): void {
        if (!request.returnData)
            return
        translationRequest.runRequest(TranslationRequests.translate({ text: request.returnData.description }))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar propriedade!')
        }
        setPropertyData(request.returnData)
        props.setProducerCode(request.returnData.producerCode)
    }

    if (!propertyData) {
        return (
            <PropertyDetailsWrapperSCP>
                <Skeleton paragraph={{ rows: 8, style: { opacity: 0.3 }}} title={false} active={true} />
            </PropertyDetailsWrapperSCP>
        )
    }

    return (
        <PropertyDetailsWrapperSCP>

            <DescriptionSectionCP
                title={propertyData.name}
                mainColor={'#fff'}
                upperTitle={'Property'}
                subTitle={`${propertyData.city} | ${propertyData.region}`}
                description={propertyData.descriptionEng ?? (translationRequest.returnData ? translationRequest.returnData.text : '')}
                showReadMore={true}
                idealDescriptionLength={800}
                maxDescriptionLength={1000}
                minDescriptionLength={500}
                extra={
                    <IconInfoGridCP<IPropertyResponseDTO>
                        gridColumns={2}
                        mainColor={'#fff'}
                        sourceData={propertyData}
                        columns={PropertyUtils.getIconGrigColumnsConfig()}
                    />
                }
            />

        </PropertyDetailsWrapperSCP>
    )
}
const PropertyDetailsWrapperSCP = styled.div`
    margin: 30px 0;
`
