/* eslint-disable @typescript-eslint/no-floating-promises */
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'

import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { BatchCardICP } from 'modules/batch/components/batch-list/inner/BatchCardICP'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { ISearchBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISearchBatchRequestDTO'
import { ISearchBatchResponseDTO } from 'modules/batch/services/dtos/responses/ISearchBatchResponseDTO'
import React, { useState, useEffect } from 'react'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { useSelector } from 'react-redux'
import { BatchListFiltersCP } from 'modules/batch/components/batch-list-filters/BatchListFiltersCP'
import { OrderingCriteriaEnum } from 'common/enums/OrderingCriteriaEnum'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { ISearchHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISearchHarvestPlanningReqDTO'
import { ListAndFiltersCP } from 'common/components/list/ListAndFiltersCP'
import { IGenericSearchRequestDTO } from 'common/dtos/requests/IGenericSearchRequestDTO'
import moment from 'moment-timezone'

const DEFAULT_PAGINATION: IGenericSearchRequestDTO = {
    page: 1,
    itemsPerPage: 12
}

const DEFAULT_FILTERS: ISearchBatchRequestDTO = {
    orderingCriteria: OrderingCriteriaEnum.DATE,
    orderingAscending: false,
    season: moment().year(),
    itemsPerPage: DEFAULT_PAGINATION.itemsPerPage,
    page: DEFAULT_PAGINATION.page
}

export function BatchListCP(): JSX.Element {
    const screenSize = useScreenSize()
    const selected: number[] = useSelector((state: IReduxState) => _.get(state, 'listState.selectedItems'))
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const currentFilters: ISearchBatchRequestDTO | null = useSelector((state: IReduxState) => _.get(state, 'searchState.filter'))
    const persistedList: ISearchBatchResponseDTO[] | null = useSelector((state: IReduxState) => _.get(state, 'searchState.loadedItems'))
    const type: FilterTypesEnum = useSelector((state: IReduxState) => _.get(state, 'searchState.type'))

    const [total, setTotal] = useState<number>(0)
    const [batchList, setBatchList] = useState<ISearchBatchResponseDTO[]>([])

    const searchBatchRequest = useRequest<IGenericListResponseDTO<ISearchBatchResponseDTO>>()
    useEffect(onRequestChange, [searchBatchRequest.awaiting])

    useEffect(getBatchList, [currentFilters])
    useEffect(onItemChange, [updateStatus])
    useEffect(loadPersistedData, [])

    function loadPersistedData(): void {
        if (type !== FilterTypesEnum.BATCH)
            return SearchActions.setFilter<ISearchBatchRequestDTO>(DEFAULT_FILTERS, FilterTypesEnum.BATCH)

        if (!!persistedList && !!persistedList.length) {
            setBatchList(persistedList)
            return
        }

        if (!currentFilters) {
            SearchActions.setFilter<ISearchBatchRequestDTO>({
                ...DEFAULT_FILTERS,
            }, FilterTypesEnum.BATCH)
        } else {
            SearchActions.setFilter<ISearchBatchRequestDTO>({
                ...currentFilters,
                page: DEFAULT_PAGINATION.page
            }, FilterTypesEnum.BATCH)
        }
    }

    function getBatchList(): void {

        if (!!currentFilters && type === FilterTypesEnum.BATCH)
            searchBatchRequest.runRequest(BatchRequests.search(currentFilters))

    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE) {
            SearchActions.saveLoaded<ISearchBatchResponseDTO>([])
            setBatchList([])
            SearchActions.setFilter<ISearchBatchRequestDTO>({
                ...currentFilters,
                page: DEFAULT_PAGINATION.page
            }, FilterTypesEnum.BATCH)
        }
    }

    function onChangeFilter(filter: ISearchHarvestPlanningReqDTO): void {
        SearchActions.saveLoaded<ISearchBatchResponseDTO>([])
        setBatchList([])
        SearchActions.setFilter(filter, FilterTypesEnum.BATCH)
    }

    function onLoadMore(): void {
        SearchActions.saveLastPage<ISearchBatchResponseDTO>(!!currentFilters && !!currentFilters.page ? +currentFilters.page + 1 : DEFAULT_PAGINATION.page!)
        SearchActions.setFilter({
            ...currentFilters,
            itemsPerPage: DEFAULT_PAGINATION.itemsPerPage!,
            page: !!currentFilters && !!currentFilters.page ? currentFilters.page + 1 : DEFAULT_PAGINATION.page,
        }, FilterTypesEnum.BATCH)
    }

    function onRequestChange(): void {
        if (searchBatchRequest.awaiting || !searchBatchRequest.tried) {
            ListActions.isUpdating()
            return
        }

        if (!searchBatchRequest.success || !searchBatchRequest.returnData)
            return RequestUtils.showDefaultErrorNotification(searchBatchRequest.error, 'Erro obter lista de lotes!')

        setTotal(searchBatchRequest.returnData.total)
        const list = _.uniqBy([...batchList, ...searchBatchRequest.returnData.list], 'code')
        const order = currentFilters?.orderingAscending ? 'asc' : 'desc'

        orderList(list, order)

        ListActions.updateIsDone()
    }

    function orderList(list: ISearchBatchResponseDTO[], order: boolean | 'asc' | 'desc'): void {
        switch (currentFilters?.orderingCriteria) {
            case OrderingCriteriaEnum.DATE:
                SearchActions.saveLoaded<ISearchBatchResponseDTO>(_.orderBy(list, ['date'], [order]))
                return setBatchList(_.orderBy(list, ['date'], [order]))
            case OrderingCriteriaEnum.CODE:
                SearchActions.saveLoaded<ISearchBatchResponseDTO>(_.orderBy(list, ['code'], [order]))
                return setBatchList(_.orderBy(list, ['code'], [order]))
            default:
                SearchActions.saveLoaded<ISearchBatchResponseDTO>(_.orderBy(list, ['date'], [order]))
                return setBatchList(_.orderBy(list, ['date'], [order]))
        }
    }

    return (
        <ListAndFiltersCP<ISearchBatchResponseDTO>
            filtersCp={
                <BatchListFiltersCP
                    filters={currentFilters ?? DEFAULT_FILTERS}
                    setFilters={onChangeFilter}
                />
            }
            listProps={{
                grid: { gutter: 16, column: 3, md: 1, sm: 1, xs: 1, lg: 2, xl: 3, xxl: 4 },
                loading: searchBatchRequest.awaiting,
                style: { padding: '0', overflow: 'hidden', marginBottom: '5em' },
                bordered: false,
                itemLayout: 'vertical',
                size: 'large',
                dataSource: batchList,
                hasStateControl: true,
                renderItem: (item: ISearchBatchResponseDTO) => (
                    <BatchCardICP
                        item={item}
                        loading={searchBatchRequest.awaiting}
                    />
                )
            }}
            loadMoreData={onLoadMore}
            clearFilters={() => {
                SearchActions.setFilter<ISearchBatchRequestDTO>(DEFAULT_FILTERS, FilterTypesEnum.BATCH)
                setBatchList([])
            }}
            total={total}
            totalSelected={!!selected && selected.length ? selected.length : 0}

        />

    )
}