/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { IsNumberString, ValidateIf } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsString } from 'common/validation/decorators/IsString'
import { CoffeeTypeEnum, CaneforaVarietyEnum, ArabicaVarietyEnum } from 'modules/field/enums/CoffeeTypeEnum'
import { IsNotEmpty } from '../../../../../common/validation/decorators/IsNotEmpty'

export class FieldFormValidator extends FormModelValidator {

    @IsNotEmpty()
    @IsString()
    name: string

    @IsNotEmpty()
    @IsNumberString()
    plantingYear: string

    @IsNotEmpty()
    coffeeType: CoffeeTypeEnum

    @IsNotEmpty()
    varieties: number[]

    @IsNotEmpty()
    @IsNumberString()
    minAltitude?: string

    @IsNotEmpty()
    @IsNumberString()
    maxAltitude?: string

    @IsNotEmpty()
    @IsNumberString()
    totalArea?: string

    @IsNotEmpty()
    @IsNumberString()
    lineSpacing?: string

    @IsNotEmpty()
    @IsNumberString()
    streetSpacing?: string

    constructor(dto?: any) {
        super()
        Object.assign(this, dto)
        if (dto) {
            this.totalArea = `${dto.totalArea}`
            this.minAltitude = `${dto.minAltitude}`
            this.maxAltitude = `${dto.maxAltitude}`
            this.streetSpacing = `${dto.streetSpacing}`
            this.lineSpacing = `${dto.lineSpacing}`
        }
    }
}