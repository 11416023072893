import { InputCP } from 'common/components/form-fields/input/InputCP'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { MaturationEnum } from 'modules/harvest/enums/MaturationEnum'
import { HarvestUtils } from 'modules/harvest/utils/HarvestUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AlertCP } from 'common/components/alert/AlertCP'
import _ from 'lodash'
import { IHarvestLitersPerMaturation } from 'modules/harvest/interfaces/IHarvestLitersPerMaturation'
interface IMaturationValues {
    maturation: MaturationEnum
    liters?: number
    percentage?: number
}
interface ILitersPerMaturationFieldsICPProps {
    totalLiters: number
    onChange: (matValues?: IHarvestLitersPerMaturation) => void
    currentValues?: IHarvestLitersPerMaturation
}

export function LitersPerMaturationFieldsICP(props: ILitersPerMaturationFieldsICPProps): JSX.Element {
    const screenSize = useScreenSize()

    const [totalByMaturation, setTotalByMaturation] = useState<number>(0)
    const [alertText, setAlertText] = useState<string>()
    const [maturationValues, setMaturationValues] = useState<IMaturationValues[]>(Object.values(MaturationEnum).map(maturation => ({ maturation })))

    useEffect(updateValues, [maturationValues])
    useEffect(checkTotalByMaturationSum, [totalByMaturation])

    useEffect(init, [])

    function init(): void {

        if (!!props.currentValues) {
            setMaturationValues(Object.entries(props.currentValues).map(value => ({
                maturation: HarvestUtils.convertCamelCaseMaturationToENum(value[0]),
                liters: _.isNumber(value[1]) ? value[1] : 0
            })))
        }
    }

    function checkTotalByMaturationSum(): void {
        if (props.totalLiters > totalByMaturation)
            return setAlertText(`Faltam ${props.totalLiters - totalByMaturation} litros para completar o total informado. `)
        if (props.totalLiters < totalByMaturation)
            return setAlertText(`Atenção! A litragem por maturação ultrapassa em ${totalByMaturation - props.totalLiters} litros o total informado. `)
        setAlertText(undefined)
    }

    function updateValues(): void {

        const liters = maturationValues.map(_mat => (_mat.liters ? +_mat.liters : 0))
        const total = liters.reduce((val1: number, val2: number) => val1 + val2)

        setTotalByMaturation(total)

        const matValues = Object.assign({}, ...maturationValues.map(item => ({
            [HarvestUtils.convertMaturationToCamelCase(item.maturation)]: item.liters ? +item.liters : undefined
        })))

        props.onChange(matValues)
    }

    function onChangeLiters(lit: number, mat: MaturationEnum): void {

        setMaturationValues(maturationValues.map((item, i) => ({
            maturation: item.maturation,
            liters: item.maturation === mat ? lit : item.liters,
            //   percentage: !!item.liters ? item.liters * (props.totalLiters * 0.01) : undefined
        })))
    }

    // function onChangePercentage(pct: number, mat: MaturationEnum): void {

    //     setMaturationValues(maturationValues.map((item, i) => ({
    //         maturation: item.maturation,
    //         liters: !!item.percentage ? (props.totalLiters * 0.01) * item.percentage : undefined,
    //         percentage: item.maturation === mat ? pct : item.percentage
    //     })))

    // }

    return (
        <WrapperSCP>
            {
                alertText &&
                <AlertCP
                    type={'warning'}
                    message={alertText}
                />
            }
            {
                Object.values(MaturationEnum).map((mat: MaturationEnum) => (
                    <GridRowSCP key={`${mat}`}>
                        <MaturationWrpSCP>
                            <CircleIndicatorSCP style={{ backgroundColor: `${HarvestUtils.getMaturationColor(mat)}` }} />
                            <TextCP text={`${HarvestUtils.getMaturationLabel(mat)}`} />
                        </MaturationWrpSCP>

                        <InputCP
                            type={'number'}
                            required={true}
                            fontSize={screenSize.smd ? 'large' : 'normal'}
                            icon={<span> litros </span>}
                            onChange={(value) => onChangeLiters(value, mat)}
                            value={maturationValues.find(item => item.maturation === mat)?.liters}
                            loading={false}
                        />

                        {/* <InputCP
                            type={'number'}
                            required={true}
                            fontSize={screenSize.smd ? 'large' : 'normal'}
                            onChange={(value) => onChangePercentage(value, mat)}
                            value={maturationValues.find(item => item.maturation === mat)!.percentage}
                            icon={<span> % </span>}
                            loading={false}
                        /> */}
                    </GridRowSCP>
                ))

            }

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    margin: 10px 0;
   `

const GridRowSCP = styled.div`
    margin: 10px 0;
    display: grid;
    grid-template-columns: 3fr 2fr;
    column-gap: 10px;
    @media(max-width:768px ){
        grid-template-columns: 2fr 1fr;
    }
`

const MaturationWrpSCP = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CircleIndicatorSCP = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 10px;
`