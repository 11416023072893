import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { HarvestTypeEnum } from 'modules/harvest/enums/HarvestTypeEnum'
import { HarvestUtils } from 'modules/harvest/utils/HarvestUtils'
import React from 'react'
import styled from 'styled-components'

interface IHarvestTypeSelectorCPProps<FModelTP extends FormModelValidator = any> {
    label?: string
    value?: HarvestTypeEnum
    onSelect?: (value: HarvestTypeEnum) => void
    formStateManager?: IFormStateManager<FModelTP>
    fieldName?: string
}

export function HarvestTypeSelectorCP<FModelTP extends FormModelValidator = any>(props: IHarvestTypeSelectorCPProps<FModelTP>): JSX.Element {

    function getOptions(): SelectOptionTP[] {
        return Object.values(HarvestTypeEnum).map(e => ({ label: HarvestUtils.translateType(e), value: e }))
    }

    return (
        <SelectCP
            options={getOptions()}
            label={props.label}
            placeholder={'Tipo'}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName ?? 'type'}
            required={true}
            onChange={props.onSelect}
            value={props.value}
        />
    )
}

const WrapperSCP = styled.div`
`
