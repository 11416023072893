import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Menu } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { DropdownCP } from '../dropdown/DropdownCP'
import { FontAwsomeIconCP } from '../icon/FontAwsomeIconCP'

export type DropdownButtonOptionTP = {
    action: () => void,
    label: string,
    icon?: JSX.Element,
    key: string,
}

interface IDropdownButtonCPProps {
    options: DropdownButtonOptionTP[]
    icon?: JSX.Element
    type?: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text'
}

export function DropdownButtonCP(props: IDropdownButtonCPProps): JSX.Element {

    function renderOptions(): JSX.Element {
        const options: DropdownButtonOptionTP[] = props.options.length > 1 ? props.options.slice(1, props.options.length) : props.options
        return (
            <Menu>
                {
                    options.map((op: DropdownButtonOptionTP) => (

                        <Menu.Item key={op.key} onClick={op.action}>
                            <span style={{ display: 'flex' }}> {op.label}</span>
                        </Menu.Item>

                    ))
                }
            </Menu>
        )
    }

    return (

        <DropdownCP
            overlay={renderOptions()}
            icon={<FontAwsomeIconCP icon={faChevronDown} />}
            width={'100%'}
            type={props.type ?? 'primary'}
        >
            <ActionSCP onClick={(e) => props.options[0].action()}>
                {props.options[0].label}
            </ActionSCP>
        </DropdownCP>
    )
}

const ActionSCP = styled.div`
    display: flex;    
`

