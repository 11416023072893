import styled from 'styled-components'
import React from 'react'
import { InputCP } from 'common/components/form-fields/input/InputCP'

import { ISearchRecipeRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchRecipeRequestDTO'
import { RecipeTypeSelectorCP } from 'modules/maneuver/components/recipe-type-selector/RecipeTypeSelectorCP'
import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import { SwitchCP } from 'common/components/switch/SwitchCP'
import { TextCP } from 'common/components/text/TextCP'

interface IRecipeFilterFieldsICPProps {
    onChangeCode: (code: number) => void
    onChangeName: (name: string) => void
    onChangeArchived: (archived?: boolean) => void
    onChangeType: (type: RecipeTypeEnum) => void
    filters?: ISearchRecipeRequestDTO
}

export function RecipeFilterFieldsICP(props: IRecipeFilterFieldsICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <GridWrapperSCP>

                <InputCP
                    placeholder={'Cód.'}
                    label={'Cód.'}
                    onChange={props.onChangeCode}
                    value={props.filters?.code}
                />
                <InputCP
                    placeholder={'Título'}
                    label={'Título'}
                    onChange={props.onChangeName}
                    value={props.filters?.title}
                />
                <RecipeTypeSelectorCP
                    value={props.filters?.type}
                    label={'Tipo'}
                    onSelect={props.onChangeType}
                />


                <SwitchWrapperSCP>
                    <TextCP text={'Mostrar arquivadas '} />
                    <SwitchCP
                        isChecked={!!props.filters?.archived}
                        isTextInside={false}
                        onChangeAction={props.onChangeArchived}
                    />
                </SwitchWrapperSCP>
            </GridWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 3px;
`

const GridWrapperSCP = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr 3fr 2fr;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;

    label, .ant-typography div{
      display: none;
    }

    @media(max-width: 767px){
        grid-template-columns: 1fr;
         label, .ant-typography div{
                   display: block;
         }
    }
`

const SwitchWrapperSCP = styled.div`
    display: flex;   
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    column-gap: 5px;
    .ant-typography div{
      display: flex!important;
      color: ${props => props.theme.gray};
    }
    
`