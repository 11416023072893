import { TagColorTP } from 'common/components/tag/TagCP'
import { BatchProcessEventEnum } from '../enums/BatchProcessEventEnum'
import { BatchStepEnum } from '../enums/BatchStepEnum'
import { BatchMethodEnum } from 'modules/batch/enums/BatchProcessEnum'
import { BatchOriginEnum } from 'modules/batch/enums/BatchOriginEnum'
import { BatchProcessTypeEnum } from 'modules/batch/enums/BatchProcessTypeEnum'
import { BatchProcessMethodEnum } from 'modules/batch/enums/BatchProcessMethodEnum'
import { StoragePlaceEnum } from 'modules/batch/enums/StoragePlaceEnum'
import { StorageTypeEnum } from 'modules/batch/enums/StorageTypeEnum'

export class BatchUtils {

    static translateBatchStep(step: BatchStepEnum): string {
        switch (step) {
            case BatchStepEnum.BENEFIT:
                return 'Benefício'
            case BatchStepEnum.HARVEST:
                return 'Colheita'
            case BatchStepEnum.POST_HARVEST_METHOD:
                return 'Pós-Colheita - Método'
            case BatchStepEnum.POST_HARVEST_PROCESS:
                return 'Pós-Colheita - Processo'
            case BatchStepEnum.REBENEFIT:
                return 'Rebenefício'
            case BatchStepEnum.STORAGE:
                return 'Armazenagem'
            default:
                return ''
        }
    }

    static translateBatchStepToEnglish(step: BatchStepEnum): string {
        switch (step) {
            case BatchStepEnum.BENEFIT:
                return 'Benefit'
            case BatchStepEnum.HARVEST:
                return 'Harvest'
            case BatchStepEnum.POST_HARVEST_METHOD:
                return 'Post Harvest - Method'
            case BatchStepEnum.POST_HARVEST_PROCESS:
                return 'Post Harvest - Process'
            case BatchStepEnum.REBENEFIT:
                return 'Rebenefit'
            case BatchStepEnum.STORAGE:
                return 'Storage'
            default:
                return ''
        }
    }

    static getStepActionKey(step: BatchStepEnum): string {
        switch (step) {
            case BatchStepEnum.BENEFIT:
                return 'benefit'
            case BatchStepEnum.HARVEST:
                return 'harvest'
            case BatchStepEnum.POST_HARVEST_METHOD:
                return 'ph_method'
            case BatchStepEnum.POST_HARVEST_PROCESS:
                return 'process'
            case BatchStepEnum.REBENEFIT:
                return 'rebenefit'
            case BatchStepEnum.STORAGE:
                return 'storage'
            default:
                return ''
        }
    }

    static getStepActionLabel(step: BatchStepEnum): string {
        switch (step) {
            case BatchStepEnum.BENEFIT:
                return 'Benefício'
            case BatchStepEnum.HARVEST:
                return 'Colheita'
            case BatchStepEnum.POST_HARVEST_METHOD:
                return 'PC - Método'
            case BatchStepEnum.POST_HARVEST_PROCESS:
                return 'PC - Processo'
            case BatchStepEnum.REBENEFIT:
                return 'Rebenefício'
            case BatchStepEnum.STORAGE:
                return 'Armazenagem'
            default:
                return ''
        }
    }

    static getBatchProcessTypeTagColor(step: BatchProcessTypeEnum): TagColorTP {
        switch (step) {
            case BatchProcessTypeEnum.BENEFIT:
                return 'purple'
            case BatchProcessTypeEnum.POST_HARVEST:
                return 'blue'
            case BatchProcessTypeEnum.STORAGE:
                return 'darkorange'
            default:
                return 'blue'
        }
    }

    static getNextStep(currentStep: BatchStepEnum, wasBenefited: boolean): BatchStepEnum[] {
        switch (currentStep) {
            case BatchStepEnum.BENEFIT:
                return [BatchStepEnum.STORAGE]
            case BatchStepEnum.HARVEST:
                return [BatchStepEnum.POST_HARVEST_METHOD]
            case BatchStepEnum.POST_HARVEST_METHOD:
                return [BatchStepEnum.POST_HARVEST_PROCESS]
            case BatchStepEnum.POST_HARVEST_PROCESS:
                return [BatchStepEnum.POST_HARVEST_PROCESS, BatchStepEnum.STORAGE, BatchStepEnum.BENEFIT]
            case BatchStepEnum.STORAGE:
                return wasBenefited ? [BatchStepEnum.STORAGE] : [BatchStepEnum.BENEFIT, BatchStepEnum.STORAGE]
            default:
                return [BatchStepEnum.HARVEST]
        }
    }

    static convertProcessEnumToStepEnum(processEnum: BatchProcessEventEnum): BatchStepEnum {
        switch (processEnum) {
            case BatchProcessEventEnum.BENEFIT:
                return BatchStepEnum.BENEFIT
            case BatchProcessEventEnum.HARVEST:
                return BatchStepEnum.HARVEST
            case BatchProcessEventEnum.POST_HARVEST_METHOD:
                return BatchStepEnum.POST_HARVEST_METHOD
            case BatchProcessEventEnum.POST_HARVEST_PROCESS:
                return BatchStepEnum.POST_HARVEST_PROCESS
            case BatchProcessEventEnum.STORAGE:
                return BatchStepEnum.STORAGE
            default:
                return BatchStepEnum.POST_HARVEST_PROCESS
        }
    }

    static translateBatchEventToEnglish(event: BatchProcessEventEnum): string {

        switch (event) {
            case BatchProcessEventEnum.BENEFIT:
                return 'Benefit'
            case BatchProcessEventEnum.HARVEST:
                return 'Harvest'
            case BatchProcessEventEnum.STORAGE:
                return 'Storage'
            case BatchProcessEventEnum.POST_HARVEST_METHOD:
                return 'Post Harvest - Method'
            case BatchProcessEventEnum.POST_HARVEST_PROCESS:
                return 'Post Harvest  - Process'
            case BatchProcessEventEnum.PARENT_MERGE_BATCHES:
                return 'Generated From: Merged Batches'
            case BatchProcessEventEnum.PARENT_SPLIT_BATCH:
                return 'Generated From: Splited Batches'
            case BatchProcessEventEnum.CHILD_MERGE_BATCHES:
                return 'Merged Batches'
            case BatchProcessEventEnum.CHILD_SPLIT_BATCH:
                return 'Splited Batches'
            default:
                return ''
        }
    }

    static translateOrigin(type: BatchOriginEnum): string {
        switch (type) {
            case BatchOriginEnum.MERGE:
                return 'Mescla'
            case BatchOriginEnum.SPLIT:
                return 'Divisão'
            case BatchOriginEnum.HARVEST:
                return 'Colheita'
            default:
                return ''
        }
    }

    static translateOriginToEnglish(type: BatchOriginEnum): string {
        switch (type) {
            case BatchOriginEnum.MERGE:
                return 'Merge'
            case BatchOriginEnum.SPLIT:
                return 'Split'
            case BatchOriginEnum.HARVEST:
                return 'Harvest'
            default:
                return ''
        }
    }

    static translateMethod(method: BatchMethodEnum, methodOther?: string): string {
        switch (method) {
            case BatchMethodEnum.DESMUCILATED:
                return 'Desmucilado'
            case BatchMethodEnum.NATURAL:
                return 'Natural'
            case BatchMethodEnum.NATURAL_WET_SEPARATED:
                return 'Natural separado por via úmida (lavador)'
            case BatchMethodEnum.PULPED_NATURAL:
                return 'Cereja Descascado'
            default:
                return ''
        }
    }

    static translateBatchMethodToEnglish(method: BatchMethodEnum, methodOther?: string): string {
        switch (method) {
            case BatchMethodEnum.DESMUCILATED:
                return 'Desmucilated'
            case BatchMethodEnum.NATURAL:
                return 'Natural'
            case BatchMethodEnum.NATURAL_WET_SEPARATED:
                return 'Natural wet separated'
            case BatchMethodEnum.PULPED_NATURAL:
                return 'Pulped natural'
            default:
                return ''
        }
    }

    static translateProcessType(type: BatchProcessTypeEnum, other?: string): string {
        switch (type) {
            case BatchProcessTypeEnum.POST_HARVEST:
                return 'Pós-colheita'
            case BatchProcessTypeEnum.STORAGE:
                return 'Armazenamento'
            case BatchProcessTypeEnum.BENEFIT:
                return 'Benefício'

            default:
                return other ?? ''
        }
    }

    static translateProcessToEnglish(type: BatchProcessTypeEnum): string {
        switch (type) {
            case BatchProcessTypeEnum.POST_HARVEST:
                return 'Post Harvest'
            case BatchProcessTypeEnum.STORAGE:
                return 'Storage'
            case BatchProcessTypeEnum.BENEFIT:
                return 'Benefit'
            default:
                return ''
        }
    }

    static getProcessTypeInitials(type: BatchProcessTypeEnum): string {
        switch (type) {
            case BatchProcessTypeEnum.POST_HARVEST:
                return 'PC'
            case BatchProcessTypeEnum.STORAGE:
                return 'A'
            case BatchProcessTypeEnum.BENEFIT:
                return 'B'
            default:
                return ''
        }
    }

    static translateProcessMethod(method: BatchProcessMethodEnum): string {
        switch (method) {
            case BatchProcessMethodEnum.AEROBIC_FERMENTED:
                return 'Fermentado aeróbico'
            case BatchProcessMethodEnum.ANAEROBIC_FERMENTED:
                return 'Fermentado anaeróbico '
            case BatchProcessMethodEnum.HONEY:
                return 'Honey'
            case BatchProcessMethodEnum.THIN_LAYER:
                return 'Camada fina'
            case BatchProcessMethodEnum.THICK_LAYER:
                return 'Camada grossa'
            case BatchProcessMethodEnum.DESMUCILATED:
                return 'Desmucilado'
            case BatchProcessMethodEnum.CARBONIC_MACERATION:
                return 'Maceração carbônica'
            case BatchProcessMethodEnum.WATER_ANAEROBIC_FERMENTED:
                return 'Fermentado anaeróbico na água'
            case BatchProcessMethodEnum.DRYER:
                return 'Secador'
            case BatchProcessMethodEnum.REST:
                return 'Descanso'
            case BatchProcessMethodEnum.WASHED:
                return 'Lavado'
            case BatchProcessMethodEnum.PULPED_NATURAL:
                return 'Cereja Descascado'
            default:
                return ''
        }
    }

    static translateProcessMethodToEnglish(method: BatchProcessMethodEnum): string {

        switch (method) {
            case BatchProcessMethodEnum.AEROBIC_FERMENTED:
                return 'Aerobic fermentation'
            case BatchProcessMethodEnum.ANAEROBIC_FERMENTED:
                return 'Anaerobic fermentation '
            case BatchProcessMethodEnum.HONEY:
                return 'Honey'
            case BatchProcessMethodEnum.THIN_LAYER:
                return 'Thin layer'
            case BatchProcessMethodEnum.THICK_LAYER:
                return 'Thick layer'
            case BatchProcessMethodEnum.DESMUCILATED:
                return 'Desmucilated'
            case BatchProcessMethodEnum.CARBONIC_MACERATION:
                return 'Carbonic maceration'
            case BatchProcessMethodEnum.WATER_ANAEROBIC_FERMENTED:
                return 'Anaerobic fermentation in water'
            case BatchProcessMethodEnum.DRYER:
                return 'Dryer'
            case BatchProcessMethodEnum.REST:
                return 'Rest'
            case BatchProcessMethodEnum.WASHED:
                return 'Washed'
            case BatchProcessMethodEnum.PULPED_NATURAL:
                return 'Cereja Descascado'
            default:
                return ''
        }

    }

    static translateStoragePlace(place: StoragePlaceEnum): string {
        switch (place) {
            case StoragePlaceEnum.COOPERATIVE:
                return 'Cooperativa'
            case StoragePlaceEnum.PROPERTY:
                return 'Na Propriedade'
            case StoragePlaceEnum.WAREHOUSE:
                return 'Armazém'
            default:
                return ''
        }
    }

    static translateStorageType(type: StorageTypeEnum): string {
        switch (type) {
            case StorageTypeEnum.BIG_BAG:
                return 'Big Bag'
            case StorageTypeEnum.HOPPER:
                return 'Moega'
            case StorageTypeEnum.JUTE_BAG:
                return 'Saco de Juta'
            case StorageTypeEnum.RAFFIA_BAG:
                return 'Saco de Ráfia'
            default:
                return ''
        }
    }

    static translateStoragePlaceToEnglish(place: StoragePlaceEnum): string {
        switch (place) {
            case StoragePlaceEnum.COOPERATIVE:
                return 'Cooperative'
            case StoragePlaceEnum.PROPERTY:
                return 'Property'
            case StoragePlaceEnum.WAREHOUSE:
                return 'Warehouse'
            default:
                return ''
        }
    }

    static translateStorageTypeToEnglish(type: StorageTypeEnum): string {
        switch (type) {
            case StorageTypeEnum.BIG_BAG:
                return 'Big Bag'
            case StorageTypeEnum.HOPPER:
                return 'Hopper'
            case StorageTypeEnum.JUTE_BAG:
                return 'Jute bag'
            case StorageTypeEnum.RAFFIA_BAG:
                return 'Rafia bag'
            default:
                return ''
        }
    }
}