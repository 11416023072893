import IMask from 'imask'
import moment from 'moment'

/**
 * UTIL
 * utilitário para aplicacao de mascaras em um input
 * @author kiara.vz
 * @author guilherme.diniz
 */
export class MaskUtils {

    static applyPhoneMask(value: string): string {
        if (!value)
            return ''

        const rawValue = value.replace(/\D/g, '')

        if (rawValue[2] === '9')
            return IMask.createMask({ mask: '(00) 00000-0000' }).resolve(`${value}`)
        return IMask.createMask({ mask: '(00) 0000-0000' }).resolve(`${value}`)

    }

    static applyCpfMask(value: string | number): string {
        if (!value)
            return `${value}`

        return IMask.createMask({ mask: '000.000.000-00' }).resolve(`${value}`)

    }

    static applyHourMask(value: string | number): string {
        const rawValue = `${value}`.replace(/\D/g, '')
        if (!value)
            return `${value}`

        const momentFormat = 'HH:mm'
        return IMask.createMask({
            mask: Date,
            pattern: momentFormat,
            format(date: Date) {
                return moment(date).format(momentFormat)
            },
            parse(str: string) {
                return moment(str, momentFormat)
            },
            blocks: {
                HH: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 23
                },
                mm: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 59
                }
            }
        }).resolve(rawValue)

    }

    static applyDateMask(value: string | number, max?: Date, min?: Date): string {
        const rawValue = `${value}`.replace(/\D/g, '')
        if (!value)
            return `${value}`

        const momentFormat = 'DD/MM/YYYY'
        return IMask.createMask({
            mask: Date,
            min,
            max,
            pattern: momentFormat,
            format(date: Date) {
                return moment(date).format(momentFormat)
            },
            parse(str: string) {
                return moment(str, momentFormat)
            },
            blocks: {
                YYYY: {
                    mask: IMask.MaskedRange,
                    from: 1901,
                    to: 9999
                },
                MM: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12
                },
                DD: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31
                }
            }
        }).resolve(rawValue)

    }

    static applyCnpjMask(value: string | number): string {
        if (!value)
            return `${value}`

        return IMask.createMask({ mask: '00.000.000/0000-00' }).resolve(`${value}`)

    }

    static applyCpfCnpjMask(value: string | number): string {
        const _value = `${value}`.length && `${value}`.replace(/\D/g, '')
        const valueToMask = _value.toString()

        if (!value)
            return valueToMask

        if (valueToMask.length > 11)
            return IMask.createMask({ mask: '00.000.000/0000-00' }).resolve(valueToMask)
        return IMask.createMask({ mask: '000.000.000-00' }).resolve(valueToMask)
    }

    static applyZipcodeMask(value: string | number): string {
        if (!value)
            return `${value}`

        return IMask.createMask({ mask: '00000-000' }).resolve(`${value}`)

    }

    static applyStateMask(value: string | number): string {
        if (!value)
            return `${value}`

        return IMask.createMask({ mask: 'aa' }).resolve(`${value}`)

    }

    static applyCurrencyMask(value: string | number): string {
        if (!value)
            return ''
        return `R$ ${this.applyNumberMask(+value, true)}`
    }

    static applyIntMask(value: string | number): string {
        if (!value)
            return `${value}`

        return IMask.createMask({ mask: Number }).resolve(`${value}`)
    }

    static applyNumberMask(value: number, padFractionalZeros: boolean = false): string {
        if (!value)
            return `${value}`

        return IMask.createMask({
            mask: Number,
            scale: 2,
            signed: true,
            thousandsSeparator: '.',
            padFractionalZeros,
            radix: ','
        })
            .resolve(value.toString())
    }

    static removeMask(value: string): string {
        return value.replace(',', '.').replace(/[^\d.-]/g, '')
    }
}
