import { Col, Form, Row } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import React from 'react'
import { UploadInput } from '../../../../../common/components/form-inputs/UploadInput'

interface IProps {
    onNext: (values?: any) => void
    onPrev: (values?: any) => void
    values?: any
}

export function RegistrationFileDataStep(props: IProps): JSX.Element {
    const [form] = Form.useForm()

    return (
        <Form
            form={form}
            initialValues={{
                ...props.values
            }}
            layout={'vertical'}
            name={'fileData'}
            onFinish={(values) => {
                props.onNext(values)
            }}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name)
            }}
        >

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        label={'Foto de Perfil:'}
                        name={'profilePicture'}
                        rules={[{ required: true, message: 'Campo Obrigatório!' }]}
                    >
                        <UploadInput
                            name={'profilePicture'}
                            multiple={false}
                            accept={['image/png', 'image/jpeg', 'image/jpg']}
                            onUpload={(file) => form.setFieldsValue({ profilePicture: file })}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        label={'Comprovante de Endereço:'}
                        name={'addressPicture'}
                        rules={[{ required: true, message: 'Campo Obrigatório!' }]}
                    >
                        <UploadInput
                            name={'addressPicture'}
                            multiple={false}
                            accept={['image/png', 'image/jpeg', 'image/jpg']}
                            onUpload={(file) => form.setFieldsValue({ addressPicture: file })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        label={'Identidade (Frente):'}
                        name={'rgFront'}
                        rules={[{ required: true, message: 'Campo Obrigatório!' }]}
                    >
                        <UploadInput
                            name={'rgFront'}
                            multiple={false}
                            accept={['image/png', 'image/jpeg', 'image/jpg']}
                            onUpload={(file) => form.setFieldsValue({ rgFront: file })}
                            files={[]}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        label={'Identidade (Verso):'}
                        name={'rgBack'}
                        rules={[{ required: true, message: 'Campo Obrigatório!' }]}
                    >
                        <UploadInput
                            name={'rgBack'}
                            multiple={false}
                            accept={['image/png', 'image/jpeg', 'image/jpg']}
                            onUpload={(file) => form.setFieldsValue({ rgBack: file })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        label={'Identidade (Segurando junto ao rosto):'}
                        name={'rgFace'}
                        rules={[{ required: true, message: 'Campo Obrigatório!' }]}
                    >
                        <UploadInput
                            name={'rgFace'}
                            multiple={false}
                            accept={['image/png', 'image/jpeg', 'image/jpg']}
                            onUpload={(file) => form.setFieldsValue({ rgFace: file })}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        label={'Foto da Inscrição Estadual:'}
                        name={'stateRegPicture'}
                        rules={[{ required: true, message: 'Campo Obrigatório!' }]}
                    >
                        <UploadInput
                            name={'stateRegPicture'}
                            multiple={false}
                            accept={['image/png', 'image/jpeg', 'image/jpg']}
                            onUpload={(file) => form.setFieldsValue({ stateRegPicture: file })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonCP type={'primary'} ghost={true} onClick={props.onPrev} marginRight={5}>
                    Voltar
                </ButtonCP>
                <ButtonCP type={'primary'} isSubmit={true}>
                    Próximo
                </ButtonCP>
            </div>
        </Form>
    )
}