import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { SchemaDataTP } from 'common/redux/ReduxTypes'
import { ThemeUtils } from 'common/utils/ThemeUtils'
import { ThemeConfig } from 'config/ThemeConfig'
import { ICompanyResDTO } from 'modules/company/services/dtos/response/ICompanyResDTO'

export class SchemaActions {

    static readonly TYPE_LOAD_SCHEMA_DATA = 'TYPE_LOAD_SCHEMA_DATA'


    private constructor() { /* private constructor to avoid instantiation */ }

    static persistSchema(schemaData: ICompanyResDTO): void {

        ReduxHelper.getInstance().getStore()
            .dispatch({ type: this.TYPE_LOAD_SCHEMA_DATA, payload: schemaData })
    }

    static getThemeColor(): any {
        const schema: SchemaDataTP | null = ReduxHelper.getInstance().getStore().getState().schemaData
        return schema?.color ?? ''
    }

    static getDomain(): string | null {
        const schema: SchemaDataTP | null = ReduxHelper.getInstance().getStore().getState().schemaData
        return schema?.domain ?? null
    }

    static clearSchemaData(): void {
        window.less.modifyVars(ThemeUtils.formatedTheme(ThemeConfig)).catch(error => { })
        ReduxHelper.getInstance().getStore()
            .dispatch({ type: this.TYPE_LOAD_SCHEMA_DATA, payload: null })
    }

}
