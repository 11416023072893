import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { StructureTypeEnum } from 'modules/batch/enums/StructureTypeEnum'
import { StructureUtils } from 'modules/structure/utils/StructureUtils'
import React from 'react'
import styled from 'styled-components'

interface IStructureTypeSelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    label?: string
    value?: StructureTypeEnum
    required?: boolean
    onSelect?: (type: StructureTypeEnum) => void
}

export function StructureTypeSelectorCP<FModelTP extends FormModelValidator = any>(props: IStructureTypeSelectorCPProps<FModelTP>): JSX.Element {
    function getOptions(): SelectOptionTP[] {
        return Object.values(StructureTypeEnum).map(e => ({ label: StructureUtils.translateType(e), value: e }))
    }

    return (
        <SelectCP
            showSearch={true}
            options={getOptions()}
            label={props.label}
            placeholder={'Tipo'}
            formStateManager={props.formStateManager}
            fieldName={'type'}
            required={props.required}
            onChange={props.onSelect}
            value={props.value}
        />
    )
}

const WrapperSCP = styled.div`
`
