/* eslint-disable @typescript-eslint/typedef */
import moment from 'moment-timezone'
import { TagCP } from 'common/components/tag/TagCP'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { momentPtBrLocale } from 'common/utils/moment-pt-br-locale'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { BatchOriginEnum } from 'modules/batch/enums/BatchOriginEnum'
import { BatchMethodEnum } from 'modules/batch/enums/BatchProcessEnum'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import { HarvestTypeEnum } from 'modules/harvest/enums/HarvestTypeEnum'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import { HarvestUtils } from 'modules/harvest/utils/HarvestUtils'
import { LogbookUtils } from 'modules/logbook/utils/LogbookUtils'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { useHistory } from 'react-router-dom'
import { ExportOutlined } from '@ant-design/icons'
import { DateUtils } from '../../../../../common/utils/DateUtils'
import { DateFormatEnum } from '../../../../../common/enums/DateFormatEnum'
import { FontAwsomeIconCP } from '../../../../../common/components/icon/FontAwsomeIconCP'
import {
    faArrowCircleUp,
    faArrowRight,
    faChevronRight,
    faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import { BatchHarvestOrigin, BatchOriginUtils } from '../../../utils/BatchOriginUtils'

interface IBatchOriginDetailsEntryICPProps {
    batchCode: number
    language?: LanguageEnum
}
const LABELS_PT = {
    method: 'Método',
    harvest: 'Colheita',
    batch: 'Lote',
    methodOther: 'Outro',
    origin: 'Origem',
}

const LABELS_EN = {
    method: 'Méthod',
    harvest: 'Harvest',
    batch: 'Batch',
    methodOther: 'Other',
    origin: 'Origin',
}

export function BatchOriginDetailsEntryICP(props: IBatchOriginDetailsEntryICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [height, setHeight] = useState(0)
    const [labels, setLabels] = useState(LABELS_PT)
    const [batchData, setBatchData] = useState<IBatchResponseDTO>()
    const [originHarvests, setOriginHarvests] = useState<BatchHarvestOrigin[]>([])
    const [originParents, setOriginParents] = useState<IBatchResponseDTO[]>([])
    const ref = useRef<HTMLDivElement>(null)
    const history = useHistory()

    const [harvestMethodTranslateFunc, setHarvestMethodTranslateFunc] = useState<
    (type: HarvestTypeEnum, typeOther?: string) => string
    >(() => HarvestUtils.translateType)
    const [batchMethodTranslateFunc, setBatchMethodTranslateFunc] = useState<
    (method: BatchMethodEnum, methodOther?: string) => string
    >(() => BatchUtils.translateMethod)
    const [batchOriginTranslateFunc, setBatchOriginTranslateFunc] = useState<
    (origin: BatchOriginEnum) => string
    >(() => BatchUtils.translateOrigin)

    useLayoutEffect(() => {
        if (!!ref.current)
            setHeight(ref.current.clientHeight)
    }, [])

    useEffect(setLanguage, [props.language])
    useEffect(translateLabels, [props.language])

    const request = useRequest<IBatchResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])
    useEffect(getBatchParents, [props.batchCode])

    function getBatchParents(): void {
        if (!!props.batchCode)
            request.runRequest(BatchRequests.getOriginData(props.batchCode))
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(
                request.error,
                'Erro ao buscar detalhes!',
            )
        }
        setBatchData(request.returnData)
        if (!!request.returnData.parents)
            setOriginParents(request.returnData.parents)

        if (!!request.returnData.originHarvests) {
            setOriginHarvests(
                BatchOriginUtils.parseOriginHarvests(request.returnData.originHarvests),
            )
        }
    }

    function translateLabels(): void {
        if (props.language === LanguageEnum.EN) {
            setLabels(LABELS_EN)
            setHarvestMethodTranslateFunc(() => HarvestUtils.translateTypeToEnlgish)
            setBatchOriginTranslateFunc(() => BatchUtils.translateOriginToEnglish)
            setBatchMethodTranslateFunc(() => BatchUtils.translateBatchMethodToEnglish)
        }
    }

    function setLanguage(): void {
        if (props.language === LanguageEnum.EN) {
            moment.locale('en_US')
            return
        }
        moment.locale('pt_BR', momentPtBrLocale)
    }

    if (!batchData || request.awaiting) {
        return (
            <SklWrppSCP>
                {' '}
                <GenericRowSkeletonICP rows={3} columns={3} />
            </SklWrppSCP>
        )
    }

    return (
        <MainWrapperSCP ref={ref}>
            <DateTimelineSCP isLastOne={true} currentHeight={height}>
                <TextCP
                    text={moment(batchData.date).format('DD')}
                    style={{
                        fontSize: '20px',
                        fontWeight: '600',
                        color: 'white',
                        lineHeight: '18px',
                        leterSpacing: '3px',
                    }}
                />
                <TextCP
                    text={`${moment(batchData.date).format('MMM')}`}
                    style={{
                        fontSize: '12px',
                        fontWeight: 'normal',
                        color: 'white',
                        lineHeight: '13px',
                    }}
                />
            </DateTimelineSCP>
            <CenterInfoWrapperSCP>
                <DateAndTimeSCP>
                    <TextCP
                        text={`${LogbookUtils.getDateText(batchData.date, props.language)} ----`}
                        style={{ fontSize: '14px', fontWeight: 500 }}
                    />
                </DateAndTimeSCP>

                <ExtraInfoSCP>
                    <ContentInfoSCP>
                        <RowSCP>
                            <TextCP
                                text={`${labels.method}:\u00A0`}
                                style={{ fontWeight: '500 ' }}
                            />
                            <TagCP
                                content={`${batchMethodTranslateFunc(
                                    batchData.method,
                                    batchData.methodOther,
                                )}`}
                                color={'green'}
                            />
                        </RowSCP>
                        <RowSCP>
                            <TextCP
                                text={`${labels.origin}:\u00A0`}
                                style={{ fontWeight: '500 ' }}
                            />
                            <TextCP text={`${batchOriginTranslateFunc(batchData.origin)}`} />
                        </RowSCP>
                        {!!originHarvests.length &&
                            batchData.origin === BatchOriginEnum.HARVEST &&
                            originHarvests.map((hv) => (
                                <HarvestWrapperSCP
                                    key={`${hv.code}`}
                                    clickable={!!hv.harvestPlanningCode}
                                >
                                    <HarvestDataSCP
                                        onClick={() =>
                                            hv.harvestPlanningCode &&
                                            history.push(
                                                `/colheita/logbook/${hv.harvestPlanningCode}`,
                                            )}
                                    >
                                        <RowSCP key={`${hv.code}`}>
                                            <TextCP
                                                text={`${labels.harvest} #${
                                                    hv.harvestPlanningCode ?? hv.code
                                                }\u00A0 - \u00A0`}
                                                style={{ fontWeight: '500 ' }}
                                            />
                                            <TextCP
                                                text={`${DateUtils.formatDate(
                                                    hv.date,
                                                    DateFormatEnum.BR_WITHOUT_TIME,
                                                )}\u00A0`}
                                                style={{ fontWeight: '500 ' }}
                                            />
                                        </RowSCP>
                                        <RowSCP>
                                            <TextCP
                                                text={`${
                                                    labels.method
                                                }:\u00A0 ${harvestMethodTranslateFunc(hv.type)}`}
                                            />
                                        </RowSCP>
                                    </HarvestDataSCP>
                                    <FontAwsomeIconCP icon={faExternalLinkAlt} />
                                </HarvestWrapperSCP>
                            ))}

                        {!!originParents.length &&
                            (batchData.origin === BatchOriginEnum.MERGE ||
                                batchData.origin === BatchOriginEnum.SPLIT) &&
                            originParents.map((bt) => {
                                if (!!bt.method) {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '5px',
                                            }}
                                        >
                                            <RowSCP key={`${bt.code}`}>
                                                <TextCP
                                                    text={`${labels.batch} #${bt.code} - ${labels.method}:\u00A0`}
                                                    style={{ fontWeight: '500 ' }}
                                                />
                                                <TextCP
                                                    text={`${batchMethodTranslateFunc(
                                                        bt.method,
                                                        bt.methodOther,
                                                    )}`}
                                                />
                                            </RowSCP>
                                            {!!bt.originHarvests &&
                                                !!bt.originHarvests.length &&
                                                BatchOriginUtils.parseOriginHarvests(
                                                    bt.originHarvests,
                                                ).map((hv) => (
                                                    <HarvestWrapperSCP
                                                        key={`${hv.code}`}
                                                        clickable={!!hv.harvestPlanningCode}
                                                    >
                                                        <HarvestDataSCP
                                                            onClick={() =>
                                                                hv.harvestPlanningCode &&
                                                                history.push(
                                                                    `/colheita/logbook/${hv.harvestPlanningCode}`,
                                                                )}
                                                        >
                                                            <RowSCP key={`${hv.code}`}>
                                                                <TextCP
                                                                    text={`${labels.harvest} #${
                                                                        hv.harvestPlanningCode ??
                                                                        hv.code
                                                                    }\u00A0 - \u00A0`}
                                                                    style={{ fontWeight: '500 ' }}
                                                                />
                                                                <TextCP
                                                                    text={`${DateUtils.formatDate(
                                                                        hv.date,
                                                                        DateFormatEnum.BR_WITHOUT_TIME,
                                                                    )}\u00A0`}
                                                                    style={{ fontWeight: '500 ' }}
                                                                />
                                                            </RowSCP>
                                                            <RowSCP>
                                                                <TextCP
                                                                    text={`${
                                                                        labels.method
                                                                    }:\u00A0 ${harvestMethodTranslateFunc(
                                                                        hv.type,
                                                                    )}`}
                                                                />
                                                            </RowSCP>
                                                        </HarvestDataSCP>
                                                        <FontAwsomeIconCP
                                                            icon={faExternalLinkAlt}
                                                        />
                                                    </HarvestWrapperSCP>
                                                ))}
                                        </div>
                                    )
                                }
                                return (
                                    <div
                                        key={`${bt.code}`}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '5px',
                                        }}
                                    >
                                        <RowSCP key={`${bt.code}`}>
                                            <TextCP
                                                text={`${labels.batch} #${bt.code} - ${labels.origin}:\u00A0`}
                                                style={{ fontWeight: '500 ' }}
                                            />
                                            <TextCP
                                                text={`${batchOriginTranslateFunc(bt.origin)}`}
                                            />
                                        </RowSCP>
                                        {!!bt.originHarvests &&
                                            !!bt.originHarvests.length &&
                                            BatchOriginUtils.parseOriginHarvests(
                                                bt.originHarvests,
                                            ).map((hv) => (
                                                <HarvestWrapperSCP
                                                    key={`${hv.code}`}
                                                    clickable={!!hv.harvestPlanningCode}
                                                >
                                                    <HarvestDataSCP
                                                        onClick={() =>
                                                            hv.harvestPlanningCode &&
                                                            history.push(
                                                                `/colheita/logbook/${hv.harvestPlanningCode}`,
                                                            )}
                                                    >
                                                        <RowSCP key={`${hv.code}`}>
                                                            <TextCP
                                                                text={`${labels.harvest} #${
                                                                    hv.harvestPlanningCode ??
                                                                    hv.code
                                                                }\u00A0 - \u00A0`}
                                                                style={{ fontWeight: '500 ' }}
                                                            />
                                                            <TextCP
                                                                text={`${DateUtils.formatDate(
                                                                    hv.date,
                                                                    DateFormatEnum.BR_WITHOUT_TIME,
                                                                )}\u00A0`}
                                                                style={{ fontWeight: '500 ' }}
                                                            />
                                                        </RowSCP>
                                                        <RowSCP>
                                                            <TextCP
                                                                text={`${
                                                                    labels.method
                                                                }:\u00A0 ${harvestMethodTranslateFunc(
                                                                    hv.type,
                                                                )}`}
                                                            />
                                                        </RowSCP>
                                                    </HarvestDataSCP>
                                                    <FontAwsomeIconCP icon={faExternalLinkAlt} />
                                                </HarvestWrapperSCP>
                                            ))}
                                    </div>
                                )
                            })}
                    </ContentInfoSCP>
                </ExtraInfoSCP>
            </CenterInfoWrapperSCP>
        </MainWrapperSCP>
    )
}

const MainWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 0.3fr 5fr 1fr;
    margin: 10px;
    column-gap: 20px;
    @media (max-width: 768px) {
        grid-template-columns: 40px 1fr;
    }
`

const NoteWrapperSCP = styled.div`
    background: #e6e6e6;
    padding: 10px;
    border-radius: 5px;
`
const CenterInfoWrapperSCP = styled.div`
    margin: 10px 0 10px 12px;
    display: grid;
    row-gap: 8px;
`
const DateAndTimeSCP = styled.div`
    .ant-typography {
        color: ${(props) => props.theme.primaryColor};
    }
`

const SklWrppSCP = styled.div`
    margin-left: 9em;
`

const DateTimelineSCP = styled.div<{ isLastOne?: boolean, currentHeight?: number }>`
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    align-items: center;
    background: ${(props) => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    justify-content: center;
    position: relative;
    &::after {
        display: ${(props) => (props.isLastOne ? 'none' : 'block')};
        position: absolute;
        content: '';
        background: ${(props) => props.theme.primaryColor};
        width: 1px;
        height: ${(props) => (!!props.currentHeight ? `${props.currentHeight}px` : '300px')};
        top: 60px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
`
const ExtraInfoSCP = styled.div``

const ActionsWrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .ant-btn {
        margin-left: 5px;
    }
`

const ContentInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    .ant-typography {
        font-size: 12px;
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography {
        font-size: 12px;
    }
`

const HarvestWrapperSCP = styled.div<{ clickable: boolean }>`
    display: flex;
    column-gap: 5px;
    row-gap: 5px;
    background-color: ${(props) => props.theme.primaryColor}22;
    border-radius: 10px;
    padding: 10px;
    ${(props) => props.clickable && 'cursor: pointer;'}
`

const HarvestDataSCP = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    column-gap: 5px;
`
