/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import styled from 'styled-components'
import { Descriptions } from 'antd'
import { TagCP } from 'common/components/tag/TagCP'
import { AnalysisUtils } from 'modules/analysis/utils/AnalysisUtils'
import { IBatchPhysicalAnalysisResponseDTO } from '../../../services/dtos/responses/IBatchAnalysisResponseDTO'
import { PhysicalColorEnum } from '../../../../analysis/enums/PhysicalColorEnum'

interface IPhysicalAnalysesResumeContentCPProps {
    item: IBatchPhysicalAnalysisResponseDTO
}

export function PhysicalAnalysesResumeContentCP(
    props: IPhysicalAnalysesResumeContentCPProps,
): JSX.Element {
    const totalBottom =
        (props.item.bottom ?? 0) +
        props.item.picking1413 +
        props.item.picking15 +
        props.item.picking16 +
        props.item.pickingMoka +
        props.item.picking1718

    const defects1718 = props.item.defects?.filter((d) => d.sieve.name === 'SIEVE_1718')
    const defects16 = props.item.defects?.filter((d) => d.sieve.name === 'SIEVE_16')
    const defects15 = props.item.defects?.filter((d) => d.sieve.name === 'SIEVE_15')
    const defects1413 = props.item.defects?.filter((d) => d.sieve.name === 'SIEVE_1413')
    const defectsMoka = props.item.defects?.filter((d) => d.sieve.name === 'SIEVE_MOKA')
    const defectsBottom = props.item.defects?.filter((d) => d.sieve.name === 'SIEVE_BOTTOM')

    return (
        <ContentWrapperSCP>
            <DescriptionsSCP column={1} bordered={true}>
                <Descriptions.Item label={'Avaliador'}>
                    <TagCP color={'purple'} content={props.item.user.name} />
                </Descriptions.Item>
                <Descriptions.Item label={'Umidade'}>
                    <TagCP color={'navyblue'} content={`${props.item.humidity}%`} />
                </Descriptions.Item>
                <Descriptions.Item label={'Peneira 17/18'}>
                    <TagCP color={'navyblue'} content={`${props.item.sieve1718}%`} />
                    <TagCP color={'red'} content={`Catação: ${props.item.picking1718}%`} />
                    {defects1718?.length
                        ? defects1718.map((defect) => (
                            <TagCP
                                key={defect.physicalDefect.id}
                                color={'red'}
                                content={AnalysisUtils.translatePhysicalDefect(
                                    defect.physicalDefect.name as any,
                                )}
                            />
                        ))
                        : null}
                </Descriptions.Item>
                <Descriptions.Item label={'Peneira Moka'}>
                    <TagCP color={'navyblue'} content={`${props.item.sieveMoka}%`} />
                    <TagCP color={'red'} content={`Catação: ${props.item.pickingMoka}%`} />
                    {defectsMoka?.length
                        ? defectsMoka.map((defect) => (
                            <TagCP
                                key={defect.physicalDefect.id}
                                color={'red'}
                                content={AnalysisUtils.translatePhysicalDefect(
                                    defect.physicalDefect.name as any,
                                )}
                            />
                        ))
                        : null}
                </Descriptions.Item>
                <Descriptions.Item label={'Peneira 16'}>
                    <TagCP color={'navyblue'} content={`${props.item.sieve16}%`} />
                    <TagCP color={'red'} content={`Catação: ${props.item.picking16}%`} />
                    {defects16?.length
                        ? defects16.map((defect) => (
                            <TagCP
                                key={defect.physicalDefect.id}
                                color={'red'}
                                content={AnalysisUtils.translatePhysicalDefect(
                                    defect.physicalDefect.name as any,
                                )}
                            />
                        ))
                        : null}
                </Descriptions.Item>
                <Descriptions.Item label={'Peneira 15'}>
                    <TagCP color={'navyblue'} content={`${props.item.sieve15}%`} />
                    <TagCP color={'red'} content={`Catação: ${props.item.picking15}%`} />
                    {defects15?.length
                        ? defects15.map((defect) => (
                            <TagCP
                                key={defect.physicalDefect.id}
                                color={'red'}
                                content={AnalysisUtils.translatePhysicalDefect(
                                    defect.physicalDefect.name as any,
                                )}
                            />
                        ))
                        : null}
                </Descriptions.Item>
                <Descriptions.Item label={'Peneira 13/14'}>
                    <TagCP color={'navyblue'} content={`${props.item.sieve1413}%`} />
                    <TagCP color={'red'} content={`Catação: ${props.item.picking1413}%`} />
                    {defects1413?.length
                        ? defects1413.map((defect) => (
                            <TagCP
                                key={defect.physicalDefect.id}
                                color={'red'}
                                content={AnalysisUtils.translatePhysicalDefect(
                                    defect.physicalDefect.name as any,
                                )}
                            />
                        ))
                        : null}
                </Descriptions.Item>
                <Descriptions.Item label={'Fundo'}>
                    <TagCP color={'red'} content={`${props.item.bottom ?? 0}%`} />
                    {defectsBottom?.length
                        ? defectsBottom.map((defect) => (
                            <TagCP
                                key={defect.physicalDefect.id}
                                color={'red'}
                                content={AnalysisUtils.translatePhysicalDefect(
                                    defect.physicalDefect.name as any,
                                )}
                            />
                        ))
                        : null}
                </Descriptions.Item>
                <Descriptions.Item label={'Fundo + Catação'}>
                    <TagCP color={'red'} content={`${totalBottom.toFixed(2)}%`} />
                </Descriptions.Item>
                <Descriptions.Item label={'Cores'}>
                    {!!props.item.color &&
                        props.item.color.map((color) =>
                            AnalysisUtils.translateColor(color.name as PhysicalColorEnum),)}
                </Descriptions.Item>

                <Descriptions.Item label={'Observações'}>{props.item.notes}</Descriptions.Item>
            </DescriptionsSCP>
        </ContentWrapperSCP>
    )
}
const ContentWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
    background: #fff;
    @media (max-width: 768px) {
        padding: 0;
    }
`
const DescriptionsSCP = styled(Descriptions)`
    &.ant-descriptions {
        margin-top: 10px;
    }

    td.ant-descriptions-item-content {
        display: flex;
        flex-wrap: wrap;
    }
`
