import { QualityReportByFieldCP } from 'modules/report/components/quality-report-by-field/QualityReportByFieldCP'
import { QualityReportGridCP } from 'modules/report/components/quality-report-grid/QualityReportGridCP.tsx'
import { ReportFiltersCP } from 'modules/report/components/report-filters/ReportFiltersCP'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IQualityReportResDTO } from 'modules/report/services/dtos/responses/IQualityReportResDTO'
import { ReportRequests } from 'modules/report/services/ReportRequests'

const DEFAULT_FILTERS: ISearchReportReqDTO = {
    season: moment().year(),

}
interface IQualityReportContentCPProps {

}
export function QualityReportContentCP(props: IQualityReportContentCPProps): JSX.Element {
    const [filters, setFilters] = useState<ISearchReportReqDTO>(DEFAULT_FILTERS)
    const [reportData, setReportData] = useState<IQualityReportResDTO>()

    const getReportReq = useRequest<IQualityReportResDTO>()
    useEffect(getReportData, [filters])
    useEffect(onRequestChange, [getReportReq.awaiting])

    function getReportData(): void {
        if (!!filters && !!filters.propertyCode)
            getReportReq.runRequest(ReportRequests.getQualityReport(filters))
    }

    function onRequestChange(): void {
        if (getReportReq.awaiting || !getReportReq.tried)
            return

        if (!getReportReq.success || !getReportReq.returnData) {
            if (getReportReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getReportReq.returnData, getReportReq.error)
            return RequestUtils.showDefaultErrorNotification(getReportReq.error, 'Erro ao salvar análise!')
        }
        setReportData(getReportReq.returnData)
    }

    return (
        <WrapperSCP>
            <ReportFiltersCP
                filters={filters}
                onChangeFilters={setFilters}
                showFields={['season', 'property']}
            />
            <QualityReportGridCP reportData={reportData?.general} loading={getReportReq.awaiting} />
            <QualityReportByFieldCP reportData={reportData?.fields} />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
 
    margin-bottom: 4em;
    max-height: 75vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    } 
`
