import { TextCP } from 'common/components/text/TextCP'
import { BatchTreeResumeCardICP } from 'modules/batch/components/batch-tree-drawer/inner/BatchTreeResumeCardICP'
import { BatchTreeResumeCardICP2 } from 'modules/batch/components/batch-tree-drawer/inner/BatchTreeResumeCardICP2'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import React from 'react'
import styled from 'styled-components'

interface IMergeTreeICPProps {
    batch: IBatchResponseDTO
    parents: IBatchResponseDTO[]
    isExternalPage?: boolean
}

export function MergeTreeICP(props: IMergeTreeICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TextCP
                text={`${props.parents.length === 1 ? `${props.isExternalPage ? 'Splited' : 'Lote dividido'}` : `${props.isExternalPage ? 'Merged' : 'Lotes mesclados'}`}`}
                style={
                    {
                        fontWeight: '500 ',
                        marginBottom: '20px',
                        justifyContent: 'center',
                        display: 'flex'
                    }
                }
            />
            <TreeSCP>
                {
                    props.parents.map(parent => (
                        <CardWrapperSCP key={`${parent.code}_key`} className={'parents'} parentQtt={props.parents.length} isExternalPage={props.isExternalPage}>
                            {
                                props.isExternalPage ?
                                    <BatchTreeResumeCardICP2
                                        batch={parent}
                                        isMainBatch={false}
                                    />
                                    :
                                    <BatchTreeResumeCardICP
                                        batch={parent}
                                        isMainBatch={false}
                                    />
                            }
                        </CardWrapperSCP>
                    ))
                }
            </TreeSCP>
            {
                props.parents.length % 2 === 0 &&
                <ContectorLineSCP />
            }
            <MainSCP>
                <CardWrapperSCP key={`${props.batch.code}_key`} className={'child'} isExternalPage={props.isExternalPage}>
                    {
                        props.isExternalPage ?
                            <BatchTreeResumeCardICP2
                                batch={props.batch}
                                isMainBatch={true}
                            />
                            :
                            <BatchTreeResumeCardICP
                                batch={props.batch}
                                isMainBatch={true}
                            />
                    }
                </CardWrapperSCP>
            </MainSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
const TreeSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    min-height: 123px;
    margin-bottom: 70px;
    margin-top: 20px;
    row-gap: 8px;
`

const MainSCP = styled.div`
    display: flex;  
    align-items: center;
    justify-content: center;
`

const CardWrapperSCP = styled.div<{ parentQtt?: number, isExternalPage?: boolean }>`
    border: 1px solid ${props => props.theme.primaryColor};
    padding: 0px 10px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    min-height: ${props => (props.isExternalPage ? '125px' : '137px')};
    flex: 1 0 30%;
    z-index: 2;
    &.parents {
        &::after{      
            position: absolute;
            content: '';
            background: ${props => props.theme.primaryColor};
            width: 1px;
            height: 70px;
            top: ${props => (props.isExternalPage ? '125px' : '145px')};
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 1;
        }
    }
    &.child{
        border: 2px solid ${props => props.theme.primaryColor};
        max-width: 300px;

        &::after{      
            position: absolute;
            content: '';
            background: ${props => props.theme.primaryColor};
            width: 1px;
            height: 80px;
            bottom:${props => (props.isExternalPage ? '123px' : '135px')};
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 1;
        }
    }

    @media(max-width: 768px){
        &.parents{
            &:after{
                top: ${props => (props.isExternalPage ? '182px' : '195px')};
            }
        }
        &.child {          

            &:after{
                bottom: ${props => (props.isExternalPage ? '136px' : '167px')};
            }
        }
    }
   
`

const ContectorLineSCP = styled.div`
    background: ${props => props.theme.primaryColor};  
    width: 50%;

    height: 1px;  
    margin: 0 auto;
    margin-bottom: 80px;
    @media(max-width: 768px){
       
    }
`