import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { InvoiceTypeEnum } from 'modules/financial/enums/InvoiceType'

import { ISearchInvoicesReqDTO } from 'modules/financial/services/dtos/request/ISearchInvoicesReqDTO'
import moment from 'moment-timezone'
import { DateRangePickerCP } from 'common/components/date-range-picker/DateRangePickerCP'
import { DateUtils } from 'common/utils/DateUtils'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import { InvoiceStatusFilterICP } from 'modules/financial/components/invoice-filters/inner/InvoiceStatusFilterICP'
import { InvoiceStatusEnum } from 'modules/financial/enums/InvoiceStatusEnum'

interface IInvoiceFiltersCPProps {
    setFilters: (filters: ISearchInvoicesReqDTO) => void
    filters?: ISearchInvoicesReqDTO
    type: InvoiceTypeEnum
}

export function InvoiceFiltersCP(props: IInvoiceFiltersCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [status, setStatus] = useState<InvoiceStatusEnum>(InvoiceStatusEnum.ALL)

    useEffect(defineStatusFilter, [status])
    useEffect(getCurrentStatus, [props.filters])

    function getCurrentStatus(): void {
        if (!props.filters)
            return

        if (props.filters.isPaid === true)
            return setStatus(InvoiceStatusEnum.FINISHED)
        if (props.filters.isPaid === false)
            return setStatus(InvoiceStatusEnum.PENDING)
        return setStatus(InvoiceStatusEnum.ALL)
    }

    function defineStatusFilter(): void {

        switch (status) {
            case InvoiceStatusEnum.FINISHED:
                return props.setFilters({ ...props.filters, isPaid: true })
            case InvoiceStatusEnum.PENDING:
                return props.setFilters({ ...props.filters, isPaid: false })
            case InvoiceStatusEnum.ALL:
                return props.setFilters({ ...props.filters, isPaid: undefined })
            default:
                return props.setFilters({ ...props.filters, isPaid: undefined })
        }
    }

    return (
        <WrapperSCP>
            <GridWrapperSCP>
                <MainFilterWppSCP>
                    <SeasonSelectorCP
                        onChange={(season) => props.setFilters({ ...props.filters, season })}
                        value={props.filters?.season}
                    />
                    <DateRangePickerCP
                        placeholder={['Entre as datas ...', '']}
                        value={(!!props.filters?.startDate && !!props.filters?.endDate) ?
                            [moment(props.filters.startDate), moment(props.filters.endDate)] :
                            undefined}
                        onChange={(dates) => props.setFilters(
                            {
                                ...props.filters,
                                startDate: DateUtils.getDateFormattedToApi(moment(dates.beginDate).format('DD-MM-YYYY')),
                                endDate: DateUtils.getDateFormattedToApi(moment(dates.endDate).format('DD-MM-YYYY'))
                            }
                        )}
                    />
                </MainFilterWppSCP>
                <InvoiceStatusFilterICP
                    onChangeStatus={setStatus}
                    status={status}
                    type={props.type}
                />

            </GridWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .ant-typography{
        font-size: 12px;   
    }
    @media(max-width: 768px){
        margin-bottom: 5px;
    }

`
const GridWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 60% 39%;
    column-gap: 1%;
    align-items: center;
    @media(max-width: 768px){
        grid-template-columns:  100%;
    }
`
const MainFilterWppSCP = styled.div`
    display: grid;
    grid-template-columns: .3fr 1fr; 
    align-items: center;
    column-gap: 5px;
    @media(max-width: 768px){
        display: grid;
        column-gap: 10px;
        grid-template-columns: .5fr 1fr;
        align-items: center;
    }
    
`