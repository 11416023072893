import React from 'react'
import styled, { css, CSSProperties } from 'styled-components'

interface ITitleCPProps extends React.PropsWithChildren<{
    underLine?: boolean,
    textSize?: any,
    underLineSpacing?: boolean,
    color?: string,
    weight?: number,
    style?: CSSProperties,
}> { }

/**
 * COMPONTENTE
 * Titulo.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function TitleCP(props: ITitleCPProps): JSX.Element {
    return <TitleSCP {...props} />
}

const afterUnderline = css<ITitleCPProps>`
    ::after{
        content: '';
        width: 58px;
        height: 2px;
        position: absolute;
        background: ${props => props.theme.primaryColor};
        bottom: -10px;
        left: 0;
        border-radius: 80px;
    }
`

const TitleSCP = styled.h3<ITitleCPProps>`
    position: relative;
    color: ${props => (props.color ? props.color : props.theme.black)};
    font-weight: ${props => props.weight ?? 500};
    font-size: ${props => props.textSize || props.theme.fontSizes.extraLarge}px;
    margin: 0px 0px 10px 0px;
    ${props => (!!props.underLine ? afterUnderline : '')}
`
