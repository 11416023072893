import { NotificationHelper } from './../../../common/NotificationHelper'
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'

/**
 * UTILITARIOS: Permissoes de acesso a recursos do sistema.
 *
 */
export class PermissionUtils {

    static showDefaultDeniedAccessMsg(): void {
        NotificationHelper.warning('Ops', 'Você não tem permissão para acessar esse módulo, portanto será redirecionado')
    }

    static hasAccess(module?: SystemModulesEnum): boolean {

        // const usePlan = ReduxHelper.getInstance().getStore().getState().loggedUser.user.plan ?? []
        // const userPermissions = ReduxHelper.getInstance().getStore().getState().loggedUser.user.permissions ?? []

        // if (!userPermissions.modules)
        //     return true

        // if (!userPermissions.modules.length)
        //     return true
        // if (!usePlan.modules)
        //     return false

        // if (!module)
        //     return true

        // return userPermissions.modules.includes(module)
        return true
    }
}
