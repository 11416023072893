import { IsString, IsIn, ValidateIf, IsOptional } from 'class-validator'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { DateUtils } from 'common/utils/DateUtils'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { BatchMethodEnum } from 'modules/batch/enums/BatchProcessEnum'

import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { IHarvestLitersPerMaturation } from 'modules/harvest/interfaces/IHarvestLitersPerMaturation'

export class UpdateBatchFormValidator extends FormModelValidator {

    @IsOptional()
    @IsString()
    @IsIn(Object.values(BatchMethodEnum))
    method?: BatchMethodEnum

    @IsNotEmpty()
    @IsString()
    date: string

    @IsOptional()
    litersPerMaturation?: IHarvestLitersPerMaturation

    @IsNotEmpty()
    season?: number

    @IsOptional()
    @IsString()
    farmId?: string

    constructor(dto?: IBatchResponseDTO) {
        super()
        if (dto) {
            this.method = dto.method
            this.date = DateUtils.getFormatted(dto.date, DateFormatEnum.BR_WITHOUT_TIME)
            this.season = dto.season
            this.farmId = dto.farmId
            this.litersPerMaturation = dto.litersPerMaturation
        }

    }
}
