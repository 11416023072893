import { faUserPlus, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import React, { useState } from 'react'
import { PersonDrawerCP } from '../components/person-drawer/PersonDrawerCP'
import { PersonListCP } from '../components/person-list/PersonListCP'

export function PersonScreen(): JSX.Element {
    const [showDrawer, setShowDrawer] = useState<boolean>(false)

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Equipe'} icon={<FontAwesomeIcon icon={faUsers} />}>
                    <ButtonCP
                        style={{ border: 'none' }}
                        size={'middle'}
                        type={'ghost'}
                        icon={<FontAwsomeIconCP icon={faUserPlus} />}
                        onClick={() => {
                            setShowDrawer(true)
                        }}
                    >
                        Novo membro
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <PersonListCP />
                    <PersonDrawerCP
                        show={showDrawer}
                        onSuccess={() => {
                            setShowDrawer(false)
                        }}
                        onCancel={() => {
                            setShowDrawer(false)
                            ListActions.mustUpdate()
                        }}
                    />
                </ContentCP>
            }
        />
    )
}

