import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { ILogbookItemResponseDTO } from 'modules/logbook/services/dtos/response/ILogbookItemResponseDTO'
import { LogbookItemPreviewCP } from 'modules/maneuver/components/logbook-item-preview/LogbookItemPreviewCP'
import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'
import { PriorityEnum } from 'modules/maneuver/enums/PriorityEnum'
import { ManeuverUtils } from 'modules/maneuver/utils/ManeuverUtils'

interface IManeuverCardContenICPProps {
    startDate: string
    estimateEndDate: string
    realEndDate?: string
    lastLogbookEntry?: ILogbookItemResponseDTO
    season?: number
    recipeType: RecipeTypeEnum
    priority?: PriorityEnum
}

/**
 * COMPONENTE
 * Conteudo central de um item da lista de atividades agrícolas
 */
export function ManeuverCardContenICP(props: IManeuverCardContenICPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }
    return (
        <WrapperSCP>
            {
                props.priority &&
                <RowSCP>
                    <TextCP text={'Prioridade: '} />
                    <TextCP
                        text={ManeuverUtils.translatePriority(props.priority)}
                        style={{
                            textTransform: 'uppercase',
                            fontWeight: '600',
                            marginLeft: '5px',
                            color: `${ManeuverUtils.getPriorityColor(props.priority)}`
                        }}
                    />
                </RowSCP>
            }
            <RowSCP>
                <TextCP text={'Iniciado em: '} />
                <TextCP text={props.startDate} style={valueStyle} />
            </RowSCP>
            <RowSCP>
                <TextCP text={'Estimativa de conclusão: '} />
                <TextCP text={props.estimateEndDate} style={valueStyle} />
            </RowSCP>
            {
                props.realEndDate &&
                <RowSCP>
                    <TextCP text={'Concluído em: '} />
                    <TextCP text={props.realEndDate} style={valueStyle} />
                </RowSCP>
            }
            {!!props.season &&
                <RowSCP>
                    <TextCP text={'Safra: '} />
                    <TextCP text={`${props.season}`} style={valueStyle} />
                </RowSCP>}
            <LastEntryWrapperSCP>
                <TextCP text={'Último registro: '} />
                <LogbookItemPreviewCP
                    logBookItem={props.lastLogbookEntry}
                    recipeType={props.recipeType}
                />
            </LastEntryWrapperSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 0 10px;
    min-height: 170px;
    
`
const RowSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    margin-bottom: 5px;
    .ant-typography{
        font-size: 13px;
    }
`
const LastEntryWrapperSCP = styled.div`
    margin-top: 12px;
    .ant-typography{
        font-size: 13px;
    }
`