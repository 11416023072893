import { isValid as isValidCNPJ } from '@fnando/cnpj/commonjs'
import { isValid as isValidCPF } from '@fnando/cpf/commonjs'
import { Form, Radio } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import React, { useEffect, useState } from 'react'
import { CnpjInput } from '../../../../../common/components/form-inputs/CnpjInput'
import { CpfInput } from '../../../../../common/components/form-inputs/CpfInput'

interface IProps {
    onNext: (values) => void
    values?: { legal: boolean, cpfCnpj: string }
}

export function RegistrationPrimaryDataStep(props: IProps): JSX.Element {
    const [form] = Form.useForm()
    const [legal, setLegal] = useState(false)

    useEffect(() => {
        if (!!props.values)
            setLegal(props.values.legal)
    }, [])

    return (
        <Form
            form={form}
            initialValues={{
                legal: false,
                ...props.values
            }}
            // layout={'vertical'}
            name={'primaryData'}
            onFinish={(values) => {
                props.onNext(values)
            }}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name)
            }}
            onValuesChange={(values) => {
                setLegal(values.legal ?? legal)
            }}
        >
            <Form.Item
                label={'Tipo:'}
                name={'legal'}
                rules={[{ required: true, message: 'Campo Obrigatório!' }]}
            >
                <Radio.Group>
                    <Radio value={false}>Pessoa Física</Radio>
                    <Radio value={true}>Pessoa Jurídica</Radio>
                </Radio.Group>
            </Form.Item>

            {
                !legal &&
                <Form.Item
                    label={'CPF'}
                    name={'cpfCnpj'}
                    validateFirst={true}
                    rules={[{ required: true, message: 'Campo Obrigatório' }, {
                        validator: async (rule, value) => {
                            if (!isValidCPF(value))
                                return Promise.reject('Cpf Inválido')
                            return Promise.resolve()
                        },
                    }]}
                >
                    <CpfInput />
                </Form.Item>
            }

            {
                legal &&
                <Form.Item
                    label={'CNPJ'}
                    name={'cpfCnpj'}
                    validateFirst={true}
                    rules={[{ required: true, message: 'Campo Obrigatório' }, {
                        validator: async (rule, value) => {
                            if (!isValidCNPJ(value))
                                return Promise.reject('Cnpj Inválido')
                            return Promise.resolve()
                        }
                    }]}
                >
                    <CnpjInput />
                </Form.Item>
            }

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonCP type={'primary'} isSubmit={true}>
                    Próximo
                </ButtonCP>
            </div>
        </Form>
    )
}