import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'

import { RecipeTypeEnum } from './enums/RecipeTypeEnum'

export class ManeuverActions {

    static readonly TYPE_CHILD_MENU_OPENED = 'TYPE_CHILD_MENU_OPENED'
    static readonly TYPE_INSTRUCTIONS_TYPE_FILTERED = 'TYPE_INSTRUCTIONS_TYPE_FILTERED'

    private constructor() { /* private constructor to avoid instantiation */ }

    static toggleChildMenu(opened: boolean): void {
        ReduxHelper.getInstance().getStore()
            .dispatch({ type: this.TYPE_CHILD_MENU_OPENED, payload: opened })
    }

    static setInstructionTypeFilter(category: RecipeTypeEnum | null): void {
        ReduxHelper.getInstance().getStore()
            .dispatch({ type: this.TYPE_INSTRUCTIONS_TYPE_FILTERED, payload: category })
    }

}
