import { ButtonCP } from 'common/components/button/ButtonCP'

import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'

import { BatchProcessMethodSelectorCP } from 'modules/batch/components/batch-process-method-selector/BatchProcessMethodSelectorCP'
import { BatchProcessTypeSelectorCP } from 'modules/batch/components/batch-process-type-selector/BatchProcessTypeSelectorCP'
import { ISearchBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISearchBatchRequestDTO'
import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import { StructureTypeSelectorCP } from 'modules/structure/components/structure-type-selector/StructureTypeSelectorCP'
import React, { useState, useEffect } from 'react'
import { CheckboxCP } from 'common/components/form-fields/checkbox/CheckboxCP'
import { SwitchCP } from 'common/components/switch/SwitchCP'
import styled from 'styled-components'

import { BatchMethodSelectorCP } from 'modules/batch/components/batch-method-selector/BatchMethodSelectorCP'
interface IBatchFiltersDrawerCPProps {
    show: boolean
    onClose: () => void
    setFilters: (filters: ISearchBatchRequestDTO) => void
    filters?: ISearchBatchRequestDTO
}

export function BatchFiltersDrawerCP(props: IBatchFiltersDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [filters, setFilters] = useState<ISearchBatchRequestDTO>()

    useEffect(setFilterValues, [props.filters])

    function setFilterValues(): void {
        if (!props.filters)
            return
        setFilters(props.filters)
    }

    return (
        <DrawerCP
            title={'Mais filtros'}
            visible={props.show}
            width={'35%'}
            onClose={props.onClose}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'primary'}
                        wrapperStyle={{ width: '100%' }}
                        onClick={() => {
                            if (!!filters)
                                props.setFilters(filters)
                            props.onClose()
                        }}
                    >
                        Filtrar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >
            <WrapperSCP>

                {
                    screenSize.smd &&
                    <OtherFiltersWppSCP>
                        <SeasonSelectorCP
                            label={'Safra'}
                            onChange={(season) => setFilters({ ...filters, season })}
                            value={filters?.season}
                        />
                        <InputCP
                            label={'Cód'}
                            placeholder={'Cód'}
                            onChange={(batchId) => setFilters({ ...filters, batchId })}
                            type={'number'}
                            value={filters?.batchId}
                        />

                        <InputCP
                            label={'Cód. da propriedade'}
                            placeholder={'Cód. da propriedade'}
                            onChange={(farmId) => setFilters({ ...filters, farmId })}
                            value={filters?.farmId}
                        />

                        <PropertyFieldFieldsCP
                            onChangeProperty={(propertyCode) => setFilters({ ...filters, propertyCode })}
                            onChangeField={(fieldCode) => setFilters({ ...filters, fieldCode })}
                            propertyCode={filters?.propertyCode}
                            fieldCode={filters?.fieldCode}
                        />
                    </OtherFiltersWppSCP>

                }



                <BatchMethodSelectorCP
                    label={'Método'}
                    onSelect={(method) => setFilters({ ...filters, method })}
                    value={filters?.method}
                />
                <StructureTypeSelectorCP
                    label={'Tipo de estrutura'}
                    onSelect={(structureType) => setFilters({ ...filters, structureType })}
                    value={filters?.structureType}
                />
                <BatchProcessTypeSelectorCP
                    label={'Etapa do processo'}
                    onSelect={(processType) => setFilters({ ...filters, processType })}
                    value={filters?.processType}
                />
                <BatchProcessMethodSelectorCP
                    label={'Processamento'}
                    onSelect={(processMethod) => setFilters({ ...filters, processMethod })}
                    value={filters?.processMethod}
                />
                <TwoColWrapperSCP>

                    <InputCP
                        label={'Umidade min.'}
                        placeholder={'Umidade min.'}
                        onChange={(minHumidity) => setFilters({ ...filters, minHumidity })}
                        value={filters?.minHumidity}
                    />

                    <InputCP
                        label={'Umidade máx.'}
                        placeholder={'Umidade máx.'}
                        onChange={(maxHumidity) => setFilters({ ...filters, maxHumidity })}
                        type={'number'}
                        value={filters?.maxHumidity}
                    />

                </TwoColWrapperSCP>
                <TwoColWrapperSCP>

                    <InputCP
                        label={'Pontuação min.'}
                        placeholder={'Pontuação min.'}
                        onChange={(minScore) => setFilters({ ...filters, minScore })}
                        value={filters?.minScore}
                    />
                    <InputCP
                        label={'Pontuação máx.'}
                        placeholder={'Pontuação máx.'}
                        onChange={(maxScore) => setFilters({ ...filters, maxScore })}
                        type={'number'}
                        value={filters?.maxScore}
                    />

                </TwoColWrapperSCP>
                <TwoColWrapperSCP>
                    <InputCP
                        label={'Catação min'}
                        placeholder={'Catação min'}
                        onChange={(minPicking) => setFilters({ ...filters, minPicking })}
                        value={filters?.minPicking}
                    />
                    <InputCP
                        label={'Catação máx.'}
                        placeholder={'Catação máx.'}
                        onChange={(maxPicking) => setFilters({ ...filters, maxPicking })}
                        type={'number'}
                        value={filters?.maxPicking}
                    />
                </TwoColWrapperSCP>

                <CheckRowSCP>
                    <CheckboxCP
                        label={'Possui Análise Sensorial'}
                        isChecked={filters?.hasSensoryAnalysis ?? false}
                        onChange={() => setFilters({ ...filters, hasSensoryAnalysis: !filters?.hasSensoryAnalysis })}
                    />

                    {filters?.hasSensoryAnalysis && (
                        <SwitchWrapperSCP onClick={() => setFilters({ ...filters, notSpecial: !filters?.notSpecial })}>
                            <SwitchCP
                                isChecked={!filters?.notSpecial}
                                isTextInside={true}
                                textInsideChecked={'Especial'}
                                textInsideUnchecked={'Não especial'}
                            />
                        </SwitchWrapperSCP>)}
                </CheckRowSCP>

                <CheckRowSCP>
                    <CheckboxCP
                        label={'Disponível para venda'}
                        isChecked={filters?.hasAvailableBags ?? false}
                        onChange={() => setFilters({ ...filters, hasAvailableBags: !filters?.hasAvailableBags })}
                    />

                </CheckRowSCP>

            </WrapperSCP>

        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`
const CheckRowSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
`
const OtherFiltersWppSCP = styled.div`

`
const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
`

const ButtonWrapperSCP = styled.div`
     display: flex;
    justify-content: center;
    .ant-btn {
        width: 100%;
        justify-content: center;
    }
`
const SwitchWrapperSCP = styled.div`
    justify-self: right;
`