import FileViewer from 'react-file-viewer'
import React from 'react'
import styled from 'styled-components'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { IDocumentResponseDTO } from 'modules/property/services/dtos/responses/IDocumentResponseDTO'

interface IDocumentPreviewDrawerCPProps{
    visible: boolean
    onClose: () => void
    document: IDocumentResponseDTO
}

export function DocumentPreviewDrawerCP(props: IDocumentPreviewDrawerCPProps): JSX.Element {
    return (
        <DrawerCP
            title={'Pré-visualização do documento'}
            visible={props.visible}
            width={'80%'}
            onClose={props.onClose}
        >
            <WrapperSCP>
                <FileViewer
                    fileType={props.document.type}
                    filePath={props.document.src}
                    errorComponent={<div>Erro ao carregar arquivo</div>}
                />
            </WrapperSCP>

        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
    width: 78vw;
    height: 80vh;
    @media(max-width: 768px){
        width: 50vh;
        height: 90vh;
    }

`
