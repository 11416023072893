import { Button, Typography } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import React from 'react'
import { AppleIcon } from '../../../../../assets/icons/Apple'
import { PlayStoreIcon } from '../../../../../assets/icons/PlayStore'
import { WebIcon } from '../../../../../assets/icons/Web'
const { Text } = Typography

interface IProps {
    onNext: (values?: any) => void
}

export function RegistrationInstructionsData(props: IProps): JSX.Element {

    return (
        <>
            <div className={'top-text'}>
                <Text>
                    Com o
                    <b> Gerente Flowins</b>
                    , você tem o seu café rastreado e analisado em nossa plataforma exclusiva.
                    <br />
                    Além disso, oferecemos consultoria comercial para garantir as
                    melhores vendas. Tudo isso sem fidelização ou corretagem.
                    <br />
                    Potencialize os seus resultados produtivos e financeiros, reinvente a gestão do seu café.
                    <br />
                    Abra já sua
                    <b> Conta Digital Flowins </b>
                    para assinar nosso
                    <b> Gerente Flowins! </b>
                </Text>
            </div>
            <div className={'badge-buttons'}>
                <Button
                    href={'https://play.google.com/store/apps/details?id=br.com.bbnk.whitelabel34'}
                    target={'_blank'}
                    type={'link'}
                    className={'btn'}
                >
                    <PlayStoreIcon />
                    <Text className={'btn-text'}>Disponível no</Text>
                    <Text className={'btn-storename'}>Google Play</Text>
                </Button>
                <Button
                    href={'https://apps.apple.com/br/app/conta-flowins/id1488604070?uo=4&at=11l6hc&app=itunes'}
                    target={'_blank'}
                    type={'link'}
                    className={'btn'}
                >
                    <AppleIcon />
                    <Text className={'btn-text'}>Baixe na</Text>
                    <Text className={'btn-storename'}>App Store</Text>
                </Button>
                <Button
                    href={'https://ib.contaflowins.flowins.me/registro'}
                    target={'_blank'}
                    type={'link'}
                    className={'btn'}
                >
                    <WebIcon />
                    <Text className={'btn-text'}>Acesse o</Text>
                    <Text className={'btn-storename'}>Internet Banking</Text>
                </Button>
            </div>

            <div className={'bottom-text'}>
                <Text>Após ter aberto sua conta, clique em Iniciar Cadastro para se inscrever em nossa plataforma!</Text>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <ButtonCP type={'primary'} onClick={props.onNext} size={'large'}>
                    Iniciar Cadastro
                </ButtonCP>
            </div>
        </>
    )
}