import { Progress } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface IProgressCPProps {
    percent: number
    showInfo: boolean
    size?: 'default' | 'small'
    width?: number
}

/**
 * Componente com barra de progresso
 *
 * @author renatofs
 */
export function ProgressBarCP(props: IProgressCPProps): JSX.Element {

    return (
        <ProgressSCP>
            <Progress
                percent={props.percent}
                showInfo={props.showInfo}
                strokeLinecap={'square'}
                size={props.size}
                strokeWidth={props.width ?? 14}
            />
        </ProgressSCP>
    )
}

const ProgressSCP = styled.div`

    width: 100%;

    .ant-progress-inner {
        background-color: #857979;
        border-radius: 3px;
    }
    .ant-progress-success-bg, .ant-progress-bg{
        background-color: ${props => props.theme.primaryColor};
    }
`
