import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { TextCP } from 'common/components/text/TextCP'
import { SystemConfig } from 'config/SystemConfig'
import React from 'react'
import styled from 'styled-components'

interface ISchemaNotFoundScreenProps {
}

export function SchemaNotFoundScreen(props: ISchemaNotFoundScreenProps): JSX.Element {
    const location = window.location.href

    return (
        <WrapperSCP>
            <NotFoundSCP>
                Ops! Página não encontrada
            </NotFoundSCP>
            <RoutSCP>
                {location}
            </RoutSCP>
            <TextWppSCP>
                <TextSCP>Não encontramos a página que você está tentando acessar.</TextSCP>
                <TextSCP>Caso esteja tentando acessar um subdomínio Flowins, verifique se você digitou corretamente a url.</TextSCP>
                <TextSCP>(ex.: https://sistema.flowins.me/seudominio)</TextSCP>
                <TextSCP>Se não for o seu caso, tente fazer o login no sistema novamente.</TextSCP>
                <ButtonCP
                    type={'ghost'}
                    onClick={() => window.location.href = `${SystemConfig.getInstance().uiBaseUrl}/entrar`}
                    icon={<FontAwsomeIconCP icon={faArrowLeft} />}
                    wrapperStyle={{ marginTop: '3em' }}
                >
                    Ir para login
                </ButtonCP>
            </TextWppSCP>

        </WrapperSCP>
    )
}
const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  center;
    height: 100vh;
    background: #7909ad;

`
const NotFoundSCP = styled.div`
    font-size: 40px;
    color:  white;
    font-weight: 600;
`

const RoutSCP = styled.div`
    font-size: 15px;
    font-style: italic;
    font-weight: 600;
    color:  white;
    margin: 10px 0;
`

const TextWppSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    .ant-btn{
        color:#7909ad;
    }
   
`


const TextSCP = styled.div`
   color: white;
`