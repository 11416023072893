export enum BatchProcessEventEnum {
    HARVEST = 'Colheita',
    POST_HARVEST_METHOD = 'Pós-colheita - Método',
    POST_HARVEST_PROCESS = 'Pós-colheita - Processo',
    BENEFIT = 'Beneficio',
    STORAGE = 'Armazenagem',
    PARENT_MERGE_BATCHES = 'Gerado de: Mescla de Lotes',
    PARENT_SPLIT_BATCH = 'Gerado de: Divisão de Lote',
    CHILD_MERGE_BATCHES = 'Lote Mesclado',
    CHILD_SPLIT_BATCH = 'Lote dividido',
}