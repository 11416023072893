import { faFilter, faSort } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { BatchFiltersDrawerCP } from 'modules/batch/components/batch-list-filters/BatchFiltersDrawerCP'
import { OrderingOptionsICP } from 'modules/batch/components/batch-list-filters/inner/OrderingOptionsICP'
import { ISearchBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISearchBatchRequestDTO'
import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import React, { useState } from 'react'
import styled from 'styled-components'

interface IMainFilterFieldsICPProps {
    onChangeSeason: (season: number) => void
    onChangeProperty: (propertyCode: number) => void
    onChangeField: (fieldCode: number) => void
    onChangeBatchCodes: (batchCodes: number[]) => void
    onChangeFarmId: (farmId: string) => void
    onChangeCode: (batchCode: number) => void
    filters?: ISearchBatchRequestDTO
    setFilters: (filters: ISearchBatchRequestDTO) => void
}

export function MainFilterFieldsICP(props: IMainFilterFieldsICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [filterDrawerVisible, setFilterDrawerVisible] = useState<boolean>(false)

    return (
        <WrapperSCP>
            <GridWrapperSCP>
                <SeasonSelectorCP
                    label={'Safra'}
                    onChange={props.onChangeSeason}
                    value={props.filters?.season}
                />
                {
                    !screenSize.smd &&
                    <OtherFiltersWppSCP>
                        <InputCP
                            placeholder={'Cód'}
                            onChange={props.onChangeCode}
                            type={'number'}
                            value={props.filters?.batchId}
                        />

                        <InputCP
                            placeholder={'Cód. da propriedade'}
                            onChange={props.onChangeFarmId}
                            value={props.filters?.farmId}
                        />

                        <PropertyFieldFieldsCP
                            propertyCode={props.filters?.propertyCode}
                            fieldCode={props.filters?.fieldCode}
                            onChangeProperty={props.onChangeProperty}
                            onChangeField={props.onChangeField}
                        />
                    </OtherFiltersWppSCP>

                }
                <ButtonCP
                    onClick={() => setFilterDrawerVisible(true)}
                    type={'primary'}
                    style={{ width: 50, height: 50, zIndex: 1, borderRadius: '100%' }}
                    icon={<FontAwsomeIconCP icon={faFilter} color={'#fff'} />}
                />
                <PopOverCP
                    placement={'left'}
                    trigger={'click'}
                    content={
                        <OrderingOptionsICP
                            filters={props.filters}
                            setFilters={props.setFilters}
                        />
                    }
                >
                    <ButtonCP
                        type={'primary'}
                        style={{ width: 50, height: 50, zIndex: 1, borderRadius: '100%' }}
                        icon={<FontAwsomeIconCP icon={faSort} color={'#fff'} />}
                    />
                </PopOverCP>
            </GridWrapperSCP>
            <BatchFiltersDrawerCP
                show={filterDrawerVisible}
                onClose={() => setFilterDrawerVisible(false)}
                filters={props.filters}
                setFilters={props.setFilters}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 3px;
`

const GridWrapperSCP = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: .8fr 4.8fr .2fr .2fr;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;
    align-items: center;

    label, .ant-typography div{
      display: none;
    }

    @media(max-width: 767px){
        grid-template-columns: 1fr .2fr .2fr;
        .ant-row .ant-form-item-label > label{
             display: none;
         }
    }
`
const OtherFiltersWppSCP = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: .8fr 1.5fr 4.8fr;
    column-gap: 10px;
`
const StatusSCP = styled.div`
    @media(max-width: 767px){
       display: none;
    }

`