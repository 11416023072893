
import { SliderCarouselCP } from 'common/components/slider-carousel/SliderCarouselCP'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { BatchPresentationRequests } from 'modules/batch-presentation/services/BatchPresentationRequests'
import { IProducerResumeRedDTO } from 'modules/batch-presentation/services/dtos/response/IProducerResumeRedDTO'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ProducerContentICP } from '../producer-carrousel/inner/ProducerContentICP'
type RouteParamsTP = { batchCode: string }
interface IPropertyResumeCPProps {

}

export function PropertyResumeCP(props: IPropertyResumeCPProps): JSX.Element {
    const routeParams = useParams<RouteParamsTP>()
    const screenSize = useScreenSize()
    const [resume, setResume] = useState<IProducerResumeRedDTO[]>()
    const [allPictures, setAllPictures] = useState<string[]>([])

    const [batchCode, setBatchCode] = useState<number>()
    useEffect(() => setBatchCode(+routeParams.batchCode), [routeParams])

    const getInfoReq = useRequest<IProducerResumeRedDTO[]>()
    useEffect(onRequestChange, [getInfoReq.awaiting])

    useEffect(getDetails, [batchCode])

    function getDetails(): void {
        if (!!batchCode)
            getInfoReq.runRequest(BatchPresentationRequests.getProducerResume(batchCode))
    }

    function onRequestChange(): void {

        if (getInfoReq.awaiting || !getInfoReq.tried)
            return

        if (!getInfoReq.success && !getInfoReq.returnData) {
            if (getInfoReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getInfoReq.returnData, getInfoReq.error)
            return RequestUtils.showDefaultErrorNotification(getInfoReq.error, 'Some error occurred. Please, try to reload the page.')
        }

        setResume(getInfoReq.returnData)
        if (!!getInfoReq.returnData && !!getInfoReq.returnData.length)
            setAllPictures(_.flatten(getInfoReq.returnData.map(item => item.pictures)))
    }

    if (!resume || getInfoReq.awaiting) {
        return (
            <div></div>
        )
    }
    return (
        <WrapperSCP>
            <TextCP
                text={'knowProducer'}
                style={{ fontSize: '18px', fontWeight: 600, color: 'white', marginLeft: screenSize.smd ? '1em' : '2.5em' }}
                translationFiles={'batch'}
                translationNameSpace={'batch'}

            />
            <SliderCarouselCP
                slidesToShow={1}
                slidesToScroll={1}
                dots={true}
                infinite={true}
                speed={500}
                draggable={true}
            >
                {
                    resume.map(item => (<ProducerContentICP key={item.code} producer={item} />))
                }

            </SliderCarouselCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

`