import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { useRequest } from 'common/request-helper/UseRequest'
import { IListNameAndCodePropertyResponseDTO } from 'modules/property/services/dtos/responses/IListNameAndCodePropertyResponseDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { PropertyRequests } from 'modules/property/services/PropertyRequests'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'

interface IPropertyFieldFieldsCPProps<FModelTP extends FormModelValidator = any> {
    onChangeProperty?: (propertyCode: number) => void
    propertyCode?: number
    onChangeField?: (fieldCode: number, totalArea?: number) => void
    fieldCode?: number
    onClear?: () => void
    formStateManager?: IFormStateManager<FModelTP>
    propertyFieldName?: string
    fieldFieldName?: string
    selectFirstAsDefault?: boolean
    required?: boolean
    hideField?: 'property' | 'field'
}

export function PropertyFieldFieldsCP<FModelTP extends FormModelValidator = any>(props: IPropertyFieldFieldsCPProps<FModelTP>): JSX.Element {
    const screenSize = useScreenSize()

    const [propertyList, setPropertyList] = useState<IListNameAndCodePropertyResponseDTO[]>([])
    const [propertyOptions, setPropertyOptions] = useState<SelectOptionTP[]>([])
    const [fieldOptions, setFieldOptions] = useState<SelectOptionTP[]>([])

    const searchPropertiesRequest = useRequest<IGenericListResponseDTO<IListNameAndCodePropertyResponseDTO>>()
    useEffect(onRequestChange, [searchPropertiesRequest.awaiting])

    useEffect(getPropertyList, [])
    useEffect(getFieldList, [propertyList])

    function getPropertyList(): void {
        searchPropertiesRequest.runRequest(PropertyRequests.searchNames())
    }

    function onRequestChange(): void {

        if (searchPropertiesRequest.awaiting || !searchPropertiesRequest.tried)
            return

        if (!searchPropertiesRequest.success || !searchPropertiesRequest.returnData)
            return

        setPropertyList(searchPropertiesRequest.returnData.list)
        setPropertyOptions(searchPropertiesRequest.returnData.list.map(opt => ({
            value: opt.code,
            label: opt.name
        })))

        if (!!props.selectFirstAsDefault && !!searchPropertiesRequest.returnData.list.length)
            onSelectProperty(searchPropertiesRequest.returnData.list[0].code)
    }

    function getFieldList(): void {
        if (!propertyList.length)
            return

        const fields = _.flatten(propertyList.map(prt => prt.fields))
        setFieldOptions(fields.map(fie => ({
            value: fie.code,
            label: fie.name
        })))
    }

    function onSelectProperty(propertyCode: number): void {
        if (!!props.onChangeProperty)
            props.onChangeProperty(propertyCode)

        const property = propertyList.find(prpt => prpt.code === propertyCode)
        const fields = !!property && property.fields
        if (!!fields) {
            setFieldOptions([
                {
                    value: undefined,
                    label: 'Todos'
                },
                ...fields.map(opt => ({
                    value: opt.code,
                    label: opt.name
                }))
            ])
        }
    }

    function onSelectField(fieldCode: number): void {
        const fields = _.flatten(propertyList.map(prt => prt.fields))
        const selectedField = fields.find(fie => fie.code === fieldCode)
        if (!!props.onChangeField)
            props.onChangeField(fieldCode, selectedField?.totalArea)
    }

    return (
        <WrapperSCP>
            {
                !(props.hideField === 'property') &&
                <SelectCP
                    showSearch={true}
                    label={'Propriedade'}
                    options={propertyOptions}
                    placeholder={'Propriedade'}
                    onChange={onSelectProperty}
                    value={props.propertyCode}
                    fieldName={props.propertyFieldName}
                    formStateManager={props.formStateManager}
                    required={props.required}
                    loading={searchPropertiesRequest.awaiting}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    onClear={props.onClear}
                    allowClear={!!props.onClear}
                />
            }
            {
                !screenSize.smd && !props.hideField &&
                <FontAwsomeIconCP icon={faArrowRight} />
            }
            {
                !(props.hideField === 'field') &&
                <SelectCP
                    label={'Talhão'}
                    options={fieldOptions}
                    showSearch={true}
                    placeholder={'Talhão'}
                    fieldName={props.fieldFieldName}
                    onChange={onSelectField}
                    value={props.fieldCode}
                    formStateManager={props.formStateManager}
                    required={props.required}
                    loading={searchPropertiesRequest.awaiting}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    disabled={_.isEmpty(fieldOptions)}
                    onClear={props.onClear}
                    allowClear={!!props.onClear}
                />
            }
            {/* { !!props.onClear &&
                <FontAwsomeIconCP icon={faTimesCircle} onClick={props.onClear} />
            } */}
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr .1fr 1fr ;
    column-gap: 10px;
    align-items: end;
    svg {
        margin-bottom: 18px;
        color: ${props => props.theme.primaryColor};
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr ;
    }
`
