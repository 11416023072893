import React from 'react'
import styled from 'styled-components'
import { MaskUtils } from 'common/utils/MaskUtils'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { TextCP } from 'common/components/text/TextCP'

interface ICollapseCPProps {
    score: number
    language?: LanguageEnum
}

export function AnalysesScoreCP(props: ICollapseCPProps): JSX.Element {
    const title = props.language === LanguageEnum.EN ? 'Average score:' : 'Pontuação média: '
    const scoreText = props.language === LanguageEnum.EN ? 'points' : 'pontos'
    const notSpecialText = props.language === LanguageEnum.EN ? 'Not rated as special' : 'Não pontuou como especial'

    const isSpecial = (props.score ?? 0) > 80
    const score = isSpecial ? MaskUtils.applyNumberMask(props.score) : notSpecialText

    if (props.language && !isSpecial)
        return <></>

    return (
        <WrappeSCP>
            <TextCP text={title} style={{ fontSize: '12px' }} />
            <PointsSCP>
                <TextCP text={score} style={{ fontSize: '23px', lineHeight: '23px' }} />
                {
                    isSpecial &&
                    scoreText &&
                    <TextCP text={scoreText} style={{ fontSize: '12px' }} />
                }
            </PointsSCP>
        </WrappeSCP>
    )
}

const WrappeSCP = styled.div`
   
    .ant-typography{
        color: ${props => props.theme.primaryColor};
    }
   
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const PointsSCP = styled.div`
    font-size: ${props => props.theme.fontSizes.extraLarge};
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    column-gap: 5px;
    margin-top: 10px;
    @media(max-width: 767px){
        font-size: ${props => props.theme.fontSizes.normal};
    }
`
