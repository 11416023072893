import React from 'react'
import styled from 'styled-components'
import { IFieldReportResDTO } from 'modules/report/services/dtos/responses/IFieldReportResDTO'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faSeedling } from '@fortawesome/free-solid-svg-icons'
import { TextCP } from 'common/components/text/TextCP'
import { FieldUtils } from 'modules/field/utils/FieldUtils'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'

interface IFieldDetailWidgetCPProps {
    field?: IFieldReportResDTO
    gridArea?: string
    loading: boolean
}

export function FieldDetailWidgetCP(props: IFieldDetailWidgetCPProps): JSX.Element {

    if (props.loading)
        return <WidgetWrapperSCP><GenericRowSkeletonICP rows={3} columns={2} /></WidgetWrapperSCP>

    return (
        <WidgetWrapperSCP style={{ gridArea: props.gridArea ?? undefined }}>
            {
                props.field
                    ? (
                        <div>
                            <HeaderWrapperSCP>
                                <TitleSCP>{props.field.name}</TitleSCP>
                                <IconWrapperSCP><FontAwsomeIconCP icon={faSeedling} style={{ fontSize: '25px' }} /></IconWrapperSCP>
                            </HeaderWrapperSCP>
                            <DetailWrapperSCP>
                                <RowDetailsSCP>
                                    <TextCP style={{ fontWeight: 'bold', fontSize: '12px' }} text={'Variedade: '} />
                                    <TextCP style={{ marginLeft: '5px', fontSize: '12px' }} text={FieldUtils.translateVarietysEnum(props.field.variety)} />
                                </RowDetailsSCP>
                                <RowDetailsSCP>
                                    <TextCP style={{ fontWeight: 'bold', fontSize: '12px' }} text={'Ano do plantão: '} />
                                    <TextCP style={{ marginLeft: '5px', fontSize: '12px' }} text={props.field.plantingYear} />
                                </RowDetailsSCP>
                                <RowDetailsSCP>
                                    <TextCP style={{ fontWeight: 'bold', fontSize: '12px' }} text={'Área total de produção: '} />
                                    <TextCP style={{ marginLeft: '5px', fontSize: '12px' }} text={`${props.field.productionArea} ha`} />
                                </RowDetailsSCP>
                                <RowDetailsSCP>
                                    <TextCP style={{ fontWeight: 'bold', fontSize: '12px' }} text={'Total de pés: '} />
                                    <TextCP style={{ marginLeft: '5px', fontSize: '12px' }} text={props.field.totalTrees} />
                                </RowDetailsSCP>
                            </DetailWrapperSCP>
                        </div>)
                    : <TextCP text={'Sem resultados'} />
            }

        </WidgetWrapperSCP>
    )

}

const WidgetWrapperSCP = styled.div`
    padding: 20px;
    background-color: ${props => props.theme.white};
    border-radius: 5px;
`

const TitleSCP = styled.span`
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.theme.primaryColor};
    flex: 1;
`

const HeaderWrapperSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const DetailWrapperSCP = styled.div`

`
const RowDetailsSCP = styled.div`
    display: flex;
    padding: 2px;
`

const IconWrapperSCP = styled.div`
    background-color: ${props => props.theme.primaryColor};
    border-radius: 50px;
    max-height: 70px;
    padding: 15px;

    svg {
        color: white;
        align-content: center;
        margin: 0;
    }
`

