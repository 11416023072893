/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-confusing-arrow */
import { ButtonCP } from 'common/components/button/ButtonCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { PropertyFormValidator } from 'modules/property/components/property-form/inner/PropertyFormValidator'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NotificationHelper } from '../../../../common/NotificationHelper'
import { PropertyActions } from '../../PropertyActions'
import { ISavePropertyRequestDTO } from '../../services/dtos/requests/ISavePropertyRequestDTO'
import { IPropertyResponseDTO } from '../../services/dtos/responses/IPropertyResponseDTO'
import { PropertyRequests } from '../../services/PropertyRequests'
import { PropertyMainImagePickerICP } from 'modules/property/components/property-details/inner/PropertyMainImagePickerICP'

const MAX_FILE_SIZE = 5
interface IProps {
    onSuccess: (code?: number) => void
    property?: IPropertyResponseDTO

}

export function PropertyFormCP(props: IProps): JSX.Element {
    const screenSize = useScreenSize()
    const [imgFile, setImgFile] = useState<File>()

    const [imgValid, setImageValid] = useState<boolean>(true)
    const [imgUrl, setImgUrl] = useState<string | undefined>(props.property?.mainPicture)
    const [formValidator, setFormValidator] = useState<PropertyFormValidator>(new PropertyFormValidator())
    const formStateManager = useFormStateManager(formValidator)
    const request = useRequest<IPropertyResponseDTO, ISavePropertyRequestDTO>()
    const saveImageReq = useRequest<{ url: string }>()

    useEffect(onSavePictureRequestChange, [saveImageReq.awaiting])
    useEffect(() => { onRequestChange() }, [request.awaiting])

    useEffect(() => {
        setFormValidator(new PropertyFormValidator(props.property))
    }, [props.property])

    async function saveImage(propertyCode: number): Promise<void> {

        if (!imgFile) {
            if (!!request.returnData) {
                PropertyActions.setProperty(request.returnData)
                return props.onSuccess(request.returnData.code)
            }
            return
        }

        getBase64(imgFile, imageUrl => {
            setImgUrl(imageUrl)
        })

        const formData = new FormData()
        formData.append('file', imgFile)

        await saveImageReq.runRequest(PropertyRequests.updateMainImage(propertyCode, formData))
    }

    function onSavePictureRequestChange(): void {
        if (saveImageReq.awaiting || !saveImageReq.tried)
            return

        if (!saveImageReq.success || !saveImageReq.returnData) {
            if (saveImageReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveImageReq.returnData, saveImageReq.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar foto!')
        }

        NotificationHelper.success(`Propriedade ${props.property ? 'atualizada' : 'salva'} com sucesso!`)
        PropertyActions.setProperty(request.returnData!)
        return props.onSuccess(request.returnData?.code)

    }

    async function onRequestChange(): Promise<void> {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar propriedade!')
        }

        if (!!imgFile && imgValid) {
            await saveImage(request.returnData.code)
            return
        }

        NotificationHelper.success(`Propriedade ${props.property ? 'atualizada' : 'salva'} com sucesso!`)

        PropertyActions.setProperty(request.returnData)
        return props.onSuccess(request.returnData.code)
    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISavePropertyRequestDTO = {
            name: formValues.name,
            // stateRegistration: formValues.stateRegistration,
            city: formValues.city,
            // region: formValues.region,
            dedicatedArea: +formValues.dedicatedArea,
            totalArea: +formValues.totalArea
        }

        await request.runRequest(props.property ? PropertyRequests.update(props.property.code, dto) : PropertyRequests.save(dto))
    }

    function getBase64(img: File, callback: (result: any) => void): void {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
    }

    function validateImage(file: File): boolean {

        const allowedTypes = ['image/jpeg', 'image/png']
        if (!allowedTypes.includes(file.type)) {
            NotificationHelper.error('Apenas JPEG e PNG são permitidos')
            setImageValid(false)
            return false
        }

        const isLt5M = file.size / 1024 / 1024 < MAX_FILE_SIZE
        if (!isLt5M) {
            NotificationHelper.error('Somente é permitido arquivos menores que 5 MB')
            setImageValid(false)
            return false
        }

        getBase64(file, imageUrl => {
            setImgUrl(imageUrl)
        })

        return false
    }

    return (
        <FormWrapperSCP mobile={screenSize.smd}>
            <FormContainerSCP>

                <InputCP
                    label={'Nome da propriedade'}
                    fieldName={'name'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                />

                {/* <InputCP
                    label={'Inscrição Estadual'}
                    fieldName={'stateRegistration'}
                    type={'number'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                /> */}

                <InputCP
                    label={'Cidade'}
                    fieldName={'city'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                />

                {/* <InputCP
                    label={'Região'}
                    fieldName={'region'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                /> */}

                <InputCP
                    label={'Área Total'}
                    fieldName={'totalArea'}
                    type={'number'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    icon={<div>ha</div>}
                />

                <InputCP
                    label={'Área Dedicada'}
                    fieldName={'dedicatedArea'}
                    type={'number'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    icon={<div>ha</div>}
                />

                <PropertyMainImagePickerICP
                    imageUrl={imgUrl}
                    fileTypes={'.png, .jpg'}
                    multiple={false}
                    showUploadList={false}
                    loading={saveImageReq.awaiting}
                    onChange={(info) => setImgFile(info.file)}
                    beforeUpload={validateImage}
                />
                <ButtonCP
                    size={screenSize.smd ? 'large' : 'middle'}
                    type={'primary'}
                    isSubmit={true}
                    loading={request.awaiting}
                    onClick={onFormSubmit}
                >
                    Salvar
                </ButtonCP>
            </FormContainerSCP>
        </FormWrapperSCP>

    )
}

const FormContainerSCP = styled.div`
    width: 100%;
    padding: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;  
    height: 100%;
`

const FormWrapperSCP = styled.div<{ mobile: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: overlay;;
 -webkit-overflow-scrolling: touch;
    min-height: 100%;
`