import { Menu, Tooltip } from 'antd'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface IMenuItemICPProps extends MenuItemProps {
    title: string
    icon: JSX.Element | string
    onClick: () => void
    menuActive: boolean
    key?: string
    style?: CSSProperties
}

export function MenuItemICP(props: IMenuItemICPProps): JSX.Element {

    return (
        <Tooltip placement={'right'} title={props.title} openClassName={'tooltip-open'}>
            <MenuItemSCP isActive={props.menuActive} style={props.style}>
                <MenuItemWrapper
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                >
                    {props.title}
                </MenuItemWrapper>
            </MenuItemSCP>
        </Tooltip>
    )
}

const MenuItemWrapper = styled(Menu.Item)`
  color: ${props => props.theme.primaryColor};
  font-weight: 600;
`

const MenuItemSCP = styled.div<{ isActive?: boolean }>`
    margin: 2px 0;
    background: ${props => (props.isActive ? '#efefef' : 'transparent')};
    position: relative;
    &::before{
        position: absolute;
        content: '';
        display: ${props => (props.isActive ? 'block' : 'none')};
        right:0;
        top: 0;
        height: 100%;
        width: 4px;
        background: ${props => props.theme.primaryColor};
        border-radius: 3px 0 0 3px;
    }
    .ant-menu-item{
        &:hover{
            color: ${props => props.theme.primaryColor};
        }
    }
`

/* border-right: ${props => (props.menuActive ? `3px ${props.theme.primaryColor} solid` : 'none')}; */
