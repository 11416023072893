import * as React from 'react'
import styled from 'styled-components'

/**
 * Pagina 404 - Pagina nao encontrada
 * @author renatofs
 */
export function Error404Screen(props) {

    return (
        <StyledError404>
            <h1>Oops! Página não encontrada!</h1>
        </StyledError404>
    )

}

const StyledError404 = styled.div`
    text-align:center;
    h1{
        color:var(--secondary-color);
    }

    p{
        color:#5E85BB;
        font-style:italic;
        font-size:0.9rem;
    }
`
