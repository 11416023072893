import IMask from 'imask'
import React from 'react'
import { MaskedInput } from './MaskedInput'

const mask = (value: string): string => IMask.createMask({ mask: '(00) 00000-0000' }).resolve(value)

export function PhoneInput(props: any): JSX.Element {
    return (
        <MaskedInput {...props} length={15} mask={mask} />
    )
}