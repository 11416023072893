import { faFunnelDollar, faHandHoldingUsd, faSeedling, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { DuoWidget } from 'common/components/widgets/DuoWidgetCP'
import { GenericCardWidgetCP } from 'common/components/widgets/GenericCardWidgetCP'
import { WidgetGenericValueICP } from 'common/components/widgets/inner/WidgetGenericValueICP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { IFinancialReportResDTO } from 'modules/report/services/dtos/responses/IFinancialReportResDTO'
import React from 'react'
import styled from 'styled-components'

interface IFinancialReportGridICPProps {
    report?: IFinancialReportResDTO
    loading: boolean
}

export function FinancialReportGridICP(props: IFinancialReportGridICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <GenericCardWidgetCP
                title={'Faturamento total'}
                icon={<FontAwsomeIconCP icon={faFunnelDollar} />}
                gridArea={'income'}
                content={
                    <WidgetGenericValueICP
                        value={!!props.report?.totalIncome ? `${MaskUtils.applyNumberMask(props.report?.totalIncome)}` : undefined}
                        label={''}
                        loading={props.loading}
                    />
                }
            />

            <GenericCardWidgetCP
                title={'Lucro total'}
                icon={<FontAwsomeIconCP icon={faHandHoldingUsd} />}
                gridArea={'profit'}
                content={
                    <WidgetGenericValueICP
                        value={!!props.report?.totalProfit ? `${MaskUtils.applyNumberMask(props.report?.totalProfit)}` : undefined}
                        label={''}
                        loading={props.loading}
                    />
                }
            />

            <GenericCardWidgetCP
                title={'Custo total'}
                icon={<FontAwsomeIconCP icon={faWallet} />}
                gridArea={'cost'}
                content={
                    <WidgetGenericValueICP
                        value={!!props.report?.totalCost ? `${MaskUtils.applyNumberMask(props.report?.totalCost)}` : undefined}
                        label={''}
                        loading={props.loading}
                    />
                }
            />

            <GenericCardWidgetCP
                title={'Custo médio por saca'}
                icon={<FontAwsomeIconCP icon={faSeedling} />}
                gridArea={'average-cost'}
                content={
                    <WidgetGenericValueICP
                        value={!!props.report?.costPerBag ? `${MaskUtils.applyNumberMask(props.report?.costPerBag)}` : undefined}
                        label={''}
                        loading={props.loading}
                    />
                }
            />

            <DuoWidget
                gridArea={'sale'}
                dataWidget={
                    [
                        {
                            title: 'Valor de venda médio por saca',
                            value: !!props.report && !!props.report?.averageValuePerBag ? `${MaskUtils.applyCurrencyMask(props.report?.averageValuePerBag)} ` : 'R$0'
                        },
                        {
                            title: 'Lucro por saca',
                            value: !!props.report && !!props.report?.profitPerBag ? `${MaskUtils.applyCurrencyMask(props.report?.profitPerBag)}` : 'R$0'
                        },
                    ]
                }
            />


        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    margin-bottom: 10px;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    grid-template-areas:
        "income profit sale sale"
        "cost average-cost sale sale"
    ;

     @media(max-width: 768px){
        grid-template-columns: 100%;
        grid-template-areas: 
            "income"
            "profit"
            "cost"
            "average-cost"
            "sale"
            "sale";           
    }
`
