import styled from 'styled-components'
import React from 'react'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ISearchImplementRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchImplementRequestDTO'
import _ from 'lodash'

interface IImplementFilterFieldsICPProps {
    onChangeSearchString: (searchString: string) => void
    filters?: ISearchImplementRequestDTO
}

export function ImplementFilterFieldsICP(props: IImplementFilterFieldsICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <GridWrapperSCP>
                <InputCP
                    placeholder={'Marca/Modelo'}
                    // label={'Nome'}
                    onChange={_.debounce(props.onChangeSearchString, 750)}
                    value={props.filters?.searchString}
                />
            </GridWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 3px;
`

const GridWrapperSCP = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr .5fr ;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;

    label, .ant-typography div{
      display: none;
    }

    @media(max-width: 767px){
        grid-template-columns: 1fr;
         label, .ant-typography div{
                   display: block;
         }
    }
`

const StatusSCP = styled.div`
    @media(max-width: 767px){
       display: none;
    }

`