import React from 'react'
import styled from 'styled-components'

interface IProgressChartCPProps {
    percent: number | string
    spanValue?: string
    positiveSpanValue?: boolean
}

export function ProgressChartCP(props: IProgressChartCPProps): JSX.Element {

    return (
        <ChartWrapperSCP>
            <div className={'single-chart'}>
                <svg viewBox={'0 0 36 36'} className={'circular-chart'}>
                    <path
                        className={'circle-bg'}
                        d='M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831'
                    />
                    <path
                        className={'circle'}
                        strokeDasharray={'30, 100'}
                        d='M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831'
                    />
                    <text x={'18'} y={'20.35'} className={'percentage'}>{`${props.percent} %`}</text>
                </svg>
            </div>
        </ChartWrapperSCP>
    )
}

const ChartWrapperSCP = styled.div`
    display: flex;
    flex-flow: row nowrap;

    .single-chart {
        width: 100%;
        justify-content: space-around;
    }

    .circular-chart {
        display: block;
        margin: 10px auto;
        max-width: 80%;
        max-height: 250px;
    }

    .circle-bg {
        fill: none;
        stroke: #eee;
        stroke-width: 3;
    }

    .circle {
        fill: none;
        stroke-width: 2.5;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
        stroke: ${props => props.theme.primaryColor};
    }

    @keyframes progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }

    .percentage {
        font-weight: bold;
        font-size: 0.5em;
        text-anchor: middle;
    }
`
