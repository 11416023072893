/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { faArrowDown, faArrowUp, faFeatherAlt, faFlask, faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaskUtils } from 'common/utils/MaskUtils'
import _ from 'lodash'
import { BatchPresentationRoutes } from 'modules/batch-presentation/BatchPresentationRoutes'
import { BatchRoutes } from 'modules/batch/BatchRoutes'
import { BatchProcessDrawerCP } from 'modules/batch/components/batch-process-drawer/BatchProcessDrawerCP'
import { BatchTreeDrawerCP } from 'modules/batch/components/batch-tree-drawer/BatchTreeDrawerCP'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import { LitersPerMaturationCP } from 'modules/harvest-planning/components/liters-per-maturation/LitersPerMaturationCP'
import { LogbookHeaderSkeletonCP } from 'modules/logbook/components/logbook-header-skeleton/LogbookHeaderSkeletonCP'
import moment from 'moment-timezone'
import React, { CSSProperties, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'


type RouteParamsTP = { code?: string }
interface IBatchDetailsHeaderICPProps {
    setSaleUnavailable: (unavailable: boolean) => void
    setHasSales: (hasSales: boolean) => void

}

export function BatchDetailsHeaderICP(props: IBatchDetailsHeaderICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const btnPositionStyle: CSSProperties = { position: 'absolute', top: '1em', right: '1em' }
    const valueStyle = { fontSize: `${screenSize.smd ? '15px' : '18px'}`, fontWeight: '600', lineHeight: '22px' }

    const history = useHistory()

    const [processDrawerVisible, setProcessDrawerVisible] = useState<boolean>(false)
    const [showMobileDetails, setShowMobileDetails] = useState<boolean>(!screenSize.smd)
    const [treeDrawerVisible, setTreeDrawerVisible] = useState<boolean>(false)

    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const routeParams = useParams<RouteParamsTP>()

    const [batchCode, setBatchCode] = useState<number>()
    const [batch, setBatch] = useState<IBatchResponseDTO>()
    const [hasBenefit, setHasBenefit] = useState<boolean>(false)

    const request = useRequest<IBatchResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(() => {
        if (!!routeParams.code)
            setBatchCode(+routeParams.code)
    }, [routeParams])

    useEffect(getBatchDetails, [batchCode])
    useEffect(onItemChange, [updateStatus])

    function getBatchDetails(): void {
        if (!!batchCode)
            request.runRequest(BatchRequests.get(batchCode))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE)
            getBatchDetails()
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar detalhes!')
        }

        setBatch(request.returnData)
        props.setSaleUnavailable(!request.returnData.availableBags)
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        props.setHasSales(request.returnData.hasSales)
        setHasBenefit(!!request.returnData.bags && request.returnData.bags > 0)
        ListActions.updateIsDone()
    }

    if (!batch || request.awaiting)
        return <LogbookHeaderSkeletonCP />

    return (
        <WrapperSCP>
            <CardWrapperSCP>
                <CardHeaderICP
                    highlight={`#${batch.code}`}
                    highlighAction={() => history.push(`${BatchPresentationRoutes.ROOT}/${batch.code}`)}
                    highlightTooltipText={'Clique para ver a página de apresentação deste lote'}
                    title={`${BatchUtils.translateMethod(batch.method)}`}
                    categoryTitle={`${batch.fields.map(fie => fie.name).join(', ')}`}
                    subtitle={
                        <>
                            <ContentInfoSCP style={{ display: `${showMobileDetails ? 'grid' : 'none'}` }}>
                                <RowSCP>
                                    <TextCP text={'Id na properiedade :\u00A0'} style={{ fontWeight: '500 ' }} />
                                    <TextCP text={`${batch.farmId ?? '-'} `} />
                                </RowSCP>
                                <RowSCP>
                                    <TextCP text={'Safra:\u00A0'} style={{ fontWeight: '500 ' }} />
                                    <TextCP text={`${batch.season}`} />
                                </RowSCP>
                                <RowSCP>
                                    <TextCP text={'Data de início:\u00A0'} style={{ fontWeight: '500 ' }} />
                                    <TextCP text={`${batch.date ? moment(batch.date).format('DD/MM/YYYY') : 'Não informado'}`} />
                                </RowSCP>
                                <RowSCP>
                                    <TextCP text={'Origem:\u00A0'} style={{ fontWeight: '500 ' }} />
                                    <TextCP text={`${BatchUtils.translateOrigin(batch.origin)}`} />
                                </RowSCP>
                            </ContentInfoSCP>
                            {
                                !!batch.litersPerMaturation &&
                                <PopOverCP
                                    placement={'right'}
                                    trigger={'click'}
                                    content={
                                        <LitersPerMaturationCP litersPerMaturation={batch.litersPerMaturation} />
                                    }
                                >
                                    <POWppSCP>
                                        <TextCP text={'Ver litros por maturação '}
                                            style={{ cursor: 'pointer', fontSize: '12px', fontWeight: 600, textDecoration: 'underline', maxWidth: '200px' }} />
                                    </POWppSCP>
                                </PopOverCP>
                            }
                        </>
                    }
                />
                {
                    screenSize.smd &&
                    <ButtonCP
                        onClick={() => setShowMobileDetails(!showMobileDetails)}
                        size={'small'}
                        style={btnPositionStyle}
                        icon={<FontAwsomeIconCP icon={!showMobileDetails ? faArrowDown : faArrowUp} />}
                        type={'primary'}
                    />

                }
            </CardWrapperSCP>
            <DateInfoWrapper>
                <LitersSCP>

                    <StatisticsSCP>

                        {!!batch.bags &&
                            <ItemSCP>
                                <TextCP text={`${MaskUtils.applyNumberMask(batch.liters / batch.bags)}L`} style={valueStyle} />
                                <TextCP text={'rendimento'} />
                            </ItemSCP>}
                        {
                            !!batch.bags &&
                            <ItemSCP>
                                <TextCP text={`${MaskUtils.applyNumberMask(batch.availableBags ?? 0)}`} style={valueStyle} />
                                <TextCP text={'sacas disponíveis'} />
                            </ItemSCP>
                        }
                        {
                            !!batch.bags &&
                            <ItemSCP>
                                <TextCP text={`${MaskUtils.applyNumberMask(batch.bags)}`} style={valueStyle} />
                                <TextCP text={'sacas totais'} />
                            </ItemSCP>
                        }

                        {
                            !!batch.liters &&
                            <ItemSCP>
                                <TextCP text={`${MaskUtils.applyNumberMask(batch.liters)}L`} style={valueStyle} />
                                <TextCP text={'litros'} />
                            </ItemSCP>
                        }

                    </StatisticsSCP>
                </LitersSCP>
                <ButtonWrapperSCP>
                    <OtherActionsSCP>

                        <ButtonCP
                            type={'primary'}
                            onClick={() => history.push(`${BatchRoutes.ROOT}/analises/${batch.code}`)}
                            icon={<FontAwsomeIconCP icon={faFlask} />}
                            disabled={false}
                            wrapperStyle={{ width: screenSize.smd ? '100%' : 'auto' }}
                        >
                            Análises
                        </ButtonCP>

                        <ButtonCP
                            type={'primary'}
                            onClick={() => setTreeDrawerVisible(true)}
                            icon={<FontAwsomeIconCP icon={faProjectDiagram} />}
                            disabled={false}
                            wrapperStyle={{ width: screenSize.smd ? '100%' : 'auto' }}
                        >
                            Árvore do lote
                        </ButtonCP>

                    </OtherActionsSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={() => setProcessDrawerVisible(true)}
                        icon={<FontAwsomeIconCP icon={faFeatherAlt} />}
                        disabled={false}
                        wrapperStyle={{ width: screenSize.smd ? '100%' : 'auto' }}
                    >
                        Novo processo
                    </ButtonCP>

                </ButtonWrapperSCP>

            </DateInfoWrapper>
            <BatchProcessDrawerCP
                show={processDrawerVisible}
                onCancel={() => setProcessDrawerVisible(false)}
                onSuccess={() => setProcessDrawerVisible(false)}
                batchCode={batch.code}
                hasBenefit={hasBenefit}
            />
            {
                !!batch &&
                <BatchTreeDrawerCP
                    show={treeDrawerVisible}
                    onCancel={() => setTreeDrawerVisible(false)}
                    batchCode={batch.code}
                />
            }
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    display: grid;
    grid-template-columns:1.5fr 1fr;
    width: 100%;
    column-gap: 20px;
    @media (max-width: 768px){
        grid-template-columns: 1fr;
    }
  
`

const CardWrapperSCP = styled.div`
    position: relative;

`
const POWppSCP = styled.div`
    .ant-typography{
        color: ${props => props.theme.primaryColor}
    }
`

const StatisticsSCP = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    @media(max-width: 768px){
        justify-content: space-between;
    }
`

const ItemSCP = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    align-items: center;
    text-align: center;
    justify-content:  center;
    .ant-typography{
        color: ${props => props.theme.primaryColor}
    }
    @media(max-width: 768px){
        margin: 0 16px;
        align-items: center;
    }

`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 0;
    .ant-btn{
        margin-left: 10px;
    }
    @media(max-width: 1280px){
        flex-direction: column;
        .ant-btn{
            margin-bottom: 10px;
        }
    }
    @media(max-width: 768px){
        align-items: flex-end;
        flex-direction: column;
        row-gap: 10px;
        width: 100%;
        .ant-btn{
            width: 100%;
            margin: 0;
            justify-content: center;
        }
    }
`
const RowSCP = styled.div`
    display: flex;
    margin-bottom: 5px;

`
const LitersSCP = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;   
    width: 100%;

    @media(max-width: 768px){
    justify-content: space-between;
        flex-direction: row;   
    }
`

const DateInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;   
    align-items: flex-end;
   width: 100%;
    justify-content: space-between;
    .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px){
         margin: 10px 0;
        align-items: flex-end;
        flex-direction: column;   
    }
`

const SubTitleSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    width: 100%;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }

`

const ContentInfoSCP = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    margin: 5px 0;
    .ant-typography{
        font-size: 12px;
    }

    @media (max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const OtherActionsSCP = styled.div`
    display: flex;
    @media(max-width: 768px){
        display: flex; 
        width: 100%;
        column-gap: 10px;
    }
`