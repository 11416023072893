import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { PropertyDetailsCP } from 'modules/property/components/property-details/PropertyDetailsCP'
import { IPropertyResponseDTO } from 'modules/property/services/dtos/responses/IPropertyResponseDTO'
import { PropertyRequests } from 'modules/property/services/PropertyRequests'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { PropertyActions } from 'modules/property/PropertyActions'
import { ButtonCP } from 'common/components/button/ButtonCP'

import { PropertyRoutes } from 'modules/property/PropertyRoutes'
import { PropertyDrawerCP } from 'modules/property/components/property-drawer/PropertyDrawerCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons'

type RouteParamsTP = { code?: string }

export function PropertyDetailsScreen(): JSX.Element {
    const routeParams = useParams<RouteParamsTP>()
    const request = useRequest<IPropertyResponseDTO>()
    const history = useHistory()
    const [visible, setVisible] = useState(false)

    function redirectToInfo(code?: number): void {
        history.push(`${PropertyRoutes.ROOT}/${code!}`)
    }

    useEffect(() => {
        if (!routeParams.code)
            return

        getPropertyInfo(routeParams.code)
    }, [routeParams])

    useEffect(onRequestChange, [request.awaiting])

    function getPropertyInfo(code: any): void {
        request.runRequest(PropertyRequests.get(code))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar propriedade!')
        }

        PropertyActions.setProperty(request.returnData)
    }

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={request.returnData?.name}
                    icon={
                        <FontAwsomeIconCP icon={faArrowLeft} onClick={() => history.push(PropertyRoutes.ROOT)} size={'1x'} />
                    }
                >
                    <ButtonCP
                        icon={<FontAwsomeIconCP icon={faPen} />}
                        size={'middle'}
                        type={'ghost'}
                        onClick={() => setVisible(true)}
                    >
                        Editar
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <PropertyDetailsCP />
                    <PropertyDrawerCP
                        visible={visible}
                        onClose={() => setVisible(false)}
                        onSuccess={() => setVisible(false)}
                        property={request.returnData}
                    />
                </ContentCP>
            }
        />
    )
}