import { CarouselCP } from 'common/components/carousel/CarouselCP'
import { FieldDetailsCP } from 'modules/batch-landing-page/components/field-details/FieldDetailsCP'
import { IFieldResponseDTO } from 'modules/field/services/dtos/responses/IFieldResponseDTO'
import React from 'react'
import styled from 'styled-components'

interface IFieldDetailsCarouselICPProps {
    fields: IFieldResponseDTO[]
}

export function FieldDetailsCarouselICP(props: IFieldDetailsCarouselICPProps): JSX.Element {

    return (
        <CarouselCP autoPlay={false}>
            {
                props.fields.map(field => (
                    <FieldDetailsCP fieldCode={field.code} propertyCode={field.propertyCode} key={field.code} />
                ))
            }
        </CarouselCP>
    )
}

const WrapperSCP = styled.div`
`
