import { isEmail, registerDecorator, ValidationArguments, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import * as _ from 'lodash'

@ValidatorConstraint()
class IsEmailConstraint implements ValidatorConstraintInterface {

    validate(value: string, args: ValidationArguments): boolean {
        return !_.isEmpty(value) && isEmail(value)
    }

    defaultMessage(args: ValidationArguments): string {
        return 'Email inválido'
    }
}

/**
 * DECORATOR de VALIDACAO
 * Valida e-mail
 *
 * @author guilherme.diniz
 * @author hjcostabr
 */
export function IsEmail(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsEmailConstraint
        })
    }
}
