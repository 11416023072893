import { faPercent } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { TextCP } from 'common/components/text/TextCP'
import { GenericCardWidgetCP } from 'common/components/widgets/GenericCardWidgetCP'
import { ProgressPercentageListCP } from 'common/components/widgets/inner/ProgressPercentageListCP'
import { ProgressPercentageDataTP } from 'common/components/widgets/types/ProgressPercentageDataTP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaskUtils } from 'common/utils/MaskUtils'
import { FinancialUtils } from 'modules/financial/utils/FinancialUtils'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'
import { ICostPerCategoryResDTO } from 'modules/report/services/dtos/responses/ICostPerCategoryResDTO'
import { ReportRequests } from 'modules/report/services/ReportRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface ICostPerCategoryCPProps {
    filters: ISearchReportReqDTO
    loading: boolean
}

const MOCK = [
    {
        label: 'Categoria',
        percentage: 50,
        value: 'R$ 50'
    },
    {
        label: 'Categoria',
        percentage: 50,
        value: 'R$ 50'
    },
    {
        label: 'Categoria',
        percentage: 50,
        value: 'R$ 50'
    },
    {
        label: 'Categoria',
        percentage: 50,
        value: 'R$ 50'
    },
]
export function CostPerCategoryCP(props: ICostPerCategoryCPProps): JSX.Element {

    const [report, setReport] = useState<ProgressPercentageDataTP[]>()

    const getFinacialReportReq = useRequest<IGenericListResponseDTO<ICostPerCategoryResDTO>>()
    useEffect(onRequestChange, [getFinacialReportReq.awaiting])
    useEffect(getReport, [props.filters])

    function getReport(): void {
        if (!!props.filters.propertyCode)
            getFinacialReportReq.runRequest(ReportRequests.getCostPerCategory(props.filters))
    }

    function onRequestChange(): void {
        if (getFinacialReportReq.awaiting || !getFinacialReportReq.tried)
            return

        if (!getFinacialReportReq.success || !getFinacialReportReq.returnData) {
            if (getFinacialReportReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getFinacialReportReq.returnData, getFinacialReportReq.error)
            return RequestUtils.showDefaultErrorNotification(getFinacialReportReq.error, 'Erro ao buscar dados!')
        }
        setReport(getFinacialReportReq.returnData.list.map(cost => ({
            label: FinancialUtils.translateCategory(cost.category),
            value: MaskUtils.applyCurrencyMask(cost.totalCost),
            percentage: cost.percentage
        })))
    }
    return (
        <WrapperSCP>
            <HeaderSCP>
                <TextCP text={'Custo por categoria'} style={{ fontSize: '15px', fontWeight: 600 }} />

            </HeaderSCP>
            <GenericCardWidgetCP
                title={'Categoria'}
                icon={<FontAwsomeIconCP icon={faPercent} />}
                style={{ height: 'auto' }}
                content={
                    <ProgressPercentageListCP
                        data={report}
                        loading={getFinacialReportReq.awaiting}
                    />
                }
            />
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
`

const HeaderSCP = styled.div`
    display: flex;
    justify-content: flex-start;
    
    margin: 15px 0;
`