import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { IPropertyResponseDTO } from './services/dtos/responses/IPropertyResponseDTO'

export class PropertyActions {

    static readonly TYPE_SET_INFO_PROPERTY = 'SET_INFO_PROPERTY'

    private constructor() { /* private constructor to avoid instantiation */ }

    static setProperty(property: IPropertyResponseDTO): void {
        ReduxHelper.getInstance().getStore()
            .dispatch({ type: this.TYPE_SET_INFO_PROPERTY, payload: property })
    }
}
