import { ValidationError } from 'class-validator'
import * as _ from 'lodash'

import { FormValuesMapTP } from 'common/form-state-manager/FormStateTypes'
import { IFormModelValidator } from 'common/form-state-manager/interfaces/IFormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'

/**
 * FORM STATE
 * Controlador de estado de formulario:
 * Gerencia validacao de dados, estado de alteracao dos campos, etc.
 *
 * @author hjcostabr
 */
export class FormStateManager<FModelTP extends IFormModelValidator> implements IFormStateManager<FModelTP> {

    /**
     * @inheritDoc
     */
    isValid = true

    /**
     * @inheritDoc
     */
    isDirty = false

    /**
     * @inheritDoc
     */
    validationsCount = 0

    /**
     * @inheritDoc
     */
    considerAllErrors = false

    private errors: { [key in keyof FModelTP]?: ValidationError } = {}

    private readonly formName?: string
    private readonly initialValues: FModelTP
    private formFields: FModelTP
    private dirtyFields: Array<keyof FModelTP> = []

    constructor(initialValues: FModelTP, formName?: string) {
        this.formFields = _.cloneDeep(initialValues)
        this.initialValues = _.cloneDeep(initialValues)
        this.formName = formName
    }

    /**
     * @inheritDoc
     */
    changeFieldValue(fieldName: keyof FModelTP, value: any): void {
        this.isDirty = true
        this.formFields[fieldName] = value
        this.setFieldDirty(fieldName)
    }

    /**
     * @inheritDoc
     */
    async validate(): Promise<boolean> {

        this.errors = {}
        let errors = await this.formFields.validate()

        if (!!this.formFields.customValidation) {
            errors = [
                ...errors,
                ...await this.formFields.customValidation()
            ]
        }

        this.validationsCount++
        this.isValid = !errors.length
        if (this.isValid)
            return true

        errors.forEach(error => this.errors[error.property] = error)
        return false
    }

    /**
     * @inheritDoc
     */
    getFieldError(fieldName: keyof FModelTP): ValidationError | undefined {
        return (this.considerAllErrors || this.isFieldDirty(fieldName))
            ? this.errors[fieldName]
            : undefined
    }

    /**
     * @inheritDoc
     */
    getFormValues(): FormValuesMapTP<FModelTP> {

        const values = {}

        Object.keys(this.formFields)
            .forEach((fieldName: string) => {
                const value = this.formFields[fieldName]
                if (typeof value !== 'function')
                    values[fieldName] = value
            })

        return values as FormValuesMapTP<FModelTP>
    }

    /**
     * @inheritDoc
     */
    getFieldValue(fieldName: keyof FModelTP): any {
        return this.formFields[fieldName]
    }

    /**
     * @inheritDoc
     */
    async reset(replacingData?: FModelTP): Promise<void> {

        this.isDirty = false
        this.considerAllErrors = false
        this.errors = {}
        this.dirtyFields = []
        this.validationsCount = 0
        this.formFields = _.cloneDeep(this.initialValues)

        if (!!replacingData) {
            Object.keys(this.formFields)
                .forEach(fieldName => {
                    this.formFields[fieldName] = replacingData[fieldName]
                })
        }

        await this.validate()
    }

    /**
     * @inheritDoc
     */
    setFieldDirty(fieldName: keyof FModelTP): void {
        if (!this.isFieldDirty(fieldName))
            this.dirtyFields.push(fieldName)
    }

    /**
     * @inheritDoc
     */
    setConsiderAllErrors(consider: boolean): void {
        this.considerAllErrors = consider
    }

    /**
     * @inheritDoc
     */
    debugFieldValues(): void {
        const formNameDebug = !!this.formName ? `(${this.formName})` : ''
        console.log(`DEBUG | FormStateManager: ${formNameDebug} | Campos:`, this.formFields)
    }

    /**
     * @inheritDoc
     */
    debugErrors(): void {
        const formNameDebug = !!this.formName ? `(${this.formName})` : ''
        console.log(`DEBUG | FormStateManager: ${formNameDebug} | Erros:`, this.errors)
    }

    /** Avalia & retorna SE 01 campo do formulario esta 'sujo' (foi alterado). */
    private isFieldDirty(fieldName: keyof FModelTP): boolean {
        return !!this.dirtyFields.find(dirtyField => dirtyField === fieldName)
    }
}
