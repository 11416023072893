import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { PhotoGalleryCP } from 'common/components/custom-photo-gallery/PhotoGalleryCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { FileUploadCP } from 'common/components/file-upload/FileUploadCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'

import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { ILogbookResponseDTO } from 'modules/harvest-planning/services/dtos/response/ILogbookResponseDTO'
import { LogbookRequests } from 'modules/logbook/services/LogbookRequests'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface ILogbookAlbumDrawerCPProps {
    logbookEntryCode: number
    show: boolean
    onCancel: () => void
}

export function LogbookAlbumDrawerCP(props: ILogbookAlbumDrawerCPProps): JSX.Element {
    const [files, setFiles] = useState<any>([])
    const timeoutId = useRef<any>()

    const [pictures, setPictures] = useState<string[]>([])

    const pictureTypes = [
        { mime: 'image/jpeg', extension: 'jpeg' },
        { mime: 'image/jpg', extension: 'jpg' },
        { mime: 'image/png', extension: 'png' },
    ]

    const request = useRequest<ILogbookResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const findOneReq = useRequest<ILogbookResponseDTO>()
    useEffect(onFindOneReqChange, [request.awaiting])

    const deleteImageReq = useRequest()
    useEffect(onDeleteImageReqChange, [deleteImageReq.awaiting])

    useEffect(init, [props.logbookEntryCode, props.show])
    useEffect(prepareToSave, [files])

    function init(): void {
        if (!!props.show && !!props.logbookEntryCode)
            findOneReq.runRequest(LogbookRequests.get(props.logbookEntryCode))
    }

    function prepareToSave(): void {

        if (files.length === 0)
            return

        if (!!timeoutId.current)
            clearTimeout(timeoutId.current)

        timeoutId.current = setTimeout(() => saveImages(), 1000)
    }

    function appendImages(option: any): void {
        console.log(option)
        setFiles([...files, option.file])
    }

    function saveImages(): void {
        const formData = new FormData()
        for (const file of files)
            formData.append('files', file)
        request.runRequest(LogbookRequests.addImages(props.logbookEntryCode, formData))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao subir imagens!')
        }

        NotificationHelper.success('Images adicionadas com sucesso!')
        setFiles([])
        init()
    }

    function onFindOneReqChange(): void {

        if (findOneReq.awaiting || !findOneReq.tried)
            return

        if (!findOneReq.success || !findOneReq.returnData) {
            if (findOneReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', findOneReq.returnData, findOneReq.error)
            return RequestUtils.showDefaultErrorNotification(findOneReq.error, 'Erro ao buscar dados do registro!')
        }

        setPictures(findOneReq.returnData.pictures ?? [])
        setFiles([])
    }

    function removeImage(url: string): void {
        deleteImageReq.runRequest(LogbookRequests.removeImages(props.logbookEntryCode, { pictures: [url] }))
    }

    function onDeleteImageReqChange(): void {
        if (deleteImageReq.awaiting || !deleteImageReq.tried)
            return

        if (!deleteImageReq.success || !deleteImageReq.returnData) {
            if (deleteImageReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteImageReq.returnData, deleteImageReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteImageReq.error, 'Erro ao excluir imagem!')
        }
        init()
    }

    return (
        <DrawerCP
            title={'Álbum de fotos'}
            visible={props.show}
            width={'80%'}
            onClose={() => {
                props.onCancel()
            }}
            footer={
                request.awaiting || deleteImageReq.awaiting ?
                    <FontAwsomeIconCP
                        icon={faSpinner}
                        loading={true}
                        style={{
                            fontSize: '20px',
                            margin: '0 10px',
                            color: `${CostumizedThemeConfig.primaryColor}`
                        }}
                    />
                    : undefined
            }
        >

            <WrapperSCP>
                <FileUploadCP
                    uploadFiles={appendImages}
                    fileTypes={pictureTypes}
                    loading={request.awaiting}
                    showUploadList={false}
                    multiple={true}
                />
                <GaleryWrapperSCP>
                    <PhotoGalleryCP
                        images={!!findOneReq.returnData && findOneReq.returnData.pictures ?
                            findOneReq.returnData.pictures.map(pic => ({
                                url: pic,
                                onDelete: () => removeImage(pic),
                                previewConfig: {
                                    mask: <MaskWrapperSCP />
                                }
                            }))
                            :
                            []}
                    />
                </GaleryWrapperSCP>

            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`
const GaleryWrapperSCP = styled.div`
    margin-top: 30px;
`

const MaskWrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    opacity: .5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
`