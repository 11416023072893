import { AxiosResponse } from 'axios'
import { IApiReturn } from 'common/interfaces/IApiReturn'
import { RequestHelper } from 'common/request-helper/RequestHelper'
import { RequestResponseTP } from 'common/request-helper/RequestResponseTP'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { RequestTP } from 'common/request-helper/types/RequestTP'
import * as _ from 'lodash'
import { useEffect, useState } from 'react'
import { HttpStatusEnum } from '../enums/HttpStatusEnum'

type ReturnDataWrapper = 'api-return' | 'none'

/**
 * HOOK
 * Encapsula gestao de 01 requisicao HTTP generica:
 * Pensado prioritariamente para tratar requisicoes a api(s) nerit.
 *
 * @todo: Incluir meio de cancelar 01 requisicao em adamento
 *
 * @see IApiReturn
 *
 * @author hjcostabr
 * @author Stella
 */
export function useRequest<ResDataType, ReqDataType = any>(
    config?: RequestConfigTP<ReqDataType>,
    returnWrapper: ReturnDataWrapper = 'api-return',
    runOnCreate?: RequestConfigTP<ReqDataType>,
): RequestTP<ResDataType, ReqDataType> {
    const [returnData, setReturnData] = useState<ResDataType>()
    const [error, setError] = useState<AxiosResponse>()
    const [awaiting, setIsAwaiting] = useState<boolean>(false)
    const [tried, setTried] = useState<boolean>(false)
    const [status, setStatus] = useState<HttpStatusEnum>()
    const [success, setSuccess] = useState<boolean>(false)
    const [responseType, setResponseType] = useState<RequestResponseTP>()

    useEffect(() => {
        if (runOnCreate) runRequest(runOnCreate)
    }, [])

    if (returnWrapper !== 'api-return' && returnWrapper !== 'none')
        throw { message: 'FALHA - useRequest: Parametrizacao invalida! 1' }

    function validateReqReturn(reqResult: AxiosResponse): void {
        if (!reqResult.status || reqResult.status < 200 || reqResult.status > 299) throw reqResult
    }

    const runRequest = async (_config?: RequestConfigTP<ReqDataType>): Promise<void> => {
        if (!!_config) config = Object.assign(config ?? {}, _config)

        if (!_.get(config, 'url') || !_.get(config, 'method'))
            throw { message: 'FALHA - useRequest: Parametrizacao invalida! 2' }

        setTried(true)
        setIsAwaiting(true)

        try {
            let reqResult: AxiosResponse

            if (returnWrapper === 'api-return') {
                type responseDataTP = IApiReturn<ResDataType>

                reqResult = (await RequestHelper.runRequest<responseDataTP>(
                    config!,
                )) as AxiosResponse<responseDataTP>

                validateReqReturn(reqResult)
                const apiReturn: any = reqResult.data
                    ? (reqResult!.data as responseDataTP)
                    : (reqResult as responseDataTP)
                setReturnData(apiReturn.data ? apiReturn.data : apiReturn)
            } else if (returnWrapper === 'none') {
                reqResult = (await RequestHelper.runRequest<ResDataType>(
                    config!,
                )) as AxiosResponse<ResDataType>

                validateReqReturn(reqResult)
                setReturnData(reqResult!.data)
            }

            if (!!reqResult!) setResponseType(reqResult!.headers['content-segmentType'])

            setError(undefined)
            setStatus(reqResult!.status)
            setSuccess(true)
        } catch (error) {
            setSuccess(false)
            setReturnData(undefined)
            setError(error)
            setStatus(_.get(error, 'status'))
        } finally {
            setIsAwaiting(false)
        }
    }

    return {
        runRequest,
        responseType,
        returnData,
        awaiting,
        error,
        tried,
        status,
        success,
    }
}
