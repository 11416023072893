import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { GenericInvoiceFormICP } from 'modules/financial/components/invoice-form-drawer/inner/GenericInvoiceFormICP'
import { InvoiceFormValidator } from 'modules/financial/components/invoice-form-drawer/inner/InvoiceFormValidator'
import { ISaveInvoiceReqDTO } from 'modules/financial/services/dtos/request/ISaveInvoiceReqDTO'
import { IInvoiceResDTO } from 'modules/financial/services/dtos/responses/IInvoiceResDTO'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IStatementPaymentDrawerCPProps {
    personCode: number
    personName: string
    show: boolean
    itemsToConsolidate: number[]
    previewData?: ISaveInvoiceReqDTO
    onCancel: () => void
    onSuccess: () => void
}

export function StatementPaymentDrawerCP(props: IStatementPaymentDrawerCPProps): JSX.Element {
    const [formValidator, setFormValidator] = useState<InvoiceFormValidator>(new InvoiceFormValidator(props.previewData))
    const formStateManager = useFormStateManager(formValidator)
    const [isPaid, setIsPaid] = useState(false)

    const request = useRequest<IInvoiceResDTO>()
    useEffect(onSaveInvoiceRequestChange, [request.awaiting])
    useEffect(changePreview, [props.previewData])

    function changePreview(): void {
        if (!!props.previewData)
            setFormValidator(new InvoiceFormValidator(props.previewData))
    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveInvoiceReqDTO = {
            date: DateUtils.getDateFormattedToApi(formValues.date),
            description: formValues.description,
            isCredit: false,
            isPaid,
            paymentDate: formValues.paymentDate ? DateUtils.getDateFormattedToApi(formValues.paymentDate) : undefined,
            value: +(`${formValues.value}`.replace(',', '.').replace(/[^\d.-]/g, '')),
            season: formValues.season
        }

        await request.runRequest(PersonRequests.saveStatementInvoice(props.personCode, props.itemsToConsolidate, dto))
    }

    function onSaveInvoiceRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar fatura!')
        }

        ListActions.mustUpdate()
        props.onSuccess()
        formStateManager.reset()

    }

    return (
        <DrawerCP
            title={`Gerar despesa: Pagamento à ${props.personName}`}
            visible={props.show}
            width={'40%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFormSubmit}
                        wrapperStyle={{ width: '100%' }}
                        loading={request.awaiting}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {
                formStateManager.reset()
                props.onCancel()
            }}
        >

            <GenericInvoiceFormICP
                isPaid={isPaid}
                markAsPaid={setIsPaid}
                formStateManager={formStateManager}
                loading={request.awaiting}
            />
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`

const ButtonWrapperSCP = styled.div`
display: flex;
justify-content: space-between;
margin-top: 15px;
width: 100%;
.ant-btn{
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.ant-typography{
    font-size: 11px;
    color: ${props => props.theme.lightGreen}
}
`

const TwoColWrapperSCP = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
column-gap: 10px;
`

const SaleFormWrapperSCP = styled.div``

const FormWrapperSCP = styled.div`
margin-top: 10px;
`