export enum RecipeTypeEnum {
    GENERAL = 'GENERAL',
    FERTILIZING = 'FERTILIZING',
    BUSH_CONTROL = 'BUSH_CONTROL',
    PRAGUE_CONTROL = 'PRAGUE_CONTROL',
    SPROUT_CLEANUP = 'SPROUT_CLEANUP',
    SKELETON = 'SKELETON',
    UNDER_CLEANUP = 'UNDER_CLEANUP',
    POST_HARVEST = 'POST_HARVEST',
    SOIL_PREPARE = 'SOIL_PREPARE',
}
