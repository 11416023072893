import { useScreenSize } from 'common/hooks/UseScreenSize'
import { ResetPasswordFormCP } from 'modules/auth/components/content-user-login/ResetPasswordFormCP'

import React, { useState } from 'react'
import styled from 'styled-components'
import { ContentCP } from '../../../../common/components/screen-layout/content/ContentCP'

import { UserLoginFormCP } from '../user-login-form/UserLoginFormCP'
import { IAuthUserResponseDTO } from 'modules/auth/services/dtos/responses/IAuthUserResponseDTO'

interface ILayoutContentLoginCPProps {
    goToRegister: () => void
    goToLoggedScreen: (IUserLoginResponseDTO) => void
    authUser: IAuthUserResponseDTO
    history: any
}

export function ContentUserLoginCP(props: ILayoutContentLoginCPProps): JSX.Element | null {
    const [recoverPassFormVisible, setRecoverPassFormVisible] = useState<boolean>(false)
    return (
        <ContentCP>
            <ContainerSCP>
                <FormWrapperSCP>
                    {
                        !recoverPassFormVisible ?
                            <UserLoginFormCP onLoginSuccess={(user) => props.goToLoggedScreen(user)} />
                            :
                            <ResetPasswordFormCP
                                onSuccess={() => setRecoverPassFormVisible(false)}
                            />
                    }
                </FormWrapperSCP>
                {/* {
                    !props.changePwdFirstAccess &&
                    <TextCP
                        text={`${recoverPassFormVisible ? 'Voltar' : 'Esqueci minha senha'}`}
                        style={{ cursor: 'pointer', fontSize: '11px', marginBottom: '40px', textDecoration: 'underline' }}
                        onClick={() => setRecoverPassFormVisible(!recoverPassFormVisible)}
                    />
                } */}
            </ContainerSCP>
        </ContentCP>
    )
}

const ContainerSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: overlay;;
    -webkit-overflow-scrolling: touch;
    height: 100%;

    background: #fff;
    .ant-typography{
        color: ${props => props.theme.primaryColor}
    }
    @media(max-width: 768px){
        background: ${props => props.theme.primaryColor};
        .ant-typography{
            color:white;
        }
    }
`

const FormWrapperSCP = styled.div`
    background: #fff;
    height: 100%;
    width: 100%;
    padding-top:20px;
    display: flex;
    justify-content: center;
    align-items: center;
   
    @media(max-width: 768px){
        background: ${props => props.theme.primaryColor}
    }
`
