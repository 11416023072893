import { CoffeeTypeEnum, ArabicaVarietyEnum, CaneforaVarietyEnum } from 'modules/field/enums/CoffeeTypeEnum'
import React from 'react'
import { IIconInfoGridColumnProps } from 'common/components/icon-info-grid/inner/IIconInfoGridColumnProps'
import { faSeedling, faCalendarAlt, faArrowsAltH, faArrowsAltV, faArrowDown, faArrowUp, faHashtag, faStar, faThLarge, faTint } from '@fortawesome/free-solid-svg-icons'
import { IFieldResponseDTO } from 'modules/field/services/dtos/responses/IFieldResponseDTO'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IGeneralFieldData } from 'modules/batch-landing-page/components/batch-traceability-content/BatchTraceabilityContentCP'
import { MaskUtils } from 'common/utils/MaskUtils'

export const FieldUtils = {

    translateArabicaVarietyEnum(value: ArabicaVarietyEnum, other?: string): string {
        switch (value) {
            case ArabicaVarietyEnum.YELLOW_CATUAI:
                return 'Catuaí Amarelo'
            case ArabicaVarietyEnum.RED_CATUAI:
                return 'Catuaí Vermelho'
            case ArabicaVarietyEnum.RUBI:
                return 'Rubi'
            case ArabicaVarietyEnum.TOPAZIO:
                return 'Topázio'
            case ArabicaVarietyEnum.MUNDO_NOVO:
                return 'Mundo Novo'
            case ArabicaVarietyEnum.YELLOW_ICATU:
                return 'Icatú Amarelo'
            case ArabicaVarietyEnum.RED_ICATU:
                return 'Icatú Vermelho'
            case ArabicaVarietyEnum.CATUCAI:
                return 'Catucaí'
            case ArabicaVarietyEnum.ARARA:
                return 'Arara'
            case ArabicaVarietyEnum.YELLOW_BOURBON:
                return 'Bourbon Amarelo'
            case ArabicaVarietyEnum.RED_BOURBON:
                return 'Bourbon Vermelho'
            case ArabicaVarietyEnum.PINK_BOURBON:
                return 'Bourbon Rosa'
            case ArabicaVarietyEnum.PARAISO:
                return 'Paraiso'
            case ArabicaVarietyEnum.GEISHA:
                return 'Geisha'
            case ArabicaVarietyEnum.MARAGOGIPE:
                return 'Maragogipe'
            case ArabicaVarietyEnum.ACAIA:
                return 'Acaia'
            default:
                return ''
        }
    },
    translateVarietysEnum(value: ArabicaVarietyEnum | CaneforaVarietyEnum, other?: string): string {
        console.log(value, other)
        switch (value) {
            case ArabicaVarietyEnum.YELLOW_CATUAI:
                return 'Catuaí Amarelo'
            case ArabicaVarietyEnum.RED_CATUAI:
                return 'Catuaí Vermelho'
            case ArabicaVarietyEnum.RUBI:
                return 'Rubi'
            case ArabicaVarietyEnum.TOPAZIO:
                return 'Topázio'
            case ArabicaVarietyEnum.MUNDO_NOVO:
                return 'Mundo Novo'
            case ArabicaVarietyEnum.YELLOW_ICATU:
                return 'Icatú Amarelo'
            case ArabicaVarietyEnum.RED_ICATU:
                return 'Icatú Vermelho'
            case ArabicaVarietyEnum.CATUCAI:
                return 'Catucaí'
            case ArabicaVarietyEnum.ARARA:
                return 'Arara'
            case ArabicaVarietyEnum.YELLOW_BOURBON:
                return 'Bourbon Amarelo'
            case ArabicaVarietyEnum.RED_BOURBON:
                return 'Bourbon Vermelho'
            case ArabicaVarietyEnum.PINK_BOURBON:
                return 'Bourbon Rosa'
            case ArabicaVarietyEnum.PARAISO:
                return 'Paraiso'
            case ArabicaVarietyEnum.GEISHA:
                return 'Geisha'
            case ArabicaVarietyEnum.MARAGOGIPE:
                return 'Maragogipe'
            case ArabicaVarietyEnum.ACAIA:
                return 'Acaia'
            case CaneforaVarietyEnum.ROBUSTA:
                return 'Robusta'
            case CaneforaVarietyEnum.CONILON:
                return 'Conilon'
            default:
                return other ?? value
        }
    },
    translateCoffeeTypeEnum(value: CoffeeTypeEnum): string {
        switch (value) {
            case CoffeeTypeEnum.ARABICA:
                return 'Arábica'
            case CoffeeTypeEnum.CANEFORA:
                return 'Canéfora'
            default:
                return ''
        }
    },
    translateCaneforaVarietyEnum(value: CaneforaVarietyEnum): string {
        switch (value) {
            case CaneforaVarietyEnum.ROBUSTA:
                return 'Robusta'
            case CaneforaVarietyEnum.CONILON:
                return 'Conilon'
            default:
                return ''
        }
    },
    translateVarietyToEnglish(value: ArabicaVarietyEnum | CaneforaVarietyEnum, coffeeOther?: string): string {
        switch (value) {
            case ArabicaVarietyEnum.YELLOW_CATUAI:
                return 'Yellow Catuaí'
            case ArabicaVarietyEnum.RED_CATUAI:
                return 'Red Catuaí'
            case ArabicaVarietyEnum.RUBI:
                return 'Rubi'
            case ArabicaVarietyEnum.TOPAZIO:
                return 'Topázio'
            case ArabicaVarietyEnum.MUNDO_NOVO:
                return 'Mundo Novo'
            case ArabicaVarietyEnum.YELLOW_ICATU:
                return 'Yellow Icatú'
            case ArabicaVarietyEnum.RED_ICATU:
                return 'Red Icatú'
            case ArabicaVarietyEnum.CATUCAI:
                return 'Catucaí'
            case ArabicaVarietyEnum.ARARA:
                return 'Arara'
            case ArabicaVarietyEnum.YELLOW_BOURBON:
                return 'Yellow Bourbon'
            case ArabicaVarietyEnum.RED_BOURBON:
                return 'Red Bourbon'
            case ArabicaVarietyEnum.PINK_BOURBON:
                return 'Pink Bourbon'
            case ArabicaVarietyEnum.PARAISO:
                return 'Paraiso'
            case ArabicaVarietyEnum.GEISHA:
                return 'Geisha'
            case ArabicaVarietyEnum.MARAGOGIPE:
                return 'Maragogipe'
            case ArabicaVarietyEnum.ACAIA:
                return 'Acaia'
            case CaneforaVarietyEnum.ROBUSTA:
                return 'Robusta'
            case CaneforaVarietyEnum.CONILON:
                return 'Conilon'
            default:
                return coffeeOther ?? '-'
        }
    },
    getIconGrigColumnsConfig(): Array<IIconInfoGridColumnProps<IFieldResponseDTO>> {
        return [
            {
                title: 'Year of planting',
                key: 'plantingYear',
                renderValue: (info: IFieldResponseDTO) => `${info.plantingYear}`,
                icon: <FontAwesomeIcon icon={faCalendarAlt} />,
            },
            {
                title: 'Variety',
                key: 'coffeeType',
                renderValue: (info: IFieldResponseDTO) => this.translateVarietyToEnglish(info.coffeeVariety, info.varieties.map(v => v.name).join(', ')),
                icon: <FontAwesomeIcon icon={faSeedling} />,
            },
            {
                title: 'Minimum elevation',
                key: 'minAltitude',
                renderValue: (info: IFieldResponseDTO) => `${info.minAltitude}m`,
                icon: <FontAwesomeIcon icon={faArrowDown} />,
            },
            {
                title: 'Maximum elevation',
                key: 'maxAltitude',
                renderValue: (info: IFieldResponseDTO) => `${info.maxAltitude}m`,
                icon: <FontAwesomeIcon icon={faArrowUp} />,
            },
            {
                title: 'Row spacing',
                key: 'streetSpacing',
                renderValue: (info: IFieldResponseDTO) => `${info.streetSpacing}m`,
                icon: <FontAwesomeIcon icon={faArrowsAltH} />,
            },
            {
                title: 'Tree spacing',
                key: 'lineSpacing',
                renderValue: (info: IFieldResponseDTO) => `${info.lineSpacing}m`,
                icon: <FontAwesomeIcon icon={faArrowsAltV} />,
            },
        ]
    },

    getGeneralDataIconColumnsConfig(hasBags: boolean): Array<IIconInfoGridColumnProps<IGeneralFieldData>> {
        return [
            {
                title: 'Code',
                key: 'code',
                align: 'center',
                renderValue: (info: IGeneralFieldData) => `${info.code}`,
                icon: <FontAwesomeIcon icon={faHashtag} />,
            },
            {
                title: `${hasBags ? 'Bags' : 'Liters'}`,
                key: 'bags',
                align: 'center',
                renderValue: (info: IGeneralFieldData) => `${info.bags}`,
                icon: <FontAwesomeIcon icon={hasBags ? faThLarge : faTint} />,
            },
            {
                title: 'Score',
                key: 'score',
                align: 'center',
                renderValue: (info: IGeneralFieldData) => `${MaskUtils.applyNumberMask(info.score)} points`,
                icon: <FontAwesomeIcon icon={faStar} />,
            },
        ]
    }
}
