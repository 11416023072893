import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Descriptions } from 'antd'
import { TagCP } from 'common/components/tag/TagCP'
import { RoastProfileEnum } from 'modules/analysis/enums/RoastProfileEnum'
import { AnalysisUtils } from 'modules/analysis/utils/AnalysisUtils'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { CoffeeFlavorEnum } from 'modules/analysis/enums/CoffeeFlavorEnum'
import { CupAcidityEnum } from 'modules/analysis/enums/CupAcidityEnum'
import { CupBodyEnum } from 'modules/analysis/enums/CupBodyEnum'
import { AfterTasteEnum } from 'modules/analysis/enums/AfterTasteEnum'
import { BrewMethodEnum } from 'modules/analysis/enums/BrewMethodEnum'
import { IBatchSensoryAnalysisDTO } from '../../../services/dtos/responses/IBatchAnalysisResponseDTO'
import { IntensityEnum } from '../../../../analysis/enums/IntensityEnum'

interface ICollapseCPProps {
    item: IBatchSensoryAnalysisDTO
    language?: LanguageEnum
    hideNotes?: boolean
}

const LABELS_PT = {
    profile: 'Perfil da Torra',
    aromaOptions: 'Aroma',
    uniformidity: 'Uniformidade',
    cleanCup: 'Xícara Limpa',
    sweetness: 'Doçura',
    flavor: 'Sabor',
    acidity: 'Acidez',
    body: 'Corpo',
    defectCups: 'Xícaras com defeito',
    afterTaste: 'Sabor Residual',
    balance: 'Balanço',
    finalGrade: 'Nota Final',
    notes: 'Observações',
    brewMethods: 'Métodos indicados',
    intensity: 'Intensidade',
    salt: 'Salinidade',
}

const LABELS_EN = {
    profile: 'Profile',
    aromaOptions: 'Aroma',
    uniformidity: 'Uniformidity',
    cleanCup: 'Clean Cup',
    sweetness: 'Sweetness',
    flavor: 'Flavor',
    acidity: 'Acidity',
    body: 'Body',
    afterTaste: 'After Taste',
    defectCups: 'Defect Cups',
    balance: 'Balance',
    finalGrade: 'Final Score',
    notes: 'Notes',
    brewMethods: 'Brew Method',
    intensity: 'Intensity',
    salt: 'Salt',
}

export function SensoryAnalyseDetailsCP(props: ICollapseCPProps): JSX.Element {
    const [labels, setLabels] = useState(LABELS_PT)

    const [roastProfileTranslateFunc, setRoastProfileTranslateFunc] = useState<
        (method: RoastProfileEnum) => string
    >(() => AnalysisUtils.translateRoastProfile)
    const [flavorTranslateFunc, setFlavorTranslateFunc] = useState<
        (method: CoffeeFlavorEnum) => string
    >(() => AnalysisUtils.translateCoffeeFlavor)
    const [acidityTranslateFunc, setAcidityTranslateFunc] = useState<
        (method: CupAcidityEnum) => string
    >(() => AnalysisUtils.translateCupAcidity)
    const [bodyTranslateFunc, setBodyTranslateFunc] = useState<(method: CupBodyEnum) => string>(
        () => AnalysisUtils.translateCupBody,
    )
    const [afterTasteTranslateFunc, setAfterTasteTranslateFunc] = useState<
        (method: AfterTasteEnum) => string
    >(() => AnalysisUtils.translateAfterTaste)
    const [brewMethodTranslateFunc, setBrewMethodTranslateFunc] = useState<
        (method: BrewMethodEnum) => string
    >(() => AnalysisUtils.translateBrewMethod)

    useEffect(translateLabels, [props.language])

    function translateLabels(): void {
        if (props.language === LanguageEnum.EN) {
            setLabels(LABELS_EN)
            setRoastProfileTranslateFunc(() => AnalysisUtils.translateRoastProfileToEnglish)
            setFlavorTranslateFunc(() => AnalysisUtils.translateCoffeeFlavorToEnglish)
            setAcidityTranslateFunc(() => AnalysisUtils.translateCupAcidityToEnglish)
            setBodyTranslateFunc(() => AnalysisUtils.translateCupBodyToEnglish)
            setAfterTasteTranslateFunc(() => AnalysisUtils.translateAfterTasteToEnglish)
            setBrewMethodTranslateFunc(() => AnalysisUtils.translateBrewMethodToEnglish)
        }
    }

    return (
        <DescriptionsSCP column={1} bordered={true}>
            <Descriptions.Item label={labels.profile}>
                <TagCP
                    color={'purple'}
                    content={roastProfileTranslateFunc(
                        Object.values(RoastProfileEnum)[(props.item.roastProfile as number) - 1],
                    )}
                />
            </Descriptions.Item>
            <Descriptions.Item label={labels.aromaOptions}>
                <TagCP color={'navyblue'} content={`${props.item.aroma}`} />
                <TagCP
                    color={'navyblue'}
                    content={`${labels.intensity}: ${AnalysisUtils.translateIntensity(
                        Object.values(IntensityEnum)[(props.item.aromaIntensity as number) - 1],
                    )}`}
                />
                {(props.item.aromaOptions ?? []).map((opt) => (
                    <TagCP
                        key={opt}
                        content={flavorTranslateFunc(Object.values(CoffeeFlavorEnum)[opt - 1])}
                        color={'purple'}
                    />
                ))}
                {props.item.aromaOther && (
                    <TagCP key={'outro'} content={props.item.aromaOther} color={'purple'} />
                )}
            </Descriptions.Item>
            <Descriptions.Item label={labels.uniformidity}>
                <TagCP color={'navyblue'} content={`${props.item.uniformidity}`} />
            </Descriptions.Item>
            <Descriptions.Item label={labels.cleanCup}>
                <TagCP color={'navyblue'} content={`${props.item.cleanCup}`} />
            </Descriptions.Item>
            <Descriptions.Item label={labels.sweetness}>
                <TagCP color={'navyblue'} content={`${props.item.sweatness}`} />
                <TagCP
                    color={'navyblue'}
                    content={`${labels.intensity}: ${AnalysisUtils.translateIntensity(
                        Object.values(IntensityEnum)[(props.item.sweatnessIntensity as number) - 1],
                    )}`}
                />
            </Descriptions.Item>
            <Descriptions.Item label={labels.flavor}>
                <TagCP color={'navyblue'} content={`${props.item.flavor}`} />
                <TagCP
                    color={'navyblue'}
                    content={`${labels.intensity}: ${AnalysisUtils.translateIntensity(
                        Object.values(IntensityEnum)[(props.item.flavorIntensity as number) - 1],
                    )}`}
                />
                {(props.item.flavorOptions ?? []).map((opt) => (
                    <TagCP
                        key={opt}
                        content={flavorTranslateFunc(Object.values(CoffeeFlavorEnum)[opt - 1])}
                        color={'purple'}
                    />
                ))}
                {props.item.flavorOther && (
                    <TagCP key={'outro'} content={props.item.flavorOther} color={'purple'} />
                )}
            </Descriptions.Item>
            <Descriptions.Item label={labels.salt}>
                <TagCP
                    color={'navyblue'}
                    content={`${labels.intensity}: ${AnalysisUtils.translateIntensity(
                        Object.values(IntensityEnum)[(props.item.saltIntensity as number) - 1],
                    )}`}
                />
            </Descriptions.Item>
            <Descriptions.Item label={labels.acidity}>
                <TagCP color={'navyblue'} content={`${props.item.acidity}`} />
                <TagCP
                    color={'navyblue'}
                    content={`${labels.intensity}: ${AnalysisUtils.translateIntensity(
                        Object.values(IntensityEnum)[(props.item.acidityIntensity as number) - 1],
                    )}`}
                />
                {(props.item.acidityOptions ?? []).map((opt) => (
                    <TagCP
                        key={opt}
                        content={acidityTranslateFunc(Object.values(CupAcidityEnum)[opt - 1])}
                        color={'purple'}
                    />
                ))}
                {props.item.acidityOther && (
                    <TagCP key={'outro'} content={props.item.acidityOther} color={'purple'} />
                )}
            </Descriptions.Item>
            <Descriptions.Item label={labels.body}>
                <TagCP color={'navyblue'} content={`${props.item.body}`} />
                {(props.item.bodyOptions ?? []).map((opt) => (
                    <TagCP
                        key={opt}
                        content={bodyTranslateFunc(Object.values(CupBodyEnum)[opt - 1])}
                        color={'purple'}
                    />
                ))}
                {props.item.bodyOther && (
                    <TagCP key={'outro'} content={props.item.bodyOther} color={'purple'} />
                )}
            </Descriptions.Item>
            <Descriptions.Item label={labels.afterTaste}>
                <TagCP color={'navyblue'} content={`${props.item.afterTaste}`} />
                {(props.item.afterTasteOptions ?? []).map((opt) => (
                    <TagCP
                        key={opt}
                        content={afterTasteTranslateFunc(Object.values(AfterTasteEnum)[opt - 1])}
                        color={'purple'}
                    />
                ))}
                {props.item.afterTasteOther && (
                    <TagCP key={'outro'} content={props.item.afterTasteOther} color={'purple'} />
                )}
            </Descriptions.Item>
            <Descriptions.Item label={labels.balance}>
                <TagCP color={'navyblue'} content={`${props.item.balance}`} />
            </Descriptions.Item>
            <Descriptions.Item label={labels.finalGrade}>
                <TagCP color={'navyblue'} content={`${props.item.finalGrade}`} />
            </Descriptions.Item>
            <Descriptions.Item label={labels.defectCups}>
                <TagCP color={'navyblue'} content={`${props.item.defectCups}`} />
                <TagCP
                    color={'navyblue'}
                    content={`${labels.intensity}: ${props.item.defectIntensity}`}
                />
            </Descriptions.Item>
            <Descriptions.Item label={labels.brewMethods}>
                {(props.item.brewMethods ?? []).map((opt) => (
                    <TagCP
                        key={opt}
                        content={brewMethodTranslateFunc(Object.values(BrewMethodEnum)[opt - 1])}
                        color={'purple'}
                    />
                ))}
            </Descriptions.Item>
            {!props.hideNotes && (
                <Descriptions.Item label={labels.notes}>{props.item.notes}</Descriptions.Item>
            )}
        </DescriptionsSCP>
    )
}

const DescriptionsSCP = styled(Descriptions)`
    margin-bottom: 5em;
    &.ant-descriptions {
        margin-top: 10px;
    }

    td.ant-descriptions-item-content {
        display: flex;
        flex-wrap: wrap;
        background: #fff;
    }
    td {
        background: #fff;
    }
`
