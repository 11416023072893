import { CheckboxCP } from 'common/components/form-fields/checkbox/CheckboxCP'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { useSelector } from 'react-redux'
import { ListStateControlTP } from 'common/redux/ReduxTypes'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { ListUtils } from 'common/components/list/inner/ListUtils'
import { TooltipCP } from 'common/components/tooltip/TooltipCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { Menu, Dropdown } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'

export type ActionsConfigTP = {
    label?: string,
    action?: (code?: number) => void,
    component?: JSX.Element,
}
interface ICardHeaderICPProps {
    title: JSX.Element | string
    subtitle?: JSX.Element | string
    categoryTitle?: string | JSX.Element
    highlight?: JSX.Element | string
    highlighAction?: () => void
    highlightTooltipText?: string
    isSelected?: boolean
    onSelect?: () => void
    actions?: ActionsConfigTP[] | JSX.Element
    itemCode?: number
    isSelectable?: boolean
    style?: CSSProperties
}

/**
 * COMPONENTE
 * Item de uma lista em formato de CARD customizado para listas padrao do projeto
 */
export function CardHeaderICP(props: ICardHeaderICPProps): JSX.Element {
    const listState: ListStateControlTP = useSelector((state: IReduxState) => _.get(state, 'listState'))
    const hasStateControl = !!listState

    function getOptions(): JSX.Element {
        if (!props.actions || !props.itemCode)
            return <div> </div>

        return (
            <Menu>
                {(props.actions as ActionsConfigTP[]).map(opt => {
                    return (
                        <Menu.Item
                            key={opt.label}
                        >
                            {opt.component ?
                                opt.component :
                                <ButtonCP
                                    onClick={() => opt.action?.(props.itemCode)}
                                    type={'link'}
                                    size={'small'}
                                    style={{ padding: 4 }}
                                >
                                    {opt.label}
                                </ButtonCP>}
                        </Menu.Item>
                    )
                })}
            </Menu>
        )

    }

    return (
        <WrapperSCP style={props.style}>
            {
                props.highlight &&
                    props.highlightTooltipText ?
                    <TooltipCP text={props.highlightTooltipText}>
                        <HighLightSCP onClick={props.highlighAction} style={{ cursor: `${props.highlighAction ? 'pointer' : 'default'}` }}>
                            {props.highlight}
                        </HighLightSCP>
                    </TooltipCP>
                    :
                    <HighLightSCP onClick={props.highlighAction} style={{ cursor: `${props.highlighAction ? 'pointer' : 'default'}` }}>
                        {props.highlight}
                    </HighLightSCP>
            }
            <TitleWrapperSCP>
                <CategoryTitleSCP>{props.categoryTitle}</CategoryTitleSCP>
                <TitleSCP>
                    {props.title}
                    {' '}
                </TitleSCP>
                <SubtitleSCP>{props.subtitle}</SubtitleSCP>
            </TitleWrapperSCP>
            {
                hasStateControl && !!props.itemCode && props.isSelectable &&
                <CheckboxWrapperSCP>
                    <CheckboxCP
                        isChecked={ListUtils.checkIfIsSelected(props.itemCode)}
                        onChange={() => ListActions.addOrRemoveSelectedItem(props.itemCode!)}
                    />
                </CheckboxWrapperSCP>

            }
            {
                !!props.itemCode && !!props.actions &&
                <DropdownWrpr>
                    {Array.isArray(props.actions) ? <Dropdown arrow={true} overlay={getOptions} trigger={['click']}>
                        <FontAwsomeIconCP icon={faEllipsisV} size={'2x'} style={{ cursor: 'pointer' }} />
                    </Dropdown> : props.actions}
                </DropdownWrpr>

            }
        </WrapperSCP>

    )
}
const WrapperSCP = styled.div`
  display: grid;
  grid-template-columns: 60px 3fr .3fr;
  column-gap: 5px;
  margin: 10px 10px 0 10px;
  align-items: center;
  position: relative;
`

const HighLightSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    svg{
                    font-size: 20px;
    }
`
const TitleWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
`
const CheckboxWrapperSCP = styled.div`
    align-items: start;
    display: flex;
    justify-content: flex-end;
`
const CategoryTitleSCP = styled.div`
    font-size: 12px;
    color: ${props => props.theme.primaryColor};
    line-height: 15px;
`
const TitleSCP = styled.div`
    font-size: 12px;
    line-height: 15px;
    margin-top: 5px;
`
const SubtitleSCP = styled.div`
    font-size: 12px;
    color: ${props => props.theme.gray};
    width: 100%;

`
const DropdownWrpr = styled.div`
   position:  absolute;
   top: 8px;
   right: 2px;
   color: ${props => props.theme.primaryColor};
`
