import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { ISaveStructureReqDTO } from 'modules/structure/services/dtos/request/ISaveStructureReqDTO'
import { ISearchStructureReqDTO } from 'modules/structure/services/dtos/request/ISearchStructureReqDTO'

export class StructureRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/structure`
    private constructor() { }

    static search = (dto: ISearchStructureReqDTO): RequestConfigTP => ({
        url: `${StructureRequests.baseApiUrl}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static get = (code: number): RequestConfigTP => ({
        url: `${StructureRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static save = (dto: ISaveStructureReqDTO): RequestConfigTP => ({
        url: `${StructureRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static update = (code: number, dto: ISaveStructureReqDTO): RequestConfigTP => ({
        url: `${StructureRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${StructureRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

}
