import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { ListHeaderICP } from 'common/components/list/ListHeaderICP'
import { FinancialFieldReportItemICP } from 'modules/report/components/financial-report-by-field/inner/FinancialFieldReportItemICP'
import { IFinancialReportByFieldResDTO } from 'modules/report/services/dtos/responses/IFinancialReportByFieldResDTO'
import React from 'react'
import styled from 'styled-components'

interface IFinancialReportByFieldTableICPProps {
    reportData: IFinancialReportByFieldResDTO[]
}

export function FinancialReportByFieldTableICP(props: IFinancialReportByFieldTableICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <ListHeaderICP
                gridColumns={'1fr 1fr 1fr 1fr 1fr 1fr'}
                labels={[
                    'Talhão:',
                    'Custo da saca',
                    'Valor de venda',
                    'Custo total',
                    'Faturamento total'
                ]}
            />
            <ListCP<IFinancialReportByFieldResDTO>
                bordered={false}
                itemLayout={'vertical'}
                size={'large'}
                loadMoreData={() => { }}
                dataSource={props.reportData ?? []}
                hasStateControl={true}
                loading={false}
                locale={{
                    emptyText: <EmptyCP description={'Sem resultados para os fitros selecionados'} />
                }}
                renderItem={(item: IFinancialReportByFieldResDTO) => (
                    <FinancialFieldReportItemICP key={`${item.field.code}_${Math.random()}`} reportData={item} loading={false} />
                )}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
background: white;
padding: 5px;
border-radius: 8px;

`
