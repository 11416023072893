import { DividerCP } from 'common/components/divider/DividerCP'
import { TextCP } from 'common/components/text/TextCP'

import { IImplementFuelResDTO } from 'modules/harvest-planning/services/dtos/response/ImplementFuelResDTO'

import { IImplementResponseDTO } from 'modules/maneuver/services/dtos/responses/IImplementResponseDTO'
import { IProductUsageResDTO } from 'modules/maneuver/services/dtos/responses/IProductUsageResDTO'
import { ManeuverUtils } from 'modules/maneuver/utils/ManeuverUtils'
import React from 'react'
import styled from 'styled-components'
import { MaskUtils } from '../../../../../common/utils/MaskUtils'

interface IImplementDetailsICPProps {
    implement: IImplementResponseDTO
    implementFuelConsume?: IProductUsageResDTO
    summary?: IImplementFuelResDTO

}

export function ImplementDetailsICP(props: IImplementDetailsICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <DividerCP text={`Dados do ${ManeuverUtils.translateImplementType(props.implement.type)}`} orientation={'left'} />
            <GridSCP>
                <RowSCP>
                    <TextCP text={'Marca:\u00A0'} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.implement.brand}`} />
                </RowSCP>
                <RowSCP>
                    <TextCP text={'Modelo:\u00A0'} style={{ fontWeight: '500' }} />
                    <TextCP text={`${props.implement.model} `} />
                </RowSCP>
                <RowSCP>
                    <TextCP text={'Ano:\u00A0'} style={{ fontWeight: '500' }} />
                    <TextCP text={`${props.implement.year} `} />
                </RowSCP>
                {
                    !!props.implement.capacity &&
                    <RowSCP>
                        <TextCP text={'Capaciadade:\u00A0'} style={{ fontWeight: '500' }} />
                        <TextCP text={`${MaskUtils.applyNumberMask(props.implement.capacity)}`} />
                    </RowSCP>
                }
                {
                    !!props.summary && props.summary.fuelConsume &&
                    <RowSCP>
                        <TextCP text={'Gasto de combustível:\u00A0'} style={{ fontWeight: '500' }} />
                        <TextCP text={`${MaskUtils.applyNumberMask(props.summary.fuelConsume.quantity)} litros`} />
                    </RowSCP>
                }
                {
                    !!props.summary && !!props.summary.currentHourMeter &&
                    <RowSCP>
                        <TextCP text={'Horímetro atual:\u00A0'} style={{ fontWeight: '500' }} />
                        <TextCP text={`${props.summary.currentHourMeter} horas`} />
                    </RowSCP>
                }

            </GridSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
const GridSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography{
        font-size: 12px;
    }
    
`