import { PaginationConfig } from 'antd/lib/pagination'
import moment from 'moment-timezone'
import { CardCP } from 'common/components/card/CardCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { ListCP } from 'common/components/list/ListCP'
import { TextCP } from 'common/components/text/TextCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'

import { HarvestGeneralReportCP } from 'modules/harvest-planning/components/harvest-general-report/HarvestGeneralReportCP'
import { HarvestPlanningFiltersCP } from 'modules/harvest-planning/components/harvest-planning-filters/HarvestPlanningFiltersCP'
import { HarvestPlanningCardICP } from 'modules/harvest-planning/components/harvest-planning-list/inner/HarvestPlanningCardICP'
import { ISearchHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISearchHarvestPlanningReqDTO'
import { ISearchHarvestPlanningResDTO } from 'modules/harvest-planning/services/dtos/response/ISearchHarvestPlanningResDTO'
import { HarvestPlanningRequests } from 'modules/harvest-planning/services/HarvestPlanningRequests'

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { SearchDataFilterTP } from 'common/redux/ReduxTypes'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchHarvestPlanningReqDTO = {
    season: moment().year(),
}

interface IHarvestPlanningListCPProps {

}

export function HarvestPlanningListCP(props: IHarvestPlanningListCPProps): JSX.Element {
    const screenSize = useScreenSize()

    const [harvestList, setHarvestList] = useState<ISearchHarvestPlanningResDTO[]>([])
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const currentFilters: ISearchHarvestPlanningReqDTO | null = useSelector((state: IReduxState) => _.get(state, 'searchState.filter'))
    const [pagination, setPagination] = useState<PaginationConfig>()
    const [total, setTotal] = useState<number>(0)

    const type: FilterTypesEnum = useSelector((state: IReduxState) => _.get(state, 'searchState.type'))

    const request = useRequest<IGenericListResponseDTO<ISearchHarvestPlanningResDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(getHarvestList, [currentFilters])
    useEffect(onItemChange, [updateStatus])
    useEffect(loadPersistedData, [])

    function loadPersistedData(): void {

        if (type !== FilterTypesEnum.HARVEST_PLANNING)
            return SearchActions.setFilter<ISearchHarvestPlanningReqDTO>(DEFAULT_FILTERS, FilterTypesEnum.HARVEST_PLANNING)

        if (!currentFilters) {
            SearchActions.setFilter<ISearchHarvestPlanningReqDTO>({
                ...DEFAULT_FILTERS,
                page: DEFAULT_PAGINATION.current,
                itemsPerPage: DEFAULT_PAGINATION.pageSize,
            }, FilterTypesEnum.HARVEST_PLANNING)
        } else {
            SearchActions.setFilter<ISearchHarvestPlanningReqDTO>({
                ...currentFilters,
                page: DEFAULT_PAGINATION.current,
                itemsPerPage: DEFAULT_PAGINATION.pageSize,
            }, FilterTypesEnum.HARVEST_PLANNING)
        }
    }
    function getHarvestList(): void {

        if (!!currentFilters && type === FilterTypesEnum.HARVEST_PLANNING)
            request.runRequest(HarvestPlanningRequests.search(currentFilters))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE) {
            setHarvestList([])
            SearchActions.setFilter<ISearchHarvestPlanningReqDTO>({
                ...DEFAULT_FILTERS,
                page: DEFAULT_PAGINATION.current,
                itemsPerPage: DEFAULT_PAGINATION.pageSize,
            }, FilterTypesEnum.HARVEST_PLANNING)
        }
    }

    function onChangeFilter(filter?: ISearchHarvestPlanningReqDTO): void {
        setHarvestList([])
        SearchActions.setFilter({
            ...filter,
            page: DEFAULT_PAGINATION.current,
            itemsPerPage: DEFAULT_PAGINATION.pageSize,
        }, FilterTypesEnum.HARVEST_PLANNING)
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried) {
            ListActions.isUpdating()
            return
        }

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar lista de atividades!')
        }
        setHarvestList(request.returnData.list)
        setTotal(request.returnData.total)
        setPagination({
            ...pagination,
            total: request.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                SearchActions.setFilter({ ...currentFilters, page, itemsPerPage: pageSize }, FilterTypesEnum.HARVEST_PLANNING)
            }
        })
        ListActions.updateIsDone()
    }

    return (
        <WrapperSCP>
            <HeaderSCP>
                <HarvestPlanningFiltersCP
                    setFilters={onChangeFilter}
                    filters={currentFilters && type === FilterTypesEnum.HARVEST_PLANNING ? currentFilters : undefined}
                />
                {
                    !screenSize.smd &&
                    <HarvestGeneralReportCP
                        filters={currentFilters && type === FilterTypesEnum.HARVEST_PLANNING ? currentFilters : undefined}
                    />
                }
            </HeaderSCP>

            <TotalSCP>
                <TextCP
                    text={`${total} registros encontrados`}
                />
                <TextCP
                    text={'Limpar filtros'}
                    onClick={() => {
                        setPagination(DEFAULT_PAGINATION)
                        SearchActions.setFilter({
                            ...DEFAULT_FILTERS,
                            page: DEFAULT_PAGINATION.current,
                            itemsPerPage: DEFAULT_PAGINATION.pageSize,
                        }, FilterTypesEnum.HARVEST_PLANNING)
                    }}
                    style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
                />
            </TotalSCP>
            <ListCP<ISearchHarvestPlanningResDTO>
                grid={{ gutter: 16, column: 3, md: 1, sm: 1, xs: 1, lg: 2, xl: 3, xxl: 3 }}
                loading={request.awaiting}
                bordered={false}
                itemLayout={'vertical'}
                size={'large'}
                dataSource={harvestList}
                hasStateControl={true}
                locale={{
                    emptyText: <CardCP noSpacing={true} noSideSpacing={true}>
                        {' '}
                        <EmptyCP description={'Você ainda não tem planejamentos.'} />
                        {' '}
                    </CardCP>
                }}
                renderItem={(item: ISearchHarvestPlanningResDTO) => (
                    <HarvestPlanningCardICP
                        item={item}
                        loading={request.awaiting}
                    />
                )}
                pagination={pagination}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
     border-radius: 10px;
    margin-bottom: 4em;
    max-height: 75vh;   
     overflow: overlay;
     -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    } 
`

const Empty = styled.div`
     border-radius: 10px;
    @media(max-width: 786px){
        margin-left: 0;    
    }
`

const TotalSCP = styled.div`
    margin-left: 3px;
        display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      
   
    }
    @media(max-width: 768px){        
    margin-top: 7px;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`
const HeaderSCP = styled.div`
    display: grid;
    grid-template-columns: .7fr 1fr;
    column-gap: 10px;
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`