import React from 'react'
import styled from 'styled-components'
import { Image } from 'antd'
interface ICarouselItemICPProps {
    subTitile?: string
    imageUrl?: string
    imgAlt?: string
    minHeight?: number
    children?: JSX.Element
}

/**
 * COMPONENTE para exibição de 1 item de carrosel
 * @author kiaravzm
 */
export function CarouselItemICP(props: ICarouselItemICPProps): JSX.Element {

    return (

        <CarouselItemWrapperSCP>
            {
                props.imageUrl &&
                <Image
                    width={'100%'}
                    src={props.imageUrl}
                    preview={{ mask: <MaskWrapperSCP /> }}
                />
            }
            {props.children}
        </CarouselItemWrapperSCP>
    )
}

const CarouselItemWrapperSCP = styled.div`
    img {
        object-fit: cover;
        width: 100%;
        max-height: 350px;
    }
`

const MaskWrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    opacity: .5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
`