import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { MoneyUtils } from 'common/utils/MoneyUtils'
import React from 'react'
import styled from 'styled-components'

interface IPlantingInfoICPProps{
    totalArea: number
    altitude: {min: number, max: number}
    spacing: {line: number, street: number}
    treeCount: number
}
const LABELS = {
    treeCount: 'N. de PÉS:',
    totalArea: 'ÁREA TOTAL:',
    maxAlt: 'ALTITUDE MÁX.:',
    minAlt: 'ALTITUDE MIN.:',
    lineSpacing: 'ESPAÇAMENTO ENTRE PÉS:',
    streetSpacing: 'ESPAÇAMENTO ENTRE RUAS:'

}
export function PlantingInfoICP(props: IPlantingInfoICPProps): JSX.Element {
    const screenSize = useScreenSize()
    return (
        <WrapperSCP>
            <TextCP text={`${screenSize.xs ? LABELS.treeCount : ''} ${props.treeCount} `} size={screenSize.xs ? 'small' : 'normal'} />
            <TextCP text={`${screenSize.xs ? LABELS.totalArea : ''} ${MoneyUtils.format(props.totalArea.toFixed(2))} ha`} size={screenSize.xs ? 'small' : 'normal'} />
            <TextCP text={`${screenSize.xs ? LABELS.minAlt : ''} ${MoneyUtils.format(props.altitude.min.toFixed(2))} m`} size={screenSize.xs ? 'small' : 'normal'} />
            <TextCP text={`${screenSize.xs ? LABELS.maxAlt : ''} ${MoneyUtils.format(props.altitude.max.toFixed(2))} m`} size={screenSize.xs ? 'small' : 'normal'} />
            <TextCP text={`${screenSize.xs ? LABELS.lineSpacing : ''} ${props.spacing.line} m`} size={screenSize.xs ? 'small' : 'normal'} />
            <TextCP text={`${screenSize.xs ? LABELS.streetSpacing : ''} ${props.spacing.street} m`} size={screenSize.xs ? 'small' : 'normal'} />
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 5px;
    .ant-typography{
        text-align: center;
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`