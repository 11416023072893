import { faPrescriptionBottle } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { ProductDrawerCP } from 'modules/maneuver/components/product-drawer/ProductDrawerCP'
import { PurchaseDrawerCP } from 'modules/maneuver/components/purchase-drawer/PurchaseDrawerCP'

import { ProductListCP } from 'modules/maneuver/components/product-list/ProductListCP'

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import { ButtonCP } from 'common/components/button/ButtonCP'

export function ProductScreen(): JSX.Element {

    const [showProductFormDrawer, setShowProductFormDrawer] = useState<boolean>(false)
    const [showPurchseFormDrawer, setShowPurchaseFormDrawer] = useState<boolean>(false)
    const history = useHistory()

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={'Estoque'}
                    icon={
                        <FontAwsomeIconCP icon={faPrescriptionBottle} />
                    }
                >
                    <ButtonCP
                        icon={<PlusOutlined />}
                        size={'middle'}
                        type={'ghost'}
                        onClick={() => setShowProductFormDrawer(true)}
                    >
                        Novo
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>

                    <ProductListCP />
                    <ProductDrawerCP
                        show={showProductFormDrawer}
                        onSuccess={() => {
                            setShowProductFormDrawer(false)
                        }}
                        onCancel={() => {
                            setShowProductFormDrawer(false)
                        }}
                    />
                    <PurchaseDrawerCP
                        show={showPurchseFormDrawer}
                        onSuccess={() => {
                            setShowPurchaseFormDrawer(false)
                        }}
                        onCancel={() => {
                            setShowPurchaseFormDrawer(false)
                        }}
                    />
                </ContentCP>
            }
        />
    )
}

const TotalSCP = styled.div`
    margin-left: 3px;
        display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color:white;      
   
    }
    @media(max-width: 768px){        
    margin-top: 17px;
        .ant-typography {
            color: ${props => props.theme.primaryColor};
        }
    }
`