import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { FileUtils } from 'common/utils/FileUtils'
import { SystemConfig } from 'config/SystemConfig'
import { CompanyFormValidator } from 'modules/company/components/company-drawer/inner/CompanyFormValidator'
import { CompanyLogoUploaderCP } from 'modules/company/components/company-drawer/inner/CompanyLogoUploaderCP'
import { SchemaSearchInputCP } from 'modules/company/components/schema-search-input/SchemaSearchInputCP'
import { CompanyRequests } from 'modules/company/services/CompanyRequests'
import { ISaveCompanyReqDTO } from 'modules/company/services/dtos/requests/ISaveCompanyReqDTO'
import { ICompanyResDTO } from 'modules/company/services/dtos/response/ICompanyResDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface ICompanyDrawerCPProps {
    companyCode?: number
    show: boolean
    onCancel: () => void
    onSuccess: () => void
}

const MAX_FILE_SIZE = 5
export function CompanyDrawerCP(props: ICompanyDrawerCPProps): JSX.Element {

    const screenSize = useScreenSize()

    const [sysAdminCode, setSysAdminCode] = useState<number | undefined>()
    const [selectedColor, setSelectedColor] = useState<string>()
    const [formValidator, setFormValidator] = useState<CompanyFormValidator>(new CompanyFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const [companyCode, setCompanyCode] = useState<number | undefined>(props.companyCode)

    const [imgFile, setImageFile] = useState<File>()
    const [imgUrl, setImgUrl] = useState<string>()

    const [iconFile, setIconFile] = useState<File>()
    const [iconUrl, setIconUrl] = useState<string>()

    const [logoInvFile, setLogoInvFile] = useState<File>()
    const [logoInvUrl, setLogoInvUrl] = useState<string>()

    useEffect(init, [props.show])

    const saveImageReq = useRequest<{ url: string }>()
    useEffect(onSaveLogoReqChange, [saveImageReq.awaiting])

    const saveRequest = useRequest<ICompanyResDTO>()
    useEffect(() => { onRequestChange() }, [saveRequest.awaiting])

    const getDetailsReq = useRequest<ICompanyResDTO>()
    useEffect(onGetDetailsReqChange, [getDetailsReq.awaiting])

    useEffect(getDetails, [props.companyCode, props.show])

    function init(): void {
        if (!!props.show && !props.companyCode) {
            setFormValidator(new CompanyFormValidator({
                name: '',
                email: '',
                color: '#7909ad',
                domain: `${SystemConfig.getInstance().uiBaseUrl}/nomedaempresa`
            }))
            setSelectedColor('#7909ad')
        }
    }

    function getDetails(): void {
        if (!!props.companyCode && props.show)
            getDetailsReq.runRequest(CompanyRequests.findOne(props.companyCode))
    }

    function onGetDetailsReqChange(): void {
        if (getDetailsReq.awaiting || !getDetailsReq.tried)
            return

        if (!getDetailsReq.success || !getDetailsReq.returnData) {
            if (getDetailsReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getDetailsReq.returnData, getDetailsReq.error)
            return RequestUtils.showDefaultErrorNotification(getDetailsReq.error, 'Ocorreu um erro ao tentar buscar os dados da empresa. Tente novamente.')
        }

        const item: ISaveCompanyReqDTO = {
            name: getDetailsReq.returnData.name,
            domain: getDetailsReq.returnData.domain,
            color: getDetailsReq.returnData.color,
            email: getDetailsReq.returnData.email,
            sysAdminCode
        }
        setFormValidator(new CompanyFormValidator(item))
        setSelectedColor(getDetailsReq.returnData.color)
        setImgUrl(getDetailsReq.returnData.logo)
        setIconUrl(getDetailsReq.returnData.icon)
        setLogoInvUrl(getDetailsReq.returnData.inverseLogo)

    }

    async function saveImage(_companyCode: number): Promise<void> {

        if (!_companyCode)
            return NotificationHelper.warning('Ocorreu algum erro, tente novamente!')


        if (!!imgFile) {
            const formData = new FormData()
            formData.append('logo', imgFile)
            await saveImageReq.runRequest(CompanyRequests.saveLogo(_companyCode, 'logo', formData))

        }

        if (!!iconFile) {
            const formData = new FormData()
            formData.append('icon', iconFile)
            await saveImageReq.runRequest(CompanyRequests.saveLogo(_companyCode, 'icon', formData))

        }

        if (!!logoInvFile) {
            const formData = new FormData()
            formData.append('inverseLogo', logoInvFile)
            await saveImageReq.runRequest(CompanyRequests.saveLogo(_companyCode, 'inverse-logo', formData))

        }
    }

    function onSaveLogoReqChange(): void {
        if (saveImageReq.awaiting || !saveImageReq.tried)
            return

        if (!saveImageReq.success || !saveImageReq.returnData)
            NotificationHelper.error('Falha ao  salvar imagem')
    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)

        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveCompanyReqDTO = {
            name: formValues.name,
            domain: formValues.domain,
            color: formValues.color,
            email: formValues.email,
            sysAdminCode
        }

        saveRequest.runRequest(props.companyCode ?
            CompanyRequests.update(props.companyCode, dto) :
            CompanyRequests.save(dto))
    }

    async function onRequestChange(): Promise<void> {
        if (saveRequest.awaiting || !saveRequest.tried)
            return

        if (!saveRequest.success || !saveRequest.returnData) {
            if (saveRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', saveRequest.returnData, saveRequest.error)
            return RequestUtils.showDefaultErrorNotification(saveRequest.error, 'Erro ao salvar estrutura!')
        }

        await saveImage(saveRequest.returnData.code)

        NotificationHelper.success('Item salvo com sucesso!')
        formStateManager.reset()
        props.onSuccess()
        ListActions.mustUpdate()

    }

    function validateImage(file: File, type: 'logo' | 'icon' | 'logoInverse'): boolean {

        const allowedTypes = ['image/jpeg', 'image/png']
        if (!allowedTypes.includes(file.type)) {
            NotificationHelper.error('Apenas JPEG e PNG são permitidos')
            return false
        }

        const isLt5M = file.size / 1024 / 1024 < MAX_FILE_SIZE
        if (!isLt5M) {
            NotificationHelper.error('Somente é permitido arquivos menores que 5 MB')
            return false
        }

        FileUtils.getBase64(file, imageUrl => {
            if (type === 'logo')
                setImgUrl(imageUrl)
            if (type === 'icon')
                setIconUrl(imageUrl)
            if (type === 'logoInverse')
                setLogoInvUrl(imageUrl)
        })

        return false
    }

    return (
        <DrawerCP
            title={props.companyCode ? `Editar empresa #${props.companyCode}` : 'Nova empresa'}
            visible={props.show}
            width={'60%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFormSubmit}
                        loading={saveRequest.awaiting || getDetailsReq.awaiting}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {
                props.onCancel()
            }}
        >

            <WrapperSCP>
                <InputCP
                    label={'Nome'}
                    placeholder={'Nome'}
                    fieldName={'name'}
                    type={'text'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    loading={saveRequest.awaiting || getDetailsReq.awaiting}
                />

                <SchemaSearchInputCP
                    formStateManager={formStateManager}
                    loading={saveRequest.awaiting || getDetailsReq.awaiting}
                    disabled={!!props.companyCode}
                    setSysAdminCode={setSysAdminCode}
                />

                <InputCP
                    label={'Domínio'}
                    placeholder={'Domínio'}
                    fieldName={'domain'}
                    type={'text'}
                    formStateManager={formStateManager}
                    required={true}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    loading={saveRequest.awaiting || getDetailsReq.awaiting}
                />


                <InfoWarningSCP>
                    <TextWrapperSCP>
                        <TextCP text={'Caso o domínio seja personalizado (ex. minhaempresa.com.br/app) lembre-se que é necessário fazer as devidas configurações antes de acessar o sistema.'} />
                        <TextCP text={'Solicitar o redirecionamento para o domínio Flowins. Ex.: sistema.flowins.me/minhaempresa'} />
                    </TextWrapperSCP>
                </InfoWarningSCP>

                <TwoColWrapperSCP>
                    <InputCP
                        label={'Cor primária:'}
                        type={'text'}
                        placeholder={'Ex.: #000000'}
                        fieldName={'color'}
                        maxLength={7}
                        onChange={setSelectedColor}
                        formStateManager={formStateManager}
                        required={true}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        icon={<span>HEX</span>}
                        loading={saveRequest.awaiting || getDetailsReq.awaiting}
                    />
                    <ColorPreviewSCP style={{ background: `${selectedColor ?? '#000'}` }} />

                </TwoColWrapperSCP>
            </WrapperSCP>
            <LogosWppColWrapperSCP>
                <CompanyLogoUploaderCP
                    imageUrl={imgUrl}
                    fileTypes={'.png, .jpg'}
                    multiple={false}
                    label={'Logo da empresa *'}
                    showUploadList={false}
                    loading={saveRequest.awaiting || saveImageReq.awaiting}
                    onChange={(info) => setImageFile(info.file)}
                    beforeUpload={(file) => validateImage(file, 'logo')}
                    instructions={'200px x 50px'}
                    style={{ width: '100%', height: '400px' }}

                />


                <CompanyLogoUploaderCP
                    imageUrl={logoInvUrl}
                    fileTypes={'.png, .jpg'}
                    multiple={false}
                    showUploadList={false}
                    loading={saveRequest.awaiting || saveImageReq.awaiting}
                    onChange={(info) => setLogoInvFile(info.file)}
                    beforeUpload={(file) => validateImage(file, 'logoInverse')}
                    label={'Logo Invertida *'}
                    instructions={'200px x 50px'}
                    style={{ width: '100%', height: '400px' }}

                />
                <CompanyLogoUploaderCP
                    imageUrl={iconUrl}
                    fileTypes={'.png, .jpg'}
                    multiple={false}
                    showUploadList={false}
                    loading={saveRequest.awaiting || saveImageReq.awaiting}
                    onChange={(info) => setIconFile(info.file)}
                    beforeUpload={(file) => validateImage(file, 'icon')}
                    label={'Ícone *'}
                    instructions={'50px x 50px'}
                    style={{ width: '100%', height: '400px' }}

                />
            </LogosWppColWrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    row-gap: 10px;

`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    align-items: end;
`

const TextWrapperSCP = styled.div`
   display: flex;
   flex-direction: column;
   .ant-typography {
        font-size: 12px;
        color: ${props => props.theme.gray}
    }
    @media(max-width: 768px){
        .ant-typography {
            color: white;
        }
    }
    
`

const InfoWarningSCP = styled.div`
    padding: 10px;
     margin: 10px 0;
    border: 1px solid ${props => props.theme.primaryColor};
    border-radius: 8px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 10px;
    column-gap: 5px;
    svg {
        font-size: 20px;
        color: ${props => props.theme.primaryColor}
    }
    @media(max-width: 768px){
        color: white;
        border: 1px solid white;
        svg {
        color: white;
      }
    }
`

const ColorPreviewSCP = styled.div`
    width: 100%;
    height: 42px;
    margin-bottom: 0.5em;
    border-radius: 3px;
`

const LogosWppColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr .7fr;
    column-gap: 10px;
    align-items: end;

`