/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { IsOptional, ValidateIf } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsIn } from 'common/validation/decorators/IsIn'
import { IsString } from 'common/validation/decorators/IsString'
import { ImplementTypeEnum } from 'modules/maneuver/enums/ImplementTypeEnum'
import { IImplementResponseDTO } from 'modules/maneuver/services/dtos/responses/IImplementResponseDTO'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'

export class ImplementFormValidator extends FormModelValidator {
    @IsIn(Object.values(ImplementTypeEnum))
    @IsNotEmpty()
    type: ImplementTypeEnum

    @IsString()
    @IsNotEmpty()
    brand: string

    @IsString()
    @IsNotEmpty()
    model: string

    @IsNotEmpty()
    year: number

    @IsNotEmpty()
    purchaseYear: number

    @IsNotEmpty()
    paidValue: number

    @IsOptional()
    lastRevisionDate: string

    @ValidateIf((e: ImplementFormValidator) => e.type === ImplementTypeEnum.VEHICLE)
    @IsOptional()
    lastRevisionHourMeter: number

    @ValidateIf((e: ImplementFormValidator) => e.type === ImplementTypeEnum.VEHICLE)
    @IsNotEmpty()
    currentHourMeter: number

    @ValidateIf((e: ImplementFormValidator) => e.type === ImplementTypeEnum.VEHICLE)
    @IsNotEmpty()
    purchaseHourMeter: number

    @IsOptional()
    capacity: number

    @IsOptional()
    solidCapacity: number

    @IsOptional()
    fuelCode: number

    constructor(dto?: IImplementResponseDTO) {
        super(dto)

        Object.assign(this, dto)
        if (dto?.lastRevisionDate)
            this.lastRevisionDate = DateUtils.toMoment(dto.lastRevisionDate, DateFormatEnum.EUA_WITHOUT_TIME).format('DD/MM/YYYY')
        if (dto?.capacity)
            this.capacity = dto.capacity

        if (dto?.solidCapacity)
            this.solidCapacity = dto.solidCapacity

    }
}
