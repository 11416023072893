import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { BatchProcessTypeEnum } from 'modules/batch/enums/BatchProcessTypeEnum'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

interface IBatchProcessTypeSelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    label?: string
    value?: BatchProcessTypeEnum
    required?: boolean
    onSelect?: (value: BatchProcessTypeEnum) => void
    filterOptions?: BatchProcessTypeEnum[]
    disabled?: boolean
}

export function BatchProcessTypeSelectorCP<FModelTP extends FormModelValidator = any>(props: IBatchProcessTypeSelectorCPProps<FModelTP>): JSX.Element {

    function getOptions(): SelectOptionTP[] {
        let values: BatchProcessTypeEnum[] = []

        if (!!props.filterOptions && !_.isEmpty(props.filterOptions)) {
            values = Object.values(BatchProcessTypeEnum).filter(type => props.filterOptions!.includes(type))
            return values.map(e => ({ label: BatchUtils.translateProcessType(e), value: e }))
        }

        values = Object.values(BatchProcessTypeEnum)
        return values.map(e => ({ label: BatchUtils.translateProcessType(e), value: e }))

    }

    return (
        <SelectCP
            options={getOptions()}
            label={props.label}
            placeholder={props.label}
            formStateManager={props.formStateManager}
            fieldName={'type'}
            required={props.required}
            onChange={props.onSelect}
            value={props.value}
            disabled={props.disabled}
        />
    )
}

const WrapperSCP = styled.div`
`
