import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { BestBatchItemICP } from 'modules/report/components/field-report-content/inner/BestBatchItemICP'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IFieldReportResDTO } from 'modules/report/services/dtos/responses/IFieldReportResDTO'
import { IBatchResumeResDTO } from 'modules/report/services/dtos/responses/IBatchResumeResDTO'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'
import { ReportRequests } from 'modules/report/services/ReportRequests'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { TextCP } from 'common/components/text/TextCP'

interface IBestBatchListCPProps {
    loading: boolean
    filters: ISearchReportReqDTO
    showTitle?: boolean
}

export function BestBatchListCP(props: IBestBatchListCPProps): JSX.Element {
    const [rankingList, setRankingList] = useState<IBatchResumeResDTO[]>()

    const getRankingReq = useRequest<IGenericListResponseDTO<IBatchResumeResDTO>>()
    useEffect(onRequestChange, [getRankingReq.awaiting])
    useEffect(getRanking, [props.filters])

    function getRanking(): void {
        if (!!props.filters.propertyCode)
            getRankingReq.runRequest(ReportRequests.getBatchRanking(props.filters))
    }

    function onRequestChange(): void {
        if (getRankingReq.awaiting || !getRankingReq.tried)
            return

        if (!getRankingReq.success || !getRankingReq.returnData) {
            if (getRankingReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getRankingReq.returnData, getRankingReq.error)
            return RequestUtils.showDefaultErrorNotification(getRankingReq.error, 'Erro ao buscar dados!')
        }
        setRankingList(getRankingReq.returnData.list)
    }

    return (
        <WrapperSCP>
            {
                !!props.showTitle &&
                <HeaderSCP>
                    <TextCP text={'Melhores lotes'} style={{ fontSize: '15px', fontWeight: 600 }} />
                </HeaderSCP>
            }
            <ListWppSCP>
                <ListCP<IBatchResumeResDTO>
                    bordered={false}
                    itemLayout={'vertical'}
                    size={'large'}
                    loadMoreData={() => { }} dataSource={rankingList ?? []}
                    loading={getRankingReq.awaiting}
                    locale={{
                        emptyText: <EmptyCP description={'Sem resultados para os fitros selecionados'} />
                    }}
                    renderItem={(item: IBatchResumeResDTO, index: number) => (
                        <BestBatchItemICP key={index} batch={item} loading={getRankingReq.awaiting} />
                    )}
                />
            </ListWppSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 5px;
    border-radius: 8px;
    
    .ant-typography {
        font-size: 12px;
    }
`
const ListWppSCP = styled.div`
    background: #fff;
    border-radius: 8px;
`

const HeaderSCP = styled.div`
    display: flex;
    justify-content: flex-start;
    
    margin: 15px 0;
`