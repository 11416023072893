import { IsOptional, IsString } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { ISaveHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISaveHarvestPlanningReqDTO'

export class HarvestPlanningFormValidator extends FormModelValidator {

    @IsNotEmpty()
    fieldCode: number

    @IsNotEmpty()
    season: number

    @IsOptional()
    @IsString()
    plannedStartDate: string

    @IsOptional()
    @IsString()
    plannedEndDate: string

    @IsOptional()
    estimatedLiters: number

    constructor(dto?: ISaveHarvestPlanningReqDTO) {
        super(dto)
        Object.assign(this, dto)

    }
}
