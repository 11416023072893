import { List, Pagination } from 'antd'
import { ListProps } from 'antd/lib/list'
import { ListActions } from 'common/components/list/inner/ListActions'
import React, { CSSProperties, useEffect } from 'react'
import { PaginationConfig } from 'antd/lib/pagination'
import styled from 'styled-components'

export interface IListCPProps<T> extends ListProps<T> {
    wrapperStyle?: CSSProperties
    hasStateControl?: boolean
    onPaginationChange?: (pagination: PaginationConfig) => void
    pagination?: PaginationConfig
    loadMoreData?: any
}

/**
 * Lista genérica com opcao de controle de estado via redux
 * @author Stella
 */
export function ListCP<T>(props: IListCPProps<T>): JSX.Element {

    useEffect(() => {
        if (props.hasStateControl && !!props.dataSource && !!props.dataSource.length)
            return ListActions.setList(props.dataSource)

        // ListActions.clearListState()
    }, [props.hasStateControl, props.dataSource])

    return (
        <WrapperSCP style={props.wrapperStyle}>
            <List<T>
                bordered={true}
                {...props}
                loading={props.loading}
                dataSource={props.loading ? [{}, {}, {}, {}, {}, {}] as T[] : props.dataSource}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
 
    height: 100%;
    margin-bottom: 12em;
    position:  relative;
    .ant-list {
        border-radius: 10px;
        height: 100%;
        overflow-x: hidden!important;
        padding: 0!important;
        .ant-spin-nested-loading {
            flex: 1;
            
            .ant-spin-dot-item{
                background-color: ${props => props.theme.primaryColor};
            }
        }
        .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot{
            .ant-spin-dot-item{
                background-color: ${props => props.theme.primaryColor};
            }
        }

        .ant-list-pagination {
            padding: 5px 0;
            margin: 16px 0;
            display: flex;
            justify-content: center;
            border-radius: 4px;
            .ant-pagination-item{
                border-radius: 4px;
                background-color: ${props => props.theme.lightGrey};
                border: none;
            }
            .ant-pagination-item-active{
                background:  ${props => props.theme.primaryColor};
                border-radius: 4px;
                a {
                    color: white
                }
            }
        }
        .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-disabled{
            border-color: ${props => props.theme.normalColor}
        }
        .ant-pagination-next .ant-pagination-item-link,  .ant-pagination-disabled{
            border-color: ${props => props.theme.normalColor}
        }
    }
    .ant-list-empty-text{
        padding: 16px 0;
    }
    .ant-list-bordered {
            border-color: ${props => props.theme.normalColor};
        }
        .ant-list-split .ant-list-item{
            border-bottom: none;
        }
    @media(max-width: 767px){
        overflow: hidden!important;
        .ant-list-bordered {
        padding: 0 !important;
        }
    }
`

const PaginationSCP = styled(Pagination)`
    &.ant-pagination {
        background: #fff;
        margin-bottom: 8em;
        width: 100%;
        display: flex;
        justify-content: center;
    }
`