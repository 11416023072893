import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DividerCP } from 'common/components/divider/DividerCP'
import { CheckboxCP } from 'common/components/form-fields/checkbox/CheckboxCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { MaskUtils } from 'common/utils/MaskUtils'
import { InvoiceFormValidator } from 'modules/financial/components/invoice-form-drawer/inner/InvoiceFormValidator'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import React from 'react'
import styled from 'styled-components'
import { InvoiceFieldICP } from './InvoiceFieldICP'

interface IGenericInvoiceFormICPProps {
    formStateManager: IFormStateManager<InvoiceFormValidator>
    isPaid: boolean
    onChangeField?: (codes: number, index: number) => void
    onRemoveField?: (index: number) => void
    onAddField?: () => void
    fieldCodes?: Array<number | undefined>
    markAsPaid: (isPaid: boolean) => void
    loading?: boolean
}

export function GenericInvoiceFormICP(props: IGenericInvoiceFormICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TwoColWrapperSCP>
                <SeasonSelectorCP
                    label={'Safra:'}
                    required={true}
                    formStateManager={props.formStateManager}
                    loading={props.loading}
                />
                <InputCP
                    label={'Data'}
                    placeholder={'__/__/____'}
                    fieldName={'date'}
                    type={'date'}
                    mask={MaskUtils.applyDateMask}
                    formStateManager={props.formStateManager}
                    required={true}
                    maxLength={10}
                    loading={props.loading}
                />
            </TwoColWrapperSCP>

            <InputCP
                label={'Descrição'}
                type={'text'}
                fieldName={'description'}
                formStateManager={props.formStateManager}
                required={true}
                loading={props.loading}
            />

            <TwoColWrapperSCP>
                <InputCP
                    label={'Valor'}
                    fieldName={'value'}
                    formStateManager={props.formStateManager}
                    required={true}
                    type={'currency'}
                />
                <InputCP
                    label={'Data do pagamento'}
                    placeholder={'__/__/____'}
                    fieldName={'paymentDate'}
                    type={'date'}
                    formStateManager={props.formStateManager}
                    required={false}
                    mask={MaskUtils.applyDateMask}
                    maxLength={10}
                    loading={props.loading}
                />

                <CheckboxWrapperSCP>
                    <CheckboxCP
                        isChecked={props.isPaid}
                        onChange={props.markAsPaid}
                        label={'Marcar como pago'}
                    />
                </CheckboxWrapperSCP>

            </TwoColWrapperSCP>
            <DividerCP text={'Talhões'} orientation={'left'} />
            {
                !!props.onChangeField
                && !!props.fieldCodes
                && !!props.onRemoveField &&
                props.fieldCodes.map((fieldCode, index) => (
                    <FieldsWppSCP key={fieldCode}>
                        <InvoiceFieldICP
                            fieldCode={fieldCode}
                            setFieldCode={(_fieCode) => props.onChangeField!(_fieCode, index)}
                        />
                        <RemoveSCP>
                            <ButtonCP
                                onClick={() => props.onRemoveField!(index)}
                                type={'primary'}
                                size={'small'}
                                style={{ marginBottom: '.5em' }}
                                disabled={index === 0}
                                icon={<FontAwesomeIcon icon={faTrash} />}
                            />
                        </RemoveSCP>

                    </FieldsWppSCP>

                ))
            }
            <ButtonCP
                onClick={props.onAddField}
                size={'small'}
                icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />}
                type={'ghost'}
                style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
            >
                Adicionar talhão
            </ButtonCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`

const FieldsWppSCP = styled.div`
    display: grid;
    grid-template-columns: 3fr .3fr; 
    align-items: end;
    padding: 20px 0;
    border-bottom: 1px solid ${props => props.theme.primaryColor};
    
`
const CheckboxWrapperSCP = styled.div`
    align-items: start;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 15px;
`
const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`
const RemoveSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    
`