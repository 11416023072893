import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DividerCP } from 'common/components/divider/DividerCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { RecipeUtils } from 'modules/maneuver/utils/RecipeUtils'
import { ProductSelectorCP } from 'modules/maneuver/components/product-selector/ProductSelectorCP'
import { RecipeFormValidator } from 'modules/maneuver/components/recipe-drawer/inner/RecipeFormValidator'
import { RecipeTypeEnum } from 'modules/maneuver/enums/RecipeTypeEnum'
import { ISaveRecipeRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveRecipeRequestDTO'
import { ISearchRecipeRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchRecipeRequestDTO'
import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'
import { ManeuverRequests } from 'modules/maneuver/services/ManeuverRequests'
import { SwitchCP } from 'common/components/switch/SwitchCP'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'
import { ProductTypeEnum } from 'modules/maneuver/enums/ProductTypeEnum'
import { ListActions } from 'common/components/list/inner/ListActions'

interface IRecipeDrawerCPProps {
    show: boolean
    recipeCode?: number
    onCancel: () => void
    onSuccess: (code?: number) => void
}
const RAW_FIELD = { propertyCode: undefined, fieldCode: undefined, totalArea: undefined }
const RAW_FORMULA = { code: 0, productCode: undefined, quantity: undefined, isLiquid: true, remainingQuantity: undefined }

/**
 * COMPONENTE
 * Formulario de cadastro de 01 instrução
 * @todo extrair algumas logicas para organizar melhor o código e facilitar a manutençao
 */
export function RecipeDrawerCP(props: IRecipeDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [formValidator, setFormValidator] = useState<RecipeFormValidator>(new RecipeFormValidator())

    const [loading, setLoading] = useState<boolean>(false)
    const [showFormulaFields, setShowFormulaFields] = useState<boolean>(false)
    const [formula, setFormula] = useState<Array<{ code?: number, productCode?: number, quantity?: number, isLiquid?: boolean, remainingQuantity?: number }>>([RAW_FORMULA])

    const formStateManager = useFormStateManager(formValidator)

    const getRecipeRequest = useRequest<IRecipeResponseDTO, ISearchRecipeRequestDTO>()
    useEffect(onGetRecipeReqChenge, [getRecipeRequest.awaiting])

    const request = useRequest<IRecipeResponseDTO, ISaveRecipeRequestDTO>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(searchRecipe, [props.recipeCode, props.show])

    function searchRecipe(): void {
        if (!!props.show && !!props.recipeCode)
            getRecipeRequest.runRequest(ManeuverRequests.getRecipe(props.recipeCode))
    }

    function onGetRecipeReqChenge(): void {
        if (getRecipeRequest.awaiting || !getRecipeRequest.tried)
            return

        if (!getRecipeRequest.success || !getRecipeRequest.returnData) {
            if (getRecipeRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getRecipeRequest.returnData, getRecipeRequest.error)
            return RequestUtils.showDefaultErrorNotification(getRecipeRequest.error, 'Erro ao buscar instrução!')
        }

        if (getRecipeRequest.returnData.formulas.length) {
            setFormula(getRecipeRequest.returnData.formulas.map(f => ({
                ...f,
                quantity: +f.quantity,
                remainingQuantity: f.remainingQuantity && +f.remainingQuantity,
            })))
        }

        setFormValidator(new RecipeFormValidator(getRecipeRequest.returnData))

        setShowFormulaFields(!!getRecipeRequest.returnData.formulas.length)
        setLoading(false)
    }
    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar instrução!')
        }

        NotificationHelper.success('Instrução salva com sucesso!')
        formStateManager.reset()
        setFormula([])
        props.onSuccess(request.returnData.code)
        ListActions.mustUpdate()
    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)
        formStateManager.debugErrors()
        if (!await formStateManager.validate() || !formValues)
            return

        const _formula = formula.filter(value => !!value.productCode && !!value.quantity)

        const dto: ISaveRecipeRequestDTO = {
            title: formValues.title,
            type: formValues.type,
            description: formValues.description,
            mixTotalLiters: +formValues.mixTotalLiters,
            formulas: _.isEmpty(_formula) || !showFormulaFields ?
                undefined
                : (_formula as Array<{ productCode: number, quantity: number, code?: number }>).map(({ code, productCode, quantity }) => ({ code, productCode, quantity: +quantity }))
        }

        request.runRequest(!!props.recipeCode ?
            ManeuverRequests.updateRecipe(props.recipeCode, dto) :
            ManeuverRequests.saveRecipe(dto))
    }

    function onChangeFormulaProduct(productCode: number, unity: UnitOfMeasurementEnum, remainingQuantity: number, index: number): void {

        setFormula(formula.map((item, i) => {
            if (i === index) {
                return {
                    productCode,
                    isLiquid: unity === UnitOfMeasurementEnum.LITERS,
                    remainingQuantity
                }
            }
            return item
        }))
    }

    function onChangeFormulaProdQuantity(quantity: number, index: number): void {

        setFormula(formula.map((item, i) => {
            if (i === index) {
                return {
                    ...formula[i],
                    quantity: +quantity
                }
            }
            return item
        }))
    }

    function addProduct(): void {
        setFormula([...formula, RAW_FORMULA])
    }

    function removeProduct(index: number): void {
        setFormula(formula.filter((formula, i) => i !== index))
    }

    const incompleteFormula = !!showFormulaFields && formula.filter(item => !item.productCode || !item.quantity).length > 0
    const disableAddProduct = formula.filter(item => !item.productCode).length > 0

    return (
        <DrawerCP
            title={!!props.recipeCode ? `Editar instrução #${props.recipeCode}` : 'Nova instrução'}
            visible={props.show}
            width={'40%'}
            onClose={() => {
                formStateManager.reset(new RecipeFormValidator())
                setFormula([RAW_FORMULA])
                setShowFormulaFields(false)
                props.onCancel()
            }}
            footer={
                <SaveBtnWrapperSCP>
                    <ButtonCP
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'primary'}
                        isSubmit={true}
                        disabled={incompleteFormula}
                        loading={request.awaiting}
                        wrapperStyle={{ width: '100%' }}
                        onClick={onFormSubmit}
                    >
                        Salvar
                    </ButtonCP>

                </SaveBtnWrapperSCP>
            }
        >
            <Skeleton active={true} loading={loading}>
                <FormWrapperSCP>

                    <InputCP
                        label={'Título'}
                        fieldName={'title'}
                        formStateManager={formStateManager}
                        required={true}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                    />
                    <SelectCP
                        options={Object.values(RecipeTypeEnum).map(rt => ({ label: RecipeUtils.translateType(rt), value: rt }))}
                        label={'Tipo'}
                        fieldName={'type'}
                        formStateManager={formStateManager}
                        required={true}
                    />

                    <TextAreaCP
                        label={'Descrição'}
                        fieldName={'description'}
                        formStateManager={formStateManager}
                        required={false}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                    />

                    <SwitchCP
                        isChecked={showFormulaFields}
                        isTextInside={false}
                        onChangeAction={setShowFormulaFields}
                        textOutside={'Possui fórmula?'}
                    />
                    {
                        showFormulaFields &&
                        <ListFieldsWrapperSCP>
                            <DividerCP
                                orientation={'left'}
                                plain={true}
                                text={'Fórmula'}
                            />

                            <>
                                <InputCP
                                    placeholder={'Litragem total da mistura:'}
                                    icon={
                                        <span>
                                            litros/ha
                                        </span>
                                    }
                                    fontSize={screenSize.smd ? 'large' : 'normal'}
                                    type={'number'}
                                    fieldName={'mixTotalLiters'}
                                    formStateManager={formStateManager}
                                    required={false}
                                />

                                {formula.map((item, index) => (
                                    <FormulaItemWrapperSCP key={`${item.productCode}`}>

                                        <ProductSelectorCP
                                            label={'Produto'}
                                            types={Object.values(ProductTypeEnum).filter((e: ProductTypeEnum) => e !== ProductTypeEnum.FUEL && e !== ProductTypeEnum.PIECE)}
                                            onSelect={(prdCode, unity, remainingQuantity) => onChangeFormulaProduct(prdCode, unity!, remainingQuantity!, index)}
                                            value={item.productCode}
                                        />

                                        <InputCP
                                            placeholder={'Quantidade'}
                                            icon={
                                                <span>
                                                    {formula[index].isLiquid ? 'litros' : 'kg'}
                                                    /ha
                                                </span>
                                            }
                                            fontSize={screenSize.smd ? 'large' : 'normal'}
                                            value={item.quantity}
                                            type={'number'}
                                            onChange={(quantity) => onChangeFormulaProdQuantity(quantity, index)}
                                        />

                                        <WrapperRemoveColCP>

                                            <ButtonCP
                                                onClick={() => removeProduct(index)}
                                                type={'primary'}
                                                size={'small'}
                                                disabled={index === 0}
                                                icon={<FontAwesomeIcon icon={faTrash} />}
                                            />
                                        </WrapperRemoveColCP>
                                    </FormulaItemWrapperSCP>
                                ))}
                                <ButtonCP
                                    onClick={addProduct}
                                    tooltip={disableAddProduct ? 'Preencha os dados do produto antes de adicionar um novo' : 'Adicionar produto'}
                                    size={'small'}
                                    icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />}
                                    type={'ghost'}
                                    disabled={disableAddProduct}
                                    style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
                                >
                                    Adicionar produto
                                </ButtonCP>
                            </>
                        </ListFieldsWrapperSCP>
                    }

                </FormWrapperSCP>

            </Skeleton>
        </DrawerCP>
    )
}

const FormulaItemWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 15px;
`

const FieldItemWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 10fr 1fr ;
    column-gap: 10px;
    margin: 10px 0;
    width: 100%;
    align-items: end;
    .ant-btn{
        margin-bottom: 5px;
    }
`

const FormWrapperSCP = styled.div`
    display: grid;
  
`
const WarningWrapperSCP = styled.div`
    padding: 5px;   
    border-radius: 5px; 
    width: 100%;
`

const FieldCardWrpSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr ;
    column-gap: 5px;
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const WrapperRemoveColCP = styled.div`
    display: flex;
    justify-content: flex-end;
   
    .ant-btn{
        margin-left: 8px;
    }
  
`
const ListFieldsWrapperSCP = styled.div`
    margin-bottom: 10px;
`

const SaveBtnWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const POWrapperSCP = styled.div`
    .ant-typography{
        font-size: 12px
    }
`

const InfoWarningSCP = styled.div`
    padding: 10px;
    margin: 20px 0;   
    align-items: center;
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    padding-bottom: 10px;
    column-gap: 5px;
    svg {
        font-size: 40px;
        color: ${props => props.theme.primaryColor}
    }
`

const TextWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    .ant-typography {
        font-size: 12px;
    }
`