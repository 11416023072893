import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { ListCP } from 'common/components/list/ListCP'
import { ListHeaderICP } from 'common/components/list/ListHeaderICP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { BatchSaleLIstItemICP } from 'modules/batch/components/batch-sale-drawer/inner/BatchSaleLIstItemICP'
import { BatchSaleFormDrawerCP } from 'modules/batch/components/batch-sale-form-drawer/BatchSaleFormDrawerCP'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IBatchSaleResDTO } from 'modules/batch/services/dtos/responses/IBatchSaleResDTO'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

interface IBatchSaleDrawerCPProps {
    show: boolean
    onCancel: () => void
    batchCode: number
    onSuccess: () => void
    saleUnavailable: boolean
}

export function BatchSaleDrawerCP(props: IBatchSaleDrawerCPProps): JSX.Element {
    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) => _.get(state, 'listState.updateStatus'))
    const [newSaleDrawerVisible, setNewSaleDrawerVisible] = useState<boolean>(false)
    const [saleList, setSalesList] = useState<IBatchSaleResDTO[]>([])

    useEffect(getSalesList, [props.batchCode, props.show])

    const searchSalesReq = useRequest<IGenericListResponseDTO<IBatchSaleResDTO>>()
    useEffect(onSearchReqChange, [searchSalesReq.awaiting])
    useEffect(onItemChange, [updateStatus])

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE)
            getSalesList()
    }

    function getSalesList(): void {
        if (!!props.batchCode && !!props.show)
            searchSalesReq.runRequest(BatchRequests.searchSales(props.batchCode))
    }

    function onSearchReqChange(): void {
        if (searchSalesReq.awaiting || !searchSalesReq.tried) {
            ListActions.isUpdating()
            return
        }

        if (!searchSalesReq.success || !searchSalesReq.returnData) {
            if (searchSalesReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', searchSalesReq.returnData, searchSalesReq.error)
            return RequestUtils.showDefaultErrorNotification(searchSalesReq.error, 'Erro ao buscar vendas!')
        }

        setSalesList(searchSalesReq.returnData.list)
        ListActions.updateIsDone()

    }

    return (
        <DrawerCP
            title={` #${props.batchCode} - Vendas`}
            visible={props.show}
            width={'60%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        disabled={props.saleUnavailable}
                        onClick={() => setNewSaleDrawerVisible(true)}
                        wrapperStyle={{ width: '100%' }}
                        loading={searchSalesReq.awaiting}
                    >
                        Nova venda
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {

                props.onCancel()
            }}
        >
            <WrapperSCP>
                <ListHeaderICP
                    gridColumns={'1fr 1fr 1fr 1fr 1fr 1fr '}
                    labels={[
                        'Comprador',
                        'Sacas',
                        'Valor da saca',
                        'Data',
                        'Obs.',
                    ]}
                />
                <ListCP<IBatchSaleResDTO>
                    bordered={false}
                    itemLayout={'vertical'}
                    size={'large'}
                    dataSource={saleList}
                    hasStateControl={true}
                    locale={{
                        emptyText: <EmptyCP description={'Ainda não existem vendas para este lote'} />
                    }}
                    renderItem={(item: IBatchSaleResDTO) => (
                        <BatchSaleLIstItemICP
                            key={`${item.code}`}
                            item={item}
                            loading={searchSalesReq.awaiting}
                            onChangeItem={() => {
                                getSalesList()
                                props.onSuccess()
                            }}
                            batchCode={props.batchCode}
                        />
                    )}
                />
                <BatchSaleFormDrawerCP
                    batchCode={props.batchCode}
                    show={newSaleDrawerVisible}
                    onCancel={() => setNewSaleDrawerVisible(false)}
                    onSuccess={() => {
                        setNewSaleDrawerVisible(false)
                    }}
                />
            </WrapperSCP>
        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

