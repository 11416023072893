import { faEdit, faFileArchive, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { TextCP } from 'common/components/text/TextCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { DocsDrawerCP } from 'modules/maneuver/components/purchase-docs-drawer/DocsDrawerCP'
import { PurchaseDrawerCP } from 'modules/maneuver/components/purchase-drawer/PurchaseDrawerCP'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'
import { IPurchaseResponseDTO } from 'modules/maneuver/services/dtos/responses/IPurchaseResponseDTO'
import { ProductRequests } from 'modules/maneuver/services/ProductRequests'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IPurchaseListItemICPProps {
    purchase: IPurchaseResponseDTO
    productCode: number
    loading: boolean
    key: string
}

export function PurchaseListItemICP(props: IPurchaseListItemICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [showPurchase, setShowPurchase] = useState<boolean>(false)
    const [purchaseDocsVisible, setPurchaseDocsVisible] = useState<boolean>(false)

    const deleteReq = useRequest(ProductRequests.deletePurchase(props.productCode, props.purchase.code))
    useEffect(onDeleteRequestChange, [deleteReq.awaiting])

    function onDeleteRequestChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success || !deleteReq.returnData)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Erro ao remover compra')

        NotificationHelper.success('Compra removida com sucesso!')
        ListActions.mustUpdate()
    }

    return (
        <ListItemICP
            key={props.key}
            isMobile={screenSize.smd}
            loading={props.loading}
            style={{ padding: '0', margin: '0' }}
            customSkeleton={<GenericRowSkeletonICP rows={1} columns={8} />}
        >
            <WrapperSCP>
                <MobileLabelsSCP>
                    <TextCP text={'#'} />
                    <TextCP text={'Safra:'} />
                    <TextCP text={'Data:'} />
                    <TextCP text={'Quantidade:'} />
                    <TextCP text={'Valor:'} />
                    <TextCP text={'Obs.:'} />
                </MobileLabelsSCP>
                <ValuesSCP>
                    <TextCP text={`${props.purchase.code}`} />
                    <TextCP text={`${props.purchase.season ?? '-'}`} />
                    <TextCP text={DateUtils.getFormatted(props.purchase.date, DateFormatEnum.BR_WITHOUT_TIME)} />
                    <TextCP text={props.purchase.unitOfMeasurement === UnitOfMeasurementEnum.UNITY
                        ? `${props.purchase.quantity} ${ProductUtils.getUnitOfMeasurementAbbreviation(props.purchase.unitOfMeasurement)}`
                        : `${MaskUtils.applyNumberMask(+props.purchase.quantity)} ${ProductUtils.getUnitOfMeasurementAbbreviation(props.purchase.unitOfMeasurement)}`}
                    />
                    <TextCP text={`${MaskUtils.applyCurrencyMask(props.purchase.paidValue)}`} />
                    <NoteSCP>
                        {props.purchase.notes}
                    </NoteSCP>
                </ValuesSCP>
                <ActionsSCP>
                    <ButtonCP
                        type={'primary'}
                        tooltip={'Editar'}
                        onClick={() => setShowPurchase(true)}
                        icon={<FontAwesomeIcon icon={faEdit} size={'sm'} />}
                    />
                    <ButtonCP
                        type={'primary'}
                        tooltip={'Documentos'}
                        onClick={() => setPurchaseDocsVisible(true)}
                        icon={<FontAwesomeIcon icon={faFileArchive} size={'sm'} />}
                    />
                    <ButtonCP
                        type={'ghost'}
                        onClick={() => deleteReq.runRequest()}
                        confirmMsg={'Lembre-se que ao remover a compra, a quantidade informada será removida do estoque. Tem certeza que deseja excluí-la?'}
                        loading={deleteReq.awaiting}
                        icon={<FontAwesomeIcon icon={faTrash} size={'sm'} />}
                    />
                </ActionsSCP>
            </WrapperSCP>
            <PurchaseDrawerCP
                productCode={props.productCode}
                purchaseCode={props.purchase.code}
                unitOfMeasurement={props.purchase.unitOfMeasurement}
                show={showPurchase}
                onSuccess={() => {
                    setShowPurchase(false)
                }}
                onCancel={() => {
                    setShowPurchase(false)
                }}
            />
            <DocsDrawerCP
                type={'purchase'}
                show={purchaseDocsVisible}
                onClose={() => setPurchaseDocsVisible(false)}
                itemCode={props.productCode}
                subItemCode={props.purchase.code}
            />

        </ListItemICP>
    )
}

const WrapperSCP = styled.div`   
    position:relative;
    display: flex;  
    
    @media(max-width: 768px){
        display: grid;
        grid-template-columns: .5fr 1fr;
        row-gap: 5px;
          column-gap: 30px;
          padding-bottom: 60px;
          margin-bottom: 2em;
          border-bottom: 1px solid ${props => props.theme.lightGray};
       
    }
`
const MobileLabelsSCP = styled.div`
    display: none;
    .ant-typography{
        font-weight: 600;
    }
    @media(max-width: 768px){
        display: grid;
        padding: 10px 0;
        grid-template-columns: 1fr;
        row-gap: 5px;   
        width: 100%;
    }
`
const ValuesSCP = styled.div`
    display: grid;
    grid-template-columns: .2fr .3fr .8fr .8fr .8fr 2fr 1fr;
    margin: 0;
    padding: 10px 0;
    column-gap: 10px;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    align-items: center;
    .ant-list-bordered.ant-list-lg .ant-list-item{
        padding: 0!important;
    }
    width: 100%;
    @media(max-width: 768px){
        grid-template-columns: 1fr;    
        row-gap: 5px;    
        border-bottom: none;
    }
`

const NoteSCP = styled.div`
    font-style: italic;
`

const ActionsSCP = styled.div`
    display: flex;
    column-gap: 5px;
    @media(max-width: 767px){
        position: absolute;
        bottom: 15px;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        .ant-btn{
            width: 100%;
        }
    }
`
