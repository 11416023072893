import React from 'react'
import { Dropdown } from 'antd'
import styled from 'styled-components'

interface IReportActionsBtnGroupCPProps {
    icon: JSX.Element
    overlay: JSX.Element
    width?: string
    children: any
    backgrounColor?: string
    type?: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text'
}

/**
 * COMPONENTE Dropdown Button com opcoes.
 *
 * @author renatofs
 */
export function DropdownCP(props: IReportActionsBtnGroupCPProps): JSX.Element {

    return (
        <DropdownBtnSCP width={props.width}>
            <Dropdown.Button
                trigger={['click']}
                overlay={props.overlay}
                icon={props.icon}
                type={props.type}
            >
                {props.children}
            </Dropdown.Button>
        </DropdownBtnSCP>
    )
}

const DropdownBtnSCP = styled.div<{ width?: string, backgrounColor?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
   
    .ant-btn-group {
        display: flex;
    }
    .ant-btn {     
        display: flex;
        height: 35px;       
        color: #fff;
        border-radius: 5px 0 0 5px!important;
        margin-right: 2px;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        &:not(.ant-dropdown-trigger) {
            padding: 10px 20px;
           
        }
        &.ant-dropdown-trigger{
            border-radius: 0 5px 5px 0!important;
        }
    }
`
