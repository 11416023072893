import { ISaveManeuverRequestDTO } from './../../../services/dtos/requests/ISaveManeuverRequestDTO'
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-useless-constructor */

import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { DateUtils } from 'common/utils/DateUtils'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsNumber } from 'common/validation/decorators/IsNumber'
import { IsOptional } from 'class-validator'

export class ManeuverFormValidator extends FormModelValidator {


    @IsNotEmpty()
    @IsNumber()
    recipeCode: number

    @IsNotEmpty()
    @IsNumber()
    season: number

    constructor(dto?: ISaveManeuverRequestDTO) {
        super(dto)
        Object.assign(this, dto)

    }
}