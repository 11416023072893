export enum BatchProcessMethodEnum {
    AEROBIC_FERMENTED = 'AEROBIC_FERMENTED',
    ANAEROBIC_FERMENTED = 'ANAEROBIC_FERMENTED',
    HONEY = 'HONEY',
    THIN_LAYER = 'THIN_LAYER',
    CARBONIC_MACERATION = 'CARBONIC_MACERATION',
    WATER_ANAEROBIC_FERMENTED = 'WATER_ANAEROBIC_FERMENTED',
    THICK_LAYER = 'THICK_LAYER',
    WASHED = 'WASHED',
    PULPED_NATURAL = 'PULPED_NATURAL',
    DESMUCILATED = 'DESMUCILATED',
    DRYER = 'DRYER',
    REST = 'REST',
}
