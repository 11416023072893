import { TextCP } from 'common/components/text/TextCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { UnityUtils } from 'common/utils/UnityUtils'
import { PaymentUnityEnum } from 'modules/logbook/enums/PaymentUnityEnum'
import { IPersonValuesUnitPayment } from 'modules/person/interfaces/IPersonValuesUnitPayment'
import React from 'react'
import styled from 'styled-components'

interface IPersonResponsiblePaymentReferencesICPProps {
    paymentReferences?: IPersonValuesUnitPayment
}

export function PersonResponsiblePaymentReferencesICP(props: IPersonResponsiblePaymentReferencesICPProps): JSX.Element {

    return (
        <WrapperSCP>
            {
                Object.values(PaymentUnityEnum).map(unity => (
                    <RowGridSCP key={`${unity}_key`}>
                        <TextCP text={`${UnityUtils.translatePaymentUnity(unity)}: `} />
                        <TextCP text={`${!!props.paymentReferences && !!props.paymentReferences[unity.toLowerCase()]
                            ? `${MaskUtils.applyCurrencyMask(props.paymentReferences[unity.toLowerCase()])} /${UnityUtils.getUnityAbreviation(unity)}`
                            : 'Valor não informado'}`}
                        />
                    </RowGridSCP>
                ))
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`
const RowGridSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
`