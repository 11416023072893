import { TextCP } from 'common/components/text/TextCP'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { BatchProcessMethodEnum } from 'modules/batch/enums/BatchProcessMethodEnum'
import { StructureTypeEnum } from 'modules/batch/enums/StructureTypeEnum'
import { IStructureResDTO } from 'modules/batch/services/dtos/responses/IStructureResDTO'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import { StructureUtils } from 'modules/structure/utils/StructureUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IPostHarvestDetailsContentICPProps {
    method: BatchProcessMethodEnum
    methodOther?: string
    humidity?: number
    averageTemperature?: number
    turnings?: number
    structure: IStructureResDTO
    language?: LanguageEnum
}

const LABELS_PT = {
    method: 'Processamento',
    methodOther: 'Outro',
    structure: {
        name: 'Nome da estrutura',
        type: 'Tipo da estrutura',
        capacity: 'Capacidade da estrutura'
    },
    humidity: 'Umidade',
    averageTemperature: 'Temperatura média',
    turnings: 'Quantas vezes revolveu'
}

const LABELS_EN = {
    method: 'Processing',
    methodOther: 'Other',
    structure: {
        name: 'Structure name',
        type: 'Structure type',
        capacity: 'Strucutre capacity'
    },
    humidity: 'Humidity',
    averageTemperature: 'Average temperature',
    turnings: 'Number of overturnings'
}

export function PostHarvestDetailsContentICP(props: IPostHarvestDetailsContentICPProps): JSX.Element {
    const [labels, setLabels] = useState(LABELS_PT)
    const [processMethodTranslateFunc, setProcessMethodTranslateFunc] = useState<(method: BatchProcessMethodEnum) => string>(() => BatchUtils.translateProcessMethod)
    const [typeTranslateFunc, setTypeTranslateFunc] = useState<(type: StructureTypeEnum) => string>(() => StructureUtils.translateType)

    useEffect(translateLabels, [props.language])

    function translateLabels(): void {
        if (props.language === LanguageEnum.EN) {
            setLabels(LABELS_EN)
            setProcessMethodTranslateFunc(() => BatchUtils.translateProcessMethodToEnglish)
            setTypeTranslateFunc(() => StructureUtils.translateTypeToEnglish)
        }

    }
    return (

        <ContentInfoSCP>
            <RowSCP>
                <TextCP text={`${labels.method}:\u00A0`} style={{ fontWeight: '500 ' }} />
                <TextCP text={`${processMethodTranslateFunc(props.method)}`} />
            </RowSCP>
            {
                !!props.methodOther &&
                <RowSCP>
                    <TextCP text={`${labels.methodOther}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.methodOther}`} />
                </RowSCP>
            }
            {
                props.language !== LanguageEnum.EN &&
                <RowSCP>
                    <TextCP text={`${labels.structure.name}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.structure.name}`} />
                </RowSCP>
            }
            <RowSCP>
                <TextCP text={`${labels.structure.type}:\u00A0`} style={{ fontWeight: '500 ' }} />
                <TextCP text={`${typeTranslateFunc(props.structure.type)}`} />
            </RowSCP>
            {
                !!props.humidity &&
                <RowSCP>
                    <TextCP text={`${labels.humidity}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.humidity}`} />
                </RowSCP>
            }
            {
                !!props.averageTemperature &&
                <RowSCP>
                    <TextCP text={`${labels.averageTemperature}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.averageTemperature}`} />
                </RowSCP>
            }
            {
                !!props.turnings &&
                <RowSCP>
                    <TextCP text={`${labels.turnings}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.turnings}`} />
                </RowSCP>
            }
            {
                !!props.structure.capacity &&
                <RowSCP>
                    <TextCP text={`${labels.structure.capacity}:\u00A0`} style={{ fontWeight: '500 ' }} />
                    <TextCP text={`${props.structure.capacity} ${ProductUtils.getUnitOfMeasurementAbbreviation(props.structure.unitOfMeasurement)}`} />
                </RowSCP>
            }
        </ContentInfoSCP>

    )
}

const WrapperSCP = styled.div`

`

const ContentInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    .ant-typography{
        font-size: 12px;
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const RowSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography{
        font-size: 12px;
    }
    
`