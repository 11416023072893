import { TextCP } from 'common/components/text/TextCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import { DateUtils } from 'common/utils/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { LitersPerMaturationCP } from 'modules/harvest-planning/components/liters-per-maturation/LitersPerMaturationCP'
import { BatchMethodEnum } from 'modules/batch/enums/BatchProcessEnum'
import { BatchOriginEnum } from 'modules/batch/enums/BatchOriginEnum'
import { IHarvestLitersPerMaturation } from 'modules/harvest/interfaces/IHarvestLitersPerMaturation'
import { IBatchProcessResDTO } from 'modules/batch/services/dtos/responses/IBatchProcessResDTO'
import { LastProcessPreviewICP } from 'modules/batch/components/batch-list/inner/LastProcessPreviewICP'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { PopConfirmCP } from 'common/components/pop-confirm/PopConfirmCP'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { NotificationHelper } from 'common/NotificationHelper'
import { MaskUtils } from 'common/utils/MaskUtils'

interface IBatchCardContentICPProps {
    code: number
    date: string
    methodOther?: string
    method?: BatchMethodEnum
    origin?: BatchOriginEnum
    litersPerMaturation?: IHarvestLitersPerMaturation
    farmId?: string
    averageScore?: number
    averagePicking?: number
    lastProcessEntry?: IBatchProcessResDTO
    bags?: number
    availableBags: number
    notSpecial?: boolean
}

/**
 * COMPONENTE
 * Conteudo central de um item da lista de lotes
 */
export function BatchCardContentICP(props: IBatchCardContentICPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }
    const scoreText =
        !!props.averageScore && props.averageScore >= 80
            ? ` ${MaskUtils.applyNumberMask(props.averageScore)} pontos (Especial)`
            : props.notSpecial
                ? 'Não especial'
                : 'Não analisado'

    const [showLitersPerMaturation, setShowLitersPerMaturation] = useState<boolean>(false)

    const undoReq = useRequest<string>()
    useEffect(onUnduReqChange, [undoReq.awaiting])

    function undoMergeOrSplit(): void {
        if (props.origin === BatchOriginEnum.MERGE)
            undoReq.runRequest(BatchRequests.undoMerge(props.code))
        if (props.origin === BatchOriginEnum.SPLIT)
            undoReq.runRequest(BatchRequests.undoSplit(props.code))
    }

    function onUnduReqChange(): void {
        if (undoReq.awaiting || !undoReq.tried)
            return

        if (!undoReq.success || !undoReq.returnData) {
            if (undoReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', undoReq.returnData, undoReq.error)
            return RequestUtils.showDefaultErrorNotification(
                undoReq.error,
                'Erro ao desfazer ação!',
            )
        }
        NotificationHelper.success(
            `${BatchUtils.translateOrigin(props.origin!)} desfeita com sucesso.`,
        )
        ListActions.mustUpdate(true)
    }

    return (
        <WrapperSCP>
            {!!props.farmId && (
                <RowSCP>
                    <TextCP text={'Cód. na propriedade: '} />
                    <TextCP text={props.farmId} style={valueStyle} />
                </RowSCP>
            )}
            {!!props.origin && (
                <RowSCP style={{ justifyContent: 'space-between' }}>
                    <TextSCP>
                        <TextCP text={'Origem: '} />
                        <TextCP
                            text={`${BatchUtils.translateOrigin(props.origin)}`}
                            style={valueStyle}
                        />
                    </TextSCP>

                    {props.origin !== BatchOriginEnum.HARVEST && (
                        <PopConfirmCP
                            title={`Tem certeza que deseja desfazer esta ${BatchUtils.translateOrigin(
                                props.origin,
                            )}?`}
                            onConfirm={undoMergeOrSplit}
                        >
                            <UndoSCP style={{ justifyContent: 'flex-end', borderBottom: 'none' }}>
                                <TextCP
                                    text={'Desfazer'}
                                    style={{
                                        fontSize: '10px',
                                        cursor: 'pointer',
                                        marginRight: '10px',
                                    }}
                                />
                                {undoReq.awaiting && (
                                    <FontAwsomeIconCP icon={faSpinner} loading={true} />
                                )}
                            </UndoSCP>
                        </PopConfirmCP>
                    )}
                </RowSCP>
            )}
            {!!props.method && (
                <RowSCP>
                    <TextCP text={'Método: '} />
                    {props}
                    <TextCP text={BatchUtils.translateMethod(props.method)} style={valueStyle} />
                </RowSCP>
            )}
            <RowSCP>
                <TextCP text={'Data:'} />
                <TextCP
                    text={`${DateUtils.getFormatted(props.date, DateFormatEnum.BR_WITHOUT_TIME)}`}
                    style={valueStyle}
                />
            </RowSCP>
            <RowSCP>
                <TextCP text={'Pontuação média: '} />
                <TextCP text={scoreText} style={valueStyle} />
            </RowSCP>
            <RowSCP>
                <TextCP text={'Catação média: '} />
                <TextCP
                    text={
                        props.averagePicking
                            ? ` ${MaskUtils.applyNumberMask(props.averagePicking)} %`
                            : ' Não analisado'
                    }
                    style={valueStyle}
                />
            </RowSCP>

            {!!props.availableBags && (
                <RowSCP>
                    <TextCP text={'Sacas disponíveis: '} />
                    <TextCP
                        text={MaskUtils.applyNumberMask(props.availableBags)}
                        style={valueStyle}
                    />
                </RowSCP>
            )}
            {!!props.litersPerMaturation && (
                <LitersPMSCP>
                    <TextCP
                        text={'Ver litros por maturação'}
                        style={{
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 600,
                            textDecoration: 'underline',
                            maxWidth: '200px',
                        }}
                        onClick={() => setShowLitersPerMaturation(!showLitersPerMaturation)}
                    />

                    {showLitersPerMaturation && (
                        <LitersPerMaturationCP litersPerMaturation={props.litersPerMaturation} />
                    )}
                </LitersPMSCP>
            )}

            <LastProcessPreviewICP item={props.lastProcessEntry} />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 0 10px;
`
const LitersPMSCP = styled.div`
    .ant-typography {
        ${(props) => props.theme.primaryColor};
    }
`

const TextSCP = styled.div`
    display: flex;
`

const RowSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.lightGray};
    margin-bottom: 5px;
    padding-bottom: 4px;
`
const UndoSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.lightGray};
    margin-bottom: 5px;
    padding-bottom: 4px;
    .ant-typography {
        ${(props) => props.theme.primaryColor};
    }
`
