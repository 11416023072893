import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import React from 'react'
import styled from 'styled-components'

interface IInvoiceFieldICP {
    fieldCode?: number
    setFieldCode: (fielCode: number) => void

}
export function InvoiceFieldICP(props: IInvoiceFieldICP): JSX.Element {
    return (
        <WrapperSCP>
            <PropertyFieldFieldsCP
                fieldCode={props.fieldCode}
                onChangeField={(fieldCode) => props.setFieldCode(fieldCode)}
            />
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div``