import { IsArray, IsOptional } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsString } from 'common/validation/decorators/IsString'
import { ISaveInvoiceReqDTO } from 'modules/financial/services/dtos/request/ISaveInvoiceReqDTO'
export class InvoiceFormValidator extends FormModelValidator {
    @IsNotEmpty()
    date: string

    @IsOptional()
    paymentDate: string

    @IsNotEmpty()
    @IsString()
    description: string

    @IsOptional()
    entityId?: number

    @IsNotEmpty()
    value: number

    @IsOptional()
    @IsArray()
    fieldCodes?: number[]

    @IsNotEmpty()
    season: number

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    attachments?: string[]

    constructor(dto?: ISaveInvoiceReqDTO) {

        super(dto)
        Object.assign(this, dto)

    }
}
