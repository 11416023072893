import React from 'react'

export function ProcessIconCP(props: any): JSX.Element {
	return (
		<span style={{ display: 'flex', justifyContent: 'center', width: props.width, alignItems: 'start', marginRight: '10px' }} >
			<svg
				{...props}
				xmlns={'http://www.w3.org/2000/svg'}
				x={'0px'}
				y={'0px'}
				viewBox={'0 0 100 100'}
				width={30}
				enableBackground={'new 0 0 100 100'}
			>
				<g fill={'#fff'}>
					<path d="M139.7,43.14c0.45-1.64,1.56-2.68,2.96-3.58c12.84-8.25,25.67-16.51,38.48-24.81c1.61-1.05,3.04-1.06,4.66-0.01
		c8.4,5.46,16.84,10.87,25.27,16.31c0.24,0.15,0.49,0.3,0.71,0.48c0.56,0.46,0.69,1.21,0.29,1.75c-0.44,0.59-1.02,0.69-1.67,0.38
		c-0.23-0.11-0.44-0.27-0.65-0.41c-8.42-5.43-16.85-10.85-25.26-16.31c-0.73-0.48-1.25-0.48-1.98,0
		c-12.93,8.37-25.89,16.71-38.83,25.06c-1.42,0.91-1.77,1.94-1.08,3.13c0.67,1.16,1.89,1.46,3.08,0.7c2.09-1.32,4.17-2.68,6.25-4.02
		c9.76-6.3,19.54-12.58,29.29-18.9c1.59-1.03,3.01-1.01,4.59,0.02c11.59,7.51,23.21,14.98,34.81,22.47
		c0.29,0.19,0.57,0.38,0.87,0.54c1.09,0.59,2.19,0.33,2.83-0.68c0.69-1.07,0.48-2.26-0.62-2.98c-1.99-1.32-4.01-2.6-6.02-3.9
		c-0.72-0.46-1.45-0.91-2.15-1.39c-0.74-0.5-0.93-1.19-0.53-1.82c0.41-0.66,1.17-0.8,1.94-0.31c2.69,1.72,5.37,3.44,8.04,5.18
		c2,1.31,2.74,3.32,2.06,5.47c-0.67,2.11-2.38,3.31-4.74,3.3c-0.14,0-0.27,0-0.46,0c0,13.49,0,26.93,0,40.51
		c0.41,0,0.83-0.01,1.24,0c2.38,0.1,4.22,2.05,4.19,4.42c-0.03,2.32-1.9,4.23-4.23,4.32c-0.31,0.01-0.63,0-0.94,0
		c-25.68,0-51.35,0-77.03,0c-3.12,0-4.18-0.71-5.36-3.59c0-0.57,0-1.14,0-1.71c0.35-0.64,0.62-1.34,1.07-1.9
		c0.84-1.06,2.01-1.56,3.37-1.55c0.31,0,0.62,0,1.05,0c0-1.2,0-2.32,0-3.45c0-0.34-0.02-0.69,0.02-1.02
		c0.09-0.74,0.62-1.2,1.32-1.18c0.71,0.02,1.19,0.5,1.21,1.27c0.03,1.11,0.01,2.22,0.01,3.33c0,0.31,0,0.62,0,1.04
		c0.38,0,0.68,0,0.98,0c2.71,0,5.42,0.02,8.12-0.02c0.48-0.01,1.02-0.15,1.43-0.39c6.35-3.87,13.27-5.93,20.64-6.58
		c1.04-0.09,2.09-0.15,3.23-0.23c-0.21-2.4-1.06-4.44-2.77-6.09c-0.18-0.17-0.58-0.18-0.87-0.15c-3.37,0.31-6.72,0.21-10.01-0.7
		c-5.48-1.52-9.77-4.57-12.28-9.79c-0.53-1.11-0.82-2.36-1.01-3.59c-0.23-1.54,0.34-2.3,1.85-2.68c3.06-0.78,6.12-0.56,9.14,0.2
		c7.28,1.83,12.08,6.35,14.31,13.52c0.3,0.97,0.89,1.58,1.64,2.33c0.03-0.33,0.06-0.55,0.06-0.76c0-2.25,0.02-4.5-0.01-6.75
		c0-0.33-0.12-0.78-0.35-0.97c-6.29-5.38-7.4-12.09-5.04-19.68c1.08-3.46,2.83-6.58,4.97-9.49c0.92-1.26,2.48-1.29,3.39-0.03
		c3.19,4.43,5.58,9.2,6.05,14.75c0.48,5.68-1.48,10.39-5.77,14.13c-0.47,0.41-0.7,0.8-0.69,1.44c0.04,2.17,0.01,4.33,0.02,6.5
		c0,0.27,0.02,0.55,0.03,0.82c0.09,0.04,0.18,0.08,0.26,0.13c0.42-0.73,0.99-1.4,1.23-2.18c2.52-8.34,9.3-13.79,17.98-14.26
		c1.83-0.1,3.7,0.06,5.51,0.35c1.63,0.26,2.17,1.2,1.96,2.86c-0.41,3.3-2.15,5.91-4.45,8.17c-3.56,3.5-7.95,5.26-12.86,5.69
		c-1.98,0.17-3.98,0.05-5.97,0.01c-0.5-0.01-0.84,0.11-1.17,0.5c-1.38,1.67-2.16,3.58-2.45,5.74c1.6,0.14,3.16,0.25,4.71,0.42
		c6.8,0.77,13.16,2.83,19.04,6.38c0.43,0.26,1,0.43,1.5,0.43c2.71,0.04,5.42,0.02,8.12,0.02c0.28,0,0.56,0,0.96,0
		c0-0.38,0-0.68,0-0.98c0-13.31,0-26.62,0.01-39.93c0-0.61-0.18-0.97-0.7-1.3c-11.36-7.3-22.7-14.61-34.04-21.95
		c-0.73-0.47-1.25-0.48-1.99,0c-11.31,7.32-22.63,14.62-33.96,21.9c-0.56,0.36-0.79,0.74-0.79,1.42c0.03,9.89,0.02,19.78,0.02,29.67
		c0,0.28,0.02,0.57-0.02,0.85c-0.08,0.68-0.47,1.09-1.17,1.14c-0.7,0.05-1.15-0.31-1.32-0.97c-0.09-0.32-0.06-0.68-0.06-1.02
		c0-9.41,0-18.81,0-28.22c0-0.34,0-0.68,0-1.07c-0.21-0.02-0.37-0.05-0.53-0.05c-2.46,0.01-4.1-1.21-4.8-3.56
		c-0.04-0.13-0.11-0.25-0.17-0.38C139.7,44.28,139.7,43.71,139.7,43.14z M183.43,95.47c12.91,0,25.82,0,38.73,0
		c0.31,0,0.63,0.02,0.94-0.02c0.92-0.12,1.61-0.91,1.59-1.82c-0.01-0.92-0.71-1.67-1.65-1.76c-0.31-0.03-0.63-0.02-0.94-0.02
		c-25.74,0-51.47,0-77.21,0c-0.31,0-0.63-0.01-0.94,0.02c-0.94,0.08-1.65,0.82-1.68,1.73c-0.03,0.94,0.68,1.75,1.65,1.86
		c0.28,0.03,0.57,0.01,0.85,0.01C157.66,95.47,170.54,95.47,183.43,95.47z M203.79,89.24c-4.63-2.58-13.26-5.05-22.96-4.53
		c-6.12,0.33-11.99,1.7-17.49,4.53C176.76,89.24,190.2,89.24,203.79,89.24z M208.89,62.79c-6.36,2.94-12.4,6.27-18.07,10.52
		C198.75,73.9,207,69.72,208.89,62.79z M182.19,41.83c-1.75,2.65-2.99,5.47-3.6,8.54c-0.9,4.58-0.14,8.78,3,12.38
		c0.15,0.17,0.34,0.31,0.59,0.55C182.19,56.04,182.19,48.93,182.19,41.83z M184.86,41.89c0,7.09,0,14.19,0,21.34
		c1.31-1.08,2.64-3.18,3.24-5.21C189.87,52.09,187.77,46.9,184.86,41.89z M175.86,73.34c-3.06-2.46-15.99-9.94-17.71-10.26
		C160.06,69.49,167.65,73.76,175.86,73.34z M160.87,61.21c5.35,3.14,10.73,6.3,15.99,9.39C175.63,65.7,166.96,60.55,160.87,61.21z
		 M190.1,70.64c5.07-3.7,10.34-6.67,16.01-9.42C199.51,60.91,192.21,65.21,190.1,70.64z"/>
				</g>
				<g>
					<path d="M28.23,48.32c1.06-5.53,5.6-8.12,9.59-8.05c3.73,0.07,8.56,2.63,9.33,8.87c1.46-1.45,2.9-2.89,4.34-4.33
		c0.63,0.68,1.21,1.31,1.89,2.04c-0.44,0.34-1.02,0.79-1.6,1.24c0.05,0.08,0.11,0.17,0.16,0.25c0.2,0.02,0.4,0.06,0.6,0.06
		c7.06,0,14.13,0.03,21.19-0.01c7.35-0.04,13.4-5.74,14.04-13.13c0.63-7.21-4.55-13.88-11.75-15.14c-0.75-0.13-1.52-0.2-2.28-0.2
		c-2.57-0.02-5.13-0.01-7.74-0.01c-0.48,2.66-1.74,4.81-3.91,6.36c-1.78,1.28-3.78,1.85-5.98,1.76c-4.66-0.2-7.85-3.31-9.08-8.95
		c-1.5,1.49-2.95,2.94-4.43,4.4c-0.59-0.59-1.2-1.2-1.96-1.95c0.6-0.47,1.18-0.91,1.75-1.36c-0.05-0.07-0.1-0.13-0.14-0.2
		c-4.73,0-9.45,0-14.25,0c-0.55,2.93-2.05,5.26-4.66,6.79c-2.05,1.2-4.28,1.55-6.62,1.1c-4.45-0.84-7.72-4.96-7.61-9.59
		c0.11-4.69,3.69-8.58,8.5-9.16c3.68-0.45,9.02,1.53,10.44,8.05c4.72,0,9.45,0,14.44,0c-0.72-0.59-1.28-1.05-1.88-1.54
		c0.82-0.73,1.46-1.31,2.03-1.82c1.35,1.34,2.8,2.78,4.42,4.39c0.29-2.75,1.25-4.91,3.13-6.62c1.86-1.69,4.05-2.52,6.56-2.5
		c3.51,0.02,8.07,2.26,9.27,8.13c1.19,0,2.4-0.04,3.6,0.01c2.42,0.09,4.89-0.03,7.26,0.37c7.82,1.34,13.63,8.54,13.62,16.6
		c-0.01,8.02-5.82,15.14-13.59,16.58c-1.24,0.23-2.52,0.34-3.79,0.34c-6.75,0.03-13.51,0.02-20.26,0.02c-0.3,0-0.6,0-0.91,0
		c-0.05,0.07-0.09,0.13-0.14,0.2c0.58,0.46,1.17,0.92,1.77,1.4c-0.8,0.79-1.4,1.38-1.93,1.91c-1.46-1.45-2.91-2.89-4.55-4.53
		c-0.29,2.84-1.3,5.05-3.24,6.76c-1.93,1.7-4.2,2.51-6.77,2.39c-3.81-0.18-7.91-2.8-8.92-8.07c-5.53-0.44-10.25,1.2-13.57,5.79
		c-3.46,4.77-3.85,10-1.03,15.17c3.22,5.9,8.56,7.95,15.21,7.43c-0.56-0.59-1.06-1.12-1.59-1.68c0.65-0.62,1.26-1.22,1.85-1.79
		c1.44,1.43,2.89,2.88,4.4,4.37c1.4-5.87,4.57-8.88,9.34-8.98c4.74-0.1,8.15,2.74,9.68,8.09c4.72,0,9.45,0,14.18,0
		c0.05-0.06,0.1-0.12,0.14-0.17c-0.59-0.46-1.18-0.91-1.81-1.39c0.82-0.78,1.43-1.36,1.97-1.87c1.44,1.42,2.89,2.86,4.51,4.47
		c0.38-3.4,1.78-5.93,4.47-7.64c1.93-1.23,4.05-1.74,6.31-1.38c4.98,0.79,8.37,4.81,8.24,9.72c-0.13,4.72-3.84,8.66-8.62,9.14
		c-4.05,0.4-9.55-2.15-10.43-8.98c-1.52,1.52-2.97,2.96-4.46,4.44c-0.53-0.59-1.1-1.21-1.72-1.9c0.43-0.45,0.94-0.99,1.53-1.61
		c-4.85,0-9.57,0-14.36,0c-0.5,2.69-1.81,4.88-4.09,6.44c-1.79,1.22-3.78,1.75-5.93,1.62c-3.55-0.22-8.13-2.74-8.91-8.91
		c-1.49,1.48-2.93,2.93-4.41,4.4c-0.61-0.64-1.2-1.26-1.91-1.99c0.53-0.43,1.1-0.89,1.66-1.35c-0.04-0.09-0.09-0.18-0.13-0.26
		c-0.91,0-1.83,0.01-2.74,0c-3.24-0.03-6.24-0.91-8.97-2.65c-5.99-3.8-8.95-10.84-7.51-17.83c1.39-6.77,7.03-12.16,13.93-13.22
		C24.99,48.4,26.59,48.42,28.23,48.32z M18.67,11.79c-3.74-0.01-6.8,3.03-6.81,6.77c0,3.7,3.02,6.76,6.71,6.79
		c3.74,0.03,6.83-3,6.85-6.73C25.44,14.87,22.41,11.8,18.67,11.79z M63.41,18.6c0.01-3.75-3.03-6.81-6.77-6.81
		c-3.71,0-6.76,3.01-6.79,6.71c-0.03,3.75,3,6.83,6.73,6.86C60.32,25.37,63.39,22.33,63.41,18.6z M37.66,42.99
		c-3.74-0.01-6.81,3.03-6.81,6.77c0,3.7,3.01,6.76,6.71,6.79c3.74,0.03,6.83-3,6.85-6.73C44.43,46.07,41.4,43,37.66,42.99z
		 M49.84,81.01c0.01-3.75-3.03-6.81-6.77-6.81c-3.71,0-6.76,3.01-6.79,6.71c-0.03,3.75,3,6.83,6.73,6.86
		C46.75,87.78,49.83,84.74,49.84,81.01z M80.99,87.76c3.74,0.02,6.82-3.01,6.84-6.75c0.01-3.71-2.99-6.77-6.68-6.81
		c-3.75-0.04-6.85,2.98-6.88,6.7C74.23,84.65,77.26,87.73,80.99,87.76z"/>
				</g>
			</svg>
		</span>
	)
}

