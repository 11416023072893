import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled, { CSSProperties } from 'styled-components'

interface IBatchPropertyItemICPProps {
    icon: JSX.Element
    label: string
    value: string[]
    noTranslationLabel?: boolean
    noTranslationValue?: boolean
    wrapperStyle?: CSSProperties
}

export function BatchPropertyItemICP(props: IBatchPropertyItemICPProps): JSX.Element {


    return (
        <WrapperSCP style={props.wrapperStyle}>
            {props.icon}
            <DescSCP>
                <TextCP
                    text={props.label}
                    translationFiles={props.noTranslationLabel ? undefined : 'batch'}
                    translationNameSpace={props.noTranslationLabel ? undefined : 'batch'}
                    style={{ fontSize: '12px', letterSpacing: '1px', fontWeight: 300 }}
                />

                <ValueSCP>
                    {props.value.map((item: string, index: number) => (
                        <RowSCP key={item}>
                            <TextCP
                                text={item}
                                translationFiles={props.noTranslationValue ? undefined : 'batch'}
                                translationNameSpace={props.noTranslationValue ? undefined : 'batch'}
                            />
                            {index !== props.value.length - 1 && <TextCP text={', '} />}
                        </RowSCP>
                    ))}
                </ValueSCP>

            </DescSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display:  flex;
    column-gap: 15px;
    min-height: 60px;
    align-items: start;
    .ant-typography, svg{
        color: white;
        fill: white;
    }
`

const DescSCP = styled.div`
    display:  flex;
    flex-direction: column;
    
`

const ValueSCP = styled.div`
    display:  flex;
  flex-wrap: wrap;
    
`

const RowSCP = styled.div`
  display: flex;
  column-gap: 2px;
  margin-right: 2px;

  flex-wrap: wrap;
`