import { faSortNumericUpAlt, faSortNumericDownAlt } from '@fortawesome/free-solid-svg-icons'
import { Row } from 'antd'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { TextCP } from 'common/components/text/TextCP'
import { OrderingCriteriaEnum } from 'common/enums/OrderingCriteriaEnum'
import { OrderingUtils } from 'common/utils/OrderingUtils'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { ThemeConfig } from 'config/ThemeConfig'
import { ISearchBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISearchBatchRequestDTO'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface IOrderingOptionsICPProps {
    filters?: ISearchBatchRequestDTO
    setFilters: (filters: ISearchBatchRequestDTO) => void
}

export function OrderingOptionsICP(props: IOrderingOptionsICPProps): JSX.Element {
    const [orderingAscending, setOrderingAscending] = useState<boolean>()
    const [orderingCriteria, setOrderingCriteria] = useState<OrderingCriteriaEnum>()

    useEffect(onChangeSort, [orderingAscending, orderingCriteria])

    function onChangeSort(): void {
        if (orderingAscending === undefined || !orderingCriteria)
            return
        props.setFilters({ ...props.filters, orderingCriteria, orderingAscending })
    }

    return (
        <WrapperSCP>
            <Row>
                <TextCP text={'Ordenar por:'} />
            </Row>
            <OptionsWppSCP>
                {
                    Object.values(OrderingCriteriaEnum).map(oc => (
                        <ItemICP
                            key={`${oc}`}
                            primary={props.filters?.orderingCriteria === oc}

                        >
                            <span
                                onClick={() => {
                                    setOrderingCriteria(oc)
                                    setOrderingAscending(!orderingAscending)
                                }}
                                style={{ width: '100%' }}
                            >
                                {OrderingUtils.translateOrderingCriteria(oc)}
                            </span>
                            {
                                props.filters?.orderingCriteria === oc &&
                                <FontAwsomeIconCP
                                    icon={orderingAscending ? faSortNumericUpAlt : faSortNumericDownAlt}
                                    onClick={() => setOrderingAscending(!orderingAscending)}
                                />
                            }
                        </ItemICP>
                    ))
                }

            </OptionsWppSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    .ant-typography{
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: 500;
    }
`
const OptionsWppSCP = styled.div`
`
const ItemICP = styled.div<{ primary?: boolean }>`
    padding: 0 10px;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    padding: 6px 0;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: ${props => props.primary ? props.theme.primaryColor : '#000'};
    border-bottom:  1px solid ${props => props.primary ? props.theme.primaryColor : props.theme.lightGray};
  
`
