import { InputCP } from 'common/components/form-fields/input/InputCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { PaymentUnitySelectorCP } from 'modules/logbook/components/payment-unity-selector/PaymentUnitySelectorCP'
import { ISavePersonResponsibleReqDTO } from 'modules/logbook/services/dtos/request/ISavePersonResponsibleReqDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'


interface IPaymentDataFieldsICPProps {
    personResponsible: ISavePersonResponsibleReqDTO
    setPersonResponsible: (personRes: ISavePersonResponsibleReqDTO) => void
    loading?: boolean
    defaultValue?: number
    setHasInvalidFields: (invalid: boolean) => void
}

export function PaymentDataFieldsICP(props: IPaymentDataFieldsICPProps): JSX.Element {
    const [finalValue, setFinalValue] = useState<string>()

    useEffect(defineDefaultValue, [props.defaultValue])
    useEffect(definedFinalValue, [props.personResponsible])

    function defineDefaultValue(): void {
        if (!!props.defaultValue) {
            setFinalValue(`${props.defaultValue}`)
            props.setPersonResponsible({ ...props.personResponsible, paidValue: +props.defaultValue })
        }
    }
    function definedFinalValue(): void {
        setFinalValue(`${props.personResponsible.paidValue}`)
        if (!!props.personResponsible.personCode)
            props.setHasInvalidFields(!props.personResponsible.quantity || !props.personResponsible.paymentUnity || !props.personResponsible.paidValue)

    }

    return (
        <WrapperSCP>
            <InputCP
                label={'Quantidade'}
                value={props.personResponsible.quantity}
                onChange={(quantity) => props.setPersonResponsible({ ...props.personResponsible, quantity: +quantity })}
                required={true}
                type={'tel'}
                loading={props.loading}
            />
            <PaymentUnitySelectorCP
                label={'Unidade'}
                required={true}
                value={props.personResponsible.paymentUnity}
                onSelect={(paymentUnity) => props.setPersonResponsible({ ...props.personResponsible, paymentUnity })}
                loading={props.loading}
            />
            <InputCP
                label={'Valor a pagar (por unidade)'}
                value={finalValue}
                onChange={(paidValue) => {
                    props.setPersonResponsible({ ...props.personResponsible, paidValue })
                }}
                required={true}
                type={'currency'}
                loading={props.loading}
            />

            <InputCP
                label={'Observação'}
                value={props.personResponsible.notes}
                required={false}
                onChange={(notes) => props.setPersonResponsible({ ...props.personResponsible, notes })}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1.2fr 1fr 2fr;
    column-gap: 10px;
    align-items: center;
    @media(max-width: 768px){
        grid-template-columns: 1fr 1fr;
    }
`