import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { TextCP } from 'common/components/text/TextCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { BatchSaleFormDrawerCP } from 'modules/batch/components/batch-sale-form-drawer/BatchSaleFormDrawerCP'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { IBatchSaleResDTO } from 'modules/batch/services/dtos/responses/IBatchSaleResDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IBatchSaleLIstItemICPProps {
    item: IBatchSaleResDTO
    loading?: boolean
    key: string

    onChangeItem: () => void
    batchCode: number
}

export function BatchSaleLIstItemICP(props: IBatchSaleLIstItemICPProps): JSX.Element {
    const [newSaleDrawerVisible, setNewSaleDrawerVisible] = useState<boolean>(false)
    const screenSize = useScreenSize()

    const deleteReq = useRequest(BatchRequests.deleteSale(props.batchCode, props.item.code))
    useEffect(onDeletRequestChange, [deleteReq.awaiting])

    function onDeletRequestChange(): void {
        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success || !deleteReq.returnData) {
            if (deleteReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteReq.returnData, deleteReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Erro ao excluir venda!')
        }
        props.onChangeItem()
    }

    return (
        <ListItemICP
            key={props.key}
            isMobile={screenSize.smd}
            loading={props.loading}
            style={{ padding: '0', margin: '0' }}
            customSkeleton={<GenericRowSkeletonICP rows={1} columns={8} />}
        >
            <WrapperSCP>
                <MobileLabelsSCP>
                    <TextCP text={'Comprador:'} />
                    <TextCP text={'Sacas:'} />
                    <TextCP text={'Valor da unidade:'} />
                    <TextCP text={'Data:'} />
                    <TextCP text={'Observações:'} />

                </MobileLabelsSCP>
                <ValuesSCP>
                    <TextCP text={`${props.item.buyer}`} />
                    <TextCP text={`${props.item.bags}`} />
                    <TextCP text={`${MaskUtils.applyCurrencyMask(props.item.unitValue)}`} />
                    <TextCP text={`${DateUtils.formatDate(props.item.date, DateFormatEnum.BR_WITHOUT_TIME)}`} />

                    <NoteSCP>
                        {props.item.notes}
                    </NoteSCP>
                    <ActionsSCP>
                        <ButtonCP
                            icon={<FontAwesomeIcon icon={faPencilAlt} />}
                            loading={deleteReq.awaiting}
                            onClick={() => setNewSaleDrawerVisible(true)}
                            type={'primary'}
                        />
                        <ButtonCP
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            onClick={deleteReq.runRequest}
                            loading={deleteReq.awaiting}
                            confirmMsg={'Tem certeza que deseja excluir esta venda?'}
                            type={'ghost'}
                        />
                    </ActionsSCP>
                </ValuesSCP>

            </WrapperSCP>
            <BatchSaleFormDrawerCP
                batchCode={props.batchCode}
                show={newSaleDrawerVisible}
                onCancel={() => setNewSaleDrawerVisible(false)}
                onSuccess={() => {
                    props.onChangeItem()
                    setNewSaleDrawerVisible(false)
                }}
                saleCode={props.item.code}
            />
        </ListItemICP>
    )
}

const WrapperSCP = styled.div`
`
const ActionsSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    column-gap: 5px;
`

const NoteSCP = styled.div`
    font-style: italic;
`

const MobileLabelsSCP = styled.div`
    display: none;
    .ant-typography{
        font-weight: 600;
    }
    @media(max-width: 768px){
        display: grid;
        padding: 10px 0;
        grid-template-columns: 1fr;
        row-gap: 5px;   
        width: 100%;
    }
`
const ValuesSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr  1fr 1fr 1fr 1fr 1fr;
    margin: 0;
    padding: 10px;
    column-gap: 10px;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    align-items: center;
    .ant-list-bordered.ant-list-lg .ant-list-item{
        padding: 0!important;
    }
    .ant-typography{
        font-size: 12px
    }
    width: 100%;
    @media(max-width: 768px){
        grid-template-columns: 1fr;    
        row-gap: 5px;    
        border-bottom: none;
    }
`