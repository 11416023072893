import React, { useState, useEffect } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { TextCP } from 'common/components/text/TextCP'
import { IFieldReportResDTO } from 'modules/report/services/dtos/responses/IFieldReportResDTO'
import { FieldUtils } from 'modules/field/utils/FieldUtils'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { IBatchResumeResDTO } from 'modules/report/services/dtos/responses/IBatchResumeResDTO'
import { CaneforaVarietyEnum, ArabicaVarietyEnum } from 'modules/field/enums/CoffeeTypeEnum'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { BatchProcessesResumeCP } from 'modules/report/components/batch-processes-resume/BatchProcessesResumeCP'
import { BatchLandingPageRoutes } from 'modules/batch-landing-page/BatchLandingPageRoutes'
import { BatchRoutes } from 'modules/batch/BatchRoutes'
import { useHistory } from 'react-router-dom'

type GeneralBatchDataTP = {
    names: string,
    varieties: string,
    method?: string,
    origin: string,
}
interface IBestBatchItemICPProps {
    batch: IBatchResumeResDTO
    loading?: boolean
    key: number
    reportData?: IFieldReportResDTO
}

export function BestBatchItemICP(props: IBestBatchItemICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const history = useHistory()
    const [wrapperStyle, setWrapperStyle] = useState<CSSProperties>({})
    const [fieldData, setFieldData] = useState<GeneralBatchDataTP>({ names: '', varieties: '', method: '', origin: '' })
    const [showProcesses, setShowProcesses] = useState<boolean>(false)

    useEffect(handleFieldData, [props.batch])
    useEffect(onExpand, [showProcesses])

    function handleFieldData(): void {
        if (!props.batch.fields)
            return
        const varieties = new Set(props.batch.fields.map(fie => fie.coffeeVariety))
        const varietiesOther = new Set(props.batch.fields.map(fie => fie.varieties.map(v => v.name).join(', ')))
        const allVarieties = [...varieties, ...varietiesOther]

        setFieldData({
            names: props.batch.fields.map(fie => fie.name).join(', '),
            varieties: allVarieties.map(variety => FieldUtils.translateVarietysEnum(variety as CaneforaVarietyEnum | ArabicaVarietyEnum)).join(', '),
            method: !!props.batch.method ? BatchUtils.translateMethod(props.batch.method) : undefined,
            origin: BatchUtils.translateOrigin(props.batch.origin)
        })
    }

    function onExpand(): void {
        if (showProcesses) {
            return setWrapperStyle({
                height: '400px',
            })
        }
        if (screenSize.smd) {
            return setWrapperStyle({
                height: '240px',
            })
        }
        return setWrapperStyle({
            height: '150px',
        })
    }

    return (
        <ListItemICP
            key={props.key}
            isMobile={screenSize.smd}
            loading={props.loading}
            style={{ padding: '0', margin: '20px 0px' }}
            customSkeleton={<WrapperItemSCP><GenericRowSkeletonICP rows={7} columns={7} /></WrapperItemSCP>}
        >
            <MainWrapperSCP style={wrapperStyle}>
                <WrapperItemSCP>
                    <MainInfoSCP>
                        <GridItemSCP><CodeWrapperSCP onClick={() => history.push(`${BatchRoutes.PROCESS_WITHOUT_PARAM}/${props.batch.code}`, 'Batch Details')}><span>{`#${props.batch.code}`}</span></CodeWrapperSCP></GridItemSCP>

                        <GridItemSCP>
                            <TextCP className={'highlight'} style={{ fontWeight: 'bold' }} text={`#${props.batch.code} - ${fieldData.names}`} />
                            <RowSCP>
                                <TextCP text={'Variedade:\u00A0'} style={{ fontWeight: 500 }} />
                                <TextCP text={`${fieldData.varieties}`} />
                            </RowSCP>
                            <RowSCP>
                                <TextCP text={'Origem:\u00A0'} style={{ fontWeight: 500 }} />
                                <TextCP text={`${fieldData.origin}`} />
                            </RowSCP>
                            {
                                !!fieldData.method &&
                                <RowSCP>
                                    <TextCP text={'Método:\u00A0'} style={{ fontWeight: 500 }} />
                                    <TextCP text={`${fieldData.method}`} />
                                </RowSCP>
                            }
                        </GridItemSCP>
                    </MainInfoSCP>

                    <PointsSCP>
                        <GridItemSCP>
                            <TextCP className={'highlight'} style={{ fontWeight: 'bold', fontSize: '25px' }} text={`${!!props.batch.averageScore ? MaskUtils.applyNumberMask(props.batch.averageScore) : '-'}`} />
                            <TextCP text={'pontuação média'} />
                        </GridItemSCP>
                        <GridItemSCP>
                            <TextCP className={'highlight'} style={{ fontWeight: 'bold', fontSize: '25px' }} text={`${!!props.batch.averagePicking ? MaskUtils.applyNumberMask(props.batch.averagePicking) : '-'}%`} />
                            <TextCP text={'catação média'} />
                        </GridItemSCP>
                        <GridItemSCP>
                            <TextCP className={'highlight'} style={{ fontWeight: 'bold', fontSize: '25px' }} text={`${props.batch.postHarvestDays} dias`} />
                            <TextCP text={'tempo de pós-colheita'} />
                        </GridItemSCP>
                    </PointsSCP>


                </WrapperItemSCP>
                {/* <ButtonCP
                    icon={<FontAwsomeIconCP icon={showProcesses ? faChevronUp : faChevronDown} />}
                    onClick={() => setShowProcesses(!showProcesses)}
                    style={{
                        position: 'absolute',
                        right: 0,
                        left: 0,
                        margin: '0 auto',
                        bottom: -15,
                        borderRadius: '100%'
                    }}
                    type={'primary'}
                />
                {
                    !!showProcesses &&
                    <BatchProcessesResumeCP processes={props.batch.processes} />
                } */}
            </MainWrapperSCP>
        </ListItemICP>
    )
}

const WrapperItemSCP = styled.div`
    display: grid;
    padding: 20px;
    grid-template-columns: 45% 55%;
    grid-column-gap: 10px;
    background-color: white;
    border-radius: 8px;
   
    height: 150px;
    transition: height 1s;

    @media(max-width: 768px){
        height: auto;
        row-gap: 20px;
        grid-template-columns: 100%;
    }
 
   
`

const PointsSCP = styled.div`
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-column-gap: 20px;
`

const MainInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 20% 80%;
`

const GridItemSCP = styled.div`
    .highlight {
        color: ${props => props.theme.primaryColor};
    }
`
const MainWrapperSCP = styled.div`
     position: relative;
     border-bottom: 1px solid ${props => props.theme.lightGray};
     .ant-btn span svg {
      margin: 0;
    }
`
const CodeWrapperSCP = styled.div`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    font-weight: 500;
    color: white;
    background-color: ${props => props.theme.primaryColor};
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const RowSCP = styled.div`
    display: flex;
    row-gap: 10px;
`