import { TextCP } from 'common/components/text/TextCP'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

interface IFeatureCardICPProps {
    title: string
    icon?: JSX.Element
    options: Array<string | undefined>
    others?: string
}

export function FeatureCardICP(props: IFeatureCardICPProps): JSX.Element {
    const clear = _.compact(props.options)
    return (
        <WrapperSCP>
            <IconSCP>
                {props.icon}
            </IconSCP>

            <TextCP
                text={props.title}
                translationNameSpace={'batch'}
                translationFiles={'batch'}
                style={{ fontSize: '18px', fontWeight: 600, marginBottom: '1em' }}
            />
            <RowSCP>
                {clear.map((opt: string, index: number) => (
                    <RowSCP key={opt}>
                        <TextCP
                            text={opt}
                            key={opt}
                            translationFiles={'batch'}
                            translationNameSpace={'batch'}
                        />
                        {index !== props.options.length - 1 && <TextCP text={', '} />}
                    </RowSCP>
                ))}
                {
                    !!props.others &&
                    <TextCP
                        text={props.others}
                    />
                }
            </RowSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    border-radius: 10px;
    min-height: 280px;
    width: 200px;
    margin: 3px 5px;
    padding: 15px;
    .ant-typography{
        color: white;
    }
    @media(max-width: 768px){
        width: 90%;
    }
`
const RowSCP = styled.div`
  display: flex;
  column-gap: 3px;
  flex-wrap: wrap;
`

const IconSCP = styled.div`
 display: flex;
  justify-content: flex-start;
`