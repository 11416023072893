import moment from 'moment-timezone'
import { TextCP } from 'common/components/text/TextCP'
import { IProcessAlbumResponseDTO } from 'modules/batch/services/dtos/responses/IProcessAlbumResponseDTO'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { LogbookUtils } from 'modules/logbook/utils/LogbookUtils'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { PhotoGalleryCP } from 'common/components/custom-photo-gallery/PhotoGalleryCP'
import { Upload } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { RcFile } from 'antd/lib/upload'
import { NotificationHelper } from 'common/NotificationHelper'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { faCamera, faSpinner, faPenAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { NewAlbumDrawerICP } from 'modules/batch/components/batch-process-album-drawer/inner/NewAlbumDrawerICP'

interface IAlbumContentICPProps {
    batchCode: number
    processCode: number
    albumData: IProcessAlbumResponseDTO
    onUpdate: () => void
    loading: boolean
    isExternalPage?: boolean
}
const MAX_FILE_SIZE = 5

export function AlbumContentICP(props: IAlbumContentICPProps): JSX.Element {
    const [files, setFiles] = useState<any>([])
    const timeoutId = useRef<any>()

    const [newAlbumDrawerVisible, setNewAlbumDrawerVisible] = useState<boolean>(false)

    const imageTypes = [
        { mime: 'image/jpeg', extension: 'jpeg' },
        { mime: 'image/jpg', extension: 'jpg' },
        { mime: 'image/png', extension: 'png' },
    ]

    const request = useRequest<IProcessAlbumResponseDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const deleteReq = useRequest(BatchRequests.deleteProcessAlbum(props.batchCode, props.processCode, props.albumData.code))
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    const deleteImageReq = useRequest()
    useEffect(onDeleteImageReqChange, [deleteImageReq.awaiting])

    useEffect(prepareToSave, [files])

    function prepareToSave(): void {
        if (files.length === 0)
            return

        if (!!timeoutId.current)
            clearTimeout(timeoutId.current)

        timeoutId.current = setTimeout(() => saveImages(), 1000)
    }

    function appendImages(option: any): void {
        setFiles([...files, option.file])
    }

    function saveImages(): void {
        const formData = new FormData()

        for (const file of files)
            formData.append('files', file)

        request.runRequest(BatchRequests.uploadImagesToAlbum(props.batchCode, props.processCode, props.albumData.code, formData))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao subir imagens!')
        }

        NotificationHelper.success('Images adicionadas com sucesso!')
        setFiles([])
        props.onUpdate()
    }

    function validateFile(file: RcFile): boolean {

        if (!file) {
            NotificationHelper.error('Nenhum arquivo foi encontrado')
            return false
        }
        if (file.size / 1024 / 1024 > MAX_FILE_SIZE) {
            NotificationHelper.error('Somente é permitido arquivos menores que 5 MB')
            return false
        }

        return true
    }

    function onDeleteReqChange(): void {
        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success || !deleteReq.returnData) {
            if (deleteReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteReq.returnData, deleteReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Erro ao excluir álbum!')
        }
        props.onUpdate()
    }

    function removeImage(url: string): void {
        deleteImageReq.runRequest(BatchRequests.removePicturesFromAlbum(props.batchCode, props.processCode, props.albumData.code, { pictures: [url] }))
    }

    function onDeleteImageReqChange(): void {
        if (deleteImageReq.awaiting || !deleteImageReq.tried)
            return

        if (!deleteImageReq.success || !deleteImageReq.returnData) {
            if (deleteImageReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteImageReq.returnData, deleteImageReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteImageReq.error, 'Erro ao excluir imagem!')
        }
        props.onUpdate()
    }

    return (
        <WrapperSCP>
            <HeaderSCP>
                <InfoWrapperSCP>
                    <DateTimelineSCP>
                        <TextCP
                            text={moment(props.albumData.date).format('DD')}
                            style={{
                                fontSize: '20px',
                                fontWeight: '600',
                                color: 'white',
                                lineHeight: '18px',
                                leterSpacing: '3px'
                            }}
                        />
                        <TextCP
                            text={moment(props.albumData.date).locale('pt-br').format('MMM')}
                            style={{
                                fontSize: '12px',
                                fontWeight: 'normal',
                                color: 'white',
                                lineHeight: '13px',
                            }}
                        />
                    </DateTimelineSCP>
                    <CenterInfoWrapperSCP>

                        <DateAndTimeSCP>
                            <TextCP
                                text={`${LogbookUtils.getDateText(props.albumData.date)}`}
                                style={{ fontSize: '14px', fontWeight: 500 }}
                            />
                        </DateAndTimeSCP>
                        {
                            props.albumData.notes &&
                            <NoteWrapperSCP>
                                {props.albumData.notes}
                            </NoteWrapperSCP>
                        }
                    </CenterInfoWrapperSCP>
                </InfoWrapperSCP>
                {
                    !props.isExternalPage &&
                    <ActionsWrapperSCP>
                        <Upload
                            multiple={true}
                            customRequest={appendImages}
                            showUploadList={false}
                            accept={imageTypes.map(t => t.mime).join(',')}
                            beforeUpload={validateFile}
                        >
                            <ButtonCP
                                type={'primary'}
                                onClick={() => console.log('selecionar imagens')}
                                icon={<FontAwsomeIconCP icon={faCamera} />}
                            >

                                Adicionar fotos
                            </ButtonCP>
                        </Upload>

                        <ButtonCP
                            type={'primary'}
                            onClick={() => setNewAlbumDrawerVisible(true)}
                            icon={<FontAwsomeIconCP icon={faPenAlt} />}

                        />
                        <ButtonCP
                            type={'ghost'}
                            onClick={deleteReq.runRequest}
                            icon={<FontAwsomeIconCP icon={faTrashAlt} />}

                        />
                        {
                            deleteReq.awaiting || request.awaiting || deleteImageReq.awaiting &&
                            <FontAwsomeIconCP
                                icon={faSpinner}
                                loading={true}
                                style={{
                                    fontSize: '20px',
                                    margin: '0 10px',
                                }}
                            />
                        }

                    </ActionsWrapperSCP>
                }
            </HeaderSCP>

            <PhotoGalleryCP
                images={!!props.albumData.pictures ?
                    props.albumData.pictures.map(pic => ({
                        url: pic,
                        onDelete: props.isExternalPage ? undefined : () => removeImage(pic),
                        previewConfig: {
                            mask: <MaskWrapperSCP />
                        }
                    }))
                    :
                    []}
            />

            <NewAlbumDrawerICP
                show={newAlbumDrawerVisible}
                processCode={props.processCode}
                batchCode={props.batchCode}
                albumCode={props.albumData.code}
                onCancel={() => setNewAlbumDrawerVisible(false)}
                onSuccess={() => {
                    setNewAlbumDrawerVisible(false)
                    props.onUpdate()
                }}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
   display: flex;
   flex-direction: column;
`
const InfoWrapperSCP = styled.div`
    display: flex;
    @media(max-width: 768px){
      margin-bottom: 10px;
       
   }
`
const HeaderSCP = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin: 20px 0;
   @media(max-width: 768px){
        flex-direction: column;
       
   }
`

const DateTimelineSCP = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 60px;
    min-width: 60px;
    justify-content: center;
    position: relative;
`
const CenterInfoWrapperSCP = styled.div`
    margin: 10px 0 10px 12px;
    display: grid;
    row-gap: 8px;
`
const DateAndTimeSCP = styled.div`
    .ant-typography{
        color:${props => props.theme.primaryColor};
    }
`
const NoteWrapperSCP = styled.div`    
    font-style: italic;
`

const ActionsWrapperSCP = styled.div`
    display: flex;
    align-items: center;
    .ant-btn{
        margin: 0 5px;
    }
    svg {
        ${props => props.theme.primaryColor};
    }
`

const MaskWrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    opacity: .5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
`

const ActionsSCP = styled.div`
    position: absolute;
    bottom: 20px;
    height: 56px;
    background: white;
    width: 100%;
    padding: 10px;
    display: flex;
    svg {
        font-size: 25px;
        margin: 0 5px;
        color: ${props => props.theme.primaryColor};
    }
`