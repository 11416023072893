/**
 * UTILS
 * Encapsula definicao de metodos genericos uteis para manipulacao
 * de strings.
 *
 * @author hjcostabr
 */
export class StringUtils {

    private constructor() { /** Construtor privado impede instanciacao. */ }

    /** Altera & retorna 01 string na forma 'camel-case'. */
    static toCamelCase(str: string): string {

        if (!str.match(/-/))
            return str

        return str
            .split('-')
            .reduce(
                (camelCaseStr: string, strToken: string) => {
                    if (!!camelCaseStr)
                        strToken = (strToken.charAt(0).toUpperCase() + strToken.slice(1))
                    return camelCaseStr + strToken
                },
                ''
            )
    }

    public static capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    /** Transforma & retorna string da forma 'camelCase' na forma 'KebabCase'. */
    public static camelCaseToKebabCase(str: string): string {
        return str
            .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
            .toLowerCase()
    }

    /**
     * Obtem a primeira e ultima iniciais de uma string
     */
    public static getFirstAndLastInitials(str: string): string {

        if (!str)
            return ''

        const initials = str.match(/\b\w/g) ?? []
        return ((initials.shift() ?? '') + (initials.pop() ?? '')).toUpperCase()
    }

    public static leftPad(width: number, n: string | number): string {
        if ((`${n}`).length > width)
            return `${n}`

        const padding = new Array(width).join('0')
        return (`${padding}${n}`).slice(-width)
    }
}
