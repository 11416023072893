import { MobileListItemICP } from 'common/components/list/inner/MobileListItemICP'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { MaskUtils } from 'common/utils/MaskUtils'
import { ThemeConfig } from 'config/ThemeConfig'
import { IFormulaApplicationByFieldResDTO } from 'modules/maneuver/services/dtos/responses/IFormulaApplicationByFieldResDTO'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import React from 'react'
import styled from 'styled-components'

interface IFormulaContentICPProps {
    item: IFormulaApplicationByFieldResDTO
    loading?: boolean
}

export function FormulaContentICP(props: IFormulaContentICPProps): JSX.Element {
    const screenSize = useScreenSize()

    if (screenSize.smd) {
        return (
            <MobileListItemICP
                noPadding={true}
                loading={!!props.loading}
                key={`${props.item.productCode}`}
                data={[
                    { label: 'Produto', value: props.item.productName },
                    { label: 'Qtd.', value: `${MaskUtils.applyNumberMask(props.item.quantity)}${ProductUtils.getUnitOfMeasurementAbbreviation(props.item.unity)}/ha` },
                    { label: 'Área', value: `${MaskUtils.applyNumberMask(props.item.totalArea)} ha` },
                    { label: 'Gasto', value: `${MaskUtils.applyNumberMask(props.item.finalExpense)} ${ProductUtils.getUnitOfMeasurementAbbreviation(props.item.unity)}` },
                    { label: 'Em estoque', value: `${MaskUtils.applyNumberMask(props.item.currentRemaining)} ${ProductUtils.getUnitOfMeasurementAbbreviation(props.item.unity)}` },
                    { label: 'Saldo final', value: `${MaskUtils.applyNumberMask(props.item.finalRemaining)} ${ProductUtils.getUnitOfMeasurementAbbreviation(props.item.unity)}` }
                ]}
            />
        )
    }
    return (
        <GridItemsSCP key={props.item.productCode}>
            <TextCP text={props.item.productName} />
            <TextCP text={`${MaskUtils.applyNumberMask(props.item.quantity)}${ProductUtils.getUnitOfMeasurementAbbreviation(props.item.unity)}/ha`} />
            <TextCP text={`${MaskUtils.applyNumberMask(props.item.totalArea)} ha`} />
            <TextCP text={`${MaskUtils.applyNumberMask(props.item.finalExpense)} ${ProductUtils.getUnitOfMeasurementAbbreviation(props.item.unity)}`} />
            <TextCP text={`${MaskUtils.applyNumberMask(props.item.currentRemaining)} ${ProductUtils.getUnitOfMeasurementAbbreviation(props.item.unity)}`} />
            <TextCP
                text={`${MaskUtils.applyNumberMask(props.item.finalRemaining)} ${ProductUtils.getUnitOfMeasurementAbbreviation(props.item.unity)}`}
                style={{ color: `${props.item.finalRemaining < 0 ? ThemeConfig.errorColor : ThemeConfig.gray}` }} />
        </GridItemsSCP>
    )
}


const GridItemsSCP = styled.div`
 column-gap: 10px;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }
`