import { CaretRightOutlined } from '@ant-design/icons'
import { Layout } from 'antd'
import { SystemConfig } from 'config/SystemConfig'
import React from 'react'

const { Header, Content, Footer } = Layout

interface Props {
    children: JSX.Element
}

export function RegistrationLayout(props: Props): JSX.Element {
    return (
        <Layout className={'registration-layout'}>
            <Header className={'registration-header'}>
                <img src={`${SystemConfig.getInstance().uiBaseUrl}/assets/flowins.png`} width={50} height={50} />
                <div>
                    Gerente Flowins
                    {' '}
                    <CaretRightOutlined />
                    {' '}
                    Novo Cadastro
                </div>
            </Header>
            <Content className={'registration-content'}>
                <Layout className={'inner-layout'}>
                    <Content className={'inner-content'}>
                        {props.children}
                    </Content>
                </Layout>
            </Content>
            <Footer className={'registration-footer'}>Flowins ©2020</Footer>
        </Layout>
    )
}