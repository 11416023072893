import { Skeleton, Tabs } from 'antd'
import * as React from 'react'
import styled from 'styled-components'

export type TabTP = {
    title: string | JSX.Element,
    key: string,
    content: JSX.Element,
}

interface ITabsCPProps {
    tabs: TabTP[]
    defaultActiveKey: string
    activeKey?: string
    type?: 'line' | 'card' | 'editable-card'
    onChange?: (key: string) => void
    onAddTab?: () => void
    onRemoveTab?: (key: string) => void
    tabPosition?: 'top' | 'right' | 'bottom' | 'left'
    loading?: boolean
    spread?: boolean
}

/**
 * Componente de abas customizado para o projeto.
 *
 * @author Lucas Rosa
 * @author Stella
 * @author hjcostabr
 */
export function TabsCP(props: ITabsCPProps): JSX.Element {

    function onEdit(e: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove'): void {

        if (action === 'add' && !!props.onAddTab)
            return props.onAddTab()
        console.log(e)

        // if (action === 'remove' && !!props.onRemoveTab && typeof key === 'string')
        //     return props.onRemoveTab(key)
    }

    return (
        <TabsSCP
            type={props.type}
            activeKey={props.activeKey}
            onChange={props.onChange}
            onEdit={onEdit}
            defaultActiveKey={props.loading ? 'loading-tab' : props.defaultActiveKey}
            tabPosition={props.tabPosition}
            spread={props.spread}
        >
            {props.loading && <Tabs.TabPane tab={<SkeletonSCP loading={true} paragraph={false} title={true} active={true} />} key={'loading-tab'} />}
            {
                props.tabs.map((tab: TabTP) => (
                    <Tabs.TabPane tab={tab.title} key={tab.key}>
                        {tab.content}
                    </Tabs.TabPane>
                ))
            }
        </TabsSCP>
    )
}

const SkeletonSCP = styled(Skeleton)`
    .ant-skeleton-content{
        h3{
            width: 250px;
            margin: 3px 0;
        }
    }
`
const TabsSCP = styled(Tabs) <{ spread?: boolean }>`
    background: #fff;
    padding: 10px 20px;
    /* height: 100%;
    overflow: overlay;;
 -webkit-overflow-scrolling: touch; */
    .ant-tabs-nav-scroll {
        width: 100%;
    }

    &.ant-tabs{
        .ant-tabs-tab:hover{
            color: ${props => props.theme.primaryColor};
        }
    }

    &.ant-tabs  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn
    {
        color: ${props => props.theme.primaryColor};
    }
    .ant-tabs-tabpane {
        outline: none;
        position: relative;
        left: 0;
        padding: 0 10px;


    }

    &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list{
        &.ant-tabs-tab {
            &.ant-tabs-tab-active .ant-tabs-tab-btn{
                color: ${props => props.theme.primaryColor};
            }
        }
    }

    &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list{
        /* width: 100%; */
        font-weight: 600;
        .ant-tabs-tab{
            font-size: 16px;
            width: ${props => (props.spread ? '100%' : 'auto')};
        }
    }

    &.ant-tabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll >.ant-tabs-nav {

        width: 100%;

        > div {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
        }

        .ant-tabs-ink-bar {
            background: ${props => props.theme.primaryColor};
            height: 3px;
        }

        .ant-tabs-tab {
            color: ${props => props.theme.secondaryColor};
            font-weight: 500;
            flex: 1;
            text-align: center;
            margin: 0;
            border-radius: 0;
            &.ant-tabs-tab-active .ant-tabs-tab-btn{
                color: ${props => props.theme.primaryColor};
            }
        }
       
        .ant-tabs-tab-active {
            color: ${props => props.theme.primaryColor};
            font-weight: 500;
            .ant-tabs-tab-btn {
                    color: ${props => props.theme.primaryColor};
            }
        }
    }

    &.ant-tabs .ant-tabs-extra-content .ant-tabs-new-tab {
        width: 40px;
        height: 40px;
        background-color: ${props => props.theme.normalColor};
        color: ${props => props.theme.secondaryColor};
        border-radius: 3px;
    }
    &.ant-tabs-extra-content{
        margin: 0;
    }
    &.ant-tabs-bar{
        margin:0;
    }

    @media(max-width: 767px){
        &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab{
            font-size: 14px;
            margin: 0 14px 0 0;
        }
        padding: 10px 8px;
        margin-bottom: 15em;
        border-radius: 10px;
    .ant-tabs-tabpane {
        padding: 0;
    }

`
