/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/typedef */
import { SelectOptionTP } from 'common/components/form-fields/select/inner/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'

import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { ImplementTypeEnum } from 'modules/maneuver/enums/ImplementTypeEnum'
import { IImplementResponseDTO } from 'modules/maneuver/services/dtos/responses/IImplementResponseDTO'
import { ImplementRequests } from 'modules/maneuver/services/ImplementRequests'
import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import { TextCP } from 'common/components/text/TextCP'

interface IEquipmentSelectorCPProps<FModelTP extends FormModelValidator = any> {
    formStateManager?: IFormStateManager<FModelTP>
    label?: string
    onlyVehicles: boolean
    required?: boolean
    value?: number
    onSelect: (value: number, isVehicle: boolean, fuelCode?: number, currentHourMeter?: number) => void
    onClear?: () => void
    setNoneLoaded?: (none: boolean) => void
}

export function EquipmentSelectorCP<FModelTP extends FormModelValidator = any>(props: IEquipmentSelectorCPProps<FModelTP>): JSX.Element {
    const [equipmentOptions, setEquipmentOptions] = useState<SelectOptionTP[]>([])
    const [equipments, setEquipments] = useState<IImplementResponseDTO[]>([])
    const fieldName = props.onlyVehicles ? 'vehicleCode' : 'equipmentCode'
    // const [isVehicle, setIsVehicle] = useState<boolean>(false)
    useEffect(getImplementList, [props.onlyVehicles])

    const request = useRequest<IGenericListResponseDTO<IImplementResponseDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    function getImplementList(): void {
        if (props.onlyVehicles)
            request.runRequest(ImplementRequests.getImplementList({ type: ImplementTypeEnum.VEHICLE }))
        else
            request.runRequest(ImplementRequests.getImplementList({ type: ImplementTypeEnum.EQUIPMENT }))

    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)

            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar itens!')
        }

        if (!!props.setNoneLoaded)
            props.setNoneLoaded(!request.returnData.list.length)

        setEquipments(request.returnData.list)
        const options = request.returnData.list.map(eq => ({
            label: `${eq.brand}/${eq.model} ${eq.capacity ? `${eq.capacity ?? 0}L` : ' '}`,
            value: eq.code,
        }))
        setEquipmentOptions(options)

    }

    function getFuelData(value: number): number | undefined {
        const data = equipments.find(imp => imp.code === value)
        return data?.fuelCode
    }

    function getCurrentHourMeter(value: number): number | undefined {
        const data = equipments.find(imp => imp.code === value)
        return data?.currentHourMeter
    }

    return (
        <WrapperSCP>
            {
                equipmentOptions.length === 0 ?
                    request.awaiting ?
                        <Skeleton.Input active={true} />
                        :
                        <TextCP text={`Você ainda não cadastrou nenhum ${props.onlyVehicles ? 'veículo' : 'equipamento'}.`} />
                    :
                    <SelectCP
                        options={equipmentOptions}
                        label={props.label}
                        allowClear={true}
                        onClear={props.onClear}
                        placeholder={`${props.onlyVehicles ? 'Selecione o veículo' : 'Selecione o implemento'}`}
                        required={props.required}
                        fieldName={fieldName}
                        loading={request.awaiting}
                        formStateManager={props.formStateManager}
                        onChange={(value) => props.onSelect(value, !!props.onlyVehicles, getFuelData(value), getCurrentHourMeter(value))}
                        value={props.value}
                        disabled={request.awaiting}
                    />
            }
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const SwitchWrapperSCP = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`