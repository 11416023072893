import { faFileArchive, faPen, faScrewdriver, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ReadMoreCP } from 'common/components/read-more/ReadMoreCP'
import { TextCP } from 'common/components/text/TextCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { MaintenanceDrawerCP } from 'modules/maneuver/components/maintenance-drawer/MaintenanceDrawerCP'
import { ProdMaintenanceListDrawerCP } from 'modules/maneuver/components/maintenance-list-drawer/ProdMaintenanceListDrawerCP'
import { IMaintenanceResponseDTO } from 'modules/maneuver/services/dtos/responses/IMaintenanceResponseDTO'
import { ImplementRequests } from 'modules/maneuver/services/ImplementRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GenericCardSkeletonICP } from 'common/components/list/inner/GenericCardSkeletonICP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DocsDrawerCP } from 'modules/maneuver/components/purchase-docs-drawer/DocsDrawerCP'

interface IMaintenanceListItemICPProps {
    maintenanceData: IMaintenanceResponseDTO
    implementCode: number
    loading?: boolean
}

export function MaintenanceListItemICP(props: IMaintenanceListItemICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [prdMaintenanceVisible, setPrdMaintenanceVisible] = useState<boolean>(false)
    const [mtnDrawerVisible, setMtnDrawerVisible] = useState<boolean>(false)
    const [docsDrawerVisible, setDocsDrawerVisible] = useState<boolean>(false)

    const deleteReq = useRequest(ImplementRequests.deleteMaintenance(props.implementCode, props.maintenanceData.code))
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    function onDeleteReqChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success || !deleteReq.returnData)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Ocorreu um erro ao remover este item. Tente novamente.')

        NotificationHelper.success('Manutenção removida com sucesso')
        ListActions.mustUpdate()
    }

    if (props.loading)
        return screenSize.smd ? <GenericCardSkeletonICP /> : <GenericRowSkeletonICP columns={5} />

    return (
        <WrapperSCP>

            <GridWrapperSCP>
                <TextCP text={`${screenSize.smd ? 'Cód: ' : ''} #${props.maintenanceData.code}`} />
                <TextCP text={`${screenSize.smd ? 'Safra: ' : ''} ${props.maintenanceData.season ?? '-'}`} />
                <TextCP text={`${screenSize.smd ? 'Data: ' : ''} ${DateUtils.getFormatted(props.maintenanceData.date, DateFormatEnum.BR_WITHOUT_TIME)}`} />
                <TextCP text={`${screenSize.smd ? 'Custo: ' : ''} ${MaskUtils.applyCurrencyMask(props.maintenanceData.cost)}`} />
                {
                    !!props.maintenanceData.notes ?
                        <>
                            {screenSize.smd && <TextCP text={'Observação:'} />}
                            <ReadMoreCP
                                minTextLength={20}
                                idealTextLength={50}
                                maxTextLength={50}
                                text={props.maintenanceData.notes}
                            />
                        </>
                        : <TextCP text={'Não possui'} style={{ fontSize: '11px' }} />
                }
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={() => setPrdMaintenanceVisible(true)}
                        icon={<FontAwsomeIconCP icon={faScrewdriver} />}
                        tooltip={'Itens utilizados'}
                    >
                        Itens
                    </ButtonCP>
                    <ButtonCP
                        type={'primary'}
                        tooltip={'Documentos'}
                        onClick={() => setDocsDrawerVisible(true)}
                        icon={<FontAwesomeIcon icon={faFileArchive} size={'sm'} />}
                    />
                    <ButtonCP
                        type={'primary'}
                        onClick={() => setMtnDrawerVisible(true)}
                        icon={<FontAwsomeIconCP icon={faPen} />}
                        tooltip={'Editar'}
                    />
                    <ButtonCP
                        type={'ghost'}
                        onClick={deleteReq.runRequest}
                        icon={<FontAwsomeIconCP icon={faTrashAlt} />}
                        loading={deleteReq.awaiting}
                        confirmMsg={'Tem certeza que deseja remover esta manutenção?'}
                    />
                </ButtonWrapperSCP>
            </GridWrapperSCP>
            <MaintenanceDrawerCP
                show={mtnDrawerVisible}
                onCancel={() => setMtnDrawerVisible(false)}
                implementCode={props.implementCode}
                maintenanceCode={props.maintenanceData.code}
                onSuccess={() => {
                    setMtnDrawerVisible(false)
                }}
            />
            <ProdMaintenanceListDrawerCP
                show={prdMaintenanceVisible}
                onCancel={() => setPrdMaintenanceVisible(false)}
                implementCode={props.implementCode}
                productMaintenances={props.maintenanceData.products ?? []}
                setMtnDrawerVisible={() => setMtnDrawerVisible(true)}
            />

            <DocsDrawerCP
                type={'maintenance'}
                show={docsDrawerVisible}
                onClose={() => setDocsDrawerVisible(false)}
                itemCode={props.implementCode}
                subItemCode={props.maintenanceData.code}
            />

        </WrapperSCP>

    )
}

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    .ant-btn{
        margin: 0 5px;
    }
`

const GridWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: .3fr .3fr .4fr .4fr 1fr 1fr;
    align-items: center;
    padding: 10px 10px;
     border-bottom: 1px solid ${props => props.theme.lightGray};
     @media(max-width: 768px){
        grid-template-columns: 1fr;
        .display-text-group{
            background: ${props => props.theme.lightGray};
            padding: 10px;
            margin: 8px 0;
            border-radius: 5px;
        }
     }
`

const WrapperSCP = styled.div``

const PrdMtnWrapperSCP = styled.div`
     display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`