import { InputCP } from 'common/components/form-fields/input/InputCP'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'

import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'

import { MaskUtils } from 'common/utils/MaskUtils'
import { LogbookFormValidator } from 'modules/logbook/components/logbook-form/inner/LogbookFormValidator'
import { ISavePersonResponsibleReqDTO } from 'modules/logbook/services/dtos/request/ISavePersonResponsibleReqDTO'

import React from 'react'
import styled from 'styled-components'

const RAW_PRS_RS: ISavePersonResponsibleReqDTO = {
    personCode: undefined,
    quantity: undefined,
    paymentUnity: undefined
}
interface ILogbookCommonFieldsCPProps {
    formStateManager: IFormStateManager<LogbookFormValidator>
}

/**
 * COMPONENTE
 * Campos em comum de um formulario de 01 registro de diário de bordo
 */
export function LogbookCommonFieldsCP(props: ILogbookCommonFieldsCPProps): JSX.Element {
    const screenSize = useScreenSize()

    return (
        <WrapperSCP>
            <TwoColWrapperSCP>
                <InputCP
                    label={'Data'}
                    placeholder={'__/__/____'}
                    fieldName={'date'}
                    type={'date'}
                    formStateManager={props.formStateManager}
                    required={true}
                    mask={MaskUtils.applyDateMask}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    maxLength={10}
                    loading={false}
                />
            </TwoColWrapperSCP>

            <TextAreaCP
                label={'Observação'}
                fieldName={'notes'}
                formStateManager={props.formStateManager}
                required={false}
                fontSize={screenSize.smd ? 'large' : 'normal'}
                loading={false}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  
`
const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    @media(max-width: 768px){
        margin-bottom: 20px;
    }
`