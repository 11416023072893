import { ValidateIf, IsString, IsOptional } from 'class-validator'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { DateUtils } from 'common/utils/DateUtils'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { BenefitFormValidator } from 'modules/batch/components/batch-process-drawer/inner/BenefitFormValidator'
import { StorageFormValidator } from 'modules/batch/components/batch-process-drawer/inner/StorageFormValidator'
import { BatchProcessMethodEnum } from 'modules/batch/enums/BatchProcessMethodEnum'
import { BatchProcessTypeEnum } from 'modules/batch/enums/BatchProcessTypeEnum'
import { ISaveBatchProcessReqDTO } from 'modules/batch/services/dtos/requests/ISaveBatchProcessReqDTO'
export class BatchProcessFormValidator extends FormModelValidator {
    @IsNotEmpty()
    startDate: string

    @IsNotEmpty()
    type: BatchProcessTypeEnum

    @ValidateIf((obj) => obj.type === BatchProcessTypeEnum.POST_HARVEST)
    @IsNotEmpty()
    @IsString()
    method?: BatchProcessMethodEnum

    @IsOptional()
    @IsString()
    notes?: string

    @ValidateIf((obj) => obj.type === BatchProcessTypeEnum.BENEFIT)
    @IsNotEmpty()
    benefit?: BenefitFormValidator

    @ValidateIf((obj) => obj.type === BatchProcessTypeEnum.STORAGE)
    @IsNotEmpty()
    storage?: StorageFormValidator

    @ValidateIf((obj) => obj.type === BatchProcessTypeEnum.POST_HARVEST)
    @IsNotEmpty()
    structureCode?: number

    constructor(dto?: ISaveBatchProcessReqDTO) {
        super()
        if (dto) {
            this.startDate = DateUtils.getFormatted(dto.startDate, DateFormatEnum.BR_WITHOUT_TIME)
            this.type = dto.type
            this.notes = dto.notes
            this.method = dto.method
            this.benefit = dto.benefit ? new BenefitFormValidator(dto.benefit) : undefined
            this.storage = dto.storage ? new StorageFormValidator(dto.storage) : undefined
            this.structureCode = dto.structureCode
        }

    }
}
