import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { ISaveRecipeRequestDTO } from './dtos/requests/ISaveRecipeRequestDTO'
import { ISearchRecipeRequestDTO } from './dtos/requests/ISearchRecipeRequestDTO'

import { ISaveManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveManeuverRequestDTO'
import { ISearchManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchManeuverRequestDTO'
import { IConcludeManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/IConcludeManeuverRequestDTO'
import { IFormulaAplicationDataReqDTO } from './dtos/requests/IFormulaAplicationDataReqDTO'
import { IFormulaApplicationByFieldReqDTO } from './dtos/requests/IFormulaApplicationByFieldReqDTO'

export class ManeuverRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/maneuvers`
    private static readonly recipeApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/recipes`
    private constructor() { }

    static getRecipeList = (dto?: ISearchRecipeRequestDTO): RequestConfigTP<ISearchRecipeRequestDTO> => ({
        url: `${ManeuverRequests.recipeApiUrl}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getRecipe = (code: number): RequestConfigTP => ({
        url: `${ManeuverRequests.recipeApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static saveRecipe = (dto: ISaveRecipeRequestDTO): RequestConfigTP<ISaveRecipeRequestDTO> => ({
        url: `${ManeuverRequests.recipeApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updateRecipe = (code: number, dto: ISaveRecipeRequestDTO): RequestConfigTP<ISaveRecipeRequestDTO> => ({
        url: `${ManeuverRequests.recipeApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static deleteRecipe = (code: number): RequestConfigTP => ({
        url: `${ManeuverRequests.recipeApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static search = (dto: ISearchManeuverRequestDTO): RequestConfigTP<ISearchManeuverRequestDTO> => ({
        url: `${ManeuverRequests.baseApiUrl}/`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static save = (dto: ISaveManeuverRequestDTO): RequestConfigTP<ISaveManeuverRequestDTO> => ({
        url: `${ManeuverRequests.baseApiUrl}/`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static update = (code: number, dto: ISaveManeuverRequestDTO): RequestConfigTP<ISaveManeuverRequestDTO> => ({
        url: `${ManeuverRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static get = (code: number): RequestConfigTP => ({
        url: `${ManeuverRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${ManeuverRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static markAsConcluded = (code: number, dto: IConcludeManeuverRequestDTO): RequestConfigTP => ({
        url: `${ManeuverRequests.baseApiUrl}/${code}/mark-as-concluded`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static reopen = (code: number): RequestConfigTP => ({
        url: `${ManeuverRequests.baseApiUrl}/${code}/reopen`,
        method: HttpMethodEnum.PUT,
    })

    static getFormulaApplicationData = (dto: IFormulaApplicationByFieldReqDTO): RequestConfigTP => ({
        url: `${ManeuverRequests.recipeApiUrl}/formula-by-area`,
        method: HttpMethodEnum.GET,
        params: dto
    })

}
