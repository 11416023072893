import { ReportFiltersCP } from 'modules/report/components/report-filters/ReportFiltersCP'
import { ISearchReportReqDTO } from 'modules/report/services/dtos/requests/ISearchReportReqDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { ReportRequests } from 'modules/report/services/ReportRequests'
import { DuoWidget } from 'common/components/widgets/DuoWidgetCP'
import { FieldWidgetCP } from 'modules/field/components/field-widget/FieldWidgetCP'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSeedling, faRulerCombined } from '@fortawesome/free-solid-svg-icons'
import { TextCP } from 'common/components/text/TextCP'
import { IProductionReportResDTO } from 'modules/report/services/dtos/responses/IProductionReportResDTO'
import { ProductionReportByFieldTableCP } from 'modules/report/components/production-report-by-field-table/ProductionReportByFieldTableCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { ProductionReportDataTP } from 'modules/report/types/ProductionReportDataTP'

const DEFAULT_FILTERS: ISearchReportReqDTO = {
    season: moment().year(),

}

interface IProductionReportContentCPProps {

}

export function ProductionReportContentCP(props: IProductionReportContentCPProps): JSX.Element {
    const [filters, setFilters] = useState<ISearchReportReqDTO>(DEFAULT_FILTERS)
    const [report, setReport] = useState<ProductionReportDataTP>()
    const [reportByField, setReportByField] = useState<ProductionReportDataTP[]>()

    const getReportReq = useRequest<IProductionReportResDTO>()
    useEffect(getReportData, [filters])
    useEffect(onRequestChange, [getReportReq.awaiting])

    function getReportData(): void {
        if (!!filters && !!filters.propertyCode)
            getReportReq.runRequest(ReportRequests.getProductionReport(filters))
    }

    function onRequestChange(): void {
        if (getReportReq.awaiting || !getReportReq.tried)
            return

        if (!getReportReq.success || !getReportReq.returnData) {
            if (getReportReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getReportReq.returnData, getReportReq.error)
            return RequestUtils.showDefaultErrorNotification(getReportReq.error, 'Erro ao salvar análise!')
        }
        setReport(getReportReq.returnData.general)
        setReportByField(getReportReq.returnData.fields)
    }

    return (
        <WrapperSCP>
            <ReportFiltersCP
                filters={filters}
                onChangeFilters={setFilters}
                showFields={['season', 'property']}
            />
            <WrapperGridWidgetsSCP>
                <WrapperFieldWidgetsSCP>
                    <FieldWidgetCP
                        title={'Área total de produção'}
                        subtitle={`${reportByField?.length ?? '...'} talhões`}
                        value={`${MaskUtils.applyNumberMask(report?.usedArea ?? 0)} ha de ${MaskUtils.applyNumberMask(report?.totalArea ?? 0)} ha`}
                        percent={!!report && !!report.totalArea && !!report.usedArea ? (report.usedArea * 100) / report.totalArea : 0}
                        loading={getReportReq.awaiting}
                        icon={<FontAwesomeIcon icon={faRulerCombined} />}
                    />
                    <FieldWidgetCP
                        title={'Total de pés utilizados'}
                        subtitle={`${reportByField?.length ?? '...'} talhões`}
                        value={`${MaskUtils.applyNumberMask(report?.usedTrees ?? 0)}  de ${MaskUtils.applyNumberMask(report?.totalTrees ?? 0)} `}
                        percent={!!report && !!report.totalTrees && !!report.usedTrees ? (report.usedTrees * 100) / report.totalTrees : 0}
                        loading={getReportReq.awaiting}
                        icon={<FontAwesomeIcon icon={faSeedling} />}
                    />
                </WrapperFieldWidgetsSCP>
                <DuoWidget
                    dataWidget={
                        [
                            {
                                title: 'Total de litros',
                                value: !!report && !!report.totalLiters ? `${MaskUtils.applyNumberMask(report.totalLiters)} L` : '0'
                            },
                            {
                                title: 'Litros/pé de café',
                                value: !!report && !!report.litersPerTree ? `${MaskUtils.applyNumberMask(report.litersPerTree)} L` : '0'
                            },
                        ]
                    }
                />
                <DuoWidget
                    dataWidget={
                        [
                            {
                                title: 'Total de sacas',
                                value: !!report && !!report.totalBags ? `${MaskUtils.applyNumberMask(report.totalBags)} ` : '0'
                            },
                            {
                                title: 'Sacas/hectare',
                                value: !!report && !!report.bagsPerHectare ? `${MaskUtils.applyNumberMask(report.bagsPerHectare)}` : '0'
                            },
                        ]
                    }
                />
            </WrapperGridWidgetsSCP>
            <ReportTableSCP>
                <TextCP text={'Indicadores por talhão'} style={{ fontSize: '15px', fontWeight: 600 }} />

                <ProductionReportByFieldTableCP fields={reportByField} loading={getReportReq.awaiting} />
            </ReportTableSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    margin-bottom: 4em;
    max-height: 75vh;   
    overflow: overlay;
    padding-right: 4px;
    ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            background-color: #F5F5F5;
    }
    ::-webkit-scrollbar{
            width: 2px;
            border-radius: 1px;
           background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb{
            background-color:${props => props.theme.primaryColor};          
    }
    @media(max-width: 768px ) and (max-height: 600px ){
        max-height: 75vh;        
    }
    @media(max-width: 768px ) and (min-height: 600px ){
        max-height: 82vh;        
    }
`

const WrapperGridWidgetsSCP = styled.div`
    row-gap: 20px;
    margin-top: 20px;
    column-gap: 20px;
    display: grid;
    @media(min-width: 1280px ){
        grid-template-columns: 1.5fr 1fr 1fr;
        display: grid;       
    }
`

const WrapperFieldWidgetsSCP = styled.div`
    display: grid;
    row-gap: 20px;
`

const ReportTableSCP = styled.div`
    margin: 30px 0;
`
