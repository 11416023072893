import { TextCP } from 'common/components/text/TextCP'
import { BatchLandingPageRoutes } from 'modules/batch-landing-page/BatchLandingPageRoutes'

import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'

import React from 'react'

import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'

interface IBatchTreeResumeCardICP2Props {
    batch: IBatchResponseDTO
    isMainBatch: boolean
}

export function BatchTreeResumeCardICP2(props: IBatchTreeResumeCardICP2Props): JSX.Element {

    return (
        <WrapperSCP onClick={() => window.open(`${BatchLandingPageRoutes.ROOT}/${props.batch.code}`, 'Batch Details')}>
            <GridSCP>
                <HighLightSCP>
                    #
                    {props.batch.code}
                </HighLightSCP>

                <InfoWrapperSCP>
                    <CategoryTitleSCP>
                        {props.batch.fields.map(f => f.name).join(', ')}
                        {' '}
                        (
                        {props.batch.season}
                        )
                    </CategoryTitleSCP>
                    {
                        !!props.batch.method ?
                            <TextCP text={`Method: ${BatchUtils.translateBatchMethodToEnglish(props.batch.method)}`} />
                            :
                            <TextCP text={`Origin: ${BatchUtils.translateOriginToEnglish(props.batch.origin)}`} />
                    }
                    {
                        !!props.batch.averageScore &&
                        <RowSCP>
                            <TextCP text={'Score:\u00A0'} style={{ fontWeight: '500 ' }} />
                            <TextCP text={`${props.batch.averageScore}`} />
                        </RowSCP>
                    }
                    <RowSCP>
                        <TextCP text={'Liters:\u00A0'} style={{ fontWeight: '500 ' }} />
                        <TextCP text={`${props.batch.liters}`} />
                    </RowSCP>
                    {
                        props.batch.bags &&
                        <RowSCP>
                            <TextCP text={'Bags:\u00A0'} style={{ fontWeight: '500 ' }} />
                            <TextCP text={`${props.batch.bags}`} />
                        </RowSCP>
                    }
                    {
                        !props.isMainBatch &&
                        <TextCP
                            text={'See this batch page'}
                            onClick={() => window.open(`${BatchLandingPageRoutes.ROOT}/${props.batch.code}`, 'Batch Details')}
                            style={
                                {
                                    fontSize: '11px',
                                    marginTop: '10px',
                                    color: `${CostumizedThemeConfig.primaryColor}`,
                                    cursor: 'pointer',
                                    fontWeight: '600',
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                }
                            }
                        />
                    }

                </InfoWrapperSCP>
            </GridSCP>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    padding-top: 6px;
    cursor: pointer;
    @media(max-width: 768px){
        padding-bottom: 10px;
    }
`

const GridSCP = styled.div`
    display: grid;
    grid-template-columns: 60px 1fr;
    column-gap: 5px;
    margin: 10px 10px 0 10px;
    align-items: center;
    position: relative;
    width: 100%;
    @media(max-width: 768px){
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
`

const HighLightSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    svg{
        font-size: 20px;
    }
`
const InfoWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    .ant-typography{
    font-size: 12px;
    }
`

const CategoryTitleSCP = styled.div`
    font-size: 12px;
    color: ${props => props.theme.primaryColor};
    line-height: 15px;
    font-weight: 600;
`

const ContentInfoSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    margin: 5px 0;
    width: 100%;
    .ant-typography{
    font-size: 12px;
    }
    @media(max-width: 768px){
    grid-template-columns: 1fr;
}
`

const RowSCP = styled.div`
display: flex;

`