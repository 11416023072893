import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'

import { IBatchProcessResDTO } from 'modules/batch/services/dtos/responses/IBatchProcessResDTO'
import { BatchUtils } from 'modules/batch/utils/BatchUtils'
import { LogbookUtils } from 'modules/logbook/utils/LogbookUtils'

interface ILastProcessPreviewICPProps {
    item?: IBatchProcessResDTO
}

export function LastProcessPreviewICP(props: ILastProcessPreviewICPProps): JSX.Element {

    if (!props.item) {
        return (
            <EmptyContentSCP>
                Ainda não há processos
            </EmptyContentSCP>
        )
    }
    return (
        <WrapperSCP>
            <HighLightSCP>
                {BatchUtils.getProcessTypeInitials(props.item.type)}
            </HighLightSCP>
            <InfoWrapperSCP>
                <ResumeSCP>
                    <TextCP
                        text={`${LogbookUtils.getDateText(props.item.startDate)} ${props.item.endDate ? ` - ${LogbookUtils.getDateText(props.item.endDate)}` : ''}- `}
                        style={{ fontWeight: 500 }}
                    />
                    {
                        !!props.item.type &&
                        <TextCP
                            text={`${BatchUtils.translateProcessType(props.item.type)}`}
                        />
                    }
                    {
                        !!props.item.method &&
                        <TextCP
                            text={`Processamento: ${BatchUtils.translateProcessMethod(props.item.method)}`}
                        />
                    }
                    {
                        !!props.item.structure &&
                        <TextCP
                            text={`Estrutura: ${props.item.structure.name}`}
                        />
                    }

                    {
                        !!props.item.methodOther &&
                        <TextCP
                            text={`Outro: ${props.item.methodOther}`}
                        />
                    }
                    {
                        !!props.item.benefit &&
                        <RowSCP>
                            <TextCP
                                text={`Bica 1: ${props.item.benefit.spoutOne ?? 0} sacas`}
                            />
                            <TextCP
                                text={`Bica 1: ${props.item.benefit.spoutOne ?? 0} sacas`}
                            />
                        </RowSCP>

                    }
                    {
                        !!props.item.storage &&
                        <RowSCP>
                            <TextCP
                                text={`Tipo: ${BatchUtils.translateStorageType(props.item.storage.type)}`}
                            />
                            <TextCP
                                text={`Local: ${BatchUtils.translateStoragePlace(props.item.storage.place)}`}
                            />
                        </RowSCP>
                    }
                    {
                        !!props.item.lastLogbook && props.item.lastLogbook.humidity &&
                        <TextCP
                            text={`Umidade: ${props.item.lastLogbook.humidity}%`}
                        />
                    }
                    {
                        !!props.item.lastLogbook && props.item.lastLogbook.averageTemperature &&
                        <TextCP
                            text={`Temperatura: ${props.item.lastLogbook.averageTemperature} °C`}
                        />
                    }
                </ResumeSCP>

            </InfoWrapperSCP>
        </WrapperSCP>
    )
}

const EmptyContentSCP = styled.div`
    background: ${props => props.theme.lightGray};
    min-height: 60px;
    margin: 8px 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const InfoWrapperSCP = styled.div`
    display: flex;
   flex-direction: column;

`
const HighLightSCP = styled.div`
display: flex;
justify-content: center;
align-items: center;
background: ${props => props.theme.primaryColor};
color: #fff;
border-radius: 100%;
height: 30px;
width: 30px;

`

const ResumeSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-typography{
        white-space: pre-wrap;
        font-size: 12px;
    }
`

const WrapperSCP = styled.div`
    margin: 8px 0;
    display: grid;
    grid-template-columns: 40px 200px;
    column-gap: 8px;
    background: #f1f1f1;   
    border-radius: 5px;
    min-height: 60px;
    align-items: center;
    padding: 10px;
`

const RowSCP = styled.div`
    display: flex;
    width: 100%;   

`
