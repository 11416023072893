import { Alert, Col, Form, Input, Row } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import React, { useEffect, useRef, useState } from 'react'
import { CepInput } from '../../../../../common/components/form-inputs/CepInput'

interface IProps {
    onNext: (values?: any) => void
    onPrev: (values?: any) => void
    values?: any
}

export function RegistrationAddressDataStep(props: IProps): JSX.Element {
    const [form] = Form.useForm()
    const [zipCodeSearch, setZipCodeSearch] = useState<{ loading: boolean, data: any } | null>(null)
    const [emptyAddress, setEmptyAddress] = useState(false)
    const hasValue = useRef(false)

    useEffect(() => {
        hasValue.current = !!props.values?.zipCode
    }, [])

    useEffect(() => {
        if (!zipCodeSearch || zipCodeSearch.loading || !zipCodeSearch.data)
            return

        if (hasValue.current) {
            hasValue.current = false
            return
        }

        if (Object.values(zipCodeSearch.data).length > 0) {
            setEmptyAddress(false)
            form.setFieldsValue({
                street: zipCodeSearch.data.street,
                neighborhood: zipCodeSearch.data.neighborhood,
                city: zipCodeSearch.data.city,
                state: zipCodeSearch.data.state
            })
        } else
            setEmptyAddress(true)
    }, [zipCodeSearch])

    return (
        <Form
            form={form}
            layout={'vertical'}
            initialValues={props.values}
            name={'otherData'}
            onFinish={(values) => {
                props.onNext(values)
            }}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name)
            }}
        >
            <Row gutter={[8, 8]}>
                <Col md={4} sm={24}>
                    <Form.Item
                        label={'CEP'}
                        name={'zipCode'}
                        rules={[{ required: true, message: 'Campo Obrigatório' }]}
                        hasFeedback={emptyAddress}
                        validateStatus={emptyAddress ? 'warning' : undefined}
                    >
                        <CepInput
                            disabled={zipCodeSearch?.loading}
                            onSearchZipCode={setZipCodeSearch}
                        />
                    </Form.Item>
                </Col>
                {
                    emptyAddress ?
                        <Col md={18} sm={24}>
                            <Alert
                                message={'Endereço não encontrado. Digite outro CEP ou insira os dados manualmente'}
                                type={'warning'}
                                closable={true}
                                onClose={() => setEmptyAddress(false)}
                            />
                        </Col>
                        : <div />
                }
            </Row>
            <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={16}>
                    <Form.Item
                        label={'Rua'}
                        name={'street'}
                        rules={[{ required: true, message: 'Campo Obrigatório' }]}
                    >
                        <Input disabled={zipCodeSearch?.loading} />
                    </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={4}>
                    <Form.Item
                        label={'Nº'}
                        name={'number'}
                        rules={[{ required: true, message: 'Campo Obrigatório' }]}
                    >
                        <Input disabled={zipCodeSearch?.loading} />
                    </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={4}>
                    <Form.Item
                        label={'Compl.'}
                        name={'complement'}
                    >
                        <Input disabled={zipCodeSearch?.loading} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={10}>
                    <Form.Item
                        label={'Bairro'}
                        name={'neighborhood'}
                        rules={[{ required: true, message: 'Campo Obrigatório' }]}
                    >
                        <Input disabled={zipCodeSearch?.loading} />
                    </Form.Item>
                </Col>
                <Col xs={18} sm={18} md={10}>
                    <Form.Item
                        label={'Cidade'}
                        name={'city'}
                        rules={[{ required: true, message: 'Campo Obrigatório' }]}
                    >
                        <Input disabled={zipCodeSearch?.loading} />
                    </Form.Item>
                </Col>
                <Col xs={6} sm={6} md={4}>
                    <Form.Item
                        label={'UF'}
                        name={'state'}
                        rules={[{ required: true, message: 'Campo Obrigatório' }]}
                    >
                        <Input disabled={zipCodeSearch?.loading} maxLength={2} />
                    </Form.Item>
                </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonCP type={'primary'} ghost={true} onClick={props.onPrev} marginRight={5}>
                    Voltar
                </ButtonCP>
                <ButtonCP type={'primary'} isSubmit={true}>
                    Próximo
                </ButtonCP>
            </div>
        </Form>
    )
}