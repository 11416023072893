import { Form, Input } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import React from 'react'

import { PhoneInput } from '../../../../../common/components/form-inputs/PhoneInput'

interface IProps {
    onNext: (values?: any) => void
    onPrev: (values?: any) => void
    values?: any
}

export function RegistrationBasicDataStep(props: IProps): JSX.Element {
    const [form] = Form.useForm()

    return (
        <Form
            form={form}
            layout={'vertical'}
            initialValues={props.values}
            name={'basicData'}
            onFinish={(values) => {
                props.onNext(values)
            }}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name)
            }}
        >
            <Form.Item
                label={props.values.legal ? 'Razão Social' : 'Nome'}
                name={'name'}
                rules={[{ required: true, message: 'Campo Obrigatório' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={'Email'}
                name={'email'}
                validateFirst={true}
                rules={[{ required: true, message: 'Campo Obrigatório' }, { type: 'email', message: 'E-mail inválido' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={'Celular'}
                name={'phone'}
                validateFirst={true}
                rules={[{ required: true, message: 'Campo Obrigatório' }, {
                    validator: async (rule, value) => {
                        if (!/\d{2}9\d{4}\d{4}/.test(value))
                            return Promise.reject('Telefone Inválido')
                        return Promise.resolve()
                    },
                }]}
            >
                <PhoneInput />
            </Form.Item>
            <Form.Item
                label={'Inscrição Estadual'}
                name={'stateRegistration'}
                rules={[{ required: true, message: 'Campo Obrigatório' }]}
            >
                <Input />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonCP type={'primary'} ghost={true} onClick={props.onPrev} marginRight={5}>
                    Voltar
                </ButtonCP>
                <ButtonCP type={'primary'} isSubmit={true}>
                    Próximo
                </ButtonCP>
            </div>
        </Form>
    )
}