import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsString } from 'common/validation/decorators/IsString'
import { StructureTypeEnum } from 'modules/batch/enums/StructureTypeEnum'

import { ISaveStructureReqDTO } from 'modules/structure/services/dtos/request/ISaveStructureReqDTO'

export class StructureFormValidator extends FormModelValidator {
    @IsNotEmpty()
    @IsString()
    name: string

    @IsNotEmpty()
    @IsString()
    type: StructureTypeEnum

    // @IsNotEmpty()
    // capacity: number

    // @IsNotEmpty()
    // @IsString()
    // unitOfMeasurement: UnitOfMeasurementEnum

    constructor(dto?: ISaveStructureReqDTO) {
        super(dto)
        Object.assign(this, dto)

    }
}
