import { OrderingCriteriaEnum } from 'common/enums/OrderingCriteriaEnum'

export const OrderingUtils = {
    translateOrderingCriteria(criteria: OrderingCriteriaEnum): string {
        switch (criteria) {
            case OrderingCriteriaEnum.DATE:
                return 'Data de início'
            case OrderingCriteriaEnum.CODE:
                return 'Código'
            case OrderingCriteriaEnum.RECENTLY_UPDATED:
                return 'Recentemente atualizados'
            default:
                return ''
        }
    }
}