import { PhotoGalleryCP } from 'common/components/custom-photo-gallery/PhotoGalleryCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'

import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { PropertyRequests } from 'modules/property/services/PropertyRequests'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

export function PropertyPhotoGalleryICP(): JSX.Element {
    const property = useSelector((state: IReduxState) => state.property)
    const request = useRequest<string[]>()

    useEffect(searchDocuments, [property])
    useEffect(onRequestChange, [request.awaiting])

    const deleteImageReq = useRequest()
    useEffect(onDeleteImageReqChange, [deleteImageReq.awaiting])

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar fotos!')
        }
    }

    function searchDocuments(): void {
        if (!property)
            return

        request.runRequest(PropertyRequests.searchPictures(property.code))
    }

    function removeImage(url: string): void {
        deleteImageReq.runRequest(PropertyRequests.removeImages(property.code, { files: [url] }))
    }

    function onDeleteImageReqChange(): void {
        if (deleteImageReq.awaiting || !deleteImageReq.tried)
            return

        if (!deleteImageReq.success || !deleteImageReq.returnData) {
            if (deleteImageReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteImageReq.returnData, deleteImageReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteImageReq.error, 'Erro ao excluir imagem!')
        }
        searchDocuments()
    }

    if (!!request.returnData && !request.returnData.length)
        return <WrapperSCP><EmptyCP description={'Você ainda não tem imagens cadastrados'} /></WrapperSCP>


    return (
        <PhotoGalleryCP
            images={!!request.returnData && request.returnData ?
                request.returnData.map(pic => ({
                    url: pic,
                    onDelete: () => removeImage(pic),
                    previewConfig: {
                        mask: <MaskWrapperSCP />
                    }
                }))
                :
                []}
        />
    )
}

const MaskWrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    opacity: .5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
`
const WrapperSCP = styled.div`
    .ant-empty{
        padding-bottom: 20em;
    }

`