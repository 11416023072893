import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { IFieldResponseDTO } from 'modules/field/services/dtos/responses/IFieldResponseDTO'
import { FieldRequests } from 'modules/field/services/FieldRequests'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FieldCardICP } from 'modules/field/components/field-list/inner/FieldCardICP'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'

interface IFieldListCPProps {
    reload?: number
    editField: (field: IFieldResponseDTO) => void
}

export function FieldListCP(props: IFieldListCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [fields, setFields] = useState<IFieldResponseDTO[]>()
    const request = useRequest<IGenericListResponseDTO<IFieldResponseDTO>>()
    const property = useSelector((state: IReduxState) => state.property)

    useEffect(searchFields, [property, props.reload])
    useEffect(onRequestChange, [request.awaiting])

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar talhões!')
        }

        setFields(request.returnData.list)
    }

    function searchFields(): void {
        if (!property)
            return

        request.runRequest(FieldRequests.search(property.code))
    }

    const loading = request.awaiting || !fields

    return (
        <ListWrapper>
            {!screenSize.xs && !!fields?.length &&
                <ListHeaderSCP>

                    <TextCP text={'Nome'} size={'small'} />
                    <TextCP text={'N. de pés'} size={'small'} style={{ justifyContent: 'center' }} />
                    <TextCP text={'Área total'} size={'small'} style={{ justifyContent: 'center' }} />
                    <TextCP text={'Altitude min.'} size={'small'} style={{ justifyContent: 'center' }} />
                    <TextCP text={'Altitude max.'} size={'small'} style={{ justifyContent: 'center' }} />
                    <TextCP text={'Espaçamento entre pés'} size={'small'} style={{ justifyContent: 'center' }} />
                    <TextCP text={'Espaçamento entre ruas'} size={'small'} style={{ justifyContent: 'center' }} />
                </ListHeaderSCP>}
            <ListWrapperSCP>
                <ListCP<IFieldResponseDTO>
                    loading={loading}
                    itemLayout={'vertical'}
                    size={'large'}
                    loadMoreData={() => { }} dataSource={fields}
                    locale={{
                        emptyText: <EmptyCP description={'Você ainda não tem talhões cadastrados'} />
                    }}
                    renderItem={(item: IFieldResponseDTO) => (<FieldCardICP field={item} reloadList={searchFields} loading={loading} />)}
                />
            </ListWrapperSCP>
        </ListWrapper>
    )
}

const ListWrapper = styled.div`
    .ant-list {
        overflow: hidden;
    }

    .ant-list-item{
        box-shadow: -5px 10px 16px -4px rgba(0,0,0,0.21);
        position: relative;
        &:before{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            border-radius: 0 5px 5px 0;
            background: ${props => props.theme.primaryColor}
        }
    }
`
const ListHeaderSCP = styled.div`
    .ant-typography{
        text-transform: uppercase;
        white-space: pre-wrap;
        text-align: center;
    }
    display: grid;
    grid-template-columns: 2.3fr .8fr .8fr .8fr .8fr 1fr 1fr 1fr;
    column-gap: 5px;
    padding: 16px 24px;

`

const ListWrapperSCP = styled.div`
    margin-bottom: 10em;
`
