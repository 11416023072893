import { faFlag, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { CompanyDrawerCP } from 'modules/company/components/company-drawer/CompanyDrawerCP'
import { CompanyListCP } from 'modules/company/components/company-list/CompanyListCP'
import React, { useState } from 'react'
import styled from 'styled-components'

interface ICompanyScreenProps {
}

export function CompanyScreen(props: ICompanyScreenProps): JSX.Element {
    const [showDrawer, setShowDrawer] = useState<boolean>(false)

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Empresas'} icon={<FontAwesomeIcon icon={faFlag} />}>
                    <ButtonCP
                        style={{ border: 'none' }}
                        size={'middle'}
                        type={'ghost'}
                        icon={<FontAwsomeIconCP icon={faPlus} />}
                        onClick={() => {
                            setShowDrawer(true)
                        }}
                    >
                        Nova empresa
                    </ButtonCP>
                </HeaderCP>
            }
            content={
                <ContentCP>
                    <CompanyListCP />
                    <CompanyDrawerCP
                        show={showDrawer}
                        onSuccess={() => {
                            setShowDrawer(false)

                        }}
                        onCancel={() => {
                            setShowDrawer(false)
                        }}
                    />

                </ContentCP>
            }
        />
    )
}

const WrapperSCP = styled.div`
`
