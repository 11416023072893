import Menu from 'antd/lib/menu'
import { MenuProps } from 'antd/lib/menu/'
import styled from 'styled-components'

interface IMenuCPProps extends MenuProps {
    width?: number
}

/**
 * Renderiza menu.
 *
 * @todo: Remover warnings gerados por este arquivo
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */

export const MenuCP = styled(Menu) <IMenuCPProps>`
    &.ant-menu {
        ${props => (props.width ? `width: ${props.width}px` : '')};
        background: none;
        border-right: 0;
    }
    /* Necessário para retirar padding com !important no colocado pelo ant design  */
    &.ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
    &.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item,
    .ant-menu-vertical-right .ant-menu-item,
    .ant-menu-vertical .ant-menu-submenu-title,
    .ant-menu-vertical-left .ant-menu-submenu-title,
    .ant-menu-vertical-right .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-submenu-title {
        margin: 10px 0;
    }
    .ant-menu-light .ant-menu-item-active{
        color:  ${props => props.theme.primaryColor} 
    }
    .ant-menu-inline .ant-menu-submenu-title{
        display: grid;
        grid-template-columns: 40px 200px;
        text-overflow: initial;
        column-gap: 12px;
        align-items: center;
    }

    &.ant-menu-inline .ant-menu-item{
        display: grid;
        grid-template-columns: 40px 200px;
        text-overflow: initial;
        column-gap: 12px;
        align-items: center;

        padding-left: 25px !important;
        margin: 0px !important;
        font-size: 12px;
    }


    &.ant-menu-inline-collapsed .ant-menu-item, &.ant-menu-vertical .ant-menu-submenu-title {
        padding: 4px !important;
        text-align: center;
        justify-content: center;

        > span, > span > svg {
            margin: 0 !important;
        }
    }

    &.ant-menu-inline-collapsed .ant-menu-item .anticon + span {
        display: inline-block;
        max-width: 0;
        opacity: 0;
    }
`
