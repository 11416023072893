import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import React, { useEffect, useState } from 'react'
import { BatchRequests } from '../../services/BatchRequests'
import { IBatchResponseDTO } from 'modules/batch/services/dtos/responses/IBatchResponseDTO'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { SplitValueFieldICP } from 'modules/batch/components/split-batch-drawer/inner/SplitValueFieldICP'
import styled from 'styled-components'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import moment from 'moment-timezone'
import { TextCP } from 'common/components/text/TextCP'
import _ from 'lodash'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DateUtils } from 'common/utils/DateUtils'
import { AlertCP } from 'common/components/alert/AlertCP'
import { ISplitBatchRequestDTO } from 'modules/batch/services/dtos/requests/ISplitBatchRequestDTO'
import { ISplitDataReqDTO } from 'modules/batch/services/dtos/requests/ISplitDataReqDTO'
import { BatchTreeResumeCardICP } from 'modules/batch/components/batch-tree-drawer/inner/BatchTreeResumeCardICP'

interface ISplitBatchDrawerCPProps {
    show: boolean
    batchCode: number
    onCancel: () => void
    onSuccess: (newBatchesCodes: number[]) => void
}

export function SplitBatchDrawerCP(props: ISplitBatchDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()

    const [batchData, setBatchData] = useState<IBatchResponseDTO>()
    const [splitValues, setSplitValues] = useState<ISplitDataReqDTO[]>([])

    const [season, setSeason] = useState<number>(moment().year())
    const [date, setDate] = useState<string>(DateUtils.transformDateStrFormat(moment().format('YYYY-MM-DD'), DateFormatEnum.EUA_WITHOUT_TIME, DateFormatEnum.BR_WITHOUT_TIME))
    const [alertText, setAlertText] = useState<string>()
    const [lostLiters, setLostLiters] = useState<number>(0)
    const [isAfterBenefit, setIsAfterBenefit] = useState<boolean>(false)

    useEffect(getBatch, [props.batchCode, props.show])

    const getBatchReq = useRequest<IBatchResponseDTO>()
    useEffect(onGetBatchReqChange, [getBatchReq.awaiting])

    const splitReq = useRequest<{ codes: number[] }>()
    useEffect(onSplitReqChange, [splitReq.awaiting])

    useEffect(defineInitialSplit, [batchData])
    useEffect(calculateLostLiters, [splitValues])

    function getBatch(): void {
        if (!props.batchCode || !props.show)
            return
        getBatchReq.runRequest(BatchRequests.get(props.batchCode))
    }

    function onGetBatchReqChange(): void {
        if (getBatchReq.awaiting || !getBatchReq.tried)
            return

        if (!getBatchReq.success || !getBatchReq.returnData) {
            if (getBatchReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getBatchReq.returnData, getBatchReq.error)
            return RequestUtils.showDefaultErrorNotification(getBatchReq.error, 'Erro ao buscar lote!')
        }
        setBatchData(getBatchReq.returnData)
        setIsAfterBenefit(!!getBatchReq.returnData.bags)
    }

    function onSplitReqChange(): void {
        if (splitReq.awaiting || !splitReq.tried)
            return

        if (!splitReq.success || !splitReq.returnData) {
            if (splitReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', splitReq.returnData, splitReq.error)
            return RequestUtils.showDefaultErrorNotification(splitReq.error, 'Erro ao buscar lote!')
        }
        props.onSuccess(splitReq.returnData.codes)
    }

    function calculateLostLiters(): void {
        if (!batchData || _.isEmpty(splitValues))
            return

        const total = batchData.liters
        const used = splitValues.map(value => +value.litersOrBags).reduce((v1, v2) => v1 + v2)

        setLostLiters(total - used)
    }

    // valores iniciados com metade do lote
    function defineInitialSplit(): void {
        if (!batchData)
            return

        if (!!batchData.availableBags && batchData.availableBags !== batchData.bags)
            setAlertText('Você não pode dividir lotes que já possuem sacas vendidas!')

        const value = isAfterBenefit ? batchData.bags : batchData.liters
        const litersOrBags = value / 2

        setSplitValues([{ litersOrBags }, { litersOrBags }])
    }

    function onFormSubmit(): void {

        const dto: ISplitBatchRequestDTO = {
            date: DateUtils.getDateFormattedToApi(date),
            season,
            newBatches: splitValues
        }

        splitReq.runRequest(BatchRequests.splitBatch(props.batchCode, dto))
    }

    function onAddItem(): void {
        setSplitValues([...splitValues, { litersOrBags: 0 }])
    }

    function onRemoveItem(index: number): void {
        setSplitValues(splitValues.filter((v, i) => i !== index))
    }

    return (
        <DrawerCP
            title={`Dividir lote #${props.batchCode}`}
            visible={props.show}
            onClose={() => {
                props.onCancel()
                setSplitValues([])
            }}
            width={'35%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        wrapperStyle={{ width: '100%' }}
                        onClick={onFormSubmit}
                        disabled={!!batchData && !!batchData.availableBags && batchData.availableBags !== batchData.bags}
                        loading={splitReq.awaiting}
                    >
                        Salvar divisão
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >

            {
                !!batchData ?
                    <FormWrapperSCP>
                        <RowSCP>
                            <TextCP
                                text={'Lote a dividir:'}
                                style={{
                                    fontSize: '12px',
                                    fontWeight: 600,
                                }}
                            />
                        </RowSCP>
                        <CardWrapperSCP>
                            <BatchTreeResumeCardICP
                                batch={batchData}
                                isMainBatch={true}
                            />
                        </CardWrapperSCP>

                        <TwoColWrapperSCP>
                            <SeasonSelectorCP
                                label={'Safra:'}
                                required={true}
                                value={season}
                                onChange={setSeason}
                            />
                            <InputCP
                                label={'Data:'}
                                type={'date'}
                                required={true}
                                onChange={setDate}
                                value={date}
                                placeholder={'Data'}
                                loading={splitReq.awaiting}
                                mask={MaskUtils.applyDateMask}
                                fontSize={'normal'}
                            />
                        </TwoColWrapperSCP>

                        {
                            !!alertText &&
                            <AlertCP
                                type={'error'}
                                message={alertText}
                            />
                        }
                        {
                            !!splitValues.length &&
                            splitValues.map((data, index) => {
                                return (
                                    <SplitValueFieldICP
                                        key={`${index}`}
                                        index={index}
                                        mainBatchData={batchData}
                                        loading={splitReq.awaiting}
                                        splitData={data}
                                        setSplitValues={setSplitValues}
                                        splitValues={splitValues}
                                        setAlertText={setAlertText}
                                        onRemoveItem={() => onRemoveItem(index)}
                                        disableRemove={splitValues.length <= 2}
                                        isAfterBenefit={isAfterBenefit}
                                        disableFields={!!batchData.availableBags && batchData.availableBags !== batchData.bags}
                                    />
                                )
                            })

                        }
                        <ButtonWrapperSCP>
                            <ButtonCP
                                size={screenSize.smd ? 'large' : 'middle'}
                                type={'primary'}
                                wrapperStyle={{ width: '100%' }}
                                onClick={onAddItem}
                                loading={splitReq.awaiting}
                            >
                                Adicionar +
                            </ButtonCP>
                        </ButtonWrapperSCP>
                        {
                            !isAfterBenefit &&
                            <RowSCP>
                                <TextCP text={'Litros perdidos na secagem:\u00A0'} style={{ fontWeight: 500 }} />
                                <TextCP text={`${lostLiters} litros`} />
                            </RowSCP>
                        }
                    </FormWrapperSCP>
                    :
                    <GenericRowSkeletonICP rows={5} columns={2} />
            }
        </DrawerCP>
    )
}

const FormWrapperSCP = styled.div`
`

const CardWrapperSCP = styled.div`
      margin: 10px 0;
    border: 1px solid ${props => props.theme.primaryColor};
    border-radius: 8px;
    align-items: center;
    padding-bottom: 10px;
`
const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
                            width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
                            font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const RowSCP = styled.div`
     display: flex;
    align-items: center;
    margin: 10px 0;

`
const TwoColWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin: 15px 0;
`