import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'

interface ISliderCarouselCPProps {
    dots?: boolean
    infinite?: boolean
    speed?: number
    draggable?: boolean
    slidesToShow: number
    slidesToScroll?: number
    children: JSX.Element[]
    centerMode?: boolean
    centerPadding?: string
    responsive?: any
}

export function SliderCarouselCP(props: ISliderCarouselCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <Slider
                dots={props.dots}
                draggable={props.draggable}
                infinite={props.infinite}
                speed={props.speed}
                slidesToShow={props.slidesToShow}
                slidesToScroll={props.slidesToScroll}
                dotsClass={'slick-dots fl-dots'}
                centerMode={!!props.centerMode}
                responsive={props.responsive ?? [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]}
            >
                {props.children}

            </Slider>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    .fl-dots{
        bottom: -55px;
        left: 12px;      
        text-align: left;
        li button:before{
            font-size: 12px;
            color: ${props => props.theme.primaryColor};
        }
    }

    .slick-next:before, .slick-prev:before {
        font-size: 31px;      
        color: ${props => props.theme.primaryColor};
    }
`
