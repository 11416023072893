import styled from 'styled-components'
import React from 'react'
import { TextCP } from 'common/components/text/TextCP'
import { Row } from 'antd'
import { ISearchProductRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchProductRequestDTO'
import { ProductFilterFieldsICP } from 'modules/maneuver/components/product-list-filters/inner/ProductFilterFieldsICP'

interface IProductListFiltersCPProps {
    setFilters: (filters: ISearchProductRequestDTO) => void
    filters?: ISearchProductRequestDTO
}

export function ProductListFiltersCP(props: IProductListFiltersCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <Row>
                <TextCP text={'Filtre por:'} />
            </Row>
            <FiltersWrpSCP>
                <ProductFilterFieldsICP
                    setFilters={props.setFilters}
                    filters={props.filters}
                />
            </FiltersWrpSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    background: white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
    .ant-typography{
        font-size: 12px;   
    }

`

const GridWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: .8fr 1.5fr 1.8fr 3fr;
    column-gap: 10px;
    margin-top: 10px;
`

const FiltersWrpSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr .4fr;
    align-items: center;
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`