/* eslint-disable @typescript-eslint/no-floating-promises */
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Skeleton } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { SwitchCP } from 'common/components/switch/SwitchCP'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { NotificationHelper } from 'common/NotificationHelper'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { MaskUtils } from 'common/utils/MaskUtils'
import IMask from 'imask'
import { ProductSelectorCP } from 'modules/maneuver/components/product-selector/ProductSelectorCP'
import { ImplementTypeEnum } from 'modules/maneuver/enums/ImplementTypeEnum'
import { ProductTypeEnum } from 'modules/maneuver/enums/ProductTypeEnum'
import { ISaveImplementRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveImplementRequestDTO'
import { ISearchImplementRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchImplementRequestDTO'
import { ImplementRequests } from 'modules/maneuver/services/ImplementRequests'
import { ManeuverUtils } from 'modules/maneuver/utils/ManeuverUtils'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IImplementResponseDTO } from '../../services/dtos/responses/IImplementResponseDTO'
import { ImplementFormValidator } from './inner/ImplementFormValidator'

interface IImplementDrawerCPProps {
    type: ImplementTypeEnum
    show: boolean
    implementCode?: number
    onCancel: () => void
    onSuccess: () => void
}

export function ImplementDrawerCP(props: IImplementDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [fuelFieldVisible, setFuelFieldVisible] = useState<boolean>(false)

    const [formValidator, setFormValidator] = useState<ImplementFormValidator>(new ImplementFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const request = useRequest<IImplementResponseDTO, ISaveImplementRequestDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const getImplementRequest = useRequest<IImplementResponseDTO, ISearchImplementRequestDTO>()
    useEffect(onFindOneReqChange, [getImplementRequest.awaiting])

    useEffect(onUpdateOne, [props.implementCode, props.show])
    useEffect(defineType, [props.type, props.show])

    function onUpdateOne(): void {

        if (!!props.implementCode && props.show) {
            getImplementRequest.runRequest(ImplementRequests.getImplement(props.implementCode))
            return
        }
        formStateManager.changeFieldValue('type', props.type)

    }

    function defineType(): void {
        if (!!props.type)
            formStateManager.changeFieldValue('type', props.type)
    }

    function onFindOneReqChange(): void {
        if (getImplementRequest.awaiting || !getImplementRequest.tried)
            return

        if (!getImplementRequest.success || !getImplementRequest.returnData) {
            if (getImplementRequest.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getImplementRequest.returnData, getImplementRequest.error)
            return RequestUtils.showDefaultErrorNotification(getImplementRequest.error, `Erro ao buscar ${ManeuverUtils.translateImplementType(props.type)}!`)
        }

        setFormValidator(new ImplementFormValidator(getImplementRequest.returnData))

        if (!!getImplementRequest.returnData.fuelCode)
            setFuelFieldVisible(true)

    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, `Erro ao salvar ${ManeuverUtils.translateImplementType(props.type)}!`)
        }

        NotificationHelper.success(`${ManeuverUtils.translateImplementType(props.type)} cadastrado com sucesso! `)
        formStateManager.reset()
        props.onSuccess()
        setFuelFieldVisible(false)
    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.debugErrors()
        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return

        let dto: ISaveImplementRequestDTO = {
            type: props.type,
            brand: formValues.brand,
            model: formValues.model,
            year: +formValues.year,
            purchaseYear: +formValues.purchaseYear,
            fuelCode: formValues.fuelCode,
            paidValue: +(formValues.paidValue.replace(',', '.').replace(/[^\d.-]/g, '')),
            lastRevisionDate: moment(formValues.lastRevisionDate, 'DD/MM/YYYY').toISOString(),
        }

        if (dto.type === ImplementTypeEnum.EQUIPMENT) {
            dto = {
                ...dto,
                capacity: +formValues.capacity,
                solidCapacity: +formValues.solidCapacity
            }
        } else {
            dto = {
                ...dto,
                lastRevisionHourMeter: +formValues.lastRevisionHourMeter,
                currentHourMeter: +formValues.currentHourMeter,
                purchaseHourMeter: +formValues.purchaseHourMeter,
            }
        }

        request.runRequest(props.implementCode ?
            ImplementRequests.updateImplement(props.implementCode, dto) :
            ImplementRequests.saveImplement(dto))
    }

    return (
        <DrawerCP
            title={props.implementCode ? `Editar ${ManeuverUtils.translateImplementType(props.type)} #${props.implementCode}` : `Novo ${ManeuverUtils.translateImplementType(props.type)}`}
            visible={props.show}
            width={'60%'}
            onClose={() => {
                formStateManager.reset()
                setFormValidator(new ImplementFormValidator())
                setFuelFieldVisible(false)
                props.onCancel()
            }}
            footer={
                <ButtonWraperSCP>
                    <ButtonCP
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'primary'}
                        isSubmit={true}
                        loading={request.awaiting}
                        onClick={onFormSubmit}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWraperSCP>
            }
        >
            <Skeleton active={true} loading={getImplementRequest.awaiting || request.awaiting}>
                <FormWrapperSCP>

                    <TwoColumnsSCP>
                        <InputCP
                            label={'Marca'}
                            fieldName={'brand'}
                            formStateManager={formStateManager}
                            required={true}
                            fontSize={screenSize.smd ? 'large' : 'normal'}
                        />
                        <InputCP
                            label={'Modelo'}
                            fieldName={'model'}
                            formStateManager={formStateManager}
                            required={true}
                            fontSize={screenSize.smd ? 'large' : 'normal'}
                        />
                    </TwoColumnsSCP>
                    <InputCP
                        label={'Ano'}
                        fieldName={'year'}
                        mask={(value: string): string => IMask.createMask({ mask: '0000' }).resolve(value.toString())}
                        formStateManager={formStateManager}
                        required={true}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                    />
                    <TwoColumnsSCP>
                        <InputCP
                            label={'Ano de compra'}
                            fieldName={'purchaseYear'}
                            mask={(value: string): string => IMask.createMask({ mask: '0000' }).resolve(value.toString())}
                            formStateManager={formStateManager}
                            required={true}
                            fontSize={screenSize.smd ? 'large' : 'normal'}
                        />
                        <InputCP
                            label={'Valor de compra'}
                            fieldName={'paidValue'}
                            formStateManager={formStateManager}
                            required={true}
                            type={'currency'}
                            fontSize={screenSize.smd ? 'large' : 'normal'}
                        />
                    </TwoColumnsSCP>
                    {props.type === ImplementTypeEnum.VEHICLE &&
                        <>
                            <TwoColumnsSCP>
                                <InputCP
                                    label={'Horímetro de compra'}
                                    type={'number'}
                                    fieldName={'purchaseHourMeter'}
                                    formStateManager={formStateManager}
                                    required={true}
                                    icon={<span>horas</span>}
                                    fontSize={screenSize.smd ? 'large' : 'normal'}
                                />
                                <InputCP
                                    label={'Horímetro atual'}
                                    type={'number'}
                                    fieldName={'currentHourMeter'}
                                    formStateManager={formStateManager}
                                    required={true}
                                    icon={<span>horas</span>}
                                    fontSize={screenSize.smd ? 'large' : 'normal'}
                                />
                            </TwoColumnsSCP>
                            <TwoColumnsSCP>
                                <InputCP
                                    label={'Data da última revisão'}
                                    fieldName={'lastRevisionDate'}
                                    type={'date'}
                                    mask={MaskUtils.applyDateMask}
                                    formStateManager={formStateManager}
                                    required={true}
                                    fontSize={screenSize.smd ? 'large' : 'normal'}
                                />
                                <InputCP
                                    label={'Horímetro da última revisão'}
                                    type={'number'}
                                    fieldName={'lastRevisionHourMeter'}
                                    formStateManager={formStateManager}
                                    required={true}
                                    icon={<span>horas</span>}
                                    fontSize={screenSize.smd ? 'large' : 'normal'}
                                />
                            </TwoColumnsSCP>
                        </>}
                    {props.type === ImplementTypeEnum.EQUIPMENT &&
                        <ImplementFieldsWppSCP>
                            <InputCP
                                label={'Data da última revisão'}
                                fieldName={'lastRevisionDate'}
                                type={'date'}
                                mask={MaskUtils.applyDateMask}
                                formStateManager={formStateManager}
                                required={true}
                                fontSize={screenSize.smd ? 'large' : 'normal'}
                            />
                            <InputCP
                                label={'Capacidade em litros'}
                                type={'number'}
                                icon={<span>litros</span>}
                                fieldName={'capacity'}
                                formStateManager={formStateManager}
                                fontSize={screenSize.smd ? 'large' : 'normal'}
                            />
                            <SolidCapWrppSCP>
                                <InputCP
                                    label={'Capacidade em quilos'}
                                    type={'number'}
                                    icon={<span>kg</span>}
                                    fieldName={'solidCapacity'}
                                    formStateManager={formStateManager}
                                    fontSize={screenSize.smd ? 'large' : 'normal'}
                                />
                                <PopOverCP
                                    placement={'top'}
                                    trigger={'click'}
                                    content={
                                        <span>
                                            A capacidade em KG deve ser informada em implementos que possuem limite de peso. Ex. Carreta.
                                            <br />
                                            A capacidade informada será considerada no cálculo de aplicação de misturas densas.
                                        </span>
                                    }
                                >
                                    <FontAwsomeIconCP icon={faInfoCircle} size={'2x'} />

                                </PopOverCP>
                            </SolidCapWrppSCP>

                        </ImplementFieldsWppSCP>}
                    <FuelDataWrpSCP>
                        <SwitchWrapperSCP>
                            <TextCP text={'Usa combustível? '} />
                            <SwitchCP
                                isChecked={fuelFieldVisible}
                                isTextInside={true}
                                onChangeAction={(visible) => {
                                    setFuelFieldVisible(visible)
                                    if (!visible)
                                        formStateManager.changeFieldValue('fuelCode', undefined)
                                }}
                                textInsideChecked={'Sim'}
                                textInsideUnchecked={'Não'}
                            />

                        </SwitchWrapperSCP>

                        {fuelFieldVisible &&
                            <ProductSelectorCP
                                fieldName={'fuelCode'}
                                label={'Combustível'}
                                types={[ProductTypeEnum.FUEL]}
                                formStateManager={formStateManager}
                            />}
                    </FuelDataWrpSCP>

                </FormWrapperSCP>

            </Skeleton>
        </DrawerCP>
    )
}

const FormWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`

const TwoColumnsSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    @media(max-width: 767px){
        grid-template-columns: 1fr;
    }
`

const SolidCapWrppSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr .3fr;
    align-items: center;
    justify-content: center;
    svg {
        margin-top: 1.3em;
        color: ${props => props.theme.primaryColor};
    }
`

const ButtonWraperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`

const ImplementFieldsWppSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;

    @media(max-width: 767px){
        grid-template-columns: 1fr;
    }

`

const SwitchWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media(max-width: 767px){
        flex-direction: row;
        align-items: center;
       justify-content: space-between;
    }
`

const FuelDataWrpSCP = styled.div`
    display: grid;
    margin-top: 10px;
    align-items: end;
    grid-template-columns: .3fr 1fr;
    @media(max-width: 767px){
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
`
