import React from 'react'

export function RegionBrasilIconCP(props: any): JSX.Element {
	return (
		<span style={{ display: 'flex', justifyContent: 'center', width: props.width, alignItems: 'start', marginRight: '10px' }} >
			<svg
				{...props}
				xmlns={'http://www.w3.org/2000/svg'}
				x={'0px'}
				y={'0px'}
				viewBox={'0 0 100 100'}
				width={30}
				enableBackground={'new 0 0 100 100'}
			>
				<g fill={'#fff'}>
					<path d="M-51.1,87.71c-0.46,1.15-1.32,1.56-2.55,1.56c-22.58-0.02-45.16-0.01-67.74-0.01c-1.8,0-2.47-0.66-2.47-2.46
		c0-3.57,0-7.15,0-10.72c0-0.13,0-0.26,0-0.39c0.04-0.84,0.49-1.35,1.18-1.33c0.66,0.02,1.11,0.5,1.13,1.3
		c0.02,1.31,0.01,2.62,0.01,3.93c0,0.59,0,1.17,0,1.81c4.39,0,8.69,0,13.05,0c0-5.34,0-10.65,0-16.05c-0.59,0-1.14,0.01-1.7,0
		c-1.45-0.02-2.31-0.87-2.33-2.3c-0.01-1-0.01-2.01,0-3.01c0.02-1.39,0.88-2.26,2.27-2.27c4.63-0.01,9.26-0.01,13.89,0
		c0.84,0,1.36,0.44,1.38,1.13c0.01,0.71-0.53,1.18-1.41,1.18c-4.29,0.01-8.59,0-12.88,0c-0.28,0-0.56,0-0.88,0c0,0.99,0,1.93,0,2.91
		c10.76,0,21.49,0,32.28,0c0-0.95,0-1.88,0-2.91c-0.3,0-0.57,0-0.84,0c-3.83,0-7.66,0-11.49,0c-0.26,0-0.52,0.02-0.77-0.03
		c-0.6-0.13-0.97-0.53-0.96-1.14c0.01-0.64,0.38-1.04,1.04-1.14c0.15-0.02,0.31-0.01,0.46-0.01c4.14,0,8.28,0,12.42,0
		c1.68,0,2.5,0.82,2.51,2.51c0.01,0.87,0.01,1.75,0,2.62c-0.02,1.59-0.85,2.42-2.43,2.44c-0.53,0.01-1.07,0-1.64,0
		c0,5.39,0,10.7,0,16.06c4.35,0,8.66,0,13.06,0c0-0.31,0-0.58,0-0.86c0-7.74,0.01-15.48-0.01-23.22c0-0.41-0.12-0.87-0.33-1.22
		c-2.45-3.92-4.91-7.84-7.41-11.73c-0.34-0.53-0.87-1.03-1.43-1.33c-5.91-3.17-11.84-6.3-17.77-9.41c-0.28-0.15-0.76-0.16-1.03-0.02
		c-5.94,3.1-11.87,6.23-17.78,9.39c-0.58,0.31-1.13,0.83-1.48,1.38c-2.51,3.89-4.97,7.8-7.41,11.73c-0.22,0.35-0.34,0.81-0.34,1.22
		c-0.02,3.99-0.01,7.97-0.02,11.96c0,0.23,0.01,0.46,0,0.69c-0.04,0.83-0.51,1.35-1.19,1.33c-0.65-0.02-1.08-0.52-1.12-1.32
		c-0.01-0.21,0-0.41,0-0.62c0-2.75,0-5.5,0-8.25c0-0.27,0-0.55,0-0.93c-1.15,0.81-2.26,1.17-3.53,0.84
		c-2.38-0.62-3.44-3.13-2.14-5.21c3.44-5.49,6.89-10.97,10.38-16.42c0.38-0.6,0.98-1.16,1.61-1.49c7.43-3.98,14.88-7.9,22.32-11.86
		c0.96-0.51,1.81-0.32,2.7,0.15c3.42,1.83,6.85,3.64,10.28,5.45c0.24,0.13,0.49,0.24,0.84,0.41c0.02-0.36,0.03-0.63,0.04-0.89
		c0.05-2.65-0.11-5.32,0.19-7.93c0.76-6.62,6.28-12.01,12.9-12.85c0.17-0.02,0.34-0.09,0.51-0.14c1.03,0,2.06,0,3.09,0
		c0.14,0.05,0.28,0.11,0.43,0.14c6.41,1.08,10.6,4.72,12.57,10.91c0.26,0.82,0.39,1.69,0.58,2.53C-51.1,45.12-51.1,66.42-51.1,87.71
		z M-53.47,40.93c0-4.38,0-8.68,0-12.99c-8.55,0-17.04,0-25.58,0c0,1.62,0.02,3.19-0.01,4.75c-0.01,0.48,0.16,0.73,0.58,0.94
		c2.17,1.12,4.29,2.32,6.48,3.39c1.63,0.8,3.05,1.78,3.87,3.48c0.09,0.2,0.42,0.4,0.64,0.4C-62.85,40.94-58.2,40.93-53.47,40.93z
		 M-79.08,25.51c8.63,0,17.12,0,25.69,0c-0.17-4.86-2.16-8.67-6.31-11.12c-4.46-2.63-9.05-2.55-13.43,0.21
		C-77.06,17.09-78.96,20.81-79.08,25.51z M-126.37,59.09c0.51-0.47,1.02-0.78,1.31-1.23c3.1-4.85,6.17-9.72,9.22-14.6
		c0.63-1.01,1.42-1.78,2.48-2.34c5.94-3.11,11.87-6.22,17.77-9.4c1.13-0.61,2.04-0.6,3.15,0c5.79,3.11,11.6,6.19,17.43,9.23
		c1.25,0.65,2.2,1.52,2.94,2.72c3.01,4.84,6.07,9.66,9.12,14.48c0.57,0.9,1.41,1.14,2.15,0.64c0.65-0.44,0.68-1,0.09-1.93
		c-3.32-5.26-6.62-10.52-9.97-15.76c-0.31-0.49-0.82-0.93-1.34-1.21c-7.14-3.81-14.29-7.58-21.45-11.34
		c-0.28-0.15-0.76-0.16-1.04-0.02c-7.25,3.8-14.5,7.63-21.73,11.47c-0.37,0.2-0.75,0.49-0.97,0.84c-3.46,5.44-6.9,10.9-10.31,16.37
		c-0.18,0.29-0.25,0.85-0.09,1.12C-127.39,58.5-126.91,58.7-126.37,59.09z M-53.44,73.97c-3.61,0-7.14,0-10.72,0
		c0,4.33,0,8.61,0,12.91c3.59,0,7.15,0,10.72,0C-53.44,82.57-53.44,78.31-53.44,73.97z M-64.16,71.55c3.6,0,7.15,0,10.71,0
		c0-4.34,0-8.62,0-12.99c-1.31,0-2.59,0.02-3.87-0.01c-0.49-0.01-0.77,0.1-1,0.59c-0.48,1.04-1.34,1.65-2.46,1.91
		c-1.24,0.29-2.31-0.12-3.38-0.83C-64.16,64.04-64.16,67.75-64.16,71.55z M-53.47,56.25c0-4.39,0-8.67,0-12.93
		c-4.32,0-8.58,0-12.93,0c2.69,4.26,5.32,8.43,7.96,12.59c0.1,0.16,0.35,0.32,0.53,0.32C-56.44,56.26-54.98,56.25-53.47,56.25z
		 M-95.75,76.15c-3.46-3.12-6.84-6.17-10.31-9.3c0,6.27,0,12.36,0,18.59C-102.59,82.31-99.21,79.27-95.75,76.15z M-92.29,76.15
		c3.47,3.13,6.84,6.16,10.28,9.26c0-6.22,0-12.32,0-18.53C-85.48,70.01-88.85,73.05-92.29,76.15z M-83.83,65.41
		c-6.86,0-13.54,0-20.37,0c3.46,3.12,6.81,6.14,10.19,9.18C-90.63,71.53-87.29,68.52-83.83,65.41z M-104.19,86.88
		c6.85,0,13.54,0,20.34,0c-3.45-3.11-6.81-6.13-10.18-9.17C-97.43,80.78-100.76,83.79-104.19,86.88z M-66.55,83.82
		c-4.38,0-8.69,0-13.01,0c0,1.06,0,2.07,0,3.08c4.36,0,8.67,0,13.01,0C-66.55,85.86-66.55,84.87-66.55,83.82z M-121.49,83.81
		c0,1.09,0,2.08,0,3.09c4.36,0,8.67,0,12.98,0c0-1.05,0-2.06,0-3.09C-112.87,83.81-117.15,83.81-121.49,83.81z"/>
					<path d="M-93.92,39.03c4.47,0.1,7.9,3.68,7.81,8.14c-0.09,4.38-3.72,7.8-8.16,7.69c-4.3-0.11-7.79-3.77-7.67-8.05
		C-101.81,42.41-98.23,38.93-93.92,39.03z M-88.42,46.98c0.01-3.13-2.51-5.65-5.61-5.64c-3.09,0.02-5.57,2.5-5.59,5.59
		c-0.02,3.11,2.49,5.63,5.62,5.62C-90.92,52.55-88.43,50.07-88.42,46.98z"/>
				</g>
				<g>
					<path d="M93.75,43.11c-1.24,1.36-2.46,2.75-3.75,4.07c-0.52,0.53-1.28,0.44-1.74-0.06c-0.46-0.5-0.48-1.24,0.02-1.76
		c0.8-0.84,1.65-1.62,2.45-2.45c0.18-0.19,0.4-0.47,0.38-0.69c-0.15-1.68-0.36-3.35-0.56-5.12c-0.41,0-0.75-0.04-1.07,0.01
		c-1.28,0.19-2.29-0.3-3.23-1.12c-2-1.74-4.05-3.43-6.04-5.18c-0.49-0.43-0.98-0.58-1.6-0.54c-1.8,0.12-3.6,0.21-5.41,0.29
		c-0.39,0.02-0.82,0.03-1.16-0.13c-1.68-0.8-3.34-1.63-4.98-2.51c-0.64-0.34-0.78-1.11-0.41-1.69c0.41-0.66,1.03-0.79,1.69-0.48
		c1.27,0.61,2.49,1.31,3.77,1.9c0.48,0.22,1.07,0.32,1.61,0.31c1.78-0.04,3.55-0.15,5.32-0.25c0.98-0.05,1.79,0.25,2.54,0.91
		c2.01,1.77,4.08,3.47,6.1,5.23c0.56,0.49,1.14,0.78,1.9,0.7c0.39-0.04,0.79-0.01,1.18,0.04c1.26,0.17,2.11,0.99,2.29,2.24
		c0.2,1.34,0.33,2.69,0.5,4.03c0.03,0.25,0.11,0.49,0.17,0.73C93.75,42.1,93.75,42.6,93.75,43.11z"/>
					<path d="M28.42,18.71c-0.3-0.91-0.58-1.71-0.82-2.52c-0.54-1.78,0.32-3.26,2.14-3.59c2.16-0.4,4.33-0.76,6.5-1.11
		c1.44-0.23,2.56,0.47,3.07,1.92c0.63,1.81,1.22,3.63,1.87,5.57c1.19-0.27,2.36-0.52,3.51-0.84c0.17-0.05,0.29-0.38,0.39-0.6
		c0.6-1.38,1.76-1.97,3.23-1.59c0.85,0.22,1.71,0.39,2.52,0.71c0.94,0.37,1.56,0.21,2.05-0.72c0.41-0.77,1.07-1.31,1.98-1.44
		c1.31-0.19,2.42,0.46,2.94,1.77c0.6,1.52,1.14,3.07,1.77,4.57c0.17,0.39,0.54,0.77,0.92,0.99c0.95,0.55,1.96,0.99,2.93,1.5
		c0.81,0.43,1.07,1.13,0.71,1.81c-0.35,0.66-1.04,0.87-1.82,0.49c-1.24-0.6-2.51-1.16-3.68-1.88c-0.53-0.33-0.98-0.94-1.24-1.52
		c-0.65-1.44-1.16-2.93-1.73-4.4c-0.1-0.25-0.21-0.5-0.35-0.86c-0.41,0.56-0.72,1.02-1.07,1.46c-0.74,0.92-1.68,1.31-2.84,1
		c-1.27-0.33-2.54-0.68-3.88-1.05c-0.35,1.56-1.41,2.2-2.84,2.44c-0.89,0.15-1.75,0.43-2.63,0.61c-1.68,0.34-2.79-0.3-3.35-1.92
		c-0.51-1.46-0.98-2.94-1.47-4.41c-0.12-0.34-0.25-0.68-0.4-1.09c-1.16,0.18-2.29,0.36-3.42,0.55c-1.1,0.18-2.2,0.38-3.39,0.59
		c0.29,0.91,0.55,1.71,0.79,2.51c0.45,1.49,0.03,2.61-1.3,3.39c-0.8,0.47-1.61,0.93-2.43,1.37c-1.35,0.73-2.56,0.54-3.59-0.59
		c-0.55-0.6-1.06-1.25-1.63-1.84c-0.17-0.18-0.47-0.36-0.69-0.33c-1,0.11-2,0.29-3.06,0.45c0.02,0.23,0,0.42,0.06,0.6
		c0.67,2.14,0.72,4.32,0.41,6.53c-0.19,1.37-0.31,2.75-0.47,4.12c-0.16,1.32-0.91,2.16-2.21,2.44c-1.41,0.3-2.81,0.6-4.23,0.82
		c-0.57,0.09-0.83,0.35-0.99,0.85c-0.27,0.83-0.61,1.64-0.85,2.48c-0.07,0.26,0.01,0.67,0.18,0.87c0.88,1.06,1.8,2.1,2.74,3.11
		c0.19,0.2,0.56,0.35,0.84,0.33c1.82-0.12,2.83,0.67,3.17,2.52c0.03,0.18,0.22,0.47,0.33,0.47c0.83,0,1.72,0.11,2.49-0.15
		c1.44-0.48,2.79-1.2,4.19-1.81c2.19-0.95,4.07,0.28,3.97,2.64c-0.03,0.69,0.13,1.14,0.77,1.39c2.1,0.8,4.18,1.67,6.31,2.36
		c1.4,0.46,2.28,1.22,2.62,2.67c0.26,1.09,0.65,2.15,0.99,3.25c0.31,0.02,0.59,0.02,0.86,0.06c1.3,0.15,2.22,1.11,2.36,2.48
		c0.29,2.92,0.57,5.84,0.86,8.76c0.03,0.34,0.09,0.67,0.16,1.12c0.49,0,0.96-0.02,1.44,0c1.29,0.05,2.19,0.65,2.63,1.89
		c0.22,0.64,0.46,1.27,0.64,1.92c0.22,0.81-0.13,1.48-0.83,1.7c-0.65,0.2-1.4-0.14-1.57-0.91c-0.32-1.51-0.97-2.27-2.69-2.17
		c-1.18,0.07-2.05-1.03-2.19-2.3c-0.35-3.17-0.65-6.34-0.98-9.51c-0.01-0.11-0.04-0.22-0.06-0.39c-0.24-0.03-0.48-0.05-0.73-0.08
		c-1.21-0.14-2.04-0.75-2.4-1.94c-0.31-1.03-0.64-2.04-0.91-3.08c-0.14-0.54-0.41-0.86-0.97-1.02c-1.27-0.37-2.55-0.73-3.77-1.24
		c-1.37-0.57-2.69-1.27-3.99-1.98c-0.72-0.39-1.04-1.09-1.08-1.9c-0.02-0.47,0-0.95,0-1.55c-1.33,0.59-2.62,1.09-3.83,1.73
		c-1.51,0.8-3.08,0.81-4.69,0.59c-1.57-0.22-2.25-1.02-2.55-2.98c-1.69,0.54-2.74-0.41-3.71-1.6c-0.8-0.99-1.66-1.92-2.51-2.86
		c-0.8-0.88-1.01-1.86-0.61-2.97c0.4-1.11,0.79-2.23,1.2-3.34c0.36-0.97,1.04-1.58,2.08-1.78c1.38-0.27,2.76-0.58,4.15-0.8
		c0.65-0.1,0.88-0.38,0.93-1.01c0.16-1.91,0.48-3.81,0.53-5.73c0.03-1.16-0.29-2.33-0.53-3.49c-0.44-2.13,0.4-3.37,2.55-3.7
		c0.89-0.13,1.78-0.27,2.67-0.42c1.06-0.18,1.95,0.1,2.67,0.91c0.56,0.63,1.1,1.29,1.68,1.9c0.15,0.15,0.51,0.29,0.67,0.22
		C26.72,19.7,27.54,19.21,28.42,18.71z"/>
					<path d="M43.33,86.07c1.83,1.91,3.62,3.78,5.28,5.5c2.61-2.52,5.2-4.99,7.73-7.52c0.33-0.33,0.38-0.97,0.47-1.48
		c0.2-1.08,0.28-2.19,0.53-3.25c0.13-0.57,0.39-1.22,0.8-1.61c1.36-1.31,2.78-2.56,4.26-3.73c0.44-0.35,1.14-0.47,1.74-0.52
		c2.36-0.22,4.72-0.39,7.09-0.54c0.55-0.04,0.92-0.22,1.25-0.69c1.79-2.6,3.62-5.18,5.42-7.77c0.2-0.29,0.35-0.65,0.43-1
		c0.7-3.11,1.34-6.23,2.09-9.32c0.18-0.72,0.61-1.45,1.11-2.01c0.9-1,1.94-1.88,2.94-2.8c0.71-0.65,1.49-0.66,2.01-0.07
		c0.51,0.59,0.43,1.27-0.26,1.93c-0.82,0.78-1.63,1.57-2.49,2.3c-0.55,0.47-0.82,1.02-0.97,1.71c-0.65,3.06-1.33,6.11-2.03,9.16
		c-0.12,0.51-0.36,1.01-0.65,1.44c-1.85,2.7-3.75,5.36-5.58,8.07c-0.7,1.03-1.6,1.51-2.81,1.58c-2.36,0.15-4.73,0.33-7.09,0.54
		c-0.41,0.04-0.87,0.24-1.19,0.5c-0.99,0.79-1.97,1.61-2.87,2.5c-0.37,0.36-0.66,0.9-0.78,1.41c-0.25,1.13-0.28,2.3-0.56,3.42
		c-0.19,0.73-0.52,1.52-1.03,2.06c-2.36,2.5-4.81,4.93-7.23,7.37c-1.41,1.42-2.87,1.43-4.28,0.05c-1.69-1.66-3.38-3.32-5.06-4.99
		c-1.4-1.4-1.31-3.07,0.24-4.29c1.59-1.26,3.19-2.51,4.82-3.73c0.47-0.35,0.69-0.67,0.44-1.26c-0.18-0.41-0.27-0.86-0.36-1.3
		c-0.14-0.7,0.25-1.34,0.89-1.53c0.64-0.18,1.32,0.15,1.56,0.83c0.22,0.64,0.39,1.3,0.54,1.95c0.27,1.18-0.04,2.17-1.02,2.93
		c-1.72,1.33-3.43,2.66-5.14,4C43.43,85.97,43.33,86.07,43.33,86.07z"/>
					<circle cx="69.89" cy="62.66" r="3.86" />

				</g>
			</svg>
		</span>
	)
}

