import React from 'react'
import styled, { CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'

interface ITransCPProps {
    text: string
    files: string | string[]
    namespace: string
    style?: CSSProperties
}

export function TransCP(props: ITransCPProps): JSX.Element {
    const { t, i18n } = useTranslation(props.files)

    return (

        <WrapperSCP style={props.style}>
            {t(`${props.namespace}:${props.text}`)}
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
`
