import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { IFormulaAplicationDataReqDTO } from 'modules/maneuver/services/dtos/requests/IFormulaAplicationDataReqDTO'
import { ISaveRecipeRequestDTO } from 'modules/maneuver/services/dtos/requests/ISaveRecipeRequestDTO'
import { ISearchRecipeRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchRecipeRequestDTO'

export class RecipeRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/recipes`

    private constructor() { }

    static getRecipeList = (dto?: ISearchRecipeRequestDTO): RequestConfigTP<ISearchRecipeRequestDTO> => ({
        url: `${RecipeRequests.baseApiUrl}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getRecipe = (code: number): RequestConfigTP => ({
        url: `${RecipeRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static saveRecipe = (dto: ISaveRecipeRequestDTO): RequestConfigTP<ISaveRecipeRequestDTO> => ({
        url: `${RecipeRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static updateRecipe = (code: number, dto: ISaveRecipeRequestDTO): RequestConfigTP<ISaveRecipeRequestDTO> => ({
        url: `${RecipeRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static deleteRecipe = (code: number): RequestConfigTP => ({
        url: `${RecipeRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static archiveRecipe = (code: number, archive: boolean): RequestConfigTP => ({
        url: `${RecipeRequests.baseApiUrl}/${code}/archive`,
        method: HttpMethodEnum.PUT,
        params: { archive }
    })

    static cloneRecipe = (code: number): RequestConfigTP => ({
        url: `${RecipeRequests.baseApiUrl}/${code}/clone`,
        method: HttpMethodEnum.PUT,
    })

    static getFormulaAplicationData = (code: number, dto: IFormulaAplicationDataReqDTO): RequestConfigTP => ({
        url: `${RecipeRequests.baseApiUrl}/${code}/formula-application`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

}
