import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { SchemaDataTP } from 'common/redux/ReduxTypes'
import { useRequest } from 'common/request-helper/UseRequest'
import _ from 'lodash'
import { SchemaNotFoundScreen } from 'modules/auth/screens/SchemaNotFoundScreen'
import { BatchPresentationContentCP } from 'modules/batch-presentation/components/batch-presentation-content/BatchPresentationContentCP'
import { BatchPresentationHeaderCP } from 'modules/batch-presentation/components/batch-presentation-header/BatchPresentationHeaderCP'
import { SchemaActions } from 'modules/company/actions/SchemaActions'
import { CompanyRequests } from 'modules/company/services/CompanyRequests'
import { ICompanyResDTO } from 'modules/company/services/dtos/response/ICompanyResDTO'
import React, { Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { LoadingBoxCP } from '../components/loading-box/LoadingBoxCP'

interface IBatchPresentationScreenProps {
}

export function BatchPresentationScreen(props: IBatchPresentationScreenProps): JSX.Element {
    const persistedSchema: SchemaDataTP | null = useSelector((state: IReduxState) => _.get(state, 'schemaData'))

    const [rootLocation, setRootLocation] = useState<string>()
    const location = useLocation()

    const schemaReq = useRequest<ICompanyResDTO>()
    useEffect(onSchemaReqChange, [schemaReq.awaiting])

    useEffect(verifySchema, [location])

    function verifySchema(): void {
        let root

        if (!!location.pathname) {
            root = location.pathname.substring(0, location.pathname.indexOf('/'))
            setRootLocation(root)
        }

        if (!!persistedSchema) {
            if (persistedSchema.domain.includes(root))
                return
        }
        getSchema()
    }

    async function getSchema(): Promise<void> {

        if (!!rootLocation && rootLocation !== 'coffees')
            return await schemaReq.runRequest(CompanyRequests.getCompany({ schema: rootLocation }))

    }

    function onSchemaReqChange(): void {
        if (schemaReq.awaiting || !schemaReq.tried)
            return

        if (!schemaReq.success) {
            SchemaActions.clearSchemaData()
            console.log('ERRO AO BUSCAR SCHEMA')
        }

        if (!!schemaReq.returnData)
            SchemaActions.persistSchema(schemaReq.returnData)
    }

    if (schemaReq.awaiting)
        return <LoadingBoxCP />

    if (!!rootLocation && rootLocation !== 'coffees' && !schemaReq.returnData)
        return <SchemaNotFoundScreen />

    return (
        <Suspense fallback={'...'}>
            <WrapperSCP>
                <LayoutCP
                    header={<BatchPresentationHeaderCP />}
                    content={
                        <BatchPresentationContentCP />
                    }
                />
            </WrapperSCP>
        </Suspense>
    )
}

const WrapperSCP = styled.div`
  

`
