import { TextCP } from 'common/components/text/TextCP'
import { ArabicaVarietyEnum, CaneforaVarietyEnum } from 'modules/field/enums/CoffeeTypeEnum'
import { FieldUtils } from 'modules/field/utils/FieldUtils'
import React from 'react'
import styled from 'styled-components'

interface IMainFieldInfoICPProps {
    name: string
    year: string
    type: ArabicaVarietyEnum | CaneforaVarietyEnum
    typeOther?: string
}

export function MainFieldInfoICP(props: IMainFieldInfoICPProps): JSX.Element {
    return (
        <WrapperSCP>
            <TextCP text={props.name} size={'large'} />
            <OtherInfoWrapperSCP>
                <TextCP text={`Variedade: ${FieldUtils.translateVarietysEnum(props.type, props.typeOther)}`} size={'small'} />
                <TextCP text={`Ano: ${props.year}`} size={'small'} />
            </OtherInfoWrapperSCP>
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div`

`
const OtherInfoWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 5px;
    .ant-typography{
        color: ${props => props.theme.gray}
    }
`