import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { DateUtils } from 'common/utils/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { BatchProcessFormValidator } from 'modules/batch/components/batch-process-drawer/inner/BatchProcessFormValidator'
import { BenefitFormICP } from 'modules/batch/components/batch-process-drawer/inner/BenefitFormICP'
import { PostHarvestFormICP } from 'modules/batch/components/batch-process-drawer/inner/PostHarvestFormICP'
import { StorageFormICP } from 'modules/batch/components/batch-process-drawer/inner/StorageFormICP'
import { BatchProcessLogbookDrawerCP } from 'modules/batch/components/batch-process-logbook-drawer/BatchProcessLogbookDrawerCP'
import { BatchProcessTypeSelectorCP } from 'modules/batch/components/batch-process-type-selector/BatchProcessTypeSelectorCP'
import { BatchProcessTypeEnum } from 'modules/batch/enums/BatchProcessTypeEnum'
import { BatchRequests } from 'modules/batch/services/BatchRequests'
import { ISaveBatchProcessReqDTO } from 'modules/batch/services/dtos/requests/ISaveBatchProcessReqDTO'
import { IBatchProcessResDTO } from 'modules/batch/services/dtos/responses/IBatchProcessResDTO'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IBatchProcessDrawerCPProps {
    processCode?: number
    show: boolean
    batchCode: number
    onCancel: () => void
    onSuccess: () => void
    hasBenefit: boolean
}

export function BatchProcessDrawerCP(props: IBatchProcessDrawerCPProps): JSX.Element {
    const screenSize = useScreenSize()
    const [formValidator, setFormValidator] = useState<BatchProcessFormValidator>(new BatchProcessFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const [processType, setProcessType] = useState<BatchProcessTypeEnum>()
    const [filterdTypesOpt, setFilteredTypesOpt] = useState<BatchProcessTypeEnum[]>([BatchProcessTypeEnum.POST_HARVEST, BatchProcessTypeEnum.BENEFIT])
    const [logbookDrawerVisible, setLogbookDrawerVisible] = useState<boolean>(false)

    const request = useRequest<IBatchProcessResDTO>()
    useEffect(onRequestChange, [request.awaiting])

    const findOneReq = useRequest<IBatchProcessResDTO>()
    useEffect(onFindOneRequestChange, [findOneReq.awaiting])

    useEffect(() => { init() }, [props.processCode, props.show])
    useEffect(defineOptions, [props.hasBenefit])

    function init(): void {
        if (!!props.show && !!props.processCode) {
            setFilteredTypesOpt([])
            findOneReq.runRequest(BatchRequests.getProcess(props.batchCode, props.processCode))
        }
    }

    function defineOptions(): void {
        if (props.hasBenefit)
            setFilteredTypesOpt([BatchProcessTypeEnum.STORAGE])
        else
            setFilteredTypesOpt([BatchProcessTypeEnum.POST_HARVEST, BatchProcessTypeEnum.BENEFIT])
    }

    async function onFormSubmit(): Promise<void> {

        const formValues = formStateManager.getFormValues()

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return

        const dto: ISaveBatchProcessReqDTO = {
            startDate: DateUtils.getDateFormattedToApi(formValues.startDate),
            type: formValues.type,
            method: formValues.method,
            notes: formValues.notes,
            benefit: formValues.benefit,
            storage: formValues.storage,
            structureCode: formValues.structureCode
        }

        request.runRequest(!props.processCode ?
            BatchRequests.saveProcess(props.batchCode, dto)
            : BatchRequests.updateProcess(props.batchCode, props.processCode, dto))
    }

    function onFindOneRequestChange(): void {

        if (findOneReq.awaiting || !findOneReq.tried)
            return

        if (!findOneReq.success || !findOneReq.returnData) {
            if (findOneReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', findOneReq.returnData, findOneReq.error)
            return RequestUtils.showDefaultErrorNotification(findOneReq.error, 'Erro ao buscar dados do processo!')
        }

        setFormValidator(new BatchProcessFormValidator(findOneReq.returnData))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao salvar registro!')
        }

        ListActions.mustUpdate(true)
        props.onSuccess()
    }

    return (
        <DrawerCP
            title={`${props.processCode ? ` #${props.processCode} Editar processo ` : 'Novo processo '}`}
            visible={props.show}
            width={'60%'}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFormSubmit}
                        loading={request.awaiting}
                        wrapperStyle={{ width: '100%' }}
                    >
                        Salvar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
            onClose={() => {
                props.onCancel()
                formStateManager.reset()
                setProcessType(undefined)
            }}
        >

            <WrapperSCP>
                <TwoColWrapperSCP>
                    <BatchProcessTypeSelectorCP
                        formStateManager={formStateManager}
                        required={true}
                        onSelect={setProcessType}
                        label={'Tipo'}
                        filterOptions={filterdTypesOpt}
                        disabled={!!props.processCode}
                    />
                    <InputCP
                        label={'Data'}
                        placeholder={'__/__/____'}
                        fieldName={'startDate'}
                        type={'date'}
                        formStateManager={formStateManager}
                        required={true}
                        mask={MaskUtils.applyDateMask}
                        fontSize={screenSize.smd ? 'large' : 'normal'}
                        maxLength={10}
                        loading={false}
                    />
                </TwoColWrapperSCP>
                <TextAreaCP
                    label={'Observação'}
                    fieldName={'notes'}
                    formStateManager={formStateManager}
                    required={false}
                    fontSize={screenSize.smd ? 'large' : 'normal'}
                    loading={false}
                />
                {
                    processType === BatchProcessTypeEnum.POST_HARVEST &&
                    <PostHarvestFormICP
                        formStateManager={formStateManager}
                    />
                }
                {
                    processType === BatchProcessTypeEnum.STORAGE &&
                    <StorageFormICP
                        formStateManager={formStateManager}
                    />
                }
                {
                    processType === BatchProcessTypeEnum.BENEFIT &&
                    <BenefitFormICP
                        formStateManager={formStateManager}
                    />
                }
                {
                    !!props.processCode &&
                    <ButtonWrapperSCP>
                        <ButtonCP
                            type={'primary'}
                            onClick={() => {
                                setLogbookDrawerVisible(true)
                            }}
                            icon={<FontAwsomeIconCP icon={faClipboardList} />}
                            wrapperStyle={{ width: '100%' }}
                        >
                            Diário
                        </ButtonCP>
                    </ButtonWrapperSCP>
                }
                {
                    !!props.processCode && !!props.batchCode &&
                    <BatchProcessLogbookDrawerCP
                        show={logbookDrawerVisible}
                        processCode={props.processCode}
                        batchCode={props.batchCode}
                        onCancel={() => setLogbookDrawerVisible(false)}
                    />
                }
            </WrapperSCP>
        </DrawerCP>
    )
}

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .ant-btn{
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .ant-typography{
        font-size: 11px;
        color: ${props => props.theme.lightGreen}
    }
`

const WrapperSCP = styled.div`
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
        padding: 12px 0;
        border-bottom: 1px solid ${props => props.theme.lightGray};
    }
    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
        padding: 16px 0;
    }
    .ant-row.has-error .ant-form-explain {
        position: absolute;
        top: -27px;
        right: 0;
    }
`

const TwoColWrapperSCP = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
column-gap: 10px;
`