import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { TagCP } from 'common/components/tag/TagCP'
import { TextCP } from 'common/components/text/TextCP'
import { GenericCardWidgetCP } from 'common/components/widgets/GenericCardWidgetCP'
import { CoffeeFlavorEnum } from 'modules/analysis/enums/CoffeeFlavorEnum'
import { PhysicalDefectEnum } from 'modules/analysis/enums/PhysicalDefectEnum'
import { AnalysisUtils } from 'modules/analysis/utils/AnalysisUtils'
import React from 'react'
import styled from 'styled-components'

interface IFlavorAndAromaWidgetICPProps {
    mostCommonAromas?: CoffeeFlavorEnum[]
    mostCommonDefects?: PhysicalDefectEnum[]
    loading?: boolean
    gridArea?: string
}

export function FlavorAndAromaWidgetICP(props: IFlavorAndAromaWidgetICPProps): JSX.Element {

    return (
        <WrapperSCP style={{ gridArea: props.gridArea ?? undefined }}>
            <GenericCardWidgetCP
                title={'Sabores/aromas mais comuns:'}
                content={
                    <TagsWrapperSCP>
                        {
                            !!props.mostCommonAromas && !!props.mostCommonAromas.length ?
                                props.mostCommonAromas.map(flavor => (
                                    <TagCP
                                        key={flavor}
                                        content={AnalysisUtils.translateCoffeeFlavor(flavor)}
                                        color={'purple'}
                                    />
                                ))
                                : props.loading ?
                                    <GenericRowSkeletonICP columns={1} rows={1} />
                                    : <TextCP text={'Sem resultados'} style={{ fontWeight: 600 }} />
                        }
                    </TagsWrapperSCP>
                }
            />

            <GenericCardWidgetCP
                title={'Defeitos mais comuns:'}
                content={
                    <TagsWrapperSCP>

                        {
                            !!props.mostCommonDefects && !!props.mostCommonDefects.length ?
                                props.mostCommonDefects.map(defect => (
                                    <TagCP
                                        key={defect}
                                        content={AnalysisUtils.translatePhysicalDefect(defect)}
                                        color={'purple'}
                                    />
                                ))
                                : props.loading ?
                                    <GenericRowSkeletonICP columns={1} rows={1} />
                                    : <TextCP text={'Sem resultados'} style={{ fontWeight: 600 }} />
                        }
                    </TagsWrapperSCP>
                }
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    grid-area: flavor-aroma;
    column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
`
const TagsWrapperSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-tag{
        font-size: 10px;
        margin-right: 0;
    }
`