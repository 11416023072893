import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'

export class SystemConfig {
    readonly gfApiBaseUrl: string
    readonly uiBaseUrl: string
    readonly oldApiBaseUrl: string
    readonly newApiUrl: string

    readonly environment?: EnvironmentEnum
    readonly reduxEncryptKey: string
    readonly rootRoute: string = '/'
    readonly defaultTimeZone = 'America/Sao_Paulo'

    private static _instance?: SystemConfig

    private constructor() {
        this.environment = process.env.REACT_APP_STAGE as EnvironmentEnum

        switch (this.environment) {
            case EnvironmentEnum.DEV:
                this.uiBaseUrl = 'http://localhost:5001'
                this.gfApiBaseUrl = 'https://gerente-api.stg.flowins.app'
                this.oldApiBaseUrl = 'https://old-api.stg.flowins.app'
                this.newApiUrl = 'https://api.stg.flowins.app'
                this.reduxEncryptKey = 'bmVyaXRzZWNyZXRrZXk='
                break

            case EnvironmentEnum.PROD:
                this.uiBaseUrl = 'https://sistema.flowins.me'
                this.gfApiBaseUrl = 'https://gerente-api.prd.flowins.app'
                this.oldApiBaseUrl = 'https://old-api.prd.flowins.app'
                this.newApiUrl = 'https://api.prd.flowins.app'
                this.reduxEncryptKey = '3hjhfubu*kfsu'
                break

            case EnvironmentEnum.TEST:
                this.uiBaseUrl = 'http://localhost:3000'
                // this.gfApiBaseUrl = 'https://gerente-api.stg.flowins.app'
                this.gfApiBaseUrl = 'http://localhost:3002'
                this.oldApiBaseUrl = 'https://old-api.stg.flowins.app'
                this.newApiUrl = 'https://api.stg.flowins.app'
                this.reduxEncryptKey = '3hjhfubu*kfsu'
                break

            default:
                throw new Error('Ambiente não configurado!')
        }
    }

    static getInstance(): SystemConfig {
        if (!this._instance)
            this._instance = new SystemConfig()
        return this._instance
    }
}
