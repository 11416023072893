import { IsOptional } from 'class-validator'
import { FormModelValidator } from 'common/form-state-manager/classes/FormModelValidator'
import { IsString } from 'common/validation/decorators/IsString'
import { IProductResponseDTO } from 'modules/maneuver/services/dtos/responses/IProductResponseDTO'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { ProductTypeEnum } from 'modules/maneuver/enums/ProductTypeEnum'
import { IsIn } from 'common/validation/decorators/IsIn'
import { UnitOfMeasurementEnum } from 'modules/maneuver/enums/UnitOfMeasurementEnum'
export class ProductFormValidator extends FormModelValidator {

    @IsString()
    @IsNotEmpty()
    name: string

    @IsIn(Object.values(UnitOfMeasurementEnum))
    @IsNotEmpty()
    unitOfMeasurement?: UnitOfMeasurementEnum

    @IsOptional()
    remainingQuantity: number

    @IsIn(Object.values(ProductTypeEnum))
    @IsNotEmpty()
    type: ProductTypeEnum

    constructor(dto?: IProductResponseDTO) {
        super(dto)
        Object.assign(this, dto)

    }
}