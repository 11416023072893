import { CarouselCP } from 'common/components/carousel/CarouselCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { PropertyRequests } from 'modules/property/services/PropertyRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IPropertiesAlbumCPProps {
    propertyCodes: number[]
}

export function PropertiesAlbumCP(props: IPropertiesAlbumCPProps): JSX.Element {
    const request = useRequest<string[]>()
    const [pictureList, setPictureList] = useState<string[]>([])
    useEffect(onRequestChange, [request.awaiting])

    useEffect(getPictures, [props.propertyCodes])

    function getPictures(): void {
        request.runRequest(PropertyRequests.getPictures(props.propertyCodes))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar fotos da propriedade!')
        }
        setPictureList(request.returnData)
    }

    if (!pictureList.length)
        return <></>

    return (
        <WrapperSCP>
            <CarouselCP
                autoPlay={true}
                source={pictureList}
                numberOfThumbs={4}
                arrowsColor={CostumizedThemeConfig.primaryColor}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
