import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { QualityReportScreen } from 'modules/report/screens/QualityReportScreen'
import { ProductionReportScreen } from 'modules/report/screens/ProductionReportScreen'
import { FieldReportScreen } from 'modules/report/screens/FieldReportScreen'
import { BatchReportScreen } from 'modules/report/screens/BatchReportScreen'
import { BestBatchesScreen } from 'modules/report/screens/BestBatchesScreen'
import { FinancialReportScreen } from 'modules/report/screens/FinancialReportScreen'

ReportRoutes.ROOT = '/relatorios'

ReportRoutes.PRODUCTION = `${ReportRoutes.ROOT}/producao`
ReportRoutes.FINANCIAL = `${ReportRoutes.ROOT}/financeiro`
ReportRoutes.QUALITY = `${ReportRoutes.ROOT}/qualidade`
ReportRoutes.BEST_BATCHES = `${ReportRoutes.ROOT}/melhores-lotes`
ReportRoutes.FIELD = `${ReportRoutes.ROOT}/talhao/:code`
ReportRoutes.FIELD_WITHOUT_PARAM = `${ReportRoutes.ROOT}/talhao`
ReportRoutes.BATCH = `${ReportRoutes.ROOT}/lote/:code`
ReportRoutes.BATCH_WITHOUT_PARAM = `${ReportRoutes.ROOT}/lote`

export function ReportRoutes(): JSX.Element {
    return (
        <Switch>
            <Redirect exact={true} from={ReportRoutes.ROOT} to={ReportRoutes.PRODUCTION} />
            <Route path={ReportRoutes.QUALITY} component={QualityReportScreen} />
            <Route path={ReportRoutes.PRODUCTION} component={ProductionReportScreen} />
            <Route path={ReportRoutes.FINANCIAL} component={FinancialReportScreen} />
            <Route path={ReportRoutes.FIELD} component={FieldReportScreen} />
            <Route path={ReportRoutes.FIELD_WITHOUT_PARAM} component={FieldReportScreen} />
            <Route path={ReportRoutes.BEST_BATCHES} component={BestBatchesScreen} />
            <Route path={ReportRoutes.BATCH} component={BatchReportScreen} />
            <Route path={ReportRoutes.BATCH_WITHOUT_PARAM} component={BatchReportScreen} />
        </Switch>
    )
}