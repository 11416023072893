import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { ISaveInvoiceReqDTO } from 'modules/financial/services/dtos/request/ISaveInvoiceReqDTO'
import { ISearchInvoicesReqDTO } from 'modules/financial/services/dtos/request/ISearchInvoicesReqDTO'

export class FinancialRequests {
    private static readonly baseApiUrl = `${SystemConfig.getInstance().gfApiBaseUrl}/invoices`
    private constructor() { }

    static searchInvoices = (dto: ISearchInvoicesReqDTO): RequestConfigTP => ({
        url: `${FinancialRequests.baseApiUrl}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static findOne = (code: number): RequestConfigTP => ({
        url: `${FinancialRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.GET
    })

    static save = (dto: ISaveInvoiceReqDTO): RequestConfigTP => ({
        url: `${FinancialRequests.baseApiUrl}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static update = (code: number, dto: ISaveInvoiceReqDTO): RequestConfigTP => ({
        url: `${FinancialRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${FinancialRequests.baseApiUrl}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static getInvoicePictures = (code: number): RequestConfigTP => ({
        url: `${FinancialRequests.baseApiUrl}/${code}/documents`,
        method: HttpMethodEnum.GET,
    })

    static deleteImages = (code: number, dto: { pictures: string[] }): RequestConfigTP => ({
        url: `${FinancialRequests.baseApiUrl}/${code}/documents`,
        method: HttpMethodEnum.DELETE,
        params: dto,
    })

    static addDocumentsToInvoice = (code: number, data: FormData): RequestConfigTP => ({
        url: `${FinancialRequests.baseApiUrl}/${code}/documents`,
        method: HttpMethodEnum.PUT,
        params: data,
        headers: { 'content-type': 'multipart/form-data' }
    })

}