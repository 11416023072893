import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { MaskUtils } from 'common/utils/MaskUtils'
import React from 'react'
import styled from 'styled-components'

interface IReportItemICPProps {
    value: number
    unit?: string
    label: string
}

export function ReportItemICP(props: IReportItemICPProps): JSX.Element {
    const screenSize = useScreenSize()
    return (
        <WrapperSCP>
            <ValueSCP>
                <TextCP
                    text={MaskUtils.applyNumberMask(props.value)}
                    style={{ fontSize: `${screenSize.smd ? '20px' : '16px'}`, lineHeght: '23px', fontWeight: '600' }}
                />
                {
                    !!props.unit &&
                    <TextCP
                        text={`\u00A0${props.unit}`}
                        style={{ fontSize: '13px', fontWeight: '600' }}
                    />
                }
            </ValueSCP>

            <TextCP
                text={props.label}
                style={{ fontSize: `${screenSize.smd ? '15px' : '12px'}` }}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    .ant-typography{
        color: ${props => props.theme.primaryColor}
    }
`
const ValueSCP = styled.div`
    display: flex;
    align-items: center;
`