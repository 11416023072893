import { isNotEmpty, registerDecorator, ValidationArguments, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'

/**
 * VALIDATOR
 * Valida se é um number
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint()
class IsNotEmptyConstraint implements ValidatorConstraintInterface {

    validate(value: string, args: ValidationArguments): boolean {
        return isNotEmpty(value)
    }

    defaultMessage(args: ValidationArguments): string {
        return 'Campo obrigatório'
    }
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsNotEmpty(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsNotEmptyConstraint,
        })
    }
}
