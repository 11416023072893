import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'

import { DateUtils } from 'common/utils/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { HarvestItemPreviewICP } from 'modules/harvest-planning/components/harvest-planning-list/inner/HarvestItemPreviewICP'
import { ILogbookResponseDTO } from 'modules/harvest-planning/services/dtos/response/ILogbookResponseDTO'
import { MaskUtils } from 'common/utils/MaskUtils'

interface IHarvestPlanningCardContentICPProps {
    startDate: string
    endDate: string
    estimatedTotalLiters: number
    totalLiters?: number
    revenue?: number
    totalBags?: number
    benefitedLiters?: number
    realEndDate?: string
    realStartDate?: string
    lastLoogbookItem?: ILogbookResponseDTO
    treeCount?: number
}

/**
 * COMPONENTE
 * Conteudo central de um item da lista de planejamento de colheita
 */
export function HarvestPlanningCardContentICP(props: IHarvestPlanningCardContentICPProps): JSX.Element {
    const valueStyle = { color: `${CostumizedThemeConfig.gray}`, marginLeft: '5px' }
    return (
        <WrapperSCP>
            <RowSCP>
                <TextCP text={'Criado em: '} />
                <TextCP text={`${props.startDate ? DateUtils.getFormatted(props.startDate, DateFormatEnum.BR_WITHOUT_TIME) : 'Não informado'}`} style={valueStyle} />
            </RowSCP>
            {
                props.realStartDate &&
                <RowSCP>
                    <TextCP text={'Iniciado em: '} />
                    <TextCP text={`${DateUtils.getFormatted(props.realStartDate, DateFormatEnum.BR_WITHOUT_TIME)}`} style={valueStyle} />
                </RowSCP>
            }
            <RowSCP>
                <TextCP text={'Estimativa de conclusão: '} />
                <TextCP text={`${props.endDate ? DateUtils.getFormatted(props.endDate, DateFormatEnum.BR_WITHOUT_TIME) : 'Não informado'}`} style={valueStyle} />
            </RowSCP>
            {/* {
                !!props.estimatedTotalLiters && !!props.treeCount &&
                <RowSCP>
                    <TextCP text={'Estimativa de litros colhidos: '} />
                    <TextCP text={`${MaskUtils.applyNumberMask(props.estimatedTotalLiters * props.treeCount)} L `} style={valueStyle} />
                </RowSCP>
            } */}
            {
                !!props.totalLiters &&
                <RowSCP>
                    <TextCP text={'Litragem atual: '} />
                    <TextCP text={`${props.totalLiters} `} style={valueStyle} />
                </RowSCP>
            }
            {
                !!props.benefitedLiters &&
                <RowSCP>
                    <TextCP text={'Litros beneficiados: '} />
                    <TextCP text={`${MaskUtils.applyNumberMask(props.benefitedLiters)} L `} style={valueStyle} />
                </RowSCP>
            }
            {
                !!props.totalBags &&
                <RowSCP>
                    <TextCP text={'Sacas: '} />
                    <TextCP text={`${MaskUtils.applyNumberMask(props.totalBags)} `} style={valueStyle} />
                </RowSCP>
            }
            {
                !!props.revenue &&
                <RowSCP>
                    <TextCP text={'Rendimento: '} />
                    <TextCP text={`${MaskUtils.applyNumberMask(props.revenue)} L `} style={valueStyle} />
                </RowSCP>
            }

            {
                props.realEndDate &&
                <RowSCP>
                    <TextCP text={'Concluído em: '} />
                    <TextCP text={DateUtils.getFormatted(props.realEndDate, DateFormatEnum.BR_WITHOUT_TIME)} style={valueStyle} />
                </RowSCP>
            }

            <LastEntryWrapperSCP>
                <TextCP text={'Última colheita: '} />
                <HarvestItemPreviewICP
                    item={props.lastLoogbookItem}
                />
            </LastEntryWrapperSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 0 10px;
    min-height: 260px;
    
`
const RowSCP = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    margin-bottom: 5px;
    .ant-typography{
        font-size: 13px;
    }
`
const LastEntryWrapperSCP = styled.div`
    margin-top: 12px;
    .ant-typography{
        font-size: 13px;
    }
`