/* eslint-disable max-params */
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { CollapsePanelTP } from 'common/components/collapse/types/CollapsePanelTP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { momentPtBrLocale } from 'common/utils/moment-pt-br-locale'
import _ from 'lodash'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'
import { LanguageEnum } from 'modules/batch-landing-page/enums/LanguageEnum'
import { HarvestFormCP } from 'modules/harvest/components/harvest-form/HarvestFormCP'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import { ImplementFormFieldsICP } from 'modules/logbook/components/logbook-form/inner/ImplementFormFieldsICP'
import { LogbookFormValidator } from 'modules/logbook/components/logbook-form/inner/LogbookFormValidator'
import { ProductUsageFormCP } from 'modules/maneuver/components/product-usage-form/ProductUsageFormCP'
import { ImplementTypeEnum } from 'modules/maneuver/enums/ImplementTypeEnum'
import { IRecipeResponseDTO } from 'modules/maneuver/services/dtos/responses/IRecipeResponseDTO'

import moment from 'moment-timezone'
import React from 'react'
import styled from 'styled-components'
import { ResponsibleFormFieldsICP } from '../components/logbook-form/inner/ResponsibleFormFieldsICP'
import { IPersonResponsibleResDTO } from '../services/dtos/response/IPersonResponsibleResDTO'

const NOW = moment()
const TODAY = NOW.clone().startOf('day')
const YESTERDAY = NOW.clone().subtract(1, 'days').startOf('day')

export const LogbookUtils = {
    getDateText(date: string, language?: LanguageEnum): string {
        if (language === LanguageEnum.EN)
            moment.locale('en_US')
        else
            moment.locale('pt_BR', momentPtBrLocale)

        if (moment(date).isSame(TODAY, 'd'))
            return `${language === LanguageEnum.EN ? 'Today' : 'Hoje'}`

        if (moment(date).isSame(YESTERDAY, 'd'))
            return `${language === LanguageEnum.EN ? 'Yesterday' : 'Ontem'}`

        return moment(date).locale('pt-br').format('LL')

    },

    getPanelHeadTitle(title: string, info?: string): JSX.Element {
        return (
            <HeaderWrpSCP>
                {title}
                {info &&
                    <PopOverCP
                        placement={'right'}
                        trigger={'click'}
                        content={<span>
                            {' '}
                            {info}
                            {' '}
                        </span>}
                    >
                        <FontAwsomeIconCP icon={faQuestionCircle} />
                    </PopOverCP>}
            </HeaderWrpSCP>
        )
    },

    getLogbookFormPannels(
        formStateManager: IFormStateManager<LogbookFormValidator>,
        logbookEntryCode?: number,
        fieldCode?: number,
        recipe?: IRecipeResponseDTO,
        harvest?: IHarvestResponseDTO,
        peopleResponsible?: IPersonResponsibleResDTO[],
        harvestPlanningCode?: number,
        maneuverCode?: number,
        lbkCode?: number,
        loading?: boolean,
        savedHourMeter?: number,
        savedImpCode?: number
    ): CollapsePanelTP[] {
        let defaultPannels: CollapsePanelTP[] = []

        if (PermissionUtils.hasAccess(SystemModulesEnum.MANEUVER)) {
            defaultPannels = [
                {
                    header: this.getPanelHeadTitle('Responsáveis', 'Insira a pessoas que atuaram nessa atividade.'),
                    content: <ResponsibleFormFieldsICP formStateManager={formStateManager} peopleResponsible={peopleResponsible} />,
                    key: 'responsibleFields',
                    disabled: loading
                },
                {
                    header: this.getPanelHeadTitle('Dados do equipamento', 'Insira o equipamento e o consumo de combustível, caso tenha sido utilizado.'),
                    content: <ImplementFormFieldsICP
                        fieldCode={fieldCode}
                        harvestPlanningCode={harvestPlanningCode}
                        formStateManager={formStateManager}
                        logbookEntryCode={lbkCode}
                        implementType={ImplementTypeEnum.EQUIPMENT}
                        recipe={recipe}
                    />,
                    key: 'equipmentFields',
                    disabled: loading
                },
                {
                    header: this.getPanelHeadTitle('Dados do veículo', 'Insira o veículo e o consumo de combustível, caso tenha sido utilizado.'),
                    content: <ImplementFormFieldsICP
                        fieldCode={fieldCode}
                        harvestPlanningCode={harvestPlanningCode}
                        formStateManager={formStateManager}
                        logbookEntryCode={lbkCode}
                        implementType={ImplementTypeEnum.VEHICLE}
                        savedHourMeter={savedHourMeter}
                        savedImpCode={savedImpCode}
                    />,
                    key: 'vechileFields',
                    disabled: loading
                },

            ]
        }

        if (!!maneuverCode && !!recipe?.formulas.length) {
            return [
                ...defaultPannels,
                {
                    header: this.getPanelHeadTitle('Gasto de produto', 'Preencha as quantidades gastas de acordo com a formúla indicada na instrução.'),
                    content: <ProductUsageFormCP
                        recipeFormula={recipe.formulas}
                        formStateManager={formStateManager}
                        maneuverCode={maneuverCode}
                        logbookCode={lbkCode}
                    />,
                    key: 'productUsageFields',
                    disabled: loading
                }
            ]
        }

        if (!!harvestPlanningCode && !!fieldCode) {
            return [
                {
                    header: this.getPanelHeadTitle('Dados da colheita', 'Insira os dados da colheita.'),
                    content: <HarvestFormCP formStateManager={formStateManager} fieldCode={fieldCode} harvest={harvest} isUpdate={!!logbookEntryCode} />,
                    key: 'harvestFields',
                    disabled: loading
                },
                ...defaultPannels
            ]
        }

        return defaultPannels

    },
}

const HeaderWrpSCP = styled.div`
    display: flex;
    color:  ${props => props.theme.primaryColor}; 
    svg {
        color:  ${props => props.theme.primaryColor}; 
        margin-left: 10px;
    }
`