import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { ActivityStatusFilterICP } from 'modules/maneuver/components/maneuver-list-filters/inner/ActivityStatusFilterICP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import moment from 'moment-timezone'
import { ISearchManeuverRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchManeuverRequestDTO'
import { ActivityStatusEnum } from 'modules/maneuver/enums/ActivityStatusEnum'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import _ from 'lodash'
import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import { RecipeTypeSelectorCP } from '../../recipe-type-selector/RecipeTypeSelectorCP'

const DEFAULT_FILTERS: ISearchManeuverRequestDTO = {
    status: ActivityStatusEnum.IN_PROGRESS,
    title: undefined,
    code: undefined,
    propertyCode: undefined,
    fieldCode: undefined,
    type: undefined,
    season: moment().year(),
    page: 1,
    itemsPerPage: 12
}
interface IManeuverFiltersDrawerICPProps {
    show: boolean
    onClose: () => void
    setFilters: (filters?: ISearchManeuverRequestDTO) => void
    filters?: ISearchManeuverRequestDTO
}

export function ManeuverFiltersDrawerICP(props: IManeuverFiltersDrawerICPProps): JSX.Element {

    const [filters, setFilters] = useState<ISearchManeuverRequestDTO | undefined>(props.filters)
    useEffect(init, [props.filters])

    function init(): void {
        if (!!props.filters && props.show)
            setFilters(props.filters)
    }

    function resetFilters(): void {

        setFilters(DEFAULT_FILTERS)
        props.setFilters(DEFAULT_FILTERS)
        props.onClose()
    }

    function onFilter(): void {
        props.setFilters(filters)
        props.onClose()
    }

    return (

        <DrawerCP
            title={'Todos os filtros'}
            visible={props.show}
            height={'60%'}
            placement={'right'}
            onClose={() => {
                props.onClose()
            }}
            footer={
                <ButtonsWrapperSCP>
                    <ButtonCP
                        type={'ghost'}
                        onClick={resetFilters}
                    >
                        Limpar
                    </ButtonCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={onFilter}
                    >
                        Filtrar
                    </ButtonCP>
                </ButtonsWrapperSCP>
            }
        >
            <FieldsWrapperSCP>
                <GridWrapperSCP>
                    <SeasonSelectorCP
                        label={'Safra'}
                        onChange={(season) => setFilters({ ...filters, season })}
                        value={filters?.season}
                    />
                    <InputCP
                        placeholder={'Cód.'}
                        label={'Cód.'}
                        onChange={(code) => setFilters({ ...filters, code })}
                        value={filters?.code}
                    />
                    <InputCP
                        placeholder={'Título'}
                        label={'Título'}
                        onChange={(title) => setFilters({ ...filters, title })}
                        value={filters?.title}
                    />

                    <RecipeTypeSelectorCP
                        label={'Tipo'}
                        value={filters?.type}
                        onSelect={(type) => setFilters({ ...filters, type })}
                    />

                    <InputCP
                        placeholder={'Colaborador'}
                        label={'Colaborador'}
                        onChange={_.debounce((personRes) => setFilters({ ...filters, personRes }), 750)}
                        value={filters?.personRes}
                    />

                    <PropertyFieldFieldsCP
                        onChangeField={(fieldCode) => setFilters({ ...filters, fieldCode })}
                        onChangeProperty={(propertyCode) => setFilters({ ...filters, propertyCode })}
                        fieldCode={filters?.fieldCode}
                        propertyCode={filters?.propertyCode}
                    />

                    <StatusSCP>
                        <ActivityStatusFilterICP
                            onChangeStatus={(status) => setFilters({ ...filters, status })}
                            status={filters?.status}
                        />
                    </StatusSCP>

                </GridWrapperSCP>
            </FieldsWrapperSCP>

        </DrawerCP>

    )
}

const WrapperSCP = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 3px;
`

const FieldsWrapperSCP = styled.div`


`
const ButtonsWrapperSCP = styled.div`
    display: flex;
    justify-content: space-between;

`

const GridWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 10px;
    margin-top: 10px;
`

const StatusSCP = styled.div`
    margin: 15px 0;
   

`