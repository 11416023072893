import { faEye } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { MobileListItemICP } from 'common/components/list/inner/MobileListItemICP'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { MaskUtils } from 'common/utils/MaskUtils'
import { ReportRoutes } from 'modules/report/ReportRoutes'
import { IFinancialReportByFieldResDTO } from 'modules/report/services/dtos/responses/IFinancialReportByFieldResDTO'
import { IFinancialReportResDTO } from 'modules/report/services/dtos/responses/IFinancialReportResDTO'
import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

interface IFinancialFieldReportItemICPProps {
    key: string
    reportData: IFinancialReportByFieldResDTO
    loading: boolean
}

export function FinancialFieldReportItemICP(props: IFinancialFieldReportItemICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const history = useHistory()

    if (screenSize.smd) {
        return (
            <MobileListItemICP
                loading={props.loading}
                key={props.key}
                data={[
                    { label: 'Talhão', value: `${props.reportData.field.name}` },
                    { label: 'Custo da saca', value: `${props.reportData.costPerBag ? MaskUtils.applyCurrencyMask(props.reportData.costPerBag) : '-'}` },
                    { label: 'Valor de venda', value: `${props.reportData.averageValuePerBag ? MaskUtils.applyCurrencyMask(props.reportData.averageValuePerBag) : '-'}` },
                    { label: 'Valor de venda', value: props.reportData.totalCost ? `${MaskUtils.applyCurrencyMask(props.reportData.totalCost)}` : '-' },
                    { label: 'Faturamento total', value: props.reportData.totalIncome ? `${MaskUtils.applyCurrencyMask(props.reportData.totalIncome)}` : '-' },
                ]}
                actions={[
                    {
                        action: () => history.push(`${ReportRoutes.FIELD_WITHOUT_PARAM}/${props.reportData.field.code}`),
                        icon: <FontAwsomeIconCP icon={faEye} />,
                        label: 'Detalhes'
                    }
                ]}

            />
        )
    }

    return (
        <ListItemICP
            key={props.key}
            isMobile={screenSize.smd}
            loading={props.loading}
            style={{ padding: '0', margin: '0' }}
            customSkeleton={<GenericRowSkeletonICP rows={1} columns={8} />}
        >
            <WrapperSCP>
                <MobileLabelsSCP>
                    <TextCP text={'Talhão'} />
                    <TextCP text={'Custo da saca'} />
                    <TextCP text={'Valor de venda'} />
                    <TextCP text={'Valor de venda'} />
                    <TextCP text={'Faturamento total'} />

                </MobileLabelsSCP>
                <ValuesSCP>
                    <TextCP text={`${props.reportData.field.name}`} style={{ fontWeight: 500 }} />
                    <TextCP text={`${props.reportData.costPerBag ? MaskUtils.applyCurrencyMask(props.reportData.costPerBag) : '-'}`} />
                    <TextCP text={`${props.reportData.averageValuePerBag ? MaskUtils.applyCurrencyMask(props.reportData.averageValuePerBag) : '-'}`} />

                    <TextCP text={props.reportData.totalCost ? `${MaskUtils.applyCurrencyMask(props.reportData.totalCost)}` : '-'} />
                    <TextCP text={props.reportData.totalIncome ? `${MaskUtils.applyCurrencyMask(props.reportData.totalIncome)}` : '-'} />
                    <ButtonCP
                        wrapperStyle={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            cursor: 'pointer',
                        }}
                        icon={<FontAwsomeIconCP icon={faEye} />}
                        onClick={() => history.push(`${ReportRoutes.FIELD_WITHOUT_PARAM}/${props.reportData.field.code}`)}
                        type={'primary'}
                    />
                </ValuesSCP>

            </WrapperSCP>
        </ListItemICP>
    )
}

const WrapperSCP = styled.div`   
    position:relative;
    display: flex;  
    
    @media(max-width: 768px){
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 5px;
          column-gap: 30px;
          padding-bottom: 60px;
          margin-bottom: 2em;
          border-bottom: 1px solid ${props => props.theme.lightGray};
       
    }
`

const ValuesSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    margin: 0;
    padding: 10px;
    column-gap: 10px;
    border-bottom: 1px solid ${props => props.theme.lightGray};
    align-items: center;
    justify-content: center;
    .ant-list-bordered.ant-list-lg .ant-list-item{
        padding: 0!important;
    }
    width: 100%;
    .ant-typography {
        font-size: 12px;
    }
    .ant-btn span svg {
        width: auto;
        margin: 0;
    }

    @media(max-width: 768px){
        grid-template-columns: 1fr;    
        row-gap: 5px;    
        border-bottom: none;
    }
`
const MobileLabelsSCP = styled.div`
    display: none;
    .ant-typography{
        font-weight: 600;
    }
    @media(max-width: 768px){
        display: grid;
        padding: 10px 0;
        grid-template-columns: 1fr;
        row-gap: 5px;   
        width: 100%;
    }
`

const ActionsSCP = styled.div`
    display: flex;
    column-gap: 5px;
    @media(max-width: 767px){
        position: absolute;
        bottom: 15px;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        .ant-btn{
            width: 100%;
        }
    }
`
