export const ThemeUtils = {
    formatedTheme(_theme: any): any {
        const varNames = Object.keys(_theme)
        const lessVars = {}
        varNames.forEach(name => {
            const nameInKebabCase = name.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase() // transforma o nome da variável para kebab-case
            lessVars[`@${nameInKebabCase}`] = _theme[name]
        })
        return lessVars
    },
}