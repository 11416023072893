import React, { useState } from 'react'
import { PrivateUserRouter } from '../../routers/PrivateUserRouter'
import styled from 'styled-components'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { MainMenuSiderCP } from 'modules/app/components/main-menu-sider/MainMenuSiderCP'
import { MainHeaderCP } from 'modules/app/components/main-header/MainHeaderCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { ResponsiveMainMenuSiderCP } from 'modules/app/components/main-menu-sider/ResponsiveMainMenuSiderCP'

export function UserMainLayoutCP(): JSX.Element {
    const screenSize = useScreenSize()
    const [shouldCollapseMobileSider, setShouldCollapseMobileSider] = useState<boolean>(false)

    return (
        <WrapperSCP>
            <LayoutCP
                mainLayout={true}
                header={<MainHeaderCP isMobile={screenSize.xs} onCollapseMenu={() => setShouldCollapseMobileSider(!shouldCollapseMobileSider)} />}
                content={
                    <MainContentSCP>
                        <LayoutCP
                            sider={
                                <MainMenuSiderCP isMobile={screenSize.xs} />
                            }
                            content={
                                <ContentCP>
                                    <PrivateUserRouter />
                                </ContentCP>
                            }
                        />
                    </MainContentSCP>
                }
                footer={
                    <FooterSCP>Powered by Flowins</FooterSCP>
                }
            />
            <ResponsiveMainMenuSiderCP
                show={screenSize.xs}
                visible={shouldCollapseMobileSider}
                onClose={() => setShouldCollapseMobileSider(false)}
                shouldCollapse={setShouldCollapseMobileSider}
            />
        </WrapperSCP>
    )
}

const MainContentSCP = styled.div`
    height: 100%;
`
const WrapperSCP = styled.div`
    .layout_main > .ant-layout > .ant-layout {
        padding: 20px 40px;
        z-index: 1;
        max-height: 100vh;
    
        @media(max-width: 768px){
            padding:10px 0 0 0 ;          
        }
    }
`

const FooterSCP = styled.div`
    font-size: 11px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #cacaca;

`