import React from 'react'
import { IListItemPropertyResponseDTO } from 'modules/property/services/dtos/responses/IListItemPropertyResponseDTO'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { PropertyCardContentICP } from 'modules/property/components/property-list/inner/PropertyCardContentICP'
import { PropertyCardFooterICP } from 'modules/property/components/property-list/inner/PropertyCardFooterICP'
import { PropertyCardHeaderICP } from 'modules/property/components/property-list/inner/PropertyCardHeaderICP'
import { PropertyCardSkeletonICP } from 'modules/property/components/property-list/inner/PropertyCardSkeletonICP'

interface IPropertyCardICPProps{
    item: IListItemPropertyResponseDTO
    reloadList: () => void
    loading: boolean
}

export function PropertyCardICP(props: IPropertyCardICPProps): JSX.Element {
    const screenSize = useScreenSize()
    return (
        <ListItemICP
            isMobile={screenSize.smd}
            key={props.item.code}
            title={<PropertyCardHeaderICP imageUrl={props.item.mainPicture} name={props.item.name} region={props.item.region} />}
            loading={props.loading}
            customSkeleton={<PropertyCardSkeletonICP />}
        >

            <PropertyCardContentICP
                description={props.item.description ?? ''}
                totalArea={props.item.totalArea}
                dedicatedArea={props.item.dedicatedArea}
                totalFields={props.item.totalFields}
            />
            <PropertyCardFooterICP code={props.item.code} reloadList={props.reloadList} />
        </ListItemICP>
    )
}
