import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { HarvestGeneralReportCP } from 'modules/harvest-planning/components/harvest-general-report/HarvestGeneralReportCP'
import { ISearchHarvestPlanningReqDTO } from 'modules/harvest-planning/services/dtos/request/ISearchHarvestPlanningReqDTO'
import React from 'react'
import styled from 'styled-components'

interface IHarvestGeneralReportDrawerCPProps {
    show: boolean
    onClose: () => void
    filters?: ISearchHarvestPlanningReqDTO
}

export function HarvestGeneralReportDrawerCP(props: IHarvestGeneralReportDrawerCPProps): JSX.Element {

    return (
        <DrawerCP
            title={'Resultados'}
            visible={props.show}
            width={'35%'}
            onClose={props.onClose}
            footer={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        wrapperStyle={{ width: '100%' }}
                        onClick={props.onClose}
                    >
                        Fechar
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        >

            <WrapperSCP>
                <HarvestGeneralReportCP filters={props.filters} />
            </WrapperSCP>

        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: center;
    .ant-btn {
    width: 100%;
    justify-content: center;
}
`