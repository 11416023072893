import { TextCP } from 'common/components/text/TextCP'

import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { MaskUtils } from 'common/utils/MaskUtils'

interface IHarvestStatisticsICPProps {
    estimatedTotalLiters: string
    totalLiters: number
    totalLitersProcessed: number
    totalBags: number
    revenue: number
    benefitedLiters: number
    realStartDate?: string
    endDate: string
    totalEntries: number
}

export function HarvestStatisticsICP(props: IHarvestStatisticsICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const valueStyle = { fontSize: `${screenSize.smd ? '15px' : '18px'}`, fontWeight: '600', lineHeight: '22px' }
    const remainingDays = moment(props.endDate).diff(moment(), 'days')
    const spentDays = moment().diff(moment(props.realStartDate), 'days')

    return (
        <WrapperSCP>

            {/* <InfoSCP>
                <TextCP text={`${props.totalEntries} registros encontrados`} />
                <TextCP text={`${props.realStartDate ? `Primeiro registro feito em ${LogbookUtils.getDateText(props.realStartDate)}` : ''} `} />
            </InfoSCP> */}

            <StatisticsSCP>
                <ItemSCP>
                    <TextCP text={`${MaskUtils.applyNumberMask(props.totalLiters)}L`} style={valueStyle} />
                    <TextCP text={'colhidos'} />
                </ItemSCP>
                <ItemSCP>
                    <TextCP text={`${MaskUtils.applyNumberMask(props.totalLitersProcessed)}L`} style={valueStyle} />
                    <TextCP text={'processados'} />
                </ItemSCP>
                <ItemSCP>
                    <TextCP text={`${props.estimatedTotalLiters}L`} style={valueStyle} />
                    <TextCP text={'estimados'} />
                </ItemSCP>

                <ItemSCP>
                    <TextCP text={`${MaskUtils.applyNumberMask(props.revenue)}L`} style={valueStyle} />
                    <TextCP text={'rendimento'} />
                </ItemSCP>
                <ItemSCP>
                    <TextCP text={`${props.totalBags}`} style={valueStyle} />
                    <TextCP text={'sacas'} />
                </ItemSCP>
                <ItemSCP>
                    <TextCP text={`${MaskUtils.applyNumberMask(props.benefitedLiters)}L`} style={valueStyle} />
                    <TextCP text={'beneficiados'} />
                </ItemSCP>

            </StatisticsSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    width: 100%;
   
`

const InfoSCP = styled.div`
    display: flex;
    flex-direction: column;
    .ant-typography{
        font-size: 12px;
        color: ${props => props.theme.gray}
    }
`

const StatisticsSCP = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    row-gap: 5px;
`

const ItemSCP = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    align-items: flex-end;
    justify-content:  flex-end;;
    .ant-typography{
        color: ${props => props.theme.primaryColor}
    }
    @media(max-width: 768px){
        margin: 0 16px;
        align-items: center;
    }

`