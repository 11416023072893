import { InputCP } from 'common/components/form-fields/input/InputCP'
import { TextCP } from 'common/components/text/TextCP'
import { IRecipeFormulaItem } from 'modules/maneuver/interfaces/IRecipeFormulaItem'
import { IProductUsageReqDTO } from 'modules/maneuver/services/dtos/requests/IProductUsageReqDTO'
import React, { useEffect, useState } from 'react'
import { ProductUtils } from 'modules/maneuver/utils/ProductUtils'
import styled from 'styled-components'
import _ from 'lodash'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { LogbookFormValidator } from 'modules/logbook/components/logbook-form/inner/LogbookFormValidator'
import { ProductUsageFormValidator } from 'modules/logbook/components/product-usage/inner/ProductUsageFormValidator'
import { MaskUtils } from '../../../../common/utils/MaskUtils'

interface IProductUsageFormCPProps {
    recipeFormula: IRecipeFormulaItem[]
    logbookCode?: number
    maneuverCode: number
    formStateManager: IFormStateManager<LogbookFormValidator>
}

/**
 * COMPONENTE
 * Agrupa os campos relacionados ao gastos de produtos de um lançamento de instrução
 */
export function ProductUsageFormCP(props: IProductUsageFormCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<ProductUsageFormValidator>(new ProductUsageFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const [productUsage, setProductUsage] = useState<IProductUsageReqDTO[]>([])
    const [initialValues, setInititialValues] = useState<IProductUsageReqDTO[]>([])

    useEffect(init, [])

    function init(): void {

        if (!props.recipeFormula.length) {
            return
        }

        const currentValues: ProductUsageFormValidator[] | undefined = props.formStateManager.getFieldValue('productUsages')
     
        if (!!currentValues?.length) {
            setInititialValues(currentValues)
            setProductUsage(_.map(currentValues, value => ({
                code: value.code,
                productCode: value.productCode,
                quantity: value.quantity,
            })))

            return
        }

        setProductUsage(_.map(props.recipeFormula, item => ({
            productCode: item.productCode,
            quantity: 0,
        })))
    }

    function onChangeUsageValue(quantity: string, productCode: number): void {

        const newUsageValues = productUsage.map(item => {

            if (item.productCode === productCode) {
                return {
                    code: item.code,
                    productCode,
                    quantity: +quantity,
                }
            }
            return item
        })

        setProductUsage(newUsageValues)

        props.formStateManager.changeFieldValue('productUsages', newUsageValues)

    }

    function getCurrentUsageValue(productCode: number): number {

        const usage = productUsage.find(s => s.productCode === productCode)?.quantity ?? 0
        return (usage ?? 0)
    }

    function getSavedUsageValue(pCode): number {
        return initialValues.find(p => p.productCode === pCode)?.quantity ?? 0
    }

    return (
        <WrapperSCP>

            {
                props.recipeFormula.map(formula => (

                    <ItemSCP key={formula.productCode}>
                        <TextCP text={`${formula.productName}: `} />
                        <InputCP
                            placeholder={'Quantidade gasta'}
                            icon={<span>{ProductUtils.getUnityLabel(formula.isLiquid)}</span>}
                            type={'number'}
                            value={productUsage.find(s => s.productCode === formula.productCode)?.quantity ?? 0}
                            onChange={(value) => onChangeUsageValue(value, formula.productCode)}

                        />
                        <QuantityInfoSCP>
                            <FontAwsomeIconCP icon={faInfoCircle} size={'2x'} />
                            <InfoSCP>
                                <TextCP
                                    text={`Em estoque: ${MaskUtils.applyNumberMask(formula.remainingQuantity!)}${ProductUtils.getUnityLabel(formula.isLiquid)}`}
                                />
                                {
                                    !!initialValues && !!initialValues.length ?
                                        <>
                                            <TextCP
                                                text={`Gasto prévio: ${getSavedUsageValue(formula.productCode)}${ProductUtils.getUnityLabel(formula.isLiquid)}`}
                                            />
                                            <TextCP
                                                text={`Restante após aplicação: ${ProductUtils.calculateRemainingQtdAfterUpdate(formula.remainingQuantity!, getSavedUsageValue(formula.productCode), getCurrentUsageValue(formula.productCode), formula.isLiquid)}`}
                                            />
                                        </>
                                        :

                                        <TextCP
                                            text={`Restante após aplicação: ${ProductUtils.calculateRemainingQtdAfterAplication(formula.remainingQuantity!, getCurrentUsageValue(formula.productCode), formula.isLiquid)}`}
                                        />
                                }
                            </InfoSCP>
                        </QuantityInfoSCP>
                    </ItemSCP>
                ))
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

`

const QuantityInfoSCP = styled.div`
  display: grid;
  grid-template-columns: .2fr 1fr;
  margin-bottom: 3px;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  position: relative;
  border: 1px solid ${props => props.theme.primaryColor};

    svg {
        color: ${props => props.theme.primaryColor};  
    }
  .ant-typography {
    font-size: 12px;
  }
`
const InfoSCP = styled.div`

`
const ItemSCP = styled.div`
  display: grid;
  grid-template-columns: .4fr .4fr 1fr;
  column-gap: 10px;
  align-items: center;

  @media (max-width: 767px) {
    margin: 18px 0;
    grid-template-columns: 1fr;
  }
`