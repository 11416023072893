import React, { useCallback, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ManeuverMainScreen } from 'modules/maneuver/screens/ManeuverMainScreen'
import { ProductScreen } from 'modules/maneuver/screens/ProductScreen'
import { RecipeScreen } from 'modules/maneuver/screens/RecipeScreen'
import { EquipmentScreen } from 'modules/maneuver/screens/EquipmentScreen'
import { LogbookScreen } from 'modules/maneuver/screens/LogbookScreen'
import { VehicleScreen } from 'modules/maneuver/screens/VehicleScreen'
import { ManeuverEntityTypeEnum } from 'modules/maneuver/enums/ManeuverEntityTypeEnum'
import { MaintenanceScreen } from 'modules/maneuver/screens/MaintenanceScreen'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { SystemModulesEnum } from 'modules/auth/enums/SystemModulesEnum'

ManeuverRoutes.ROOT = '/manejo'
ManeuverRoutes.LOGBOOK_WITHOUT_PARAM = `${ManeuverRoutes.ROOT}/logbook`
ManeuverRoutes.LOGBOOK = `${ManeuverRoutes.ROOT}/logbook/:code`
ManeuverRoutes.INSTRUCTIONS = `${ManeuverRoutes.ROOT}/instrucoes`
ManeuverRoutes.EQUIPMENTS = `${ManeuverRoutes.ROOT}/equipamentos`
ManeuverRoutes.VEHICLES = `${ManeuverRoutes.ROOT}/veiculos`
ManeuverRoutes.PRODUCTS = `${ManeuverRoutes.ROOT}/estoque`
ManeuverRoutes.MAINTENANCE = `${ManeuverRoutes.ROOT}/manutencoes/:code`
export interface IItemDetails {
    type: ManeuverEntityTypeEnum
    code: number
    payload?: any
}
interface IManeuverContext {
    reload?: number
    toggleReload: () => void
    itemDetails?: IItemDetails
    toggleItemDetail: (dt?: IItemDetails) => void
}

export const ManeuverContext = React.createContext<IManeuverContext>({
    toggleReload: () => { return },
    toggleItemDetail: (dt?: IItemDetails) => { return dt }
})

export function ManeuverRoutes(): JSX.Element {
    const toggleItemDetail = useCallback((itemDetails?: IItemDetails) => setState((st: IManeuverContext) => ({ ...st, itemDetails })), [])
    const toggleReload = useCallback(() => setState((st: IManeuverContext) => ({ ...st, reload: Math.random() })), [])
    const [state, setState] = useState<IManeuverContext>({
        reload: 0,
        toggleItemDetail,
        toggleReload
    })

    return (
        <AccessControlCP module={SystemModulesEnum.MANEUVER}>
            <ManeuverContext.Provider value={state}>
                <Switch>
                    <Route path={ManeuverRoutes.ROOT} exact={true} component={ManeuverMainScreen} />
                    <Route path={ManeuverRoutes.LOGBOOK} component={LogbookScreen} />
                    <Route path={ManeuverRoutes.INSTRUCTIONS} component={RecipeScreen} />
                    <Route path={ManeuverRoutes.EQUIPMENTS} component={EquipmentScreen} />
                    <Route path={ManeuverRoutes.VEHICLES} component={VehicleScreen} />
                    <Route path={ManeuverRoutes.PRODUCTS} component={ProductScreen} />
                    <Route path={ManeuverRoutes.MAINTENANCE} component={MaintenanceScreen} />
                </Switch>
            </ManeuverContext.Provider>
        </AccessControlCP>
    )
}