import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { ISearchHarvestRequestDTO } from 'modules/harvest/services/dtos/requests/ISearchHarvestRequestDTO'
import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface IHarvestMobileFiltersCPProps {
    show: boolean
    onClose: () => void
    setFilters: (filters: ISearchHarvestRequestDTO) => void
    filters?: ISearchHarvestRequestDTO
}

export function HarvestMobileFiltersCP(props: IHarvestMobileFiltersCPProps): JSX.Element {
    const screenSize = useScreenSize()

    const [propertyCode, setPropertyCode] = useState<number>()
    const [fieldCode, setFieldCode] = useState<number>()
    const [season, setSeason] = useState<number>()

    useEffect(setFilterValues, [props.filters])

    function setFilterValues(): void {
        if (!props.filters)
            return
        setPropertyCode(props.filters.propertyCode)
        setFieldCode(props.filters.fieldCode)
        setSeason(props.filters.season)
    }

    return (
        <DrawerCP
            title={'Filtrar colheitas'}
            visible={props.show}
            height={'60%'}
            placement={'bottom'}
            onClose={props.onClose}

        >
            <WrapperSCP>
                <PropertyFieldFieldsCP
                    onChangeField={setFieldCode}
                    fieldCode={fieldCode}
                    propertyCode={propertyCode}
                    onChangeProperty={setPropertyCode}
                    onClear={() => {
                        setPropertyCode(undefined)
                        setFieldCode(undefined)
                    }}
                />
                <SeasonSelectorCP
                    label={'Safra'}
                    onChange={setSeason}
                />
                <ButtonWrapperSCP>
                    <ButtonCP
                        size={screenSize.smd ? 'large' : 'middle'}
                        type={'primary'}
                        wrapperStyle={{ width: '100%' }}
                        onClick={() => {
                            props.setFilters({ propertyCode, season, fieldCode })
                            props.onClose()
                        }}
                    >
                        Filtrar
                    </ButtonCP>
                </ButtonWrapperSCP>
            </WrapperSCP>

        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
`

const ButtonWrapperSCP = styled.div`
   margin-top: 40px;
    display: flex;
    justify-content: center;
    .ant-btn {
        width: 100%;
        justify-content: center;
    }
`