import { faCheckDouble, faList } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { CardFooterICP } from 'common/components/list/inner/CardFooterICP'
import { CardHeaderICP } from 'common/components/list/inner/CardHeaderICP'
import { CardListItemICP } from 'common/components/list/inner/CardListItemICP'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { BatchCardSkeletonICP } from 'modules/batch/components/batch-list/inner/BatchCardSkeletonICP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'

import { CostumizedThemeConfig } from 'config/CostumizedThemeConfig'

import { HarvestRoutes } from 'modules/harvest/HarvestRoutes'
import { HarvestPlanningRequests } from 'modules/harvest-planning/services/HarvestPlanningRequests'
import { useHistory } from 'react-router-dom'
import { HarvestPlanningCardContentICP } from 'modules/harvest-planning/components/harvest-planning-list/inner/HarvestPlanningCardContentICP'
import { ISearchHarvestPlanningResDTO } from 'modules/harvest-planning/services/dtos/response/ISearchHarvestPlanningResDTO'
import { HavestPlanningDrawerCP } from 'modules/harvest-planning/components/harvest-planning-drawer/HarvestPlanningDrawerCP'

interface IHarvestPlanningCardICPProps {
    item: ISearchHarvestPlanningResDTO
    loading: boolean
}

export function HarvestPlanningCardICP(props: IHarvestPlanningCardICPProps): JSX.Element {
    const [harvestPlanningDrawerVisible, setHarvestPlanningDrawerVisible] = useState<boolean>(false)
    const history = useHistory()

    const deleteReq = useRequest(HarvestPlanningRequests.delete(props.item.code))
    useEffect(onDeleteReqChange, [deleteReq.awaiting])

    function onDeleteReqChange(): void {

        if (deleteReq.awaiting || !deleteReq.tried)
            return

        if (!deleteReq.success) {
            if (deleteReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', deleteReq.returnData, deleteReq.error)
            return RequestUtils.showDefaultErrorNotification(deleteReq.error, 'Erro remover item!')
        }
        ListActions.mustUpdate()
    }

    if (!props.item)
        return <BatchCardSkeletonICP />

    return (
        <WrapperSCP>

            <CardListItemICP
                key={props.item.code}
                item={props.item}
                loading={props.loading}
                customSkeleton={<BatchCardSkeletonICP />}
                header={
                    <CardHeaderICP
                        highlight={`#${props.item.code}`}
                        title={`${props.item.field ? props.item.field.name : ''} (${props.item.field ? props.item.field.totalArea : ''} ha/ ${props.item.field ? props.item.field.treeCount : ''} pés)`}
                        categoryTitle={`Safra: ${props.item.season}`}
                        actions={!!props.item.realEndDate ? <FontAwsomeIconCP icon={faCheckDouble} color={CostumizedThemeConfig.successColor} /> : [
                            {
                                label: 'Editar',
                                action: (code) => setHarvestPlanningDrawerVisible(true)
                            },
                            // {
                            //     label: 'Gerar lote',
                            //     action: (code) => console.log(true)
                            // }
                        ]}
                        itemCode={props.item.code}
                    />
                }
                content={
                    <HarvestPlanningCardContentICP
                        startDate={props.item.plannedStartDate}
                        endDate={props.item.plannedEndDate}
                        revenue={props.item.revenue}
                        totalBags={props.item.totalBags}
                        benefitedLiters={props.item.benefitedLiters}
                        realEndDate={props.item.realEndDate}
                        realStartDate={props.item.realStartDate}
                        estimatedTotalLiters={props.item.estimatedLiters}
                        totalLiters={props.item.totalLiters}
                        lastLoogbookItem={props.item.lastLogbookEntry}
                        treeCount={props.item.field ? props.item.field.treeCount : undefined}
                    />
                }
                footer={
                    <CardFooterICP
                        onDelete={() => deleteReq.runRequest()}
                        deleteLoading={false}
                        confirmLabel={'Tem certeza que deseja remover este item?'}
                    >
                        <ActionsSCP>
                            <ButtonCP
                                type={'primary'}
                                style={{ borderRadius: '5px' }}
                                onClick={() => history.push(`${HarvestRoutes.ROOT}/logbook/${props.item.code}`)}
                                icon={<FontAwsomeIconCP icon={faList} style={{ margin: '0 5px' }} />}
                            >
                                Ver diário de bordo
                            </ButtonCP>
                        </ActionsSCP>
                    </CardFooterICP>
                }
            />
            <HavestPlanningDrawerCP
                show={harvestPlanningDrawerVisible}
                harvestPlanningCode={props.item.code}
                onCancel={() => setHarvestPlanningDrawerVisible(false)}
                onSuccess={() => setHarvestPlanningDrawerVisible(false)}
            />
        </WrapperSCP>
    )
}

const SubTitleSCP = styled.div`
    display: grid;
    grid-template-columns: .6fr 1fr;
    width: 100%;
    .ant-typography{
        color: ${props => props.theme.gray};
        font-size: 12px;
    }

`
const ActionsSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const WrapperSCP = styled.div`
    min-heigth: 367px;
`

const POWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .ant-btn{
        margin-left: 10px;
        margin-bottom: 5px;
        min-width: 150px;
    }
    
`