import { GenericRowSkeletonICP } from 'common/components/list/inner/GenericRowSkeletonICP'
import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'

interface IWidgetGenericValueICPProps {
    value?: string | number
    label: string
    loading?: boolean
}

export function WidgetGenericValueICP(props: IWidgetGenericValueICPProps): JSX.Element {

    if (props.loading)
        return <WrapperSCP><GenericRowSkeletonICP columns={1} rows={1} /></WrapperSCP>

    return (
        <WrapperSCP>
            {
                props.value !== null && props.value !== undefined ?
                    <>
                        <TextCP text={`${props.value}`} style={{ fontSize: '23px', fontWeight: 'bolder' }} />
                        <TextCP text={props.label} style={{ fontSize: '20px', fontWeight: 'bolder' }} />
                    </>

                    : <TextCP text={'sem resultados'} style={{ fontSize: '15px', fontWeight: 600 }} />

            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
   
    display: flex;
            align-items: baseline;
            column-gap: 8px;
            `
