import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-helper/RequestManagerTypes'
import { SystemConfig } from 'config/SystemConfig'
import { ISaveFieldRequestDTO } from './dtos/requests/ISaveFieldRequestDTO'

export class FieldRequests {
    private constructor() { }

    static search = (propertyCode: number): RequestConfigTP => ({
        url: `${FieldRequests.baseApiUrl(propertyCode)}`,
        method: HttpMethodEnum.GET,
    })

    static get = (propertyCode: number, code: number): RequestConfigTP => ({
        url: `${FieldRequests.baseApiUrl(propertyCode)}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static save = (propertyCode: number, dto: ISaveFieldRequestDTO): RequestConfigTP<ISaveFieldRequestDTO> => ({
        url: `${FieldRequests.baseApiUrl(propertyCode)}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static update = (propertyCode: number, code: number, dto: ISaveFieldRequestDTO): RequestConfigTP<ISaveFieldRequestDTO> => ({
        url: `${FieldRequests.baseApiUrl(propertyCode)}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static delete = (propertyCode: number, code: number): RequestConfigTP => ({
        url: `${FieldRequests.baseApiUrl(propertyCode)}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    private static readonly baseApiUrl = (propertyCode: number): string => `${SystemConfig.getInstance().gfApiBaseUrl}/properties/${propertyCode}/fields`
}
