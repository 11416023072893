import { PhotoGalleryCP } from 'common/components/custom-photo-gallery/PhotoGalleryCP'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { LoadingBoxCP } from 'modules/batch-presentation/components/loading-box/LoadingBoxCP'
import { BatchPresentationRequests } from 'modules/batch-presentation/services/BatchPresentationRequests'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
type RouteParamsTP = { batchCode: string }

interface IBatchPhotoGalleryCPProps {
}

export function BatchPhotoGalleryCP(props: IBatchPhotoGalleryCPProps): JSX.Element {
    const routeParams = useParams<RouteParamsTP>()
    const [pictures, setPictures] = useState<string[]>()

    const [batchCode, setBatchCode] = useState<number>()
    useEffect(() => setBatchCode(+routeParams.batchCode), [routeParams])

    const getInfoReq = useRequest<string[]>()
    useEffect(onRequestChange, [getInfoReq.awaiting])

    useEffect(getDetails, [batchCode])

    function getDetails(): void {
        if (!!batchCode)
            getInfoReq.runRequest(BatchPresentationRequests.getAllPictures(batchCode))
    }

    function onRequestChange(): void {

        if (getInfoReq.awaiting || !getInfoReq.tried)
            return

        if (!getInfoReq.success) {
            if (getInfoReq.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', getInfoReq.returnData, getInfoReq.error)
            return RequestUtils.showDefaultErrorNotification(getInfoReq.error, 'Some error occurred. Please, try to reload the page.')
        }

        setPictures(getInfoReq.returnData)
    }

    if (!pictures || getInfoReq.awaiting) {
        return (
            <div></div>
        )
    }
    return (
        <WrapperSCP>
            <TextCP
                text={'allPictures'}
                translationFiles={'batch'}
                translationNameSpace={'batch'}
                style={{ fontSize: '23px', fontWeight: 600, marginBottom: '1em', marginLeft: '3em' }}
            />

            <PhotoGalleryCP
                images={
                    pictures.map(pic => ({
                        url: pic,
                        previewConfig: {
                            mask: <MaskWrapperSCP />
                        }
                    }))
                }
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
        padding-top: 4em;
        background: white;
`
const MaskWrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    opacity: .5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
`