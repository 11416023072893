import { PaginationConfig } from 'antd/lib/pagination'
import { CardCP } from 'common/components/card/CardCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { TextCP } from 'common/components/text/TextCP'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { HarvestCardCP } from 'modules/harvest/components/harvest-card/HarvestCardCP'
import { ISearchHarvestRequestDTO } from 'modules/harvest/services/dtos/requests/ISearchHarvestRequestDTO'
import { IHarvestResponseDTO } from 'modules/harvest/services/dtos/responses/IHarvestResponseDTO'
import { HarvestRequests } from 'modules/harvest/services/HarvestRequests'
import { PropertyFieldFieldsCP } from 'modules/property/components/property-field-fields/PropertyFieldFieldsCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { useScreenSize } from 'common/hooks/UseScreenSize'
import { SeasonSelectorCP } from 'modules/season/components/season-selector/SeasonSelectorCP'
import { ListActions } from 'common/components/list/inner/ListActions'
import { MaskUtils } from 'common/utils/MaskUtils'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icon/FontAwsomeIconCP'
import { HarvestMobileFiltersCP } from 'modules/batch/components/batch-generation-drawer/inner/HarvestMobileFiltersICP'
import moment from 'moment-timezone'
const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchHarvestRequestDTO = {
    season: moment().year(),
    itemsPerPage: DEFAULT_PAGINATION.pageSize,
    page: 1
}
interface IHarvestSelectionStepICPProps {
    setSelectedHarvests: (hvts: IHarvestResponseDTO[]) => void
    selectedHarvests: IHarvestResponseDTO[]
}
export function HarvestSelectionStepICP(props: IHarvestSelectionStepICPProps): JSX.Element {
    const screenSize = useScreenSize()
    const selectedHvtCodes: number[] = useSelector((state: IReduxState) => _.get(state, 'listState.selectedItems'))

    const [totalLiters, setTotalLiters] = useState<number>(0)
    const [selectedFieldsNames, setSelectedFieldsNames] = useState<string>('--')
    const [harvestList, setHarvestList] = useState<IHarvestResponseDTO[]>([])

    const [filterDrawerVisible, setFilterDrawerVisible] = useState<boolean>(false)

    const [filters, setFilters] = useState<ISearchHarvestRequestDTO>(DEFAULT_FILTERS)
    const [pagination, setPagination] = useState<PaginationConfig>(DEFAULT_PAGINATION)

    const request = useRequest<IGenericListResponseDTO<IHarvestResponseDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(searchHarvests, [filters])
    useEffect(defineHarvests, [selectedHvtCodes])
    useEffect(definedTotalLiters, [props.selectedHarvests])

    function searchHarvests(): void {
        if (!!filters)
            request.runRequest(HarvestRequests.search(filters))
    }

    function onRequestChange(): void {

        if (request.awaiting || !request.tried)
            return

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(request.error, 'Erro ao buscar colheitas!')
        }
        setPagination({
            ...pagination,
            total: request.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                setFilters({ ...filters, page, itemsPerPage: pageSize })
            }
        })
        setHarvestList(request.returnData.list)
    }

    function defineHarvests(): void {
        if (!selectedHvtCodes)
            return

        const selectedHvts = [...props.selectedHarvests, ...harvestList].filter(hvt => selectedHvtCodes.includes(hvt.code))

        const hvts = selectedHvts.filter((v, i, a) => a.findIndex(t => (t.code === v.code)) === i) // remove duplicatas
        props.setSelectedHarvests([...hvts])

        const names = new Set(selectedHvts.map(hvt => hvt.field.name))
        setSelectedFieldsNames([...names].join(', '))
    }

    function definedTotalLiters(): void {
        if (!props.selectedHarvests.length)
            return setTotalLiters(0)

        setTotalLiters(props.selectedHarvests.map(hvt => hvt.remainingLiters).reduce((val1: number, val2: number) => val1 + val2))
    }

    return (
        <WrapperSCP>
            <FiltersWrapperSCP>
                <FiltersSCP>
                    <PropertyFieldFieldsCP
                        onChangeField={(fieldCode: number) => setFilters({ ...filters, fieldCode })}
                        fieldCode={filters.fieldCode}
                        propertyCode={filters.propertyCode}
                        onChangeProperty={(propertyCode: number) => setFilters({ ...filters, propertyCode })}
                        onClear={() => setFilters(DEFAULT_FILTERS)}
                    />
                    <SeasonSelectorCP
                        label={'Safra'}
                        onChange={(season: number) => setFilters({ ...filters, season })}
                    />
                </FiltersSCP>
                <InfoSCP>
                    <RowSCP>
                        <TextCP text={'Talhões:'} />
                        <TextCP text={`${selectedFieldsNames}`} style={{ marginLeft: '10px', fontSize: '12px', fontWeight: 600, maxWidth: '200px' }} />
                    </RowSCP>
                    <RowSCP>
                        <TextCP text={'Total de litros:'} />
                        <TextCP text={`${MaskUtils.applyNumberMask(totalLiters)} L`} style={{ marginLeft: '10px', fontSize: '20px', fontWeight: 600 }} />
                    </RowSCP>
                    <TextCP
                        text={'Limpar selecionados'}
                        onClick={() => {
                            props.setSelectedHarvests([])
                            setTotalLiters(0)
                            ListActions.unselectAll()
                        }}
                        style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer', fontSize: '12px' }}
                    />
                </InfoSCP>
                {
                    screenSize.smd &&
                    <ButtonCP
                        onClick={() => setFilterDrawerVisible(true)}
                        type={'primary'}
                        style={{ bottom: 4, left: 32, height: 60, width: 60, zIndex: 1, borderRadius: '100%' }}
                        icon={<FontAwsomeIconCP icon={faFilter} color={'#fff'} />}
                    />
                }
            </FiltersWrapperSCP>

            <ListWrapperSCP>
                <ListCP<IHarvestResponseDTO>
                    grid={{ gutter: 16, column: 3, md: 1, sm: 1, xs: 1, lg: 2, xl: 3, xxl: 3 }}
                    bordered={false}
                    itemLayout={'vertical'}
                    loading={request.awaiting}
                    size={'large'}
                    dataSource={harvestList}
                    pagination={pagination}
                    hasStateControl={true}
                    locale={{
                        emptyText: <CardCP noSpacing={true} noSideSpacing={true}>
                            {' '}
                            <EmptyCP description={'Ainda não existem colheitas'} />
                            {' '}
                        </CardCP>
                    }}
                    renderItem={(item: IHarvestResponseDTO) => (
                        <HarvestCardCP
                            harvest={item}
                            loading={request.awaiting}
                        />
                    )}
                />
            </ListWrapperSCP>
            {
                screenSize.smd &&
                <HarvestMobileFiltersCP
                    show={filterDrawerVisible}
                    onClose={() => setFilterDrawerVisible(false)}
                    setFilters={setFilters}
                    filters={filters}
                />
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

`
const ListWrapperSCP = styled.div`
`
const InfoSCP = styled.div`
    display: flex;
    width: 100%; 
    justify-content: center;
    margin-left: 15px;
    flex-direction: column;
    align-items: flex-end;
   
    .ant-typography{
        font-size: 13px;
        ${props => props.theme.primaryColor};
    }

    svg {
        ${props => props.theme.primaryColor};
    }
    @media(max-width: 767px){
         align-items: flex-start;
     }
`
const FiltersWrapperSCP = styled.div` 
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1fr;
    column-gap: 10px; 
    justify-content: center;
    align-items: end;
    margin-bottom: 10px;
   
`
const FiltersSCP = styled.div`
     display: grid;
     width: 100%;
     grid-template-columns: 2fr 1fr;
     column-gap: 20px;
     @media(max-width: 767px){
         display: none;
     }
`
const RowSCP = styled.div`
    display: flex;
    align-items: center;
`
