import { PaginationConfig } from 'antd/lib/pagination'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-helper/RequestUtils'
import { useRequest } from 'common/request-helper/UseRequest'
import { ImplementRequests } from 'modules/maneuver/services/ImplementRequests'
import { IImplementResponseDTO } from 'modules/maneuver/services/dtos/responses/IImplementResponseDTO'
import React, { useEffect, useState } from 'react'
import { ISearchImplementRequestDTO } from 'modules/maneuver/services/dtos/requests/ISearchImplementRequestDTO'

import { CardCP } from 'common/components/card/CardCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListCP } from 'common/components/list/ListCP'
import { ImplementCardICP } from 'modules/maneuver/components/implement-list/inner/ImplementCardICP'
import styled from 'styled-components'
import { SearchActions } from 'common/components/list/inner/SearchActions'
import { FilterTypesEnum } from 'common/enums/FilterTypesEnum'
import { ListActions } from 'common/components/list/inner/ListActions'
import { ListUpdateStatusEnum } from 'common/components/list/inner/ListUpdateStatusEnum'
import { TextCP } from 'common/components/text/TextCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import _ from 'lodash'
import { ImplementTypeEnum } from 'modules/maneuver/enums/ImplementTypeEnum'
import { useSelector } from 'react-redux'
import { ImplementListFiltersCP } from '../implement-list-filters/ImplementListFiltersCP'

const DEFAULT_PAGINATION: PaginationConfig = {
    current: 1,
    pageSize: 12,
    total: 0,
    showTotal: (total: number, range: any[]) => `Mostrando ${range.join('-')} de ${total}`,
}

const DEFAULT_FILTERS: ISearchImplementRequestDTO = {
    searchString: undefined,
    itemsPerPage: DEFAULT_PAGINATION.pageSize,
    page: DEFAULT_PAGINATION.current
}
interface IImplementListCPProps {
    filterType: FilterTypesEnum
    implementType: ImplementTypeEnum
}

export function ImplementListCP(props: IImplementListCPProps): JSX.Element {
    const [implementList, setImplementList] = useState<IImplementResponseDTO[]>([])
    const [pagination, setPagination] = useState<PaginationConfig>(DEFAULT_PAGINATION)
    const [total, setTotal] = useState<number>(0)

    const updateStatus: ListUpdateStatusEnum = useSelector((state: IReduxState) =>
        _.get(state, 'listState.updateStatus'))
    const currentFilters: ISearchImplementRequestDTO | null = useSelector((state: IReduxState) =>
        _.get(state, 'searchState.filter'))
    const type: FilterTypesEnum = useSelector((state: IReduxState) =>
        _.get(state, 'searchState.type'))

    const request = useRequest<IGenericListResponseDTO<IImplementResponseDTO>>()
    useEffect(onRequestChange, [request.awaiting])

    useEffect(getImplementList, [currentFilters])
    useEffect(onItemChange, [updateStatus])
    useEffect(loadPersistedData, [props.filterType])

    function loadPersistedData(): void {
        if (type !== props.filterType) {
            return SearchActions.setFilter<ISearchImplementRequestDTO>(
                {
                    ...DEFAULT_FILTERS, type: props.implementType,
                    itemsPerPage: DEFAULT_PAGINATION.pageSize,
                    page: DEFAULT_PAGINATION.current
                },
                props.filterType,
            )
        }

        if (!currentFilters) {
            SearchActions.setFilter<ISearchImplementRequestDTO>(
                {
                    ...DEFAULT_FILTERS, type: props.implementType,
                    itemsPerPage: DEFAULT_PAGINATION.pageSize,
                    page: DEFAULT_PAGINATION.current
                },
                props.filterType,
            )
        } else {
            SearchActions.setFilter<ISearchImplementRequestDTO>(
                {
                    ...currentFilters,
                    itemsPerPage: DEFAULT_PAGINATION.pageSize,
                    page: DEFAULT_PAGINATION.current
                },
                props.filterType,
            )
        }
    }
    function getImplementList(): void {
        if (!!currentFilters && type === props.filterType)
            request.runRequest(ImplementRequests.getImplementList(currentFilters))
    }

    function onItemChange(): void {
        if (updateStatus === ListUpdateStatusEnum.MUST_UPDATE) {
            setImplementList([])
            SearchActions.setFilter<ISearchImplementRequestDTO>(
                {
                    ...DEFAULT_FILTERS, type: props.implementType,
                    itemsPerPage: DEFAULT_PAGINATION.pageSize,
                    page: DEFAULT_PAGINATION.current
                },
                props.filterType,
            )
        }
    }

    function onChangeFilter(filter?: ISearchImplementRequestDTO): void {
        setImplementList([])
        SearchActions.setFilter({
            ...filter,
            page: DEFAULT_PAGINATION.current,
            itemsPerPage: DEFAULT_PAGINATION.pageSize,
        }, props.filterType)
    }

    function onRequestChange(): void {
        if (request.awaiting || !request.tried) {
            ListActions.isUpdating()
            return
        }

        if (!request.success || !request.returnData) {
            if (request.status !== HttpStatusEnum.UNAUTHORIZED)
                console.error('ERROR: ', request.returnData, request.error)
            return RequestUtils.showDefaultErrorNotification(
                request.error,
                'Erro ao buscar lista de atividades!',
            )
        }
        setImplementList(request.returnData.list)
        setTotal(request.returnData.total)
        setPagination({
            ...pagination,
            total: request.returnData.total || 0,
            onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page })
                SearchActions.setFilter({ ...currentFilters, page, itemsPerPage: pageSize }, props.filterType)
            },
        })
        ListActions.updateIsDone()
    }

    return (
        <WrapperSCP>
            <ImplementListFiltersCP
                setFilters={onChangeFilter}
                filters={currentFilters ?? undefined}
            />
            <TotalSCP>
                <TextCP text={`${total} registros encontrados`} />
                <TextCP
                    text={'Limpar filtros'}
                    onClick={() => {
                        setPagination(DEFAULT_PAGINATION)
                        SearchActions.setFilter({
                            ...DEFAULT_FILTERS,
                            type: props.implementType,
                            page: 1
                        }, props.filterType)
                    }}
                    style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
                />
            </TotalSCP>
            <ListWrapperSCP>
                <ListCP<IImplementResponseDTO>
                    grid={{ gutter: 16, column: 3, md: 1, sm: 1, xs: 1, lg: 2, xl: 3, xxl: 3 }}
                    loading={request.awaiting}
                    bordered={false}
                    itemLayout={'vertical'}
                    size={'large'}
                    dataSource={implementList}
                    hasStateControl={true}
                    locale={{
                        emptyText: (
                            <CardCP noSpacing={true} noSideSpacing={true}>
                                {' '}
                                <EmptyCP description={'Nenhum resultado'} />
                                {' '}
                            </CardCP>
                        ),
                    }}
                    renderItem={(item: IImplementResponseDTO) => (
                        <ImplementCardICP implement={item} loading={request.awaiting} />
                    )}
                    pagination={pagination}
                />
            </ListWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    border-radius: 10px;
    margin-bottom: 4em;
    max-height: 75vh;
    overflow: overlay;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding-right: 4px;
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: #f5f5f5;
    }
    ::-webkit-scrollbar {
        width: 2px;
        border-radius: 1px;
        background-color: #f5f5f5;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.primaryColor};
    }
    @media (max-width: 768px) and (max-height: 600px) {
        max-height: 75vh;
    }
    @media (max-width: 768px) and (min-height: 600px) {
        max-height: 70vh;
    }
`
const ListWrapperSCP = styled.div``

const TotalSCP = styled.div`
    margin-left: 3px;
    display: flex;
    justify-content: space-between;
    min-height: 20px;
    .ant-typography {
        font-size: 11px;
        color: white;
    }
    @media (max-width: 768px) {
        margin-top: 17px;
        .ant-typography {
            color: ${(props) => props.theme.primaryColor};
        }
    }
`
